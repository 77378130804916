import * as React from 'react';

import { Modal } from '../modal';
import { ThemeConsumer } from '../theme';
import { Box } from '../box';
import { OrganizationPreview } from '../organization-preview';

const styles = {
  modal: {
    content: {
      borderRadius: 10,
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0.5px 10px 0 rgba(0,0,0,0.13)',
      top: 0,
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, 0%)',
      padding: 0,
      border: 'none',
    },
    overlay: {
      zIndex: 10,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
};

export function OrganizationModal(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Modal
          isOpen={props.isOpen}
          onRequestClose={props.onRequestClose}
          style={styles.modal}
          closeTimeoutMS={500}
          submitButtonProps={{
            title: 'Close',
          }}
        >
          {(modal) =>
            props.data ? (
              <Box style={{ width: '470' }}>
                <OrganizationPreview data={props.data} joining={props.joining} onJoinNow={props.onJoinNow} />
              </Box>
            ) : null
          }
        </Modal>
      )}
    </ThemeConsumer>
  );
}
