import * as React from 'react';
import { Formik } from 'formik';
import { Trans, withTranslation } from 'react-i18next';

import { Button } from '../../atoms/button';
import { RequestError } from '../../atoms/error';
import { SelectField, CheckboxGroup } from '../../atoms/form';
import { TranslationService } from '../../services/TranslationService';
import languages from './languages.json';

import { Box } from '../../atoms/box/Box';
import { Text } from '../../atoms/text';
import { TABS } from 'old/pages/page-settings/SettingsSideNav';
import variables from 'common/styles/variables.json';

function CheckboxTitle(props) {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      style={{ marginBottom: 5 }}
      id={props.id}
    >
      <Text weight="600" size={14} color="#90A4AE">
        {props.title}
      </Text>
      <CheckboxGroup name={props.name} values={[props.value]} />
    </Box>
  );
}

class Renderer extends React.Component {
  state = {
    languages: languages,
    response: null,
  };

  get lang() {
    return navigator.language || navigator.userLanguage;
  }

  get settings() {
    return (
      this.props.settings || {
        language: { value: this.lang },
        enable_translations: { value: true },
      }
    );
  }

  get initialValues() {
    return {
      enable_translations: [this.settings.enable_translations.value],
      language: this.settings.language.value || this.lang,
    };
  }

  componentWillMount() {
    if (!this.props.settings.language.value) {
      this.props.onSubmit(
        {
          enable_translations: this.props.settings.enable_translations.value,
          language: this.lang,
        },
        true,
      );
    }

    this.fetchSupportedLanguages();
  }

  fetchSupportedLanguages = async () => {
    const response = await TranslationService.getSupportedLanguages();

    if (response.ok) {
      this.setState({ languages: response.data.languages });
    }
  };

  onSubmit = async (values, formik) => {
    if (formik) {
      formik.setSubmitting(true);
    }

    const response = await this.props.onSubmit({
      enable_translations: values.enable_translations.includes(true),
      language: values.language,
    });

    this.setState({ response: response });

    if (formik) {
      formik.setSubmitting(false);
    }
  };

  render() {
    return (
      <div id={TABS.translations}>
        <Formik initialValues={this.initialValues} onSubmit={this.onSubmit}>
          {(formik) => (
            <>
              <CheckboxTitle
                title={<Trans>Enable Translations</Trans>}
                name="enable_translations"
                value={{ value: true, label: <Trans /> }}
                id="enable-translations"
              />

              <SelectField
                name="language"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.language}
                label={<Trans>Language</Trans>}
                options={this.state.languages || []}
                placeholder={this.props.t('Please, select language')}
                id="select-language"
              />

              <div style={{ margin: '25px 0', width: 150 }}>
                <Button
                  outline
                  block
                  type="submit"
                  color={variables.brandColor.value}
                  textColor={variables.brandColor.value}
                  size="lg"
                  title={formik.isSubmitting ? this.props.t('Updating...') : this.props.t('Update')}
                  disabled={formik.isSubmitting}
                  onClick={formik.submitForm}
                  id="btn-update-translations"
                />
              </div>

              <RequestError response={this.state.response} />
            </>
          )}
        </Formik>
      </div>
    );
  }
}

export const TranslationsForm = withTranslation()(Renderer);
