import { isPasswordValid } from 'common/validators';

export const Validator = {
  name: 'password_regex',

  errorMessage:
    'Password must contain minimum 12 characters, maximum 72 characters, at least one uppercase letter, one lowercase letter, one number and one special character.',

  validate: (value) => isPasswordValid(value),
};
