import * as React from 'react';
import { ThemeConsumer } from '../../theme';

export class Radio extends React.Component {
  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <React.Fragment>
            <input type="radio" {...this.props} hidden />
            <svg className="radio-box-svg" disabled={this.props.disabled}>
              <circle
                className="ring"
                cx="12.75"
                cy="12.75"
                r="12"
                strokeWidth="1.5"
                style={{
                  stroke: theme.color.brand,
                }}
              />
              <circle
                className="fill"
                cx="12.75"
                cy="12.75"
                r="7.5"
                style={{
                  transition: 'fill 200ms ease-in-out',
                  fill: this.props.checked ? theme.color.brand : 'transparent',
                }}
              />
            </svg>
          </React.Fragment>
        )}
      </ThemeConsumer>
    );
  }
}
