import { t } from 'i18next';
import { ClosePasswordIcon, Paragraph, modalConfirm } from 'ui';

type P = {
  onRequestUnblockAccount: (email?: string) => void;
};

const useShowUnblockAccountModal = () => {
  // TODO use useCallback with combination of useMemo
  const showUnblockAccountModal = ({ onRequestUnblockAccount }: P) => {
    modalConfirm({
      title: t('Authentication failed'),
      content: (
        <>
          <Paragraph>
            Your account has been locked for 30 minutes after 5 failed attempts to login. After this period, your
            account will be available with current credentials.
          </Paragraph>
          <Paragraph>If you wish to unlock your account sooner, you need to reset your password.</Paragraph>
        </>
      ),
      onOk() {
        onRequestUnblockAccount();
      },
      okText: t('Reset password'),
      cancelText: t('Cancel'),
      icon: <ClosePasswordIcon className="onboarding-password-login__failed-login-modal-icon" />,
    });
  };

  return { showUnblockAccountModal };
};

export default useShowUnblockAccountModal;
