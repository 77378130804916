import cn from 'classnames';
import { Suspense, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { ChatroomState } from '../index';
import { getChatroom } from './actions';
import { Profile, User } from './common/models';
import { DataState } from './common/recoil/queries';
import { activePracticeIdState } from './common/recoil/selectors';
import { useSocketIO } from './common/sockets';
import { ID } from './common/types';
import { ChatroomCreating, ChatroomDetail, ChatroomSidebar } from './components';
import { Chatroom, Message } from './models';
import { activeChatroom, chatrooms } from './recoil/atoms';
import {
  chatroomsArchivedState,
  chatroomsLastUpdatedState,
  chatroomsState,
  updateChatroomsState,
} from './recoil/selectors';
import { ChatroomDataObject, MessageDataObject } from './services/data-objects';
import { Col, message as userMessage, Result, Row, Spin } from './ui';
import { isPracticeEnabled } from './utils';
import { events } from './common/services';

type P = {
  className?: string;
  isLoading: boolean;
  currentUserProfileData: DataState<Profile>;
  updateTotalUnreadCount?: (difference?: number) => void;
  defaultNewChatroomUser?: User;
  chatroomIdToOpen?: ID;
  handleChatState: (chatStateToApply: ChatroomState, id?: ID) => void;
  currentChatState: ChatroomState;
};

const Chat = ({
  className,
  currentUserProfileData,
  isLoading,
  updateTotalUnreadCount,
  defaultNewChatroomUser,
  chatroomIdToOpen,
  handleChatState,
  currentChatState,
}: P) => {
  const { t } = useTranslation(['translationChat']);
  const activePracticeId = useRecoilValue(activePracticeIdState);
  const loadedChatrooms = useRecoilValue(chatrooms);
  const deleteChatrooms = useResetRecoilState(updateChatroomsState);
  const setLastUpdatedChatroom = useSetRecoilState(chatroomsLastUpdatedState);
  const setActiveChatroomId = useSetRecoilState(activeChatroom);
  const defaultNewChatroomUserReference = useRef(defaultNewChatroomUser);
  const setArchivedChatroom = useSetRecoilState(chatroomsArchivedState);
  const resetChatroomsData = useResetRecoilState(chatroomsState);
  const chatroomsData = useRecoilValueLoadable(chatroomsState);

  useEffect(() => {
    return () => {
      setActiveChatroomId(null);
      deleteChatrooms();
    };
  }, [deleteChatrooms, setActiveChatroomId]);

  useEffect(() => {
    if (defaultNewChatroomUser && currentChatState !== 'creating') {
      handleChatState('creating');
      defaultNewChatroomUserReference.current = new User(defaultNewChatroomUser);
    }
  }, [defaultNewChatroomUser, handleChatState, defaultNewChatroomUserReference]);

  const updateUnreadBadge = (chatroomIdToUpdate: ID | null) => {
    if (updateTotalUnreadCount && chatroomIdToUpdate) {
      const currentChatroom = loadedChatrooms.find((chatroomItem) => chatroomItem._id === chatroomIdToUpdate);
      if (!currentChatroom) {
        updateTotalUnreadCount();
      } else if (currentChatroom.stats) {
        updateTotalUnreadCount(currentChatroom.stats.unread * -1);
      } else {
        // Do nothing
      }
    }
  };

  const handleSocketNewMessage = async (message: Message) => {
    const updatedChatroom = loadedChatrooms.find((c) => c._id === message.chatroom);
    if (updatedChatroom) {
      const newChatroom = new Chatroom({
        ...updatedChatroom,
        updatedAt: message.createdAt.toISOString(),
        createdAt: updatedChatroom.createdAt.toISOString(),
        message,
        ...(updatedChatroom.stats &&
          message.author !== currentUserProfileData.result?._id && {
            stats: { ...updatedChatroom.stats, unread: updatedChatroom.stats.unread + 1 },
          }),
      });

      setLastUpdatedChatroom(newChatroom);
    } else {
      await handleGetChatroom(message.chatroom);
    }
  };

  const handleGetChatroom = async (chatroomId: string) => {
    const response = await getChatroom(chatroomId, activePracticeId);

    if (response.error) {
      return userMessage.error('errors.unknown');
    }

    const { chatroom } = response.result;
    setLastUpdatedChatroom(chatroom);
  };

  const handleChatroomMembers = async (chatroom: Chatroom) => {
    setLastUpdatedChatroom(chatroom);
  };

  const handleChatroomDelete = (chatroomId: ID) => {
    const chatroomToDelete = loadedChatrooms.find((chatroom) => chatroom._id === chatroomId);
    updateUnreadBadge(chatroomId);
    if (!chatroomToDelete) {
      return null;
    }

    setArchivedChatroom(chatroomToDelete);
    resetChatroomsData();
    return handleChatState('default');
  };

  useSocketIO<MessageDataObject>({
    event: events.chatroom.messages.created,
    onEvent: (socketData: MessageDataObject) => {
      const message = new Message(socketData);
      return handleSocketNewMessage(message);
    },
  });

  useSocketIO<ChatroomDataObject>({
    event: events.chatroom.members.created,
    onEvent: (socketData: ChatroomDataObject) => {
      const chatroom = new Chatroom({
        ...socketData,
        message: socketData.message ? new Message(socketData.message) : undefined,
        statuses: socketData.statuses.map((status) => new Message(status)),
      });
      return handleChatroomMembers(chatroom);
    },
  });

  useSocketIO<ChatroomDataObject>({
    event: events.chatroom.members.removed,
    onEvent: (socketData: ChatroomDataObject) => {
      const chatroom = new Chatroom({
        ...socketData,
        message: socketData.message ? new Message(socketData.message) : undefined,
        statuses: socketData.statuses?.map((status) => new Message(status)) ?? [],
      });
      return handleChatroomMembers(chatroom);
    },
  });

  useSocketIO<ChatroomDataObject>({
    event: events.chatroom.removed,
    onEvent: (socketData: ChatroomDataObject) => {
      const chatroom = new Chatroom({
        ...socketData,
        message: socketData.message ? new Message(socketData.message) : undefined,
        statuses: socketData.statuses?.map((status) => new Message(status)) ?? [],
      });
      return handleChatroomDelete(chatroom._id);
    },
  });

  if (loadedChatrooms.length > 0 && currentChatState === 'default' && chatroomsData.state === 'hasValue') {
    resetChatroomsData();
    handleChatState('chatroom', loadedChatrooms[0]._id);
    return null;
  }

  if (!isLoading && isPracticeEnabled() && !activePracticeId) {
    return (
      <div className="no-practice">
        <Result status={'error'} title={'Error'} subTitle={t('chat.errors.noActivePractice')} />
      </div>
    );
  }

  const renderChatroomContent = () => {
    if (currentChatState === 'creating') {
      return (
        <ChatroomCreating
          currentUserProfileData={currentUserProfileData}
          defaultNewChatroomUserRef={defaultNewChatroomUserReference}
          handleChatState={handleChatState}
        />
      );
    }

    return (
      <ChatroomDetail
        currentUserProfileData={currentUserProfileData}
        updateUnreadBadge={updateUnreadBadge}
        handleChatState={handleChatState}
      />
    );
  };

  return (
    <Row className={cn('chat', className)}>
      <Col className="chat__sidebar">
        <ChatroomSidebar
          currentChatState={currentChatState}
          currentUserProfileData={currentUserProfileData}
          updateTotalUnreadCount={updateTotalUnreadCount}
          updateUnreadBadge={updateUnreadBadge}
          defaultNewChatroomUserRef={defaultNewChatroomUserReference}
          chatroomIdToOpen={chatroomIdToOpen}
          handleChatState={handleChatState}
        />
      </Col>
      <Col className="chat__detail">
        <Suspense fallback={<Spin />}>{renderChatroomContent()}</Suspense>
      </Col>
    </Row>
  );
};

export default Chat;
