import { useCallback, useEffect, useState, ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Input, Modal } from '../../../../ui';

type P = {
  isOpen: boolean;
  isLoading: boolean;
  currentName: string | undefined;
  onOkClick: (chatroomName: string) => void;
  onCancelClick: () => void;
};

const ModalRenameChat = ({ isOpen, isLoading, onOkClick, onCancelClick, currentName }: P) => {
  const { t } = useTranslation(['translationChat']);
  const [form] = Form.useForm();
  const [name, setName] = useState(currentName);
  const handleOkClick = () => (name ? onOkClick(name) : null);
  const handleCancelClick = () => onCancelClick();

  const inputReference = useCallback((node) => (node as HTMLElement)?.focus(), []);

  useEffect(() => {
    form.setFieldsValue({ groupname: currentName });
  }, [currentName, form]);

  return (
    <Modal
      className="modal--rename-chat"
      open={isOpen}
      title={t('chat.chatroomDetail.header.modalRenameChat.title')}
      okText={t('chat.chatroomDetail.header.modalRenameChat.okText')}
      cancelText={t('chat.chatroomDetail.header.modalRenameChat.cancelText')}
      onOk={handleOkClick}
      okButtonProps={{ loading: isLoading }}
      onCancel={handleCancelClick}
      okType={'primary'}
      destroyOnClose
    >
      <Form
        form={form}
        className="form form--change-chatroom-name"
        layout="vertical"
        initialValues={{ groupname: name }}
      >
        <Form.Item label={t('chat.chatroomDetail.header.modalRenameChat.form.groupName.label')} name="groupname">
          <Input
            ref={inputReference}
            type={'text'}
            size={'large'}
            className="form-item outlined form-item--group-name"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            placeholder={t('chat.chatroomDetail.header.modalRenameChat.form.groupName.placeholder')}
            onPressEnter={handleOkClick}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalRenameChat;
