import * as React from 'react';
import { Trans } from 'react-i18next';
import { Field } from 'formik';

import { Box } from '../box';
import { FieldError } from './FieldError';
import { Icon } from '../icon';
import { Loader } from '../loader';
import { Uploader } from '../uploader';
import { FileDownloader } from '../file-downloader';

export class UploadFileField extends React.Component {
  state = {
    file: null,
    uploading: false,
  };

  $uploader = React.createRef();

  getFileParam(fieldProps, param) {
    if (fieldProps.field.value?.[param]) {
      return fieldProps.field.value[param];
    }

    if (this.state.file?.[param]) {
      return this.state.file[param];
    }
  }

  openUploader = () => {
    if (this.$uploader.current) {
      this.$uploader.current.click();
    }
  };

  onChange = (fieldProps) => {
    return (files) => {
      this.setState({ file: files[0], uploading: false });
      fieldProps.form.setFieldValue(fieldProps.field.name, {
        id: files[0].id,
        url: files[0].url,
      });
    };
  };

  getSize = (sizeInB) => {
    return Math.round(sizeInB / 1024 / 1024, 2);
  };

  render() {
    return (
      <Field name={this.props.name} className="upload-file-field">
        {(fieldProps) => {
          return (
            <Box>
              <div style={{ position: 'relative' }}>
                <div className={`upload-file-field__icon ${this.props.iconClass}`}>
                  {this.state.uploading ? (
                    <Loader size={24} />
                  ) : (
                    <Icon
                      onClick={this.openUploader}
                      name="pba-attachment"
                      size={30}
                      color="#B0BEC5"
                      style={{ display: 'block', cursor: 'pointer' }}
                    />
                  )}
                  <Uploader
                    onChange={this.onChange(fieldProps)}
                    setUploading={(uploading) => {
                      this.setState({ uploading });
                    }}
                    TriggerComponent={({ uploading }) => <div ref={this.$uploader} />}
                    id={`upload-file-field-${this.props.suffixId}`}
                    // accept="application/vnd.ms-powerpoint"
                  />
                </div>
              </div>
              {fieldProps.field.value && (
                <div>
                  <span style={{ fontWeight: 'bold' }}>
                    {fieldProps.field.value.filename || this.state.file.filename}
                  </span>
                  <br />
                  {this.getSize(fieldProps.field.value.byte_size || this.state.file.byte_size)} <Trans>KB</Trans>
                  {this.props.downloadable &&
                    this.getFileParam(fieldProps, 'download_url') &&
                    this.getFileParam(fieldProps, 'filename') && (
                      <FileDownloader
                        url={this.getFileParam(fieldProps, 'download_url')}
                        fileName={this.getFileParam(fieldProps, 'filename')}
                      />
                    )}
                </div>
              )}
              <FieldError name={fieldProps.field.name} />
            </Box>
          );
        }}
      </Field>
    );
  }
}
