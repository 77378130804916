import { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export class ImageCropper extends Component {
  base64 = '';

  state = {
    images: {},
  };

  componentDidMount() {
    this.loadData();
  }

  crop = () => {
    if (this.refs.cropper) {
      this.refs.cropper.crop();
    }
  };

  getBase64() {
    return this.base64;
  }

  onCrop = () => {
    if (this.refs.cropper) {
      this.base64 = this.refs.cropper.cropper.getCroppedCanvas().toDataURL();
    }
  };

  onReady = () => {
    if (this.props.ready) {
      this.props.ready();
    }
  };

  image = () => this.state.images[this.props.src];

  loadData = () => {
    // This is, because Cropper is adding timestamp to URL -> so AWS URLs are not working with Cropper (hash check)
    if (this.props.src && !this.image() && !this.state.loading) {
      const superThis = this;
      const superSrc = this.props.src;
      superThis.setState({ loading: true });

      const xhr = new XMLHttpRequest();
      xhr.open('GET', superSrc, true);
      xhr.responseType = 'blob';
      xhr.onload = function (e) {
        if (this.status === 200) {
          const reader = new FileReader();

          reader.onload = function (e) {
            superThis.setState({ loading: false, images: { ...superThis.images, [superSrc]: e.target.result } });
          };

          reader.onerror = function () {
            superThis.setState({ loading: false });
          };

          reader.readAsDataURL(this.response);
        } else {
          superThis.setState({ loading: false });
        }
      };

      xhr.send();
    }
  };

  render() {
    if (!this.image()) {
      return null;
    }

    return (
      <Cropper
        ref="cropper"
        guides={true}
        crop={this.onCrop}
        ready={this.onReady}
        viewMode={1}
        style={{ height: 500, width: '100%' }}
        {...this.props}
        src={this.image()}
      />
    );
  }
}
