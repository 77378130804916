import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import api from '../../services/api';

export const useMarkAsCompleted = () =>
  useMutation<
    unknown,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      activityId: string;
    }
  >({
    async mutationFn({ activityId }) {
      const response = await api.markActivityAsCompleted(activityId);

      // TODO currently working with redux
      return response.data;
    },
  });
