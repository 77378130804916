import { useResetQuery } from 'query';
import { useProfileQuery } from 'features/user-profile/queries';
import { Loading } from 'ui';
import { ConsentsForm } from '../components';
import { useConsentsQuery } from '../queries';

type P = {
  onAccept: () => void;
  onReject: () => void;
};

const Consents = ({ onAccept, onReject }: P) => {
  const { mutateAsync: resetQuery } = useResetQuery();
  const { data: profile, isLoading: isLoadingProfile, isFetching: isFetchingProfile } = useProfileQuery();
  const {
    data: consents,
    isLoading: isLoadingConsents,
    isFetching: isFetchingConsents,
  } = useConsentsQuery({ consentIds: profile?.requiredConsents.map((consent) => consent.id) });

  if ((isLoadingProfile && isFetchingProfile) || !profile || (isLoadingConsents && isFetchingConsents) || !consents) {
    return <Loading />;
  }

  const communitiesThatRequiresConsents = profile.joinedCommunities.filter((community) =>
    community.organization.requiredConsents.some((requiredConsent) =>
      consents.some((consent) => consent.id === requiredConsent.id),
    ),
  );

  const handleAccept = async () => {
    await resetQuery({ cacheResetOption: 'reset' });
    onAccept();
  };

  const handleReject = async () => {
    await resetQuery({ cacheResetOption: 'reset' });
    onReject();
  };

  return (
    <ConsentsForm
      onAccept={handleAccept}
      onReject={handleReject}
      consents={consents}
      currentAcceptedConsents={profile.usersConsents}
      entities={communitiesThatRequiresConsents}
    />
  );
};

export default Consents;
