import * as React from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import Downshift from 'downshift';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { GlobalSearchService } from '../../services/GlobalSearchService';
import { ShadowBox } from '../shadow-box';
import { SearchInput } from 'ui';
import { Section } from './Section';
import {
  EventItem,
  OrganizationItem,
  CommunityItem,
  CurriculumItem,
  FileItem,
  UserItem,
  CampaignItem,
  PostItem,
  NoResultsItem,
  RecentSearchItem,
} from './search-items';
import { getAppConfig } from '../app-config-gate';
import { OrganizationModalDeprecated } from '../organization-modal';
import { dispatch } from '../../store';
import { CommunityService } from '../../services/CommunityService';
import { can } from '../../utils/permissions';

const LIMIT_CATEGORY_ITEMS = 3;

const itemToString = (item) => (item ? item.name : '');

const convertItem = (item) =>
  ({
    Asset: 'files',
    Post: 'posts',
    Event: 'events',
    Organization: 'organizations',
    Community: 'communities',
    Program: 'programs',
    User: 'users',
    Campaign: 'campaigns',
    Activity: 'activities',
    Poll: 'polls',
  }[item]);

export const searchItemTypes = {
  posts: {
    item: 'Post',
    header: <Trans>Conversations</Trans>,
    component: (props) => <PostItem {...props} />,
    path: (props) => props.history.push(`/posts/${props.item.id}`),
    itemToString: (item) => (item ? item.text_content : ''),
  },
  activities: {
    item: 'Activity',
    header: <Trans>Activities</Trans>,
    component: (props) => <PostItem {...props} />,
    path: (props) => props.history.push(`/activities/${props.item.id}`),
    itemToString: (item) => (item ? item.name : ''),
  },
  polls: {
    item: 'Poll',
    header: <Trans>Polls</Trans>,
    component: (props) => <PostItem {...props} />,
    path: (props) => props.history.push(`/polls/${props.item.id}`),
    itemToString: (item) => (item ? item.description : ''),
  },
  events: {
    item: 'Event',
    header: <Trans>Events</Trans>,
    component: (props) => <EventItem {...props} />,
    path: (props) => props.history.push(`/events/${props.item.id}/about`),
    itemToString,
  },
  communities: {
    item: 'Community',
    header: <Trans>Communities</Trans>,
    component: (props) => <CommunityItem {...props} />,
    path: (props) => props.history.push(`/communities/${props.item.id}/feed`),
    itemToString,
  },
  organizations: {
    item: 'Organization',
    header: <Trans>Organizations</Trans>,
    component: (props) => <OrganizationItem {...props} />,
    path: (props) => props.history.push(`/organizations/${props.item.id}/about`),
    itemToString,
  },
  programs: {
    item: 'Program',
    header: <Trans>Programs</Trans>,
    component: (props) => <CurriculumItem {...props} />,
    path: (props) =>
      props.history.push(
        `/courses/${props.item.id}/${can(props.item, 'Course', 'isMemberOfProgram') ? 'timeline' : 'about'}`,
      ),
    itemToString,
  },
  campaigns: {
    item: 'Campaign',
    header: <Trans>Campaigns</Trans>,
    component: (props) => <CampaignItem {...props} />,
    path: (props) => props.history.push(`/campaigns/listing/${props.item.organization_id}/${props.item.id}`),
    itemToString,
  },
  files: {
    item: 'Asset',
    header: <Trans>Files</Trans>,
    component: (props) => <FileItem {...props} />,
    path: (props) => {
      return props.history.push(`/${convertItem(props.item.assetable_type)}/${props.item.assetable_id}/assets`);
    },
    itemToString,
  },
  users: {
    item: 'User',
    header: <Trans>People</Trans>,
    component: (props) => <UserItem {...props} />,
    path: (props) => props.history.push(`/members/${props.item.id}`),
    itemToString: (item) => (item ? `${item.first_name} ${item.last_name}` : ''),
  },
};

export class Renderer extends React.Component {
  attempt = 0;
  timeout = 0;

  state = {
    searchResults: {},
    recent: [],
    search: '',
    loading: false,
    isOrganizationPreviewModalOpen: false,
    selectedCommunityForPreview: null,
    selectedOrganizationForPreview: null,
    isSearchFocused: false,
  };

  componentDidMount = () => {
    !getAppConfig().feature_flags.campaigns && delete searchItemTypes.campaigns;
  };

  onRequestClose = () => {
    this.setState({
      isOrganizationPreviewModalOpen: false,
      selectedCommunityForPreview: null,
      selectedOrganizationForPreview: null,
    });
  };

  onOrganizationJoinNow = async (organization) => {
    this.setState({ loadingOrganizationJoinId: organization.id });
    await dispatch.organizations.joinAsync(organization.id);

    this.setState({ selectedOrganizationForPreview: organization });

    this.onRequestClose();
  };

  onCommunityJoinNow = async (community, params) => {
    await CommunityService.join(community.id, params);

    await dispatch.session.fetchUser();
  };

  onCommunityExploring = async (community) => {
    window.location.href = `/communities/${community.id}/feed`;
  };

  onCommunityClick = (community) => {
    if (can(community, 'Community', 'joined')) {
      return false;
    }

    this.setState({
      selectedCommunityForPreview: community,
    });

    return true;
  };

  onOpenOrganizationPreviewModal = (organization) => {
    this.setState({
      isOrganizationPreviewModalOpen: true,
      selectedOrganizationForPreview: organization,
    });
  };

  setTerm = (value) => {
    if (value) {
      this.setState({ search: value }, () => {
        this.attempt += 1;
      });
    }
  };

  getDataForValue = async () => {
    const _attempt = this.attempt;
    const result = await GlobalSearchService.get({ query: this.state.search });
    if (_attempt === this.attempt) {
      this.setState({ searchResults: result.data, loading: false });
    }
  };

  clearTerm = () => {
    this.setState({ searchResults: {}, search: '' });
  };

  setRecentSearch = async () => {
    this.setState({ loading: true });
    const result = await GlobalSearchService.recentSearch();
    this.setState({ recent: result.data, loading: false });
  };

  onTrackRecentSearch = async (item) => {
    await GlobalSearchService.trackRecent({ id: item.id, type: item.type });
  };

  onSeeAll = (type) => {
    this.clearTerm();
    this.props.history.push(`/search/${type}?query=${this.state.search}`);
  };

  render() {
    return (
      <Manager>
        <Downshift
          onInputValueChange={(value, ds) => {
            if (ds.isOpen || value) {
              this.setTerm(value);
            }
          }}
          onOuterClick={() => {
            this.clearTerm();
          }}
          itemToString={itemToString}
        >
          {(ds) => (
            <div className="global-search__wrapper">
              <Reference>
                {({ ref }) => (
                  <div ref={ref} className="global-search--ref" id="header-global-search">
                    <SearchInput
                      className={classNames('global-search', {
                        'global-search__focused': this.state.isSearchFocused,
                      })}
                      placeholder={this.props.t('Search user or content...')}
                      enterButton={this.props.t('Search')}
                      prefix={
                        this.state.loading ? (
                          <LoadingOutlined className="global-search__icon" />
                        ) : (
                          <SearchOutlined className="global-search__icon" />
                        )
                      }
                      {...ds.getInputProps({
                        onFocus: () => {
                          this.setState({ isSearchFocused: true });
                          ds.clearItems();
                          ds.clearSelection();
                          !ds.inputValue && this.setRecentSearch();
                          !ds.isOpen && ds.openMenu();
                        },
                      })}
                      onBlur={() => {
                        this.setState({ isSearchFocused: false });
                      }}
                      onSearch={() => {
                        this.setState({ loading: true });
                        this.getDataForValue();
                      }}
                    />
                  </div>
                )}
              </Reference>
              {ds.isOpen ? (
                <Popper
                  placement="bottom-start"
                  modifiers={{
                    flip: { enabled: false },
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                    autoSizing: {
                      enabled: true,
                      fn: (data) => {
                        data.styles.width = data.offsets.reference.width;
                        return data;
                      },
                    },
                  }}
                >
                  {({ ref, style, placement }) => (
                    <div ref={ref} style={{ zIndex: 2, ...style }} data-placement={placement}>
                      <ShadowBox style={{ maxHeight: 600, overflowY: 'auto', borderTop: 0, marginTop: 4 }}>
                        {ds.inputValue ? (
                          <React.Fragment>
                            {
                              // show global search results from all categories
                              Object.keys(searchItemTypes).map((type) =>
                                this.state.searchResults[type] && this.state.searchResults[type].length > 0 ? (
                                  <div key={type}>
                                    <Section
                                      title={searchItemTypes[type].header}
                                      showSeeAll={this.state.searchResults[`${type}_count`] > LIMIT_CATEGORY_ITEMS}
                                      onSeeAll={() => {
                                        this.onSeeAll(type);
                                        ds.closeMenu();
                                      }}
                                    />
                                    <div>
                                      {this.state.searchResults[type]
                                        ?.slice(0, LIMIT_CATEGORY_ITEMS)
                                        .map((item, index) =>
                                          searchItemTypes[type].component({
                                            key: item.id,
                                            title: searchItemTypes[type].itemToString(item),
                                            itemProps: item,
                                            user: this.props.user,
                                            downshiftProps: {
                                              ...ds.getItemProps({
                                                item: item,
                                                onClick: async () => {
                                                  if (type === 'organizations') {
                                                    this.onOpenOrganizationPreviewModal(item);
                                                  } else {
                                                    await this.onTrackRecentSearch({
                                                      type: searchItemTypes[type].item,
                                                      id: item.id,
                                                    });
                                                    await searchItemTypes[type].path({
                                                      item: item,
                                                      ...this.props,
                                                    });
                                                  }
                                                },
                                              }),
                                            },
                                          }),
                                        )}
                                    </div>
                                  </div>
                                ) : null,
                              )
                            }
                            {
                              // show no result message if all categories are empty
                              Object.keys(searchItemTypes)
                                .map((type) => this.state.searchResults[`${type}_count`])
                                .every((value) => value === 0) && <NoResultsItem />
                            }
                          </React.Fragment>
                        ) : (
                          // show recent searches
                          this.state.recent.map((item) => (
                            <RecentSearchItem
                              key={item.id}
                              title={item.name}
                              {...ds.getItemProps({
                                item: item,
                                onClick: () => {
                                  const convertedItem = convertItem(item.type);
                                  searchItemTypes[convertedItem].path({
                                    item: item,
                                    ...this.props,
                                  });
                                },
                              })}
                            />
                          ))
                        )}
                      </ShadowBox>
                    </div>
                  )}
                </Popper>
              ) : null}
            </div>
          )}
        </Downshift>
        <OrganizationModalDeprecated
          isOpen={!!this.state.isOrganizationPreviewModalOpen}
          onRequestClose={this.onRequestClose}
          onRequestToJoin={() => {}}
          onJoinNow={this.state.selectedCommunityForPreview ? this.onCommunityJoinNow : this.onOrganizationJoinNow}
          type={this.state.selectedCommunityForPreview ? 'community' : 'organization'}
          data={this.state.selectedCommunityForPreview || this.state.selectedOrganizationForPreview}
          onExploring={this.onCommunityExploring}
          onCommunityClick={this.onCommunityClick}
        />
        {this.state.isSearchFocused && <div className="global-search__overlay" />}
      </Manager>
    );
  }
}

export const GlobalSearch = withTranslation()(withRouter(Renderer));
