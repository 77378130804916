import { denormalize, normalize, schema } from 'normalizr';
import { update } from './helpers';
import { createModel } from '@rematch/core';
import { GlobalSearchService } from '../services/GlobalSearchService';

const INITIAL_STATE = {
  entities: { globalSearch: {} },
  result: [],
  scope: null,
  query: null,
};
/**
 * Helpers
 */

const GlobalSearchSchema = new schema.Entity('globalSearch');

function denorm(state) {
  return denormalize(state.result || [], [GlobalSearchSchema], state.entities);
}

function norm(payload) {
  return normalize(payload, [GlobalSearchSchema]);
}

export const model = createModel({
  name: 'globalSearch',
  state: INITIAL_STATE,
  reducers: {
    pushResults: (state, payload) => {
      const { entities, result } = norm(payload);
      return update(state, {
        entities: { globalSearch: { $merge: entities.globalSearch || {} } },
        result: { $autoArray: { $push: result } },
      });
    },
    updateQuery(state, payload) {
      const { query, scope } = payload;
      return update(state, {
        query: { $set: query },
        scope: { $set: scope },
      });
    },
    resetResults(state) {
      return INITIAL_STATE;
    },
  },
  selectors: {
    collection(state) {
      return denorm(state);
    },
    query(state) {
      return state.query;
    },
    scope(state) {
      return state.scope;
    },
  },
  effects: (dispatch) => ({
    async get(params) {
      const { scope, query } = params;
      const response = await GlobalSearchService.detail(params);
      if (response.ok) {
        dispatch.globalSearch.pushResults(response.data);
        dispatch.globalSearch.updateQuery({ scope, query });
      }
      return response;
    },
  }),
});
