import { type ComponentProps, useCallback, useMemo } from 'react';
import { type ButtonProps } from 'ui';
import { type CalendarOption } from '../CalendarOption';
import { type CalendarOption as CalendarOptionType } from '.';

export const useCalendarOptionProperties = () => {
  const generateCalendarButtonProperties = useCallback(
    ({ status, loading, onSync, onDesync }: CalendarOptionType): ButtonProps => {
      switch (status) {
        case 'synced':
          return { loading, children: 'Unsync', danger: true, type: 'dashed', onClick: onDesync };
        case 'unsynced':
          return { loading, children: 'Sync', type: 'primary', onClick: onSync };
        case 'upcoming':
          return { loading, children: 'Coming soon', disabled: true, type: 'default' };
      }
    },
    [],
  );

  const generateCalendarOptionProperties = useCallback(
    (option: CalendarOptionType): ComponentProps<typeof CalendarOption> => {
      const buttonProperties = generateCalendarButtonProperties(option);

      return {
        icon: option.icon,
        title: option.title,
        button: buttonProperties,
      };
    },
    [generateCalendarButtonProperties],
  );

  return useMemo(
    () => ({
      generateCalendarButtonProperties,
      generateCalendarOptionProperties,
    }),
    [generateCalendarButtonProperties, generateCalendarOptionProperties],
  );
};
