import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { TextInput, SelectInput, Button, Form, Row, Col, Title, Text } from 'ui';

type P = {
  name: string;
  defaultQuestion: any;
  maxLength?: number;
};

const PollQuestions = ({ name, defaultQuestion, maxLength = 90 }: P) => {
  const { t } = useTranslation();

  const renderChoice = (field: any, removable: boolean, remove: (name: number) => void, autoFocus: boolean) => {
    return (
      <Row item={{ gutter: 16 }} key={field.key}>
        <Col item={{ span: 21 }}>
          <Form.Item required={false} key={field.key} className="poll__question">
            <TextInput
              item={{
                ...field,
                name: [field.name, 'name'],
                rules: [
                  { required: true, message: t('Answer is required'), whitespace: true },
                  {
                    max: maxLength,
                    message: t('Answer cannot be longer than {{maxLength}} characters', { maxLength }),
                  },
                ],
              }}
              input={{
                autoFocus,
                placeholder: t('Answer...'),
              }}
            />
          </Form.Item>
        </Col>
        <Col item={{ span: 3, className: 'poll__delete' }}>
          {removable ? (
            <Button
              onClick={() => {
                remove(field.name);
              }}
              id="btn_delete-option"
              icon={<CloseCircleOutlined />}
              size="large"
            />
          ) : null}
        </Col>
      </Row>
    );
  };

  const renderQuestion = (field: any, removable: boolean, questionNumber: number, remove: (name: number) => void) => {
    return (
      <div key={`${field.name}-${questionNumber}`}>
        <div>
          <Title level={5}>
            {t('Question')} #{questionNumber}
          </Title>
        </div>
        <Form.Item required={false} key={field.key} className="poll">
          <Row item={{ gutter: 16, className: 'poll__question-wrapper' }}>
            <Col item={{ span: 14 }}>
              <TextInput
                item={{
                  ...field,
                  name: [field.name, 'question'],
                  label: 'Question',
                  rules: [
                    { required: true, message: t('Question is required'), whitespace: true },
                    {
                      max: maxLength,
                      message: t(`Question cannot be longer than {{maxLength}} characters`, { maxLength }),
                    },
                  ],
                }}
              />
            </Col>
            <Col item={{ span: 7 }}>
              <SelectInput
                item={{
                  name: [field.name, 'multiple'],
                  label: 'Type',
                  rules: [
                    {
                      required: true,
                      message: t('Type is required'),
                    },
                  ],
                }}
                input={{
                  placeholder: t('Select type'),
                  options: [
                    {
                      name: t('Single choice'),
                      value: false,
                    },
                    {
                      name: t('Multiple choice'),
                      value: true,
                    },
                  ],
                  getOptionLabel: (option) => option.name,
                  getOptionValue: (option) => option.value,
                  'data-test-id': 'poll_type',
                }}
              />
            </Col>
            <Col item={{ span: 3, className: 'poll__delete' }}>
              {removable ? (
                <CloseCircleOutlined
                  className="poll__delete__button"
                  onClick={() => {
                    remove(field.name);
                  }}
                  id="btn_delete-question"
                />
              ) : null}
            </Col>
          </Row>
          <Form.List name={[field.name, 'choices']}>
            {(fields, { add, remove }) => {
              const first = fields[0];
              const rest = fields.slice(1);
              return (
                <div className="poll__answers-wrapper">
                  <div className="poll__answers-title">
                    <Text weight="bold">Answers</Text>
                  </div>
                  {renderChoice(first, fields.length > 1, remove, false)}
                  {rest.map((field) => renderChoice(field, true, remove, true))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      size="large"
                      id="poll_add-option"
                    >
                      <PlusCircleOutlined /> {t('Add option')}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form.Item>
      </div>
    );
  };

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        const first = fields[0];
        const rest = fields.slice(1);

        return (
          <div>
            {renderQuestion(first, fields.length > 1, 1, remove)}
            {rest.map((field, index) => renderQuestion(field, true, index + 2, remove))}
            <Form.Item className="poll__add-question">
              <Button
                size="large"
                onClick={() => {
                  add(defaultQuestion);
                }}
                id="btn_add-question"
              >
                <PlusCircleOutlined /> {t('Add new question')}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default PollQuestions;
