import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { AdminAppConfigLayout } from '../../layouts/AdminAppConfigLayout';
import { withPermissions } from '../../atoms/auth';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { Loader } from '../../atoms/loader';
import { Icon } from '../../atoms/icon';
import { showSuccessErrorAlert, showAlert } from '../../atoms/alert';
import { AppConfigForm } from '../../atoms/app-config-form';
import { AppService } from '../../services/AppService';
import { Link } from 'ui';

export class Renderer extends React.Component {
  state = {
    appConfig: null,
  };

  componentDidMount() {
    this.init();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.appConfigName !== prevProps.match.params.appConfigName) {
      this.init();
      window.scrollTo(0, 0);
    }
  }

  init = async () => {
    await this.props.fetchOrganizations();
    const config = await this.props.findAppConfig();
    this.setState({ appConfig: config });
  };

  onUpdateSuccess = (response) => {
    showAlert({ type: 'success', message: <Trans>App config was successfully updated.</Trans> });
  };

  onUpdateAppConfig = async (values) => {
    const result = {
      ...values,
    };
    return this.props.updateAppConfig(this.state.appConfig.name, result);
  };

  onSubmitError() {
    showAlert({
      type: 'error',
      message: <Trans>Something went wrong, please try again later!</Trans>,
    });
  }

  deleteAppConfig = async () => {
    const response = await this.props.deleteAppConfig();

    showSuccessErrorAlert({
      isOk: response.ok,
      successMessage: <Trans>App config successfully deleted.</Trans>,
      errorMessage: <Trans>Cannot delete this app config right now, please try again later.</Trans>,
    });

    if (response.ok) {
      this.props.history.push(`/admin/app-configs`);
    }
  };

  renderForm = () => {
    if (this.props.loadingAppConfig) {
      return <Loader />;
    }

    return (
      <div className="app-config-form-wrapper">
        {!this.props.loadingAppConfig &&
        this.state.appConfig &&
        this.state.appConfig.name === this.props.match.params.appConfigName ? (
          <AppConfigForm
            appConfig={this.state.appConfig}
            onSubmit={this.onUpdateAppConfig}
            onSuccess={this.onUpdateSuccess}
            onError={this.onSubmitError}
            onDelete={this.deleteAppConfig}
            currentUser={this.props.user}
            organizations={this.props.organizations}
          />
        ) : null}
        {this.props.deletingAppConfig && (
          <div className="app-config-loader-wrapper">
            <Loader color="white" />
            <Text size={17} color="white">
              <Trans>It can take several minutes to delete app config. Please wait.</Trans>
            </Text>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <AdminAppConfigLayout>
        <Box>
          {withPermissions(
            [{ scope: 'superadmin' }],
            <Box flexDirection="row" justifyContent="flex-end">
              <Link to="/admin/app-configs/create" className="app-config-create">
                <button>
                  <Icon name="plus-bold" color="inherit" /> <Trans>Create New App Config</Trans>
                </button>
              </Link>
            </Box>,
            null,
          )}
          {this.renderForm()}
        </Box>
      </AdminAppConfigLayout>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  loadingAppConfig: state.loading.effects.adminAppConfigs.findAsync,
  deletingAppConfig: state.loading.effects.adminAppConfigs.deleteAsync,
  organizations: select.adminOrganizations.get(state),
});

const mapDispatch = (dispatch, props) => ({
  findAppConfig: () => dispatch.adminAppConfigs.findAsync(props.match.params.appConfigName),
  deleteAppConfig: () => dispatch.adminAppConfigs.deleteAsync(props.match.params.appConfigName),
  updateAppConfig: (name, payload) => AppService.update(name, payload),
  fetchOrganizations: () => dispatch.adminOrganizations.getAsync(),
});

export const PageAppConfigUpdate = connect(mapState, mapDispatch)(Renderer);
