import { AbstractEvent, type ReduxUser, type InvitationToken, type PublicEventContentObject } from 'models';
import { useTranslation } from 'react-i18next';
import { Alert } from 'ui';
import { SharedEventForm } from './SharedEventForm';

type P = {
  invitationToken: InvitationToken;
  eventContentObject: PublicEventContentObject;
  viewer?: ReduxUser;
};

const SharedEventEnter = ({ invitationToken, eventContentObject, viewer }: P) => {
  const { t } = useTranslation();
  const eventInviteRole = invitationToken.eventInviteeRole;

  if (!eventInviteRole) {
    return <Alert showIcon type="warning" description={t('Access token is expired or invalid.')} />;
  }

  if (eventContentObject.event.isBeforeOpenToJoin(eventInviteRole)) {
    return (
      <Alert
        showIcon
        type="info"
        description={t('Please come back up to {{count}} minutes before the event starts', {
          count: AbstractEvent.getEnterOffset(eventInviteRole) * -1,
        })}
      />
    );
  }

  if (eventContentObject.event.isAfterOpenToJoin) {
    return <Alert showIcon type="info" description={t('This event has ended')} />;
  }

  return <SharedEventForm invitationToken={invitationToken} eventContentObject={eventContentObject} viewer={viewer} />;
};

export default SharedEventEnter;
