import { Switch, Route } from 'react-router-dom';
import { PageRedirectToMobileApp } from 'pages';

type P = {
  platform: 'android' | 'ios';
  onContinueInBrowser: () => void;
};

export const MobileAppRouter = ({ platform, onContinueInBrowser }: P) => (
  <Switch>
    <Route
      path="*"
      render={() => <PageRedirectToMobileApp platform={platform} onContinueInBrowser={onContinueInBrowser} />}
    />
  </Switch>
);
