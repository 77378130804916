import { ContentLeft, Footer, Header, RedirectToMobileApp } from 'features/onboarding';
import { LandingLayout } from 'ui';

type P = {
  platform: 'android' | 'ios';
  onContinueInBrowser: () => void;
};

const PageRedirectToMobileApp = ({ platform, onContinueInBrowser }: P) => {
  return (
    <LandingLayout
      header={<Header items={[]} />}
      contentLeft={<ContentLeft />}
      contentRight={<RedirectToMobileApp platform={platform} onContinueInBrowser={onContinueInBrowser} />}
      footer={<Footer />}
    />
  );
};

export default PageRedirectToMobileApp;
