import cs from 'classnames';
import { type Community, type ReduxUser } from 'models';
import { TelehealthRequest } from 'features/events/components/Telehealth';
// TODO import from different feature
import { useCreateEventPermissions } from 'features/events/hooks';
import { Card, Col, Row, Title } from 'ui';

type P = {
  viewer: ReduxUser;
  community: Community;
};

const QuickActionsCard = ({ viewer, community }: P) => {
  const { joinedCommunities, isTelehealthButtonAvailable } = useCreateEventPermissions({ viewer, community });

  return (
    <>
      {isTelehealthButtonAvailable ? (
        <Card
          className={cs('quick-actions-card')}
          title={
            <Row item={{ justify: 'space-between', align: 'middle' }}>
              <Col>
                <Title level={5} className="quick-actions-card__title">
                  Quick Actions
                </Title>
              </Col>
            </Row>
          }
        >
          <TelehealthRequest
            viewer={viewer}
            communities={joinedCommunities}
            buttonType="primary"
            communityId={community.id}
          />
        </Card>
      ) : null}
    </>
  );
};

export default QuickActionsCard;
