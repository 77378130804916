import { useTranslation } from 'react-i18next';
import { Button, Col, LeftLine, RightLine, Row, Title } from 'ui';

type P = {
  title?: string;
  clickActions?: { onNext?: () => void; onPrevious?: () => void; onSeeAll?: () => void };
  queryData: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  isLoading?: boolean;
  hideArrows?: boolean;
};

const SliderControls = ({
  title,
  clickActions,
  queryData: { hasNextPage, hasPreviousPage },
  isLoading,
  hideArrows,
}: P) => {
  const { t } = useTranslation();
  const areArrowsVisible = (hasNextPage || hasPreviousPage) && !hideArrows;

  return (
    <Row item={{ justify: 'space-between', align: 'middle', className: 'slider-controls' }}>
      <Title level={3} className="slider-controls__title">
        {title ? t(title) : null}
      </Title>
      <Col item={{ span: 6 }}>
        <Row item={{ justify: 'end', align: 'middle' }}>
          {clickActions?.onSeeAll ? (
            <Button
              type="link"
              className="slider-controls__see-all-button"
              onClick={clickActions?.onSeeAll}
              size="large"
            >
              {t('See all')}
            </Button>
          ) : null}
          {areArrowsVisible ? (
            <>
              <Button
                icon={<LeftLine />}
                className={'slider-controls__arrow-button'}
                onClick={clickActions?.onPrevious}
                disabled={!hasPreviousPage || isLoading}
                size="small"
              />
              <Button
                icon={<RightLine />}
                className="slider-controls__arrow-button"
                onClick={clickActions?.onNext}
                disabled={!hasNextPage || isLoading}
                size="small"
              />
            </>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default SliderControls;
