import { type Profile, type Consent, type Entity } from 'models';
import { useProfileQuery } from 'features/user-profile/queries';
import { Modal, Skeleton } from 'ui';
import { ConsentsForm } from '../components';
import { useConsentsQuery } from '../queries';

type P = {
  isOpened: boolean;
  entity: Entity;
  requiredConsentIds: string[];
  onAccept: () => Promise<void> | void;
  onReject: () => Promise<void> | void;
  onClose: () => Promise<void> | void;
  zIndex?: number;
};

const ConsentsModal = ({ isOpened, entity, requiredConsentIds, onAccept, onReject, onClose, zIndex }: P) => {
  const { data: profile, isLoading: isLoadingProfile, isFetching: isFetchingProfile } = useProfileQuery();
  const {
    data: consents,
    isLoading: isLoadingConsents,
    isFetching: isFetchingConsents,
  } = useConsentsQuery({ consentIds: requiredConsentIds });

  const renderForm = ({ consents, profile }: { consents: Consent[]; profile: Profile }) => {
    return (
      <ConsentsForm
        onAccept={onAccept}
        onReject={onReject}
        consents={consents}
        currentAcceptedConsents={profile.usersConsents}
        entities={[entity]}
        rejectTitle="Not now"
        showTitle={false}
        infoMessage="To join this community, you must agree with policies. Without consent, you cannot join the community."
      />
    );
  };

  return (
    <Modal
      title={'Confirm Consents'}
      isOpened={isOpened}
      disableBack
      onCancel={onClose}
      maskClosable={false}
      zIndex={zIndex}
    >
      {(isLoadingProfile && isFetchingProfile) || !profile || (isLoadingConsents && isFetchingConsents) || !consents ? (
        <Skeleton active paragraph={{ rows: 14 }} />
      ) : (
        renderForm({ consents, profile })
      )}
    </Modal>
  );
};

export default ConsentsModal;
