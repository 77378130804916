import { AppLayout } from 'old/layouts/AppLayout';
import { Channels } from 'features/channels';
import { useGetPathToEntityDetail } from '../../hooks';
import {
  useNavigateToJoinedCommunities,
  useNavigateToOrganizationChannel,
  useNavigateToJoinedPrograms,
  useNavigateToChannelDetail,
} from '../hooks';

const PageChannels = () => {
  const navigateToJoinedCommunities = useNavigateToJoinedCommunities();
  const navigateToJoinedPrograms = useNavigateToJoinedPrograms();
  const navigateToOrganizationChannel = useNavigateToOrganizationChannel();
  const navigateToChannelDetail = useNavigateToChannelDetail();
  const getPathToEntityDetail = useGetPathToEntityDetail();

  return (
    <AppLayout
      center={
        <Channels
          navigateToJoinedCommunities={navigateToJoinedCommunities}
          navigateToJoinedPrograms={navigateToJoinedPrograms}
          navigateToOrganizationChannel={navigateToOrganizationChannel}
          navigateToChannelDetail={navigateToChannelDetail}
          getPathToEntityDetail={getPathToEntityDetail}
        />
      }
    />
  );
};

export default PageChannels;
