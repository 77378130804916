import * as React from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ThemeConsumer } from '../theme';
import { EventAvatar } from '../events';
import { Box } from '../box';
import { Text } from '../text';
import { images } from 'common/utils';

const PADDING = 20;

export class Renderer extends React.Component {
  renderCoverPhoto() {
    const event = this.props.item.event || this.props.item;

    return (
      <div className="renderer-core__cover-photo-wrapper">
        <img src={event.cover_photo?.url ?? images.default.eventCoverPhoto} alt={this.props.item.name} />
      </div>
    );
  }

  renderCalendarPanel() {
    const { isPublic } = this.props;
    const event = this.props.item.event || this.props.item;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div style={{ cursor: !isPublic && 'pointer' }} onClick={() => this.props.onRequestEventDetail(event.id)}>
            <Box flexDirection="row" alignItems="center" style={{ marginRight: 15, paddingBottom: 20 }}>
              <EventAvatar size="md" date={event.start_time} />
              <div className="renderer-core__event-name">
                <Text color="#455A64" size="14px" component="p" weight="600" style={{ marginBottom: 5 }}>
                  {this.props.item.name}
                </Text>
                <Text component="p" color={theme.color.lightGray}>
                  {this.props.t('dateFormatShortMonthDayYearTime', {
                    date: new Date(event.start_time),
                  })}
                </Text>
              </div>
            </Box>
          </div>
        )}
      </ThemeConsumer>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={classNames({
            'renderer-core__cover-photo': !this.props.isPublic,
          })}
          onClick={() => this.props.onRequestEventDetail(this.props.item.event.id)}
        >
          {this.renderCoverPhoto()}
        </div>
        <div
          className="renderer-core"
          style={{ paddingLeft: PADDING, paddingRight: PADDING, paddingTop: 15, width: '100%' }}
        >
          {this.renderCalendarPanel()}
        </div>
      </React.Fragment>
    );
  }
}

export const EventRendererCore = withTranslation()(Renderer);
