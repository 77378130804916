const events = {
  chatroom: {
    removed: 'chatrooms removed',
    messages: {
      created: 'chatrooms/:chatroomId/messages created',
    },
    members: {
      created: 'chatrooms/:id/members created',
      removed: 'chatrooms/:id/members removed',
    },
  },
};

export default events;
