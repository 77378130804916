import * as React from 'react';

// $FlowMissingDefinition: forwardRef
export const Input = React.forwardRef((props, ref) => (
  <input {...props} ref={ref} className={`text-input ${props.className}`} />
));

// $FlowMissingDefinition: forwardRef

class Area extends React.Component {
  textArea = null;

  componentDidMount() {
    this.handleKeyDown({ target: this.textArea });
  }

  handleKeyDown = (e) => {
    e.target.style.height = 'inherit';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      e.target.scrollHeight -
      10 +
      parseInt(computed.getPropertyValue('padding-bottom'), 10);

    e.target.style.height = `${height}px`;
  };

  render() {
    return (
      <textarea
        rows={1}
        {...this.props}
        ref={(ref) => (this.textArea = ref)}
        onKeyDown={this.handleKeyDown}
        className={`text-input text-input--textarea ${this.props.className}`}
      />
    );
  }
}

export const Textarea = React.forwardRef((props, ref) => {
  return <Area ref={ref} {...props} />;
});
