import { type EntityOptions } from 'models';
import { Form, Loading } from 'ui';
import { ChannelsOrganizationSelect } from './ChannelsOrganizationSelect';
import { CustomChannels } from './CustomChannels';
import { DefaultChannels } from './DefaultChannels';
import { useOrganizationsQuery } from './queries';

type ChannelsFormData = {
  organizationId: string;
};

type P = {
  navigateToJoinedCommunities: () => void;
  navigateToJoinedPrograms: () => void;
  navigateToOrganizationChannel: (id: string, scope: 'campaigns' | 'communities' | 'events' | 'programs') => void;
  navigateToChannelDetail: (id: string) => void;
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
};

const Channels = ({
  navigateToJoinedCommunities,
  navigateToJoinedPrograms,
  navigateToOrganizationChannel,
  navigateToChannelDetail,
  getPathToEntityDetail,
}: P) => {
  const [form] = Form.useForm();
  const { data: channelsOrganizations, isLoading: isChannelsLoading } = useOrganizationsQuery();

  if (isChannelsLoading || !channelsOrganizations) {
    return <Loading />;
  }

  return (
    <Form<ChannelsFormData> form={form} initialValues={{ organizationId: channelsOrganizations[0]?.id }}>
      <Form.Item name="organizationId">
        <ChannelsOrganizationSelect organizations={channelsOrganizations} />
      </Form.Item>

      <Form.Item<ChannelsFormData> noStyle dependencies={['organizationId']}>
        {({ getFieldsValue }) => {
          const { organizationId } = getFieldsValue(true);

          // TODO this in 3 times same logic. Should be hook
          const organization = channelsOrganizations.find(
            (channelOrganization) => channelOrganization.id === organizationId,
          );

          if (organization?.customChannels) {
            return (
              <CustomChannels
                navigateToChannelDetail={navigateToChannelDetail}
                getPathToEntityDetail={getPathToEntityDetail}
                organizationId={organizationId}
              />
            );
          }

          const isDefaultOrganization = organization?.default;

          const organizationIdForRequests = isDefaultOrganization ? undefined : organizationId;

          return (
            <DefaultChannels
              organizationIdForRequests={organizationIdForRequests}
              organizationId={organizationId}
              navigateToJoinedCommunities={navigateToJoinedCommunities}
              navigateToJoinedPrograms={navigateToJoinedPrograms}
              navigateToOrganizationChannel={navigateToOrganizationChannel}
              getPathToEntityDetail={getPathToEntityDetail}
            />
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default Channels;
