import { nodeApiRoutes, nodeApiClient, type NodeApiClientResponse } from 'common/services';
import { type AccountDataObject, type NodeUserDataObject, type PaginatedDataObjects } from 'models/service';

export type GetPositionsDataObject = PaginatedDataObjects<AccountDataObject>;

type GetAccountsRequest = {
  type?: string;
  status?: string;
  $limit?: number;
  $skip?: number;
  $sort?: {
    position?: number;
  };
  'meta.ownerExternalIds'?: string;
};

export const getPositions = async (params: GetAccountsRequest) =>
  nodeApiClient.get<GetPositionsDataObject, NodeApiClientResponse<GetPositionsDataObject>>(
    nodeApiRoutes.accounts.find,
    {
      params,
    },
  );

export type GetUserDataObject = PaginatedDataObjects<NodeUserDataObject>;

export const getUser = async (id: string) => {
  return nodeApiClient.get<GetUserDataObject, NodeApiClientResponse<GetUserDataObject>>(
    nodeApiRoutes.members.getById(id),
  );
};
