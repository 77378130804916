import { parseISO } from 'date-fns';
import { type MetaItemTranslations } from 'models';
import { useQuery } from 'query';
import { type ChangeRequestStatus } from 'models/service';
import { type CreateEventChangeRequestDataObject, getChangeRequest } from '../services';
import { getCreateEventChangeRequest } from '.';

type TelehealthType = {
  name: string;
  translations: MetaItemTranslations;
};

type CreateEventChangeRequestMeta = {
  patientType: TelehealthType;
  visitType: TelehealthType;
  visitTypeOption: TelehealthType;
  visitTypeSubOption?: TelehealthType;
  visitDuration: number;
  visitDate: Date;
  textContent: string;
  htmlContent: string;
};

type CommonCreateEventChangeRequest = {
  id: string;
  subject: {
    subjectId: string;
    subjectType: string;
  };
  subjectUser: {
    id: string;
  };
  createdUser: {
    id: string;
  };
  kind: 'event_request_to_create';
};

export type DefaultCreateEventChangeRequest = CommonCreateEventChangeRequest & {
  status: Exclude<ChangeRequestStatus, 'approved'>;
  meta: CreateEventChangeRequestMeta;
};

export type ApprovedCreateEventChangeRequest = CommonCreateEventChangeRequest & {
  status: 'approved';
  meta: CreateEventChangeRequestMeta & { eventId: string };
};

export type CreateEventChangeRequest = ApprovedCreateEventChangeRequest | DefaultCreateEventChangeRequest;

const generateCreateEventChangeRequest = (data: CreateEventChangeRequestDataObject): CreateEventChangeRequest => {
  if (data.status === 'approved') {
    return {
      id: data.id,
      subject: {
        subjectId: data.subject.subject_id,
        subjectType: data.subject.subject_type,
      },
      subjectUser: {
        id: data.subject_user.id,
      },
      createdUser: {
        id: data.created_user.id,
      },
      kind: data.kind,
      status: data.status,
      meta: {
        eventId: data.meta.event_id,
        patientType: data.meta.patient_type,
        visitType: data.meta.visit_type,
        visitTypeOption: data.meta.visit_type_option,
        visitTypeSubOption: data.meta.visit_type_sub_option,
        visitDuration: data.meta.visit_duration,
        visitDate: parseISO(data.meta.visit_date),
        textContent: data.meta.text_content,
        htmlContent: data.meta.html_content,
      },
    };
  }

  return {
    id: data.id,
    subject: {
      subjectId: data.subject.subject_id,
      subjectType: data.subject.subject_type,
    },
    subjectUser: {
      id: data.subject_user.id,
    },
    createdUser: {
      id: data.created_user.id,
    },
    kind: data.kind,
    status: data.status,
    meta: {
      patientType: data.meta.patient_type,
      visitType: data.meta.visit_type,
      visitTypeOption: data.meta.visit_type_option,
      visitTypeSubOption: data.meta.visit_type_sub_option,
      visitDuration: data.meta.visit_duration,
      visitDate: parseISO(data.meta.visit_date),
      textContent: data.meta.text_content,
      htmlContent: data.meta.html_content,
    },
  };
};

// TODO fix duplicity with onboarding
export const useChangeRequestsQuery = (
  { changeRequestId }: { changeRequestId: string },
  options?: { useErrorBoundary: boolean },
) =>
  useQuery<CreateEventChangeRequest>(
    getCreateEventChangeRequest({ changeRequestId }),
    async () => {
      const response = await getChangeRequest({
        changeRequestId,
      });

      return generateCreateEventChangeRequest(response.data);
    },
    {
      refetchOnMount: 'always',
      ...options,
    },
  );
