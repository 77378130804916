import { ReactComponent as AppStoreButton } from 'assets/app-store-button.svg';
import { ReactComponent as GooglePlayButton } from 'assets/google-play-button.svg';
import qs from 'qs';

export type Platform = 'android' | 'ios';

type P = {
  platform: Platform;
};

const StoreButton = ({ platform }: P) => {
  const generateLinkForButton = () => {
    switch (platform) {
      case 'android':
        return `https://play.google.com/store/apps/details?${qs.stringify({
          id: process.env.REACT_APP_ANDROID_PACKAGE,
          referrer: window.location.href,
        })}`;
      case 'ios':
        return `https://apps.apple.com/us/app/id${process.env.REACT_APP_IOS_APPSTORE_ID}`;
    }
  };

  const generateIconForButton = () => {
    switch (platform) {
      case 'android':
        return <GooglePlayButton />;
      case 'ios':
        return <AppStoreButton />;
    }
  };

  return <a href={generateLinkForButton()}>{generateIconForButton()}</a>;
};

export default StoreButton;
