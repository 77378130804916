import * as React from 'react';

import { Box } from '../../box';
import { Text } from '../../text';
import { Avatar } from 'ui';
import { ThemeConsumer } from '../../theme';
import { images } from 'common/utils';

export function CampaignItem(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box
          {...props.downshiftProps}
          flexDirection="row"
          alignItems="center"
          style={{ padding: '7px 15px', cursor: 'pointer' }}
        >
          <Box style={{ marginRight: 10 }}>
            <Avatar size={35} name={props.title} photo={props.itemProps.cover_photo ?? images.default.campaignCoverPhoto} />
          </Box>
          <Text component="p" size="14px" weight="500" color={theme.color.gray} lineHeight="19px">
            {props.itemProps.name}
          </Text>
        </Box>
      )}
    </ThemeConsumer>
  );
}
