import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const ContentObjectService = {
  find: (objectId, params) => http.get(`/content_objects/${objectId}`, params),
  generateThumbnail: (url) => http.post(`/content_objects/generate_thumbnail`, { url }),
  findEventContentObject: (eventId) => http.get(`/events/${eventId}`),
  getGlobalFeed: (params) => http.get(`/content_objects/feed`, params),
  getCommunityFeed: (community_id, params) => http.get(`/content_objects`, { ...params, community_id: community_id }),
  getCurriculumFeed: (program_id, params) => http.get(`/content_objects`, { ...params, program_id: program_id }),
  getBookmarkedFeed: (params) => http.get(`/content_objects/bookmarks`, params),
  getJournalFeed: (params) => http.get(`/posts/journal`, params),
  create: (payload) => http.post(`/posts`, { post: payload }),
  createComment: (payload) => http.post(`/content_objects`, { content_object: payload }),
  update: (payload) => http.put(`/posts/${payload.post.id}`, { post: payload }),
  updateComment: (payload) => http.put(`/content_objects/${payload.id}`, { content_object: payload }),
  delete: (id) => http.delete(`/posts/${id}`),
  deleteComment: (id) => http.delete(`/content_objects/${id}`),
  deleteActivity: (id) => http.delete(`/activities/${id}`),
  deletePoll: (id) => http.delete(`/polls/${id}`),
  deleteEvent: (id) => http.delete(`/events/${id}`),
  deleteOrderPost: (id) => http.delete(`/withings_order_posts/${id}.json`),
  like: (id) => http.post(`/content_objects/${id}/like`),
  unlike: (id) => http.delete(`/content_objects/${id}/like`),
  bookmark: (id) => http.post(`/content_objects/${id}/bookmark`),
  unbookmark: (id) => http.delete(`/content_objects/${id}/bookmark`),
  vote: (choiceId) => http.post(`/poll_choices/${choiceId}/vote`),
  unvote: (choiceId) => http.delete(`/poll_choices/${choiceId}/vote`),
  reportAbuse: (id) => http.post(`/abuse_reports`, { abuse_report: { content_object_id: id } }),
  createPoll: (poll) => http.post(`/polls`, { poll }),
  findPoll: (pollId) => http.get(`/polls/${pollId}`),
  findActivity: (activityId) => http.get(`/activities/${activityId}`),
  findPost: (postId) => http.get(`/posts/${postId}`),
  findOrderPost: (orderPostId) => http.get(`/withings_order_posts/${orderPostId}`),
  createKudos: (kudo) => http.post(`/kudos`, { kudo: kudo }),
  findKudos: (id) => http.get(`/kudos/${id}`),
  updateKudos: (kudo) => http.put(`/kudos/${kudo.id}`, { kudo: kudo }),
  deleteKudos: (id) => http.delete(`/kudos/${id}`),
};
