import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Trans, withTranslation } from 'react-i18next';
import { lastDayOfMonth, startOfMonth } from 'date-fns';
import { debounce } from 'throttle-debounce';
import { select } from '@rematch/select';

import { Loader } from '../../atoms/loader';
import { CampaignsLayout } from '../../layouts/CampaignsLayout';
import { CampaignsSideNav } from '../admin-page-campaigns/CampaignsSideNav';
import { ThemeConsumer } from '../../atoms/theme';
import { Avatar } from 'ui';
import { InViewport } from '../../atoms/in-viewport';
import { SearchInputAnimated } from '../../atoms/search-input';
import { CampaignFilter } from '../../atoms/campaign-filter';
import { DateRangePicker } from '../../atoms/date-range-picker';
import { OrganizationsFilter } from '../../atoms/organizations-filter';
import { Export } from '../../atoms/export';
import { images } from 'common/utils';

class Renderer extends React.Component {
  state = {
    donationsGrupedByDate: [],
    donations: [],
    skip: 0,
    detailsShow: {},
    hasMoreObjects: true,
    isSearchFocused: false,
    campaigns: [],
    communities: [],
  };

  groupsByDate = (data) => {
    let i = -1;
    let previousDate = '';
    const detailsShow = {};

    const donationsGrupedByDate = data.reduce((groups, game) => {
      const date = game.created_at.split('T')[0];
      if (date !== previousDate) {
        previousDate = date;
        i++;
      }

      if (!groups[i]) {
        groups[i] = [];
      }
      groups[i].push(game);

      if (!(game.id in detailsShow)) {
        detailsShow[game.id] = false;
      }

      return groups;
    }, []);

    return [donationsGrupedByDate, detailsShow];
  };

  translateInterval = (interval) => {
    switch (interval) {
      case 'one-time':
        return <Trans>One Time</Trans>;
      case 'monthly':
        return <Trans>Monthly</Trans>;
      case 'quarterly':
        return <Trans>Quarterly</Trans>;
      case 'annually':
        return <Trans>Annually</Trans>;
      default:
        return <Trans>Monthly</Trans>;
    }
  };

  showDetails(donation) {
    const detailsShow = this.state.detailsShow;
    detailsShow[donation.id] = !detailsShow[donation.id];
    this.setState({ detailsShow });
  }

  getShowDetailButton = (donation) => {
    if (this.state.detailsShow[donation.id]) {
      return (
        <div>
          <Trans>Less details</Trans>
          <img alt="sort" src="/assets/arrow-up.svg" className="donation-report__arrow-down" />
        </div>
      );
    }
    return (
      <div>
        <Trans>More details</Trans>
      </div>
    );
  };

  filterObjects = (skip, nextDonations) => {
    const newDonations = this.state.donations.concat(nextDonations);
    const [donationsGrupedByDate, detailsShow] = this.groupsByDate(newDonations);

    this.setState({ skip, donationsGrupedByDate, detailsShow, donations: newDonations });
  };

  getNextObjects = async () => {
    if (!this.props.loading) {
      const skip = this.state.skip + 10;
      const nextDonations = (await this.props.getDonations(10, skip, this.props.filter)).data;
      if (nextDonations.length < 10) {
        this.setState({ hasMoreObjects: false }, () => this.filterObjects(skip, nextDonations));
      } else {
        this.filterObjects(skip, nextDonations);
      }
    }
  };

  onChangeSearch = debounce(550, (value) => {
    this.props.setFilter({ search: value });
    this.setState(
      { skip: -10, donationsGrupedByDate: [], detailsShow: {}, donations: [], hasMoreObjects: true },
      this.getNextObjects,
    );
  });

  onChangeCampaigns = (chosenCampaigns) => {
    this.props.setFilter({ campaignIds: chosenCampaigns.map((chosenCampaign) => chosenCampaign.id) });
    this.setState(
      { skip: -10, donationsGrupedByDate: [], detailsShow: {}, donations: [], hasMoreObjects: true },
      this.getNextObjects,
    );
  };

  onChangeCommunities = (chosenCommunities) => {
    this.props.setFilter({ communityIds: chosenCommunities.map((chosenCommunity) => chosenCommunity.id) });
    this.setState(
      { skip: -10, donationsGrupedByDate: [], detailsShow: {}, donations: [], hasMoreObjects: true },
      this.getNextObjects,
    );
  };

  onChangeDates = (fromDate, toDate) => {
    this.props.setFilter({ from: fromDate, to: toDate });
    this.setState(
      { skip: -10, donationsGrupedByDate: [], detailsShow: {}, donations: [], hasMoreObjects: true },
      this.getNextObjects,
    );
  };

  async componentDidMount() {
    await this.props.setFilter({
      from: startOfMonth(new Date()),
      to: lastDayOfMonth(new Date()),
      search: '',
      campaignIds: [],
      communityIds: [],
    });

    const donations = (await this.props.getDonations(10, 0, this.props.filter)).data;
    const [donationsGrupedByDate, detailsShow] = this.groupsByDate(donations);

    this.setState(
      {
        donationsGrupedByDate,
        detailsShow,
        donations,
      },
      async () => {
        this.setState({
          campaigns: (await this.props.getCampaigns()).data,
          communities: (await this.props.getCommunities()).data,
        });
      },
    );
  }

  render() {
    return (
      <CampaignsLayout
        navigation={<CampaignsSideNav scope={'donation-report'} />}
        campaignsByOrganization={this.props.campaignsByOrganization}
      >
        <ThemeConsumer>
          {() => (
            <div id="donations-report-content">
              <div className="donation-report-filter" id="donations-report-filter">
                <div className="donation-report-filter__heading">
                  <Trans>FILTER BY:</Trans>
                </div>

                <div className="donation-report-filter__selectors">
                  <div className={`donation-report-filter__filter ${this.state.isSearchFocused ? 'focused' : ''}`}>
                    <CampaignFilter onChange={this.onChangeCampaigns} campaigns={this.state.campaigns} />
                    <DateRangePicker onChange={this.onChangeDates} />
                    <OrganizationsFilter onChange={this.onChangeCommunities} communities={this.state.communities} />
                  </div>

                  <div className={`donation-report-filter__search ${this.state.isSearchFocused ? 'focused' : ''}`}>
                    <SearchInputAnimated
                      onChangeFocus={(isSearchFocused) => this.setState({ isSearchFocused })}
                      onChange={this.onChangeSearch}
                      id="donation-report-search-input"
                    />
                    {!this.state.isSearchFocused && (
                      <Export donations={this.state.donations} filter={this.props.filter} />
                    )}
                  </div>
                </div>
              </div>

              {this.state.donationsGrupedByDate.map((donationsGroup, i) => (
                <div key={i} className="donation-report">
                  <div className="donation-report__group-header">
                    {this.props.t('dateFormatShortMonthDayYear', { date: new Date(donationsGroup[0].created_at) })}
                  </div>
                  <div>
                    {donationsGroup.map((donation) => (
                      <div key={donation.id} className="donation-report__body-item">
                        <div className="donation-report__donation-photo">
                          <Avatar size={30} photo={donation.user?.profile_photo ?? images.default.userProfilePhoto} />
                          <div className="donation-report__group-line" />
                        </div>

                        <div className="donation-report__info-container">
                          <div className="donation-report__line">
                            <div className="donation-report__name">
                              {donation.first_name + ' ' + donation.last_name}{' '}
                              <span className="donation-report__name__text">
                                <Trans>donated for campaign</Trans>
                              </span>
                            </div>
                            <div className="donation-report__amount">
                              {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                donation.amount,
                              )}
                              <br />
                              <span className="donation-report__amount__interval">
                                {this.translateInterval(donation.interval)}
                              </span>
                            </div>
                          </div>

                          <div className="donation-report__line">
                            <div className="donation-report__description">{donation.campaign.name}</div>
                          </div>

                          <div className="donation-report__line">
                            <div className="donation-report__community">
                              {donation.campaign.community.profile_photo && (
                                <img
                                  src={donation.campaign.community.profile_photo?.url ?? images.default.communityCoverPhoto}
                                  alt="Community cover"
                                  className="donation-report__community-cover"
                                />
                              )}
                              {donation.campaign.community.name}
                            </div>

                            <div className="donation-report__details" onClick={() => this.showDetails(donation)}>
                              {this.getShowDetailButton(donation)}
                            </div>
                          </div>

                          {this.state.detailsShow[donation.id] && (
                            <div className="donation-report__line">
                              <div className="donation-report__info">
                                <div className="donation-report__icon">
                                  <img alt="user icon" src="/assets/user.svg" />
                                </div>
                                <div>
                                  <div className="donation-report__info-header">
                                    <Trans>USER ADDRESS</Trans>
                                  </div>
                                  <br />
                                  <div className="donation-report__info-value">
                                    {donation.country}
                                    {donation.country && donation.apt ? ', ' : null}
                                    {donation.apt}
                                    <br />
                                    {donation.city}
                                    {donation.city && donation.state ? ', ' : null}
                                    {donation.state} {donation.zip}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {this.state.detailsShow[donation.id] && (
                            <div className="donation-report__line">
                              <div className="donation-report__info">
                                <div className="donation-report__icon">
                                  <img alt="user icon" src="/assets/mail.svg" />
                                </div>
                                <div>
                                  <div className="donation-report__info-header">
                                    <Trans>EMAIL ADDRESS</Trans>
                                  </div>
                                  <br />
                                  <div className="donation-report__info-value">{donation.email}</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </ThemeConsumer>

        {this.props.loading ? (
          <Loader />
        ) : this.state.hasMoreObjects ? (
          <InViewport key="donation-report-viewport" onEnter={this.getNextObjects} />
        ) : null}
      </CampaignsLayout>
    );
  }
}

const mapState = (state) => ({
  filter: state.donations.filter,
  loading: state.loading.effects.donations.get,
  campaignsByOrganization: select.campaigns.getBy(state, 'organization'),
});

const mapDispatch = (dispatch) => ({
  getDonations: (limit, skip, filter) => dispatch.donations.get({ limit, skip, filter }),
  setFilter: (filter) => dispatch.donations.setFilter(filter),
  getCampaigns: () => dispatch.campaigns.getAsync(),
  getCommunities: () => dispatch.communities.get(),
});

export const DonationReport = withTranslation()(connect(mapState, mapDispatch)(Renderer));
