import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { AdminLayout } from '../../layouts/AdminLayout';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { showAlert } from '../../atoms/alert';
import { OrganizationForm } from '../../atoms/organization-form';
import { CommunityForm } from '../../atoms/community-form';

export class Renderer extends React.Component {
  contentRef = React.createRef();

  state = {
    organizationResult: null,
    showCommunityForm: false,
  };

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  onCreateSuccess = (response) => {
    showAlert({ type: 'success', message: <Trans>Organization was successfully created.</Trans> });
  };

  onSubmitError(response) {
    showAlert({
      type: 'error',
      message: response ? response.data.message : <Trans>Something went wrong!</Trans>,
    });
  }

  buildNotificationsSettingsToObject(settings) {
    let result = {};
    Object.entries(settings).forEach(([key, value]) => {
      result[key] = {
        value: value,
        change_allowed: true,
      };
    });

    return result;
  }

  onCreateOrganization = (values) => {
    // $FlowLibIssue
    this.contentRef.current.scrollTop = 0;
    this.setState({ organizationResult: values, showCommunityForm: true });
    return { ok: true };
  };

  onCreateCommunity = async (values) => {
    const result = {
      ...this.state.organizationResult,
      default_community: values,
    };

    const response = await this.props.createOrganization(result);

    if (response.ok) {
      this.props.updateUser(response.data);
      this.props.history.push(`/admin/organizations/${response.data.id}/update`);
    } else {
      this.setState({
        showCommunityForm: false,
        organizationResult: {
          ...this.state.organizationResult,
          email_notifications_settings: this.buildNotificationsSettingsToObject(
            this.state.organizationResult.email_notifications_settings,
          ),
          push_notifications_settings: this.buildNotificationsSettingsToObject(
            this.state.organizationResult.push_notifications_settings,
          ),
        },
      });
    }
    return response;
  };

  render() {
    const { organizationResult, showCommunityForm } = this.state;
    return (
      <AdminLayout>
        <Box>
          <div style={{ overflow: 'visible', paddingRight: 10 }} ref={this.contentRef}>
            {!showCommunityForm ? (
              <React.Fragment>
                <OrganizationForm
                  organization={this.state.organizationResult}
                  onSubmit={this.onCreateOrganization}
                  onSuccess={() => {}}
                  currentUser={this.props.user}
                  onError={this.onSubmitError}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Text size="20px" color="#90a4ae" component="p" style={{ textAlign: 'center' }}>
                  <Trans>Create a Default Community</Trans>
                </Text>
                <CommunityForm
                  isDefaultCommunity
                  isMedicalOrganization={this.state.organizationResult.settings.allow_medical_community}
                  onSubmit={this.onCreateCommunity}
                  onSuccess={this.onCreateSuccess}
                  onError={this.onSubmitError}
                  currentUser={this.props.user}
                  organizations={organizationResult ? [organizationResult] : []}
                  organizationData={organizationResult}
                />
              </React.Fragment>
            )}
          </div>
        </Box>
      </AdminLayout>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  organization: select.adminOrganizations.find(state, props.match.params.organizationId),
});

const mapDispatch = (dispatch, props) => ({
  createOrganization: (payload) => dispatch.adminOrganizations.createAsync(payload),
  updateUser: (payload) => dispatch.session.updateUserOrganizationsForCreateCommunity(payload),
});

export const PageOrganizationCreate = connect(mapState, mapDispatch)(Renderer);
