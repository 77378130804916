import { type EventContentObject, InviteType, type CommunityListItem, type ReduxUser } from 'models';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosErrorHandler } from 'common/error-handlers';
import { type EventContentObjectDataObject } from 'models/service';

import { Modal, Skeleton, message, Space } from 'ui';

import { useEventQuery, useUpdateEvent, useCreateEvent } from '../../queries';
import { type DeprecatedEventProgramProperties } from '../../types';

import { DataStepEventForm, type DefaultEventData, type TelehealthEventData } from './DataStepEventForm';

type P = {
  eventId: string;
  isOpened: boolean;
  viewer: ReduxUser;
  program?: DeprecatedEventProgramProperties;
  communityId?: string;
  communities: CommunityListItem[];
  onCancel: () => void;
  onSuccess: (data: EventContentObjectDataObject) => Promise<void> | void;
  mode: 'duplicate' | 'edit';
};

const EditEventFormModal = ({
  eventId,
  isOpened,
  viewer,
  program,
  communityId,
  communities,
  onCancel,
  onSuccess,
  mode,
}: P) => {
  const { t } = useTranslation();
  const {
    data: eventContentObject,
    isLoading: isLoadingEvent,
    isFetching: isFetchingEvent,
    error: eventError,
  } = useEventQuery({ eventId }, { useErrorBoundary: false });
  const { isLoading: isUpdatingEvent, mutate: updateEvent } = useUpdateEvent();
  const { isLoading: isDuplicatingEvent, mutate: duplicateEvent } = useCreateEvent();

  const [inviteType, setInviteType] = useState<InviteType>(InviteType.empty);

  const isInviteOpened = Boolean(inviteType);

  const onModalBack = useMemo(() => {
    if (isInviteOpened) {
      return () => {
        setInviteType(InviteType.empty);
      };
    }

    return undefined;
  }, [isInviteOpened, setInviteType]);

  const getTitleName = () => {
    const eventType = eventContentObject?.event.eventType;

    if (isInviteOpened) {
      switch (inviteType) {
        case 'moderators':
          return 'Invite admins / hosts';
        case 'presenters':
          return eventType === 'telehealth_visit' ? 'Invite providers' : 'Invite presenters';
        case 'invitees':
          return eventType === 'telehealth_visit' ? 'Invite patients' : 'Invite attendees';
      }
    }

    if (mode === 'duplicate') {
      switch (eventType) {
        case 'conference':
          return 'Duplicate Video conference';
        case 'webinar':
          return 'Duplicate Webinar / Webcast';
        case 'telehealth_visit':
          return 'Duplicate Telehealth Visit';
        case undefined:
          return 'Duplicate';
      }
    }

    switch (eventType) {
      case 'webinar':
        return 'Edit Webinar / Webcast';
      case 'telehealth_visit':
        return 'Edit Telehealth Visit';
      case 'conference':
        return 'Edit Video conference';
      case undefined:
        return 'Edit';
    }
  };

  const onSubmit = (data: DefaultEventData | TelehealthEventData, eventContentObject: EventContentObject) => {
    if (mode === 'duplicate') {
      duplicateEvent(
        { data, communities },
        {
          onSuccess(data) {
            message.success(t('Event was duplicated.'));
            onSuccess(data.eventContentDataObject);
          },
          onError(error) {
            message.error(error.response?.data?.message ?? 'Failed to duplicate event.');
          },
        },
      );
    } else {
      updateEvent(
        { data, communities, eventContentObject },
        {
          onSuccess(data) {
            message.success(t('Event was updated.'));
            onSuccess(data.eventContentDataObject);
          },
          onError(error) {
            message.error(error.response?.data?.message ?? 'Failed to edit event.');
          },
        },
      );
    }
  };

  return (
    <Modal
      isOpened={isOpened}
      title={getTitleName()}
      onCancel={onCancel}
      disableBack={!onModalBack}
      onBackClick={onModalBack}
      closable={!isInviteOpened}
      className="edit-event-form-modal"
      destroyOnClose
    >
      <AxiosErrorHandler error={eventError} actions={false}>
        {(isLoadingEvent && isFetchingEvent) || !eventContentObject ? (
          <Space size="large" direction="vertical" className="edit-event-form-modal__skeleton">
            <Skeleton active paragraph={{ rows: 8 }} />
            <Skeleton active paragraph={{ rows: 14 }} />
            <Skeleton active paragraph={{ rows: 12 }} />
            <Skeleton active paragraph={{ rows: 10 }} />
            <Skeleton active paragraph={{ rows: 8 }} />
          </Space>
        ) : (
          <DataStepEventForm
            isLoading={isUpdatingEvent || isDuplicatingEvent}
            viewer={viewer}
            program={program}
            eventContentObject={eventContentObject}
            onSubmit={(data) => {
              onSubmit(data, eventContentObject);
            }}
            inviteType={inviteType}
            onInviteesTypeChanged={setInviteType}
            communities={communities}
            communityId={communityId}
            eventType={eventContentObject.event.eventType}
            mode={mode}
          />
        )}
      </AxiosErrorHandler>
    </Modal>
  );
};

export default EditEventFormModal;
