import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ListUserDataObject } from 'models/service';

type GetMembersParams = {
  limit?: number;
  cursor?: string;
};

export const getMembers = async (id: string, params: GetMembersParams) =>
  apiClient.get<ListUserDataObject[], ApiClientResponse<ListUserDataObject[]>>(routes.community.members(id), {
    params,
  });
