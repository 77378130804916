import { getInitials } from '../../utils';
import { ID } from '../types';

export class User {
  _id: ID;
  firstName: string;
  lastName: string;

  constructor({ _id, firstName, lastName }: { _id: ID; firstName: string; lastName: string }) {
    this._id = _id;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  getFullName = (): string => `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();

  getInitials = (): string => getInitials(this.firstName, this.lastName);
}
