import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Profile, User } from '../../../../common/models';
import { usersInChatroomState } from '../../../../recoil/selectors';
import { Modal } from '../../../../ui';
import UserCardTag from '../UserCardTag';
import { getFullName } from '../../../../models';

type P = {
  currentUserProfile: Profile;
  isOpen: boolean;
  onCancelClick: () => void;
};

const ModalViewMembers = ({ currentUserProfile, isOpen, onCancelClick }: P) => {
  const { t } = useTranslation(['translationChat']);
  const chatroomUsers = useRecoilValue(usersInChatroomState);

  const handleCancelClick = onCancelClick;

  const renderChatroomUsers = () => {
    return Object.keys(chatroomUsers).map((id) => {
      if (id === currentUserProfile._id) {
        return (
          <UserCardTag key={id} user={chatroomUsers[id]} name={`${t('chat.currentUserNameSuffix', { name: getFullName(chatroomUsers[id]) })}`} />
        );
      }

      return <UserCardTag key={id} user={chatroomUsers[id]} name={getFullName(chatroomUsers[id])} />;
    });
  };

  return (
    <Modal
      className="modal--view-members"
      open={isOpen}
      title={t('chat.chatroomDetail.header.modalViewMembers.title')}
      onCancel={handleCancelClick}
      footer={null}
      destroyOnClose
    >
      <div className="view-members__members">
        <span className="label--large view-members__title">
          {t('chat.chatroomDetail.header.modalManageMembers.listTitle')}
        </span>
        <div className="view-members__members__list">{renderChatroomUsers()}</div>
      </div>
    </Modal>
  );
};

export default ModalViewMembers;
