import { createModel } from '@rematch/core';
import { normalize, denormalize } from 'normalizr';

import { AdminService } from '../services/AdminService';
import { dispatch, getState } from '../store';
import { OrganizationService } from '../services/OrganizationService';
import { OrganizationSchema } from './schemas';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [OrganizationSchema] : OrganizationSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

const INITIAL_STATE = [];

export const model = createModel({
  name: 'adminOrganizations',
  state: INITIAL_STATE,
  reducers: {
    'session/reset': () => INITIAL_STATE,

    set: (state, payload) => {
      return payload;
    },

    push: (state, id) => {
      return state.includes(id) ? state : state.concat(id);
    },

    delete: (state, payload) => {
      return state.filter((id) => id !== payload.id);
    },
  },
  selectors: {
    find(state, id) {
      return denormalize(id, OrganizationSchema, getState().entities);
    },

    get(state) {
      return denormalize(state, [OrganizationSchema], getState().entities);
    },
  },
  effects: (dispatch) => ({
    async getAsync() {
      const response = await AdminService.getOrganizations();

      if (response.ok) {
        this.set(normalizeData(response.data));
      }

      return response;
    },

    async createAsync(payload) {
      const response = await OrganizationService.create(payload);

      if (response.ok) {
        this.push(normalizeData(response.data));
      }

      return response;
    },

    async updateAsync(params) {
      const { organization_id, payload } = params;
      const response = await OrganizationService.update(organization_id, payload);

      if (response.ok) {
        this.push(normalizeData(response.data));
      }

      return response;
    },

    async findAsync(id) {
      const response = await OrganizationService.getEdit(id);

      if (response.ok) {
        this.push(normalizeData(response.data));
      }

      return response;
    },

    async deleteAsync(id) {
      const response = await OrganizationService.delete(id);

      if (response.ok) {
        this.delete({ id });
      }

      return response;
    },
  }),
});
