import { type GreetingsScope } from '../Greetings';

export const useGreetingsScope = (): GreetingsScope => {
  const now = new Date();
  const hours = now.getHours();

  if (hours >= 5 && hours <= 11) {
    return 'morning';
  }

  if (hours >= 12 && hours <= 17) {
    return 'afternoon';
  }

  return 'evening';
};
