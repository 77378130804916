// Get feed name base on input criteria.
export function getFeedKey(props) {
  const targetVariant = props.community ? props.community.id : 'global';
  const pinnedVariant = props.pinned ? 'yes' : 'no';
  return props.type === 'bookmarks'
    ? 'bookmarks'
    : props.type === 'journal'
    ? 'journal'
    : `feed?pinned=${pinnedVariant}&community=${targetVariant}`;
}

// Every object is displayed in global feed, plus community feed (one or multiple).
// By default it is displayed in "unpinned" feed and possibly in "pinned" feed as well.
// Only add "pinned" name variantion if post is truly pinned to save state mutations.
export function getPossibleFeedKeys({ post_in_communities, pinned, feedType }) {
  const defaultNames = [getFeedKey({ community: null, pinned: false, type: feedType })];
  if (pinned) {
    defaultNames.push(getFeedKey({ community: null, pinned: true }));
  }

  return post_in_communities.reduce((prev, curr) => {
    prev.push(getFeedKey({ community: curr, pinned: false }));
    if (pinned) {
      prev.push(getFeedKey({ community: curr, pinned: true }));
    }
    return prev;
  }, defaultNames);
}
