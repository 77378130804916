import { useQuery } from 'query';
import { type Gender } from 'models/gender';
import { getGenders } from '../services';
import { getGendersQueryKey } from '.';

export const useGenders = () =>
  useQuery<Gender[]>(getGendersQueryKey(), async () => {
    const { data } = await getGenders();
    return data;
  });
