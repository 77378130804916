import * as React from 'react';
import { AutocompleteService } from '../../../services/AutocompleteService';
import { Autocomplete } from '../../autocomplete';
import { Avatar } from 'ui';
import { images } from 'common/utils';

export class MembersAutocomplete extends React.Component {
  itemToString = (item) => {
    return !item ? null : `${item.first_name} ${item.last_name}`;
  };

  renderItem = (item, itemAsString) => {
    const name = `${item.first_name} ${item.last_name}`;
    return !item ? null : (
      <div className="members-autocomplete__item">
        <div className="members-autocomplete__avatar">
          <Avatar name={name} photo={item.profile_photo ?? images.default.userProfilePhoto} size={28} />
        </div>
        {name}
      </div>
    );
  };

  getCommunityUsers = async (value) => {
    if (this.props.communities.length > 0) {
      const response = await AutocompleteService.getCommunityUsers({
        scope: 'communities',
        search: value,
        community_ids: this.props.communities.map((c) => c.id),
      });

      if (response.ok) {
        return response.data;
      }
    }

    return [];
  };

  onSelect = (item) => {
    this.props.onSelect(item);
  };

  render() {
    return (
      <div className="members-autocomplete">
        <Autocomplete
          disabled={this.props.disabled}
          dataForValue={this.getCommunityUsers}
          itemToString={this.itemToString}
          getRowId={(item) => item.id}
          placeholder={this.props.placeholder}
          onSelect={this.onSelect}
          renderItem={this.renderItem}
          defaultSelectedItem={this.props.value ? this.props.value : null}
        />
      </div>
    );
  }
}
