import { type ReduxUser } from 'models';
import { useOnRequestEnterConference } from 'features/events/hooks';
import { type Event } from 'features/todos/types';
import { Button, CamcorderLineIcon } from 'ui';

type P = {
  data: Event;
  viewer: ReduxUser;
};

const JoinEventItem = ({ data, viewer }: P) => {
  const { onRequestEnterConference, isJoining } = useOnRequestEnterConference();

  return (
    <Button
      type="primary"
      size="small"
      loading={isJoining}
      icon={<CamcorderLineIcon />}
      onClick={(event) => {
        event.preventDefault();
        onRequestEnterConference(data, viewer);
      }}
    >
      Join video
    </Button>
  );
};

export default JoinEventItem;
