import { type LocationDescriptor } from 'history';
import { type HTMLAttributeAnchorTarget, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'ui';

type P = {
  to: LocationDescriptor;
  text: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
  onClick?: () => Promise<void> | void;
};

const MenuLinkItem = ({ to, text, target, className, onClick }: P) => {
  const { t } = useTranslation();

  return (
    <Link
      to={to}
      target={target}
      className={className}
      onClick={async (event: MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
          event.preventDefault();
          await onClick();
        }
      }}
    >
      {t(text)}
    </Link>
  );
};

export default MenuLinkItem;
