import { useMutation } from 'query';
import { getUserExists } from '../../services';

type Data = undefined;

type Variables = {
  type: 'email' | 'phone';
  value: string;
};

export const useUserExists = () =>
  useMutation<Data, Variables>(
    async ({ type, value }: Variables) => {
      const response = await getUserExists({ [type]: value });
      return response.data;
    },
    {
      onError: undefined,
    },
  );
