import cs from 'classnames';
import { Icon } from 'old/atoms/icon';
import variables from 'common/styles/variables.json';
import { Image, Button } from 'ui';

type P = {
  src: string;
  alt: string;
  size?: 'large' | 'small';
  borderBottom?: boolean;
  onRequestEdit?: () => void;
};

const HeaderImage = ({ src, alt, size = 'large', borderBottom = true, onRequestEdit }: P) => {
  return (
    <div className="header-image">
      <Image
        src={src}
        alt={alt}
        rootClassName={cs('header-image__cover', {
          'header-image__cover--border-bottom': borderBottom,
          'header-image__cover--small': size === 'small',
        })}
        className="header-image__cover__content"
      />
      {onRequestEdit ? (
        <Button
          onClick={onRequestEdit}
          className="header-image__edit"
          icon={<Icon name="pen" size={15} color={variables.gray.value} />}
          size="large"
        />
      ) : null}
    </div>
  );
};

export default HeaderImage;
