import React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { dispatch } from '../../store';
import { SettingsSideNav } from '../page-settings/SettingsSideNav';
import { showAlert } from '../../atoms/alert';
import { TranslationsForm } from './TranslationsForm';
import { UserZoneLayout, TABS } from '../../layouts/UserZoneLayout';

export class PageTranslationSettingsRenderer extends React.Component {
  get settings() {
    return this.props.user.settings || {};
  }

  onSubmit = async (data, disableAlert) => {
    const response = await dispatch.session.updateUser({
      id: this.props.user.id,
      data: { settings: { translations: data } },
    });

    if (response.ok && !disableAlert) {
      showAlert({
        type: 'success',
        message: <Trans>Translation settings was successfully updated.</Trans>,
      });
    }

    return response;
  };

  render() {
    return (
      <UserZoneLayout navigation={<SettingsSideNav />} contentId={TABS.settings}>
        <TranslationsForm settings={this.settings.translations} onSubmit={this.onSubmit} />
      </UserZoneLayout>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const PageTranslationSettings = connect(mapState)(PageTranslationSettingsRenderer);
