import { useMutation } from '@tanstack/react-query';

import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { type RequestResetPassword } from '../../models';
import { requestResetPassword } from '../../services';

export const useRequestResetQuery = () =>
  useMutation<unknown, ApiClientResponseError<DefaultResponseErrorDataObject>, RequestResetPassword, any>({
    mutationFn: async (data: { email: string; domain: string }) => requestResetPassword(data),
  });
