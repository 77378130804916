import * as React from 'react';
import { Trans } from 'react-i18next';

import { Icon } from '../icon';

export function Files(props) {
  return props.attachments?.length ? (
    <div className="text-editor-files">
      <p className="text-editor-files__label">
        <Trans>Files</Trans>
      </p>
      {props.attachments.map((attachment, idx) => (
        <div key={attachment.id} className="text-editor-files__attachment" id={`uploaded-file[${idx}]`}>
          <p className="text-editor-files__attachment__name">{attachment.filename}</p>
          <button type="button" onClick={props.onRemove(attachment)} className="text-editor-files__attachment__close">
            <Icon name="close" />
          </button>
        </div>
      ))}
    </div>
  ) : null;
}
