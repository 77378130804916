import { type ComponentProps } from 'react';
import { GameIcon } from 'ui';
import { type WithingsOrderPostTodo } from '../../../../types';
import { BaseTodoWidgetItem } from '../BaseTodoWidgetItem';

type P = {
  type?: ComponentProps<typeof BaseTodoWidgetItem>['type'];
  todo: WithingsOrderPostTodo;
  className?: string;
};

const WithingsOrderPostTodoWidgetItem = ({ type, todo, className }: P) => {
  return (
    <BaseTodoWidgetItem
      type={type}
      className={className}
      path={todo.path}
      icon={<GameIcon />}
      title="Order device"
      description={todo.withingsOrderPost.title}
      additionalDescription={todo.withingsOrderPost.textContent}
      mode="by"
      time={todo.endTime}
    />
  );
};

export default WithingsOrderPostTodoWidgetItem;
