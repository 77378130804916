import * as React from 'react';

export function ColorPicker({ colors, onColorClick, rectSize, pickedColor }) {
  return (
    <div
      style={{
        paddingBottom: 15,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          height: rectSize,
          width: rectSize,
          backgroundColor: pickedColor,
          borderRadius: '5px',
          marginRight: 15,
        }}
      />
      {colors.map((color) => (
        <div
          key={color}
          onClick={() => onColorClick(color)}
          style={{
            height: rectSize,
            width: rectSize,
            backgroundColor: color,
            cursor: 'pointer',
            borderRadius: 5,
          }}
        />
      ))}
    </div>
  );
}
