import { type ReduxUser, type CommunityBase } from 'models';
import { canManageCommunity, canManageCommunityRedux } from './canManageCommunity';

export const canLeaveCommunityRedux = ({ viewer, community }: { viewer: ReduxUser; community: any }) =>
  viewer.joined_communities.length > 1 &&
  (Boolean(community.settings?.allow_unsubscribe) || canManageCommunityRedux({ viewer, community }));

export const canLeaveCommunity = ({ viewer, community }: { viewer: ReduxUser; community: CommunityBase }) =>
  viewer.joined_communities.length > 1 &&
  (Boolean(community.settings.allowUnsubscribe) || canManageCommunity({ viewer, community }));
