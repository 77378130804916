import { type ReduxUser, type CommunityBase } from 'models';

export const canManageCommunityRedux = ({ viewer, community }: { viewer: ReduxUser; community: any }) =>
  viewer.superadmin ||
  viewer.admin_organizations.includes(community.organization_id) ||
  viewer.admin_communities.includes(community.id);

export const canManageCommunity = ({ viewer, community }: { viewer: ReduxUser; community: CommunityBase }) =>
  viewer.superadmin ||
  viewer.admin_organizations.includes(community.organizationId) ||
  viewer.admin_communities.includes(community.id);
