import variables from 'common/styles/variables.json';
import { Col, Fieldset, Row, TextInput } from 'ui';

type NameFormValues = {
  firstName: string;
  middleName?: string;
  lastName: string;
};

type P = {
  withMiddleName?: boolean;
};

const NameFieldset = <FormValues extends NameFormValues>({ withMiddleName = true }: P) => {
  const span = withMiddleName ? 8 : 12;

  return (
    <Fieldset legend={{ title: 'Full name' }}>
      <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value] }}>
        <Col item={{ span }}>
          <TextInput<FormValues>
            item={{
              name: 'firstName',
              label: 'First name',
              rules: [
                {
                  required: true,
                },
              ],
            }}
            input={{
              autocomplete: 'given-name',
            }}
          />
        </Col>
        {withMiddleName ? (
          <Col item={{ span }}>
            <TextInput<FormValues>
              item={{
                name: 'middleName',
                label: 'Middle name',
              }}
            />
          </Col>
        ) : null}
        <Col item={{ span }}>
          <TextInput<FormValues>
            item={{
              name: 'lastName',
              label: 'Last name',
              rules: [
                {
                  required: true,
                },
              ],
            }}
            input={{
              autocomplete: 'family-name',
            }}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

export default NameFieldset;
