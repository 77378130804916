import {
  useQuery as useQueryReact,
  type QueryKey,
  type QueryFunction,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type ApiClientResponseError, type ResponseErrorDataObject } from 'common/services/api';

export const useQuery = <
  TQueryFunctionData,
  TError = ApiClientResponseError<ResponseErrorDataObject>,
  TData = TQueryFunctionData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  query: QueryFunction<TQueryFunctionData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFunctionData, TError, TData, TQueryKey>, 'queryFn' | 'queryKey'>,
) =>
  useQueryReact<TQueryFunctionData, TError, TData, TQueryKey>(key, query, {
    staleTime: 5 * 60 * 1000, // 5 Minutes
    cacheTime: 24 * 60 * 60 * 1000, // 1 Day
    ...options,
  });
