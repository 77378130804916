import { useRef, type ReactNode, useState, useEffect } from 'react';
import variables from 'common/styles/variables.json';
import { DownLine } from 'ui/Icons';
import { Button } from 'ui';

type P = {
  children: ReactNode;
};

const ReadMore = ({ children }: P) => {
  const containerReference = useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = useState(false);
  const [isForceExpanded, setIsForceExpanded] = useState(false);

  useEffect(() => {
    const element = containerReference.current;
    if (!element || isForceExpanded) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries, observer) => {
      const entry = entries[0];

      const shouldBeCollapsed = entry.contentRect.height > variables.collapsibleHeight.value;
      if (shouldBeCollapsed) {
        setIsHidden(shouldBeCollapsed);
        observer.unobserve(element);
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [children, isForceExpanded]);

  return (
    <div className="read-more" ref={containerReference} data-hidden={String(isHidden)}>
      {children}
      <Button
        className="read-more__button"
        icon={<DownLine />}
        type="link"
        onClick={() => {
          setIsHidden(false);
          setIsForceExpanded(true);
        }}
        size="large"
      >
        Show more
      </Button>
    </div>
  );
};

export default ReadMore;
