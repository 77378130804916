import { DefaultValue, selector, waitForAll } from 'recoil';
import { DataState, transformError } from '../../common/recoil/queries';
import { MessagesStateType } from '../../types';
import { activeChatroom, messagesLastCreatedAt } from '../atoms';
import { getMessages } from '../queries';

export const messagesState = selector<DataState<MessagesStateType>>({
  key: 'MessagesState',
  get: async ({ get }) => {
    const { lastCreatedAt, activeChatroomId } = get(
      waitForAll({
        lastCreatedAt: messagesLastCreatedAt,
        activeChatroomId: activeChatroom,
      }),
    );
    if (!activeChatroomId) {
      return { error: null, result: { messages: [], hasMoreMessages: false } };
    }

    const messagesPaginatedData = await getMessages(lastCreatedAt, activeChatroomId);

    if (messagesPaginatedData.error) {
      return transformError(messagesPaginatedData.error);
    }

    const { data: messages, total } = messagesPaginatedData.result;

    return {
      error: null,
      result: {
        messages,
        hasMoreMessages: total > messages.length,
      },
    };
  },
  // eslint-disable-next-line camelcase
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const messagesDependenciesState = selector<Date | undefined>({
  key: 'MessagesDependenciesState',
  get: ({ get }) => get(messagesLastCreatedAt),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(messagesLastCreatedAt, undefined);
    } else {
      set(messagesLastCreatedAt, newValue);
    }
  },
});
