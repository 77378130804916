import * as React from 'react';

import { Avatar, Row, Link } from 'ui';
import { CircleIcon } from '../../atoms/icon';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { ChatToButton } from '../../widgets/chat-to-button';
import { images } from 'common/utils';

export class AttendeeRow extends React.Component {
  onCreateMessage = (manager) => () => {
    const user = this.props.invitee;
    manager.sendMessageForRemoteUsers({}, [user]);
  };

  renderRsvpStatusIcon = (rsvpStatus) => {
    const currentRsvp = {
      going: {
        icon: 'check',
        color: 'green',
      },
      not_going: {
        icon: 'close',
        color: 'red',
      },
      pending: {
        icon: 'question-mark',
        color: 'lightGray',
      },
    }[rsvpStatus];

    return (
      <ThemeConsumer>
        {(theme) => (
          <CircleIcon
            size={22}
            name={currentRsvp.icon}
            color="white"
            background={theme.color[currentRsvp.color]}
            className="attendee-row__status-icon"
          />
        )}
      </ThemeConsumer>
    );
  };

  render() {
    const { viewer, invitee } = this.props;
    const fullName = `${invitee.first_name} ${invitee.last_name}`;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="attendee-row">
            <div className="attendee-row__cell attendee-row__cell--avatar">
              <div className="attendee-row__icon-wrapper">
                {invitee.id ? (
                  <Link to={`/members/${invitee.id}`} style={{ textDecoration: 'inherit' }}>
                    <Avatar
                      name={fullName}
                      photo={invitee.profile_photo ?? images.default.userProfilePhoto}
                      size={35}
                    />
                    {this.renderRsvpStatusIcon(invitee.rsvp)}
                  </Link>
                ) : (
                  <Row>
                    <Avatar photo={invitee.profile_photo ?? images.default.userProfilePhoto} size={35} />
                    {this.renderRsvpStatusIcon(invitee.rsvp)}
                  </Row>
                )}
              </div>
            </div>
            <div className="attendee-row__cell attendee-row__cell--name">
              {invitee.id ? (
                <Link to={`/members/${invitee.id}`} style={{ textDecoration: 'inherit' }}>
                  <Text color={theme.color.gray} size="15px" lineHeight="1">
                    {fullName}
                  </Text>
                </Link>
              ) : (
                <Text color={theme.color.gray} size="15px" lineHeight="1">
                  {invitee.email}
                </Text>
              )}
            </div>
            <div className="attendee-row__cell attendee-row__cell--message">
              {viewer.id !== invitee.id && <ChatToButton legacyUserId={viewer.id} />}
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}
