import { Space, Title, type ButtonProps, Text, Button } from 'ui';

type P = {
  title: string;
  description: string;
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
};

const InfoStep = ({ title, description, primaryButton, secondaryButton }: P) => {
  return (
    <Space direction="vertical" size="extra-large" block>
      <Title>{title}</Title>
      <Text>{description}</Text>
      <Space direction="vertical" size="middle" block>
        <Button type="primary" block {...primaryButton} />
        {secondaryButton ? <Button block {...secondaryButton} /> : null}
      </Space>
    </Space>
  );
};

export default InfoStep;
