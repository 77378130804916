import { apiClient, routes, type ApiClientResponse } from 'common/services';
import { type PaginatedRailsDataObjects } from 'models/service';
import { type TodoDataObject } from '../types/service';

type TodoRequestParams = {
  featured?: boolean;
  cursor?: string;
  limit?: number;
};

export const getTodos = async (params: TodoRequestParams) =>
  apiClient.get<
    PaginatedRailsDataObjects<TodoDataObject>,
    ApiClientResponse<PaginatedRailsDataObjects<TodoDataObject>>
  >(routes.todos.get, { params });
