import { type ReduxUser } from 'models';
import { type ComponentProps } from 'react';
import { type Todo } from '../../../types';
import { ActivityTodoWidgetItem } from './ActivityTodoWidgetItem';
import { type BaseTodoWidgetItem } from './BaseTodoWidgetItem';
import { EventTodoWidgetItem } from './EventTodoWidgetItem';
import { PollTodoWidgetItem } from './PollTodoWidgetItem';
import { PostTodoWidgetItem } from './PostTodoWidgetItem';
import { UserTodoWidgetItem } from './UserTodoWidgetItem';
import { WithingsOrderPostTodoWidgetItem } from './WithingsOrderPostTodoWidgetItem';

export type TodosWidgetItemType = ComponentProps<typeof BaseTodoWidgetItem>['type'];

type P = {
  type?: TodosWidgetItemType;
  todo: Todo;
  className?: string;
  viewer: ReduxUser;
};

const TodosWidgetItem = ({ type, todo, className, viewer }: P) => {
  switch (todo.type) {
    case 'Activity':
      return <ActivityTodoWidgetItem type={type} todo={todo} className={className} />;
    case 'Event':
      return <EventTodoWidgetItem type={type} todo={todo} className={className} viewer={viewer} />;
    case 'Poll':
      return <PollTodoWidgetItem type={type} todo={todo} className={className} />;
    case 'WithingsOrderPost':
      return <WithingsOrderPostTodoWidgetItem type={type} todo={todo} className={className} />;
    case 'Post':
      return <PostTodoWidgetItem type={type} todo={todo} className={className} />;
    case 'User':
      return <UserTodoWidgetItem type={type} todo={todo} className={className} />;
  }
};

export default TodosWidgetItem;
