import { useEffect, useState } from 'react';
import { CARDS_IN_ROW } from '../../..';
import { useChannelEntitiesQuery } from '../../../queries';

export const useFeaturedChannelEntities = ({ onSeeAll, channelId }: { onSeeAll?: () => void; channelId: string }) => {
  const [featured, setFeatured] = useState<boolean>(true);

  useEffect(() => {
    setFeatured(true);
  }, [channelId]);

  const query = useChannelEntitiesQuery({
    pageSize: CARDS_IN_ROW,
    channelId,
    featured,
  });

  const hasNoFeaturedChannelEntities = featured && onSeeAll && query.data?.pages[0]?.length === 0;
  if (hasNoFeaturedChannelEntities) {
    setFeatured(false);
  }

  return { featured, query };
};
