import * as React from 'react';
import { Trans } from 'react-i18next';

import { Loader } from '../loader';
import { BraintreeService } from '../../services/BraintreeService';

const CONTAINER_ID = 'braintree-dropin-ui-container';
const SCRIPT_ID = 'braintree-dropin-script';

export class CreditCardForm extends React.Component {
  state = {
    instance: undefined,
  };

  componentDidMount() {
    this.requestClientToken();
    this.injectBTScripts();
  }

  injectBTScripts() {
    if (!document.getElementById(SCRIPT_ID)) {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src = 'https://js.braintreegateway.com/web/dropin/1.13.0/js/dropin.min.js';
      if (document.head) {
        document.head.appendChild(script);
      }
    }
  }

  async requestClientToken() {
    const response = await BraintreeService.getClientToken();
    if (response.ok) {
      this.initCreditCardForm(response.data.token);
    } else {
      this.setState({ instance: null });
    }
  }

  initCreditCardForm = async (clientToken) => {
    const instance = await window.braintree.dropin.create({
      authorization: clientToken,
      container: `#${CONTAINER_ID}`,
    });
    this.setState({ instance: instance });
  };

  requestPaymentMethod = async () => {
    if (!this.state.instance) {
      throw new Error('Dropin UI not ready yet');
    }
    return this.state.instance.requestPaymentMethod();
  };

  render() {
    if (this.state.instance === null) {
      return <Trans>Count not process your request. Please try again later.</Trans>;
    }

    return (
      <React.Fragment>
        {this.state.instance === undefined && <Loader />}
        <div id={CONTAINER_ID} />
      </React.Fragment>
    );
  }
}
