import { parseISO } from 'date-fns';
import { type OnboardingTourStepDataObject, type OnboardingTourStepDataObjectOption } from './service';

const supportedCustomSteps = {
  intro: undefined,
  finish: undefined,
} as const;

const supportedOnboardingTourSteps = {
  ...supportedCustomSteps,
  profile: 'profile_completion',
  consents: 'consents',
  calendar: 'calendar_sync',
} as const;

export type CustomOnboardingTourStepOption = keyof typeof supportedCustomSteps;

export type OnboardingTourStepOption = keyof typeof supportedOnboardingTourSteps;

export type NonCustomOnboardingTourStepOption = Exclude<OnboardingTourStepOption, CustomOnboardingTourStepOption>;

export const isOnboardingTourStepOption = (value?: unknown): value is OnboardingTourStepOption => {
  return typeof value === 'string' ? value in supportedOnboardingTourSteps : false;
};

export const mapToOnboardingTourStepDataObjectOption = (
  step: NonCustomOnboardingTourStepOption,
): OnboardingTourStepDataObjectOption => {
  return supportedOnboardingTourSteps[step];
};

export const mapToNonCustomOnboardingTourStepOption = (
  step: OnboardingTourStepDataObjectOption,
): NonCustomOnboardingTourStepOption | undefined => {
  for (const [key, value] of Object.entries(supportedOnboardingTourSteps)) {
    if (value === step) {
      // TODO looks like Typescript issue
      return key as NonCustomOnboardingTourStepOption;
    }
  }

  return undefined;
};

export type OnboardingTourStep = {
  id: string;
  type: OnboardingTourStepDataObjectOption;
  order: number;
  status: 'completed' | 'pending';
  meta: {
    selectedCalendarOptions?: string[];
  };
  createdAt: Date;
  updatedAt: Date;
};

export const createOnboardingTourStep = (step: OnboardingTourStepDataObject): OnboardingTourStep => ({
  id: step.id,
  type: step.type,
  order: step.order,
  status: step.status,
  meta: {
    selectedCalendarOptions: step.meta.selected_calendar_options,
  },
  createdAt: parseISO(step.created_at),
  updatedAt: parseISO(step.updated_at),
});

export type SupportedOboardingTourStep = {
  step: NonCustomOnboardingTourStepOption;
  data: OnboardingTourStep;
};

export type UpdateOnboardingTourStepOptionHandlerOptions = {
  step: OnboardingTourStepOption;
  replace?: boolean;
};

export type UpdateOnboardingTourStepOptionHandler = (options: UpdateOnboardingTourStepOptionHandlerOptions) => void;
