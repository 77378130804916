import { t } from 'i18next';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import variables from 'common/styles/variables.json';
import { BellCircleIcon, Col, ListCircleIcon, MessagesCircleIcon, Row, Text } from 'ui';

type P = {
  unreadMessages: number;
  newNotifications: number;
  hideTodosFeedCta?: boolean;
};

const TodosWidgetEmptyState = ({ unreadMessages, newNotifications, hideTodosFeedCta }: P) => {
  const emptyStateAttributes = useMemo(() => {
    if (unreadMessages > 0) {
      return {
        icon: <MessagesCircleIcon />,
        title: t('Well done on finishing your To-Dos for today'),
        description: (
          <Trans t={t} i18nKey="There are unread messages waiting for your attention. See what’s happening there">
            There are <strong>{{ unreadMessages }}</strong> unread messages waiting for your attention. See what’s
            happening there.
          </Trans>
        ),
        linkLabel: t('Check my messages'),
        link: '/messages',
      };
    }

    if (newNotifications > 0) {
      return {
        icon: <BellCircleIcon />,
        title: t('Great job on completing your To-Dos for today'),
        description: (
          <Trans t={t} i18nKey="There are new notifications waiting for your attention. See what’s happening there">
            There are <strong>{{ newNotifications }}</strong> new notifications waiting for your attention. See what’s
            happening there.
          </Trans>
        ),
        linkLabel: t('Open notifications'),
        link: '/notifications',
      };
    }

    return {
      icon: <ListCircleIcon />,
      title: t('Your To-Do adventure starts here'),
      description: t(
        `Stay organized and accomplish tasks throughout the day. Explore more features as you complete your To-Dos or wait for new ones`,
      ),
      linkLabel: t('See what’s happening'),
      link: '/feed',
      hidden: hideTodosFeedCta,
    };
  }, [unreadMessages, newNotifications, hideTodosFeedCta]);

  return (
    <div className="todos-widget-empty-state">
      <Row
        item={{
          className: 'todos-widget-empty-state__content',
          align: 'middle',
          justify: 'center',
          gutter: [0, variables.spaceMd.value],
        }}
      >
        <Col item={{ span: 24 }}>{emptyStateAttributes.icon}</Col>
        <Col item={{ span: 24 }}>
          <Text strong>{emptyStateAttributes.title}</Text>
        </Col>
        <Col item={{ span: 24 }}>
          <Text>{emptyStateAttributes.description}</Text>
        </Col>
        {emptyStateAttributes.hidden ? null : (
          <Col item={{ span: 24 }}>
            <NavLink to={emptyStateAttributes.link}>{emptyStateAttributes.linkLabel}</NavLink>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default TodosWidgetEmptyState;
