import { Text } from 'ui';

export const ContentLeft = () => (
  <>
    <img
      className="onboarding-layout-content__left__bg-image"
      alt=""
      src={`/${process.env.REACT_APP_CONFIG}/landing.jpg`}
    />
    <div className="onboarding-layout-content__left__text">
      <Text className="onboarding-layout-content__left__text-inner" weight="bold">
        {process.env.REACT_APP_DESCRIPTION}
      </Text>
    </div>
  </>
);
