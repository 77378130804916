import { useMutation } from '@tanstack/react-query';
import { type Moment } from 'moment';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';

import { type WithingsOrderPostDataObject } from 'models/service/content-object-deprecated';
import { editWithingsOrderPost } from '../../services';

export const useEditWithingsOrderPostQuery = () =>
  useMutation<
    WithingsOrderPostDataObject,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      withingsPostOrderId: string;
      programId: string;
      programTopicId: string;
      publishAt: Moment;
      textContent: string;
      htmlContent: string;
      title?: string;
      todoDueDate?: Moment;
    }
  >({
    async mutationFn({
      withingsPostOrderId,
      programId,
      programTopicId,
      publishAt,
      textContent,
      htmlContent,
      title,
      todoDueDate,
    }) {
      const createOrderPostResponse = await editWithingsOrderPost({
        withingsPostOrderId,
        programId,
        programTopicId,
        publishAt,
        textContent,
        htmlContent,
        title,
        todoDueDate,
      });

      return createOrderPostResponse.data;
    },
  });
