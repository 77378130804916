import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../';

type P = {
  title: string;
  isOpen: boolean;
  isLoading: boolean;
  onOkClick: () => void;
  onCancelClick: () => void;
  children: ReactNode;
};

const ModalConfirmDelete: FC<P> = ({ title, children, isOpen, isLoading, onOkClick, onCancelClick }) => {
  const { t } = useTranslation(['translationChat']);
  const handleOkClick = onOkClick;
  const handleCancelClick = onCancelClick;

  return (
    <Modal
      className="modal--confirm-delete"
      open={isOpen}
      title={title}
      okText={t('chat.modal.confirmDelete.okText')}
      okButtonProps={{ loading: isLoading }}
      cancelText={t('chat.modal.confirmDelete.cancelText')}
      onOk={handleOkClick}
      onCancel={handleCancelClick}
      okType={'danger'}
      cancelButtonProps={{ type: 'default' }}
    >
      {children}
    </Modal>
  );
};

export default ModalConfirmDelete;
