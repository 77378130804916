import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { showAlert } from '../../atoms/alert';

export class Renderer extends React.Component {
  state = {
    object: null,
  };

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const response = await this.props.loadData();
    if (!response.ok) {
      showAlert({ type: 'error', message: response.data.message });
    }
  };

  render() {
    if (this.props.loading) {
      return this.props.renderLoading();
    } else if (this.props.object) {
      return this.props.renderLoaded(this.props.object, this.loadData);
    } else {
      return null;
    }
  }
}

const mapState = (state, props) => ({
  object: select.events.find(state, props.eventId),
  loading: state.loading.effects.events.findAsync,
});

const mapDispatch = (dispatch, props) => ({
  loadData: () => dispatch.events.findAsync(props.eventId),
});

export const EventFetcher = connect(mapState, mapDispatch)(Renderer);
