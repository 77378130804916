import { useQueryClient, useMutation } from '@tanstack/react-query';
import { EventContentObject, type CommunityListItem } from 'models';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
// TODO fix this import from different feature, probably target queries should be here instead
import { getEventsQueryKey, getEventQueryKey as getCommunityEventQueryKey } from 'features/communities/queries';
import { type EventContentObjectDataObject } from 'models/service';

import { getEventQueryKey } from '../';
import { type DefaultEventData, type TelehealthEventData } from '../../components';
import { useMapEventRequestData } from '../../hooks';
import { updateEvent } from '../../services';

export const useUpdateEvent = () => {
  const queryClient = useQueryClient();
  const { mapEventRequestData } = useMapEventRequestData();

  return useMutation<
    { eventContentObject: EventContentObject; eventContentDataObject: EventContentObjectDataObject }, // TODO need to return DataObject because redux for now
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      data: DefaultEventData | TelehealthEventData;
      communities: CommunityListItem[];
      eventContentObject: EventContentObject;
    }
  >({
    async mutationFn({ data, communities, eventContentObject }) {
      const networkData = mapEventRequestData(data, communities);
      const result = await updateEvent(eventContentObject.event.id, networkData);

      return {
        eventContentObject: new EventContentObject(result.data),
        eventContentDataObject: result.data,
      };
    },
    onSuccess(_, variables) {
      queryClient.invalidateQueries(getCommunityEventQueryKey({ eventId: variables.eventContentObject.event.id }));
      queryClient.invalidateQueries(getEventQueryKey({ eventId: variables.eventContentObject.event.id }));
      queryClient.invalidateQueries(getEventsQueryKey({ type: 'upcoming' }));
    },
    onError() {
      queryClient.invalidateQueries(getEventsQueryKey({}));
    },
  });
};
