import { type LoginTokenDataObject } from './service';

export class LoginToken {
  loginToken = '';

  constructor(data: LoginTokenDataObject) {
    Object.assign(this, data);

    if (data) {
      this.loginToken = data.login_token;
    }
  }
}
