import { Modal } from 'ui';
import ContactUsForm from './ContactUsForm';

type P = {
  isOpened: boolean;
  onClose: () => void;
  programId: string;
  userPhone?: string;
  onSuccess: (programId: string) => void;
};

const ContactUsModal = ({ isOpened, onClose, programId, userPhone, onSuccess }: P) => {
  return (
    <Modal disableBack destroyOnClose isOpened={isOpened} title="Contact me at" onCancel={onClose}>
      <ContactUsForm programId={programId} onSuccess={onSuccess} userPhone={userPhone} />
    </Modal>
  );
};

export default ContactUsModal;
