import * as React from 'react';
import { Icon } from '../icon';
import { ThemeConsumer } from '../theme';
import { Tooltip } from '../../../ui';

export function ControlIcon(props) {
  const { active, onClick, size, lineHeight, disabled, tooltip, ...iconProps } = props;
  return (
    <Tooltip item={{ title: tooltip }}>
      <div
        onMouseDown={
          disabled
            ? undefined
            : (evt) => {
                evt.preventDefault();
                onClick(evt);
              }
        }
        style={{ cursor: disabled ? undefined : 'pointer' }}
      >
        <Icon
          {...iconProps}
          className={disabled || !active ? 'control-icon__active' : 'control-icon__disabled'}
          size={size}
          style={{ lineHeight: lineHeight ? lineHeight : null }}
        />
      </div>
    </Tooltip>
  );
}

ControlIcon.defaultProps = {
  active: false,
  size: '18px',
  onClick: () => {},
};
