import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { markAsRead } from 'features/programs/services';

export const useMarkAsRead = () =>
  useMutation<
    unknown,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      postId: string;
    }
  >({
    async mutationFn({ postId }) {
      const response = await markAsRead({ id: postId });

      // TODO currently working with redux
      return response.data;
    },
  });
