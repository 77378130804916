import * as React from 'react';
import { Field } from 'formik';
import { Select } from './base-atoms/Select';
import { FieldError } from './FieldError';

export function SelectField(props) {
  return (
    <Field name={props.name}>
      {(fieldProps) => {
        const { getOptionLabel, getOptionValue, label, options, placeholder, onChange, mergeOnChange, ...htmlProps } =
          props;

        const overrideOptions = {};

        if (onChange) {
          if (mergeOnChange) {
            overrideOptions.onChange = (event) => {
              fieldProps.field.onChange(event);
              onChange(event);
            };
          } else {
            overrideOptions.onChange = onChange;
          }
        }

        return (
          <label className="select-field">
            <div className={'select-field__label'}>{label}</div>
            <div className="select-field__select">
              <Select {...htmlProps} {...fieldProps.field} {...overrideOptions} id={props?.id}>
                {placeholder && (
                  <option value="" disabled>
                    {placeholder}
                  </option>
                )}
                {options.map((option, idx) => (
                  <option key={getOptionValue(option)} value={getOptionValue(option)} data-test-id={`option_[${idx}]`}>
                    {getOptionLabel(option)}
                  </option>
                ))}
              </Select>
            </div>
            <FieldError name={props.name} />
          </label>
        );
      }}
    </Field>
  );
}
