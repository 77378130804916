import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { Profile, User } from '../../../../common/models';
import { users } from '../../../../common/recoil/atoms';
import { ID } from '../../../../common/types';
import UserCard from '../../../../components/UserCard';
import { activeChatroom, usersSearch } from '../../../../recoil/atoms';
import { activeChatroomState, usersInChatroomState, usersState } from '../../../../recoil/selectors';
import { Empty, LabeledValue, Modal, Select, SelectSearch } from '../../../../ui';
import UserCardTag from '../UserCardTag';
import { ReactComponent as ClearIcon } from './close-circle.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { Participant, getFullName } from '../../../../models';

type P = {
  currentUserProfile: Profile;
  isOpen: boolean;
  isLoading: boolean;
  onOkClick: (membersToAdd: Participant[], membersToRemove:| Participant[]) => void;
  onCancelClick: () => void;
};

const ModalManageMembers = ({ currentUserProfile, isOpen, isLoading, onOkClick, onCancelClick }: P) => {
  const { t } = useTranslation(['translationChat']);
  const activeChatroomId = useRecoilValue(activeChatroom);
  const activeChatroomData = useRecoilValue(activeChatroomState(activeChatroomId));
  const chatroomUsers = useRecoilValue(usersInChatroomState);
  const searchUsersData = useRecoilValueLoadable(usersState);
  const setUsersSearchState = useSetRecoilState(usersSearch);
  const [usersData, setUsersData] = useRecoilState(users);
  const [displayedUsers, setDisplayedUsers] = useState<Record<string, Participant>>(chatroomUsers);

  const originalChatroomUsers = activeChatroomData.participants;

  const currentChatroomUsersIds: ID[] = Object.keys(displayedUsers);

  const handleOnSearch = (value: string) => {
    setUsersSearchState(value);
  };

  // TODO use correct type for handleOfSelect function params
  const handleOnSelect = (value: string | number | LabeledValue) => {
    if (searchUsersData.state === 'hasValue' && !searchUsersData.contents.error) {
      const selectedUser = searchUsersData.contents.result.find((u: User) => u._id === value?.toString());
      if (selectedUser) {
        const updatedUsers: Record<string, Participant> = {};

        for (const [key,value] of Object.entries(displayedUsers)) {
          updatedUsers[key] = value
        }

        updatedUsers[selectedUser._id] = { _id: selectedUser._id, firstName: selectedUser.firstName, lastName: selectedUser.lastName }

        setDisplayedUsers(updatedUsers);
      }
    }
  };

  const handleMemberDelete = (user: Participant) => {
    const updatedUsers: Record<string, Participant> = {};

    for (const [key,value] of Object.entries(displayedUsers)) {
      updatedUsers[key] = value
    }

    if (updatedUsers[user._id]) {
      delete updatedUsers[user._id];
    }
    setDisplayedUsers(updatedUsers);
  };

  const handleOkClick = () => {
    for (const key in displayedUsers) {
      if (!usersData[displayedUsers[key]._id]) {
        setUsersData({
          ...usersData,
          [displayedUsers[key]._id]: displayedUsers[key],
        });
      }
    }

    onOkClick(
      Object.values(displayedUsers).filter((m) => !Object.values(originalChatroomUsers).some((user) => user._id === m._id)),
      Object.values(originalChatroomUsers).filter((m) => !Object.values(displayedUsers).some((user) => user._id === m._id)),
    );
  };

  const handleCancelClick = onCancelClick;

  const renderSelectError = () => (
    <Select.Option key={0} value={''} label={''}>
      <Empty />
    </Select.Option>
  );

  const renderSelectOptions = () => {
    switch (searchUsersData.state) {
      case 'hasValue':
        if (searchUsersData.contents.error) {
          return renderSelectError();
        }

        return searchUsersData.contents.result
          .filter((u: User) => !currentChatroomUsersIds.includes(u._id) && u._id !== currentUserProfile._id)
          .map((u: User) => (
            <Select.Option key={u._id} value={u._id} label={u.getFullName()}>
              <UserCard user={u} />
            </Select.Option>
          ));
      case 'loading':
        return undefined;
      case 'hasError':
        return renderSelectError();
    }
  };

  const renderChatroomUsers = () => {
    return Object.keys(displayedUsers).map((id) => {
      if (id === currentUserProfile._id) {
        return (
          <UserCardTag key={id} user={displayedUsers[id]} name={`${t('chat.currentUserNameSuffix', { name: getFullName(displayedUsers[id]) })}`} />
        );
      }

      return <UserCardTag key={id} user={displayedUsers[id]} name={getFullName(displayedUsers[id])} onCloseClick={handleMemberDelete} />;
    });
  };

  return (
    <Modal
      className="modal--manage-members"
      open={isOpen}
      title={t('chat.chatroomDetail.header.modalManageMembers.title')}
      okText={t('chat.chatroomDetail.header.modalManageMembers.okText')}
      cancelText={t('chat.chatroomDetail.header.modalManageMembers.cancelText')}
      onOk={handleOkClick}
      okButtonProps={{ loading: isLoading }}
      onCancel={handleCancelClick}
      okType='primary'
    >
      <div className="manage-members__search">
        <span className="label--large manage-members__title">
          {t('chat.chatroomDetail.header.modalManageMembers.searchTitle')}
        </span>
        <div className="manage-members__search__input">
          <SelectSearch
            allowClear
            autoClearSearchValue={false}
            showSearch
            className="select-search--manage-members reversed-icons"
            placeholder={t('chat.chatroomDetail.header.addMembersPlaceholder')}
            value={''}
            loading={searchUsersData.state === 'loading'}
            clearIcon={<ClearIcon />}
            suffixIcon={<SearchIcon />}
            onSearch={handleOnSearch}
            // @ts-ignore
            onSelect={handleOnSelect}
            createCustomOptions={renderSelectOptions}
          />
        </div>
      </div>
      <div className="manage-members__members">
        <span className="label--large manage-members__title">
          {t('chat.chatroomDetail.header.modalManageMembers.listTitle')}
        </span>
        <div className="manage-members__members__list">{renderChatroomUsers()}</div>
      </div>
    </Modal>
  );
};

export default ModalManageMembers;
