import { type Form, TimePicker } from 'antd';
import moment, { type Moment } from 'moment';
import type React from 'react';
import { Item } from './Item';

type ItemP = Omit<React.ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
};

type InputP = Omit<React.ComponentProps<typeof TimePicker>, 'css'> & {
  minimum?: Moment;
  allowPast?: boolean;
  fullWidth?: boolean;
  use12Hours?: boolean;
  format?: string;
  'data-test-id'?: string;
};

type P = {
  item: ItemP;
  input?: InputP;
};

const defaultInput: InputP = {};

const TimeInput = ({
  item,
  input: {
    minimum = moment(),
    allowPast,
    fullWidth = true,
    use12Hours = true,
    format = 'h:mm A',
    ...inputRest
  } = defaultInput,
}: P) => {
  return (
    <Item {...item}>
      <TimePicker
        className={fullWidth ? 'full-width' : undefined}
        use12Hours={use12Hours}
        format={format}
        {...inputRest}
      />
    </Item>
  );
};

export default TimeInput;
