import { message as messageAD } from 'antd';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

type Content = ReactElement | string;
type Duration = number;
type OnClose = () => void;

type LabelProperties = {
  label: Content;
};

const Text: FC<LabelProperties> = ({ label }) => {
  const { t } = useTranslation(['translationChat']);

  return typeof label === 'string' ? t(label) : label;
};

const message = {
  success: (content: Content, duration?: Duration, onClose?: OnClose) => {
    // eslint-disable-next-line promise/catch-or-return
    messageAD.success(<Text label={content} />, duration, onClose).then(
      () => ({}),
      (error) => console.error(error),
    );
  },
  error: (content: Content, duration?: Duration, onClose?: OnClose) => {
    // eslint-disable-next-line promise/catch-or-return
    messageAD.error(<Text label={content} />, duration, onClose).then(
      () => ({}),
      () => ({}),
    );
  },
  warn: (content: Content, duration?: Duration, onClose?: OnClose) => {
    // eslint-disable-next-line promise/catch-or-return
    messageAD.warning(<Text label={content} />, duration, onClose).then(
      () => ({}),
      () => ({}),
    );
  },
};

export default message;
