import { dispatch } from 'old/store';
import { useCallback, useEffect } from 'react';
import { Loading } from 'ui';
import { useLoginWithTokenQuery } from '../queries';

type P = {
  loginToken: string;
  onFinish: () => void;
};

const TokenLogin = ({ loginToken, onFinish }: P) => {
  const { mutateAsync: checkTokenValitidation } = useLoginWithTokenQuery();

  const checkToken = useCallback(async () => {
    try {
      // TODO refactor this somehow
      // It is now in `TokenLogin` `MagicLinkCheck` and almost same but old written logic in `Login`
      const loginData = await checkTokenValitidation({ token: loginToken });
      await dispatch.session.storeSuccessfulEmailResponse(loginData);
    } catch {}

    onFinish();
  }, [checkTokenValitidation, loginToken, onFinish]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return <Loading />;
};

export default TokenLogin;
