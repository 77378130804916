import { Typography } from 'antd';
import { type BaseType } from 'antd/es/typography/Base';
import cn from 'classnames';
import type React from 'react';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { generateTypographySizeClassName, type TypographySize } from './Typography';

type TextType = BaseType | 'title';

type P = Omit<React.ComponentProps<typeof Typography.Text>, 'type'> & {
  type?: TextType;
  weight?: 'bold' | 'medium';
  size?: TypographySize | number;
  children: ReactNode;
  withinLink?: { hasHoverEffect?: boolean };
};

const Text = ({ className, type, weight, size, children, withinLink, ...rest }: P) => {
  const { t } = useTranslation();

  return (
    <Typography.Text
      {...rest}
      type={type === 'title' ? undefined : type}
      style={typeof size === 'number' ? { fontSize: size } : undefined}
      className={cn(
        className,
        'ui-text',
        typeof size === 'number' ? undefined : generateTypographySizeClassName(size),
        {
          'title-color': type === 'title',
          'bold-weight': weight === 'bold',
          'medium-weight': weight === 'medium',
          'ui-text--hover': withinLink?.hasHoverEffect, // TODO add to title and paragraph?
        },
      )}
    >
      {typeof children === 'string' ? t(children) : children}
    </Typography.Text>
  );
};

export default Text;
