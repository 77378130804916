import { transformError, transformResponse } from '../common/recoil/queries';
import { Chatroom } from '../models';
import { CreateChatroomRequest, createChatroom as createChatroomRequest } from '../services';

import { ChatroomDataObject } from '../services/data-objects';

// TODO Reconsider usage and form of Request objects
const createChatroom = async (data: CreateChatroomRequest) => {
  try {
    const responseChatroom = await createChatroomRequest(data);
    const chatroomData = transformResponse<ChatroomDataObject, Chatroom>(
      responseChatroom,
      ({ statuses, ...rest }) =>
        new Chatroom({
          ...rest,
          message: undefined,
          statuses: [],
        }),
    );

    return {
      error: null,
      result: {
        chatroom: chatroomData.result,
      },
    };
  } catch (error) {
    return transformError(error);
  }
};

export default createChatroom;
