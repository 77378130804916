import { FolderAddOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import cs from 'classnames';
import { AttachmentMenu } from 'old/atoms/post-editor';
import { useState } from 'react';
import variables from 'common/styles/variables.json';
import { Button, Col, Row } from 'ui';
import { useUploadFile } from '../../queries';
import { CreateFolder } from '../AssetsActionModals';

type P = {
  queryParams: { communityId: string; parentId?: string };
};

const AssetsHeaderButtons = ({ queryParams }: P) => {
  const [isCreateFolderOpened, setIsCreateFolderOpened] = useState<boolean>(false);

  const { mutate: uploadFile, isLoading: isLoadingUploadFile } = useUploadFile();

  const onFileUpload = (values: File[]) => {
    uploadFile({ data: values, params: queryParams });
  };

  return (
    <Row item={{ gutter: variables.spaceXs.value, className: 'assets-header' }}>
      <Col>
        <Button
          icon={<FolderAddOutlined />}
          onClick={() => {
            setIsCreateFolderOpened(true);
          }}
          size="large"
        >
          Create folder
        </Button>
      </Col>
      <Col>
        {/* TODO change for proper typescript component */}
        <AttachmentMenu
          active
          onChange={onFileUpload}
          onUpload={() => {
            // Do nothing
          }}
          AttachmentTriggerComponent={() => {
            return (
              <div
                className={cs('ant-btn ant-btn-default', {
                  'ant-btn-loading': isLoadingUploadFile,
                })}
              >
                {isLoadingUploadFile ? <LoadingOutlined /> : <UploadOutlined />}
                <span>Upload</span>
              </div>
            );
          }}
          disabled={isLoadingUploadFile}
          isUploading={isLoadingUploadFile}
          disableUpload
        />
      </Col>
      <CreateFolder
        isOpened={isCreateFolderOpened}
        onCancel={() => {
          setIsCreateFolderOpened(false);
        }}
        communityId={queryParams.communityId}
        parentId={queryParams.parentId}
      />
    </Row>
  );
};

export default AssetsHeaderButtons;
