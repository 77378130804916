// TODO once we get rid off redux replace this only for new AppLayout
import { TrophyOutlined } from '@ant-design/icons';
import { type ReduxUser } from 'models';
import { AppLayout } from 'old/layouts/AppLayout';
import { FullwidthPageHeader } from 'ui';
import { LeaderboardHeader, LeaderboardTable } from './components';

type P = {
  user: ReduxUser;
};

const Leaderboard = ({ user }: P) => {
  const routes = [
    { path: 'profile', breadcrumbName: 'My profile' },
    { path: '#', breadcrumbName: 'Leaderboard' },
  ];

  return (
    <AppLayout
      center={
        <>
          <FullwidthPageHeader title={{ icon: <TrophyOutlined />, text: 'Leaderboard' }} routes={routes}>
            <LeaderboardHeader user={user} />
          </FullwidthPageHeader>
          <LeaderboardTable currentUser={user} />
        </>
      }
    />
  );
};

export default Leaderboard;
