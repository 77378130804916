import { ArrowDownOutlined } from '@ant-design/icons';
import cs from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, InViewport, Row } from 'ui';

const ContinueReading = () => {
  const { t } = useTranslation();
  const reference = useRef<HTMLDivElement>(null);
  const [hidden, setHidden] = useState(false);

  const scrollToViewport = useCallback(() => {
    reference.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <>
      <InViewport
        reference={reference}
        onEnter={() => {
          setHidden(true);
        }}
      />
      <Row
        item={{
          className: cs('continue-reading', { 'continue-reading__hidden': hidden }),
          align: 'middle',
          justify: 'center',
        }}
      >
        <Col>
          <Button type="link" size="large" onClick={scrollToViewport}>
            {t('Continue reading')} <ArrowDownOutlined />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ContinueReading;
