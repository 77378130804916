import type React from 'react';
import { ModalFormActions, Section } from '../';
import Steps from './Steps';

type P = {
  currentStep: number;
  numberOfSteps: number;
  getStep: (step: number) => any;
  onNavigateTo: (step: number) => void;
  onSubmit?: (step: number) => void;
};

const Stepper: React.FC<P> = ({ currentStep, numberOfSteps, getStep, onNavigateTo, onSubmit }) => {
  const renderBody = () => {
    return [...Array.from({ length: currentStep + 1 }).keys()].map((renderStep) => (
      <div className={renderStep === currentStep ? undefined : 'step__inactive'} key={`render-step-${renderStep}`}>
        {getStep(renderStep)}
      </div>
    ));
  };

  const onChangeStep = (step: number) => {
    onNavigateTo(Math.max(0, Math.min(currentStep, step)));
  };

  const onBackPressed = () => {
    onNavigateTo(Math.max(currentStep - 1, 0));
  };

  const onNextPressed = onSubmit
    ? () => {
        onSubmit(currentStep);
      }
    : undefined;

  return (
    <div className="stepper">
      <Section>
        <Steps currentStep={currentStep} numberOfSteps={numberOfSteps} onChange={onChangeStep} />
      </Section>
      <Section paddingBottom={Boolean(onNextPressed)} lineBottom={Boolean(onNextPressed)}>
        {renderBody()}
      </Section>
      {onNextPressed ? (
        <ModalFormActions
          submit={{
            onClick: onNextPressed,
            children: currentStep + 1 < numberOfSteps ? 'Continue' : 'Finish & Publish',
          }}
          left={currentStep > 0 ? { onClick: onBackPressed } : undefined}
        />
      ) : null}
    </div>
  );
};

export default Stepper;
