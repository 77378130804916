import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventContentObject, type CommunityListItem } from 'models';
import { type DefaultResponseErrorDataObject, type ApiClientResponseError } from 'common/services';
// TODO fix this import from different feature, probably target queries should be here instead
import { getEventsQueryKey } from 'features/communities/queries';
import { type EventContentObjectDataObject } from 'models/service';

import { type DefaultEventData, type TelehealthEventData } from '../../components';
import { useMapEventRequestData } from '../../hooks';
import { createEvent } from '../../services';

export const useCreateEvent = () => {
  const queryClient = useQueryClient();
  const { mapEventRequestData } = useMapEventRequestData();

  return useMutation<
    { eventContentObject: EventContentObject; eventContentDataObject: EventContentObjectDataObject }, // TODO need to return DataObject because redux for now
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    { data: DefaultEventData | TelehealthEventData; communities: CommunityListItem[] }
  >({
    async mutationFn({ data, communities }) {
      const networkData = mapEventRequestData(data, communities);
      const result = await createEvent(networkData);

      return {
        eventContentObject: new EventContentObject(result.data),
        eventContentDataObject: result.data,
      };
    },
    onSuccess() {
      queryClient.invalidateQueries(getEventsQueryKey({ type: 'upcoming' }));
    },
  });
};
