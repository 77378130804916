import { type ReduxUser } from 'models';
import { dispatch } from 'old/store';
import { useParams } from 'react-router-dom';
import { CommunityFeed, CommunityLayout } from 'features/communities';
import { useFeedbackOptions } from '../../hooks';

type P = {
  viewer: ReduxUser;
};

const PageCommunityFeed = ({ viewer }: P) => {
  const { communityId } = useParams<{ communityId: string }>();
  const feedbackOptions = useFeedbackOptions();

  const handleStatusChange = () => {
    dispatch.session.fetchUser();
  };

  return (
    <CommunityLayout viewer={viewer} communityId={communityId} tab="/feed" onStatusChange={handleStatusChange}>
      {(community) => <CommunityFeed community={community} viewer={viewer} feedbackOptions={feedbackOptions} />}
    </CommunityLayout>
  );
};

export default PageCommunityFeed;
