import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { PageLoginSignpost } from 'pages';
import { routes } from 'routes';
import { withPermissions, IsAuthenticated } from '../atoms/auth';
import { PageOrganizationCreate } from '../pages/page-organization-create/PageOrganizationCreate';
import { PageOrganizationUpdate } from '../pages/page-organization-update/PageOrganizationUpdate';
import { PageAdminCommunity } from '../pages/page-admin-community/PageAdminCommunity';
import { AdminPageCampaigns } from '../pages/admin-page-campaigns/AdminPageCampaigns';
import { PageChequePledge } from '../pages/page-cheque-pledge';
import { AdminPageCampaignDetail } from '../pages/admin-page-campaigns/AdminPageCampaignDetail';
import { DonationReport, SendDonationReport } from '../pages/page-admin-donation-report';
import { PageAppConfigCreate } from '../pages/page-app-config-create/PageAppConfigCreate';
import { PageAppConfigUpdate } from '../pages/page-app-config-update/PageAppConfigUpdate';

const PageNotAllowed = () => (
  <h1>
    <Trans>Not allowed to see this</Trans>
  </h1>
);

const mapState = (state) => ({
  user: select.session.user(state),
});

export const AdminRouter = connect(mapState)(function AdminRouter(props) {
  const { match, user } = props;
  const notAllowedRedirect = <PageNotAllowed />;

  return (
    <Switch>
      <Route path="/login" component={PageLoginSignpost} />
      <IsAuthenticated
        NotAllowed={() => <Redirect to={{ pathname: '/landing', state: { referrer: window.location.href } }} />}
        Allowed={() => (
          <Switch>
            {/* Admin:Campaings */}
            <Redirect exact from="/admin/campaigns" to="/admin/campaigns/current" />
            {withPermissions(
              [
                { scope: 'superadmin' },
                { scope: 'manage-community', subj: user.admin_communities },
                { scope: 'manage-organization', subj: user.admin_organizations },
              ],
              <Route path={'/admin/campaigns/cheque-pledge'} component={PageChequePledge} />,
              notAllowedRedirect,
            )}

            {withPermissions(
              [
                { scope: 'superadmin' },
                { scope: 'manage-community', subj: user.admin_communities },
                { scope: 'manage-organization', subj: user.admin_organizations },
              ],
              <Route path={'/admin/campaigns/:scope(donation-report)'} component={DonationReport} />,
              notAllowedRedirect,
            )}

            {withPermissions(
              [
                { scope: 'superadmin' },
                { scope: 'manage-community', subj: user.admin_communities },
                { scope: 'manage-organization', subj: user.admin_organizations },
              ],
              <Route path={'/admin/campaigns/send-donation-report'} component={SendDonationReport} />,
              notAllowedRedirect,
            )}

            {withPermissions(
              [
                { scope: 'superadmin' },
                { scope: 'manage-community', subj: user.admin_communities },
                { scope: 'manage-organization', subj: user.admin_organizations },
              ],
              <Route path={'/admin/campaigns/:scope'} component={AdminPageCampaigns} />,
              notAllowedRedirect,
            )}

            {withPermissions(
              [{ scope: 'superadmin' }, { scope: 'manage-organization', subj: user.admin_organizations }],
              <Route path={'/admin/donations/:donation_id'} component={AdminPageCampaignDetail} />,
              notAllowedRedirect,
            )}

            {/* ORGANIZATION UPDATE */}
            {withPermissions(
              [{ scope: 'superadmin' }, { scope: 'manage-organization', subj: user.admin_organizations }],
              <Route path={`${match.url}/organizations/:organizationId/update`} component={PageOrganizationUpdate} />,
              notAllowedRedirect,
            )}
            {/* ORGANIZATION CREATE */}
            {withPermissions(
              [{ scope: 'superadmin' }],
              <Route path={`${match.url}/organizations/create`} component={PageOrganizationCreate} />,
              notAllowedRedirect,
            )}

            {/* COMMUNITY UPDATE */}
            {withPermissions(
              [{ scope: 'superadmin' }, { scope: 'manage-community', subj: user.admin_communities }],
              <Route path={`${match.url}/communities/:communityId/update`} component={PageAdminCommunity} />,
              notAllowedRedirect,
            )}
            {withPermissions(
              [{ scope: 'superadmin' }, { scope: 'manage-community', subj: user.admin_communities }],
              <Route
                path={`${match.url}/organizations/:organizationId/communities/:communityId/update`}
                component={PageAdminCommunity}
              />,
              notAllowedRedirect,
            )}
            {/* COMMUNITY CREATE */}
            {withPermissions(
              [
                { scope: 'superadmin' },
                { scope: 'manage-organization', subj: user.admin_organizations },
                { scope: 'manage-community', subj: user.admin_communities },
              ],
              <Route
                path={`${match.url}/organizations/:organizationId/communities/create`}
                component={PageAdminCommunity}
              />,
              notAllowedRedirect,
            )}
            {/* Admin:App Config */}
            {withPermissions(
              [{ scope: 'superadmin' }],
              <Route path={`${match.url}/app-configs/create`} component={PageAppConfigCreate} />,
              notAllowedRedirect,
            )}
            {withPermissions(
              [{ scope: 'superadmin' }],
              <Route path={`${match.url}/app-configs/:appConfigName/update`} component={PageAppConfigUpdate} />,
              notAllowedRedirect,
            )}

            <Route path={`${match.url}/notallowed`} component={PageNotAllowed} />
            <Redirect from="/admin/organizations" to={`/admin/organizations/${user.admin_organizations[0]}/update`} />
            <Redirect from="/admin/communities" to={`/admin/communities/${user.admin_communities[0]}/update`} />
            <Redirect from={routes.admin.whiteLabel} to={`/admin/app-configs/create`} />
            <Redirect
              from="/admin"
              to={
                user.superadmin || user.admin_organizations.length > 0
                  ? `/admin/organizations/${user.admin_organizations[0]}/update`
                  : `/admin/communities/${user.admin_communities[0]}/update`
              }
            />
          </Switch>
        )}
      />
    </Switch>
  );
});
