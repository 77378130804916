import { Component, createRef } from 'react';
import CanvasDraw from 'react-canvas-draw';

import { ColorPicker } from './base-atoms/ColorPicker';
import { ActionsBar } from './base-atoms/ActionsBar';
import { Slider } from './base-atoms/Slider';
import { convertCanvasToFile } from './helpers';

import { StorageService } from '../../services/StorageService';

const BRUSH_COLORS = ['#000', '#FAE741', '#F9A538', '#FF6236', '#E13111', '#BE0FDA', '#8F0DF9', '#0074FA', '#00AEFC'];

export class WhiteBoard extends Component {
  static defaultProps = {
    loadTimeOffset: 0,
    lazyRadius: 0,
    gridColor: '#949494',
    hideGrid: true,
    canvasWidth: 576,
    canvasHeight: 300,
    disabled: false,
    imgSrc: '',
    saveData: null,
    immediateLoading: false,
  };

  canvasRef = createRef();

  state = {
    brushColor: '#000',
    brushRadius: 1,
    catenaryColor: '#000',
    loading: false,
  };

  transparentPixelsToWhite = () => {
    var canvasContext = this.canvasRef.canvas.drawing.getContext('2d');
    var canvasData = canvasContext.getImageData(0, 0, this.props.canvasWidth, this.props.canvasHeight);
    var pixels = canvasData.data;

    for (var i = 0, n = pixels.length; i < n; i += 4) {
      if (pixels[i] === 0 && pixels[i + 1] === 0 && pixels[i + 2] === 0 && pixels[i + 3] === 0) {
        pixels[i] = 255;
        pixels[i + 1] = 255;
        pixels[i + 2] = 255;
        pixels[i + 3] = 255;
      }
    }

    canvasContext.putImageData(canvasData, 0, 0);
  };

  onColorPick = (brushColor) => {
    this.setState({ brushColor: brushColor, catenaryColor: brushColor });
  };

  onBrushRadiusChange = (e) => {
    this.setState({ brushRadius: parseFloat(e.target.value) });
  };

  onDrawingDone = async () => {
    this.setState({ loading: true });
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    this.transparentPixelsToWhite();
    const uploadFile = convertCanvasToFile(
      this.canvasRef.canvas.drawing.toDataURL('image/png'),
      `whiteboard_image_${s4()}`,
    );
    const response = await StorageService.send(uploadFile);

    if (response.ok) {
      this.props.onDrawingDone([response.data]);
    }
    this.setState({ loading: false });
  };

  render() {
    const { onDrawingDone, ...whiteBoardProps } = this.props;
    return (
      <>
        <div style={{ borderBottom: '1px solid #edeff2' }}>
          <CanvasDraw {...whiteBoardProps} {...this.state} ref={(canvas) => (this.canvasRef = canvas)} />
        </div>
        <div style={{ padding: '0 30px' }}>
          <Slider
            value={this.state.brushRadius}
            onValueChanged={this.onBrushRadiusChange}
            min={1}
            max={23}
            step={0.2}
          />
          <ColorPicker
            colors={BRUSH_COLORS}
            onColorClick={this.onColorPick}
            rectSize={32}
            pickedColor={this.state.brushColor}
          />
          <ActionsBar
            undoAction={() => this.canvasRef.undo()}
            clearAction={() => this.canvasRef.clear()}
            doneAction={() => this.onDrawingDone()}
            isUploading={this.state.loading}
          />
        </div>
      </>
    );
  }
}
