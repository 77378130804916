import { FC } from 'react';

type P = {
  text: string;
  onClick: () => void;
};

const Toast: FC<P> = ({ text, onClick }: P) => {
  const handleOnClick = onClick;
  return (
    <div className="toast">
      <button type="button" className="toast__content" onClick={handleOnClick}>
        {text}
      </button>
    </div>
  );
};

export default Toast;
