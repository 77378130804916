import { createModel } from '@rematch/core';
import { normalize, denormalize } from 'normalizr';

import { CampaignSchema } from './schemas';
import { CampaignsService } from '../services/CampaignsService';
import { dispatch, getState } from '../store';
import { groupByEntity } from './helpers';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [CampaignSchema] : CampaignSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

const INITIAL_STATE = [];

export const model = createModel({
  name: 'campaigns',
  state: INITIAL_STATE,
  reducers: {
    'session/reset': () => INITIAL_STATE,

    setCollection: (state, payload) => {
      return payload;
    },

    push: (state, id) => {
      if (state.includes(id)) {
        return state;
      }

      return state.concat(id);
    },
  },
  selectors: {
    find(state, id) {
      return denormalize(id, CampaignSchema, getState().entities);
    },

    get(state) {
      return denormalize(state, [CampaignSchema], getState().entities);
    },

    getBy(state, type) {
      return groupByEntity(denormalize(state, [CampaignSchema], getState().entities), type);
    },
  },
  effects: (dispatch) => ({
    async getAsync() {
      // TODO remove limit and use pagination
      const response = await CampaignsService.get({ limit: 1000 });
      if (response.ok) {
        this.setCollection(normalizeData(response.data));
      }
      return response;
    },

    async findAsync(id) {
      const response = await CampaignsService.find(id);
      if (response.ok) {
        this.push(normalizeData(response.data));
      }
      return response;
    },

    async getDonors(campaigns) {
      const response = await CampaignsService.getDonors(campaigns);
      if (response.ok) {
        return response.data;
      }
    },
  }),
});
