import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { select } from '@rematch/select';

/**
 * Connected component which receives current authenticated user
 */

function isAllowed(props) {
  const { permissions, user, authenticated } = props;
  return (
    permissions.length === 0 ||
    permissions.some((permission) => {
      switch (permission.scope) {
        case 'guest':
          return authenticated === false;

        case 'authenticated':
          return authenticated === true;

        case 'superadmin':
          return user?.superadmin;

        case 'manage-organization':
          return permission.subj.some((orgId) => user?.admin_organizations?.includes(orgId));

        case 'manage-community':
          return permission.subj.some((commId) => user?.admin_communities?.includes(commId));

        case 'super-user':
          if (!permission.subj.length) return false;
          return permission.subj.every((commId) => user?.super_user_permissions?.[permission.type]?.includes(commId));

        case 'manage-course':
          return permission.subj.some((course) => course?.administrators?.some((admin) => admin.id === user.id));

        case 'joined-organization':
          return permission.subj.some((orgId) => user?.joined_organizations?.map((org) => org.id).includes(orgId));

        case 'joined-community':
          return permission.subj.some((commId) => user?.joined_communities?.map((comm) => comm.id).includes(commId));

        default:
          return false;
      }
    })
  );
}

const mapState = (state) => ({
  authenticated: select.session.isAuthenticated(state),
  user: select.session.user(state),
});

const AuthState = connect(mapState)(function AuthState(props) {
  return props.render(isAllowed(props));
});

/**
 * Decision making component which renders content based on defined behaviour.
 */
export function withPermissions(permissions, allowedElement, notAllowedElement) {
  function renderElement(allowed) {
    if (allowed) {
      return typeof allowedElement === 'function' ? allowedElement() : allowedElement;
    }
    return typeof notAllowedElement === 'function' ? notAllowedElement() : notAllowedElement;
  }

  function render() {
    return <AuthState permissions={permissions} render={renderElement} />;
  }

  return allowedElement.type === Route ? <Route path={allowedElement.props.path} render={render} /> : render();
}
