import { useTranslation } from 'react-i18next';
import { Profile } from '../../../common/models';
import { DataState, processError } from '../../../common/recoil/queries';

import { ID } from '../../../common/types';
import { Message as MessageModel } from '../../../models';
import { ChatroomMessage } from '../../../types';
import Message from '../Message';

type P = {
  group: { date: Date; messages: MessageModel[] };
  onResendMessage: (data: ChatroomMessage, resentMessageId: ID) => void;
  currentUserProfileData: DataState<Profile>;
};

const MessagesGroup = ({ group, onResendMessage, currentUserProfileData }: P) => {
  const { t } = useTranslation(['translationChat']);

  const { messages, date } = group;
  const isAuthorOfMessage = (authorId?: string) => currentUser._id === authorId;

  if (currentUserProfileData.error) {
    return processError(currentUserProfileData.error);
  }

  const currentUser = currentUserProfileData.result;
  const reversedMessages = [...messages].reverse();

  return (
    <div className="messages-group">
      <p className="p--small messages-group__date-info">{t('chat.chatroomDetail.messagesGroupDate', { date })}</p>
      <div className="message-group__messages">
        {reversedMessages.map((message: MessageModel, index: number) => (
          // TODO: Get somehow isOnline status of user
          <Message
            key={message._id}
            message={message}
            isAuthor={isAuthorOfMessage(message.author)}
            isLastMessageInMessageGroup={
              index === reversedMessages.length - 1 ||
              reversedMessages[index].author !== reversedMessages[index + 1].author
            }
            onResendMessage={onResendMessage}
          />
        ))}
      </div>
    </div>
  );
};

export default MessagesGroup;
