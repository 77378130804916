import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  type SignUpLocationState,
  type LoginLocationState,
  type PasswordlessLocationState,
  type CheckMagicLinkLocationState,
  type LoginSignpostLocationState,
  type ForgotPasswordLocationState,
} from 'pages';
import { type OnboardingLocationState } from '../types';

export const useOnboardingNavigation = <T extends OnboardingLocationState>() => {
  const { state } = useLocation<T | undefined>();
  const { push: pushToSignUp } = useHistory<SignUpLocationState>();
  const { push: pushToPasswordLogin, replace: replaceWithLogin } = useHistory<LoginLocationState>();
  const { push: pushToPasswordless } = useHistory<PasswordlessLocationState>();
  const { push: pushToLogin } = useHistory<LoginSignpostLocationState>();
  const { replace: replaceWithMagicLink } = useHistory<CheckMagicLinkLocationState>();
  const { push: pushToForgotPassword } = useHistory<ForgotPasswordLocationState>();

  return useMemo(
    () => ({
      onNavigateToSignUp(signUpState?: SignUpLocationState) {
        pushToSignUp('/sign-up', { ...state, ...signUpState });
      },
      onNavigateToPasswordlessLogin(loginState?: PasswordlessLocationState) {
        pushToPasswordless('/login/magic-link', { ...state, ...loginState });
      },
      onNavigateToPasswordLogin(loginState?: LoginLocationState) {
        pushToPasswordLogin('/login/password', { ...state, ...loginState });
      },
      onReplaceCheckMagicLink() {
        replaceWithMagicLink('/magic-link', state);
      },
      onReplaceToLogin() {
        replaceWithLogin('/login/password', state);
      },
      onNavigateToPrimaryLogin(loginState?: LoginSignpostLocationState) {
        pushToLogin('/login', { ...state, ...loginState });
      },
      onNavigateToForgotPassword(email?: string) {
        pushToForgotPassword({ pathname: `/forgot-password/`, state: { ...state, email } });
      },
    }),
    [
      pushToSignUp,
      state,
      pushToPasswordless,
      pushToPasswordLogin,
      replaceWithMagicLink,
      replaceWithLogin,
      pushToLogin,
      pushToForgotPassword,
    ],
  );
};
