import { SearchOutlined } from '@ant-design/icons';
import { type InviteType } from 'models';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { type AutocompleteUser } from 'models/user';
import { type CheckboxChangeEvent, type RadioChangeEvent } from 'ui';
import { Button, ReactMultiEmail, Loading, Section, isEmail, Radio, Input, Checkbox } from 'ui';

export type InviteesType = 'email' | 'registered';

type P = {
  onInvite: (inviteType: InviteType, invitees: AutocompleteUser[], emailInvitees?: string[]) => void;
  inviteType: InviteType;
  isRefetching: boolean;
  // TODO this is type AutocompleteUser but it is deprecated, please replace with USer model when available
  allUsers: AutocompleteUser[];
  chosenRegistered?: string[];
  chosenEmails?: string[];
  allowedInvites?: InviteesType[];
};

const defaultAllowedInvites: InviteesType[] = ['registered', 'email'];

const EventInvitees = ({
  onInvite,
  inviteType,
  isRefetching,
  allUsers,
  chosenRegistered,
  chosenEmails,
  allowedInvites = defaultAllowedInvites,
}: P) => {
  const { t } = useTranslation();
  const [type, setType] = useState(allowedInvites[0]);
  const [invitees, setInvitees] = useState(allUsers.filter((user) => chosenRegistered?.includes(user.id)));
  const [search, setSearch] = useState('');
  const [emailInvitees, setEmailInvitees] = useState(chosenEmails);

  useEffect(() => {
    setInvitees(allUsers.filter((user) => chosenRegistered?.includes(user.id)));
    setEmailInvitees(chosenEmails);
  }, [allUsers, chosenRegistered, chosenEmails]);

  const filteredUsers = useMemo(() => {
    const removeSpaces = (text?: string) => text?.replace(/\s+/g, ' ').toLocaleLowerCase().trim();
    const modifiedSearch = removeSpaces(search);

    /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
    return allUsers.filter(
      (user) =>
        !modifiedSearch ||
        removeSpaces(user.first_name)?.includes(modifiedSearch) ||
        removeSpaces(user.last_name)?.includes(modifiedSearch) ||
        removeSpaces(user.name)?.includes(modifiedSearch),
    );
  }, [allUsers, search]);
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

  return (
    <>
      {allowedInvites.length > 1 && (
        <Radio.Group
          defaultValue={type}
          size="middle"
          buttonStyle="solid"
          onChange={(event: RadioChangeEvent) => {
            setType(event.target.value);
          }}
          style={{ marginBottom: 16, width: '100%', display: 'flex' }}
        >
          {allowedInvites.includes('registered') && (
            <Radio.Button value="registered" data-test-id="input_registered">
              {t('Registered')}
            </Radio.Button>
          )}
          {allowedInvites.includes('email') && (
            <Radio.Button value="email" data-test-id="input_by-email">
              {t('By e-mail')}
            </Radio.Button>
          )}
        </Radio.Group>
      )}
      <Section paddingBottom={false} data-test-id="event-invitees_content">
        {type === 'registered' && (
          <div className="user-list">
            <Input
              placeholder={t('search...')}
              suffix={<SearchOutlined />}
              onChange={(event: { target: { value: React.SetStateAction<string> } }) => {
                setSearch(event.target.value);
              }}
              id="input_search-users"
            />

            <div className="user-list__list" id="user-list">
              {isRefetching ? <Loading /> : null}
              {filteredUsers.map((user, index) => (
                <label className="user-list__list__item" key={user.id} id={`user-${index}`}>
                  <Checkbox
                    onChange={(event: CheckboxChangeEvent) => {
                      if (event.target.checked) {
                        setInvitees([...invitees, user]);
                      } else {
                        setInvitees(invitees.filter((invitee) => invitee.id !== user.id));
                      }
                    }}
                    checked={invitees.some((invitee: any) => invitee.id === user.id)}
                  />
                  <div
                    className="user-list__list__item__image"
                    style={{ backgroundImage: `url(${user.profile_photo?.url ?? images.default.userProfilePhoto})` }}
                  />
                  <span className="user-list__list__item__name">{`${user.first_name} ${user.last_name}`}</span>
                </label>
              ))}

              {filteredUsers.length === 0 && <div className="user-list__list__empty">{t('No matches.')}</div>}
            </div>
          </div>
        )}
        {type === 'email' && (
          <ReactMultiEmail
            placeholder={t('Email addresses...')}
            emails={emailInvitees}
            style={{ minHeight: '254px', marginBottom: '32px' }}
            onChange={(emails: string[]) => {
              setEmailInvitees(emails);
            }}
            validateEmail={(email) => isEmail(email)}
            getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span
                    data-tag-handle
                    onClick={() => {
                      removeEmail(index);
                    }}
                  >
                    ×
                  </span>
                </div>
              );
            }}
          />
        )}
      </Section>
      <div className="event-invitees__footer" id="event-invitees_footer">
        <Button
          onClick={() => {
            onInvite(inviteType, invitees, emailInvitees);
          }}
          id="btn_invite"
          htmlType="submit"
          type="primary"
          className="event-invitees__footer__button"
        >
          Invite
        </Button>
        {allowedInvites.includes('registered') && (
          <span className="event-invitees__footer__label">{t('registeredUsers', { number: invitees.length })}</span>
        )}
        {allowedInvites.includes('email') && (
          <span className="event-invitees__footer__label">{t('byEmail', { number: emailInvitees?.length })}</span>
        )}
      </div>
    </>
  );
};

export default EventInvitees;
