import { type Form, DatePicker } from 'antd';
import cn from 'classnames';
import moment, { type Moment } from 'moment';
import { useRef, type ComponentProps } from 'react';

import { disabledDate, disabledTime } from '../Disable';
import { Item } from '../Item';

type ItemP = Omit<ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
};

type InputP = Omit<ComponentProps<typeof DatePicker>, 'css'> & {
  minimum?: Moment;
  maximum?: Moment;
  allowPast?: boolean;
  allowFuture?: boolean;
  fullWidth?: boolean;
  showTime?: Record<string, unknown> | boolean; // TODO try to fix this, this is already in DatePicker type, but it do not work
  'data-test-id'?: string;
};

type P = {
  item: ItemP;
  input?: InputP;
};

const defaultInput: InputP = {};

// TODO minimum and maximum in date is not working properly, do it in task 61936
const DatePickerItemRenderer = ({
  className,
  minimum,
  maximum,
  allowPast,
  allowFuture,
  fullWidth,
  onChange,
  ...inputRest
}: InputP) => {
  const currentTime = useRef<Moment>();

  const minimumDate = allowPast ? undefined : minimum;
  const maximumDate = allowFuture ? undefined : maximum;

  return (
    <DatePicker
      className={cn(className, { 'full-width': fullWidth })}
      disabledDate={disabledDate(minimumDate, maximumDate)}
      /* TODO add maximum also to time */
      disabledTime={disabledTime(minimumDate)}
      size="middle"
      allowClear={false}
      popupClassName="date-time-input"
      onSelect={(value) => {
        currentTime.current = minimumDate && value.isBefore(minimumDate) ? minimumDate : value;
        currentTime.current = maximumDate && value.isAfter(maximumDate) ? maximumDate : currentTime.current;
      }}
      onOpenChange={(open) => {
        if (!open && currentTime.current) {
          onChange?.(
            currentTime.current,
            typeof inputRest.format === 'string'
              ? currentTime.current.format(inputRest.format)
              : currentTime.current.toISOString(),
          );

          currentTime.current = undefined;
        }
      }}
      {...inputRest}
    />
  );
};

const DateTimeInput = ({
  item,
  input: {
    minimum = moment(),
    maximum = moment(),
    allowPast,
    allowFuture = true,
    fullWidth = true,
    ...inputRest
  } = defaultInput,
}: P) => {
  return (
    <Item {...item} label={item.label}>
      <DatePickerItemRenderer
        minimum={minimum}
        maximum={maximum}
        allowPast={allowPast}
        allowFuture={allowFuture}
        fullWidth={fullWidth}
        {...inputRest}
      />
    </Item>
  );
};

export default DateTimeInput;

export type DateTimeInputItemProperties = ItemP;
