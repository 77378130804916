import * as React from 'react';
import { Field } from 'formik';

import { FieldError } from './FieldError';
import { CheckboxWithLabel } from './base-atoms/CheckboxWithLabel';

function toggleArrayValue(arr, value) {
  const set = new Set(arr);
  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }
  return Array.from(set);
}

export function CheckboxGroup(groupProps) {
  return (
    <React.Fragment>
      {groupProps.label ? <div>{groupProps.label}</div> : null}
      <FieldError name={groupProps.name} />
      <Field name={groupProps.name}>
        {(fieldProps) =>
          groupProps.values.map((inputProps, idx) => {
            return (
              <CheckboxWithLabel
                key={idx}
                disabled={groupProps.disabled || inputProps.disabled}
                label={inputProps.label}
                id={inputProps.id}
                inputProps={{
                  ...fieldProps.field,
                  onChange: (event) => {
                    const updatedDataArray = toggleArrayValue(
                      fieldProps.field.value,
                      inputProps.value ? inputProps.value : inputProps.data,
                    );

                    fieldProps.form.setFieldValue(fieldProps.field.name, updatedDataArray, true);
                    if (groupProps.onChange) {
                      groupProps.onChange(updatedDataArray);
                    }
                    fieldProps.field.onBlur(event);
                  },
                  value: inputProps.value.toString(),
                  checked: fieldProps.field.value.includes(inputProps.value),
                  disabled: groupProps.disabled || inputProps.disabled,
                }}
              />
            );
          })
        }
      </Field>
    </React.Fragment>
  );
}
