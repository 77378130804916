import { http } from './shared';

export const TranslationService = {
  translate(params) {
    return http.post('/translations', { translation: params });
  },
  getSupportedLanguages() {
    return http.get('/translations/supported_languages');
  },
};
