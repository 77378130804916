export function calculateFileSize(size) {
  const divide = Math.round(size / 1024);
  const result =
    divide > 1024
      ? `${parseFloat(divide / 1024).toFixed(1)} MB`
      : divide < 1
      ? `${parseFloat(size).toFixed(1)} B`
      : `${parseFloat(divide).toFixed(1)} kB`;
  return result;
}

export function isVisualFile(attachment) {
  return ['jpg', 'jpeg', 'png', 'gif'].some((type) => {
    return attachment.content_type.endsWith(type);
  });
}
