import { useMemo } from 'react';
import { type ChannelsOrganization } from '../types';

export const useOrganizationIdForRequests = (
  organizationId: string,
  channelsOrganizations?: ChannelsOrganization[],
) => {
  const isDefaultOrganization = channelsOrganizations?.find(
    (channelOrganization) => channelOrganization.id === organizationId,
  )?.default;

  return useMemo(() => (isDefaultOrganization ? undefined : organizationId), [isDefaultOrganization, organizationId]);
};
