import { useState, useEffect } from 'react';

import Poll from 'models/poll';
import type Program from 'models/program';
import { message, Modal } from '../../ui';
import PollForm from './form/PollForm';
import api from './services/api';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: (params: any) => Promise<void>;
  program?: Program;
  poll: Poll;
  onRequestReturn?: () => void;
};

const CreatePoll = ({ onSuccess, onCancel, isOpened, program, poll, onRequestReturn }: P) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pollData, setPollData] = useState<Poll>(new Poll(poll));
  const isProgram = Boolean(program);

  const onSubmit = async (poll: Poll) => {
    setLoading(true);

    if (isProgram) {
      const pollResponse = await api.createPoll(poll);

      if (pollResponse.ok) {
        await onSuccess(poll);
        message.success('Poll was created.');
      } else {
        message.error('Failed to create poll.');
      }
    } else {
      onSuccess(poll);
    }

    setLoading(false);
  };

  useEffect(() => {
    setPollData(new Poll(poll));
  }, [isOpened, poll]);

  return (
    <Modal
      title="Create Poll"
      isOpened={isOpened}
      onCancel={onCancel}
      onBackClick={onRequestReturn}
      disableBack={!onRequestReturn}
      loading={loading}
      destroyOnClose
    >
      <PollForm poll={pollData} program={program} onSubmit={onSubmit} communities={[]} />
    </Modal>
  );
};

export default CreatePoll;
