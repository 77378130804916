import { select } from '@rematch/select';
import { type NodeUser, type ReduxUser } from 'models';
import { AppLayout } from 'old/layouts/AppLayout';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { MemberMessage } from 'features/members';

type Params = {
  memberId: string;
};

type P = {
  viewer: ReduxUser;
};

const PageMemberMessage = ({ viewer }: P) => {
  const { replace } = useHistory();
  const { memberId } = useParams<Params>();

  const navigateToChat = useCallback(
    (user: NodeUser) => {
      // Can't push class object's need to be pure object
      replace(routes.chat.create(), {
        userToChat: { _id: user._id, firstName: user.firstName, lastName: user.lastName },
      });
    },
    [replace],
  );

  const navigateToProfile = useCallback(
    (userId: string) => {
      replace(routes.members.detail(userId));
    },
    [replace],
  );

  return (
    <AppLayout
      center={
        <MemberMessage
          viewer={viewer}
          memberId={memberId}
          navigateToProfile={navigateToProfile}
          navigateToChat={navigateToChat}
        />
      }
    />
  );
};

const mapState = (state: any) => ({
  // @ts-expect-error
  viewer: select.session.user(state),
});

export default connect(mapState)(PageMemberMessage);
