import * as React from 'react';
import { Trans } from 'react-i18next';

import { Icon } from 'old/atoms/icon';
import { Row, Col } from 'ui';

export class Kudo extends React.Component {
  render() {
    const { item } = this.props;
    const { kudo } = item;

    if (!kudo) {
      return null;
    }

    return (
      <Row item={{ align: 'middle', justify: 'center', className: 'kudos' }}>
        <Col item={{ span: 24 }}>
          <Icon name="pba-Kudos" size={64} color="#ffffff" />
          <div className="kudos__text">
            <Trans>Kudos to</Trans> {` `}
            <span className="kudos__name">{`${kudo.user.first_name} ${kudo.user.last_name}`}</span>
          </div>
        </Col>
      </Row>
    );
  }
}
