import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Button, Col, Row, Text, Title } from 'ui';

type P = {
  title: string;
  description?: string;
  buttonText: string;
  loading: boolean;
  onClick: () => void;
  buttonClassName?: string;
  icon: ReactNode;
  danger?: boolean;
};

const DexcomRow = ({ title, loading, onClick, buttonText, description, buttonClassName, icon, danger }: P) => {
  const { t } = useTranslation();
  return (
    <Row item={{ align: 'middle' }}>
      <Col item={{ span: 24 }}>
        <Title level={5}>Dexcom account sync</Title>
      </Col>

      <Col item={{ span: 16 }}>
        <Row item={{ gutter: [variables.spaceXs.value, 0], align: 'middle' }}>
          <Col>
            <Row item={{ gutter: [variables.spaceXs.value, variables.spaceMd.value], align: 'middle' }}>
              <Col>{icon}</Col>
              <Col>
                <Title level={5} marginBottom={false}>
                  {t(title)}
                </Title>
              </Col>
            </Row>
          </Col>
          {description ? (
            <Col item={{ span: 24 }}>
              <Text>{description}</Text>
            </Col>
          ) : null}
        </Row>
      </Col>

      <Col item={{ span: 8 }}>
        <Row item={{ justify: 'end' }}>
          <Button
            type="primary"
            loading={loading}
            danger={danger}
            className={buttonClassName}
            onClick={onClick}
            size="large"
          >
            {buttonText}
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default DexcomRow;
