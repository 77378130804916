import { getLoginToken } from '../../features/auth/services/api';
import { LoginToken } from '../../models/auth';
import { routes } from './routes';

export const generateAdminDashboardUrl = (path: string) => `${process.env.REACT_APP_ADMIN_DASHBOARD_URL}${path}`;

const adminDashboardRedirect = async (referrer: string) => {
  const adminUrl = new URL(generateAdminDashboardUrl(routes.tokenLogin));
  const response = await getLoginToken();

  if (response.ok) {
    const token = new LoginToken(response.data);
    adminUrl.searchParams.append('login_token', token.loginToken);
    adminUrl.searchParams.append('referrer', referrer);
  }

  return window.open(adminUrl.toString(), '_blank');
};

export default adminDashboardRedirect;
