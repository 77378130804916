import * as React from 'react';
import { Trans } from 'react-i18next';

import { Text, H2 } from '../../atoms/text';
import { ChequePledgePreview } from './ChequePledgePreview';

export class ChequePledgeList extends React.Component {
  render() {
    const { donations } = this.props;
    return (
      <div id="checklist-donations">
        <H2>
          <Trans>Check donations</Trans>
        </H2>
        {donations && donations.length > 0 ? (
          donations.map((donation) => (
            <ChequePledgePreview
              key={donation.id}
              donation={donation}
              editCheque={this.props.editCheque}
              deleteCheque={this.props.deleteCheque}
            />
          ))
        ) : (
          <Text>
            <Trans>You have no check donations.</Trans>
          </Text>
        )}
      </div>
    );
  }
}
