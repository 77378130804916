import { ParticipantBase } from "../types";
import { getInitials } from "../utils";

export type Participant = ParticipantBase & {
  _id: string,
};

export const getFullName = (participant: ParticipantBase) => `${participant.firstName ?? ''} ${participant.lastName ?? ''}`.trim();

export const getParticipantInitials = (participant?: ParticipantBase): string => getInitials(participant?.firstName, participant?.lastName);
