import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfinityScrollSelectDeprecated, type SelectProperties } from 'ui';
import { useCommunitiesInfiniteQuery } from '../../queries';

export type CommunitySelectP = Omit<SelectProperties, 'options'>;

const CommunitySelect = (props: CommunitySelectP) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const { data, isFetching, fetchNextPage, hasNextPage } = useCommunitiesInfiniteQuery({
    pageSize: 12,
    search,
    scope: 'joined',
  });

  const communities = data?.pages.flat().flatMap((communitiesPaginated) => communitiesPaginated.data);

  return (
    <InfinityScrollSelectDeprecated
      placeholder={t('Select community')}
      onSearch={setSearch}
      isDebounce
      isLoading={isFetching}
      loadingProps={{ hasMoreData: hasNextPage, fetchNextPage }}
      options={
        communities?.map((community) => ({
          value: community.id,
          label: community.name,
        })) ?? []
      }
      onDropdownVisibleChange={(open) => {
        if (!open) {
          setSearch(undefined);
        }
      }}
      labelInValue
      {...props}
    />
  );
};

export default CommunitySelect;
