import { type ComponentProps } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

type P = ComponentProps<typeof Redirect> & {
  additionalSearch: Record<string, string>;
};

const RedirectWithSearch = ({ additionalSearch, to, ...rest }: P) => {
  const { search } = useLocation();

  const toObject = typeof to === 'string' ? { pathname: to } : to;

  const originalSearchParams = new URLSearchParams(search);
  const newSearchParams = new URLSearchParams(additionalSearch);

  const finalSearchParams = new URLSearchParams();

  for (const originalSearchParameter of originalSearchParams.entries()) {
    finalSearchParams.append(originalSearchParameter[0], originalSearchParameter[1]);
  }

  for (const newSearchParameter of newSearchParams.entries()) {
    finalSearchParams.append(newSearchParameter[0], newSearchParameter[1]);
  }

  return <Redirect to={{ ...toObject, search: finalSearchParams.toString() }} {...rest} />;
};

export default RedirectWithSearch;
