import { AppLayout } from 'old/layouts/AppLayout';
import { useParams } from 'react-router-dom';
import { ChannelsEvents } from 'features/channels';
import { useGetPathToEntityDetail } from '../../hooks';

const PageChannelsEvents = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const getPathToEntityDetail = useGetPathToEntityDetail();

  return (
    <AppLayout
      center={
        <ChannelsEvents
          getPathToEventDetail={(id) => getPathToEntityDetail(id, { entityType: 'event' })}
          organizationId={organizationId}
        />
      }
    />
  );
};

export default PageChannelsEvents;
