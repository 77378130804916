import { withRouter } from 'react-router';

import { Text } from '../atoms/text';
import { Box } from '../atoms/box';
import { ThemeConsumer } from '../atoms/theme';
import { Link } from 'ui';

function Renderer(props) {
  const selected = props.location.pathname === props.to;
  return (
    <ThemeConsumer>
      {(theme) => (
        <Link
          to={props.to}
          style={{
            outline: 'none',
            textDecoration: 'none',
            width: 120,
            borderBottom: `${selected ? '4px solid ' + theme.color.brand : null}`,
          }}
          id={`topbar_${props?.id}`}
        >
          <Box>
            <Text
              weight="400"
              size={15}
              color={selected ? theme.color.brand : '#595959'}
              className="sidebar-link-text"
              style={{ transition: 'color 200ms ease-in-out', textAlign: 'center' }}
            >
              {props.children}
            </Text>
          </Box>
        </Link>
      )}
    </ThemeConsumer>
  );
}

export const TopBarLink = withRouter(Renderer);
