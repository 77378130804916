import * as React from 'react';

export function Slider({ onValueChanged, value, min, max, step }) {
  return (
    <div className="whiteboard-slider">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        onChange={(e) => onValueChanged(e)}
        value={value}
        className="whiteboard-slider__input"
      />
    </div>
  );
}
