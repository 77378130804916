import * as React from 'react';
import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { dispatch } from '../../store';

import { AppLayout } from '../../layouts/AppLayout';
import { CommunityService } from '../../services/CommunityService';
import { MemberProfile } from '../../atoms/member';
import { OrganizationModalDeprecated } from '../../atoms/organization-modal';
import { OrganizationService } from '../../services/OrganizationService';
import { showAlert } from '../../atoms/alert';
import { ThemeConsumer } from '../../atoms/theme';
import { UserService } from '../../services/UserService';
import { Loading } from 'ui';

class Renderer extends React.Component {
  state = {
    loading: true,
    member: null,
    isCommunityPreviewModalOpen: false,
    selectedCommunityForPreview: null,
    isOrganizationPreviewModalOpen: false,
    selectedOrganizationForPreview: null,
  };

  async componentWillMount() {
    const memberId = this.props.match.params.memberId || 'undefined';
    const response = await UserService.find(memberId);
    if (response.ok) {
      this.setState({ member: response.data, loading: false });
    } else {
      this.setState({ loading: false });
      showAlert({ type: 'error', message: response.data.message });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.memberId !== prevProps.match.params.memberId) {
      const response = await UserService.find(this.props.match.params.memberId);
      this.setState({ member: response.data });
    }
  }

  onCommunityJoinNow = async (community, payload) => {
    const response = await CommunityService.join(community.id, payload);

    if (response.ok) {
      this.setState({ selectedCommunityForPreview: response.data });
      await dispatch.session.fetchUser();
    }
  };

  onOrganizationJoinNow = async (organization) => {
    const response = await OrganizationService.join(organization.id);

    if (response.ok) {
      this.setState({ selectedOrganizationForPreview: response.data });
      await dispatch.session.fetchUser();
    }
  };

  onCommunityExploring = async (community) => {
    this.props.history.push(`/communities/${community.id}`);
  };

  onRequestClose = () => {
    this.setState({
      isCommunityPreviewModalOpen: false,
      selectedCommunityForPreview: null,
      isOrganizationPreviewModalOpen: false,
      selectedOrganizationForPreview: null,
    });
  };

  onClickToCommunity = async (community) => {
    if (this.props.user.joined_communities.map((community) => community.id).includes(community.id)) {
      this.props.history.push(`/communities/${community.id}`);
    } else {
      this.setState({
        isCommunityPreviewModalOpen: true,
        selectedCommunityForPreview: community,
      });
    }
  };

  onClickToOrganization = async (organization) => {
    this.setState({
      isOrganizationPreviewModalOpen: true,
      selectedOrganizationForPreview: organization,
    });
  };

  getJoinedCommunityIds() {
    return this.props.user.joined_communities.map((community) => community.id);
  }

  getJoinedOrganizationIds() {
    return this.props.user.joined_organizations.map((org) => org.id);
  }

  render() {
    const {
      loading,
      member,
      isCommunityPreviewModalOpen,
      selectedCommunityForPreview,
      isOrganizationPreviewModalOpen,
      selectedOrganizationForPreview,
    } = this.state;

    return (
      <AppLayout
        center={
          <ThemeConsumer>
            {(theme) => (
              <React.Fragment>
                {loading ? (
                  <Loading />
                ) : member ? (
                  <MemberProfile
                    member={member}
                    viewer={this.props.user}
                    onClickToCommunity={this.onClickToCommunity}
                    onClickToOrganization={this.onClickToOrganization}
                    viewersJoinedCommunityIds={this.getJoinedCommunityIds()}
                    viewersJoinedOrganizationIds={this.getJoinedOrganizationIds()}
                  />
                ) : null}

                <OrganizationModalDeprecated
                  isOpen={!!isCommunityPreviewModalOpen}
                  onJoinNow={this.onCommunityJoinNow}
                  onRequestClose={this.onRequestClose}
                  type="community"
                  data={selectedCommunityForPreview}
                  onExploring={this.onCommunityExploring}
                />
                <OrganizationModalDeprecated
                  isOpen={!!isOrganizationPreviewModalOpen}
                  onJoinNow={this.onOrganizationJoinNow}
                  onRequestClose={this.onRequestClose}
                  type="organization"
                  data={selectedOrganizationForPreview}
                />
              </React.Fragment>
            )}
          </ThemeConsumer>
        }
      />
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const PageMemberProfile = connect(mapState)(Renderer);
