import * as React from 'react';

import { ThemeConsumer } from '../theme';

export function Tag(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          className={`tags-tag ${props.className || ''}`}
          style={{ border: `1px solid ${theme.color.brand}`, color: theme.color.brand }}
        >
          {props.children}
          {props.accessoryView ? <div style={{ marginLeft: 5 }}>{props.accessoryView}</div> : null}
        </div>
      )}
    </ThemeConsumer>
  );
}
