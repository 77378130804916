import * as React from 'react';
import Popup from 'reactjs-popup';
import { Trans, withTranslation } from 'react-i18next';
import { lastDayOfMonth, startOfMonth, startOfDay, subDays, subMonths, endOfDay } from 'date-fns';
import DayPicker, { DateUtils } from 'react-day-picker';

const TODAY = 0;
const YESTERDAY = 1;
const MONTH = 2;
const LAST_MONTH = 3;
const CUSTOM = 4;

class Renderer extends React.Component {
  state = {
    chosen: MONTH,
    shownCampaigns: [],
    open: false,
    from: new Date(),
    to: new Date(),
  };

  getLabel = (value, menu) => {
    switch (value) {
      case TODAY:
        return <Trans>Today</Trans>;
      case YESTERDAY:
        return <Trans>Yesterday</Trans>;
      case MONTH:
        return <Trans>This month</Trans>;
      case LAST_MONTH:
        return <Trans>Last month</Trans>;
      case CUSTOM:
        if (!menu) {
          return (
            <span>
              {this.props.t('dateRangePicker', {
                dateFrom: new Date(this.state.from),
                dateTo: new Date(this.state.to),
              })}
            </span>
          );
        }
        return <Trans>Custom</Trans>;
      default:
        return <Trans>Today</Trans>;
    }
  };

  onCheck = (menu) => {
    this.setState({ chosen: menu });

    switch (menu) {
      case TODAY:
        this.props.onChange(startOfDay(new Date()), endOfDay(new Date()));
        break;
      case YESTERDAY:
        this.props.onChange(startOfDay(subDays(new Date(), 1)), startOfDay(new Date()));
        break;
      case MONTH:
        this.props.onChange(startOfMonth(new Date()), endOfDay(lastDayOfMonth(new Date())));
        break;
      case LAST_MONTH:
        this.props.onChange(startOfMonth(subMonths(new Date(), 1)), endOfDay(lastDayOfMonth(subMonths(new Date(), 1))));
        break;
      default:
        break;
    }

    if (menu !== CUSTOM) {
      this.setState({ open: true }, () => this.setState({ open: false }));
    }
  };

  closePopup = () => {
    this.setState({ open: true }, () => this.setState({ open: false }));

    this.props.onChange(this.state.from, this.state.to);
  };

  clear = () => this.onCheck(MONTH);

  handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState({ from: startOfDay(range.from), to: endOfDay(range.to) });
  };

  render() {
    return (
      <Popup
        trigger={() => (
          <span className="date-range-picker__trigger">
            {this.getLabel(this.state.chosen)}
            <img alt="arrow down" src="/assets/arrow-down.svg" className="date-range-picker__arrow" />
          </span>
        )}
        position="bottom right"
        offsetY={10}
        open={this.state.open}
        closeOnDocumentClick={true}
        closeOnEscape={true}
        contentStyle={{
          width: this.state.chosen === CUSTOM ? 560 : 257,
          height: this.state.chosen === CUSTOM ? 430 : 222,
          lineHeight: '28px',
          fontSize: '12px',
          fontWeight: 'bold',
        }}
        arrowStyle={{ color: '#22333c', filter: 'none' }}
      >
        {this.state.chosen === CUSTOM ? (
          <div>
            <DayPicker
              className="date-range-picker__date-range"
              numberOfMonths={2}
              selectedDays={{ from: this.state.from, to: this.state.to }}
              modifiers={{ from: this.state.from, to: this.state.to }}
              onDayClick={this.handleDayClick}
            />

            <div className="date-range-picker__buttons">
              <div className="date-range-picker__buttons__button" onClick={this.clear}>
                <Trans>Clear</Trans>
              </div>
              <div className="date-range-picker__buttons__button" onClick={this.closePopup}>
                <Trans>Apply</Trans>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {[TODAY, YESTERDAY, MONTH, LAST_MONTH, CUSTOM].map((menu) => (
              <div className="date-range-picker__menu-item" onClick={() => this.onCheck(menu)} key={menu}>
                {this.getLabel(menu, true)}
                {this.state.chosen === menu && (
                  <img alt="arrow down" src="/assets/check.svg" className="date-range-picker__menu-arrow" />
                )}
              </div>
            ))}
          </div>
        )}
      </Popup>
    );
  }
}

export const DateRangePicker = withTranslation()(Renderer);
