import * as React from 'react';
import { Trans } from 'react-i18next';

import { ThemeConsumer } from '../atoms/theme';
import { Menu, MenuButton, MenuList, MenuItem } from '../atoms/menu';
import { Icon } from '../atoms/icon';
import { Text } from '../atoms/text';
import { DuplicateIcon } from 'ui';

export class EventMenu extends React.Component {
  actions = [
    {
      action: {
        id: 'edit',
        label: <Trans>Edit Event</Trans>,
        handler: this.props.onEditEvent,
        icon: 'pen',
      },
      visible: !!this.props.onEditEvent,
    },
    {
      action: {
        id: 'duplicate',
        label: <Trans>Duplicate</Trans>,
        handler: this.props.onDuplicateEvent,
      },
      visible: !!this.props.onDuplicateEvent,
    },
    {
      action: {
        id: 'delete',
        label: <Trans>Delete Event</Trans>,
        handler: this.props.onDeleteEvent,
        icon: 'delete',
      },
      visible: !!this.props.onDeleteEvent,
    },
    {
      action: {
        id: 'report',
        label: <Trans>Report Event</Trans>,
        handler: this.props.onReportEvent,
        icon: 'report',
      },
      visible: !!this.props.onReportEvent,
    },
  ];

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <Menu>
            <MenuButton style={{ border: 0, background: 'none' }}>
              <div style={{ height: '100%' }}>
                <Icon name="menu" size={21} color="#B0BEC5" />
              </div>
            </MenuButton>
            <MenuList>
              {this.actions
                .filter((action) => action.visible)
                .map((data, index) => (
                  <MenuItem key={data.action.id} onSelect={data.action.handler}>
                    <div style={{ width: 115, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Text color="#ECEFF1" size="13px">
                        {data.action.label}
                      </Text>
                      {data.action.id === 'duplicate' ? (
                        <DuplicateIcon />
                      ) : (
                        <Icon name={data.action.icon} size={15} color="#B0BEC5" />
                      )}
                    </div>
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        )}
      </ThemeConsumer>
    );
  }
}
