import { useQuery } from 'query';
import { type PublicEventContentObjectDataObject } from 'models/service';
import { getEvent } from '../services';
import { getEventQueryKey } from '.';

export const useEventPublicQueryDeprecated = (
  { eventId }: { eventId: string },
  options?: { useErrorBoundary?: boolean },
) => {
  return useQuery<PublicEventContentObjectDataObject>(
    getEventQueryKey({ eventId, view: 'public-deprecated' }),
    async () => {
      const { data } = await getEvent<PublicEventContentObjectDataObject>(eventId);
      return data;
    },
    {
      useErrorBoundary: true,
      ...options,
    },
  );
};
