import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import variables from 'common/styles/variables.json';
import { Col, Row, Button, Form, NumberInput, SelectInput, Title } from 'ui';

type P = {
  name: string;
  disabled?: boolean;
};

const ActivityReminders = ({ name, disabled }: P) => {
  const { t } = useTranslation();

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field) => (
              <Form.Item required={false} key={field.key} className="activity-reminders__item">
                <Row item={{ gutter: variables.spaceMd.value }}>
                  <Col item={{ span: 6 }}>
                    <NumberInput
                      item={{
                        ...field,
                        name: [field.name, 'minutes'],
                        rules: [{ required: true, message: t('This field is required') }],
                      }}
                      input={{
                        min: 0,
                        disabled,
                      }}
                    />
                  </Col>
                  <Col item={{ span: 10 }}>
                    <SelectInput
                      item={{
                        name: [field.name, 'type'],
                        rules: [{ required: true, message: t('This field is required') }],
                      }}
                      input={{
                        placeholder: t('Select type'),
                        options: [
                          {
                            name: t('Minutes'),
                            value: 1,
                          },
                          {
                            name: t('Hours'),
                            value: 60,
                          },
                          {
                            name: t('Days'),
                            value: 1440,
                          },
                        ],
                        getOptionLabel: (option) => option.name,
                        getOptionValue: (option) => option.value,
                        disabled,
                      }}
                    />
                  </Col>
                  {!disabled && (
                    <Col item={{ span: 2, className: 'delete-button-wrapper' }}>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Form.Item>
            ))}
            {!disabled && (
              <Form.Item style={{ marginTop: 10 }}>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  size="large"
                >
                  <PlusOutlined /> {t('Add new reminder')}
                </Button>
              </Form.Item>
            )}
            {disabled && fields.length === 0 ? (
              <Title level={5}>No reminders were created for this activity</Title>
            ) : null}
          </>
        );
      }}
    </Form.List>
  );
};

export default ActivityReminders;
