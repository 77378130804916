import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Form, Formik } from 'formik';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import numbro from 'numbro';
import { ThemeConsumer } from '../theme';
import { Text, H2 } from '../text';
import { Button } from '../button';
import { Radio } from '../form/base-atoms/Radio';
import { CreditCardForm } from '../credit-card-form';
import { Input } from '../form/base-atoms/TextualInput';
import { Modal } from '../modal';
import { TextField, SelectField } from '../form';
import { DonationSuccessInfo } from './DonationSuccessInfo';
import { validator } from '../../utils/validator';
import { Loader } from '../loader';
import { Icon } from '../icon';
import { googleReCaptchaSiteKey } from '../../../common/services/api-keys';
import { Row, Col } from '../../../ui';
import { isFuture, parseISO } from 'date-fns';
import { StripeDonation } from 'features/campaigns';
import { Campaign } from 'models';
import { countries, states } from 'common/utils';

const LABELS = {
  interval: {
    'one-time': <Trans>One time</Trans>,
    monthly: <Trans id="Monthly">Monthly</Trans>,
    quarterly: <Trans id="Quarterly">Quarterly</Trans>,
    annually: <Trans id="Annually">Annually</Trans>,
  },
};

function chargeIntervalToMonths(interval) {
  return {
    'one-time': 'immediately',
    monthly: '1 month',
    quarterly: '3 months',
    annually: '12 months',
  }[interval];
}

// $FlowMissingDefinition
const AmountButton = React.forwardRef((props, ref) => {
  const { active, ...htmlProps } = props;
  return (
    <ThemeConsumer>
      {(theme) => (
        <button
          ref={ref}
          {...htmlProps}
          className="donation-form__amount-button"
          style={{
            border: `1px solid ${theme.color.brand}`,
            backgroundColor: props.active ? theme.color.brand : theme.color.white,
            color: props.active ? theme.color.white : 'inherit',
          }}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      )}
    </ThemeConsumer>
  );
});

class Renderer extends React.Component {
  ccRef = React.createRef();
  payerForm = React.createRef();

  state = {
    // TODO allow amount > 0 for custom input
    amount: this.props.campaign.amounts[0].toString(),
    customAmountPickerActive: false,
    interval: this.props.campaign.intervals[0],
    paymentMethod: null,
    payer: null,
    response: null,
  };

  donate = async (paymentMethod, token) => {
    const response = await this.props.onSubmit({
      amount: this.state.amount,
      interval: this.state.interval,
      paymentMethod: paymentMethod,
      payer: this.state.payer,
      recaptcha_token: token,
    });

    this.setState({ response: response });
  };

  handleAmountChange = () => {
    this.setState((prevState) => ({
      amount: Number(prevState.amount).toFixed(2),
    }));
  };

  renderCCButtonWithModal(theme, minAmount) {
    const { user } = this.props;
    return (
      <Modal
        title={<Trans>Card Details and Card Holder Details</Trans>}
        onAfterOpen={() => this.setState({ paymentMethod: null, payer: null })}
        submitButtonProps={{
          title: this.state.paymentMethod === null ? this.props.t('Donate') : this.props.t('Close'),
          id: this.state.paymentMethod === null ? 'btn_donate' : 'btn_close',
        }}
        onSubmitButtonClick={async () => {
          if (this.state.paymentMethod) {
            return true;
          }

          // $FlowMissingDefinition
          this.payerForm.submitForm();
          return false;
        }}
        trigger={(ref) => (
          <Button
            ref={ref}
            size="lg"
            outline
            color={theme.color.brand}
            disabled={!this.state.amount || this.state.amount < minAmount}
            type="button"
            title={<Trans>Donate</Trans>}
            style={{ margin: '0 auto' }}
            id="btn_donate-with-credit-card"
          />
        )}
      >
        {() => (
          <div style={{ maxWidth: '100%' }}>
            {this.state.paymentMethod ? (
              this.props.loadingPayment ? (
                <Loader />
              ) : this.state.response?.ok ? (
                <DonationSuccessInfo
                  campaign={this.props.campaign}
                  amount={this.state.amount}
                  interval={this.state.interval}
                />
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Icon name="donation-alert" size={100} color={theme.color.brand} />
                  <Text size="22px">
                    <Trans>Your donation was not successful</Trans>
                  </Text>
                </div>
              )
            ) : (
              <>
                <H2 className="donation-form__header">
                  <Trans>Card Details</Trans>
                </H2>
                <CreditCardForm ref={this.ccRef} />

                <H2 className="donation-form__header">
                  <Trans>Card Holder Details</Trans>
                </H2>

                <Formik
                  initialValues={{
                    first_name: user?.first_name ?? '',
                    last_name: user?.last_name ?? '',
                    email: user?.email ?? '',
                    street: user?.street ?? '',
                    apt: user?.apt ?? '',
                    city: user?.city ?? '',
                    zip: user?.zip ?? '',
                    country: user?.country ?? '',
                    state: user?.state ?? '',
                  }}
                  ref={(node) => (this.payerForm = node)}
                  onSubmit={async (values) => {
                    window.grecaptcha.ready(() => {
                      window.grecaptcha
                        .execute(googleReCaptchaSiteKey, { action: 'donation_create' })
                        .then(async (token) => {
                          if (this.ccRef.current) {
                            try {
                              const paymentMethod = await this.ccRef.current.requestPaymentMethod();
                              this.setState({ paymentMethod: paymentMethod, payer: values });
                              this.donate(paymentMethod, token);
                            } catch (paymentRequestError) {
                              console.warn(paymentRequestError);
                            }
                          }
                        });
                    });
                  }}
                  validate={(values) =>
                    validator.validateAll(values, {
                      first_name: 'required',
                      last_name: 'required',
                      email: 'required|email',
                      apt: 'string',
                      state: 'required_if:country,US',
                      street: 'required',
                      city: 'required',
                      zip: 'required',
                    })
                  }
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-xs-6">
                            <TextField
                              name="first_name"
                              placeholder={this.props.t('First name')}
                              label={<Trans>First name</Trans>}
                            />
                          </div>
                          <div className="col-xs-6">
                            <TextField
                              name="last_name"
                              placeholder={this.props.t('Last name')}
                              label={<Trans>Last name</Trans>}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12">
                            <TextField name="email" placeholder={this.props.t('Email')} label={<Trans>Email</Trans>} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-6">
                            <TextField
                              name="street"
                              placeholder={this.props.t('Street')}
                              label={<Trans>Street</Trans>}
                            />
                          </div>
                          <div className="col-xs-6">
                            <TextField
                              name="apt"
                              placeholder={this.props.t('Apt., Suite, Building')}
                              label={<Trans>Apt., Suite, Building</Trans>}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-6">
                            <TextField name="city" placeholder={this.props.t('City')} label={<Trans>City</Trans>} />
                          </div>
                          <div className="col-xs-6">
                            <TextField name="zip" placeholder={this.props.t('ZIP')} label={<Trans>ZIP</Trans>} />
                          </div>
                        </div>

                        <SelectField
                          name="country"
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          label={<Trans>Country</Trans>}
                          options={countries.map((option) => ({
                            label: option.name,
                            value: option.code,
                          }))}
                          placeholder={this.props.t('Country')}
                        />

                        {formik.values['country'] === 'US' && (
                          <SelectField
                            name="state"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            label={<Trans>State</Trans>}
                            options={states.map((option) => ({
                              label: option.name,
                              value: option.code,
                            }))}
                            placeholder={this.props.t('State')}
                          />
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
          </div>
        )}
      </Modal>
    );
  }

  render() {
    const { campaign, user } = this.props;
    const minAmount = 1;

    if (campaign?.finished || isFuture(parseISO(campaign.start_time))) {
      return null;
    }

    return (
      <ThemeConsumer>
        {(theme) => (
          <>
            {campaign.payment_methods.includes('stripe') ? (
              <>
                <div className="donation-form__stripe-donation-button">
                  <StripeDonation
                    // TODO this typing is not good. Constructor is from Redux object now
                    campaign={new Campaign(campaign)}
                    viewer={user}
                  />
                </div>
              </>
            ) : (
              <div className="donation-form" id="about-campaign_bottom">
                <div className="donation-form__row">
                  <div className="donation-form__button-wrapper" id="amounts-buttons">
                    {campaign.amounts.map((amount) => (
                      <AmountButton
                        key={amount}
                        active={this.state.amount === amount.toString()}
                        onClick={() =>
                          this.setState({
                            amount: amount.toString(),
                            customAmountPickerActive: false,
                          })
                        }
                        id={`btn_amount-${amount}`}
                      >
                        {numbro(amount).formatCurrency({
                          mantissa: 2,
                          thousandSeparated: true,
                          optionalMantissa: true,
                        })}
                      </AmountButton>
                    ))}
                  </div>
                </div>
                {campaign.allow_custom_amount ? (
                  <>
                    <div className="donation-form__row">
                      <div className="donation-form__button-wrapper">
                        {this.state.customAmountPickerActive === false ? (
                          <AmountButton
                            onClick={() => this.setState({ customAmountPickerActive: true })}
                            style={{ width: '100%' }}
                            id="btn_other-amounts"
                          >
                            <Trans>Other Amounts</Trans>
                          </AmountButton>
                        ) : (
                          <Row item={{ justify: 'center', align: 'middle' }}>
                            <Col item={{ span: 2, className: 'donation-form__amount-input-prefix' }}>
                              <span className="donation-form__amount-input-prefix__body">$</span>
                            </Col>
                            <Col item={{ span: 22 }}>
                              <Input
                                autoFocus
                                type="number"
                                value={this.state.amount}
                                style={{ border: `border: 1px solid ${theme.color.brand}` }}
                                onChange={(event) => {
                                  this.setState({
                                    amount: event.target.value ? event.target.value : '',
                                  });
                                }}
                                min="1"
                                onBlur={() => {
                                  this.handleAmountChange();
                                }}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    this.handleAmountChange();
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                    {this.state.amount < minAmount && (
                      <div className="donation-form__row donation-form__error">
                        {this.props.t('donationMinimum', { min: minAmount })}
                      </div>
                    )}
                  </>
                ) : null}

                <div className="donation-form__row">
                  {campaign.intervals
                    .filter((interval) => !(!this.props.user && interval !== 'one-time'))
                    .map((interval) => (
                      <React.Fragment key={interval}>
                        <label className="donation-form__radio-label">
                          <Radio
                            name="interval"
                            value={interval}
                            onChange={() => this.setState({ interval: interval })}
                            checked={this.state.interval === interval}
                          />
                          <Text weight="600" lineHeight="28px" style={{ paddingLeft: 10 }}>
                            {LABELS.interval[interval]}
                          </Text>
                        </label>
                      </React.Fragment>
                    ))}
                </div>
                <div className="donation-form__row">
                  <div className="donation-form__recurring-payments" color={theme.color.gray}>
                    <p useDangerouslySetInnerHTML={true}>
                      <Trans
                        defaults="If you would like to donate by check please mail to:<br /> BigHeart Philanthropy<br /> {{ street }} {{ apt }}, {{ city }}, {{ state }} {{ zip }}"
                        values={{
                          country: campaign.country,
                          street: campaign.street,
                          apt: campaign.apt,
                          city: campaign.city,
                          state: campaign.state,
                          zip: campaign.zip,
                        }}
                      />
                    </p>
                    {!this.props.user && (
                      <p>
                        <Trans>
                          If you would like to set up a recurring donation, please sign up on &nbsp;
                          <a href="/landing" target="_blank">
                            this link
                          </a>
                          .
                        </Trans>
                      </p>
                    )}
                  </div>
                </div>

                {this.state.interval !== 'one-time' && (
                  <div className="donation-form__row">
                    <div className="donation-form__recurring-payments" color={theme.color.gray}>
                      {this.props.t('recurringPaymentInfo', { interval: chargeIntervalToMonths(this.state.interval) })}
                    </div>
                  </div>
                )}
                <div className="donation-form__row">
                  <GoogleReCaptchaProvider reCaptchaKey={googleReCaptchaSiteKey}>
                    {this.renderCCButtonWithModal(theme, minAmount)}
                  </GoogleReCaptchaProvider>
                </div>
              </div>
            )}
          </>
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const CampaignDonationForm = withTranslation()(connect(mapState)(Renderer));
