import { type CommunityListItem } from 'models';
import { Modal } from 'ui';

import TelehealthRequestForm from './TelehealthRequestForm';

type P = {
  isOpened: boolean;
  onClose: () => void;
  communities: CommunityListItem[];
  communityId?: string;
};

const TelehealthRequestFormModal = ({ isOpened, onClose, communities, communityId }: P) => {
  return (
    <Modal
      title={'Request telehealth visit'}
      isOpened={isOpened}
      onCancel={() => {
        onClose();
      }}
      disableBack
      destroyOnClose
    >
      <TelehealthRequestForm communities={communities} communityId={communityId} onSuccess={onClose} />
    </Modal>
  );
};

export default TelehealthRequestFormModal;
