import { Trans } from 'react-i18next';

import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { Card as CardA } from 'ui';

export function Card(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div onClick={props.onClick} className={`${props.comingSoon && 'coming-soon'}`}>
          <CardA shrinked className="category-card">
            <div className='category-card__content'>
              <img
                src={(props.category.icon || props.category.photo || props.category.symbol_photo).url}
                alt=""
                className="category-card__image"
              />

              <div className="category-card__label">
                <Text size="15px" weight="600">
                  <Trans>{props.category.name}</Trans>
                </Text>
                {props.comingSoon && (
                  <Text size="12px" weight="500" className="category-card__label__coming_soon" component="div">
                    <Trans>Coming soon</Trans>
                  </Text>
                )}
              </div>
            </div>
          </CardA>
        </div>
      )}
    </ThemeConsumer>
  );
}
