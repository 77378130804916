import { useMutation } from '@tanstack/react-query';

import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { type RequestMagicLink } from '../../models';
import { requestMagicLink } from '../../services';

export const useRequestMagicLinkQuery = () =>
  useMutation<undefined, ApiClientResponseError<DefaultResponseErrorDataObject>, RequestMagicLink>({
    async mutationFn(data: { email: string; redirectUrl: string }) {
      await requestMagicLink(data);
      return undefined;
    },
  });
