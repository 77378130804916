import { routes } from 'routes';
import { Link, Title } from 'ui';

type P = {
  authorId: string;
  title: string;
};

const UserNameLink = ({ authorId, title }: P) => {
  return (
    <Link
      to={routes.members.detail(authorId)}
      className="username-link"
      effects={{ focus: true, hover: true, radius: true }}
    >
      <Title level={5} marginBottom={false}>
        {title}
      </Title>
    </Link>
  );
};

export default UserNameLink;
