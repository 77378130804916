import { AxiosError } from 'axios';
import { dispatch } from 'old/store';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { handleAxiosError } from 'common/error-handlers';
import { Button, Col, Text, Title, Row, Spin, message } from 'ui';
import { useLoginWithTokenQuery, useUserQuery } from '../queries';

type P = {
  onSuccessLogin: () => void;
  onNavigateToPrimaryLogin: () => void;
  token?: string;
};

const MagicLinkCheck = ({ token, onSuccessLogin, onNavigateToPrimaryLogin }: P) => {
  const { t } = useTranslation();

  const { mutateAsync: checkTokenValitidation, error: loginError } = useLoginWithTokenQuery();
  const { mutateAsync: getProfile, error: fetchProfileError } = useUserQuery();

  const checkToken = useCallback(async () => {
    if (!token) {
      return;
    }

    try {
      const loginData = await checkTokenValitidation({ token, type: 'magic_token' });
      await dispatch.session.storeSuccessfulEmailResponse(loginData);
      const profileData = await getProfile();
      await dispatch.session.setUserData(profileData);
      onSuccessLogin();
    } catch (error) {
      if (error instanceof AxiosError) {
        handleAxiosError(error);
      } else {
        message.error(t('Something went wrong, please try again later.'));
      }
    }
  }, [checkTokenValitidation, getProfile, onSuccessLogin, t, token]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <>
      {token && !loginError && !fetchProfileError ? (
        <Spin tip={t('Logging you in')} />
      ) : (
        <Row>
          <Col item={{ span: 24 }}>
            <Title level={2} type="danger">
              {t('Log in link is invalid')}
            </Title>
          </Col>

          <Col item={{ span: 24, className: 'onboarding-magic-link-check__already-sent-text' }}>
            <Text>{t(`Log in link expired or was already used`)}</Text>
          </Col>

          <Col item={{ span: 24, className: 'onboarding-magic-link-check__buttons-section' }}>
            <Button
              block
              onClick={() => {
                onNavigateToPrimaryLogin();
              }}
              size="large"
            >
              {t(`Back to Log in`)}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MagicLinkCheck;
