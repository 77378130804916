import { useEffect, useState } from 'react';

export const usePageCursor = <T,>(dependency?: T) => {
  // eslint-disable-next-line react/hook-use-state
  const state = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPageCursor] = state;

  useEffect(() => {
    setPageCursor(0);
  }, [dependency, setPageCursor]);

  return state;
};
