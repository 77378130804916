import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { ProfileSideNav } from './ProfileSideNav';
import { ChangePasswordForm } from '../../atoms/change-password-form';
import { UserZoneLayout, TABS } from '../../layouts/UserZoneLayout';

class Renderer extends React.Component {
  render() {
    return (
      <UserZoneLayout navigation={<ProfileSideNav user={this.props.user} />} contentId={TABS.profile}>
        <ChangePasswordForm />
      </UserZoneLayout>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const UserChangePassword = connect(mapState)(Renderer);
