import * as React from 'react';

import { Box } from '../box';
import { Text } from '../text';
import { Icon } from '../icon';
import { ThemeConsumer } from '../theme';
import { Menu, MenuButton, MenuList, MenuItem } from '../menu';

export class Filter extends React.Component {
  get currentFilter() {
    const filter = this.props.items.find((i) => i.key === this.props.filter);
    return filter ? filter.name : '';
  }

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <Menu>
            <MenuButton style={{ border: 0, background: 'none', float: 'right' }}>
              <Box flexDirection="row" alignItems="center" style={{ height: 58 }} className="filter__wrapper">
                <Text size={15} style={{ marginRight: 10 }}>
                  {this.props.title}
                </Text>
                <Text size={15} color={theme.color.brand} weight="bold">
                  {this.currentFilter}
                </Text>
                <Icon
                  name="arrow-open-bottom-thin"
                  color={theme.color.brand}
                  size={14}
                  style={{ margin: '0 0 0 9px', padding: '9px 3px 9px 0' }}
                />
              </Box>
            </MenuButton>
            <MenuList>
              {this.props.items.map((menu) => (
                <MenuItem key={menu.key} onSelect={() => this.props.onSort(menu.key)}>
                  {menu.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </ThemeConsumer>
    );
  }
}
