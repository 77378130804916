import { AxiosRequestConfig } from 'axios';
import { ApiClientResponse, mediaModuleClient, routes } from '../../../common/services';

import { UploadProgressEvent } from '../../../ui';
import { MediaDataObject } from '../../data-objects';

export interface CreateMediaRequest extends File {
  name: string;
  type: string;
}

export const createMedia = (file: CreateMediaRequest, onProgress?: (event: UploadProgressEvent) => void) => {
  const uploadFormData: FormData = new FormData();
  const config: AxiosRequestConfig = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: ({ loaded, total, ...rest }) => {
      onProgress?.({
        percent: (loaded / total) * 100,
        ...rest,
      } as UploadProgressEvent);
    },
  };
  uploadFormData.append('file', file);
  uploadFormData.append('name', file.name);
  // TODO change 'size' to be optional for this request
  uploadFormData.append('size', '0');
  uploadFormData.append('type', 'chatroom_attachement');
  uploadFormData.append('mimeType', file.type);
  return mediaModuleClient.post<MediaDataObject, ApiClientResponse<MediaDataObject>>(
    routes.chatrooms.media.create,
    uploadFormData,
    config,
  );
};
