import { useTranslation } from 'react-i18next';
import { Text } from 'ui/index';

type P = {
  prices: { currentPrice: number | 'free'; originalPrice?: number; label?: string };
};

const DataCardPrice = ({ prices: { currentPrice, originalPrice, label } }: P) => {
  const { t } = useTranslation();

  return (
    <>
      <Text className="channels-card-price__price" weight="bold" type="title">{`${
        typeof currentPrice === 'number' ? `$${currentPrice}` : t('Free')
      }`}</Text>
      {originalPrice ? <Text delete type="secondary" size="small">{`$${originalPrice}`}</Text> : null}
      {label ? <Text type="secondary" size="small">{`${label}`}</Text> : null}
    </>
  );
};

export default DataCardPrice;
