import { EventContentObject } from 'models';
import { useQuery } from 'query';
import { type RegisteredEventInviteeDataObject, type EventContentObjectDataObject } from 'models/service';

import { getEvent, getEventInvitees } from '../services';

import { getEventQueryKey } from '.';

export const useEventQuery = ({ eventId }: { eventId: string }, options?: { useErrorBoundary?: boolean }) => {
  return useQuery<EventContentObject>(
    getEventQueryKey({ eventId, view: 'internal' }),
    async () => {
      const { data: eventContenObject } = await getEvent<EventContentObjectDataObject>(eventId);
      const { data: eventUsers } = await getEventInvitees(eventId, { status: 'all', limit: 100_000 });

      const eventWithAllInvitees: EventContentObjectDataObject = {
        ...eventContenObject,
        event: {
          ...eventContenObject.event,
          invitees: eventUsers.filter((user) => user.id !== null) as RegisteredEventInviteeDataObject[], // TODO all users are not anymore part of Event, they need to be load separately
        },
      };

      return new EventContentObject(eventWithAllInvitees);
    },
    {
      useErrorBoundary: true,
      ...options,
    },
  );
};
