export function openPopup(url, name) {
  const width = 640;
  const height = 480;
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  return window.open(
    url,
    name,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
  );
}

export function polling(popup, cb, include) {
  const closeDialog = (polling) => {
    clearInterval(polling);
    if (!popup) return;
    popup.close();
  };

  const polling = setInterval(() => {
    if (!popup || popup.closed || popup.closed === undefined) {
      closeDialog(polling);
      cb(null);
    }

    try {
      if (popup.location.hostname && !popup.location.hostname.includes(include)) {
        if (popup.location.search) {
          cb(new URLSearchParams(popup.location.search));
        } else {
          cb(null);
        }

        closeDialog(polling);
      }
    } catch (error) {
      // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
      // A hack to get around same-origin security policy errors in IE.
    }
  }, 500);
}
