import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans, withTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';
import { Row, Col } from 'antd';

import { AppLayout } from '../../layouts/AppLayout';
import { AvatarGroup } from '../../atoms/avatar-group';
import { Box } from '../../atoms/box';
import { Button } from '../../atoms/button';
import { CourseLayout } from '../../layouts/CourseLayout';
import { CourseTimeline, CourseTopicItem } from '../../atoms/courses-timeline';
import { EventService } from '../../services/EventService';
import { Loader } from '../../atoms/loader';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { can } from '../../utils/permissions';
import { Modal, Card, Button as ButtonAD } from 'ui';

import { CreateEvent } from 'features/events';
import CreatePoll from 'features/polls/CreatePoll';
import CreateActivity from 'features/programs/feed/CreateActivity';
import CreatePost from 'features/posts/CreatePost';
import { CreateWithingsOrderPost } from 'features/programs';
import { onErrorReport, onSuccessReport } from '../../utils/report';

import { ReactComponent as PostIcon } from 'assets/course/post.svg';
import { ReactComponent as ActivityIcon } from 'assets/course/activity.svg';
import { ReactComponent as EventIcon } from 'assets/course/event.svg';
import { ReactComponent as PollIcon } from 'assets/course/poll.svg';
import { ReactComponent as WithingsOrderPostIcon } from 'assets/course/withings_order_post.svg';

class Renderer extends React.Component {
  constructor(props) {
    super(props);
    this.descriptionRef = React.createRef();
  }

  state = {
    hasMoreObjects: true,
    topicItem: null,
    createPost: false,
    createContent: false,
  };

  filterItems = [
    {
      key: 'all',
      name: <Trans>All Objects</Trans>,
    },
    {
      key: 'post',
      name: <Trans>Posts</Trans>,
    },
    {
      key: 'event',
      name: <Trans>Events</Trans>,
    },
  ];

  get item() {
    if (this.state.topicItemId) {
      return this.props.collection.find((item) => item.id === this.state.topicItemId);
    }

    return this.props.collection[0];
  }

  program(course) {
    const { id, topics, author, administrators, start_time, end_time, status, withings_devices } = course;
    const users = administrators ? [...administrators] : [];

    if (!users.find((user) => user.id === author.id)) {
      users.push(author);
    }

    return {
      id,
      topics,
      users,
      start_time,
      end_time,
      status,
      withings_devices,
    };
  }

  onClickToMembers = () => {
    this.props.history.push(`/courses/${this.props.courseId}/members`);
  };

  requestUserProfile = (user) => {
    this.props.history.push(`/members/${user.id}`);
  };

  actions = {
    createComment: this.props.createComment,
    createObject: this.props.createObject,
    createEventObject: this.props.createEventObject,
    updateObject: this.props.updateObject,
    deleteObject: this.props.deleteObject,
    deleteCurriculumObject: this.props.deleteCurriculumObject,
    likeObject: this.props.likeObject,
    bookmarkObject: this.props.bookmarkObject,
    loadCollection: this.props.loadCollection,
    reportObject: this.props.reportObject,
    sortChange: this.props.sortChange,
    requestUserProfile: this.requestUserProfile,
    reloadObject: this.props.reloadObject,
  };

  getNextObjects = async (reset = false) => {
    if (!this.props.loading) {
      const lastObject = this.props.collection[this.props.collection.length - 1];

      const resp = await this.props.loadCollection({
        program_topic_id: this.state.topicId,
        cursor: !reset && lastObject ? lastObject.id : undefined,
        reset,
      });

      this.setState({ hasMoreObjects: resp.ok && resp.data.length > 0 });
    }
  };

  getEventDetail(event_id) {
    return EventService.find(event_id);
  }

  onSelectTopic = async (topic) => {
    this.setState({ topicId: topic ? topic.id : topic, topicItemId: null }, () => {
      if (topic) {
        this.getNextObjects(true);
      }
    });
  };

  onSelectPost = (post) => {
    this.setState({ topicItemId: post?.id });
  };

  onOpenCreateEvent = () => {
    this.setState({ isCreateEventOpened: true });
  };

  onCancelEvent = (force) => {
    if (force || window.confirm(this.props.t('Do you wish to discard your Event?'))) {
      this.setState({ isCreateEventOpened: false });
    }
  };

  onOpenCreatePost = () => {
    this.setState({ isCreatePostOpened: true });
  };

  onCancelPost = () => {
    if (window.confirm(this.props.t('Do you wish to discard your Post?'))) {
      this.setState({ isCreatePostOpened: false });
    }
  };

  onOpenCreateActivity = () => {
    this.setState({ isCreateActivityOpened: true });
  };

  onCancelActivity = () => {
    if (window.confirm(this.props.t('Do you wish to discard your Activity?'))) {
      this.setState({ isCreateActivityOpened: false });
    }
  };

  onOpenCreatePoll = () => {
    this.setState({ isCreatePollOpened: true });
  };

  onCancelPoll = () => {
    if (window.confirm(this.props.t('Do you wish to discard your Poll?'))) {
      this.setState({ isCreatePollOpened: false });
    }
  };

  onOpenCreateOrder = () => {
    this.setState({ isCreateOrderOpened: true });
  };

  onCancelCreateOrder = () => {
    if (window.confirm(this.props.t('Do you wish to discard your Order?'))) {
      this.setState({ isCreateOrderOpened: false });
    }
  };

  onCreateEvent = () => {
    this.onCancelEvent(true);
    this.getNextObjects(true);
  };

  onCreateActivity = () => {
    this.setState({ isCreateActivityOpened: false, createContent: false });
    this.getNextObjects(true);
  };

  onCreatePoll = () => {
    this.setState({ isCreatePollOpened: false, createContent: false });
    this.getNextObjects(true);
  };

  onCreatePost = () => {
    this.setState({ isCreatePostOpened: false, createContent: false });
    this.getNextObjects(true);
  };

  onCreateOrder = () => {
    this.setState({ isCreateOrderOpened: false, createContent: false });
    this.getNextObjects(true);
  };

  onEditObject = () => {
    this.getNextObjects(true);
  };

  addNewContent = (course) => {
    return (
      <Row gutter={16} className="timeline-create-content">
        <Col span={24} className="timeline-create-content__text">
          <Trans>What type of content do you want to create?</Trans>
        </Col>
        <Col span={12} className="timeline-create-content__button">
          <Button
            title={
              <>
                <PostIcon />
                <Trans>Create Post</Trans>
              </>
            }
            color="transparent"
            textColor="#717E94"
            size="md"
            style={{ lineHeight: '18px' }}
            data-test-id="btn_course-add-post"
            onClick={() => {
              this.setState({ createContent: false });
              this.onOpenCreatePost();
            }}
          />
        </Col>
        <Col span={12} className="timeline-create-content__button">
          <Button
            title={
              <>
                <EventIcon />
                <Trans>Create Event</Trans>
              </>
            }
            color="transparent"
            textColor="#717E94"
            size="md"
            onClick={() => {
              this.setState({ createContent: false });
              this.onOpenCreateEvent();
            }}
            style={{ lineHeight: '18px' }}
            data-test-id="btn_course-add-event"
          />
        </Col>
        <Col span={12} className="timeline-create-content__button">
          <Button
            title={
              <>
                <PollIcon />
                <Trans>Create Poll</Trans>
              </>
            }
            color="transparent"
            textColor="#717E94"
            size="md"
            onClick={() => {
              this.setState({ createContent: false });
              this.onOpenCreatePoll();
            }}
            style={{ lineHeight: '18px' }}
          />
        </Col>
        <Col span={12} className="timeline-create-content__button">
          <Button
            title={
              <>
                <ActivityIcon />
                <Trans>Create Activity</Trans>
              </>
            }
            color="transparent"
            textColor="#717E94"
            size="md"
            onClick={() => {
              this.setState({ createContent: false });
              this.onOpenCreateActivity();
            }}
            style={{ lineHeight: '18px' }}
          />
        </Col>
        {course.withings_devices?.length > 0 && (
          <Col span={12} className="timeline-create-content__button">
            <Button
              title={
                <>
                  <WithingsOrderPostIcon />
                  <Trans>Create Withings order</Trans>
                </>
              }
              color="transparent"
              textColor="#717E94"
              size="md"
              onClick={() => {
                this.setState({ createContent: false });
                this.onOpenCreateOrder();
              }}
              style={{ lineHeight: '18px' }}
            />
          </Col>
        )}
      </Row>
    );
  };

  onRequestReturn = () => {
    this.setState({
      isCreateEventOpened: false,
      isCreatePostOpened: false,
      isCreatePollOpened: false,
      isCreateActivityOpened: false,
      isCreateOrderOpened: false,
      createContent: true,
    });
  };

  renderNewPostModal = (course, theme) => (
    <React.Fragment>
      <ButtonAD
        data-test-id="btn_course-add-post"
        onClick={() => this.setState({ createContent: true })}
        size="large"
        type="primary"
      >
        Create content
      </ButtonAD>

      <Modal
        title={this.props.t('Create content')}
        isOpened={this.state.createContent}
        onCancel={() => this.setState({ createContent: false })}
        steps={[this.addNewContent(course)]}
        disableBack={true}
      />
      <CreateEvent
        viewer={this.props.viewer}
        program={this.program(course)}
        isOpened={this.state.isCreateEventOpened}
        onCancel={() => this.onCancelEvent(false)}
        onSuccess={this.onCreateEvent}
        onRequestReturn={this.onRequestReturn}
      />
      <CreatePoll
        program={this.program(course)}
        isOpened={this.state.isCreatePollOpened}
        onCancel={this.onCancelPoll}
        onSuccess={this.onCreatePoll}
        onRequestReturn={this.onRequestReturn}
      />
      <CreatePost
        program={this.program(course)}
        isOpened={this.state.isCreatePostOpened}
        onCancel={this.onCancelPost}
        onSuccess={this.onCreatePost}
        onRequestReturn={this.onRequestReturn}
      />

      {this.state.isCreateActivityOpened && (
        <CreateActivity
          program={this.program(course)}
          isOpened={this.state.isCreateActivityOpened}
          onCancel={this.onCancelActivity}
          onSuccess={this.onCreateActivity}
          onRequestReturn={this.onRequestReturn}
        />
      )}
      {this.state.isCreateOrderOpened && (
        <CreateWithingsOrderPost
          program={this.program(course)}
          isOpened={this.state.isCreateOrderOpened}
          onCancel={this.onCancelCreateOrder}
          onSuccess={this.onCreateOrder}
          onRequestReturn={this.onRequestReturn}
        />
      )}
    </React.Fragment>
  );

  renderTopPanel = (course, theme) => (
    <Card>
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box flexDirection="row" alignItems="center">
          {this.renderNewPostModal(course, theme)}
        </Box>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: 20, cursor: 'pointer' }} onClick={this.onClickToMembers}>
            {course.users && <AvatarGroup size={34} users={course.users.slice(0, 3)} total={course.members_count} />}
          </div>
        </div>
      </Box>
    </Card>
  );

  renderItemContent = (course, viewer) => {
    const { item } = this;
    const currentTime = new Date();

    if (this.props.loading || !course?.topics) {
      return (
        <div>
          <Loader size={24} />
        </div>
      );
    }

    if (item && (isAfter(currentTime, new Date(item.publish_at)) || can(course, 'Course', 'seeFuturePosts'))) {
      return (
        <CourseTopicItem
          item={item}
          course={course}
          actions={this.actions}
          viewer={viewer}
          getEventDetail={this.getEventDetail}
          onEditObject={this.onEditObject}
          data-test-id="course_topic-item"
        />
      );
    }

    return (
      <div className="timeline-create-content__select-post-text">
        <Text>
          <Trans>Select some post</Trans>
        </Text>
      </div>
    );
  };

  render() {
    const { viewer } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <AppLayout
            center={
              <CourseLayout
                courseId={this.props.courseId}
                fetchUsers={true}
                viewer={viewer}
                history={this.props.history}
              >
                {(course) => (
                  <div className="row">
                    <div className="col-xs-5">
                      {course?.topics ? (
                        <CourseTimeline
                          course={course}
                          topics={course.topics}
                          items={this.props.collection}
                          loading={this.props.loading}
                          onSelectTopic={this.onSelectTopic}
                          onSelectPost={this.onSelectPost}
                        />
                      ) : (
                        <div>
                          <Loader size={24} />
                        </div>
                      )}
                    </div>
                    <div className="col-xs-7">
                      {can(course, 'Course', 'createProgramContent') ? this.renderTopPanel(course, theme) : null}
                      <Card shrinked style={{ overflow: 'hidden' }}>
                        {this.renderItemContent(course, viewer)}
                      </Card>
                    </div>
                  </div>
                )}
              </CourseLayout>
            }
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => {
  const feed = `feed?course=${props.match.params.courseId}`;
  return {
    courseId: props.match.params.courseId,
    viewer: select.session.user(state),
    collection: select.feed.get(state, feed),
    loading: select.feed.loading(state, { feed: feed }),
  };
};

const mapDispatch = (dispatch, props) => {
  const feed = `feed?course=${props.match.params.courseId}`;
  return {
    reloadObject: (postId) => {
      return dispatch.feed.reloadAsync({ feed, postId });
    },
    createComment: (draft, parent_id) => {
      return dispatch.feed.createAsync({ draft: draft, parent_id: parent_id, feed, comment: true });
    },
    createObject: (draft) => {
      return dispatch.feed.createAsync({ draft: draft, parent_id: null, feed });
    },
    createEventObject: (event) => {
      return dispatch.feed.createEvent({ event, feed });
    },
    deleteCurriculumObject: async ({ object }) => {
      if (await window.confirm('Delete this Post/Event?')) {
        return dispatch.feed.deleteAsync({ object: object, feed });
      }
    },
    updateObject: async (object) => {
      return dispatch.feed.updateAsync({ object: object, feed });
    },
    likeObject: (object) => {
      return dispatch.feed.likeAsync({ object: object });
    },
    bookmarkObject: (object) => {
      return dispatch.feed.bookmarkAsync({ object: object });
    },
    loadCollection: (params) => {
      return dispatch.feed.getAsync({
        feed: feed,
        params: {
          ...params,
          program_id: props.match.params.courseId,
          limit: 1000,
        },
      });
    },
    deleteObject: async ({ object, originalItem }) => {
      if (await window.confirm('Delete this Comment?')) {
        return dispatch.feed.deleteAsync({ object, originalItem });
      }
    },
    reportObject: async (object) => {
      if (await window.confirm('Report this Post/Event/Comment?')) {
        return dispatch.feed.reportAsync({ object: object, onSuccess: onSuccessReport, onError: onErrorReport });
      }
    },
    acceptInvitation: () => dispatch.courses.accept(props.match.params.courseId),
    declineInvitation: () => dispatch.courses.decline(props.match.params.courseId),
    sortChange: async (object, sort) => {
      return dispatch.feed.sortCommentsAsync({ object, sort });
    },
  };
};

export const PageCourseTimeline = withTranslation()(connect(mapState, mapDispatch)(Renderer));
