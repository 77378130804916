import * as React from 'react';
import { Trans } from 'react-i18next';

import { Text } from '../text';
import { Button } from '../button';
import { ThemeConsumer } from '../theme';
import { images } from 'common/utils';

export class CampaignsListing extends React.Component {
  render() {
    return (
      <ThemeConsumer>
        {(theme) =>
          this.props.campaignsByOrganization.map((group) => {
            return (
              <div key={group.organization.id}>
                {this.props.campaignsByOrganization.length > 1 && (
                  <Text color={theme.color.brand} size={20} component="div" weight="600" style={{ marginBottom: 20 }}>
                    {group.organization.name}
                  </Text>
                )}
                {group.collection.map((campaign) => (
                  <div
                    key={campaign.id}
                    className="campaign-teaser"
                    style={{ backgroundImage: `url(${campaign.cover_photo?.url ??  images.default.campaignCoverPhoto})` }}
                    id={`campaign-${campaign.id}`}
                  >
                    <div className="campaign-teaser__body-wrapper">
                      <div className="campaign-teaser__body">
                        <Text
                          size="16px"
                          lineHeight="20px"
                          color={theme.color.white}
                          className={'campaign-teaser__body__name'}
                        >
                          {campaign.name}
                        </Text>
                        <div className="campaign-teaser__button">
                          <Button
                            type="button"
                            onClick={() => this.props.onRequestCampaignDetail(campaign)}
                            textColor={theme.color.white}
                            color={theme.color.green}
                            size="md"
                            title={<Trans>Donate</Trans>}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })
        }
      </ThemeConsumer>
    );
  }
}
