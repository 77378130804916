import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, modalConfirm, ModalFormActions, modalWarning, Section, TextAreaInput, Text, Title } from 'ui';
import { useRemoveUserAccountQuery } from './queries';

type P = {
  logOut: () => void;
};

const UserRemoveAccount = ({ logOut }: P) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const {
    mutate: removeAccount,
    isLoading: removeAccountIsLoading,
    status: removeAccountStatus,
  } = useRemoveUserAccountQuery();

  useEffect(() => {
    if (removeAccountStatus === 'success') {
      modalWarning({
        title: t('Remove account'),
        content: <Text>{t('Account removal request was sent. You will now be logged out from your account.')}</Text>,
        okText: t('Sign out'),
        onOk() {
          logOut();
        },
        onCancel() {
          logOut();
        },
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        okButtonProps: { danger: true },
      });
    }
  }, [logOut, removeAccountStatus, t]);

  const onFinish = (data: { deleteReason: string }) => {
    modalConfirm({
      title: t('Remove account'),
      content: <Text>{t('Do you want to remove account?')}</Text>,
      cancelText: t('Cancel'),
      okText: t('Remove account'),
      onOk() {
        removeAccount({ deleteReason: data.deleteReason });
      },
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okButtonProps: { danger: true },
    });
  };

  return removeAccountIsLoading ? (
    <Col item={{ style: { textAlign: 'center', fontSize: 24 } }}>
      <LoadingOutlined />
    </Col>
  ) : (
    <>
      <Title level={3}>Remove your account?</Title>
      <Text>
        Removing this account will delete all of its messages, contacts, and other data from the platform. This can’t be
        undone.
      </Text>
      <Form onFinish={onFinish} form={form}>
        <Section paddingTop paddingBottom>
          <TextAreaInput
            item={{
              name: 'deleteReason',
              label: 'Reason of your request',
              rules: [
                {
                  whitespace: true,
                },
              ],
            }}
          />
          <Text>You will be logged out from your account immediately after this action.</Text>
        </Section>
        <ModalFormActions submit={{ children: 'Remove account', id: 'btn_remove-account', danger: true }} />
      </Form>
    </>
  );
};

export default UserRemoveAccount;
