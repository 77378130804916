import { useTranslation } from 'react-i18next';
import { HeartFilledIcon, HeartIcon, Row, Text } from 'ui';

type P = {
  count: number;
  liked: boolean;
};

const LikeCount = ({ count, liked }: P) => {
  const { t } = useTranslation();
  return (
    <Row item={{ className: 'like-count', align: 'middle' }}>
      {liked ? (
        <HeartFilledIcon className="like-count__like-icon__filled" />
      ) : (
        <HeartIcon className="like-count__like-icon" />
      )}
      <Text type="secondary">{t('likesCount', { count })}</Text>
    </Row>
  );
};

export default LikeCount;
