import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type EnterEventDataObject } from 'models/service';

type EnterEventRequestData = {
  exit_uri: string;
  invitation_token?: string;
  user_ids?: string[];
  first_name?: string;
  last_name?: string;
  email?: string;
};

export const enterEvent = async (id: string, data: EnterEventRequestData) =>
  apiClient.post<EnterEventDataObject, ApiClientResponse<EnterEventDataObject>>(routes.events.enter(id), data);
