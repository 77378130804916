import { type ReduxUser } from 'models';
import { dispatch } from 'old/store';
import { useParams } from 'react-router-dom';
import { CommunityLayout, CommunityAboutContent } from 'features/communities';

type RoutingParams = {
  communityId: string;
};

type P = {
  viewer: ReduxUser;
};

export const PageCommunityAbout = ({ viewer }: P) => {
  const { communityId } = useParams<RoutingParams>();

  return (
    <CommunityLayout
      viewer={viewer}
      communityId={communityId}
      tab="/about"
      // eslint-disable-next-line react/jsx-handler-names
      onStatusChange={dispatch.session.fetchUser}
      children={(community) => <CommunityAboutContent community={community} viewer={viewer} />}
    />
  );
};
