import { type Form, InputNumber } from 'antd';
import type React from 'react';
import { Item } from './Item';

type ItemP = Omit<React.ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
};

type InputP = Omit<React.ComponentProps<typeof InputNumber>, 'css'> & {
  'data-test-id'?: string;
};

type P = {
  item: ItemP;
  input?: InputP;
};

const NumberInput = ({ item, input }: P) => {
  return (
    <Item {...item}>
      <InputNumber {...input} style={{ width: '100%' }} type="number" />
    </Item>
  );
};

export default NumberInput;
