import { type ReduxUser, type ContentObjectRedux } from 'models';
import { canCommentContentObject } from './canCommentContentObject';

export const canShowComments = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject: ContentObjectRedux;
}) => {
  return contentObject.comments_count > 0 || canCommentContentObject({ viewer, contentObject });
};
