import { type Community, type ReduxUser } from 'models';
import { ContentWrapper } from 'ui';

import { Description, Administrators, About } from '../components';

type P = {
  community: Community;
  viewer: ReduxUser;
};

const CommunityAboutContent = ({ community, viewer }: P) => (
  <ContentWrapper
    aside={
      <>
        <About community={community} viewer={viewer} />
        <Administrators community={community} viewer={viewer} />
      </>
    }
  >
    <Description community={community} viewer={viewer} />
  </ContentWrapper>
);

export default CommunityAboutContent;
