import apiClient, { type ApiClientResponse } from 'common/services/deprecatedApi';
import { type Social } from 'models/content-object';
import type Post from 'models/post';
import { type ContentObjectDataObjectDeprecated } from 'models/service/content-object-deprecated';

const api = {
  getPost: async (post: string) =>
    apiClient.get<ContentObjectDataObjectDeprecated, ApiClientResponse<ContentObjectDataObjectDeprecated>>(
      `/posts/${post}`,
    ),

  createPost: async (post: Post<Social>) =>
    apiClient.post<Post<Social>, ApiClientResponse<Post<Social>>>(`/posts`, { post }),

  editPost: async (post: Post<Social>) =>
    apiClient.put<Post<Social>, ApiClientResponse<Post<Social>>>(`/posts/${post.id}`, { post }),
};

export default api;
