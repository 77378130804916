import { type ReduxUser, type CommunityListItem, type EventPrivacy } from 'models';
import { useTranslation } from 'react-i18next';
import { canViewChannelsSection } from 'permissions';
import { CheckboxInput, Form, HelpIcon, TagsInput, TextInput, Title, Tooltip, Text, Alert, Section } from 'ui';

type RequiredFormValues = {
  communityId?: string;
  organizationId?: string;
  viewPermission: EventPrivacy;
  showInChannels?: boolean;
  featuredInChannels?: boolean;
  tags?: string[];
};

type P = {
  communities: CommunityListItem[];
  viewer: ReduxUser;
};

const EventChannelsOptions = <FormValues extends RequiredFormValues>({ communities, viewer }: P) => {
  const { t } = useTranslation();

  return (
    <>
      <TextInput item={{ name: 'organizationId', hidden: true }} />
      <Form.Item<FormValues> noStyle dependencies={['organizationId', 'viewPermission']}>
        {({ getFieldsValue, setFieldValue }) => {
          const { organizationId, communityId, viewPermission } = getFieldsValue(true) as Partial<FormValues>;

          const community = communities.find((community) => community.id === communityId);
          const organization = community?.organization;

          const isCommunitySecret = community?.privacy === 'secret';
          const areAllMembersInvited = viewPermission === 'public';

          const channelType = organization?.customChannels ? 'custom' : 'default';

          if (
            communityId &&
            canViewChannelsSection({ viewer, organizationId }) &&
            !isCommunitySecret &&
            areAllMembersInvited
          ) {
            return (
              <>
                <Title level={4}>Channels</Title>
                <Section lineBottom={false}>
                  <Alert
                    message={
                      <>
                        <Text>{t('The selected community is part of an organization that is using')}</Text>{' '}
                        <Text strong>{t(channelType)}</Text> <Text>{t('channels')}</Text>
                      </>
                    }
                    type="info"
                    showIcon
                  />
                </Section>
                <TagsInput
                  item={{ name: 'tags', label: 'Tags' }}
                  input={{
                    options: organization?.tags?.map((tagItem) => ({
                      value: tagItem.id,
                      label: tagItem.name,
                    })),
                    emptyProps: {
                      description: t('The selected community is in an organization that has no tags defined.'),
                    },
                  }}
                />
                <CheckboxInput
                  item={{
                    label: (
                      <Text>
                        {t('Show event in Channels')}{' '}
                        <Tooltip
                          item={{
                            title:
                              'Event will display in your organization’s channels. Everyone in your organization will be able to join the event.',
                          }}
                        >
                          <HelpIcon />
                        </Tooltip>
                      </Text>
                    ),
                    name: 'showInChannels',
                    valuePropName: 'checked',
                  }}
                  input={{
                    onChange() {
                      // TODO find better approach - `setFieldValue` does not inherit type from `FormValues`
                      setFieldValue('featuredInChannels', false);
                    },
                  }}
                />
                <Form.Item<FormValues> noStyle dependencies={['showInChannels']}>
                  {({ getFieldsValue }) => {
                    const { showInChannels } = getFieldsValue(true) as Partial<FormValues>;

                    if (showInChannels) {
                      return (
                        <CheckboxInput
                          item={{
                            label: (
                              <Text>
                                {t('Pin event as featured in Channels')}{' '}
                                <Tooltip item={{ title: 'Event will appear as featured in Channels.' }}>
                                  <HelpIcon />
                                </Tooltip>
                              </Text>
                            ),
                            name: 'featuredInChannels',
                            valuePropName: 'checked',
                          }}
                        />
                      );
                    }

                    return null;
                  }}
                </Form.Item>
              </>
            );
          }

          return null;
        }}
      </Form.Item>
    </>
  );
};

export default EventChannelsOptions;
