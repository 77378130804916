import { transformError, transformResponse } from '../common/recoil/queries';
import { ID } from '../common/types';
import { Chatroom, Message } from '../models';
import { statusChatroom } from '../services';
import { ChatroomDataObject } from '../services/data-objects';

const setChatroomStatusToRead = async (chatroomId: ID) => {
  try {
    const response = await statusChatroom(chatroomId, {
      status: 'read',
    });

    return transformResponse<ChatroomDataObject, Chatroom>(
      response,
      ({ statuses, message, ...rest }) =>
        new Chatroom({
          ...rest,
          message: message ? new Message(message) : undefined,
          statuses: statuses.map((status) => new Message(status)),
        }),
    );
  } catch (error) {
    return transformError(error);
  }
};

export default setChatroomStatusToRead;
