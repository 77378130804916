import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { useCommunitiesInfiniteQuery } from 'features/communities/queries'; // TODO import from another feature
import { Card, Link, Skeleton, Title, Breadcrumb } from 'ui';
import { ChannelsEmpty, CommunitiesSlider, EntityList } from '../components';
import { useOrganizationIdForRequests } from '../hooks';
import { useOrganizationsQuery } from '../queries';

type P = {
  getPathToCommunityDetail: (id: string) => string;
  navigateToJoinedCommunities: () => void;
  organizationId: string;
};

const ChannelsCommunities = ({ getPathToCommunityDetail, navigateToJoinedCommunities, organizationId }: P) => {
  const { t } = useTranslation();
  // TODO Solve default organization logic in better way - perhaps BE update?
  const { data: channelsOrganizations, isLoading: isChannelsOrganizationLoading } = useOrganizationsQuery();
  const organizationIdForRequests = useOrganizationIdForRequests(organizationId, channelsOrganizations);

  const {
    data: allCommunitiesData,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    fetchNextPage,
    hasNextPage,
  } = useCommunitiesInfiniteQuery({
    scope: 'channels',
    pageSize: 12,
    organizationId: organizationIdForRequests,
  });

  const allCommunities = allCommunitiesData?.pages.flat().flatMap((communitiesPaginated) => communitiesPaginated.data);

  const breadcrumbRoutes = [
    {
      path: routes.channels.index,
      breadcrumbName: t('Channels'),
    },
    {
      path: routes.channels.organizationChannel(organizationId, 'communities'),
      breadcrumbName: t('Communities'),
    },
  ];

  return (
    <>
      <Breadcrumb
        routes={breadcrumbRoutes.map((route) => ({ ...route, breadcrumbName: t(route.breadcrumbName) }))}
        itemRender={({ path, breadcrumbName }: { path: string; breadcrumbName: string }) => {
          return <Link to={path}>{breadcrumbName}</Link>;
        }}
      />
      <Title>{t('Communities')}</Title>
      {isChannelsOrganizationLoading || isLoadingAll || !allCommunities || !channelsOrganizations ? (
        <Skeleton active paragraph={{ rows: 16 }} />
      ) : (
        <>
          <CommunitiesSlider
            getPathToCommunityDetail={getPathToCommunityDetail}
            navigateToJoinedCommunities={navigateToJoinedCommunities}
            organizationId={organizationIdForRequests}
            title="Featured"
            view="detail"
          />
          <Card>
            <Title level={3}>{t('All')}</Title>
            <EntityList
              getPathToEntityDetail={getPathToCommunityDetail}
              empty={<ChannelsEmpty scope="communities" navigateToJoined={navigateToJoinedCommunities} />}
              entities={allCommunities}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetching={isFetchingAll}
              isLoading={isLoadingAll}
              showPrice
            />
          </Card>
        </>
      )}
    </>
  );
};

export default ChannelsCommunities;
