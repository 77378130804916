import { type NotificationSettingDataObject } from './service';

export class NotificationSetting {
  value: boolean;
  changeAllowed: boolean;

  constructor(data: NotificationSettingDataObject) {
    this.value = data.value;
    this.changeAllowed = data.change_allowed;
  }
}

export class UnreadMessages {
  user: string;
  unread: number;

  constructor({ user, unread }: { user: string; unread: number }) {
    this.user = user;
    this.unread = unread;
  }
}
