import { type SpinSize } from 'antd/lib/spin';
import { Row, Col, Spin } from 'ui';

type P = {
  className?: string;
  size?: SpinSize;
};

const Loading = ({ className, size }: P) => (
  <Row item={{ justify: 'center', align: 'middle', className }}>
    <Col>
      <Spin size={size ?? 'large'} />
    </Col>
  </Row>
);

export default Loading;
