import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const DonationsService = {
  get: (params) => http.get('/donations', params),
  getSummary: (params) => http.get('/donations/summary.json', params),
  update: (id, payload) => http.put(`/donations/${id}`, { donation: payload }),
  delete: (id) => http.delete(`/donations/${id}`),
  donate: (payload) => http.post('/donations', { donation: payload }),
  createCheque: (payload) => http.post('/donations', { donation: payload }),
  cancelRecurringDonation: (id) => http.delete(`/donations/${id}`),
  getDonationsForCurrentUser: () => http.get(`/donations/current`),
  sendReport: (params) => http.post('/donations/report', params),
};
