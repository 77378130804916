import { isProgramContentObject, type ContentObjectRedux } from 'models';
import { type ReduxUser } from 'models/user';
import { BasicStatusBar } from './BasicStatusBar';
import { ProgramStatusBar } from './ProgramStatusBar';

type P = {
  item: ContentObjectRedux;
  viewer: ReduxUser;
  onShowComments?: () => void;
};

const StatusBar = ({ viewer, item, onShowComments }: P) => {
  return isProgramContentObject(item) ? (
    <ProgramStatusBar viewer={viewer} item={item} onShowComments={onShowComments} />
  ) : (
    <BasicStatusBar item={item} onShowComments={onShowComments} />
  );
};

export default StatusBar;
