import { type ReduxUser } from 'models';
import { isLive } from 'old/utils/events';
import { canManageCommunityRedux } from './canManageCommunity';

export const canDeleteContentObject = ({
  viewer,
  contentObject,
  view = 'feed',
}: {
  viewer: ReduxUser;
  contentObject: any;
  view?: 'detail' | 'feed'; // TODO fix detail logic
}) => {
  if (view === 'feed' && contentObject?.program) {
    return false;
  }

  switch (contentObject?.type) {
    case 'event':
      return canDeleteEvent({ viewer, contentObject });
    case 'kudo':
    case 'post':
    case 'poll':
      return (
        isAuthor({ viewer, contentObject }) ||
        canManageAnyCommunity({ viewer, postInCommunities: contentObject?.post_in_communities })
      );
    default:
      return false;
  }
};

const canDeleteEvent = ({ viewer, contentObject }: { viewer: ReduxUser; contentObject: any }) => {
  const isTelehealthVisit = contentObject.event?.event_type === 'telehealth_visit';
  const isEventLive = isLive(contentObject.event);

  if (isTelehealthVisit || isEventLive) {
    return false;
  }

  return (
    isAuthor({ viewer, contentObject }) ||
    canManageAnyCommunity({ viewer, postInCommunities: contentObject?.post_in_communities })
  );
};

const isAuthor = ({ viewer, contentObject }: { viewer: ReduxUser; contentObject: any }) =>
  viewer.id === contentObject?.author?.id;

const canManageAnyCommunity = ({ viewer, postInCommunities }: { viewer: ReduxUser; postInCommunities?: any[] }) =>
  postInCommunities?.some?.((community) => canManageCommunityRedux({ viewer, community }));
