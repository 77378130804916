import { type Image, type EventType, type EventPrivacy } from 'models';
import { apiClient, type ApiClientResponse, routes } from 'common/services';
import {
  type EventContentObjectDataObject,
  type EventEmailInviteeDataObject,
  type RegisteredEventInviteeDataObject,
} from 'models/service';

export type AttendeesRequestData = {
  invitees: RegisteredEventInviteeDataObject[];
  email_invitees: EventEmailInviteeDataObject[];
};

type CommonEventRequestData = AttendeesRequestData & {
  name: string;
  community_id?: string;
  allow_guest?: boolean;
  cover_photo?: Image;
  text_content?: string;
  html_content?: string;
  start_time: Date;
  end_time: Date;
};

export type DefaultEventRequestData = CommonEventRequestData & {
  publish_at?: Date;
  program_id?: string;
  program_topic_id?: string;
  event_type: Exclude<EventType, 'telehealth_visit'>;
  view_permission: EventPrivacy;
  events_files?: Array<{ id: string }>;
  tag_ids?: string[];
  show_in_channels?: boolean;
  featured_in_channels?: boolean;
};

export type MetaItemRequestData = {
  name: string;
  translations: {
    en: string;
    es: string;
  };
};

export type MetaRequestData = {
  patient_type?: MetaItemRequestData;
  service_type?: MetaItemRequestData[];
  admission?: { _id: string };
};

export type TelehealthEventRequestData = CommonEventRequestData & {
  allow_guest: false;
  event_type: 'telehealth_visit';
  view_permission: 'private';
  meta?: MetaRequestData;
  change_request_id?: string;
};

export type EventRequestData = DefaultEventRequestData | TelehealthEventRequestData;

export const createEvent = async (event: EventRequestData) =>
  apiClient.post<EventContentObjectDataObject, ApiClientResponse<EventContentObjectDataObject>>(routes.events.create, {
    event,
  });
