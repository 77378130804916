import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ChannelEntityDataObject } from 'models/service';
import { type EntityType } from '../types';

export const getChannelEntities = async (
  channelId: string,
  params: {
    limit?: number;
    offset?: number;
    featured?: boolean;
    subject?: EntityType;
  },
) =>
  apiClient.get<ChannelEntityDataObject[], ApiClientResponse<ChannelEntityDataObject[]>>(
    routes.channels.entities.get(channelId),
    {
      params,
    },
  );
