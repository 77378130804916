import React from 'react';
import { Trans } from 'react-i18next';

import { convertCanvasToFile } from '../whiteboard/helpers';
import { ImageCropper } from './ImageCropper';
import { StorageService } from '../../services/StorageService';
import { Button, Modal } from 'ui';

export class ImageCropperModal extends React.Component {
  state = {
    isOpen: false,
    uploading: false,
  };

  $imageCropper = null;

  open = () => {
    this.setState({ isOpen: true });
  };

  onRequestClose = (success = false) => {
    this.setState({ isOpen: false });

    if (this.props.onRequestClose) {
      this.props.onRequestClose(success);
    }
  };

  onSubmit = async () => {
    if (!this.$imageCropper || !this.props.src) {
      return null;
    }

    this.setState({ uploading: true });

    const fileName = this.props.src.split('/').pop();
    const croppedFile = convertCanvasToFile(this.$imageCropper.getBase64(), fileName);
    const response = await StorageService.send(croppedFile);

    this.setState({ uploading: false });

    if (response.ok) {
      this.onRequestClose(true);
      this.props.onSubmit(response.data);
    }
  };

  render() {
    const { onSubmit, ...args } = this.props;

    return (
      <Modal
        title={<Trans>Crop Your Image</Trans>}
        isOpened={this.state.isOpen}
        onCancel={this.onRequestClose}
        width={1040}
        footer={[
          <Button type="primary" onClick={this.onSubmit} loading={this.state.uploading} style={{ marginLeft: 'auto' }}>
            Submit
          </Button>,
        ]}
        disableBack
        destroyOnClose
      >
        <ImageCropper ref={(ref) => (this.$imageCropper = ref)} {...args} />
      </Modal>
    );
  }
}
