import { useMutation } from 'query';
import { default as adminDashboardRedirect } from '../../adminDashboardRedirect';

type Variables = {
  referrer: string;
};

export const useAdminDashboardRedirect = () =>
  useMutation<undefined, Variables>(async ({ referrer }: Variables) => {
    await adminDashboardRedirect(referrer);
    return undefined;
  });
