import { useQuery } from 'query';
import { createOnboardingTourStep, type OnboardingTourStep } from '../models';
import { getOnboardingSteps } from '../services';
import { getOnboardingTourStepsQueryKey } from '.';

export const useOnboardingTourSteps = (options?: { useErrorBoundary?: boolean }) =>
  useQuery<OnboardingTourStep[]>(
    getOnboardingTourStepsQueryKey(),
    async () => {
      const { data: steps } = await getOnboardingSteps();

      return steps.map((dataObject) => createOnboardingTourStep(dataObject));
    },
    options,
  );
