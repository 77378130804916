import { Entity } from 'models';
import apiClient, { type ApiClientResponse } from '../../../common/services/deprecatedApi';
import type Poll from '../../../models/poll';

const api = {
  async createPoll(poll: Poll) {
    return apiClient.post<any, ApiClientResponse<any>>(`/polls`, { poll });
  },

  async updatePoll(poll: Poll) {
    return apiClient.put<any, ApiClientResponse<any>>(`/polls/${poll.id}`, { poll });
  },

  async addVote(choice: string) {
    return apiClient.post<any, ApiClientResponse<any>>(`/poll_choices/${choice}/vote`);
  },

  async removeVote(choice: string) {
    return apiClient.delete<any, ApiClientResponse<any>>(`/poll_choices/${choice}/vote`);
  },

  // TODO This api call changes structure of itself. This should me moved somewhere further
  async findMyCommunities() {
    const response = await apiClient.get<any, ApiClientResponse<any>>(`/users/current`);

    if (response.ok) {
      response.data = response.data?.joined_communities
        ? response.data.joined_communities.map((community: any) => new Entity(community))
        : [];
    }

    return response as ApiClientResponse<Entity[]>;
  },
};

export default api;
