import * as React from 'react';
import { connect } from 'formik';

function valuesAreNotEqual(a, b) {
  return Object.keys(a).some((key) => a[key] !== b[key]);
}

class Renderer extends React.Component {
  static defaultProps = {
    throttleTimeout: 300,
  };

  componentDidUpdate(prevProps) {
    if (valuesAreNotEqual(prevProps.formik.values, this.props.formik.values)) {
      this.props.onChange(this.props.formik.values);
    }
  }

  render() {
    return null;
  }
}

export const Effect = connect(Renderer);
