import * as React from 'react';
import { Trans } from 'react-i18next';

import { ThemeConsumer } from '../../atoms/theme';
import { Table } from '../../atoms/table';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { AttendeeRow } from './AttendeeRow';

export class AttendeesList extends React.Component {
  render() {
    const { invitees } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <React.Fragment>
            {invitees && invitees.length > 0 ? (
              <React.Fragment>
                <Table>
                  {invitees.map((invitee, index) => (
                    <AttendeeRow key={`${invitee.id}-${index}`} viewer={this.props.viewer} invitee={invitee} />
                  ))}
                </Table>
              </React.Fragment>
            ) : (
              <Box flexDirection="row" justifyContent="center">
                {!this.props.loading && (
                  <Text>
                    <Trans>No invitees found with these parameters.</Trans>
                  </Text>
                )}
              </Box>
            )}
          </React.Fragment>
        )}
      </ThemeConsumer>
    );
  }
}
