import * as React from 'react';
import { Field } from 'formik';

import { CommunitiesInput } from '../inputs';
import { FieldError } from '../FieldError';
import { FloatingFieldLabel } from '../base-atoms/FloatingFieldLabel';

export function CommunitiesField(props) {
  return (
    <Field name={props.name}>
      {(fieldProps) => {
        // $FlowExpectedError
        const selection = fieldProps.field.value || [];
        return (
          <React.Fragment>
            <div className="communities-field">
              {props.label ? <FloatingFieldLabel visible>{props.label}</FloatingFieldLabel> : null}
              <CommunitiesInput
                disabled={props.disabled}
                placeholder={props.placeholder}
                currentSelectedData={props.currentSelectedData}
                selection={selection}
                scope={props.scope}
                allowPosts={props.allowPosts}
                onChange={(value) => {
                  if (props.onChange) {
                    props.onChange(fieldProps, props.name, value);
                  } else {
                    fieldProps.form.setFieldValue(props.name, value, true);
                    fieldProps.form.setFieldTouched(props.name, true, true);
                    fieldProps.field.onBlur(props.name);
                  }
                }}
                id={props?.id}
              />
            </div>
            <FieldError name={props.name} />
          </React.Fragment>
        );
      }}
    </Field>
  );
}
