import { Component } from 'react';
import { Route, Link } from 'react-router-dom';
import { Context } from './Navigation';
import { escapePath } from './helpers';
import variables from 'common/styles/variables.json';

export class NavLink extends Component {
  static defaultProps = {
    isActive: false,
  };

  render() {
    const path = typeof this.props.to === 'object' ? this.props.to.pathname : this.props.to;
    const escapedPath = escapePath(path || '');

    return (
      <Context.Consumer>
        {(context) => (
          <Route path={escapedPath}>
            {({ match, ...rest }) => {
              const isActive = !!match || rest.location.pathname.includes(this.props.alias);

              return (
                <div
                  className={`${context.nested === false ? 'navigation--item' : ''} ${
                    context.nested === true ? 'navigation--sub-item' : ''
                  } ${isActive || this.props.isActive ? 'navigation--active-item' : ''} ${
                    context.openGroups && this.props.isActive ? 'navigation--active-group-item' : ''
                  }`}
                  style={{
                    borderColor: isActive || this.props.isActive ? variables.brandColor.value : 'transparent',
                    color: isActive || this.props.isActive ? variables.brandColor.value : 'inherit',
                    ...this.props.style,
                  }}
                  id={this.props.id}
                >
                  <Link
                    to={this.props.to}
                    className="navigation--a"
                    title={typeof this.props.children === 'string' ? this.props.children : null}
                    aria-selected={isActive ? 'true' : 'false'}
                    role="tab"
                    aria-controls={this.props.controls}
                  >
                    {this.props.children}
                  </Link>
                </div>
              );
            }}
          </Route>
        )}
      </Context.Consumer>
    );
  }
}
