import { Event, type CommunityListItem } from 'models';
import { type Moment } from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, type FormInstance, SelectInput } from 'ui';
import EventCommunity from '../EventCommunity';
import EventTime from '../EventTime';

type RequiredFormValues = {
  communityId: string;
  patientType: string;
  visitType: string;
  visitTypeSubOption: string;
  visitTypeSubSubOption?: string;
  startTime: Moment;
  duration: number;
  endTime: Moment;
};

type P = {
  mode?: 'create' | 'duplicate' | 'edit';
  form: FormInstance<RequiredFormValues>;
  communities: CommunityListItem[];
  disabled?: boolean;
  disabledCommunity?: boolean;
  disabledTime?: boolean;
  disabledDuration?: boolean;
  resetSelectedUsers?: () => void;
};

type SelectOption = {
  name: string;
  getTranslation: () => string;
};

const TelehealthOptions = ({
  mode,
  form,
  communities,
  disabled,
  disabledCommunity,
  disabledTime,
  disabledDuration,
  resetSelectedUsers,
}: P) => {
  const { t } = useTranslation();

  const minimumStart = useMemo(() => Event.generateStartDate({}), []);

  return (
    <>
      <EventCommunity
        communities={communities}
        item={{
          label: 'Select medical community',
          rules: [{ required: true }],
        }}
        input={{
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          disabled: disabledCommunity || disabled,
          onChange() {
            resetSelectedUsers?.();
            form.setFieldsValue({
              patientType: undefined,
              visitType: undefined,
              visitTypeSubOption: undefined,
              duration: undefined,
            });
          },
        }}
      />
      <Form.Item noStyle dependencies={['communityId']}>
        {() => {
          const communityId: string | undefined = form.getFieldValue('communityId');
          const community = communities.find((community) => community.id === communityId);

          /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

          return (
            <>
              <SelectInput<SelectOption>
                item={{
                  name: 'patientType',
                  label: 'Patient type',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  placeholder: t('Choose type...'),
                  options: community?.meta?.patientTypes ?? [],
                  getOptionLabel: (option) => option.getTranslation(),
                  getOptionValue: (option) => option.name,
                  disabled: disabled || !community,
                }}
              />
              <SelectInput<SelectOption>
                item={{
                  name: 'visitType',
                  label: 'Visit type',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                }}
                input={{
                  placeholder: t('Choose type...'),
                  options: community?.meta?.serviceTypes ?? [],
                  getOptionLabel: (option) => option.getTranslation(),
                  getOptionValue: (option) => option.name,
                  disabled: disabled || !community,
                  onChange() {
                    form.setFieldsValue({ visitTypeSubOption: undefined });
                  },
                }}
              />
            </>
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['visitType']}>
        {() => {
          const visitTypeId: string | undefined = form.getFieldValue('visitType');

          const communityId: string | undefined = form.getFieldValue('communityId');
          const community = communities.find((community) => community.id === communityId);

          const visitType = community?.meta?.serviceTypes?.find((serviceType) => serviceType.name === visitTypeId);

          return (
            <SelectInput<SelectOption>
              item={{
                name: 'visitTypeSubOption',
                label: 'Visit type sub-option',
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              input={{
                placeholder: t('Choose sub-option...'),
                options: visitType?.children ?? [],
                getOptionLabel: (option) => option.getTranslation(),
                getOptionValue: (option) => option.name,
                disabled: disabled || !visitType,
              }}
            />
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['visitTypeSubOption']}>
        {() => {
          const visitTypeSubOptionId: string | undefined = form.getFieldValue('visitTypeSubOption');

          const communityId: string | undefined = form.getFieldValue('communityId');
          const community = communities.find((community) => community.id === communityId);

          const visitTypeId: string | undefined = form.getFieldValue('visitType');
          const visitType = community?.meta?.serviceTypes?.find((serviceType) => serviceType.name === visitTypeId);

          const visitTypeSubOption = visitType?.children?.find(
            (visitTypeSubOption) => visitTypeSubOption.name === visitTypeSubOptionId,
          );

          if (!visitTypeSubOption?.children) {
            return null;
          }

          return (
            <SelectInput<SelectOption>
              item={{
                name: 'visitTypeSubSubOption',
                label: `${visitTypeSubOption.getTranslation()} ${t('sub-option')}`,
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              input={{
                placeholder: t('Choose sub-option...'),
                options: visitTypeSubOption.children,
                getOptionLabel: (option) => option.getTranslation(),
                getOptionValue: (option) => option.name,
                disabled: disabled || !visitTypeSubOption,
              }}
            />
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['communityId']}>
        {() => {
          const communityId: string | undefined = form.getFieldValue('communityId');
          const community = communities.find((community) => community.id === communityId);
          const durations = community?.meta?.telehealthVisitDurations;

          return (
            <EventTime
              mode={mode}
              form={form}
              minimum={minimumStart}
              start={{ name: 'startTime', label: t('Starts on') }}
              duration={{ name: 'duration', label: t('Duration') }}
              end={{ name: 'endTime' }}
              durations={durations ?? []}
              disabledTime={disabled || disabledTime}
              disabledDuration={disabled || disabledDuration || !durations}
            />
          );
        }}
      </Form.Item>
    </>
    /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
  );
};

export default TelehealthOptions;
