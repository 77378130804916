import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { useCampaignsInfiniteQuery } from 'features/campaigns/queries';
import { Card, Link, Skeleton, Title, Breadcrumb, Empty } from 'ui';
import { CampaignsSlider, EntityList } from '../components';
import { useOrganizationIdForRequests } from '../hooks';
import { useOrganizationsQuery } from '../queries';

type P = {
  getPathToCampaignDetail: (id: string) => string;
  organizationId: string;
};

const ChannelsCampaigns = ({ getPathToCampaignDetail, organizationId }: P) => {
  const { t } = useTranslation();
  // TODO Solve default organization logic in better way - perhaps BE update?
  const { data: channelsOrganizations, isLoading: isChannelsOrganizationLoading } = useOrganizationsQuery();

  const organizationIdForRequests = useOrganizationIdForRequests(organizationId, channelsOrganizations);

  const {
    data: allCampaignsData,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    fetchNextPage,
    hasNextPage,
  } = useCampaignsInfiniteQuery({
    pageSize: 12,
    organizationId: organizationIdForRequests,
    scope: 'channels',
  });

  const allCampaigns = allCampaignsData?.pages.flat();

  const breadcrumbRoutes = [
    {
      path: routes.channels.index,
      breadcrumbName: t('Channels'),
    },
    {
      path: routes.channels.organizationChannel(organizationId, 'campaigns'),
      breadcrumbName: t('Campaigns'),
    },
  ];

  return (
    <>
      <Breadcrumb
        routes={breadcrumbRoutes.map((route) => ({ ...route, breadcrumbName: t(route.breadcrumbName) }))}
        itemRender={({ path, breadcrumbName }: { path: string; breadcrumbName: string }) => {
          return <Link to={path}>{breadcrumbName}</Link>;
        }}
      />
      <Title>{t('Campaigns')}</Title>
      {isChannelsOrganizationLoading || isLoadingAll || !allCampaigns || !channelsOrganizations ? (
        <Skeleton active paragraph={{ rows: 16 }} />
      ) : (
        <>
          <CampaignsSlider
            getPathToCampaignDetail={getPathToCampaignDetail}
            organizationId={organizationIdForRequests}
            title="Featured"
            view="detail"
          />
          <Card>
            <Title level={3}>{t('All')}</Title>
            <EntityList
              getPathToEntityDetail={getPathToCampaignDetail}
              empty={<Empty />}
              entities={allCampaigns}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetching={isFetchingAll}
              isLoading={isLoadingAll}
              showPrice
            />
          </Card>
        </>
      )}
    </>
  );
};

export default ChannelsCampaigns;
