type P = {
  alt: string;
  src: string;
};

const ContentLeftImage = ({ alt, src }: P) => {
  return (
    <div className="content-left-image">
      <img alt={alt} src={src} className="content-left-image__img" />
    </div>
  );
};

export default ContentLeftImage;
