import { dispatch } from 'old/store';
import { Button, message } from 'ui';
import { useMarkAsRead } from '../queries';

type P = {
  postId: string;
  status: string;
  view: 'bookmark' | 'other';
};

const MarkAsRead = ({ postId, status, view }: P) => {
  const { isLoading: isLoadingMarkAsCompleted, mutate: markAsRead } = useMarkAsRead();

  const disabled = status === 'read' || view === 'bookmark';

  return (
    <Button
      disabled={disabled}
      size="large"
      type="primary"
      loading={isLoadingMarkAsCompleted}
      onClick={() => {
        markAsRead(
          { postId },
          {
            onSuccess(data) {
              dispatch.feed.storeContentObjectData(data);
              message.success('Post marked as read successfully');
            },
            onError() {
              message.error('Failed to mark post as read');
            },
          },
        );
      }}
    >
      {status === 'read' ? 'Marked as read' : 'Mark as read'}
    </Button>
  );
};

export default MarkAsRead;
