import { useTranslation } from 'react-i18next';

import { Title, Text } from '../../';

type P = {
  title: string;
  subtitle: string;
};

const CardTab = ({ title, subtitle }: P) => {
  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>{t(title)}</Title>
      <Text size="small">{t(subtitle)}</Text>
    </>
  );
};

export default CardTab;
