import * as React from 'react';
import { Trans } from 'react-i18next';
import { Navigation, NavLink } from '../../atoms/navigation';

export class HelpSideNav extends React.Component {
  render() {
    return (
      <Navigation direction="vertical" compact id="help-navigation">
        <NavLink to="/help" id="nav-help">
          <Trans>Help</Trans>
        </NavLink>
      </Navigation>
    );
  }
}
