import * as React from 'react';
import { withRouter } from 'react-router';

import { Card } from 'ui';
import { AppLayout } from './AppLayout';
import { AdminSideNav } from './AdminSideNav';

class Renderer extends React.Component {
  render() {
    return (
      <AppLayout
        center={
          <Card shrinkedLeft>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%', flexShrink: 0, maxWidth: 300 }}>
                <AdminSideNav
                  selectedOrganizationId={this.props.match.params.organizationId}
                  selectedCommunityId={this.props.match.params.communityId}
                />
              </div>
              <div style={{ width: '100%' }}>{this.props.children}</div>
            </div>
          </Card>
        }
      />
    );
  }
}

export const AdminLayout = withRouter(Renderer);
