import * as React from 'react';
import { Field } from 'formik';
import { TagsSelect } from '../tags-select';
import { FieldError } from './FieldError';

export function TagsField(props) {
  return (
    <Field name={props.name}>
      {(fieldProps) => {
        const { label, placeholder, options } = props;
        return (
          <label className="tags-field">
            <div className="tags-field__label">{label}</div>
            <div className="tags-field__tags">
              <TagsSelect
                options={options}
                placeholder={placeholder}
                tags={fieldProps.field.value}
                onChange={(values) => {
                  fieldProps.form.setFieldValue(fieldProps.field.name, values, true);
                }}
              />
            </div>
            <FieldError name={props.name} />
          </label>
        );
      }}
    </Field>
  );
}
