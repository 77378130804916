import { Image as ImageA } from 'antd';
import cs from 'classnames';
import { GroupConsumerProps } from 'rc-image/lib/PreviewGroup';

const ImagePreview = ({ preview, ...rest }: GroupConsumerProps) => {
  const defaultClassName = 'image-preview';

  if (preview && typeof preview !== 'boolean') {
    preview.rootClassName = 'rootClassName' in preview ? cs(preview.rootClassName, defaultClassName) : defaultClassName;
  }

  if (preview === undefined) {
    preview = { rootClassName: defaultClassName };
  }

  return <ImageA.PreviewGroup {...rest} preview={preview} />;
};

export default ImagePreview;
