import { type Campaign, type ReduxUser } from 'models';
import qs from 'qs';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'ui';
import { StripeDonationModal } from './StripeDonationModal';
import { StripeDonationResultModal } from './StripeDonationResultModal';

type P = {
  campaign: Campaign;
  viewer?: ReduxUser;
};

const StripeDonation = ({ campaign, viewer }: P) => {
  const [isStripeModalOpen, setIsStripeModalOpen] = useState<boolean>(false);

  // TODO this should be in page
  const { replace } = useHistory();
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const donationResultParameter = query.donation_result;

  return (
    <>
      <Button
        onClick={() => {
          setIsStripeModalOpen(true);
        }}
        className="stripe-donation__button"
        size="large"
      >
        Donate
      </Button>

      <StripeDonationModal
        isOpened={isStripeModalOpen}
        onClose={() => {
          setIsStripeModalOpen(false);
        }}
        campaign={campaign}
        viewer={viewer}
      />

      <StripeDonationResultModal
        isOpened={donationResultParameter === 'success' || donationResultParameter === 'cancel'}
        onClose={() => {
          delete query.donation_result;
          replace({ pathname: location.pathname, search: qs.stringify(query) });
        }}
        wasSuccessful={donationResultParameter === 'success'}
        campaign={campaign}
      />
    </>
  );
};

export default StripeDonation;
