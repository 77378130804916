import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { CategoriesLayout, NextButton, BackButton } from '../../layouts/CategoriesLayout';
import { CategoriesFilters } from '../../atoms/categories-card-list';
import { Loader } from '../../atoms/loader';
import { OrganizationItem } from './OrganizationItem';
import { OrganizationModal } from '../../atoms/organization-modal';
import { ThemeConsumer } from '../../atoms/theme';
import { dispatch } from '../../store';

class Renderer extends React.Component {
  state = {
    searchValue: '',
    selectedOrganization: null,
  };

  get organizations() {
    return this.props.organizations.filter(
      (organization) => organization.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1,
    );
  }

  async componentDidMount() {
    await this.props.fetchOrganizations();
  }

  onDoneClick = () => {
    this.props.history.push('/');
  };

  onJoinNow = async (organization) => {
    await this.props.joinOrganization(organization);
  };

  onRequestToJoin = async (organization) => {};

  onExploring = async () => {
    window.location.href = '/';
  };

  onRequestClose = () => {
    this.setState({ selectedOrganization: null });
  };

  onSelectOrganization = async (organization) => {
    const response = await dispatch.organizations.findAsync(organization.id);

    if (response.ok) {
      this.setState({ selectedOrganization: response.data });
    }
  };

  onChangeSearch = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <CategoriesLayout
            buttons={
              <React.Fragment>
                <BackButton onClick={this.props.history.goBack} />
                <NextButton title={<Trans>Done</Trans>} onClick={this.onDoneClick} />
              </React.Fragment>
            }
          >
            <OrganizationModal
              isOpen={!!this.state.selectedOrganization}
              onJoinNow={this.onJoinNow}
              onRequestClose={this.onRequestClose}
              onRequestToJoin={this.onRequestToJoin}
              data={this.state.selectedOrganization}
              onExploring={this.onExploring}
              joining={this.props.joiningOrganization}
            />
            <div style={{ maxWidth: 900, marginRight: 'auto', marginLeft: 'auto' }}>
              <CategoriesFilters
                title={<Trans>Join an organization</Trans>}
                subtitle={<Trans>that interests you</Trans>}
                value={this.state.searchValue}
                onChange={this.onChangeSearch}
              />
              {this.props.loading ? (
                <Loader />
              ) : (
                <TransitionGroup className="row">
                  {this.organizations.map((organization) => (
                    <CSSTransition key={organization.id} timeout={400} classNames="fade">
                      <div key={organization.id} className="col-md-6 col-xs-12">
                        <OrganizationItem
                          onClick={() => this.onSelectOrganization(organization)}
                          organization={organization}
                          onJoinNow={() => this.onJoinNow(organization)}
                          onRequestToJoin={() => this.onRequestToJoin(organization)}
                        />
                      </div>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              )}
            </div>
          </CategoriesLayout>
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state) => ({
  organizations: select.organizations.all(state),
  loading: state.loading.effects.organizations.fetchByCategoriesAsync,
  joiningOrganization: state.loading.effects.organizations.joinAsync,
});

const mapDispatch = (dispatch, props) => ({
  joinOrganization: (organization) => dispatch.organizations.joinAsync(organization.id),
  fetchOrganizations: () => dispatch.organizations.fetchByCategoriesAsync(props.match.params.category_ids.split(',')),
});

export const PageOrganizationsForCategory = connect(mapState, mapDispatch)(Renderer);
