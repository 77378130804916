import * as React from 'react';
import { Trans } from 'react-i18next';

import { CommunityFetcher } from '../smart-atoms/community-fetcher';
import { TopBarLink } from './TopBarLink';
import { Menu, MenuButton, MenuList, MenuItem } from '../atoms/menu';
import { Icon } from '../atoms/icon';
import { Loader } from '../atoms/loader';
import { Box } from '../atoms/box/Box';
import { H1 } from '../atoms/text';
import { can } from '../utils/permissions';
import { images } from 'common/utils';

export class CommunityLayout extends React.Component {
  static defaultProps = {
    isPublicView: false,
  };

  render() {
    return (
      <CommunityFetcher
        isForPublic={this.props.isPublicView}
        communityId={this.props.communityId}
        renderLoading={() => <Loader />}
        renderLoaded={(fetcher) => (
          <div className="community-layout">
            <div className="community-layout__header">
              <img src={fetcher.data.cover_photo?.url ?? images.default.communityCoverPhoto} alt={fetcher.data.name} />
            </div>
            <div className="community-layout__title">
              <Box alignItems="center" style={{ width: '100%' }}>
                <H1 weight="100" color="#001E34" style={{ marginBottom: 10 }}>
                  {fetcher.data.name}
                </H1>
              </Box>
            </div>
            <Box className="community-layout__nav">
              <TopBarLink to={`/communities/${fetcher.data.id}/feed`} id="feed">
                <Trans>News</Trans>
              </TopBarLink>
              <TopBarLink to={`/communities/${fetcher.data.id}/events`} id="events">
                <Trans>Events</Trans>
              </TopBarLink>
              <TopBarLink to={`/communities/${fetcher.data.id}/assets`} id="files">
                <Trans>Files</Trans>
              </TopBarLink>
              <TopBarLink to={`/communities/${fetcher.data.id}/members`} id="members">
                <Trans>Members</Trans>
              </TopBarLink>
              <TopBarLink to={`/communities/${fetcher.data.id}/about`} id="about">
                <Trans>About</Trans>
              </TopBarLink>
              <TopBarLink to={`/communities/${fetcher.data.id}/settings`} id="settings">
                <Trans>Settings</Trans>
              </TopBarLink>
              {!this.props.isPublicView && can(this.props, 'Community', 'leaveCommunity') && (
                <Menu>
                  <MenuButton style={{ border: 0, background: 'none' }}>
                    <Icon name="menu" size={20} />
                  </MenuButton>
                  <MenuList style={{ width: 210 }}>
                    <MenuItem
                      id={'leave-community'}
                      onSelect={() => {
                        fetcher.onLeaveCommunity(fetcher.data);
                      }}
                    >
                      <Trans>Leave Community</Trans>
                      <Icon size={20} name="other-1" color="rgba(176,190,197,0.5)" />
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Box>
            {this.props.children(fetcher.data)}
          </div>
        )}
      />
    );
  }
}
