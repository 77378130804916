import { Entity } from './entity';
import { type ConsentDataObject } from './service';

export class Consent extends Entity {
  validFor: number;
  htmlContent: string;

  constructor(data: ConsentDataObject) {
    super(data);
    this.validFor = data.valid_for;
    this.htmlContent = data.html_content;
  }
}
