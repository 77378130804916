import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import variables from 'common/styles/variables.json';

import { Button, Card, Col, Form, ImageInputDeprecated, RichTextInput, Row, TextInput } from 'ui';

type P = {
  name: string;
};

const ProgramFeaturedParticipants = ({ name }: P) => {
  const { t } = useTranslation();

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <Form.Item required={false} key={field.key}>
                <Card
                  title={
                    <Row item={{ gutter: variables.spaceMd.value }}>
                      <Col item={{ span: 12 }}>
                        <TextInput
                          item={{
                            ...field,
                            name: [field.name, 'name'],
                            label: 'Featured participant name',
                            rules: [
                              { required: true, message: t('Featured participant name is required'), whitespace: true },
                            ],
                          }}
                          input={{
                            id: 'program_participant-name',
                          }}
                        />
                      </Col>
                      <Col item={{ span: 12, className: 'featured-participant-logo' }}>
                        <ImageInputDeprecated
                          item={{
                            ...field,
                            name: [field.name, 'logo'],
                            label: 'Upload featured participant photo',
                          }}
                          aspectRatio={{ width: 500, height: 500 }}
                          input={{
                            id: 'program_participant-photo',
                          }}
                        />
                      </Col>
                    </Row>
                  }
                  style={{ marginBottom: 16 }}
                  extra={
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                      data-test-id="btn_delete-participant"
                    />
                  }
                >
                  <RichTextInput
                    text={{ name: [field.name, 'description'] }}
                    html={{
                      ...field,
                      name: [field.name, 'html_description'],
                      label: 'Description',
                      maxWidth: '594px',
                    }}
                  />
                </Card>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                id="btn_add-participant"
                size="large"
              >
                <PlusOutlined /> {t('Add Featured Participant')}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default ProgramFeaturedParticipants;
