import { Account } from 'models';
import { useInfiniteQuery, type QueryFunctionContext, type QueryKey } from 'query';
import { type NodeApiClientResponseError, type NodeApiError } from 'common/services';
import { type AccountDataObject, type PaginatedDataObjects } from 'models/service';
import { getPositions } from '../services';
import { getLeaderboardQueryKey } from './';

export const useLeaderboardQuery = (pageSize: number) =>
  useInfiniteQuery<PaginatedDataObjects<Account>, NodeApiClientResponseError<NodeApiError>>(
    getLeaderboardQueryKey(pageSize),
    async ({ pageParam }: QueryFunctionContext<QueryKey, number>) => {
      const { data: positions } = await getPositions({
        type: 'leaderboard',
        $limit: pageSize,
        $skip: pageParam,
        $sort: {
          position: 1,
        },
      });

      return {
        total: positions.total,
        limit: positions.limit,
        skip: positions.skip,
        data: positions.data.map((item: AccountDataObject) => new Account(item)),
      };
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.skip + lastPage.data.length < lastPage.total ? lastPage.skip + lastPage.limit : undefined,
      useErrorBoundary: true,
    },
  );
