import { type ReduxUser } from 'models';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Skeleton, Title } from 'ui';
import { useLeaderboardQuery } from '../../queries/useLeaderboardQuery';
import { LeaderboardTableItem } from '../LeaderboardTableItem';

const pageSize = 10;

type P = {
  currentUser: ReduxUser;
};

const LeaderboardTable = ({ currentUser }: P) => {
  const { t } = useTranslation();
  const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } = useLeaderboardQuery(pageSize);
  const [pagination, setPagination] = useState<number>(pageSize);
  const users = useMemo(
    () =>
      data?.pages
        .flat()
        .flatMap((dataPaginated) => dataPaginated.data)
        .flatMap((item) => {
          return item.accounts.map((account) => ({
            userId: account.meta.ownerExternalIds[1],
            points: item.points!,
            position: item.position,
          }));
        }),
    [data],
  );

  const getNextObjects = async () => {
    const newPagination = pagination + pageSize;
    if (newPagination > (users?.length ?? 0)) {
      await fetchNextPage();
    }

    setPagination(newPagination);
  };

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const hasNextObjects = hasNextPage || pagination < (users?.length ?? 0);

  return isLoading || !users ? (
    <Skeleton active avatar paragraph={{ rows: 12 }} />
  ) : (
    <Row item={{ className: 'leaderboard-table' }}>
      <Col item={{ span: 24 }}>
        <Title level={4} className="leaderboard-table__title">
          {t('Leaderboard')}
        </Title>
      </Col>
      <Col item={{ span: 24, className: 'leaderboard-table__body-wrapper' }}>
        {/* TODO improve request to get pageSize users at once and cache them separately */}
        {users.slice(0, pagination).map((user, index) => (
          <LeaderboardTableItem
            key={`${user.userId}-${index}`}
            currentUser={currentUser.id === user.userId}
            {...user}
          />
        ))}
      </Col>
      {!isLoading && hasNextObjects ? (
        <Col item={{ span: 24, className: 'leaderboard-table__show-more-wrapper' }}>
          <Button type="default" onClick={getNextObjects} loading={isFetchingNextPage} size="large">
            Show more
          </Button>
        </Col>
      ) : null}
    </Row>
  );
};

export default LeaderboardTable;
