import cn from 'classnames';
import { type Profile } from 'models';
import { type ReactNode } from 'react';
import { AxiosErrorHandler } from 'common/error-handlers';
import { useProfileQuery } from 'features/user-profile/queries';
import { LandingLayout, Loading } from 'ui';
import { Footer, Header } from '../components';
import { type OnboardingTourStep } from '../models';
import { useOnboardingTourSteps } from '../queries';

const OnboardingTourLayout = ({
  centeredContent = false,
  contentLeft,
  contentRight,
}: {
  centeredContent?: boolean;
  contentLeft: ReactNode | ((data: { steps: OnboardingTourStep[] }) => ReactNode);
  contentRight: (data: { steps: OnboardingTourStep[]; viewer: Profile }) => ReactNode;
}) => {
  const {
    data: viewer,
    isLoading: isLoadingProfile,
    error: profileError,
  } = useProfileQuery({ useErrorBoundary: false });
  const {
    data: steps,
    isLoading: isLoadingSteps,
    error: stepsError,
  } = useOnboardingTourSteps({ useErrorBoundary: false });

  return (
    <LandingLayout
      className="onboarding-tour-layout"
      header={<Header items={[]} />}
      contentLeft={
        typeof contentLeft === 'function'
          ? isLoadingSteps || !steps
            ? undefined
            : contentLeft({ steps })
          : contentLeft
      }
      contentRight={
        <AxiosErrorHandler error={profileError ?? stepsError}>
          {isLoadingSteps || !steps || isLoadingProfile || !viewer ? (
            <Loading />
          ) : (
            <div
              className={cn('onboarding-tour-layout__content', {
                'onboarding-tour-layout__content--full-height': !centeredContent,
              })}
            >
              {contentRight({ steps, viewer })}
            </div>
          )}
        </AxiosErrorHandler>
      }
      footer={<Footer />}
    />
  );
};

export default OnboardingTourLayout;
