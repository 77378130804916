import cs from 'classnames';
import { type FC, type ReactNode } from 'react';
import { Row, Col, Text } from 'ui';

type P = {
  underline?: boolean;
  label: string;
  value?: ReactNode | string;
};

const SummaryLine: FC<P> = ({ underline: underlined = true, label, value }) => {
  return (
    <Row item={{ className: cs('summary-line', { 'summary-line--underline': underlined }) }}>
      <Col item={{ span: 12 }}>
        <Text>{label}</Text>
      </Col>
      <Col item={{ span: 12, className: 'summary-line__value' }}>
        <Text strong>{value}</Text>
      </Col>
    </Row>
  );
};

export default SummaryLine;
