import { type Campaign, type ReduxUser } from 'models';
import { useMemo, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { googleReCaptchaSiteKey } from 'common/services/api-keys';
import { type PriceInterval } from 'models/service';
import { Modal } from 'ui';
import { StripeDonationBillingInformationForm } from './StripeDonationBillingInformationForm';
import { StripeDonationSelectAmountForm } from './StripeDonationSelectAmountForm';
import { StripeDonationTypeButtons } from './StripeDonationTypeButtons';

type StripeDonationStep = 'amount' | 'billing-information' | 'type';

type P = {
  isOpened: boolean;
  onClose: () => void;
  campaign: Campaign;
  viewer?: ReduxUser;
};

const StripeDonationModal = ({ isOpened, onClose, campaign, viewer }: P) => {
  const [donationType, setDonationType] = useState<PriceInterval>();
  const [donationAmountStripeId, setDonationAmountStripeId] = useState<string>();
  const [step, setStep] = useState<StripeDonationStep>('type');
  const { prices } = campaign;

  const priceTypes = useMemo(() => {
    const priceTypesToUse: PriceInterval[] = [];

    for (const [key, value] of Object.entries(prices)) {
      if (value.length > 0) {
        // TODO do this typing in better way
        priceTypesToUse.push(key as PriceInterval);
      }
    }

    return priceTypesToUse;
  }, [prices]);

  const selectedPriceGroup = donationType && prices[donationType];
  const selectedPriceItem = selectedPriceGroup?.find((priceItem) => priceItem.stripeId === donationAmountStripeId);

  const renderModalTitle = () => {
    switch (step) {
      case 'type':
        return 'Select donation type';
      case 'amount':
        return 'Select amount';
      case 'billing-information':
        return 'Fill billing information';
      default:
        return null;
    }
  };

  const onTypeButtonSelect = (donationType: PriceInterval) => {
    setDonationType(donationType);
    setStep('amount');
  };

  const onAmountFormSubmit = (stripeId: string) => {
    setDonationAmountStripeId(stripeId);
    setStep('billing-information');
  };

  const handleBackClick = () => {
    if (step === 'amount') {
      setStep('type');
      return;
    }

    setStep('amount');
  };

  const onModalClose = () => {
    setStep('type');
    onClose();
  };

  return (
    <Modal
      width="small"
      title={renderModalTitle()}
      isOpened={isOpened}
      onCancel={() => {
        onModalClose();
      }}
      disableBack={step === 'type'}
      // TODO remember values?
      onBackClick={handleBackClick}
    >
      <GoogleReCaptchaProvider reCaptchaKey={googleReCaptchaSiteKey}>
        {step === 'type' ? <StripeDonationTypeButtons types={priceTypes} onSelect={onTypeButtonSelect} /> : null}
        {step === 'amount' && selectedPriceGroup ? (
          <StripeDonationSelectAmountForm prices={selectedPriceGroup} onFormSubmit={onAmountFormSubmit} />
        ) : null}
        {step === 'billing-information' && selectedPriceItem ? (
          <StripeDonationBillingInformationForm campaign={campaign} viewer={viewer} price={selectedPriceItem} />
        ) : null}
      </GoogleReCaptchaProvider>
    </Modal>
  );
};

export default StripeDonationModal;
