import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const CampaignService = {
  get: () => http.get('/campaigns'),
  find: (id) => http.get(`/campaigns/${id}`),
  create: (payload) => http.post(`/campaigns`, { campaign: payload }),
  update: (id, payload) => http.put(`/campaigns/${id}`, { campaign: payload }),
  delete: (id) => http.delete(`/campaigns/${id}`),
};
