import { EventRendererCore } from 'old/atoms/feed-object';
import { AxiosErrorHandler } from 'common/error-handlers';
import { Loading } from 'ui';
import { useEventPublicQueryDeprecated } from './queries';

type P = {
  eventId: string;
};

export const PublicEvent = ({ eventId }: P) => {
  const {
    isLoading: isLoadingEvent,
    isFetching: isFetchingEvent,
    data: eventData,
    error,
  } = useEventPublicQueryDeprecated({ eventId }, { useErrorBoundary: false });

  return (
    <AxiosErrorHandler error={error} actions={false}>
      {(isLoadingEvent && isFetchingEvent) || !eventData ? (
        <Loading />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <EventRendererCore item={eventData} isBookmark={false} onRequestEventDetail={() => {}} isPublic />
      )}
    </AxiosErrorHandler>
  );
};
