import { ReactComponent as MODLogo } from 'assets/MOD_logo.svg';
import cn from 'classnames';
import type React from 'react';
import { Text, Col, Row, Button, TelehealthIcon } from '../';

export type TelehealthButtonType = 'primary' | 'secondary' | 'tertiary';

type P = React.ComponentProps<typeof Button> & {
  buttonType: TelehealthButtonType;
  onClick: () => void;
  className?: string;
};

const TelehealthButton = ({ onClick, className, buttonType = 'primary', ...rest }: P) => {
  const renderTelehealthButton = () => {
    switch (buttonType) {
      case 'primary':
        return (
          <Button
            {...rest}
            type="primary"
            icon={<TelehealthIcon />}
            className={cn('telehealth-button--primary', 'telehealth-button', className)}
            onClick={onClick}
            size="large"
          />
        );
      case 'secondary':
        return (
          <Row item={{ justify: 'space-between' }}>
            <Col item={{ span: 12 }}>
              <Button
                {...rest}
                block
                type="primary"
                className={cn('telehealth-button--primary', 'telehealth-button', className)}
                onClick={onClick}
                size="large"
              />
            </Col>
            <Col item={{ span: 12, className: 'telehealth-button__logo-section' }}>
              <Text className="telehealth-button__logo-section__text" type="secondary">
                Provided by
              </Text>
              <MODLogo className="telehealth-button__logo-section__image" />
            </Col>
          </Row>
        );
      case 'tertiary':
        return (
          <Button
            {...rest}
            onClick={onClick}
            size="large"
            className={cn('telehealth-button', className)}
            icon={<TelehealthIcon />}
          />
        );
    }
  };

  return renderTelehealthButton();
};

export default TelehealthButton;
