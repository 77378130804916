import { type Form } from 'antd';
import { Select } from '../Select';
import { Item } from './Item';

const { Option } = Select;

export type SelectItemProperties = Omit<React.ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
  'data-test-id'?: string;
};

export type SelectInputProperties<T> = Omit<React.ComponentProps<typeof Select>, 'css' | 'options'> & {
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => boolean | number | string;
  'data-test-id'?: string;
};

type P<T> = {
  item: SelectItemProperties;
  input: SelectInputProperties<T>;
};

// TODO should be generic as `Select` ?
// TODO rename to DeprecatedSelectInput
// TODO selected items in multiple select should be 12px, now are 14px
const SelectInput = <T,>({ item, input: { options, getOptionLabel, getOptionValue, ...restInput } }: P<T>) => {
  return (
    <Item {...item}>
      <Select
        {...restInput}
        filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        data-test-id={item?.['data-test-id']}
      >
        {options.map((option) => (
          <Option
            key={`option_${item.name}_${getOptionValue(option)}`}
            value={getOptionValue(option)}
            id={`option_${getOptionValue(option)}`}
          >
            {getOptionLabel(option)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default SelectInput;
