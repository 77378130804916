import * as React from 'react';
import { format, parseISO, isDate, isValid } from 'date-fns';
import { withTranslation } from 'react-i18next';

import { ThemeConsumer } from '../../theme';
import { Text } from '../../text';
import { Box } from '../../box';

export class Renderer extends React.Component {
  render() {
    const { time } = this.props;
    const parsedDate = parseISO(time);
    const isValidDate = isDate(parsedDate) && isValid(parsedDate);

    return (
      <ThemeConsumer>
        {(theme) => (
          <Box
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            style={{ width: 66, height: 66, background: `${theme.color.brand}1A` }}
          >
            <Text
              size={25}
              color={theme.color.brand}
              component="div"
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              {isValidDate ? format(parsedDate, 'dd') : ''}
            </Text>
            <Text
              size={15}
              color={theme.color.brand}
              component="div"
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              {this.props.t('dateFormatShortMonth', { date: parsedDate })}
            </Text>
          </Box>
        )}
      </ThemeConsumer>
    );
  }
}

export const CalendarIcon = withTranslation()(Renderer);
