import { PlusCircleOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Empty } from '../../../ui';
import { MessageIcon } from '../../../ui/Icons';

type P = {
  onStartNewChatClick: () => void;
};

const NoChatrooms: FC<P> = ({ onStartNewChatClick }: P) => {
  const { t } = useTranslation(['translationChat']);

  return (
    <div className="no-chatrooms">
      <Empty description={t('chat.chatroomDetail.empty.message')} image={<MessageIcon />}>
        <Button type={'primary'} icon={<PlusCircleOutlined />} size={'large'} onClick={onStartNewChatClick}>
          {t('chat.chatroomDetail.empty.button')}
        </Button>
      </Empty>
    </div>
  );
};

export default NoChatrooms;
