import { StarFilled } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { message, Rate, Text } from 'ui';
import api from './../services/api';

type P = {
  onRate?: () => Promise<unknown>;
  contentObjectId: string;
  rating: number;
  ratingAverage: string;
  canRate: boolean;
  canViewRating: boolean;
};

const Rating = ({ onRate, contentObjectId, rating, ratingAverage, canRate, canViewRating }: P) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ratingData, setRatingData] = useState<number>(rating);
  const [ratingAverageData, setRatingAverageData] = useState<string>(ratingAverage);

  const onRateChange = async (value: number) => {
    setLoading(true);
    const rateResponse = await api.postRating(contentObjectId, value);

    if (rateResponse.ok) {
      await onRate?.(); // TODO this might not work correctly
      setRatingData(value);
      message.success('Your evaluation was recorded.');
    } else {
      message.error('Failed to record your evaluation.');
    }

    setLoading(false);
  };

  useEffect(() => {
    setRatingData(rating);
    setRatingAverageData(ratingAverage);
  }, [rating, ratingAverage]);

  return (
    <div className="rating">
      {canRate ? (
        <>
          <Text className="rating__text" size="small">
            Was this valuable?
          </Text>
          <Rate
            allowClear={false}
            className="rating__rate"
            onChange={onRateChange}
            disabled={loading}
            value={ratingData}
          />
        </>
      ) : null}
      {canViewRating ? (
        <>
          <StarFilled className="rating__full-star" />
          <Text className="rating__text">{ratingAverageData}</Text>
        </>
      ) : null}
    </div>
  );
};

export default Rating;
