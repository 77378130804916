import { PlusCircleOutlined, CheckCircleOutlined, CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { type ReactNode, useMemo, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, type MenuProps } from 'ui';

export type ButtonStatus = 'accept-reject' | 'accepted' | 'join' | 'joined' | 'pending' | 'rejected' | 'request';

type P = {
  size?: 'middle' | 'small';
  status?: ButtonStatus;
  children?: ReactNode;
  loading?: boolean;
  dropdown?: MenuProps;
  onClick?: ((event: MouseEvent<HTMLElement>) => Promise<void>) | ((event: MouseEvent<HTMLElement>) => void);
  onRejectClick?: (() => Promise<void>) | (() => void);
  disabled?: boolean;
};

type ReducerState = {
  title: string;
  type: 'default' | 'primary';
  icon?: ReactNode;
  className?: string;
};

type ReducerAction = { status: ButtonStatus };

const reduceData = ({ status }: ReducerAction): ReducerState => {
  switch (status) {
    case 'join':
      return { type: 'primary', className: 'join-status-button--join', title: 'Join' };
    case 'request':
      return { type: 'primary', icon: <PlusCircleOutlined />, title: 'Request to Join' };
    case 'pending':
      return {
        type: 'default',
        icon: <CheckCircleOutlined />,
        title: 'Request sent',
        className: 'join-status-button--pending',
      };
    case 'accepted':
      return {
        type: 'default',
        icon: <CheckCircleOutlined />,
        title: "Yes, I'm going",
        className: 'join-status-button--accepted',
      };
    case 'joined':
      return {
        type: 'default',
        icon: <CheckCircleOutlined />,
        title: 'Joined',
        className: 'join-status-button--joined',
      };
    case 'accept-reject':
      return {
        type: 'primary',
        icon: <CheckCircleOutlined />,
        title: 'Accept',
        className: 'join-status-button--accept',
      };
    case 'rejected':
      return {
        type: 'default',
        icon: <CloseCircleOutlined />,
        title: "No, I can't make it",
        className: 'join-status-button--rejected',
      };
    default:
      throw new Error('Invalid status');
  }
};
/**
 * @deprecated Do not use. Please use normally `Button` + `Dropdown`
 */

const JoinStatusButton = ({
  size = 'middle',
  status = 'join',
  loading,
  dropdown,
  children,
  onClick,
  onRejectClick,
  disabled,
}: P) => {
  const { t } = useTranslation();
  const { title, ...buttonStateProperties } = useMemo(() => reduceData({ status }), [status]);

  const text = useMemo(() => {
    if (children) {
      return typeof children === 'string' ? t(children) : children;
    }

    return t(title);
  }, [title, children, t]);

  const renderRejectButton = () => {
    if (status !== 'accept-reject') {
      return null;
    }

    return (
      <Button
        icon={<CloseCircleOutlined />}
        className="join-status-button--reject"
        size={size}
        type="default"
        loading={loading}
        onClick={onRejectClick}
        disabled={!onRejectClick || disabled}
      />
    );
  };

  return dropdown ? (
    <>
      <Dropdown menu={dropdown} trigger={['click']} disabled={disabled}>
        <Button size={size} loading={loading} {...buttonStateProperties}>
          {text}
          <CaretDownOutlined />
        </Button>
      </Dropdown>
      {renderRejectButton()}
    </>
  ) : (
    <>
      <Button
        size={size}
        loading={loading}
        {...buttonStateProperties}
        onClick={onClick}
        disabled={!onClick || disabled}
      >
        {text}
      </Button>
      {renderRejectButton()}
    </>
  );
};

export default JoinStatusButton;
