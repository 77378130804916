import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../common/services';

import {
  TimestampRequest,
  PaginationDataObject,
  PaginationRequest,
  ReferenceRequest,
  StatusRequest,
} from '../../common/services/data-objects';
import { ChatroomDataObject } from '../data-objects';

export type FindChatroomsRequest = PaginationRequest &
  ReferenceRequest &
  StatusRequest &
  TimestampRequest & {
    $search?: string;
  };

export type FindChatroomsDataObject = PaginationDataObject<ChatroomDataObject>;

export const findChatrooms = (params: FindChatroomsRequest) =>
  nodeApiClient.get<FindChatroomsDataObject, AxiosResponse<FindChatroomsDataObject>>(routes.chatrooms.find, {
    params,
  });
