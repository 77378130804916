import { Tag as TagAD } from 'antd';
import cs from 'classnames';

type P = React.ComponentProps<typeof TagAD>;
const Tag = ({ children, className, ...rest }: P) => {
  return (
    <TagAD className={cs(className, 'tag')} {...rest}>
      {children}
    </TagAD>
  );
};

export default Tag;
