import { type ReduxUser, type Community, type ListEvent } from 'models';
import { Row, Col, Card } from 'ui';

import { EventsListItem } from '../EventsListItem';

type P = {
  viewer: ReduxUser;
  community: Community;
  data: ListEvent[];
};

const EventsList = ({ viewer, data, community }: P) => (
  <Row item={{ className: 'events-list__grid', gutter: [16, 16] }}>
    {data.map((item) => (
      <Col item={{ span: 6 }} key={item.id}>
        <Card shrinked marginBottom={false}>
          <EventsListItem data={item} viewer={viewer} community={community} />
        </Card>
      </Col>
    ))}
  </Row>
);

export default EventsList;
