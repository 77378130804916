import Validator from 'validatorjs';
import i18n from 'i18next';
import { messages as defaultMessages } from './messages';
import * as validators from './validators';

Object.keys(validators).forEach((key) => {
  const validator = validators[key];
  Validator.register(validator.name, validator.validate, validator.errorMessage);
});

export const validator = {
  isValid: (data, rules) => {
    const validation = new Validator(data, rules);
    return validation.passes();
  },

  validateAll: (data, rules, messages = {}) => {
    // Validate
    const validation = new Validator(data, rules, { ...defaultMessages, ...messages });
    // Transform and return errors
    if (validation.check() === false) {
      return Object.keys(validation.errors.all()).reduce((errors, key) => {
        if (validation.errors.has(key)) {
          errors[key] = i18n.t(validation.errors.first(key));
        }
        return errors;
      }, {});
    } else {
      return {};
    }
  },
};
