import { type AutocompleteUser, type InviteType } from 'models';
import { type FormInstance } from 'ui';

import { useEventProgramUsers } from '../../../../queries';
import { type DeprecatedEventProgramProperties } from '../../../../types';
import { type InviteesType } from '../../../EventInvitees';

import { type CommonFormValues } from './hooks';
import InviteesPicker from './InviteesPicker';

type P = {
  allowedInvites?: InviteesType[];
  form: FormInstance<CommonFormValues>;
  inviteType: InviteType;
  onInvite: (inviteType: InviteType, invitees: AutocompleteUser[], emailInvitees?: string[] | undefined) => void;
  postInProgram: DeprecatedEventProgramProperties;
};

const ProgramEventInviteesPicker = ({ postInProgram, ...rest }: P) => {
  const {
    isLoading: isLoadingProgramUsers,
    isFetching: isFetchingProgramUsers,
    isRefetching: isRefetchingProgramUsers,
    data: programUsers,
    error: programUsersError,
  } = useEventProgramUsers(
    { programId: postInProgram.id, programAdmins: postInProgram?.users },
    { useErrorBoundary: false },
  );

  return (
    <InviteesPicker
      {...rest}
      isLoading={isLoadingProgramUsers}
      isFetching={isFetchingProgramUsers}
      isRefetching={isRefetchingProgramUsers}
      data={programUsers}
      error={programUsersError}
    />
  );
};

export default ProgramEventInviteesPicker;
