import * as React from 'react';
import { Field } from 'formik';
import { Manager, Reference, Popper } from 'react-popper';
import ClickOutside from 'react-click-outside';
import DayPicker from 'react-day-picker/DayPicker';
import { format, getDate, getMonth, setDate, setMonth, setYear, parseISO, getYear, isDate } from 'date-fns';

import { withTranslation } from 'react-i18next';
import { FieldBorder } from '../base-atoms/FieldBorder';

import { Input } from '../base-atoms/TextualInput';
import { isInvalid } from '../FieldError';
import { YearMonthSelector } from '../DateField/YearMonthSelector';
import { Box } from '../../box';
import { Text } from '../../text';
import { Icon } from '../../icon';

function getSelectedDays(date) {
  return date ? [date] : [];
}

export class Renderer extends React.Component {
  static defaultProps = {
    formatDate: 'MMM d, yyyy',
    formatTime: 'h:mm a',
  };

  pickerRef = React.createRef();

  state = {
    openDatePicker: false,
  };

  openDatePicker = () => {
    this.setState({ openDatePicker: true });
  };

  closeDatePicker = () => {
    this.setState({ openDatePicker: false });
  };

  showMonth = (date) => {
    this.pickerRef.current.showMonth(date);
  };

  setDate = (fieldProps) => (date) => {
    let value = isDate(fieldProps.field.value) ? fieldProps.field.value : parseISO(fieldProps.field.value);
    value = setDate(value, getDate(date));
    value = setYear(value, getYear(date));
    value = setMonth(value, getMonth(date));
    this.props.onChange({ target: { name: fieldProps.field.name, value: value } });
  };

  renderDatePicker(fieldProps) {
    return (
      <Popper placement="auto-start">
        {({ ref, style, placement }) =>
          this.state.openDatePicker ? (
            <div
              data-placement={placement}
              ref={ref}
              style={{
                zIndex: 1000,
                background: '#ffffff',
                border: '1px solid #cccccc',
                ...style,
              }}
            >
              <DayPicker
                captionElement={({ date }) => (
                  <YearMonthSelector
                    selectedDate={fieldProps.field.value}
                    date={date}
                    range={this.props.range}
                    onChange={this.showMonth}
                  />
                )}
                initialMonth={
                  isDate(fieldProps.field.value)
                    ? fieldProps.field.value ?? undefined
                    : parseISO(fieldProps.field.value)
                }
                navbarElement={() => null}
                onDayClick={this.setDate(fieldProps)}
                ref={this.pickerRef}
                selectedDays={getSelectedDays(fieldProps.field.value)}
                value={fieldProps.field.value}
              />
            </div>
          ) : null
        }
      </Popper>
    );
  }

  render() {
    return (
      <Field name={this.props.name}>
        {(fieldProps) => {
          return (
            <>
              <>
                <Box flexDirection="row" alignItems="center" justifyContent="flex-start" className="date-time">
                  <ClickOutside onClickOutside={this.closeDatePicker} className="date-time__date-wrapper">
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      className="date-time__date"
                    >
                      <Text
                        size="17px"
                        lineHeight="20px"
                        color="#90A4AE"
                        className="date-time__date__text"
                        style={{ width: 'auto' }}
                      >
                        {this.props.label}
                      </Text>
                      <Manager>
                        <Reference>
                          {(refProps) => (
                            <Box flexDirection="row" alignItems="center" className="date-time__input-wrapper">
                              <Input
                                {...refProps}
                                name={fieldProps.field.name}
                                disabled={this.props.disabled}
                                placeholder={this.props.placeholder || this.props.t(this.props.label.props.id)}
                                value={format(
                                  isDate(fieldProps.field.value)
                                    ? fieldProps.field.value
                                    : parseISO(fieldProps.field.value),
                                  this.props.formatDate,
                                )}
                                onClick={this.openDatePicker}
                                readOnly
                                className="date-time__input"
                              />
                              <Icon name="pba-calendar" size="24px" color="#90A4AE" />
                              {this.props.disabled ? null : this.renderDatePicker(fieldProps)}
                            </Box>
                          )}
                        </Reference>
                      </Manager>
                    </Box>
                  </ClickOutside>

                  <Box flexDirection="row" alignItems="center" className="date-time__time date-time__input-wrapper">
                    {format(
                      isDate(fieldProps.field.value) ? fieldProps.field.value : parseISO(fieldProps.field.value),
                      this.props.formatTime,
                    )}
                    <Icon name="clock" size="22px" color="#90A4AE" />
                  </Box>
                </Box>
                <FieldBorder invalid={isInvalid(fieldProps.field.name, fieldProps.form)} />
              </>
              {fieldProps.form.errors[fieldProps.field.name] ? (
                <div
                  style={{
                    color: 'red',
                    fontSize: 12,
                    textAlign: 'left',
                    fontFamily: 'Gilroy, sans-serif',
                    padding: '10px 0',
                  }}
                >
                  {fieldProps.form.errors[fieldProps.field.name]}
                </div>
              ) : null}
            </>
          );
        }}
      </Field>
    );
  }
}

export const CustomDateTimeInput = withTranslation()(Renderer);
