import ChatComponent, { User, type Profile, type DataState, type ChatroomState } from 'chat-web';
import { Suspense, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { getCurrentNodeToken } from 'features/auth/services/nodeApi';
import { getChatroomStats } from 'features/onboarding/services';
import { Loading } from 'ui';
import { AppLayout } from '../../old/layouts/AppLayout';

type MessagesLocation = {
  userToChat?: { _id: string; firstName: string; lastName: string };
};

const PageMessages = ({
  nodeAPIUser,
  setUnreadMessages,
  updateUnreadCount,
  unreadMessages,
  chatState,
}: {
  nodeAPIUser: DataState<Profile>;
  setUnreadMessages: (unreadMessages: number) => void;
  updateUnreadCount: (unreadCount: number) => void;
  unreadMessages: number;
  chatState: ChatroomState;
}) => {
  const { push, location, replace } = useHistory<MessagesLocation | undefined>();
  const { activeConversationId } = useParams<{ activeConversationId: string }>();

  const userToChat = useMemo(
    () => (location.state?.userToChat ? new User(location.state.userToChat) : undefined),
    [location.state],
  );

  const updateTotalUnreadCountForProperties = async (difference: number | undefined = undefined) => {
    if (difference === undefined) {
      const chatroomStatsResponse = await getChatroomStats();
      setUnreadMessages(chatroomStatsResponse.data[0].unread);
    } else {
      updateUnreadCount(Math.max(unreadMessages + difference, 0));
    }
  };

  const nodeApiToken = getCurrentNodeToken();

  const handleUrlForChat = (mode: ChatroomState, id?: string) => {
    switch (mode) {
      case 'chatroom':
        return `/messages/chatrooms/${id}`;
      case 'creating':
        return routes.chat.create();
      case 'default':
        return '/messages/';
    }
  };

  const handleChatState = (chatStateToApply: ChatroomState, id?: string) => {
    if (!chatState && chatStateToApply === 'chatroom') {
      replace(handleUrlForChat(chatStateToApply, id));
      return;
    }

    push(handleUrlForChat(chatStateToApply, id));
  };

  return (
    <AppLayout
      center={
        nodeApiToken && nodeAPIUser.result ? (
          <Suspense fallback={<Loading />}>
            <ChatComponent
              nodeApiToken={nodeApiToken}
              currentUserProfileData={nodeAPIUser}
              isLoading={false}
              updateTotalUnreadCount={updateTotalUnreadCountForProperties}
              defaultNewChatroomUser={userToChat}
              chatroomIdToOpen={activeConversationId}
              handleChatState={handleChatState}
              currentChatState={chatState ?? 'default'}
            />
          </Suspense>
        ) : (
          <Loading />
        )
      }
    />
  );
};

export default PageMessages;
