import * as React from 'react';
import { Trans } from 'react-i18next';
import { format, formatDistanceToNow, parseISO } from 'date-fns';

import { Avatar, Col, Button, Link, DeleteIcon } from 'ui';
import { CircleIcon } from '../../atoms/icon';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { images } from 'common/utils';

export class MemberRow extends React.Component {
  renderRsvpStatusIcon = (joined) => {
    return (
      <ThemeConsumer>
        {(theme) => (
          <CircleIcon
            size={22}
            name={joined ? 'check' : 'question-mark'}
            color="white"
            background={joined ? theme.color.green : theme.color.lightGray}
            className="program-member-row__status-icon"
          />
        )}
      </ThemeConsumer>
    );
  };

  renderDate = (member) => {
    if (this.props.dateType === 'joined_at') {
      return member.joined_at === null ? (
        <Trans>Not joined yet.</Trans>
      ) : (
        `${formatDistanceToNow(parseISO(member.joined_at), { addSuffix: true })} at ${format(
          parseISO(member.joined_at),
          'h:mm a',
        )}`
      );
    }
    if (this.props.dateType === 'invited_at' && member.invited_at) {
      return `${formatDistanceToNow(parseISO(member.invited_at), { addSuffix: true })} at ${format(
        parseISO(member.invited_at),
        'h:mm a',
      )}`;
    }
    return null;
  };

  render() {
    const { member, onRequestUserProfile } = this.props;
    const fullName = `${member.first_name} ${member.last_name}`;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="program-member-row" data-test-id={this.props?.['data-test-id']}>
            <div
              className="program-member-row__cell"
              style={{ width: 70, padding: '0 20px' }}
              data-test-id={this.props?.['data-test-id']}
            >
              <Link to={`/members/${member.id}`} className="program-member-row__icon-wrapper">
                <Avatar name={fullName} photo={member.profile_photo ?? images.default.userProfilePhoto} size={35} />
                {this.renderRsvpStatusIcon(member.joined)}
              </Link>
            </div>
            <div
              className="program-member-row__cell"
              onClick={() => onRequestUserProfile(member)}
              style={{ cursor: 'pointer' }}
            >
              <Text color={theme.color.gray} size="15px" lineHeight="1" data-test-id="member_name">
                {fullName}
              </Text>
            </div>
            <div className="program-member-row__cell" style={{ textAlign: 'right', paddingRight: 20 }}>
              <Text color={theme.color.lightGray} size="12px" lineHeight="1">
                {this.renderDate(member)}
              </Text>
            </div>
            <Col item={{ className: 'program-member-row__icons' }}>
              {this.props.onDeleteMember ? (
                <Button
                  onClick={() => {
                    this.props.onDeleteMember(member, this.props.courseName);
                  }}
                  type="link"
                  danger
                  icon={<DeleteIcon />}
                />
              ) : null}
            </Col>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}
