import io from 'socket.io-client';
import { ID } from '../types';
import { Participant } from '../../models';

export const defaultLegacyActivePracticeId: ID | null = null;

export const defaultUsers: Record<string,Participant> = {};

export const defaultSocketConnection =
  /* eslint-disable camelcase */
  io(String(process.env.REACT_APP_NODE_API_URL), {
    transports: ['websocket'],
  });
/* eslint-enable camelcase */
