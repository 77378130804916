import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { Input } from '../form/base-atoms/Input';
import { Empty, TagLineIcon } from 'ui';
import { Trans } from 'react-i18next';

class Renderer extends React.PureComponent {
  state = {
    search: '',
    showSuggestions: undefined,
  };

  onInputChange = (e) => {
    const { value } = e.target;

    this.setState({ search: value?.toLowerCase() || '' });
  };

  onInputFocus = () => {
    this.setState({ showSuggestions: true });
  };

  handleClickOutside = () => {
    this.setState({ showSuggestions: false });
  };

  onRemoveTag = (tag) => {
    const { tags: oldTags, onChange } = this.props;
    
    const tags = oldTags.filter(oldTag => oldTag !== tag);
    onChange(tags);
  };

  onSelectOption = (option) => {
    const { tags: oldTags, onChange } = this.props;
    
    const tags = [...oldTags, option];
    onChange(tags);
  };

  render() {
    const { search, showSuggestions } = this.state;
    const { tags, options, placeholder } = this.props;

    const selectedOptions = options.filter(option => tags.includes(option.value));
    const suggestionOptions = options
      // Remove already selected options
      .filter(option => !selectedOptions.some(selectedOption => selectedOption.value === option.value))
      // Apply search filter
      .filter(option => option.label.toLowerCase().includes(search));

    return tags && options ? (
      <div className="tags-select">
        <div className="tags-select__upper">
          {selectedOptions.map((selectedOption) => (
            <div key={selectedOption.value} className="tags-select__tag">
              {selectedOption.label}
              <span className="tags-select__tag__remove" onClick={() => this.onRemoveTag(selectedOption.value)}>
                x
              </span>
            </div>
          ))}
          <Input
            placeholder={placeholder}
            onChange={this.onInputChange}
            onFocus={this.onInputFocus}
            className="tags-select__input"
          />
        </div>
        {showSuggestions && (
          <div className="tags-select__suggestions">
            {suggestionOptions.length > 0 ? suggestionOptions.map((option) => (
              <div key={option.value} onClick={() => this.onSelectOption(option.value)} className="tags-select__option">
                {option.label}
                <span className="tags-select__option__add">+</span>
              </div>
            )) : <Empty image={<TagLineIcon />} size="small" description={<Trans>No tags available</Trans>}/>}
          </div>
        )}
      </div>
    ) : null;
  }
}

const clickOutsideConfig = {
  excludeScrollbar: true,
};

export const TagsSelect = onClickOutside(Renderer, clickOutsideConfig);
