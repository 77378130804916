import { CommunityListItem, type ReduxUser } from 'models';
import { useMemo } from 'react';
import { type EventContentObjectDataObject } from 'models/service';

import { EditEventFormModal } from './components';
import { type DeprecatedEventProgramProperties } from './types';

type P = {
  eventId: string;
  isOpened: boolean;
  viewer: ReduxUser;
  onCancel: () => void;
  onSuccess: (data: EventContentObjectDataObject) => Promise<void> | void;
  program?: DeprecatedEventProgramProperties;
  mode: 'duplicate' | 'edit';
};

export const EditEvent = ({ viewer, eventId, mode, ...rest }: P) => {
  const communitiesData = useMemo<CommunityListItem[]>(
    () => viewer.joined_communities.map((community) => new CommunityListItem(community)),
    [viewer],
  );

  return rest.isOpened ? (
    <EditEventFormModal eventId={eventId} viewer={viewer} communities={communitiesData} mode={mode} {...rest} />
  ) : null;
};
