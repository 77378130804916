import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../common/services';

import { ID } from '../../common/types';
import { MessageReadText } from '../../types';
import { ChatroomDataObject } from '../data-objects';

export type StatusChatroomRequest = {
  status: typeof MessageReadText;
};

export const statusChatroom = (chatroom: ID, data: StatusChatroomRequest) =>
  nodeApiClient.post<ChatroomDataObject, AxiosResponse<ChatroomDataObject>>(routes.chatrooms.status(chatroom), data);
