import * as React from 'react';
import { Route } from 'react-router-dom';
import { Context } from './Navigation';
import { escapePath } from './helpers';

export class NavGroup extends React.Component {
  render() {
    const escapedPath = escapePath(this.props.path);
    return (
      <Context.Consumer>
        {(context) => (
          <Context.Provider value={{ ...context, nested: true }}>
            <div className="navigation--nav-group" id={this.props.id}>
              <Route path={escapedPath}>
                {({ match }) => (match || context.openGroups ? this.props.children : null)}
              </Route>
            </div>
          </Context.Provider>
        )}
      </Context.Consumer>
    );
  }
}
