import { Component } from 'react';
import { Image } from 'ui';

export class GalleryPreview extends Component {
  render() {
    return (
      <div style={{ marginBottom: 16 }}>
        <Image.PreviewGroup>
          <Image
            style={{
              objectFit: 'cover',
            }}
            src={this.props.images[0]}
            width={'100%'}
            height={300}
          />
          {this.props.images.length > 1 && (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.props.images.slice(1).map((imgSrc) => (
                <Image
                  key={imgSrc}
                  style={{
                    width: '100%',
                    height: 150,
                    objectFit: 'cover',
                    maxWidth: 143,
                  }}
                  src={imgSrc}
                />
              ))}
            </div>
          )}
        </Image.PreviewGroup>
      </div>
    );
  }
}
