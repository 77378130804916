import { type Profile } from 'models';
import { useTranslation } from 'react-i18next';
import { type UpdateOnboardingTourStepOptionHandler, type SupportedOboardingTourStep } from '../../models';
import { useNavigateThroughOnboardingTourSteps } from '../hooks';
import { InfoStep } from '../InfoStep';

const IntroStep = ({
  viewer,
  steps,
  onChangeStep,
  onLogout,
}: {
  viewer: Profile;
  steps: SupportedOboardingTourStep[];
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
  onLogout: () => void;
}) => {
  const { t } = useTranslation();
  const { onNavigateToNextStep } = useNavigateThroughOnboardingTourSteps({ step: 'intro', steps, onChangeStep });

  return (
    <InfoStep
      title={t('Hello {{firstName}}!', { firstName: viewer.firstName })}
      description={t('onboardingTourWelcomeSubtitle', { count: steps.length })}
      primaryButton={{ onClick: onNavigateToNextStep, children: 'Get started' }}
      secondaryButton={{ onClick: onLogout, children: 'Log out' }}
    />
  );
};

export default IntroStep;
