import { Col, Row, Skeleton } from 'ui';

type P = {
  cols: number;
};

const TodosWidgetLoadingState = ({ cols }: P) => {
  return (
    <Row item={{ className: 'todos-widget-loading-state' }}>
      {[...Array.from({ length: cols }).keys()].map((todo) => (
        <Col key={todo} item={{ span: 24, className: 'todos-widget-loading-state__item' }}>
          <Skeleton active paragraph={{ rows: 2 }} />
        </Col>
      ))}
    </Row>
  );
};

export default TodosWidgetLoadingState;
