import * as React from 'react';

import { AppService } from '../../services/AppService';
import { Box } from '../../atoms/box';
import { Loader } from '../../atoms/loader';
import { getSession, setSession } from '../../utils/session-storage';

import variables from 'common/styles/variables.json';

/**
 * Helpers
 */
const STORAGE_KEY = `APP_CONFIG`;

let actualConfig = null;
function getPossiblyPeristedConfig() {
  if (!actualConfig) {
    const config = getSession(STORAGE_KEY);
    const configParsed = config ? JSON.parse(config) : null;
    actualConfig = configParsed
      ? {
          ...configParsed,
          colors: { ...configParsed.colors, green: variables.greenColor.value, brand: variables.brandColor.value },
        }
      : null;
  }

  return actualConfig;
}

function persistConfig(config) {
  actualConfig = null;
  setSession(STORAGE_KEY, JSON.stringify(config));
}

export class AppConfigGate extends React.Component {
  state = {
    config: getPossiblyPeristedConfig(),
  };

  constructor(props) {
    super(props);

    if (this.state.config === null) {
      this.fetchConfig();
    }
  }

  async fetchConfig() {
    const slug = process.env.REACT_APP_CONFIG;
    const response = await AppService.getConfig(slug);
    if (response.problem) {
      throw Error('[App] Could not load theme for this app deployment');
    }

    persistConfig(response.data);

    this.setState({ config: response.data });
  }

  render() {
    return this.state.config === null ? (
      <Box justifyContent="center" style={{ height: '100%' }}>
        <Loader />
      </Box>
    ) : (
      this.props.children
    );
  }
}

// TODO export into util
export function getAppConfig() {
  // $FlowExpectedError
  return (
    getPossiblyPeristedConfig() || {
      colors: {
        brand: '#2E4690',
        infoColor: '#2B73B6',
        warnColor: '#cd853f',
        errorColor: '#cc3232',
        successColor: '#32A54A',
        link: '#00B0FF',
        facebookBlue: '#3b5998',
        twitterBlue: '#1da1f2',
        linkedinBlue: '#007bb6',
        googleRed: '#EA4335',
        white: '#ffffff',
        green: '#00e676',
        red: '#ff1744',
        darkBlue: '#38505A',
        grayBlue: '#4e5f67',
        gray: '#455A64',
        lightGray: '#90A4AE',
      },
      constants: {
        FACEBOOK_ID: '',
        GOOGLE_ID: '',
        GOOGLE_GA: '',
        DOMAIN_URL: '',
        SHARE_URL: '',
      },
      organization: null,
      assets: {
        logo: '',
      },
      feature_flags: {},
    }
  );
}
