import { createModel } from '@rematch/core';
import { normalize } from 'normalizr';
import { PollSchema } from './schemas';
import { dispatch } from '../store';

function normalizePollData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [PollSchema] : PollSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

export const model = createModel({
  name: 'poll',

  state: {},

  effects: () => ({
    votePoll({ poll }) {
      normalizePollData(poll);
    },
  }),
});
