import * as React from 'react';
import { Trans } from 'react-i18next';
import { Manager, Reference, Popper } from 'react-popper';
import Downshift from 'downshift';
import { DefaultDropdownElement } from './DefaultDropdownElement';
import { DefaultDropdownItemElement } from './DefaultDropdownItemElement';
import { Loader } from '../loader';
import { Text } from '../text';

export class Autocomplete extends React.Component {
  static defaultProps = {
    clickable: true,
    clearOnSelect: false,
    DropdownComponent: DefaultDropdownElement,
    DropdownItemComponent: DefaultDropdownItemElement,
    renderInput: (ref, props) => <input autoComplete="off" ref={ref} {...props} />,
    renderItem: (item, itemAsString) => itemAsString,
    currentSelectedData: [],
  };

  attempt = 0;
  timeout = 0;

  state = {
    data: [],
    search: null,
    loading: false,
  };

  setTerm = (value) => {
    if (value || this.attempt >= 1) {
      this.setState({ search: value }, async () => {
        this.attempt += 1;
        await this.getDataForValue();
      });
    }
  };

  completeDebouncedGetData = (_attempt, data) => {
    const currentDataKeys = this.props.currentSelectedData.map((item) => item.id);
    const result = data.filter((item) => !currentDataKeys.includes(item.id));
    if (_attempt === this.attempt) {
      this.setState({ data: result, search: '' });
    }
    this.setState({ loading: false });
  };

  getDataForValue = async () => {
    const _attempt = this.attempt;
    this.setState({ loading: true });
    let data = [];
    if (this.props.clickable || (!this.props.clickable && this.state.search)) {
      data = await this.props.dataForValue(this.state.search, (data) => this.completeDebouncedGetData(_attempt, data));
    }

    if (data) {
      this.completeDebouncedGetData(_attempt, data);
    }
  };

  render() {
    const { DropdownItemComponent, DropdownComponent } = this.props;
    return (
      <Manager>
        <Downshift
          defaultSelectedItem={this.props.defaultSelectedItem}
          defaultIsOpen
          itemToString={this.props.itemToString}
          onChange={(value, ds) => {
            this.props.onSelect(value);
            if (this.props.currentSelectedData.length > 0) {
              this.setState({ search: '' });
            }
            if (this.props.clearOnSelect) {
              ds.clearItems();
              ds.clearSelection();
              this.attempt = 0;
            }
          }}
          onInputValueChange={(value, ds) => {
            if (ds.isOpen || value) {
              this.setTerm(value);
            }
          }}
        >
          {(ds) => (
            <div>
              <Reference>
                {({ ref }) =>
                  this.props.renderInput(
                    ref,
                    ds.getInputProps({
                      disabled: this.props.disabled,
                      placeholder: this.props.placeholder,
                      onClick: async () => {
                        if (this.props.clickable) {
                          await this.getDataForValue();
                          ds.openMenu();
                        }
                      },
                    }),
                  )
                }
              </Reference>
              <div style={{ position: 'relative' }}>
                {this.state.loading && (
                  <div
                    style={{
                      position: 'absolute',
                      width: 25,
                      right: 10,
                      top: -33,
                    }}
                  >
                    <Loader size={20} />
                  </div>
                )}
                {!this.state.loading && this.state.data.length === 0 && ds.inputValue && this.attempt > 0 && (
                  <Text lineHeight="32px" style={{ paddingLeft: 5 }}>
                    <Trans>No Results Found</Trans>
                  </Text>
                )}
              </div>
              {ds.isOpen && this.state.data.length > 0 ? (
                <Popper
                  modifiers={{
                    flip: { enabled: false },
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                  }}
                  placement="bottom-start"
                  style={{
                    backgroundColor: 'orange',
                  }}
                >
                  {({ ref, style, placement }) => (
                    // $FlowExpectedError issue with ref forwarding
                    <DropdownComponent ref={ref} style={style} data-placement={placement} id="dropdown_component">
                      {!this.state.loading &&
                        this.state.data.map((item, index) => (
                          <DropdownItemComponent
                            // $FlowExpectedError item typing
                            key={this.props.getRowId(item)}
                            highlighted={ds.highlightedIndex === index}
                            selected={ds.itemToString(item) === ds.selectedItem}
                            {...ds.getItemProps({ item: item })}
                            id={`dropdown_component-item[${index}]`}
                          >
                            {
                              // $FlowExpectedError item typing
                              this.props.renderItem(item, ds.itemToString(item))
                            }
                          </DropdownItemComponent>
                        ))}
                    </DropdownComponent>
                  )}
                </Popper>
              ) : null}
            </div>
          )}
        </Downshift>
      </Manager>
    );
  }
}
