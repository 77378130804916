import { useMemo } from 'react';
import { type SupportedOboardingTourStep } from '../../../../models';

export const useSupportedStepProgress = ({
  step,
  steps,
}: {
  step: SupportedOboardingTourStep;
  steps: SupportedOboardingTourStep[];
}) =>
  useMemo(() => {
    const currentStepIndex = steps.findIndex((supportedStep) => supportedStep.step === step.step);

    const current = Math.max(currentStepIndex, 0) + 1;
    const total = Math.max(steps.length, 1);

    return {
      current,
      total,
      percent: Math.min((current / total) * 100, 100),
    };
  }, [step.step, steps]);
