import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

// Layout
import { AppLayout } from '../../layouts/AppLayout';
import { RegularFeed } from '../../atoms/feed';
import { Sidebar } from '../../atoms/sidebar';

import { getFeedKey } from '../../models/feed';
import { Title } from '../../../ui';
import { useFeedbackOptions } from 'pages/hooks';

class Renderer extends React.Component {
  static defaultProps = {
    community: null,
  };

  componentDidMount() {
    this.props.resetFeed();
  }

  navigateToEvents = () => {
    this.props.history.push(`/events`);
  };

  navigateToEvent = (eventId) => {
    this.props.history.push(`/events/${eventId}/about`);
  };

  render() {
    return (
      <AppLayout
        center={
          <RegularFeed
            community={this.props.community}
            limit={7}
            viewer={this.props.viewer}
            onInitiatePostEditing={this.initiatePostEditing}
            onRequestEventDetail={this.navigateToEvent}
            reloadingPost={this.props.reloadingPost}
            type="bookmarks"
            render={(feedNode, actions) => (
              <React.Fragment>
                <Title level={1}>Bookmarks</Title>
                <div>{feedNode}</div>
              </React.Fragment>
            )}
          />
        }
        right={
          <Sidebar
            viewer={this.props.viewer}
            navigateToEvents={this.navigateToEvents}
            numberOfNotifications={this.props.badges.total}
            unreadMessages={this.props.unreadMessages}
            feedbackOptions={this.props.feedbackOptions}
          />
        }
      />
    );
  }
}

const mapDispatch = (dispatch, props) => {
  const feedName = getFeedKey(props);
  return {
    resetFeed: () => {
      dispatch.feed.reset();
    },
    reloadContentObject: (object) => {
      dispatch.feed.reloadAsync({ feed: feedName, postId: object.id });
    },
  };
};

const mapState = (state, props) => {
  return {
    viewer: select.session.user(state),
    reloadingPost: state.loading.effects.feed.reloadAsync,
    badges: select.notifications.badges(state),
    unreadMessages: select.session.unreadMessages(state),
  };
};

const PageBookmarksFunctional = (props) => {
  const feedbackOptions = useFeedbackOptions();
  return <Renderer {...props} feedbackOptions={feedbackOptions} />;
};

export const PageBookmarks = connect(mapState, mapDispatch)(PageBookmarksFunctional);
