import { type AutocompleteUser } from 'models';
import { UserZoneLayout, TABS } from 'old/layouts/UserZoneLayout';
import { ProfileSideNav } from 'old/pages/page-profile/ProfileSideNav';
import { connect } from 'react-redux';
import { UserRemoveAccount } from 'features/user-profile';

type P = {
  user: AutocompleteUser;
  logOut: () => void;
};

const PageRemoveUserAccount = ({ user, logOut }: P) => {
  return (
    <UserZoneLayout navigation={<ProfileSideNav user={user} />} contentId={TABS.profile}>
      <UserRemoveAccount logOut={logOut} />
    </UserZoneLayout>
  );
};

const mapStateToProperties = (state: { session: { user: AutocompleteUser } }) => ({ user: state.session.user });
// @ts-expect-error
const mapDispatchToProperties = (dispatch) => ({ logOut: dispatch.session.logOut });

export default connect(mapStateToProperties, mapDispatchToProperties)(PageRemoveUserAccount);
