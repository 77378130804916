import { type ReduxUser, type BasicContentObjectRedux } from 'models';
import { canLikeContentObject, canShowComments, canRepostContentObject } from 'permissions';
import { CommentButton } from '../CommentButton';
import { LikeButton } from '../LikeButton';
import { RepostButton } from '../RepostButton';
import { ShareButton } from '../ShareButton';
import { ViewButton } from '../ViewButton';

type P = {
  item: BasicContentObjectRedux;
  onShowComments?: () => void;
  viewer: ReduxUser;
  view: 'bookmark' | 'other'; // TODO move to some `type`
};

const BasicActionBar = ({ item, onShowComments, viewer, view }: P) => {
  return (
    <div className="basic-action-bar">
      {view === 'bookmark' ? <ViewButton item={item} /> : null}
      {view === 'other' ? (
        <>
          {canLikeContentObject({ contentObject: item }) ? <LikeButton item={item} /> : null}
          {canShowComments({ viewer, contentObject: item }) ? <CommentButton onShowComments={onShowComments} /> : null}
          {canRepostContentObject({ viewer, contentObject: item }) ? (
            <RepostButton item={item} viewer={viewer} />
          ) : null}
          <ShareButton item={item} viewer={viewer} />
        </>
      ) : null}
    </div>
  );
};

export default BasicActionBar;
