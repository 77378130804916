import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type CommunityDataObject } from 'models/service';

export type UpdateCommunityRequest = {
  cover_photo?: {
    id: string;
  };
  html_description?: string;
  description?: string;
};

export const updateCommunity = async (communityId: string, data: UpdateCommunityRequest) =>
  apiClient.patch<CommunityDataObject, ApiClientResponse<CommunityDataObject>>(routes.community.update(communityId), {
    community: data,
  });
