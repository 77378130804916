import * as React from 'react';
import { Trans } from 'react-i18next';

import { Avatar } from 'ui';
import { Box } from '../box';
import { Icon } from '../icon';
import { ShadowBox } from '../shadow-box';
import { Text, H3, H1, Paragraph } from '../text';
import { ThemeConsumer } from '../theme';
import { images } from 'common/utils';
import { ChatToButton } from '../../widgets/chat-to-button';

export class MemberProfile extends React.Component {
  onCreateMessage = (manager) => () => {
    const user = this.props.member;
    manager.sendMessageForRemoteUsers({}, [user]);
  };

  renderRow(clickedObject, isCommunity) {
    return (
      <div
        key={clickedObject.id}
        onClick={() => {
          isCommunity ? this.props.onClickToCommunity(clickedObject) : this.props.onClickToOrganization(clickedObject);
        }}
      >
        <Box flexDirection="row" style={{ padding: 10, borderBottom: '1px solid #edeff2', cursor: 'pointer' }}>
          <Avatar
            size={35}
            photo={isCommunity ? (clickedObject.organization.profile_photo ?? images.default.organizationProfilePhoto) : (clickedObject.profile_photo ?? images.default.communityProfilePhoto)}
            name={clickedObject.name}
          />
          <Box style={{ marginLeft: 20 }}>
            <Text lineHeight="34px">{clickedObject.name}</Text>
          </Box>
        </Box>
      </div>
    );
  }

  render() {
    const { member } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <ShadowBox>
            <Box style={{ padding: '60px 20px 20px' }} className="member-profile">
              <Box style={{ margin: '0 auto', position: 'relative' }}>
                <div style={{ margin: '0 auto' }}>
                  <Avatar size={115} photo={member.profile_photo ?? images.default.userProfilePhoto} name={`${member.first_name} ${member.last_name}`} />
                </div>
              </Box>
              <Box alignItems="center">
                <H1 style={{ marginBottom: 5 }}>
                  <Text>{`${member.first_name} ${member.last_name}`}</Text>
                </H1>
                {member.profession ? <Text size={16}>{member.profession.name}</Text> : null}
              </Box>
              <Box flexDirection="row" justifyContent="center" className="member-profile__chat-to-button">
                <ChatToButton legacyUserId={member.id} useTextButton />
              </Box>
              {this.props.member.id !== this.props.viewer.id && (
                <Box flexDirection="row" justifyContent="center" alignItems="center">
                  <Box flexDirection="row" style={{ width: 180, padding: 10 }} justifyContent="center" />
                </Box>
              )}

              <Box flexDirection="row" justifyContent="center">
                <Box style={{ width: '70%', paddingTop: 30 }}>
                  <Box>
                    <H3 style={{ paddingBottom: 5, textTransform: 'uppercase' }} color={theme.color.lightGray}>
                      <Trans>About me</Trans>
                    </H3>
                    <Paragraph style={{ wordBreak: 'break-word' }}>{member.biography}</Paragraph>
                  </Box>
                  <Box style={{ paddingBottom: 50 }}>
                    {member.company ? (
                      <Box flexDirection="row">
                        <Icon name="briefcase" size={24} color={theme.color.lightGray} />
                        <Text style={{ marginLeft: 15 }} lineHeight="34px">
                          <Trans>Works at:</Trans> <Text weight={500}>{member.company}</Text>
                        </Text>
                      </Box>
                    ) : null}
                    {member.phone ? (
                      <Box flexDirection="row">
                        <Icon name="phone" size={24} color={theme.color.lightGray} />
                        <Text style={{ marginLeft: 15 }} lineHeight="34px" weight={500} color={theme.color.brand}>
                          {member.phone}
                        </Text>
                      </Box>
                    ) : null}
                    <Box flexDirection="row">
                      <Icon name="mail" size={24} color={theme.color.lightGray} />
                      <Text style={{ marginLeft: 15 }} lineHeight="34px" weight={500} color={theme.color.brand}>
                        {member.email}
                      </Text>
                    </Box>
                  </Box>
                  <H3 style={{ paddingBottom: 5, textTransform: 'uppercase' }} color={theme.color.lightGray}>
                    <Trans>Joined Organizations</Trans>
                  </H3>
                  <Box style={{ padding: '10px 0', paddingBottom: 50 }}>
                    <Box className="member-profile__organizations">
                      {member.joined_organizations.map((community) => this.renderRow(community, false))}
                    </Box>
                  </Box>
                  <H3 style={{ paddingBottom: 5, textTransform: 'uppercase' }} color={theme.color.lightGray}>
                    <Trans>Joined Communities</Trans>
                  </H3>
                  <Box style={{ padding: '10px 0' }}>
                    <Box className="member-profile__organizations">
                      {member.joined_communities.map((community) => this.renderRow(community, true))}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box />
            </Box>
          </ShadowBox>
        )}
      </ThemeConsumer>
    );
  }
}
