import * as React from 'react';
import qs from 'qs';
import { PlusOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { Card, Title, Button } from 'ui';

import { AppLayout } from '../../layouts/AppLayout';
import { Box } from '../../atoms/box';
import { EventEditor } from '../../atoms/event-editor';
import { EventsList } from '../../widgets/events-list';
import { SegmentedControl } from '../../atoms/segmented-control';
import { Loader } from '../../atoms/loader';

import { TelehealthRequest } from 'features/events/components/Telehealth';
import { getCreateEventPermissions } from 'features/events/utils';
import { CreateTelehealthEvent, ChangeRequestTelehealthEvent } from 'features/events';
import { useFeedbackOptions } from 'pages/hooks';

export class Renderer extends React.Component {
  state = {
    visibleModal: false,
    telehealthPrefillData: undefined,
  };

  componentDidMount() {
    const { eventType, postInCommunities, relatedAdmissionId, invitees, change_request } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true,
      },
    );

    this.setState({ changeRequest: change_request });

    // TODO solve better
    const isPrefill =
      eventType === 'telehealth_visit' &&
      Boolean(postInCommunities) &&
      Array.isArray(invitees) &&
      typeof invitees[0] === 'string' &&
      (relatedAdmissionId === undefined || typeof relatedAdmissionId === 'string');
    this.setState({
      telehealthPrefillData: isPrefill
        ? { eventType, communityId: postInCommunities, invitees, relatedAdmissionId }
        : undefined,
    });
  }

  onOpenModal() {
    this.setState({ visibleModal: true });
  }

  onCloseModal = () => {
    this.setState({ visibleModal: false });
  };

  onSuccess = (event) => {
    this.props.createEvent(event);
    this.onCloseModal();
    if (this.props.location.pathname === '/events/Past') {
      this.props.history.push('/events/Upcoming');
    }
  };

  onCloseTelehealthPrefill = () => {
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    delete query.eventType;
    delete query.postInCommunities;
    delete query.invitees;
    delete query.change_request;
    this.props.history.replace({ pathname: this.props.location.pathname, search: qs.stringify(query) });
    this.setState({ telehealthPrefillData: undefined, changeRequest: undefined });
  };

  onSuccessTelehealthPrefill = (event) => {
    this.props.createEvent(event);
    this.onCloseTelehealthPrefill();
  };

  render() {
    const { joinedCommunities, createEventAllowedTypes, isCreateButtonAvailable, isTelehealthButtonAvailable } =
      getCreateEventPermissions({ viewer: this.props.user });

    return (
      <React.Fragment>
        <AppLayout
          center={
            <>
              <Title level={2} marginTop={false}>
                Events
              </Title>
              <Card
                className="events-page"
                title={
                  <div className="events-page__head">
                    <Box flexDirection="row" alignItems="center" flexGrow="1">
                      <div style={{ width: 200 }}>
                        <SegmentedControl
                          labels={['Upcoming', 'Past']}
                          selectedLabel={this.props.scope}
                          onChange={this.props.setScope}
                          height={25}
                          disabled={this.props.loadingEvents}
                        />
                      </div>
                    </Box>

                    {this.state.telehealthPrefillData && createEventAllowedTypes.includes('telehealth_visit') ? (
                      <CreateTelehealthEvent
                        viewer={this.props.user}
                        eventTypes={createEventAllowedTypes}
                        onCancel={(force) => {
                          if (force || window.confirm(this.props.t('Do you wish to discard your Event?'))) {
                            this.onCloseTelehealthPrefill();
                          }
                        }}
                        onSuccess={this.onSuccessTelehealthPrefill}
                        initialData={this.state.telehealthPrefillData}
                      />
                    ) : null}

                    {this.state.changeRequest && createEventAllowedTypes.includes('telehealth_visit') ? (
                      <ChangeRequestTelehealthEvent
                        viewer={this.props.user}
                        changeRequest={this.state.changeRequest}
                        onCancel={(force) => {
                          if (force || window.confirm(this.props.t('Do you wish to discard your Event?'))) {
                            this.onCloseTelehealthPrefill();
                          }
                        }}
                        onSuccess={this.onSuccessTelehealthPrefill}
                      />
                    ) : null}
                    {isTelehealthButtonAvailable ? (
                      <TelehealthRequest
                        viewer={this.props.user}
                        communities={joinedCommunities}
                        buttonType="tertiary"
                      />
                    ) : null}
                    {isCreateButtonAvailable ? (
                      <Button
                        onClick={() => this.onOpenModal()}
                        icon={<PlusOutlined className="create-event-button__icon" />}
                        type="primary"
                        size="large"
                        className="action-button"
                      >
                        Create
                      </Button>
                    ) : null}
                  </div>
                }
              >
                {this.props.loading ? (
                  <Loader size={30} />
                ) : (
                  <EventsList limit={15} scope={this.props.scope} feedbackOptions={this.props.feedbackOptions} />
                )}
              </Card>
            </>
          }
        />
        <EventEditor
          eventTypes={createEventAllowedTypes}
          isEditorOpen={this.state.visibleModal}
          onRequestClose={this.onCloseModal}
          onSuccess={this.onSuccess}
        />
      </React.Fragment>
    );
  }
}

const mapState = (state, props) => ({
  scope: props.match.params.scope || 'Upcoming',
  loading: state.loading.effects.events.create,
  loadingEvents: state.loading.effects.events.fetchAsync,
  user: select.session.user(state),
});

const mapDispatch = (dispatch, props) => ({
  setScope: (scope) => props.history.push(`/events/${scope}`),
  createEvent: (event) => dispatch.events.create(event),
});

const PageEventsFunctional = (props) => {
  const feedbackOptions = useFeedbackOptions();
  return <Renderer {...props} feedbackOptions={feedbackOptions} />;
};

export const PageEvents = withTranslation()(connect(mapState, mapDispatch)(PageEventsFunctional));
