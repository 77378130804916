import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../../common/services';

import { ID } from '../../../common/types';
import { ChatroomDataObject } from '../../data-objects';

type RemoveMemberChatroomDataObject = ChatroomDataObject;

export const removeMemberFromChatroom = (chatroom: ID, member: ID) =>
  nodeApiClient.delete<RemoveMemberChatroomDataObject, AxiosResponse<RemoveMemberChatroomDataObject>>(
    routes.chatrooms.members.remove(chatroom, member),
  );
