import { type ReduxUser } from 'models';
import { useGenders, useUpdateUserProfile } from 'features/user-profile/queries';
import { PersonalInfoForm, type PersonalInfoFormValues } from './PersonalInfoForm';
import { PersonalInfoSkeleton } from './PersonalInfoSkeleton';

type P = {
  viewer: ReduxUser;
  onCancel: () => void;
  onSuccess: () => void;
};

const PersonalInfoContent = ({ viewer, onCancel, onSuccess }: P) => {
  const { data: genders, isLoading: isLoadingGenders } = useGenders();
  const { mutate: updateProfile, isLoading: isUpdatingProfile } = useUpdateUserProfile();

  const onSubmit = (values: PersonalInfoFormValues) => {
    updateProfile(
      {
        userId: viewer.id,
        userData: values,
      },
      {
        onSuccess,
      },
    );
  };

  if (isLoadingGenders || !genders) {
    return <PersonalInfoSkeleton />;
  }

  return (
    <PersonalInfoForm
      viewer={viewer}
      genders={genders}
      isLoading={isUpdatingProfile}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

export default PersonalInfoContent;
