import { type MetaItemTranslations } from 'models';
import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ChangeRequestStatus } from 'models/service';

type GetChangeRequestParams = {
  changeRequestId: string;
};

type TelehealthTypeDataObject = {
  name: string;
  translations: MetaItemTranslations;
};

type CreateEventChangeRequestMetaDataObject = {
  patient_type: TelehealthTypeDataObject;
  visit_type: TelehealthTypeDataObject;
  visit_type_option: TelehealthTypeDataObject;
  visit_type_sub_option?: TelehealthTypeDataObject;
  visit_duration: number;
  visit_date: string;
  text_content: string;
  html_content: string;
};

type CommonCreateEventChangeRequestDataObject = {
  id: string;
  subject: {
    subject_id: string;
    subject_type: string;
  };
  subject_user: {
    id: string;
  };
  created_user: {
    id: string;
  };
  kind: 'event_request_to_create';
};

export type DefaultCreateEventChangeRequestDataObject = CommonCreateEventChangeRequestDataObject & {
  status: Exclude<ChangeRequestStatus, 'approved'>;
  meta: CreateEventChangeRequestMetaDataObject;
};

export type ApprovedCreateEventChangeRequestDataObject = CommonCreateEventChangeRequestDataObject & {
  status: 'approved';
  meta: CreateEventChangeRequestMetaDataObject & { event_id: string };
};

export type CreateEventChangeRequestDataObject =
  | ApprovedCreateEventChangeRequestDataObject
  | DefaultCreateEventChangeRequestDataObject;

// TODO fix duplicity with onboarding
export const getChangeRequest = async ({ changeRequestId }: GetChangeRequestParams) =>
  apiClient.get<CreateEventChangeRequestDataObject, ApiClientResponse<CreateEventChangeRequestDataObject>>(
    routes.changeRequests.get(changeRequestId),
  );
