import { type EntityOptions, getNestedEntity } from 'models';
import { EntityList } from 'features/channels';
import { CompassIcon, Empty } from 'ui';
import { useChannelEntitiesQuery } from '../../queries';
import { type EntityType } from '../../types';

type P = {
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
  channelId: string;
  subject?: EntityType;
};

const AllEntities = ({ getPathToEntityDetail, subject, channelId }: P) => {
  const {
    data: channelEntitiesData,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useChannelEntitiesQuery({
    pageSize: 12,
    channelId,
    subject,
  });

  const entities = channelEntitiesData?.pages.flat();

  return (
    <EntityList
      getPathToEntityDetail={getPathToEntityDetail}
      empty={
        <Empty
          image={<CompassIcon />}
          description={{
            title: 'No channels',
            subtitle: 'There are no channels to display here',
          }}
        />
      }
      entities={entities?.map((entity) => getNestedEntity(entity))}
      isLoading={isLoading}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      showTag
      showPrice
    />
  );
};

export default AllEntities;
