import { type FolderAsset } from 'models';
import { useMemo, useState } from 'react';

export const useAssetsFoldersParents = () => {
  const [parents, setParents] = useState<FolderAsset[]>([]);
  const lastParentFolder: FolderAsset | undefined = parents[parents.length - 1];

  const result: {
    currentFolderName: string;
    lastParentFolder?: FolderAsset;
    addParentFolder: (folder: FolderAsset) => void;
    removeLastParentFolder: () => void;
  } = useMemo(
    () => ({
      currentFolderName: lastParentFolder ? lastParentFolder.name : 'All Files',
      lastParentFolder,
      addParentFolder(folder: FolderAsset) {
        setParents((previous) => [...previous, folder]);
      },
      removeLastParentFolder() {
        setParents((previous) => previous.slice(0, -1));
      },
    }),
    [lastParentFolder],
  );

  return result;
};
