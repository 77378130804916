import { type PaginationData } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { getTodos } from '../services';
import { createTodo, type Todo } from '../types';
import { getTodosQueryKey } from './';

export const useTodosQuery = ({ pageSize, featured }: { pageSize: number; featured: boolean }) =>
  useInfiniteQuery<PaginationData<Todo>>(
    getTodosQueryKey({ pageSize, featured }),
    async ({ pageParam: cursor }: QueryFunctionContext<QueryKey, string>) => {
      const { data: todos } = await getTodos({ cursor, limit: pageSize, featured });

      return {
        total: todos.total,
        data: todos.data.map((todoDataObject) => createTodo(todoDataObject)),
      };
    },
    {
      getNextPageParam: (lastPage) => (lastPage.data.length === pageSize ? lastPage.data.at(-1)?.id : undefined),
      staleTime: 5 * 60 * 1000,
      cacheTime: 0,
      refetchInterval: 5 * 60 * 1000,
    },
  );
