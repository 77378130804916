const convertCentimetersToInches = (cm: number) => Math.round(cm / 2.54);
const convertInchesToCentimeters = (inch: number) => Math.round(inch * 2.54);
const convertFeetToInches = (feet: number) => Math.round(feet * 12);
export { convertCentimetersToInches, convertInchesToCentimeters, convertFeetToInches };

const getAllInches = () => {
  const data: Array<{ label: string; value: number }> = [];

  for (let index = 1; index < 100; index++) {
    data.push({
      label: `${Math.floor(index / 12)}’ ${index % 12}”`,
      value: index,
    });
  }

  return data;
};

export default getAllInches();
