import { CheckOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, DeleteIcon } from 'ui';

export function ActionsBar({ undoAction, clearAction, doneAction, isUploading }) {
  return (
    <div className="action-bar">
      <Button onClick={() => doneAction()} type="text" loading={isUploading} icon={<CheckOutlined />}>
        Done
      </Button>
      <Button onClick={() => undoAction()} disabled={isUploading} type="text" icon={<UndoOutlined />}>
        Undo
      </Button>
      <Button onClick={() => clearAction()} disabled={isUploading} type="text" icon={<DeleteIcon />}>
        Clear
      </Button>
    </div>
  );
}
