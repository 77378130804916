import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';
import { getOnboardingTourStepsQueryKey } from '..';
import { completeOnboardingTour } from '../../services';

export const useCompleteOnboardingTour = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Record<string, unknown>>(
    async () => {
      const { data } = await completeOnboardingTour();

      return data;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(getOnboardingTourStepsQueryKey());
      },
    },
  );
};
