import * as React from 'react';
import { AppLayout } from './AppLayout';
import { TwoColumnsInnerLayout } from './TwoColumnsInnerLayout';
import { select } from '@rematch/select';
import connect from 'react-redux/es/connect/connect';
import { CampaignsTopNav } from './CampaignsTopNav';
import { OneColumnInnerLayout } from './OneColumnInnerLayout';

export class Renderer extends React.Component {
  render() {
    return (
      <AppLayout
        center={
          <>
            <CampaignsTopNav {...this.props} />
            {this.props.navigation ? (
              <TwoColumnsInnerLayout right={this.props.children} left={this.props.navigation} wide />
            ) : (
              <OneColumnInnerLayout right={this.props.children} />
            )}
          </>
        }
      />
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const CampaignsLayout = connect(mapState)(Renderer);
