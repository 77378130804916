import { type CategoryDeprecated, type ReduxUser } from 'models';
import { type FC, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Program from 'models/program';
import { message } from 'ui';
import { type OrganizationDataObject } from '../../models/service';
import { ProgramForm } from './form';
import { useInvalidatePrograms } from './queries';
import api from './services/api';

type P = {
  onSuccess: (any: Program) => Promise<void> | void;
  currentUser: ReduxUser;
  organizationId?: string;
};

const CreateProgram: FC<P> = ({ onSuccess, currentUser, organizationId, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const programData = useMemo(() => new Program(), []);
  const [categoriesData, setCategoriesData] = useState<CategoryDeprecated[]>([]);
  const organizationsData = useMemo<OrganizationDataObject[]>(
    () => currentUser.joined_organizations.filter((org) => currentUser.admin_organizations.includes(org.id)),
    [currentUser],
  );
  const [error, setError] = useState<Error>();

  const { t } = useTranslation();
  const invalidatePrograms = useInvalidatePrograms();

  const fetchData = () => {
    (async () => {
      setLoading(true);
      const categoriesResponse = await api.getCategories({ type: 'program' });
      setLoading(false);

      if (categoriesResponse.ok) {
        setCategoriesData(categoriesResponse.data);
        setError(undefined);
      } else {
        setError(new Error(categoriesResponse.errorMessage ?? 'Something went wrong.'));
        message.error('Failed to load data.');
      }
    })();
  };

  const getUsers = async (scope: string, value: string, limit = 10) => {
    const response = await api.getMembers({
      scope,
      search: value,
      limit,
    });

    if (response.ok) {
      return response.data;
    }

    setError(new Error(response.errorMessage ?? 'Something went wrong.'));
    message.error('Failed to load data.');
  };

  // TODO fix type
  const onSubmit = async (program: any) => {
    setLoading(true);
    const programResponse = await api.createProgram(program);

    if (programResponse.ok) {
      await onSuccess(programResponse.data);
      message.success('Program was created.');
      invalidatePrograms();
    } else {
      message.error('Failed to create program.');
    }

    setLoading(false);
  };

  useEffect(fetchData, []);

  return (
    <ProgramForm
      onSubmit={onSubmit}
      getUsers={getUsers}
      error={error}
      loading={loading}
      program={programData}
      categories={categoriesData}
      currentUser={currentUser}
      organizations={organizationsData}
      formTitle={t('Create program')}
      organizationId={organizationId}
      {...rest}
    />
  );
};

export default CreateProgram;
