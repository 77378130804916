export const isPasswordValid = (value: string) => {
  return validatePasswordConditionSeparatly(value).allConditions;
};

export const passwordRules = [
  {
    key: 'lowercase',
    title: 'Lowercase letter',
    rule: (value: string) => /[a-z]/.test(value),
  },
  {
    key: 'number',
    title: 'Number',
    rule: (value: string) => /\d/.test(value),
  },
  {
    key: 'longEnough',
    title: 'At least 12 characters',
    rule: (value: string) => value.length >= 12 && value.length <= 72,
  },
  {
    key: 'uppercase',
    title: 'Uppercase letter',
    rule: (value: string) => /[A-Z]/.test(value),
  },
  {
    key: 'special',
    title: 'Symbol: @#$%^&* etc.',
    rule: (value: string) => /[^\dA-Za-z]/.test(value),
  },
];

export const validatePasswordConditionSeparatly = (value: string) => {
  const results = { number: false, lowercase: false, uppercase: false, special: false, longEnough: false };

  for (const rule of passwordRules) {
    results[rule.key] = rule.rule(value);
  }

  const numberOfFullfilledConditions = Object.values(results).filter(Boolean).length;

  return {
    ...results,
    numberOfFullfilledConditions,
    allConditions: numberOfFullfilledConditions === passwordRules.length,
    anyCondition: numberOfFullfilledConditions > 0,
  };
};
