import { useDebounce } from 'common/hooks';
import { EntityList } from 'features/channels';
import { CommunityIcon, Card, Empty } from 'ui';
import { useCommunitiesInfiniteQuery } from '../../queries';

type P = {
  getPathToCommunityDetail: (id: string) => string;
  search?: string;
};

const JoinedCommunitiesList = ({ getPathToCommunityDetail, search: propertySearch }: P) => {
  const { value: search, isDebouncing } = useDebounce(propertySearch);
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useCommunitiesInfiniteQuery({
    scope: 'joined',
    pageSize: 12,
    search,
  });

  const communities = data?.pages.flat().flatMap((communitiesPaginated) => communitiesPaginated.data);

  return (
    <Card>
      <EntityList
        getPathToEntityDetail={getPathToCommunityDetail}
        empty={
          <Empty
            image={<CommunityIcon />}
            description={{
              title: 'No communities',
              subtitle: 'There are no communities to display here',
            }}
          />
        }
        entities={communities}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetching={isFetching}
        isLoading={isDebouncing || isLoading}
      />
    </Card>
  );
};

export default JoinedCommunitiesList;
