import { type RefObject, useEffect, useMemo, useState, type Dispatch, type SetStateAction, useRef } from 'react';

function useIsInViewport({
  reference,
  onEnterViewPort,
}: {
  reference: RefObject<HTMLDivElement>;
  onEnterViewPort?: Dispatch<SetStateAction<boolean>>;
}) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }),
    [],
  );

  useEffect(() => {
    if (reference.current) {
      observer.observe(reference.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [reference, observer]);

  useEffect(() => {
    if (isIntersecting && onEnterViewPort) {
      onEnterViewPort(true);
    }
  }, [isIntersecting, onEnterViewPort]);

  return isIntersecting;
}

const InViewport = ({
  onEnter,
  reference: initialReference,
}: {
  onEnter: () => unknown;
  reference?: RefObject<HTMLDivElement>;
}) => {
  const localReference = useRef<HTMLDivElement>(null);
  const reference = initialReference ?? localReference;

  useIsInViewport({
    reference,
    onEnterViewPort: onEnter,
  });

  return <div ref={reference} />;
};

export default InViewport;
