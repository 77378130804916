import * as React from 'react';
import ReactModal from 'react-modal';
import { withTranslation } from 'react-i18next';
import { Icon } from '../icon';
import { Button } from '../button';

ReactModal.setAppElement('#root');

export class Renderer extends React.Component {
  static defaultProps = {
    onSubmitButtonClick: () => true,
    showHeader: true,
    showFooter: true,
    styles: {},
  };

  state = {
    isOpen: !!this.props.isOpen,
  };

  get renderProps() {
    return { closeModal: this.closeModal, openModal: this.openModal };
  }

  // $FlowLibIssue
  triggerRef = React.createRef();

  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  componentDidMount() {
    if (this.triggerRef.current) {
      this.triggerRef.current.addEventListener('click', this.openModal);
    }
  }

  componentWillUnmount() {
    if (this.triggerRef.current) {
      this.triggerRef.current.removeEventListener('click', this.openModal);
    }
  }

  closeModal = () => {
    if (this.props.onRequestClose) {
      // $FlowExpectedError
      this.props.onRequestClose();
    }

    this.setState({ isOpen: false });
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  onSubmitButtonClick = async () => {
    const shouldClose = await this.props.onSubmitButtonClick(this.renderProps);
    if (shouldClose) {
      this.closeModal();
    }
  };

  renderHeader() {
    return (
      this.props.showHeader && (
        <div className="PbaModal__Header" id="modal_header">
          <div className="PbaModal__Title">{this.props.title}</div>
          <div onClick={this.closeModal} className="PbaModal__CloseButton" id="modal_close">
            <Icon name="close" size={18} color="#90A4AE" />
          </div>
        </div>
      )
    );
  }

  renderFooter() {
    return (
      this.props.showFooter && (
        <div className="PbaModal__Footer" id="modal_footer">
          <div className="PbaModal__FooterAccessory">{this.props.footerAccessory}</div>
          <div className="PbaModal__CloseButton" id="modal_close-wrapper">
            <Button
              size="md"
              textColor="white"
              color="brand"
              type="button"
              onClick={this.onSubmitButtonClick}
              title={this.props.t('Submit')}
              {...this.props.submitButtonProps}
            />
          </div>
        </div>
      )
    );
  }

  render() {
    const { disableScroll, trigger, renderTrigger, styles, ...modalProps } = this.props;
    const _trigger = trigger ? trigger(this.triggerRef) : renderTrigger ? renderTrigger(this.renderProps) : null;

    return (
      <React.Fragment>
        {_trigger}
        <ReactModal
          {...modalProps}
          className="PbaModal"
          isOpen={this.triggerRef.current ? this.state.isOpen : this.props.isOpen}
          onRequestClose={this.closeModal}
          style={this.props.styles}
          closeTimeoutMS={250}
        >
          <div className="PbaModal__InnerWrapper" style={styles.inner} id="modal_inner-wrapper">
            {this.renderHeader()}
            <div
              className={`PbaModal__Scrollable${disableScroll ? ' PbaModal__Scrollable__Disable' : ''}`}
              style={{ padding: this.props.padding ? this.props.padding : '20px 20px 0' }}
              id="modal_content"
            >
              {modalProps.children(this.renderProps)}
            </div>
            {this.renderFooter()}
          </div>
        </ReactModal>
      </React.Fragment>
    );
  }
}

export const Modal = withTranslation()(Renderer);
