import { type InfiniteData } from '@tanstack/react-query';

import { type ReduxUser, type Community, type ListEvent } from 'models';
import variables from 'common/styles/variables.json';
import { EventsList } from 'features/events';
import { Row, Col, Button, Empty, Skeleton, Loading, Card, List } from 'ui';

const CommunityEventsData = ({
  pageSize,
  community,
  viewer,
  hasNextPage,
  fetchNextPage,
  isLoading,
  isFetching,
  isRefetching,
  emptyDescription,
  className,
  data,
}: {
  pageSize: number;
  community: Community;
  viewer: ReduxUser;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetching: boolean;
  isRefetching: boolean;
  emptyDescription: string;
  className: string;
  data?: InfiniteData<ListEvent[]>;
}) => {
  const dataToRender = data?.pages.flat();

  return (
    <>
      {(isLoading && isFetching) || !dataToRender ? (
        <List
          grid={{ gutter: variables.spaceMd.value, column: pageSize }}
          dataSource={Array.from({ length: pageSize }).fill(1)}
          renderItem={() => (
            <List.Item>
              <Card>
                <Skeleton active paragraph={{ rows: 8 }} />
              </Card>
            </List.Item>
          )}
        />
      ) : dataToRender.length > 0 ? (
        <>
          {isRefetching ? <Loading /> : null}
          <EventsList viewer={viewer} community={community} data={dataToRender} />
          <Row>
            <Col item={{ span: 24, className }}>
              {hasNextPage ? (
                <Button
                  type="default"
                  onClick={() => {
                    fetchNextPage();
                  }}
                  loading={isFetching}
                  size="large"
                >
                  Show more
                </Button>
              ) : null}
            </Col>
          </Row>
        </>
      ) : (
        <>
          {isRefetching ? <Loading /> : null}
          <Empty description={emptyDescription} />
        </>
      )}
    </>
  );
};

export default CommunityEventsData;
