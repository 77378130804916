import { denormalize } from 'normalizr';
import deepmerge from 'deepmerge';
import update from 'immutability-helper';

const INITIAL_STATE = {
  assets: {},
  campaigns: {},
  categories: {},
  communities: {},
  content_objects: {},
  events: {},
  files: {},
  choices: {},
  kudos: {},
  notifications: {},
  organizations: {},
  polls: {},
  programs: {},
  replies: {},
  users: {},
};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const MODEL_NAME = 'entities';

export const model = {
  name: MODEL_NAME,
  state: INITIAL_STATE,
  reducers: {
    'session/reset': () => INITIAL_STATE,
    mergeEntities: (state, payload) => {
      return update(state, {
        $set: deepmerge(state, payload, {
          arrayMerge: overwriteMerge,
        }),
      });
    },
    updateEntity: (state, payload) => {
      return update(state, payload);
    },
  },
  selectors: {
    get(state, payload) {
      return denormalize(payload.input, payload.schema, state);
    },
    existEntity(state, payload) {
      return !!state[payload.entity][payload.id];
    },
  },
  effects: (dispatch) => ({}),
};
