import { FormikConsumer } from 'formik';

export function isInvalid(name, formik) {
  return !!(formik.errors[name] && formik.touched[name]);
}

export function isValid(name, formik) {
  return !isInvalid(name, formik);
}

export function FieldError(props) {
  return (
    <FormikConsumer>
      {(formik) => {
        return isInvalid(props.name, formik) ? (
          <div
            style={{
              padding: '10px 0',
              color: 'red',
              fontSize: 12,
              textAlign: 'left',
              fontFamily: 'Gilroy, sans-serif',
              fontWeight: 400,
            }}
            data-error={props.name}
          >
            {formik.errors[props.name]}
          </div>
        ) : null;
      }}
    </FormikConsumer>
  );
}
