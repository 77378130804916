import { type Profile } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompleteOnboardingTour } from '../../queries';
import { InfoStep } from '../InfoStep';

const FinishStep = ({ viewer, onFinish }: { viewer: Profile; onFinish: () => void }) => {
  const { t } = useTranslation();
  const { mutate: finishOnboardingTour, isLoading } = useCompleteOnboardingTour();

  const handleFinish = useCallback(() => {
    finishOnboardingTour(
      {},
      {
        onSuccess: onFinish,
      },
    );
  }, [finishOnboardingTour, onFinish]);

  return (
    <InfoStep
      title={t('Welcome {{firstName}}!', { firstName: viewer.firstName })}
      description="Thank you for signing up! Now you're ready to enjoy the app."
      primaryButton={{ onClick: handleFinish, children: 'Let’s go', loading: isLoading }}
    />
  );
};

export default FinishStep;
