import { transformResponse } from '../common/recoil/queries';
import { ID } from '../common/types';
import { Chatroom, Message } from '../models';
import { deleteChatroom as deleteChatroomRequest } from '../services/chatrooms/delete';
import { ChatroomDataObject } from '../services/data-objects';

const deleteChatroom = async (chatroomId: ID) => {
  const response = await deleteChatroomRequest(chatroomId);
  const chatroomData = transformResponse<ChatroomDataObject, Chatroom>(
    response,
    ({ statuses, message, ...rest }) =>
      new Chatroom({
        ...rest,
        message: message ? new Message(message) : undefined,
        statuses: [],
      }),
  );

  if (chatroomData.error) {
    return chatroomData;
  }

  return chatroomData;
};

export default deleteChatroom;
