import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { ThemeConsumer } from '../theme';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  render() {
    const { campaign } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <div
            style={{
              marginBottom: 30,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${campaign.cover_photo?.url ?? images.default.campaignCoverPhoto})`,
            }}
          >
            <div
              style={{
                background: 'rgba(0, 0, 0, 0.25)',
                padding: 25,
                height: 200,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            />
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export const CampaignCover = withTranslation()(Renderer);
