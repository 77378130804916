import { type Community, type ReduxUser } from 'models';
import { ContentWrapper } from 'ui';
import { Administrators, Members } from '../components';

type P = {
  community: Community;
  viewer: ReduxUser;
};

const CommunityMembers = ({ community, viewer }: P) => (
  <ContentWrapper aside={<Administrators community={community} viewer={viewer} />}>
    <Members community={community} viewer={viewer} />
  </ContentWrapper>
);

export default CommunityMembers;
