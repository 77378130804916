import { type ChannelDetail, getChannelDetailData } from 'models';
import { useQuery } from 'query';
import { getChannelDetail } from '../services';
import { getChannelDetailQueryKey } from '.';

export const useChannelDetailQuery = ({ channelId }: { channelId: string }) => {
  return useQuery<ChannelDetail>(getChannelDetailQueryKey({ channelId }), async () => {
    const { data } = await getChannelDetail(channelId);

    return getChannelDetailData(data);
  });
};
