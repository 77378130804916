import * as React from 'react';
import { Trans } from 'react-i18next';
import { Navigation, NavLink } from '../../atoms/navigation';

export class InviteSideNav extends React.Component {
  render() {
    return (
      <Navigation direction="vertical" compact id="invite-navigation">
        <NavLink to="/invite" id="nav-invite">
          <Trans>Invite</Trans>
        </NavLink>
      </Navigation>
    );
  }
}
