import { Radio as RadioA } from 'antd';
import { useTranslation } from 'react-i18next';
import { Row, Col } from '..';

export type Option<T> = {
  key: T;
  title: string;
  description?: string;
};

type P<T> = {
  disabled?: boolean;
  option: Option<T>;
  checked: boolean;
  onSelect: () => void;
};

function RadioOption<T>({ disabled, option, checked, onSelect }: P<T>) {
  const { t } = useTranslation();

  return (
    <Row
      item={{
        className:
          'radio-option-wrapper' +
          (checked ? ' radio-option-wrapper__active' : '') +
          (disabled ? ' radio-option-wrapper__disabled' : ''),
        onClick: disabled
          ? undefined
          : () => {
              onSelect();
            },
      }}
    >
      <Col item={{ span: 24 }}>
        <RadioA disabled={disabled} checked={checked}>
          {t(option.title)}
        </RadioA>
      </Col>
      {option.description ? (
        <Col item={{ className: 'radio-option-description', span: 24 }}>{t(option.description)}</Col>
      ) : null}
    </Row>
  );
}

export default RadioOption;
