import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';

export type CacheOption = 'invalidate' | 'reset';

export const useResetQuery = () => {
  const queryClient = useQueryClient();

  return useMutation<void, { cacheResetOption: CacheOption }>(async ({ cacheResetOption }) => {
    switch (cacheResetOption) {
      case 'invalidate':
        return queryClient.invalidateQueries();
      case 'reset':
        return queryClient.resetQueries();
    }
  });
};
