import { LoadingOutlined } from '@ant-design/icons';
import { Link, type LinkProps } from 'react-router-dom';
import { PillBadge } from 'ui';
import { useOverallPointsQuery } from './queries/useOverallPointsQuery';

type P = Omit<LinkProps, 'to'> & {
  userId: string;
};

const OverallPoints = ({ userId, ...rest }: P) => {
  const { isLoading, data } = useOverallPointsQuery(userId);
  const userPoints = data?.points ?? null;

  return (
    <Link
      to="/leaderboard"
      onClick={(event) => {
        event.stopPropagation();
      }}
      {...rest}
    >
      <PillBadge type="overall">{isLoading ? <LoadingOutlined /> : userPoints}</PillBadge>
    </Link>
  );
};

export default OverallPoints;
