import { ChangeRequest } from 'models';
import { useQuery } from 'query';
import { getChangeRequest } from '../services/changeRequests';
import { getChangeRequestsQueryKey } from '.';

export const useChangeRequestsQuery = ({
  changeRequestId,
  invitationToken,
}: {
  changeRequestId?: string;
  invitationToken?: string;
}) =>
  useQuery<ChangeRequest>(
    getChangeRequestsQueryKey(changeRequestId!),
    async () => {
      const response = await getChangeRequest({
        changeRequestId: changeRequestId!,
        invitationToken: invitationToken!,
      });

      return new ChangeRequest(response.data);
    },
    {
      enabled: changeRequestId !== undefined && invitationToken !== undefined,
    },
  );
