import { Badge as BadgeA } from 'antd';
import cn from 'classnames';
import { type ComponentProps } from 'react';

type P = ComponentProps<typeof BadgeA>;

const Badge = ({ className, ...rest }: P) => {
  return <BadgeA {...rest} className={cn(className, 'badge-default')} />;
};

export default Badge;
