import { useQuery } from 'query';
import { type EducationLevel } from 'models/educationsLevel';
import { getEducationsLevel } from '../services';
import { getEducationLevelsQueryKey } from '.';

export const useEducationsLevel = () =>
  useQuery<EducationLevel[]>(getEducationLevelsQueryKey(), async () => {
    const { data } = await getEducationsLevel();
    return data;
  });
