import * as React from 'react';
import { Radio } from '../form/base-atoms/Radio';
import { NavLink } from './NavLink';

export class NavLinkRadio extends React.Component {
  static defaultProps = {
    isActive: false,
  };

  render() {
    return (
      <NavLink {...this.props}>
        <span style={{ paddingRight: 10 }}>
          <Radio checked={this.props.isActive} onChange={(e) => {}} />
        </span>
        {this.props.children}
      </NavLink>
    );
  }
}
