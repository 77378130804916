import * as React from 'react';

import { ThemeConsumer } from '../../atoms/theme';

export function RsvpButton(props) {
  return (
    <ThemeConsumer>
      {(theme) => {
        const BUTTON_COLOR = '#595959';
        const variantCls = {
          going: {
            backgroundColor: theme.color.green,
            borderColor: theme.color.green,
            color: '#ffffff',
          },
          not_going: {
            backgroundColor: theme.color.red,
            borderColor: theme.color.red,
            color: '#ffffff',
          },
          pending: {
            borderColor: BUTTON_COLOR,
            color: BUTTON_COLOR,
          },
        }[props.variant];

        return (
          <button
            onClick={props.onClick}
            className={`widgets-rsvp-button ${props.disabled ? 'widgets-rsvp-button--disabled' : ''} ${
              props.className
            }`}
            style={{ ...variantCls, ...props.style }}
            disabled={props.disabled}
            data-test-id={props['data-test-id']}
            id={props.id}
          >
            {props.children}
          </button>
        );
      }}
    </ThemeConsumer>
  );
}

RsvpButton.defaultProps = {
  disabled: false,
};
