import { isDexcomEnabled } from 'common/validators';
import { Title, type Option, RadioGroup } from 'ui';

import { type ActivityOption } from './ActivityTypes';

type P = {
  disabledEdit: boolean;
  selected: ActivityOption;
  isWithingsEnabled: boolean;
  isDexcomEnabledProgram: boolean;
  onSelect: (type: ActivityOption) => void;
};

const ActivityFormStep2 = ({ disabledEdit, selected, isWithingsEnabled, onSelect, isDexcomEnabledProgram }: P) => {
  const options: Array<Option<ActivityOption>> = [
    {
      key: 'extended-feedback',
      title: 'Get extended feedback or response from the user',
      description: '(e.g.: share your personal feedback to the webinar)',
    },
    {
      key: 'numeric-feedback',
      title: 'Get numeric value from the user',
      description: '(e.g.: record your blood sugar value)',
    },
    {
      key: 'select-feedback',
      title: 'Physical activity',
      description: '(e.g.: select which exercise you did and for how long)',
    },
  ];

  if (isWithingsEnabled) {
    options.push({
      key: 'withings-blood-pressure',
      title: 'Withings blood pressure',
    });
  }

  if (isDexcomEnabledProgram && isDexcomEnabled()) {
    options.push({
      key: 'dexcom-glucose',
      title: 'Dexcom glucose',
    });
  }

  return (
    <div className="activity-form-step-2">
      <Title level={3}>Select type of activity</Title>
      <RadioGroup<ActivityOption> disabled={disabledEdit} selected={selected} onSelect={onSelect} options={options} />
    </div>
  );
};

export default ActivityFormStep2;
