import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const AdminService = {
  acceptCommunityRequest: (communityId, memberId) => http.post(`/communities/${communityId}/accept/${memberId}`),
  declineCommunityRequest: (communityId, memberId) => http.post(`/communities/${communityId}/decline/${memberId}`),
  getOrganizations: () => http.get(`admins/organizations`),
  getCampaigns: (queryParams) => http.get(`admins/campaigns`, queryParams),
  getCourses: (queryParams) => http.get(`admins/programs`, queryParams),
  getCommunities: (organizationId) => http.get(`admins/communities`, { organization_id: organizationId }),
  getMembers: (params) => http.get('/admins/members', params),
  getRequests: (params) => http.get('/admins/requests', params),
};
