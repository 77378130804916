import * as React from 'react';
import { select } from '@rematch/select';
import { connect } from 'react-redux';

import { ContentObjectRenderer } from '../../atoms/feed-object';
import { Modal } from 'ui';

class Renderer extends React.Component {
  render() {
    const { object, actions } = this.props;
    return (
      <Modal
        isOpened={this.props.isOpen}
        onCancel={this.props.onRequestClose}
        loading={!Boolean(object)}
        disableBack
        title=""
        destroyOnClose
      >
        <ContentObjectRenderer
          item={object}
          onCreateComment={actions.createComment}
          onDeleteObject={async (item) => {
            await actions.deleteObject(item);
            this.props.onRequestClose();
          }}
          onEditObject={actions.updateObject}
          onInitiatePostEditing={(item) => {
            this.props.onInitiatePostEditing(item);
            this.props.onRequestClose();
          }}
          onLikeObject={actions.likeObject}
          onReportObject={actions.reportObject}
          onSortChange={actions.sortChange}
          onBookmarkObject={actions.bookmarkObject}
          viewer={this.props.viewer}
        />
      </Modal>
    );
  }
}

const mapState = (state, props) => {
  return {
    object: select.feed.findById(state, props.objectId),
  };
};

export const ModalPostDetail = connect(mapState)(Renderer);
