import { Entity, Event, Program, PublicEvent } from 'models';
import moment, { type Moment } from 'moment';
import {
  type PublicEventContentObjectDataObject,
  type ContentObjectDataObject,
  type EventContentObjectDataObject,
  type PostInCommunityDataObject,
} from './service';

export interface Social {
  id: string;
  name: string;
}

export class ContentObject {
  id: string;
  publishAt: Moment;
  postInCommunities: Entity[];
  programTopicId?: string; // TODO change module id to Social, as Object will be shareable in multiple modules
  program?: Program;

  constructor(data: ContentObjectDataObject) {
    this.id = data.id;
    this.publishAt = moment(data.publish_at);
    this.programTopicId = data.programs_topic?.id;
    this.program = new Program(data.program);
    this.postInCommunities = data.post_in_communities.map(
      (community: PostInCommunityDataObject) => new Entity(community),
    );
  }
}

export class PublicEventContentObject extends ContentObject {
  event: PublicEvent;

  constructor(data: PublicEventContentObjectDataObject) {
    super(data);
    this.event = new PublicEvent(data.event);
  }
}

export class EventContentObject extends ContentObject {
  event: Event;

  constructor(data: EventContentObjectDataObject) {
    super(data);
    this.event = new Event(data.event);
  }
}
