import { Component } from 'react';
import { Trans } from 'react-i18next';
import { Navigation, NavLink } from '../../atoms/navigation';

export const TABS = {
  notifications: 'profile-settings-notifications-tab',
  translations: 'profile-settings-translations-tab',
};

export class SettingsSideNav extends Component {
  render() {
    return (
      <Navigation direction="vertical" compact id="settings-navigation">
        <NavLink to="/settings/notifications" id="nav-notifications" controls={TABS.notifications}>
          <Trans>Notifications</Trans>
        </NavLink>
        <NavLink to="/settings/translations" id="nav-translations" controls={TABS.translations}>
          <Trans>Translations</Trans>
        </NavLink>
      </Navigation>
    );
  }
}
