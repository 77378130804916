import { routes } from 'routes';
import { useDebounce } from 'common/hooks';
import { EntityList } from 'features/channels';
import { Card, Empty, ProgramIcon, Link } from 'ui';
import { useProgramsInfiniteQuery } from '../../queries';
import { type ProgramsScope, type ProgramStatus } from '../../types';

type P = {
  getPathToProgramDetail: (id: string) => string;
  scope: ProgramsScope;
  search?: string;
  status?: ProgramStatus;
};

const JoinedProgramsList = ({ getPathToProgramDetail, search: propertySearch, scope, status }: P) => {
  const { value: search, isDebouncing } = useDebounce(propertySearch);
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useProgramsInfiniteQuery({
    scope,
    pageSize: 12,
    status,
    search,
  });

  const programs = data?.pages.flat().flatMap((programsPaginated) => programsPaginated.data);

  const filteredView = Boolean(status && ['draft', 'finished'].includes(status)) || Boolean(search);

  return (
    <Card>
      <EntityList
        getPathToEntityDetail={getPathToProgramDetail}
        empty={
          <Empty
            image={<ProgramIcon />}
            description={{
              title: filteredView ? 'No programs' : 'You haven’t registered for any program yet',
              subtitle: filteredView
                ? 'There are no programs to display here'
                : 'Discover new content that is right for you in our Channels',
            }}
          >
            {filteredView ? null : <Link to={routes.channels.index}>Go to channels</Link>}
          </Empty>
        }
        entities={programs}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetching={isFetching}
        isLoading={isDebouncing || isLoading}
      />
    </Card>
  );
};

export default JoinedProgramsList;
