import { useTranslation } from 'react-i18next';

import { Modal, ModalFormActions, Section, Text } from 'ui';

type P = {
  type?: 'isDraftDelete' | 'isDraftEdit';
  closeModal: () => void;
};

const ChangeEventDuringApprovalModal = ({ type, closeModal }: P) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpened={type !== undefined}
      title={type === 'isDraftDelete' ? 'Delete Event' : 'Change Event'}
      onCancel={closeModal}
      width="small"
      disableBack
      closable
    >
      <Section className="change-draft-modal-content">
        <Text strong>
          {t(
            type === 'isDraftDelete'
              ? 'Event cannot be deleted during approval process'
              : 'Event cannot be changed during approval process',
          )}
        </Text>
      </Section>
      <ModalFormActions submit={{ children: 'I understand', onClick: closeModal }} />
    </Modal>
  );
};

export default ChangeEventDuringApprovalModal;
