import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { AutocompleteService } from '../../../services/AutocompleteService';
import { Autocomplete } from '../../autocomplete';
import { TagsBox } from '../../tags';

export class Renderer extends React.Component {
  itemToString = (item) => {
    return item === null ? '' : item.name;
  };

  getDataForValue = async (value) => {
    const response = await AutocompleteService.getOrganizations({
      scope: this.props.scope,
      search: value,
    });

    const selectionIds = this.props.selection.map((item) => item.id);
    return response.data.filter((item) => !selectionIds.includes(item.id));
  };

  handleChange = (item) => {
    if (Array.isArray(item)) {
      this.props.onChange(item);
    } else if (item !== null) {
      this.props.onChange(this.props.selection.concat(item));
    }
  };

  render() {
    return (
      <Autocomplete
        clearOnSelect
        currentSelectedData={this.props.currentSelectedData}
        dataForValue={this.getDataForValue}
        itemToString={this.itemToString}
        getRowId={(item) => item.id}
        placeholder={this.props.placeholder}
        onSelect={this.handleChange}
        renderInput={(ref, inputProps) => (
          <div ref={ref}>
            <TagsBox
              inputProps={inputProps}
              placeholder={this.props.t('Post in...')}
              selection={this.props.selection}
              itemToString={this.itemToString}
              onChange={this.handleChange}
              id={this.props?.id}
            />
          </div>
        )}
      />
    );
  }
}

export const OrganizationsInput = withTranslation()(Renderer);
