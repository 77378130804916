import { useQueryClient } from '@tanstack/react-query';
import { getProgramsInfiniteQueryKey, getProgramsQueryKey } from '..';

export const useInvalidatePrograms = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(getProgramsInfiniteQueryKey({}));
    queryClient.invalidateQueries(getProgramsQueryKey({}));
  };
};
