import { type ReduxUser, type InvitationToken, type PublicEventContentObject } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Button, Col, Form, Row, TextInput } from 'ui';
import { useEnterEvent } from '../../../../queries';

type FormValues = {
  firstName: string;
  lastName: string;
  email?: string;
};

type P = {
  invitationToken: InvitationToken;
  eventContentObject: PublicEventContentObject;
  viewer?: ReduxUser;
};

const SharedEventForm = ({ invitationToken, eventContentObject, viewer }: P) => {
  const { t } = useTranslation();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [form] = Form.useForm<FormValues>();

  const { mutate: enterEvent, isLoading: isEnteringEvent } = useEnterEvent();

  const onFinish = (values: FormValues) => {
    enterEvent(
      { eventId: eventContentObject.event.id, invitationToken: invitationToken.token, ...values },
      {
        onSuccess() {
          setIsRedirecting(true);
        },
      },
    );
  };

  return (
    <Form<FormValues>
      form={form}
      onFinish={onFinish}
      initialValues={{ firstName: viewer?.first_name, lastName: viewer?.last_name, email: viewer?.email }}
    >
      <Row item={{ gutter: [variables.spaceMd.value, 0] }}>
        <Col item={{ span: 12 }}>
          <TextInput
            item={{
              name: 'firstName',
              label: 'First name',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 12 }}>
          <TextInput
            item={{
              name: 'lastName',
              label: 'Last name',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <TextInput
            item={{
              name: 'email',
              label: 'Email',
              rules: [
                {
                  type: 'email',
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <Button block type="primary" htmlType="submit" loading={isEnteringEvent || isRedirecting}>
            Join Event
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SharedEventForm;
