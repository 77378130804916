import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ConsentDataObject } from 'models/service';

type RequestData = {
  consent: {
    consent_ids: string[];
  };
};

export const acceptConsents = async (data: RequestData) => {
  return apiClient.post<ConsentDataObject[], ApiClientResponse<ConsentDataObject[]>>(routes.consents.accept, data);
};
