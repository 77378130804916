export const getPriceProperties = (course) => {
  const totalPrice = parseFloat(course.price) || 0;
  const paidBySponsors = course.sponsors?.reduce((prev, curr) => prev + (parseFloat(curr.price) || 0), 0) ?? 0;
  const missingToPay = Math.max(totalPrice - paidBySponsors, 0);

  return {
    totalPrice,
    paidBySponsors,
    missingToPay,
    isFree: course.price_option === 'free',
    isContactUs: course.price_option === 'contact_us',
  };
};

export const getSponsorValue = (totalPrice, paidPrice) => {
  totalPrice = parseFloat(totalPrice) || 0;
  paidPrice = parseFloat(paidPrice) || 0;

  if (totalPrice > 0) {
    return `${Math.round((paidPrice / totalPrice) * 100).toFixed(2)}%`;
  }

  return `$${paidPrice}`;
};
