import * as React from 'react';
import {
  addDays,
  addMonths,
  addYears,
  isAfter,
  isBefore,
  isSameMonth,
  isSameYear,
  setMonth,
  setYear,
  min,
  max,
  isDate,
  parseISO,
} from 'date-fns';
import i18n from 'i18next';

import { Select } from '../base-atoms/Select';

function add(date, range) {
  const fn = {
    year: addYears,
    month: addMonths,
    day: addDays,
  }[range[1]];

  return fn(date, range[0]);
}

function sub(date, range) {
  return add(date, [range[0] * -1, range[1]]);
}

function isRealyBeforeMonth(date, dateToCompare) {
  return isBefore(date, dateToCompare) && isSameMonth(date, dateToCompare) === false;
}

function isRealyAfterMonth(date, dateToCompare) {
  return isAfter(date, dateToCompare) && isSameMonth(date, dateToCompare) === false;
}

function isRealyBeforeYear(date, dateToCompare) {
  return isBefore(date, dateToCompare) && isSameYear(date, dateToCompare) === false;
}

function isRealyAfterYear(date, dateToCompare) {
  return isAfter(date, dateToCompare) && isSameYear(date, dateToCompare) === false;
}

function makeYearOptions(start, end, currentDate) {
  const options = [];

  const currentDateDate = isDate(currentDate) ? currentDate : parseISO(currentDate);
  for (let i = min([start, currentDateDate]).getFullYear(); i <= max([end, currentDateDate]).getFullYear(); i += 1) {
    const then = setYear(currentDateDate, i);
    options.push(
      <option
        key={i}
        value={i}
        children={i}
        disabled={isRealyBeforeYear(then, start) || isRealyAfterYear(then, end)}
      />,
    );
  }
  return options;
}

function makeMonthOptions(start, end, currentDate) {
  const options = [];

  for (let i = 0; i < 12; i += 1) {
    const then = setMonth(currentDate, i);
    options.push(
      <option key={i} value={i} disabled={isRealyBeforeMonth(then, start) || isRealyAfterMonth(then, end)}>
        {i18n.t('dateFormatMonth', { date: new Date(then) })}
      </option>,
    );
  }
  return options;
}

export class YearMonthSelector extends React.Component {
  handleMonthChange = (e) => {
    this.handleChange(setMonth(this.props.date, parseInt(e.target.value, 10)));
  };

  handleYearChange = (e) => {
    this.handleChange(setYear(this.props.date, parseInt(e.target.value, 10)));
  };

  handleChange = (date) => {
    const start = sub(new Date(), this.props.range.before);
    const end = add(new Date(), this.props.range.after);
    const sanitizedDate = isRealyBeforeMonth(date, start) ? start : isRealyAfterMonth(date, end) ? end : date;
    this.props.onChange(sanitizedDate);
  };

  render() {
    const start = sub(new Date(), this.props.range.before);
    const end = add(new Date(), this.props.range.after);

    return (
      <div style={{ display: 'table-caption', marginBottom: 15 }}>
        <form>
          <div style={{ display: 'flex' }}>
            <Select
              name="month"
              onChange={this.handleMonthChange}
              value={this.props.date.getMonth().toString()}
              id={'month'}
            >
              {makeMonthOptions(start, end, this.props.date)}
            </Select>
            <div style={{ width: 20 }} />
            <div style={{ maxWidth: 80, width: '100%' }}>
              <Select
                name="year"
                onChange={this.handleYearChange}
                value={this.props.date.getFullYear().toString()}
                id={'year'}
              >
                {makeYearOptions(start, end, this.props.selectedDate || this.props.date)}
              </Select>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
