import * as React from 'react';
import { Trans } from 'react-i18next';
import { Row, Col, Checkbox, Popover, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { Box } from '../box';
import { BigLightContent, BigContent, BigBrandContent } from '../text';
import { Loader } from '../loader';
import { CourseTopicPost } from './CourseTopicPost';
import { ThemeConsumer } from '../theme';
import { withTranslation } from 'react-i18next';
import { Text } from '../text';
import { can } from '../../utils/permissions';
import { Select } from 'ui';

export class Renderer extends React.Component {
  state = {
    visible: false,
  };

  filterOptions = [
    { label: this.props.t('Activities'), value: 'activity' },
    { label: this.props.t('Events'), value: 'event' },
    { label: this.props.t('Polls'), value: 'poll' },
    { label: this.props.t('Posts'), value: 'post' },
    { label: this.props.t('Withings order'), value: 'withings_order_post' },
  ];

  sortOptions = [
    { label: this.props.t('Date created'), value: 'date_created' },
    { label: this.props.t('Alphabetically'), value: 'name' },
    { label: this.props.t('Content type'), value: 'type' },
  ];

  componentDidMount = () => {
    this.setState({ posts: this.props.posts });
  };

  onSelectPost = (post, index) => {
    this.props.onSelectPost(this.props.topic, post, index);
  };

  getTypeForIndex(index) {
    if (index === this.props.selectedIndex) {
      return 'selected';
    }

    if (index < this.props.selectedIndex) {
      return 'checked';
    }

    return 'unchecked';
  }

  onChangeSort = async (value) => {
    await this.props.filterPosts({ sort: value });
  };

  onChangeFilter = async (value) => {
    await this.props.setFilter(value);
  };

  filterCheckboxes = () => {
    return (
      <>
        <Checkbox.Group
          options={this.filterOptions}
          defaultValue={this.props.filter.type}
          value={this.props.filter.type}
          onChange={this.onChangeFilter}
          className="modules-filter__checkboxes"
        />
      </>
    );
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  renderContent = () => {
    const { posts, loading } = this.props;

    if (loading) {
      return <Loader size={20} />;
    }

    if (!posts || !posts.length) {
      return (
        <BigBrandContent id="course_topic-no-content">
          <Trans>No content yet</Trans>
        </BigBrandContent>
      );
    }

    return (
      <>
        {posts.map((post, index) => (
          <Box className="list-item__content__clickable" onClick={() => this.onSelectPost(post, index)} key={index}>
            <CourseTopicPost post={post} last={index === posts.length - 1} type={this.getTypeForIndex(index)} />
          </Box>
        ))}
      </>
    );
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <>
            <Box className="list-separator" />
            <Box
              onClick={() => this.props.onSelectTopic(this.props.index, 0)}
              className={`list-item list-item__header`}
              style={{ flexDirection: 'row', background: `${this.props.selected ? theme.color.brand + '1A' : ''}` }}
            >
              <div className="list-item__index">
                <BigLightContent>{this.props.index + 1}</BigLightContent>
              </div>
              <div className="list-item__value">
                {this.props.selected ? (
                  <BigBrandContent>{this.props.topic.name}</BigBrandContent>
                ) : (
                  <BigContent>{this.props.topic.name}</BigContent>
                )}
              </div>
            </Box>
            {can(this.props.course, 'Course', 'seeFuturePosts') ? (
              <Row className={`modules-filter ${this.props.selected ? 'modules-filter--selected' : ''}`}>
                <Col span={12} className="modules-filter__sort">
                  <Text component="div" size={12} color="#5a6578" weight="bold" style={{ marginBottom: 8 }}>
                    <Trans>Sort By</Trans>
                  </Text>
                  <Select
                    placeholder={this.props.t('Default')}
                    style={{ width: '100%' }}
                    onChange={this.onChangeSort}
                    value={this.props.filter.sort}
                    options={this.sortOptions}
                  />
                </Col>
                <Col span={12} className="modules-filter__filter">
                  <Text component="div" size={12} color="#5a6578" weight="bold" style={{ marginBottom: 8 }}>
                    <Trans>Filter by category</Trans>
                  </Text>
                  <Popover
                    placement="bottom"
                    content={this.filterCheckboxes}
                    trigger="click"
                    onVisibleChange={this.handleVisibleChange}
                    visible={this.state.visible}
                  >
                    <Button>
                      <DownOutlined />
                    </Button>
                  </Popover>
                </Col>
              </Row>
            ) : null}
            {this.props.selected ? (
              <div
                className={`${this.props.className} list-item__content`}
                id={`course_topic-content[${this.props.index}]`}
              >
                {this.renderContent()}
              </div>
            ) : null}
          </>
        )}
      </ThemeConsumer>
    );
  }
}

export const CourseTopic = withTranslation()(Renderer);
