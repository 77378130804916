import { type MetaItemTranslations } from 'models';
import { type EntityDataObject } from 'models/service';
import apiClient, { type ApiClientResponse } from './api';
import { routes } from './routes';

export type ChangeRequestData = {
  subject_id: string;
};

type ContactUsRequestData = ChangeRequestData & {
  subject_type: 'Program';
  kind: 'program_request_to_contact';
  meta: {
    invitation_phone: string;
  };
};

type CommunityJoinRequestData = ChangeRequestData & {
  subject_type: 'Community';
  kind: 'community_request_to_join';
  meta: {
    reason_to_join?: string;
  };
};

type ProgramJoinRequestData = ChangeRequestData & {
  subject_type: 'Program';
  kind: 'program_request_to_join';
  meta: {
    reason_to_join?: string;
  };
};

type TelehealthTypeRequestData = {
  name: string;
  translations: MetaItemTranslations;
};

type TelehealthRequestData = ChangeRequestData & {
  subject_type: 'Community';
  kind: 'event_request_to_create';
  meta: {
    patient_type: TelehealthTypeRequestData;
    visit_type: TelehealthTypeRequestData;
    visit_type_option: TelehealthTypeRequestData;
    visit_type_sub_option?: TelehealthTypeRequestData;
    visit_duration: number;
    visit_date: Date;
    text_content: string;
    html_content: string;
  };
};

export const changeRequest = async (
  data: CommunityJoinRequestData | ContactUsRequestData | ProgramJoinRequestData | TelehealthRequestData,
) => apiClient.post<EntityDataObject, ApiClientResponse<EntityDataObject>>(routes.changeRequests.post, data);
