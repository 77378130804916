import { Rate as RateAD } from 'antd';
import type React from 'react';

type P = React.ComponentProps<typeof RateAD>;

const Rate = ({ ...rest }: P) => {
  return <RateAD {...rest} />;
};

export default Rate;
