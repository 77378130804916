import { Trans } from 'react-i18next';
import { Navigation, NavLink } from '../atoms/navigation';
import { Card } from 'ui';
import { withPermissions } from '../atoms/auth';

export function CampaignsTopNav(props) {
  const campaignsCount = props.campaignsByOrganization.reduce((prev, curr) => {
    return prev + curr.collection.length;
  }, 0);

  return (
    <Card shrinked>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Navigation direction="horizontal" id="campaigns-tabs">
          <NavLink to="/campaigns/listing" id={'nav-current-campaigns'}>
            <Trans>Current Campaigns</Trans>
            {` (${campaignsCount})`}
          </NavLink>
          <NavLink to="/campaigns/payment-history" id={'nav-my-donations'}>
            <Trans>My Donations</Trans>
          </NavLink>
          {withPermissions(
            [
              { scope: 'superadmin' },
              { scope: 'manage-organization', subj: props.user.admin_organizations },
              { scope: 'manage-community', subj: props.user.admin_communities },
            ],
            [
              <NavLink to="/admin/campaigns" id={'nav-dashboard'}>
                <Trans>Dashboard</Trans>
              </NavLink>,
            ],
            null,
          )}
        </Navigation>
      </div>
    </Card>
  );
}
