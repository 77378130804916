import * as React from 'react';

import { CommunityMemberRow } from './CommunityMemberRow';
import { EventMemberRow } from './EventMemberRow';
import { Table } from '../../atoms/table';
import { ThemeConsumer } from '../../atoms/theme';

export class MembersList extends React.Component {
  static defaultProps = {
    type: 'community',
  };

  render() {
    const { type, members, viewer, onDropdownSelect, showCommunities, showActions, onClickToMember } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <React.Fragment>
            <Table>
              {members.map((member) =>
                type === 'community' ? (
                  <CommunityMemberRow
                    key={member.id}
                    member={member}
                    onDropdownSelect={onDropdownSelect}
                    showCommunities={showCommunities}
                    showActions={showActions}
                  />
                ) : (
                  <EventMemberRow
                    key={member.id}
                    viewer={viewer}
                    member={member}
                    onClickToMember={onClickToMember}
                    onDropdownSelect={onDropdownSelect}
                    showActions={showActions}
                  />
                ),
              )}
            </Table>
          </React.Fragment>
        )}
      </ThemeConsumer>
    );
  }
}
