import { AxiosResponse } from 'axios';

export type Error = 'bad-request' | 'forbidden' | 'not-found' | 'unauthorized' | 'unknown';

export type SuccessState<T> = {
  result: T;
  error: null;
};

export type ErrorState = { error: Error; result: null };

export type DataState<T> = ErrorState | SuccessState<T>;

export const transformError = (error: unknown) => {
  return { result: null, error } as ErrorState;
};

export const transformResponse = <Response, Result>(
  response: AxiosResponse<Response>,
  transform: (data: Response) => Result,
): SuccessState<Result> => {
  return { result: transform(response.data), error: null };
};

export const processError = (error: Error) => {
  // TODO here was page
  // TODO replace return of 'null' with '<Navigate to="/" />' when 'react-router-dom' is updated
  switch (error) {
    case 'bad-request':
    case 'unknown':
    case 'forbidden':
    case 'not-found':
    case 'unauthorized':
    default:
      return null;
  }
};

export { getProfile } from './getProfile';
export { findPractice as getPractice } from './findPractice';
