import { init } from '@rematch/core';
import { getPersistor } from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import createRematchPersist from '@rematch/persist';
import selectorsPlugin from '@rematch/select';
import { model as admin } from '../models/admin';
import { model as adminOrganizations } from '../models/adminOrganizations';
import { model as adminCommunities } from '../models/adminCommunities';
import { model as adminCourses } from '../models/adminCourses';
import { model as adminAppConfigs } from '../models/adminAppConfigs';
import { model as campaigns } from '../models/campaigns';
import { model as categories } from '../models/categories';
import { model as communities } from '../models/communities';
import { model as courses } from '../models/courses';
import { model as donations } from '../models/donations';
import { model as events } from '../models/events';
import { model as feed } from '../models/feed';
import { model as globalSearch } from '../models/globalSearch';
import { model as notifications } from '../models/notifications';
import { model as organizations } from '../models/organizations';
import { model as session } from '../models/session';
import { model as entities } from '../models/entities';
import { model as poll } from '../models/poll';

export const store = init({
  models: {
    admin,
    adminCommunities,
    adminCourses,
    adminOrganizations,
    adminAppConfigs,
    campaigns,
    categories,
    communities,
    courses,
    donations,
    entities,
    events,
    feed,
    globalSearch,
    notifications,
    organizations,
    session,
    poll,
  },
  plugins: [
    selectorsPlugin(),
    createLoadingPlugin(),
    createRematchPersist({
      whitelist: ['session', 'entities'],
      version: 1,
    }),
  ],
  redux: {
    rootReducers: {
      RESET_APP: (state, action) => ({
        session: { ...state.session, authenticated: true, user: action.data.user },
        entities: action.data.entities,
      }),
    },
  },
});

export const persistor = getPersistor();
export const { getState, dispatch } = store;
