import * as React from 'react';
import { Icon } from './Icon';

export class CircleIcon extends React.Component {
  render() {
    const { size, background, className, style, ...iconProps } = this.props;

    return (
      <i
        className={className}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          height: size,
          background: background || '#dfe5e7',
          borderRadius: size / 2,
          ...style,
        }}
      >
        <Icon {...iconProps} size={size * 0.6} />
      </i>
    );
  }
}
