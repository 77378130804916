import { Upload } from 'antd';
import { type UploadProps, type UploadFile } from 'antd/es/upload/interface';
import variables from 'common/styles/variables.json';
import { message, UploadIcon, Text, Row, Col } from 'ui';
import UploadFileList from './UploadFileList';

// TODO: import from ui
const { Dragger } = Upload;

type P = {
  fileList?: UploadFile[];
  startUpload: ({ file }: { file: File }) => void;
  removeUpload: () => void;
  retryUpload: () => void;
};

const VideoDragger = ({ fileList, startUpload, removeUpload, retryUpload }: P) => {
  const customRequest: UploadProps['customRequest'] = ({ file }) => {
    if (file instanceof File) {
      startUpload({ file });
    } else {
      message.error('Uploaded item is not a File object');
    }
  };

  const uploadProperties = {
    customRequest,
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    fileList,
    accept: 'video/*',
  };

  return (
    <Row>
      <Col item={{ span: 24 }}>
        <Dragger {...uploadProperties}>
          <Row>
            <Col item={{ span: 24 }}>
              <UploadIcon width={36} height={36} color={variables.brandColor.value} />
            </Col>
            <Col item={{ span: 24 }}>
              <Text>Click or drag file to this area to upload</Text>
            </Col>
            <Col item={{ span: 24 }}>
              <Text size="small">Support for a single file only</Text>
            </Col>
          </Row>
        </Dragger>
      </Col>

      <Col item={{ span: 24 }}>
        {Array.isArray(uploadProperties.fileList) && uploadProperties.fileList.length > 0 ? (
          <div className="ant-upload-list ant-upload-list-text">
            <UploadFileList file={uploadProperties.fileList[0]} removeUpload={removeUpload} retryUpload={retryUpload} />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default VideoDragger;
