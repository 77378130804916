import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const EventService = {
  get: (params) => http.get(`/events`, params),
  find: (id) => http.get(`/events/${id}`),
  calendar: (id) =>
    http.get(
      `/events/${id}/calendar`,
      {},
      {
        headers: {
          Accept: 'text/calendar',
        },
      },
    ),
  delete: (id) => http.delete(`/events/${id}`),
  update: (id, data) => {
    return http.put(`/events/${id}`, { event: data });
  },
  setStatus: (id, rsvp) => http.post(`/events/${id}/connect`, { rsvp }),
  enterConference: (eventId, memberIds, email, exit_url) =>
    http.post(`/calls/${eventId}/enter`, {
      user_ids: memberIds,
      email: email,
      exit_uri: exit_url,
    }),
  create: (data) => http.post('/events', { event: data }),
  getInvitees: (id, params) => http.get(`/events/${id}/users`, params),
};
