import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import Chat from './src/Chat';
import { localStorageTokenKey } from './src/common/constants';
import { Profile, User } from './src/common/models';
import { DataState } from './src/common/recoil/queries';
import { chatroomsState } from './src/recoil/selectors';
import { ID } from './src/common/types';

type P = {
  className?: string;
  currentUserProfileData: DataState<Profile>;
  nodeApiToken: string;
  isLoading: boolean;
  updateTotalUnreadCount?: (difference?: number) => void;
  defaultNewChatroomUser?: User;
  chatroomIdToOpen?: ID;
  handleChatState: (chatStateToApply: ChatroomState, id?: ID) => void;
  currentChatState: ChatroomState;
};

export { SocketConnection } from './src/common/sockets';
export { useSocketIO } from 'chat-web/src/common/sockets';
export { routes, events } from './src/common/services';
export { User, Profile } from './src/common/models';
export { Message } from './src/models';

export type { Login, Logout } from './src/common/types';
export type { UserDataObject } from './src/common/services/user';
export type { DataState } from './src/common/recoil/queries';

export type ChatroomState = 'creating' | 'default' | 'chatroom';

export const chatLogout = () => localStorage.removeItem(localStorageTokenKey);

const ChatComponent = ({
  className,
  currentUserProfileData,
  nodeApiToken,
  isLoading,
  updateTotalUnreadCount,
  defaultNewChatroomUser,
  chatroomIdToOpen,
  handleChatState,
  currentChatState,
}: P) => {
  const reloadChatrooms = useResetRecoilState(chatroomsState);

  useEffect(() => {
    reloadChatrooms();
  }, [reloadChatrooms]);

  localStorage.setItem(localStorageTokenKey, nodeApiToken);

  return (
    <Chat
      className={className}
      currentUserProfileData={currentUserProfileData}
      isLoading={isLoading}
      updateTotalUnreadCount={updateTotalUnreadCount}
      defaultNewChatroomUser={defaultNewChatroomUser}
      chatroomIdToOpen={chatroomIdToOpen}
      handleChatState={handleChatState}
      currentChatState={currentChatState}
    />
  );
};

export default ChatComponent;
