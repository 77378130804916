import { denormalize, normalize } from 'normalizr';
import { AuthService } from '../services/AuthService';
import { CalendarService } from '../services/CalendarService';
import { dispatch, getState } from '../store';
import { UserService } from '../services/UserService';
import { UserSchema } from './schemas';
import { clearTokens, getAccessToken, nodeApiLogin, nodeApiLogout, storeTokensData } from 'common/services';
import { clearNodeToken, storeNodeToken } from 'features/auth/services/nodeApi';
import { getChatroomStats } from 'features/onboarding/services';
import { chatLogout } from 'chat-web';
import { getCurrentUserTimezone } from 'common/utils/timezones';
import { queryClient } from 'query';

function normalizeUserDataWithResult(data) {
  return normalize(data, Array.isArray(data) ? [UserSchema] : UserSchema);
}

function normalizeUserData(data) {
  const normalizedData = normalizeUserDataWithResult(data);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

const INITIAL_STATE = {
  authenticated: false,
  oauthResponse: null,
  user: null,
  nodeAPIUser: null,
  provider: null,
  unreadMessages: 0,
};

const updateApiTokens = (payload) => {
  if (payload) {
    storeTokensData(payload);
  } else {
    clearTokens();
  }
};

export const model = {
  state: INITIAL_STATE,

  reducers: {
    setAuthenticationStatus(state, payload) {
      return { ...state, authenticated: payload };
    },
    setOauthResponse(state, payload) {
      updateApiTokens(payload);

      return { ...state, oauthResponse: payload };
    },
    setProvider(state, payload) {
      return { ...state, provider: payload };
    },
    setUser(state, payload) {
      return { ...state, user: payload };
    },
    setNodeAPIUser(state, payload) {
      return { ...state, nodeAPIUser: payload };
    },
    setUnreadMessages(state, payload) {
      return { ...state, unreadMessages: payload };
    },
    reset() {
      return INITIAL_STATE;
    },
  },

  effects: (dispatch) => ({
    async logOut() {
      window.referrer = undefined;
      this.reset();
      await nodeApiLogout();
      updateApiTokens();
      chatLogout();
      clearNodeToken();
      sessionStorage.clear();
      queryClient.invalidateQueries();
    },

    async attemptWithSocial(payload) {
      const oauthResponse = await AuthService.attemptWithSocial({
        ...payload,
        time_zone: getCurrentUserTimezone(),
      });

      if (oauthResponse.ok) {
        this.setOauthResponse(oauthResponse.data);
        this.setProvider(payload.provider);
      }

      await this.fetchUser();

      this.setAuthenticationStatus(oauthResponse.ok);

      return oauthResponse;
    },

    async storeSuccessfulEmailResponse(oauthData) {
      this.setOauthResponse({
        access_token: oauthData.accessToken,
        token_type: oauthData.tokenType,
        expires_in: oauthData.expiresIn,
        refresh_token: oauthData.refreshToken,
        created_at: oauthData.createdAt,
      });
      this.setProvider('local');
      this.setAuthenticationStatus(true);

      await this.fetchUser();
    },

    storeFreshAccessToken(data) {
      this.setOauthResponse(data);
    },

    updateUserOrganizationsForCreateCommunity(data, rootState) {
      dispatch.entities.updateEntity({
        users: {
          [rootState.session.user]: {
            organizations_for_new_community: (arr) =>
              arr.concat({
                id: data.id,
                name: data.name,
              }),
          },
        },
      });
    },

    async resetOnlyToUser() {
      const response = await AuthService.getCurrentUser();

      if (response.ok) {
        const { result: user, entities } = normalizeUserDataWithResult(response.data);
        dispatch({ type: 'RESET_APP', data: { user, entities } });
        this.setUser(normalizeUserData(response.data));
        this.setAuthenticationStatus(true);
      }

      return response;
    },

    async setUserData(user) {
      await this.setUser(normalizeUserData(user));
    },

    async fetchUser() {
      const response = await AuthService.getCurrentUser();

      if (response.ok) {
        this.setUser(normalizeUserData(response.data));
        try {
          const nodeAPIResponse = await nodeApiLogin(await getAccessToken());
          storeNodeToken(nodeAPIResponse.data.token);
          this.setNodeAPIUser({ result: nodeAPIResponse.data, error: null });
          try {
            const chatroomStatsResponse = await getChatroomStats();
            this.setUnreadMessages(chatroomStatsResponse.data[0].unread);
          } catch (error) {
            console.error(error);
          }
        } catch (error) {
          this.setNodeAPIUser({ result: null, error: new Error(error) });
        }
      }

      return response;
    },

    updateChatroomStats(unreadCount) {
      this.setUnreadMessages(unreadCount);
    },

    async updateUser({ id, data }) {
      const response = await UserService.updateUser(id, data);

      if (response.ok) {
        this.setUser(normalizeUserData(response.data));
      }

      return response;
    },

    updatePassword(payload) {
      return UserService.updatePassword(payload);
    },

    async getCalendars() {
      return await CalendarService.get();
    },

    async removeCalendar(params) {
      return await CalendarService.remove(params);
    },

    async addCalendar(params) {
      return await CalendarService.add(params);
    },
  }),

  selectors: {
    oauthResponse: (state) => state.oauthResponse,
    isAuthenticated: (state) => state.authenticated,
    user: (state) => denormalize(state.user, UserSchema, getState().entities),
    nodeAPIUser: (state) => state.nodeAPIUser,
    unreadMessages: (state) => state.unreadMessages,
    provider: (state) => state.provider,
    accessToken: (state) => (state.oauthResponse ? state.oauthResponse.access_token : undefined),
  },
};
