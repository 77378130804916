import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

export const useNavigateToJoinedPrograms = () => {
  const { push } = useHistory();

  return () => {
    push(routes.program.joined);
  };
};
