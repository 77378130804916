import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type EventContentObjectDataObject } from 'models/service';

import { type AttendeesRequestData } from './createEvent';

export const patchEvent = async (id: string, event: AttendeesRequestData) =>
  apiClient.patch<EventContentObjectDataObject, ApiClientResponse<EventContentObjectDataObject>>(
    routes.events.patch(id),
    {
      event,
    },
  );
