import { PostRendererCore } from 'old/atoms/feed-object';
import { useState, useEffect } from 'react';
import { AxiosErrorHandler } from 'common/error-handlers';
import { type ContentObjectDataObject } from 'models/service';
import { Loading } from 'ui';
import { getKudos } from './services';

type P = {
  id: string;
};

export const PublicKudos = ({ id }: P) => {
  const [loading, setLoading] = useState<boolean>(true);
  // TODO we cant use our intern object `Poll` because this is going into components that are using Redux Data Objects
  const [kudosData, setKudosData] = useState<ContentObjectDataObject>();
  const [error, setError] = useState<any>(null);

  const fetchData = () => {
    (async () => {
      try {
        setLoading(true);
        const response = await getKudos(id);

        setKudosData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(fetchData, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AxiosErrorHandler error={error}>
      <PostRendererCore
        item={kudosData}
        isBookmark={false}
        isJournal={false}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onRequestUserProfile={() => {}}
        isPublic
      />
    </AxiosErrorHandler>
  );
};
