import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { AdminLayout } from '../../layouts/AdminLayout';
import { withPermissions } from '../../atoms/auth';
import { Icon } from '../../atoms/icon';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { Loader } from '../../atoms/loader';
import { showSuccessErrorAlert, showAlert } from '../../atoms/alert';
import { CommunityForm } from '../../atoms/community-form';
import { Link } from 'ui';

class Renderer extends React.Component {
  componentDidMount() {
    if (this.props.match.params.communityId) {
      this.props.findCommunity();
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.communityId !== prevProps.match.params.communityId) {
      if (!this.props.match.params.communityId) {
        this.props.resetCommunity();
      } else {
        this.props.findCommunity();
        window.scrollTo(0, 0);
      }
    }
  }

  onSubmitSuccess = (response) => {
    const text = this.props.match.params.communityId ? (
      <Trans>Community was successfully updated.</Trans>
    ) : (
      <Trans>Community was successfully created.</Trans>
    );

    showAlert({ type: 'success', message: text });

    this.props.history.push(
      `/admin/organizations/${this.props.match.params.organizationId}/communities/${response.data.id}/update`,
    );
  };

  onSubmitError(response) {
    showAlert({ type: 'error', message: <Trans>Something went wrong!</Trans> });
  }

  onSubmitCommunity = (values) => {
    if (this.props.match.params.communityId) {
      return this.props.updateCommunity({
        community_id: this.props.match.params.communityId,
        payload: values,
      });
    } else {
      return this.props.createCommunity(values);
    }
  };

  deleteCommunity = async () => {
    const { user } = this.props;
    const response = await this.props.deleteCommunity();

    showSuccessErrorAlert({
      isOk: response.ok,
      successMessage: <Trans>Community successfully deleted.</Trans>,
      errorMessage: <Trans>Cannot delete this community right now, please try again later.</Trans>,
    });

    if (response.ok) {
      this.props.history.push(
        user.admin_organizations && user.admin_organizations.length > 0
          ? `/admin/organizations/${this.props.community.organization_id}/update`
          : `/admin/communities`,
      );
    }
  };

  renderForm = () => {
    if (this.props.loading) {
      return <Loader />;
    }

    return (
      <div className="admin-community__form">
        <CommunityForm
          isDefaultCommunity={this.props.community ? this.props.community.default : false}
          community={this.props.community}
          onSubmit={this.onSubmitCommunity}
          onSuccess={this.onSubmitSuccess}
          onDelete={this.deleteCommunity}
          onError={this.onSubmitError}
          organizations={[...this.props.user.organizations_for_new_community]}
          currentUser={this.props.user}
        />
        {this.props.deletingCommunity && (
          <div className="admin-community__form__deleting">
            <Loader color="white" />
            <Text size={17} color="white">
              <Trans>It can take several minutes to delete large community. Please wait.</Trans>
            </Text>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <AdminLayout>
        <Box className="admin-community">
          {withPermissions(
            [{ scope: 'superadmin' }, { scope: 'manage-organization', subj: this.props.user.admin_organizations }],
            <Box flexDirection="row" justifyContent="flex-end">
              <Link
                to={`/admin/organizations/${this.props.match.params.organizationId}/communities/create`}
                className="admin-community__link"
              >
                <button className="admin-community__button" id={'create-community'}>
                  <Icon name="plus-bold" color="inherit" /> <Trans>Create New Community</Trans>
                </button>
              </Link>
            </Box>,
            null,
          )}
          {this.renderForm()}
        </Box>
      </AdminLayout>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  community: select.adminCommunities.find(state, { id: props.match.params.communityId }),
  organizations: select.adminOrganizations.get(state),
  loading:
    state.loading.effects.adminCommunities.get ||
    state.loading.effects.adminCommunities.find ||
    state.loading.effects.adminCommunities.resetCommunity,
  deletingCommunity: state.loading.effects.adminCommunities.delete,
  communities: select.adminCommunities.get(state),
});

const mapDispatch = (dispatch, props) => ({
  findCommunity: () => dispatch.adminCommunities.find(props.match.params.communityId),
  createCommunity: (payload) => dispatch.adminCommunities.create(payload),
  updateCommunity: (params) => dispatch.adminCommunities.update(params),
  deleteCommunity: () => dispatch.adminCommunities.delete(props.match.params.communityId),
  resetCommunity: () => dispatch.adminCommunities.resetCommunity(),
});

export const PageAdminCommunity = connect(mapState, mapDispatch)(Renderer);
