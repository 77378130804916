import debounce from 'lodash/debounce';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Row, Tooltip } from '../../../ui';
import { PlusIcon, SearchIcon } from '../../../ui/Icons';

type P = { onCreateChatroom: () => void; onSearchChange: (value: string) => void };

const ChatroomSearch: FC<P> = ({ onCreateChatroom, onSearchChange }: P) => {
  const { t } = useTranslation(['translationChat']);
  const handleCreateChatroom = onCreateChatroom;

  const handleOnChange = debounce((event: ChangeEvent<HTMLInputElement> | undefined) => {
    if (event) {
      onSearchChange(event.target.value);
    }
  }, 500);

  return (
    <Row className="chatroom-sidebar__search">
      <Input
        id="chat-sidebar-search"
        type="text"
        size="middle"
        allowClear
        placeholder={t('chat.chatroomSidebar.searchUsersSelect')}
        onChange={handleOnChange}
        suffix={<SearchIcon />}
      />
      <Tooltip title={t('chat.chatroomSidebar.newMessage')} trigger="hover">
        <Button
          type="link"
          className="icon icon--plus"
          size={'large'}
          onClick={handleCreateChatroom}
          id="chat-header-new-message"
        >
          <PlusIcon />
        </Button>
      </Tooltip>
    </Row>
  );
};

export default ChatroomSearch;
