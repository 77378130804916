import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { Navigation, NavLink, NavGroup } from '../atoms/navigation';
import { Loader } from '../atoms/loader';

class Renderer extends React.Component {
  componentDidMount() {
    this.props.getOrganizations();
    this.props.getCommunities();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOrganizationId && prevProps.selectedOrganizationId !== this.props.selectedOrganizationId) {
      this.props.getCommunities();
    }
  }

  render() {
    const organizationsCount = this.props.organizations.length;
    const communitiesCount = this.props.communities.length;

    return (
      <Navigation direction="vertical" compact openGroups>
        {/* ORGANIZATIONS */}
        {(this.props.user.superadmin || this.props.user.admin_organizations.length) > 0 && (
          <React.Fragment>
            <NavLink to="/admin/organizations">
              <Trans>Organizations</Trans>
              {` (${organizationsCount})`}
            </NavLink>

            {this.props.loadingOrganizations ? <Loader size={14} /> : null}
            <NavGroup path="/admin/organizations">
              {this.props.organizations.map((organization) => (
                <NavLink
                  key={organization.id}
                  isActive={this.props.selectedOrganizationId === organization.id}
                  to={`/admin/organizations/${organization.id}/update`}
                >
                  {organization.name}
                </NavLink>
              ))}
            </NavGroup>
          </React.Fragment>
        )}

        {/* COMMUNITIES */}
        <NavLink id={'nav-admin-communities'} to="/admin/communities">
          <Trans>Communities</Trans>
          {` (${communitiesCount})`}
        </NavLink>
        {this.props.loadingCommunities ? <Loader size={14} /> : null}
        <NavGroup path="/admin/communities">
          {this.props.communities.map((community) => (
            <NavLink
              key={community.id}
              isActive={this.props.selectedCommunityId === community.id}
              to={
                this.props.selectedOrganizationId
                  ? `/admin/organizations/${this.props.selectedOrganizationId}/communities/${community.id}/update`
                  : `/admin/communities/${community.id}/update`
              }
            >
              {community.name}
            </NavLink>
          ))}
        </NavGroup>
      </Navigation>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
  organizations: select.adminOrganizations.get(state),
  communities: select.adminCommunities.get(state),
  loadingOrganizations: state.loading.effects.adminOrganizations.getAsync,
  loadingCommunities: state.loading.effects.adminCommunities.get,
});

const mapDispatch = (dispatch, props) => ({
  getOrganizations: () => dispatch.adminOrganizations.getAsync(),
  getCommunities: () => dispatch.adminCommunities.get(props.selectedOrganizationId),
});

export const AdminSideNav = connect(mapState, mapDispatch)(Renderer);
