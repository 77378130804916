import * as React from 'react';
import { Field } from 'formik';

import { CategoriesInput } from '../inputs';
import { FieldError } from '../FieldError';

export function CategoriesField(props) {
  return (
    <Field name={props.name}>
      {(fieldProps) => {
        // $FlowExpectedError
        const selection = fieldProps.field.value || [];
        return (
          <React.Fragment>
            <div
              style={{
                width: '100%',
                display: 'inline-block',
                margin: '8px 0',
                padding: '6px 0',
              }}
            >
              <div
                style={{
                  marginBottom: 5,
                  padding: '0 5px',
                  color: '#90a4ae',
                  textAlign: 'left',
                  fontFamily: 'Gilroy, sans-serif',
                  fontSize: 12,
                  fontWeight: 100,
                  lineHeight: '14px',
                }}
              >
                {props.label}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CategoriesInput
                  currentSelectedData={fieldProps.field.value}
                  placeholder={props.placeholder}
                  selection={selection}
                  onChange={(value) => {
                    if (value) {
                      fieldProps.form.setFieldValue(props.name, value, true);
                      fieldProps.form.setFieldTouched(props.name, true, true);
                    }
                  }}
                  type={props.type}
                  data-test-id={props?.['data-test-id']}
                />
              </div>
              <FieldError name={props.name} />
            </div>
          </React.Fragment>
        );
      }}
    </Field>
  );
}
