import { ID, PaginationOptions } from '../common/types';
import { Chatroom, Message } from '../models';

export const defaultChatroomsPaginationOptions: PaginationOptions = {
  skip: 0,
  limit: 15,
  total: null,
};
export const defaultChatrooms: Chatroom[] = [];
export const defaultActiveChatroom: ID | null = null;
export const defaultChatroomsSearch = undefined;
export const defaultDeletedChatrooms = [];

export const defaultMessages: Message[] = [];
export const defaultMessagesLastCreatedAt: Date | undefined = undefined;
export const defaultUnreadMessagesInChatroom = 0;
export const defaultFailedMessageFromCreating: Message | undefined = undefined;
export const defaultMessagesPaginationOptions: PaginationOptions = {
  skip: 0,
  limit: 15,
  total: null,
};

export const defaultUsersSearch = null;
export const defaultUsersPaginationOptions: PaginationOptions = {
  skip: 0,
  limit: 15,
  total: null,
};
