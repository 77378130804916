import { Card } from 'ui';

export function OneColumnInnerLayout(props) {
  return (
    <Card shrinkedLeft>
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%', paddingLeft: 20 }}>{props.right}</div>
      </div>
    </Card>
  );
}
