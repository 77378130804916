import { type EntityOptions } from 'models';
import { InfinityLoading, Skeleton, Empty, CompassIcon, Card } from 'ui';
import { CARDS_IN_ROW } from '..';
import { ChannelsSlider } from '../components';
import { useChannelsInfiniteQuery } from '../queries';

type P = {
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
  navigateToChannelDetail: (id: string) => void;
  organizationId?: string;
};

const CustomChannels = ({ navigateToChannelDetail, getPathToEntityDetail, organizationId }: P) => {
  const {
    data: channelsData,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useChannelsInfiniteQuery({
    pageSize: CARDS_IN_ROW,
    organizationId,
  });

  const channels = channelsData?.pages.flat();

  return (
    <>
      {isLoading || !channels ? (
        <Skeleton active paragraph={{ rows: 12 }} />
      ) : channels.length === 0 ? (
        <Card>
          <Empty
            image={<CompassIcon />}
            description={{
              title: 'No channels',
              subtitle: 'There are no channels to display here',
            }}
          />
        </Card>
      ) : (
        <>
          {channels.map((channel) => (
            <ChannelsSlider
              channelId={channel.id}
              view="preview"
              getPathToEntityDetail={getPathToEntityDetail}
              onSeeAll={() => {
                navigateToChannelDetail(channel.id);
              }}
              title={channel.name}
              description={channel.description}
              key={`channel-${channel.id}`}
            />
          ))}
          <InfinityLoading isLoading={isFetching} hasMoreData={hasNextPage} onEnter={fetchNextPage} />
        </>
      )}
    </>
  );
};

export default CustomChannels;
