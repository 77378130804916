import { type ApiClientResponse, apiClient, routes } from 'common/services';
import { type PaginatedRailsDataObjects } from 'models/service';
import { type ProgramsScope, type ProgramListDataObject, type ProgramStatus } from '../types';

type ProgramsRequest = {
  scope?: ProgramsScope;
  limit?: number;
  offset?: number;
  cursor?: string;
  organization_id?: string;
  featured?: boolean;
  status?: ProgramStatus;
  view: 'table';
  search?: string;
};

export const getPrograms = async (params: ProgramsRequest) =>
  apiClient.get<
    PaginatedRailsDataObjects<ProgramListDataObject>,
    ApiClientResponse<PaginatedRailsDataObjects<ProgramListDataObject>>
  >(routes.program.find, {
    params,
  });
