import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { AdminLayout } from '../../layouts/AdminLayout';
import { withPermissions } from '../../atoms/auth';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { Loader } from '../../atoms/loader';
import { Icon } from '../../atoms/icon';
import { showSuccessErrorAlert, showAlert } from '../../atoms/alert';
import { OrganizationForm } from '../../atoms/organization-form';
import { Link } from 'ui';

export class Renderer extends React.Component {
  componentDidMount() {
    this.props.findOrganization();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.organizationId !== prevProps.match.params.organizationId) {
      this.props.findOrganization();
      window.scrollTo(0, 0);
    }
  }

  onUpdateSuccess = (response) => {
    showAlert({ type: 'success', message: <Trans>Organization was successfully updated.</Trans> });
  };

  onUpdateOrganization = async (values) => {
    return this.props.updateOrganization({
      organization_id: this.props.organization.id,
      payload: values,
    });
  };

  onSubmitError() {
    showAlert({
      type: 'error',
      message: <Trans>Something went wrong, please try again later!</Trans>,
    });
  }

  deleteOrganization = async () => {
    const response = await this.props.deleteOrganization();

    showSuccessErrorAlert({
      isOk: response.ok,
      successMessage: <Trans>Organization successfully deleted.</Trans>,
      errorMessage: <Trans>Cannot delete this organization right now, please try again later.</Trans>,
    });

    if (response.ok) {
      this.props.history.push(`/admin/organizations`);
    }
  };

  renderForm = () => {
    if (this.props.loadingOrganization) {
      return <Loader />;
    }

    return (
      <div className="organization-update__form">
        <OrganizationForm
          organization={this.props.organization}
          onSubmit={this.onUpdateOrganization}
          onSuccess={this.onUpdateSuccess}
          onError={this.onSubmitError}
          onDelete={this.deleteOrganization}
          currentUser={this.props.user}
        />
        {this.props.deletingOrganization && (
          <div className="organization-update__deleting">
            <Loader color="white" />
            <Text size={17} color="white">
              <Trans>It can take several minutes to delete large organization. Please wait.</Trans>
            </Text>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <AdminLayout>
        <Box className="organization-update">
          {withPermissions(
            [{ scope: 'superadmin' }],
            <Box flexDirection="row" justifyContent="flex-end">
              <Link to="/admin/organizations/create" style={{ textDecoration: 'none' }}>
                <button className="organization-update__create">
                  <Icon name="plus-bold" color="inherit" /> <Trans>Create New Organization</Trans>
                </button>
              </Link>
            </Box>,
            null,
          )}
          {this.renderForm()}
        </Box>
      </AdminLayout>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  organization: select.adminOrganizations.find(state, props.match.params.organizationId),
  loadingOrganization: state.loading.effects.adminOrganizations.findAsync,
  deletingOrganization: state.loading.effects.adminOrganizations.deleteAsync,
});

const mapDispatch = (dispatch, props) => ({
  findOrganization: () => dispatch.adminOrganizations.findAsync(props.match.params.organizationId),
  deleteOrganization: () => dispatch.adminOrganizations.deleteAsync(props.match.params.organizationId),
  updateOrganization: (params) => dispatch.adminOrganizations.updateAsync(params),
});

export const PageOrganizationUpdate = connect(mapState, mapDispatch)(Renderer);
