import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Text, Row, Col, Section, Item, SelectInput, RichTextInput, Title } from 'ui';
import { ActivityReminders, ActivityTimes } from '.';

type P = {
  disabledEdit: boolean;
  isDexcom: boolean;
};

const dexcomSchedulingOptions = {
  periodicity: [
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
  ],
};

const schedulingOptions = {
  periodicity: [
    { name: 'One time', value: 'one_time' },
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Quarterly', value: 'quarterly' },
    { name: 'Annually', value: 'annually' },
  ],
  days: [
    { name: 'Monday', value: 'Monday' },
    { name: 'Tuesday', value: 'Tuesday' },
    { name: 'Wednesday', value: 'Wednesday' },
    { name: 'Thursday', value: 'Thursday' },
    { name: 'Friday', value: 'Friday' },
    { name: 'Saturday', value: 'Saturday' },
    { name: 'Sunday', value: 'Sunday' },
  ],
};

const ActivityFormStep4 = ({ disabledEdit, isDexcom }: P) => {
  const { t } = useTranslation();

  return (
    <div className="activity-form-step-4">
      <Section paddingTop={false} paddingBottom={false} lineTop={false} lineBottom>
        <Title level={3}>Frequency</Title>
        <Row item={{ gutter: variables.spaceMd.value }}>
          <Col item={{ span: 12 }}>
            <SelectInput
              item={{
                name: 'periodicity_type',
                label: 'Periodicity',
                rules: [
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                ],
              }}
              input={{
                placeholder: t('Please select periodicity'),
                options: isDexcom ? dexcomSchedulingOptions.periodicity : schedulingOptions.periodicity,
                getOptionLabel: (option) => t(option.name),
                getOptionValue: (option) => option.value,
                disabled: disabledEdit,
              }}
            />
          </Col>
          <Item
            noStyle
            shouldUpdate={(previousValues: any, currentValues: any) =>
              previousValues.periodicity_type !== currentValues.periodicity_type
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue('periodicity_type') === 'daily' ? (
                <Col item={{ span: 12 }}>
                  <ActivityTimes name="times" disabled={disabledEdit} />
                </Col>
              ) : null;
            }}
          </Item>
        </Row>
      </Section>
      <Section paddingTop paddingBottom lineTop={false} lineBottom>
        <Title level={3}>Reminders</Title>
        <Text className="activity-form__reminders__description">{t('reminderInfo')}</Text>
        <ActivityReminders name="reminders" disabled={disabledEdit} />
      </Section>
      <Section paddingTop paddingBottom={false} lineTop={false} lineBottom={false}>
        <Title level={3}>Additional details</Title>

        <RichTextInput
          text={{ name: 'description' }}
          html={{ name: 'html_description', label: t('Description (Optional)'), maxWidth: '100%' }}
        />
      </Section>
    </div>
  );
};

export default ActivityFormStep4;
