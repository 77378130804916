import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../common/services';

import {
  IdRequest,
  PaginationDataObject,
  PaginationRequest,
  UserNameRequest,
} from '../../common/services/data-objects';
import { ID } from '../../common/types';
import { UserDataObject } from '../data-objects';

export type FindMembersRequest = IdRequest &
  PaginationRequest &
  UserNameRequest & {
    $sort?: {
      lastName: number;
    };
  };

export type FindMembersDataObject = PaginationDataObject<UserDataObject>;

export const findMembers = (practice: ID | null, params: FindMembersRequest) =>
  nodeApiClient.get<FindMembersDataObject, AxiosResponse<FindMembersDataObject>>(routes.practices.members(practice), {
    params,
  });
