import { useTranslation } from 'react-i18next';

import { Alert, Form } from 'ui';

type P = {
  totalInvitees: number;
  isEventApproved: boolean;
  exceededApprovedInviteesLimit?: number;
};

const EventInviteesAlert = ({ totalInvitees, isEventApproved, exceededApprovedInviteesLimit }: P) => {
  const { t } = useTranslation();

  const isExceededLimitAlertVisible = Boolean(exceededApprovedInviteesLimit);
  const isApprovalNeededAlertVisible = !isEventApproved && totalInvitees > 25;

  return (
    <Form.Item
      name="alertExceededInvitees"
      className="event-invitees-exceeded-limit"
      noStyle={!isExceededLimitAlertVisible && !isApprovalNeededAlertVisible}
      rules={[
        {
          required: true,
          message: '',
          validator: async () =>
            isExceededLimitAlertVisible
              ? Promise.reject(new Error('Number of invitees exceeded limit'))
              : Promise.resolve(),
        },
      ]}
    >
      {isExceededLimitAlertVisible ? (
        <Alert
          message={t('More than {{ count }} invitees', {
            count: exceededApprovedInviteesLimit,
          })}
          description={t(
            'The number of invitees cannot be increased, beyond the specified limit, in the published event.',
            { number: exceededApprovedInviteesLimit },
          )}
          type="warning"
        />
      ) : null}
      {isApprovalNeededAlertVisible ? (
        <Alert
          message={t('More than {{ count }} invitees', {
            count: 25,
          })}
          description={t(
            'You are inviting more than {{ count }} people! This requires approval from the admin. During approval process, event cannot be changed.',
            { count: 25 },
          )}
          type="warning"
        />
      ) : null}
    </Form.Item>
  );
};

export default EventInviteesAlert;
