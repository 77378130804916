import {
  type CommunityListItem,
  type EventContentObject,
  Event,
  type Image,
  InviteType,
  type RegisteredEventInvitee,
} from 'models';
import { type Moment } from 'moment';

import { Form, ModalFormActions, RichTextInput, Title, ImageInputDeprecated, TextInput } from 'ui';

import EventInviteesSelect from '../../../EventInviteesSelect';

import { mapInviteTypeToRole, useEventInvitees, EventInviteesPicker } from '../EventInviteesPicker';

import TelehealthOptions from './TelehealthOptions';

type P = {
  mode: 'create' | 'duplicate' | 'edit';
  isLoading: boolean;
  communities: CommunityListItem[];
  inviteType: InviteType;
  onSubmit: (data: TelehealthEventData, event?: Event) => Promise<void> | void;
  onInviteesTypeChanged: (type: InviteType) => void;
  initialData?: Partial<TelehealthEventFormValues>;
  communityId?: string;
  eventContentObject?: EventContentObject;
};

export type TelehealthEventData = { formValues: TelehealthEventFormValues; eventType: 'telehealth_visit' };

export type TelehealthEventFormValues = {
  communityId: string;
  patientType: string;
  visitType: string;
  visitTypeSubOption: string;
  visitTypeSubSubOption?: string;
  startTime: Moment;
  duration: number;
  endTime: Moment;
  invitees: RegisteredEventInvitee[];
  moderators: RegisteredEventInvitee[];
  htmlContent?: string;
  textContent?: string;
  coverPhoto?: Image;
  changeRequestId?: string;
  relatedAdmissionId?: string;
};

const generateInitialValues = ({
  communityId,
  eventContentObject,
  isDuplicating,
  initialData,
}: {
  communityId?: string;
  eventContentObject?: EventContentObject;
  isDuplicating?: boolean;
  initialData?: Partial<TelehealthEventFormValues>;
}): Partial<TelehealthEventFormValues> => {
  const event = eventContentObject?.event;
  const startTime = initialData?.startTime ?? Event.generateStartDate({ startTime: event?.startTime, isDuplicating });
  const duration = initialData?.duration ?? event?.duration;
  const endTime =
    initialData?.endTime ?? Event.getEndDate({ startTime, duration, endTime: event?.endTime, isDuplicating });

  return {
    communityId: (eventContentObject?.postInCommunities.map((entity) => entity.id) ?? [
      communityId ?? initialData?.communityId,
    ])[0],
    patientType: initialData?.patientType ?? event?.meta?.patientType?.name,
    visitType: initialData?.visitType ?? event?.meta?.serviceType?.[0]?.name,
    visitTypeSubOption: initialData?.visitTypeSubOption ?? event?.meta?.serviceType?.[1]?.name,
    visitTypeSubSubOption: initialData?.visitTypeSubSubOption ?? event?.meta?.serviceType?.[2]?.name,
    startTime,
    duration,
    endTime,
    invitees:
      initialData?.invitees ??
      (event?.invitees ?? initialData?.invitees)?.filter(
        (invitee: RegisteredEventInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.invitees),
      ) ??
      [],
    moderators:
      initialData?.moderators ??
      event?.invitees.filter(
        (invitee: RegisteredEventInvitee) =>
          invitee.eventRole === mapInviteTypeToRole(InviteType.presenters) ||
          invitee.eventRole === mapInviteTypeToRole(InviteType.moderators),
      ) ??
      [],
    htmlContent: initialData?.htmlContent ?? event?.htmlContent ?? undefined,
    textContent: initialData?.textContent ?? event?.textContent,
    coverPhoto: initialData?.coverPhoto ?? event?.coverPhoto,
    changeRequestId: initialData?.changeRequestId,
    relatedAdmissionId: initialData?.relatedAdmissionId,
  };
};

const TelehealthEventForm = ({
  isLoading,
  communityId,
  communities,
  eventContentObject,
  inviteType,
  initialData,
  onSubmit,
  onInviteesTypeChanged,
  mode,
}: P) => {
  const event = eventContentObject?.event;
  const [form] = Form.useForm<TelehealthEventFormValues>();

  const { resetSelectedUsers, onInvite, onShowInvitees } = useEventInvitees({ form, onInviteesTypeChanged });

  const submitTitle = () => {
    switch (mode) {
      case 'duplicate':
        return 'Duplicate Telehealth Visit';
      case 'create':
        return 'Create Telehealth Visit';
      case 'edit':
        return 'Edit Telehealth Visit';
    }
  };

  const onFinish = (formValues: TelehealthEventFormValues) =>
    onSubmit({ formValues, eventType: 'telehealth_visit' }, event);

  const isDuplicating = Boolean(mode === 'duplicate');

  return (
    <>
      {inviteType ? (
        <Form.Item noStyle dependencies={['communityId']}>
          {() => {
            const communityId: string | undefined = form.getFieldValue('communityId');

            return (
              <EventInviteesPicker
                role={inviteType === InviteType.invitees ? 'member' : 'admin'}
                allowedInvites={['registered']}
                form={form}
                communityId={communityId}
                inviteType={inviteType}
                onInvite={onInvite}
              />
            );
          }}
        </Form.Item>
      ) : (
        <Form<TelehealthEventFormValues>
          form={form}
          initialValues={generateInitialValues({
            communityId,
            eventContentObject,
            isDuplicating,
            initialData,
          })}
          onFinish={onFinish}
        >
          <Title level={4}>Visit details</Title>
          <TelehealthOptions
            mode={mode}
            form={form}
            communities={communities}
            disabledCommunity={Boolean(initialData)}
            resetSelectedUsers={resetSelectedUsers}
            /* eslint-disable react/jsx-no-leaked-render */
            disabledTime={event?.isStarted && !isDuplicating}
            disabledDuration={event?.isFinished && !isDuplicating}
            disabled={mode === 'edit' && event?.isStarted}
            /* eslint-enable react/jsx-no-leaked-render */
          />
          <Title level={4}>Invitations</Title>
          <EventInviteesSelect
            name="moderators"
            label="Providers"
            buttonText="Invite"
            onPress={onShowInvitees(InviteType.moderators)}
            id="btn_invite-moderators"
            disabled={mode === 'edit' && event?.isFinished}
          />
          <EventInviteesSelect
            name="invitees"
            label="Patients / Guests"
            buttonText="Invite"
            onPress={onShowInvitees(InviteType.invitees)}
            id="btn_invite-attendees"
            disabled={mode === 'edit' && event?.isFinished}
            inviteOptions={
              mode === 'edit' && event?.id
                ? {
                    eventId: event?.id,
                    kind: 'event_attendee_invitation',
                    successMessage: 'Link for patient / guest copied to clipboard',
                  }
                : undefined
            }
          />
          <Title level={4}>Additional details</Title>
          <RichTextInput
            text={{ name: 'textContent' }}
            html={{
              name: 'htmlContent',
              label: 'Description (Optional)',
              placeholder: 'Type your text here...',
              'data-test-id': 'event_description',
            }}
          />
          <ImageInputDeprecated
            item={{ name: 'coverPhoto', label: 'Event photo' }}
            aspectRatio={{ width: 965, height: 300 }}
          />
          <TextInput item={{ name: 'changeRequestId', hidden: true }} />
          <TextInput item={{ name: 'relatedAdmissionId', hidden: true }} />
          <ModalFormActions
            submit={{
              loading: isLoading,
              children: submitTitle(),
              id: 'btn_schedule-event',
            }}
          />
        </Form>
      )}
    </>
  );
};

export default TelehealthEventForm;
