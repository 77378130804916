import { MutableRefObject, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useResetRecoilState, useSetRecoilState } from 'recoil';
import { getChatroom, setChatroomStatusToRead } from '../../actions';
import { Profile, User } from '../../common/models';
import { DataState } from '../../common/recoil/queries';
import { activePracticeIdState } from '../../common/recoil/selectors';
import { ID } from '../../common/types';
import { Chatroom as ChatroomModel } from '../../models';
import { activeChatroom, chatrooms as chatroomsAtom, deletedChatrooms } from '../../recoil/atoms';
import {
  chatroomsDependenciesState,
  chatroomsSearchState,
  chatroomsState,
  pushChatroomsState,
  updateChatroomsState,
  updateChatroomStatusState,
} from '../../recoil/selectors';
import { Button, InfiniteScroll, message, Result, Spin } from '../../ui';
import Chatroom from './Chatroom';
import ChatroomSearch from './ChatroomSearch';
import { ChatroomState } from '../../../index';
import { ChatroomSidebarEmptyState } from './ChatroomSidebarEmptyState';

type P = {
  currentUserProfileData: DataState<Profile>;
  updateTotalUnreadCount?: (difference?: number) => void;
  updateUnreadBadge: (activeChatroomId: string | null) => void;
  defaultNewChatroomUserRef?: MutableRefObject<User | undefined>;
  chatroomIdToOpen?: ID;
  handleChatState: (chatStateToApply: ChatroomState, id?: ID) => void;
  currentChatState: ChatroomState;
};

const ChatroomSidebar = ({
  currentUserProfileData,
  updateTotalUnreadCount,
  updateUnreadBadge,
  defaultNewChatroomUserRef,
  chatroomIdToOpen,
  handleChatState,
  currentChatState,
}: P) => {
  const { t } = useTranslation(['translationChat']);
  const chatrooms = useRecoilValue(chatroomsAtom);
  const chatroomsData = useRecoilValueLoadable(chatroomsState);
  const resetChatroomsData = useResetRecoilState(chatroomsState);
  const [activeChatroomId, setActiveChatroomId] = useRecoilState(activeChatroom);
  const [chatroomsDependencies, setChatroomsDependencies] = useRecoilState(chatroomsDependenciesState);
  const setChatroomStatus = useSetRecoilState(updateChatroomStatusState);
  const setLoadedChatrooms = useSetRecoilState(updateChatroomsState);
  const pushLoadedChatrooms = useSetRecoilState(pushChatroomsState);
  const activePracticeId = useRecoilValue(activePracticeIdState);
  const preopenedChatroomDataReference = useRef<ChatroomModel | undefined>();
  const deletedChatroomsData = useRecoilValue(deletedChatrooms);
  const [chatroomSearch, setChatroomSearch] = useRecoilState(chatroomsSearchState);

  useEffect(() => {
    const loadPreopenedChatroomData = async (chatroomIdToOpen: string) => {
      const response = await getChatroom(chatroomIdToOpen, activePracticeId);

      if (response.error) {
        return message.error(t('chat.chatroomDetail.messages.preopenedChatroomLoadError'));
      }

      const { chatroom } = response.result;
      setLoadedChatrooms([...chatrooms, chatroom]);
      preopenedChatroomDataReference.current = chatroom;
      setActiveChatroomId(chatroomIdToOpen);
    };

    if (
      chatroomIdToOpen &&
      chatrooms.length > 0 &&
      !deletedChatroomsData.find((chatroomId) => chatroomId === chatroomIdToOpen)
    ) {
      chatrooms.some((chatroom) => chatroom._id === chatroomIdToOpen)
        ? setActiveChatroomId(chatroomIdToOpen)
        : loadPreopenedChatroomData(chatroomIdToOpen).catch(() =>
            message.error(t('chat.chatroomDetail.messages.preopenedChatroomLoadError')),
          );
    }
  }, [
    t,
    chatrooms,
    chatroomIdToOpen,
    setActiveChatroomId,
    activePracticeId,
    setLoadedChatrooms,
  ]);

  useEffect(() => {
    if (chatroomsData.state === 'hasValue' && !chatroomsData.contents.error) {
      const { chatrooms: newChatrooms } = chatroomsData.contents.result;
      if (chatroomsDependencies.skip > 0) {
        if (newChatrooms.some((chatroom) => chatroom._id === chatroomIdToOpen)) {
          preopenedChatroomDataReference.current = undefined;
        }

        pushLoadedChatrooms(newChatrooms);
      } else {
        setLoadedChatrooms(newChatrooms);
      }

      if (preopenedChatroomDataReference.current) {
        pushLoadedChatrooms([preopenedChatroomDataReference.current]);
      }
    }
  }, [
    // TODO `chatroomIdToOpen` can't be dependency, we have to call this `useEffect` only on change of response data from `chatroomsData`
    // chatroomIdToOpen,
    preopenedChatroomDataReference,
    chatroomsData,
    chatroomsDependencies.skip,
    pushLoadedChatrooms,
    setLoadedChatrooms,
  ]);

  if ((chatroomsData.state === 'hasValue' && chatroomsData.contents.error) || currentUserProfileData.error) {
    return (
      <Result
        status="500"
        subTitle={t('Sorry, something went wrong.')}
        extra={
          !currentUserProfileData.error && (
            <Button type="primary" onClick={() => resetChatroomsData()}>
              Retry
            </Button>
          )
        }
      />
    );
  }

  const currentUser = currentUserProfileData.result;

  const loadChatrooms = () => {
    if (chatroomsData.state === 'hasValue' && chatroomsData.contents && !chatroomsData.contents.error) {
      setChatroomsDependencies(lastDeps => ({
        ...lastDeps,
        skip: lastDeps.skip + lastDeps.limit,
      }));
    }
  };

  const handleCreateChatroomClick = () => {
    resetChatroomsData();
    handleChatState('creating');
    setActiveChatroomId(null);
  };

  const handleChatroomClick = async (chatroomId: ID) => {
    const readChatroom = await setChatroomStatusToRead(chatroomId);

    if (!readChatroom.error) {
      setChatroomStatus(readChatroom.result);

      if (updateTotalUnreadCount) {
        updateUnreadBadge(chatroomId);
      }
    }

    if (defaultNewChatroomUserRef) {
      defaultNewChatroomUserRef.current = undefined;
    }

    if (currentChatState === 'creating') {
      resetChatroomsData();
    }

    handleChatState('chatroom', chatroomId);
  };

  const handleSearchChange = (value: string) => setChatroomSearch(value);

  const isChatroomActive = (id: ID) => id === activeChatroomId;

  const getHasNextPage = () => {
    if (chatroomsData.state === 'loading') {
      return true;
    }

    if (chatroomsData.state === 'hasValue' && chatroomsData.contents.result) {
      return chatroomsData.contents.result.hasMoreChatrooms;
    }

    return false;
  };

  return (
    <div className="chatroom-sidebar" id="chat-sidebar">
      <ChatroomSearch onCreateChatroom={handleCreateChatroomClick} onSearchChange={handleSearchChange} />
      {chatrooms.length === 0 && chatroomsData.state !== 'loading' ? (
        <ChatroomSidebarEmptyState isSearched={Boolean(chatroomSearch)} />
      ) : (
        <InfiniteScroll
          isLoading={chatroomsData.state === 'loading'}
          onLoadMore={loadChatrooms}
          hasNextPage={getHasNextPage()}
          className="chatroom-sidebar__chatrooms"
          loadingTitle={t('chat.chatroomSidebar.loading')}
          data={chatrooms}
          createItem={(chatroom: ChatroomModel) => (
            <Chatroom
              key={chatroom._id}
              currentUser={currentUser}
              chatroom={chatroom}
              isOnline
              isActive={isChatroomActive(chatroom._id)}
              onChatroomClick={handleChatroomClick}
            />
          )}
          compareFirstItems={(item1?: ChatroomModel, item2?: ChatroomModel) => item1?._id === item2?._id}
        />
      )}
    </div>
  );
};

export default ChatroomSidebar;
