import * as React from 'react';
import { FieldArray } from 'formik';
import { Trans } from 'react-i18next';

import { CropperEditorField } from '../../form';
import { Box } from '../../box';
import { Button } from '../../button';
import { Icon } from '../../icon';
import { FieldError } from '../..//form/FieldError';

export class Screenshots extends React.Component {
  render() {
    const { name, formikElement, min, max, ...bag } = this.props;

    return (
      <>
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <div className="screenshots-wrapper">
              {formikElement && formikElement.length > 0
                ? formikElement.map((screenshot, index) => (
                    <div key={index} className="col-sm-6 screenshot-image-wrapper">
                      <Box flexDirection="row" style={{ width: '100%' }}>
                        <CropperEditorField
                          name={`${name}.${index}`}
                          labelAlign="center"
                          label={<Trans>Screenshot</Trans>}
                          {...bag}
                        />
                        {index >= min && (
                          <div className="screenshot-delete-button" onClick={() => arrayHelpers.remove(index)}>
                            <Icon name="delete" size={22} color="#B0BEC5" />
                          </div>
                        )}
                      </Box>
                    </div>
                  ))
                : null}
              {formikElement.length <= max - 1 && (
                <Box className="col-sm-12">
                  <Button
                    type="button"
                    onClick={() => arrayHelpers.push('')}
                    title={<Trans>Add Screenshot</Trans>}
                    color="#eceff1"
                    textColor="#455A64"
                    size="md"
                    style={{
                      margin: '15px 0',
                    }}
                  />
                </Box>
              )}
            </div>
          )}
        />
        <FieldError name={name} />
      </>
    );
  }
}
