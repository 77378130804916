import cn from 'classnames';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useResetRecoilState, useSetRecoilState } from 'recoil';
import { MessageInput } from '..';
import { sendMessage, setChatroomStatusToRead } from '../../actions';
import { Profile } from '../../common/models';
import { processError, Error, DataState } from '../../common/recoil/queries';
import { useSocketIO } from '../../common/sockets';
import { ID } from '../../common/types';
import { Message } from '../../models';
import { activeChatroom, failedMessageFromCreating, messages, unreadMessagesInChatroom } from '../../recoil/atoms';
import {
  activeChatroomState,
  chatroomsLastUpdatedMessageState,
  chatroomsState,
  messagesDependenciesState,
  messagesState,
  updateChatroomStatusState,
} from '../../recoil/selectors';
import { MessageDataObject } from '../../services/data-objects';
import { ChatroomMessage } from '../../types';
import { InfiniteScroll, message as messagePopUp } from '../../ui';
import Header from './Header';
import MessagesGroup from './MessagesGroup';
import NoChatrooms from './NoChatrooms';
import { ChatroomState } from '../../../index';
import { events } from '../../common/services';

type P = {
  currentUserProfileData: DataState<Profile>;
  updateUnreadBadge: (activeChatroomId: string | null) => void;
  handleChatState: (chatStateToApply: ChatroomState, id?: ID) => void;
};

type GroupedMessages = { date: Date; messages: Message[] };

const ChatroomDetail = ({ currentUserProfileData, updateUnreadBadge, handleChatState }: P) => {
  const { t } = useTranslation(['translationChat']);
  const activeChatroomId = useRecoilValue(activeChatroom);
  const activeChatroomData = useRecoilValue(activeChatroomState(activeChatroomId));
  const chatroomsData = useRecoilValueLoadable(chatroomsState);
  const loadedMessages = useRecoilValueLoadable(messagesState);
  const [messagesData, setMessagesData] = useRecoilState(messages);
  const [areFilesUploaded, setAreFilesUploaded] = useState(false);
  const [unreadMessages, setUnreadMessages] = useRecoilState(unreadMessagesInChatroom);
  const setChatroomStatus = useSetRecoilState(updateChatroomStatusState);
  const setLastCreatedAt = useSetRecoilState(messagesDependenciesState);
  const setLastUpdatedChatroom = useSetRecoilState(chatroomsLastUpdatedMessageState);
  const resetMessagesData = useResetRecoilState(messages);
  const failedMessageToInclude = useRecoilValue(failedMessageFromCreating);
  const resetMessagesDependenciesState = useResetRecoilState(messagesDependenciesState);
  const [idForFailedMessage, setIdForNextFailedMessage] = useState(1);
  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const failedMessageTranslationString = 'chat.chatroomDetail.messages.messageSendError';
  const initializeFailedMessage = useCallback(() => {
    if (failedMessageToInclude) {
      setMessagesData((messagesData) => [...messagesData, failedMessageToInclude]);
    }
  }, [failedMessageToInclude, setMessagesData]);


  useEffect(() => {
    resetMessagesData();
    resetMessagesDependenciesState();
    initializeFailedMessage();
  }, [initializeFailedMessage, resetMessagesData, resetMessagesDependenciesState]);

  useEffect(() => {
    setUnreadMessages(0);
    setLastCreatedAt(undefined);
    resetMessagesData();
  }, [activeChatroomId, resetMessagesData, setUnreadMessages, setLastCreatedAt]);

  useSocketIO<MessageDataObject>({
    event: events.chatroom.messages.created,
    onEvent: (socketData: MessageDataObject) => {
      const message = new Message(socketData);
      if (currentUserProfileData.error) {
        return messagePopUp.error(failedMessageTranslationString);
      }

      if (
        message.type === 'message' &&
        activeChatroomId === message.chatroom &&
        message.author !== currentUserProfileData.result._id
      ) {
        setUnreadMessages(unreadMessages + 1);
        setMessagesData([message, ...messagesData]);
      }
    },
  });

  const compareFirstItems = useCallback((item1?: GroupedMessages, item2?: GroupedMessages) => item1?.messages[0]?._id === item2?.messages[0]?._id, []);

  const isEqual = useCallback((item1: GroupedMessages, item2: GroupedMessages) =>
    item1?.messages[0]._id === item2?.messages[0]._id &&
    item1?.messages[item1.messages.length - 1]._id === item2?.messages[item2.messages.length - 1]._id, []);

  const messagesDataContents: { contents: Message[]; error: Error; result: Record<string, unknown> } =
    loadedMessages.contents as {
      contents: Message[];
      error: Error;
      result: Record<string, unknown>;
    };

  const messagesDataState = loadedMessages.state;

  const chatroomsDataContents: { contents: Message[]; error: Error; result: Record<string, unknown> } =
    chatroomsData.contents as {
      contents: Message[];
      error: Error;
      result: Record<string, unknown>;
    };

  const chatroomsDataState = chatroomsData.state;

  useEffect(() => {
    if (loadedMessages.state === 'hasValue') {
      setAllMessages([...messagesData, ...(loadedMessages.contents.result?.messages ?? [])]);
    }
  }, [loadedMessages, messagesData]);

  if (messagesDataState === 'hasValue' && messagesDataContents.error) {
    return processError(messagesDataContents.error);
  }

  if (chatroomsDataState === 'hasValue' && chatroomsDataContents.error) {
    return processError(chatroomsDataContents.error);
  }

  const hasMoreMessages = messagesDataState === 'hasValue' ? Boolean(messagesDataContents.result.hasMoreMessages) : true;
  
  const { total: totalChatrooms } = chatroomsDataState === 'hasValue' ? chatroomsDataContents.result : { total: null };

  const handleStartNewChatClick = () => handleChatState('creating');

  // TODO: Add fetched messages to atom associated by chatroomId to prevent fetching
  const handleSendMessage = async (data: ChatroomMessage, resentMessageId?: ID) => {
    const response = await sendMessage({ text: data.text, payload: data.payload }, activeChatroomId as string);
    if (currentUserProfileData.error) {
      return messagePopUp.error(failedMessageTranslationString);
    }

    if (response.error) {
      if (!resentMessageId) {
        setMessagesData([
          {
            text: data.text,
            payload: data.payload,
            author: currentUserProfileData.result._id,
            type: 'message',
            status: 'failed',
            createdAt: new Date(),
            updatedAt: new Date(),
            _id: idForFailedMessage.toString(),
            chatroom: activeChatroomId as ID,
          },
          ...messagesData,
        ]);
        setIdForNextFailedMessage(idForFailedMessage + 1);
      }

      return messagePopUp.error('chat.chatroomDetail.messages.messageSendError');
    }

    if (resentMessageId) {
      setMessagesData([
        response.result,
        ...messagesData.filter((message) => Number(message._id) !== Number(resentMessageId)),
      ]);
    } else {
      setMessagesData([response.result, ...messagesData]);
    }

    updateUnreadBadge(activeChatroomId);
    return setLastUpdatedChatroom(response.result);
  };

  const handleScrollAtStart = async () => {
    const readChatroom = await setChatroomStatusToRead(activeChatroomId as ID);

    if (!readChatroom.error && messagesDataState === 'hasValue') {
      setChatroomStatus(readChatroom.result);
      setUnreadMessages(0);
      updateUnreadBadge(activeChatroomId);
    }
  };

  const loadMessages = () => {
    if (hasMoreMessages && messagesDataState !== 'loading') {
      setLastCreatedAt(allMessages[allMessages.length - 1].createdAt);
      setMessagesData(allMessages);
    }
  };

  const formatMessageDate = (date: Date): string => format(date, 'yyyy-MM-dd');
  // TODO: Move to selector & change the way of grouping
  const groupMessagesByDay = useMemo(() => {
    const groupedMessages: GroupedMessages[] = [];

    for (const m of allMessages) {
      const currentDate = formatMessageDate(m.createdAt);
      const lastMessagesGroup = groupedMessages[groupedMessages.length - 1];
      if (!lastMessagesGroup || formatMessageDate(lastMessagesGroup.date) !== currentDate) {
        groupedMessages.push({
          date: m.createdAt,
          messages: [m],
        });
      } else {
        lastMessagesGroup.messages.push(m);
      }
    }

    return groupedMessages.reverse();
  }, [allMessages]);

  const setChatroomAsRead = async () => {
    if (!currentUserProfileData.result) {
      return;
    }

    if (activeChatroomId && !activeChatroomData.isReadByUser(currentUserProfileData.result?._id)) {
      const readChatroom = await setChatroomStatusToRead(activeChatroomId);

      if (!readChatroom.error) {
        setChatroomStatus(readChatroom.result);
        updateUnreadBadge(activeChatroomId);
      }
    }

    return;
  };

  if (chatroomsDataState === 'hasValue' && totalChatrooms === 0) {
    return <NoChatrooms onStartNewChatClick={handleStartNewChatClick} />;
  }

  if (!activeChatroomId) {
    return <div className="chatroom-detail" />;
  }

  return (
    <div className="chatroom-detail" onClick={setChatroomAsRead}>
      {!currentUserProfileData.error && activeChatroomData && (
        <Header currentUserProfile={currentUserProfileData.result} handleChatState={handleChatState} />
      )}
      <InfiniteScroll
        hasNextPage={hasMoreMessages}
        isLoading={messagesDataState === 'loading'}
        hasNewData={unreadMessages > 0}
        reverse
        className={cn('chatroom-detail__messages', {
          'chatroom-detail__messages--with-attachments': areFilesUploaded,
        })}
        loadingTitle={t('chat.chatroomSidebar.loading')}
        toastTitle={t('chat.chatroomDetail.toast', { count: unreadMessages })}
        bottomOffset={80}
        onLoadMore={loadMessages}
        onScrollAtStart={handleScrollAtStart}
        data={groupMessagesByDay}
        createItem={(g: GroupedMessages) => (
          <MessagesGroup
            key={g.date.toISOString()}
            group={g}
            onResendMessage={handleSendMessage}
            currentUserProfileData={currentUserProfileData}
          />
        )}
        compareFirstItems={compareFirstItems}
        isEqual={isEqual}
      />

      <MessageInput key={activeChatroomId} onSendClick={handleSendMessage} onFileListChange={setAreFilesUploaded} />
    </div>
  );
};

export default ChatroomDetail;
