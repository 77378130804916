import * as React from 'react';

import { Text as BaseText } from '../text';
import { ThemeConsumer } from '../theme';
import { Box } from '../box';

function Text(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <BaseText
          color={theme.color.lightGray}
          size="12px"
          weight="600"
          lineHeight="14px"
          style={{ textTransform: 'uppercase' }}
        >
          {props.children}
        </BaseText>
      )}
    </ThemeConsumer>
  );
}

export function ShadowBoxHeader(props) {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      style={{ borderBottom: '1px solid #edeff2', height: 55, padding: '0 20px', marginBottom: 20 }}
    >
      {typeof props.title === 'string' ? <Text>{props.title}</Text> : props.title}
      {typeof props.subtitle === 'string' ? <Text>{props.subtitle}</Text> : props.subtitle ? props.subtitle : null}
    </Box>
  );
}
