import { type OnboardingTourStepOption } from '../../models';
import { ContentLeftImage } from './ContentLeftImage';

type P = {
  step: OnboardingTourStepOption;
};

const OnboardingTourImage = ({ step }: P) => {
  switch (step) {
    case 'intro':
      return <ContentLeftImage alt="Welcome" src="/assets/onboarding/web-intro-bh.png" />;
    case 'profile':
      return <ContentLeftImage alt="Profile" src="/assets/onboarding/web-profile.png" />;
    case 'consents':
      return <ContentLeftImage alt="Consents" src="/assets/onboarding/web-consnets.png" />;
    case 'calendar':
      return <ContentLeftImage alt="Calendar" src="/assets/onboarding/web-calendar.png" />;
    case 'finish':
      return <ContentLeftImage alt="Welcome" src="/assets/onboarding/web-finish.png" />;
  }
};

export default OnboardingTourImage;
