import { type ReduxUser } from 'models';
import { useHistory, useParams } from 'react-router-dom';
import { SharedEvent } from 'features/events';
import { Header, ContentLeft, Footer, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

type RouteParams = {
  eventId: string;
  invitationToken: string;
};

type P = {
  viewer?: ReduxUser;
};

const PageSharedEvent = ({ viewer }: P) => {
  const { eventId, invitationToken } = useParams<RouteParams>();
  const { push } = useHistory();
  const { onNavigateToPrimaryLogin, onNavigateToSignUp } = useOnboardingNavigation<OnboardingLocationState>();

  const headerButtons: HeaderItem[] = [];

  if (viewer) {
    const onNavigateToEvents = () => {
      push('/events');
    };

    headerButtons.push({
      label: 'Go to events',
      type: 'default',
      onClick() {
        onNavigateToEvents();
      },
    });
  } else {
    headerButtons.push(
      {
        label: 'Log in',
        type: 'default',
        onClick() {
          onNavigateToPrimaryLogin({ referrer: window.location.pathname });
        },
      },
      {
        label: 'Sign up',
        type: 'default',
        onClick() {
          onNavigateToSignUp({ referrer: window.location.pathname });
        },
      },
    );
  }

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={<SharedEvent eventId={eventId} invitationToken={invitationToken} viewer={viewer} />}
      footer={<Footer />}
    />
  );
};

export default PageSharedEvent;
