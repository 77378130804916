import { type ChangeRequestDataObject, type ChangeRequestKind, type ChangeRequestStatus } from './service';

export class ChangeRequest {
  id: string;
  createdAt: Date;
  updatedAt: string;
  subject: {
    subjectId: string;
    subjectType: string;
    name: string;
  };

  createdUser: {
    id: string;
    fullName: string;
  };

  kind: ChangeRequestKind;
  status: ChangeRequestStatus;
  meta: {
    birthdate?: string;
    lastName?: string;
    firstName?: string;
    reasonToJoin?: string;
    invitationEmail?: string;
    invitationPhone?: string;
    invitationToken?: string;
  };

  constructor(data: ChangeRequestDataObject) {
    this.id = data.id;
    this.createdAt = new Date(data.created_at);
    this.updatedAt = data.updated_at;
    this.subject = {
      subjectId: data.subject.subject_id,
      subjectType: data.subject.subject_type,
      name: data.subject.name,
    };
    this.createdUser = {
      id: data.created_user.id,
      fullName: data.created_user.full_name,
    };
    this.kind = data.kind;
    this.status = data.status;
    this.meta = {
      birthdate: data.meta.birthdate,
      lastName: data.meta.last_name,
      firstName: data.meta.first_name,
      reasonToJoin: data.meta.reason_to_join,
      invitationEmail: data.meta.invitation_email,
      invitationPhone: data.meta.invitation_phone,
      invitationToken: data.meta.invitation_token,
    };
  }
}
