import { type NonCustomOnboardingTourStepOption } from '../../../../models';

export const useSupportedStepTitle = ({ step }: { step: NonCustomOnboardingTourStepOption }) => {
  switch (step) {
    case 'profile':
      return 'Make it yours!';
    case 'consents':
      return 'Confirm consents';
    case 'calendar':
      return 'Sync calendar to stay in loop';
  }
};
