// TODO: Write messages for rules like after, before, etc.
export const messages = {
  accepted: 'This field must be accepted.',
  after: 'This field must be after :after.',
  after_or_equal: 'This field must be equal or after :after_or_equal.',
  alpha: 'This field must contain only alphabetic characters.',
  alpha_dash: 'This field may only contain alpha-numeric characters, as well as dashes and underscores.',
  alpha_num: 'This field must be alphanumeric.',
  before: 'This field must be before :before.',
  before_or_equal: 'This field must be equal or before :before_or_equal.',
  between: 'This field must be between :min and :max.',
  confirmed: 'This field confirmation does not match.',
  email: 'This field format is invalid. Correct format is name@domain.tld.',
  date: 'This field is not a valid date format.',
  def: 'This field attribute has errors.',
  digits: 'This field must be :digits digits.',
  different: 'This field and :different must be different.',
  in: 'The selected :attribute is invalid.',
  integer: 'This field must be an integer.',
  hex: 'This field should have hexadecimal format',
  min: {
    numeric: 'This field must be at least :min.',
    string: 'This field must be at least :min characters.',
  },
  max: {
    numeric: 'This field may not be greater than :max.',
    string: 'This field may not be greater than :max characters.',
  },
  not_in: 'The selected :attribute is invalid.',
  numeric: 'This field must be a number.',
  present: 'This field must be present (but can be empty).',
  required: 'This field is required.',
  required_if: 'This field is required when :other is :value.',
  required_unless: 'This field is required when :other is not :value.',
  required_with: 'This field is required when :field is not empty.',
  required_with_all: 'This field is required when :fields are not empty.',
  required_without: 'This field is required when :field is empty.',
  required_without_all: 'This field is required when :fields are empty.',
  same: 'This field and :same fields must match.',
  size: {
    numeric: 'This field must be :size.',
    string: 'This field must be :size characters.',
  },
  string: 'This field must be a string.',
  url: 'This field format is invalid.',
  regex: 'This field format is invalid.',
  phone: 'This phone field contains invalid phone number. Correct format is XXX-XXX-XXXX.',
  attributes: {},
};
