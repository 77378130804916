import { useTranslation } from 'react-i18next';
import { Row, Text } from 'ui';

type P = {
  updatedAt: [number, number];
};

const TodosWidgetUpdatedAt = ({ updatedAt }: P) => {
  const { t } = useTranslation();

  return (
    <Row item={{ justify: 'center', className: 'todos-widget-updated-at' }}>
      <Text type="secondary">
        {t(`Last updated on`, {
          lastUpdateTime: new Date(Math.min(updatedAt[0], updatedAt[1])),
        })}
      </Text>
    </Row>
  );
};

export default TodosWidgetUpdatedAt;
