import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { sync } from '../services';
import { type SyncDexcomRequest } from '../services/data-objects';

export const useSyncAccount = () =>
  useMutation<unknown, ApiClientResponseError<DefaultResponseErrorDataObject>, SyncDexcomRequest>({
    async mutationFn(data: SyncDexcomRequest) {
      await sync(data);
    },
  });
