import * as React from 'react';

import { Box } from '../../box';
import { Icon } from '../../icon';
import { Text } from '../../text';
import { ThemeConsumer } from '../../theme';

export function RecentSearchItem(props) {
  const { title, ...downshiftProps } = props;
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box
          {...downshiftProps}
          flexDirection="row"
          alignItems="center"
          style={{ padding: '7px 15px', cursor: 'pointer' }}
        >
          <Box style={{ marginRight: 10 }}>
            <Icon name="recent" color={theme.color.gray} size={22} />
          </Box>
          <Text
            component="p"
            size="14px"
            color={theme.color.gray}
            lineHeight="19px"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {props.title}
          </Text>
        </Box>
      )}
    </ThemeConsumer>
  );
}
