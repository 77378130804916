import { type Campaign } from 'models';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleLineWithBackground, CloseCircleLineWithBackground, Modal, Title, Text, Row, Col, Button } from 'ui';

type P = {
  isOpened: boolean;
  onClose: () => void;
  wasSuccessful: boolean;
  campaign: Campaign;
};

const StripeDonationResultModal = ({ isOpened, onClose, wasSuccessful, campaign }: P) => {
  const organizationName = campaign.organization.name;
  const campaignName = campaign.name;
  const { t } = useTranslation();

  const modalProperties = useMemo(() => {
    return wasSuccessful
      ? {
          title: 'Thank you for donation!',
          icon: <CheckCircleLineWithBackground />,
          description: t('We would like to say thank you for your generous gift to campaign', { campaignName }),
          additionalDescription: t('You will receive all information directly to your e-mail', {
            organizationName,
            supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
          }),
        }
      : {
          title: 'Something went wrong.',
          icon: <CloseCircleLineWithBackground />,
          description: 'We’re sorry, but your donation could not be processed. Please try again later.',
        };
  }, [campaignName, organizationName, t, wasSuccessful]);

  return (
    <Modal
      title={wasSuccessful ? 'Donation success' : 'Donation failed'}
      isOpened={isOpened}
      onCancel={() => {
        onClose();
      }}
      width="small"
      disableBack
      destroyOnClose
      // TODO this can be done other way?
      footer={
        <Button type="primary" onClick={onClose} size="large">
          Close
        </Button>
      }
    >
      <Row item={{ align: 'middle', justify: 'center', gutter: [0, 16] }}>
        <Col item={{ span: 24, className: 'stripe-donation-result-modal__section' }}>{modalProperties.icon}</Col>
        <Col item={{ span: 24, className: 'stripe-donation-result-modal__section' }}>
          <Title level={3}>{modalProperties.title}</Title>
        </Col>
        <Col item={{ span: 22, className: 'stripe-donation-result-modal__section' }}>
          <Text>{modalProperties.description}</Text>
        </Col>
        {modalProperties.additionalDescription ? (
          <Col item={{ span: 20, className: 'stripe-donation-result-modal__section' }}>
            <Text>{modalProperties.additionalDescription}</Text>
          </Col>
        ) : null}
      </Row>
    </Modal>
  );
};

export default StripeDonationResultModal;
