import * as React from 'react';
import { withRouter } from 'react-router';
import { Trans } from 'react-i18next';
import { select } from '@rematch/select';
import { connect } from 'react-redux';

import { AppLayout } from './AppLayout';
import { Card } from 'ui';
import { Navigation, NavLink, NavGroup } from '../atoms/navigation';
import { AppService } from '../services/AppService';
import { routes } from 'routes';

class Renderer extends React.Component {
  state = {
    appConfigs: [],
  };

  async componentDidMount() {
    await this.init();
  }

  init = async () => {
    const response = await AppService.get();
    if (response.ok) {
      this.setState({ appConfigs: response.data });
    }
  };

  render() {
    return (
      <AppLayout
        center={
          <Card shrinkedLeft>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%', flexShrink: 0, maxWidth: 300 }}>
                <Navigation direction="vertical" compact openGroups>
                  {this.props.user.superadmin && (
                    <React.Fragment>
                      <NavLink to={routes.admin.whiteLabel}>
                        <Trans>App Configs</Trans>
                        {` (${this.state.appConfigs.length})`}
                      </NavLink>

                      <NavGroup path="/admin/app-configs">
                        {this.state.appConfigs.map((appConfig) => (
                          <NavLink key={appConfig.name} to={`/admin/app-configs/${appConfig.name}/update`}>
                            {appConfig.name}
                          </NavLink>
                        ))}
                      </NavGroup>
                    </React.Fragment>
                  )}
                </Navigation>
              </div>
              <div style={{ width: '100%' }}>{this.props.children}</div>
            </div>
          </Card>
        }
      />
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
});

export const AdminAppConfigLayout = withRouter(connect(mapState)(Renderer));
