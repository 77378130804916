import variables from 'common/styles/variables.json';
import { RichTextEditor, Space, Title } from 'ui';
// TODO probably create some universal config
import config from './config.json';

const PrivacyPolicy = () => {
  return (
    <Space className="privacy-policy" direction="vertical" size={variables.spaceXl.value}>
      <Title level={1}>Privacy Policy</Title>
      <div className="privacy-policy__content">
        <RichTextEditor data={config.htmlContent} editable={false} />
      </div>
    </Space>
  );
};

export default PrivacyPolicy;
