import { type ReduxUser, type InvitationToken, type PublicEventContentObject } from 'models';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { HeaderImage, Space, Title } from 'ui';
import { SharedEventEnter } from './SharedEventEnter';
import { SharedEventInfo } from './SharedEventInfo';

type P = {
  invitationToken: InvitationToken;
  eventContentObject: PublicEventContentObject;
  viewer?: ReduxUser;
};

const SharedEventContent = ({ eventContentObject, invitationToken, viewer }: P) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size={variables.spaceLg.value}>
      <Title marginBottom={false}>{"You're invited!"}</Title>
      <HeaderImage
        alt={t('Event cover image')}
        src={eventContentObject.event.coverPhoto?.url ?? images.default.eventCoverPhoto}
        size="small"
        borderBottom={false}
      />
      <SharedEventInfo eventContentObject={eventContentObject} />
      <SharedEventEnter invitationToken={invitationToken} eventContentObject={eventContentObject} viewer={viewer} />
    </Space>
  );
};

export default SharedEventContent;
