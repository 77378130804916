import * as React from 'react';

import { Box } from '../box/Box';
import { Icon } from '../icon';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { images } from 'common/utils';

export function Community(props) {
  const { community } = props;

  function onClick(e) {
    const tagName = e.target.tagName.toLowerCase();
    if (tagName !== 'span' && tagName !== 'button') {
      props.onClick(e);
    }
  }

  return (
    <ThemeConsumer>
      {(theme) => (
        <Box onClick={onClick} className="org-preview-community">
          <Box flexDirection="row" alignItems="flex-start" className="org-preview-community__wrapper">
            <div className="org-preview-community__image">
              {community.white_profile_photo ? (
                <img src={community.white_profile_photo?.url} alt="" />
              ) : (
                <img src={community.cover_photo?.url ?? images.default.communityCoverPhoto} alt="" />
              )}
            </div>
            <Box flexDirection="row" className="item-body org-preview-community__body">
              {community.short_name ? (
                <Text size="24px" lineHeight="1" color="#455a64" weight="bold" component="div">
                  {community.short_name}
                </Text>
              ) : null}
              <Box>
                <Text size="14px" lineHeight="1.2" color="#455a64" weight="300" component="div">
                  {community.name}
                </Text>
              </Box>
              <Box className="item-body org-preview-community__icon">
                <Icon name="arrow-open-right" size={36} color="#c1c9d6" />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </ThemeConsumer>
  );
}
