import cn from 'classnames';
import type React from 'react';
import { Item, Select, type Form } from '../../';
import { CloseLineIcon, TagLineIcon } from '../../Icons';

type InputType = React.ComponentProps<typeof Select>;

type ItemType = Omit<React.ComponentProps<typeof Form.Item>, 'label'> & {
  label?: string;
};

type P = {
  item?: ItemType;
  input?: InputType;
};

const defaultItemProperties: ItemType = {};
const defaultInputProperties: InputType = {};

const TagsInput = ({
  item = defaultItemProperties,
  input: { className, emptyProps, ...restInput } = defaultInputProperties,
}: P) => {
  return (
    <Item {...item}>
      <Select
        removeIcon={<CloseLineIcon />}
        className={cn('tags-input', className)}
        mode="multiple"
        allowClear
        optionFilterProp="label"
        emptyProps={{ image: <TagLineIcon />, ...emptyProps }}
        {...restInput}
      />
    </Item>
  );
};

export default TagsInput;
