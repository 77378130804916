import * as React from 'react';

export class Box extends React.Component {
  static defaultProps = {
    alignContent: 'stretch',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
  };

  render() {
    const { className, children, style, ...styleProps } = this.props;
    return (
      <div
        className={className || ''}
        style={{
          display: 'flex',
          ...styleProps,
          ...style,
        }}
        onClick={this.props.onClick}
        id={this.props?.id}
      >
        {children}
      </div>
    );
  }
}
