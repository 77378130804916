import { dispatch } from 'old/store';
import { useHistory, useLocation } from 'react-router-dom';
import { Consents } from 'features/consents';
import { Footer, Header } from 'features/onboarding';
import { LandingLayout } from 'ui';

export type ConsentsLocationState = {
  referrer?: string;
  originalState?: unknown;
};

const PageConsents = () => {
  const { state } = useLocation<ConsentsLocationState | undefined>();
  const { replace } = useHistory();

  const onAccept = () => {
    replace(state?.referrer ?? '/', state?.originalState);
  };

  const onReject = async () => {
    await dispatch.session.logOut();
  };

  return (
    <LandingLayout
      header={<Header items={[]} />}
      contentRight={<Consents onAccept={onAccept} onReject={onReject} />}
      footer={<Footer />}
    />
  );
};

export default PageConsents;
