import cn from 'classnames';
import { type ReduxUser } from 'models';
import { type Todo } from '../../../types';
import { TodosWidgetList } from '../TodosWidgetList';

type P = {
  items: Todo[];
  isFetching: boolean;
  hasMoreData?: boolean;
  fetchNextPage: () => unknown;
  viewer: ReduxUser;
};

const FeaturedTodoList = ({ items, viewer, ...rest }: P) => {
  const hasMultipleTodos = items.length > 1;

  return (
    <TodosWidgetList
      type="featured"
      items={items}
      {...rest}
      rowProps={{
        className: cn('todos-widget-list-featured', { 'todos-widget-list-featured--multiple': hasMultipleTodos }),
      }}
      todoColProps={{
        span: hasMultipleTodos ? 22 : 24,
        className: cn({ 'todos-widget-list-featured--multiple__col': hasMultipleTodos }),
      }}
      loadingColProps={{ span: 4, className: 'todos-widget-list-featured__loading' }}
      viewer={viewer}
    />
  );
};

export default FeaturedTodoList;
