import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Trans } from 'react-i18next';

import { Loader } from '../../atoms/loader';
import { Text } from '../../atoms/text';
import { InViewport } from '../../atoms/in-viewport';
import { Notification } from './Notification';
import { Result, Button } from 'ui';

class Renderer extends React.Component {
  async componentWillMount() {
    await this.props.getBadges();
  }

  componentWillUnmount() {
    this.props.clearLocalNotifications();
  }

  static defaultProps = {
    limit: 20,
  };

  state = {
    hasMoreNotifications: true,
    failedToLoad: false,
  };

  getNextNotifications = async () => {
    if (!this.props.loading) {
      const { notifications } = this.props;
      const lastNotification = notifications[notifications.length - 1];

      const resp = await this.props.getNotifications({
        limit: this.props.limit,
        cursor: lastNotification ? lastNotification.id : undefined,
      });

      if (resp.ok) {
        this.setState({ hasMoreNotifications: resp.data.length > 0 });
      } else {
        this.setLoadErrorStatus();
      }
    }
  };

  setLoadErrorStatus = (isError = true) => {
    this.setState({ hasMoreNotifications: !isError, failedToLoad: isError });
  };

  onRequestReadNotification = async (notification) => {
    await this.props.readNotification(notification);
  };

  render() {
    return (
      <TransitionGroup component={null}>
        <CSSTransition key="no-notifications" timeout={400} classNames="notification">
          <React.Fragment>
            {this.props.notifications.length === 0 && !this.props.loading ? (
              <Text component="div" style={{ padding: '0 20px 20px' }}>
                <Trans>You have no unread notifications.</Trans>
              </Text>
            ) : null}
          </React.Fragment>
        </CSSTransition>
        {this.props.notifications.map((notification) => {
          return (
            <CSSTransition key={notification.id} timeout={400} classNames="notification">
              <Notification
                key={notification.id}
                {...notification}
                onRequestReadNotification={this.onRequestReadNotification}
              />
            </CSSTransition>
          );
        })}
        <CSSTransition key="loader" timeout={400} classNames="notification">
          <React.Fragment>
            {this.props.loading ? (
              <Loader size={30} />
            ) : this.state.hasMoreNotifications ? (
              <InViewport size={30} key="notification-list-viewport" onEnter={this.getNextNotifications} />
            ) : this.state.failedToLoad ? (
              <Result
                status="error"
                subTitle={<Trans>Sorry, something went wrong.</Trans>}
                extra={
                  <Button type="primary" onClick={() => this.setLoadErrorStatus(false)}>
                    Retry
                  </Button>
                }
              />
            ) : null}
          </React.Fragment>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

const mapState = (state, props) => ({
  notifications: select.notifications.get(state),
  loading: state.loading.effects.notifications.getAsync,
});

const mapDispatch = (dispatch, props) => ({
  getBadges: () => dispatch.notifications.getBadgesAsync({}),
  clearLocalNotifications: () => dispatch.notifications.clearLocalResult(),
  getNotifications: (params) => dispatch.notifications.getAsync(params),
  readNotification: (id) => dispatch.notifications.readAsync(id),
});

export const NotificationsList = connect(mapState, mapDispatch)(Renderer);
