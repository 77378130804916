import Hls from 'hls.js';
import type Config from 'hls.js';
import { createRef, useEffect, type VideoHTMLAttributes } from 'react';
import { VideoPlayer } from '../VideoPlayer';

type P = {
  video: Omit<VideoHTMLAttributes<HTMLVideoElement>, 'src'> & {
    src: string;
  };
  hlsConfig?: Config;
};

const HlsVideoPlayer = ({ hlsConfig, video: { src, autoPlay, ...props } }: P) => {
  const playerReference = createRef<HTMLVideoElement>();

  useEffect(() => {
    let hls: Hls;

    function _initPlayer() {
      if (hls !== undefined && hls !== null) {
        hls.destroy();
      }

      const newHls = new Hls({
        enableWorker: false,
        ...hlsConfig,
      });

      if (playerReference.current !== null) {
        newHls.attachMedia(playerReference.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (autoPlay) {
            playerReference?.current?.play().catch(() => {
              console.log('Unable to autoplay prior to user interaction with the dom.');
            });
          }
        });
      });

      newHls.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    }

    // Check for Media Source support
    if (Hls.isSupported()) {
      _initPlayer();
    }

    return () => {
      if (hls !== null) {
        hls.destroy();
      }
    };
  }, [autoPlay, hlsConfig, playerReference, src]);

  // If Media Source is supported, use HLS.js to play video
  if (Hls.isSupported()) return <VideoPlayer ref={playerReference} {...props} />;

  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  return <VideoPlayer ref={playerReference} src={src} autoPlay={autoPlay} {...props} />;
};

export default HlsVideoPlayer;
