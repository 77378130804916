import { Table, type TableProps } from 'ui';

type P<T> = TableProps<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
const FileManager = <RecordType extends object>({ ...props }: P<RecordType>) => {
  return <Table<RecordType> {...props} />;
};

export default FileManager;
