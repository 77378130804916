import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { type NewPasswordLocationState } from 'pages';

export const useExpiredPassword = <T>() => {
  const history = useHistory<NewPasswordLocationState>();
  const { state } = useLocation<T | undefined>();

  return useMemo(
    () => ({
      onExpiredPassword(email: string) {
        history.push({ pathname: `/new-password/`, state: { ...state, email } });
      },
    }),
    [history, state],
  );
};
