import * as React from 'react';

import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { select } from '@rematch/select';

import { ThemeConsumer } from '../theme';
import { ShareCampaign } from './ShareCampaign';
import { Button } from '../button';
import { Icon } from '../icon';
import { Text } from '../text';
import { Modal } from '../modal';
import { Box } from '../box';

import numbro from 'numbro';

const intervals = {
  'one-time': <Trans>one time</Trans>,
  monthly: <Trans>monthly</Trans>,
  quarterly: <Trans>quarterly</Trans>,
  annually: <Trans>annually</Trans>,
};

class Renderer extends React.Component {
  renderShareCampaign = (theme) => (
    <Modal
      showFooter={false}
      trigger={(ref) => (
        <Button ref={ref} size="md" outline color={theme.color.brand} type="button" title={<Trans>Share</Trans>} />
      )}
    >
      {(modal) => (
        <div style={{ maxWidth: 500 }}>
          <ShareCampaign campaign={this.props.campaign} viewer={this.props.user} onSuccess={() => modal.closeModal()} />
        </div>
      )}
    </Modal>
  );

  render() {
    const { organization } = this.props.campaign;
    return (
      <ThemeConsumer>
        {(theme) => (
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: 20 }}>
              <Icon name="donation-success" size={100} color={theme.color.brand} />
            </div>
            <div style={{ marginBottom: 20 }}>
              <Text size="22px">
                <Trans>
                  {'Your '}
                  <span style={{ color: theme.color.brand }}>{intervals[this.props.interval]}</span>
                  {' donation'}
                  <br />
                  of{' '}
                  <span style={{ color: theme.color.brand }}>
                    {numbro(this.props.amount).formatCurrency({ mantissa: 2, thousandSeparated: true })}
                  </span>{' '}
                  is a success!
                </Trans>
              </Text>
            </div>
            <div style={{ maxWidth: 400, margin: '20px auto' }}>
              <Text size="14px">
                <Trans
                  defaults="Thank you for your generous gift and supporting our {{ name }} family"
                  values={{
                    name: organization.name,
                  }}
                />
              </Text>
            </div>
            {this.props.user ? (
              <div style={{ maxWidth: 400, margin: '0 auto' }}>
                <Text size="13px" color={theme.color.lightGray}>
                  <Trans>
                    To cancel or change your recurring donation to the BigHeart Philanthropy, please email{' '}
                    <a href={`mailto:${theme.abuse_reports_email}`} style={{ color: 'inherit' }}>
                      {theme.abuse_reports_email}
                    </a>
                  </Trans>
                </Text>
              </div>
            ) : null}

            <Box justifyContent="center" alignItems="center">
              <div style={{ marginTop: 10 }}>{this.renderShareCampaign(theme)}</div>
            </Box>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
});

export const DonationSuccessInfo = withTranslation()(connect(mapState)(Renderer));
