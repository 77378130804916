import { type Campaign, type Price, type ReduxUser } from 'models';
import { useMemo, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import variables from 'common/styles/variables.json';
import { countries, states } from 'common/utils';
// TODO this is wrong
import { type Option } from 'features/programs/subscribe/types';
import { Col, Form, message, ModalFormActions, Row, SelectInput, TextInput } from 'ui';
import { useStripeDonation, useStripeDonationPayment } from '../../../queries';

type BillingInformationFormData = {
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  apt?: string;
  city: string;
  zip: string;
  country: string;
  state?: string;
};

type P = {
  campaign: Campaign;
  viewer?: ReduxUser;
  price: Price;
};

const StripeDonationBillingInformationForm = ({ campaign, viewer, price }: P) => {
  const [form] = Form.useForm<BillingInformationFormData>();
  const { t } = useTranslation();
  const { mutate: getStripeData, isLoading: isLoadingStripeData } = useStripeDonation();
  const { mutate: getPayment, isLoading: isLoadingPayment } = useStripeDonationPayment();
  const [googleRecaptchaKey, setGoogleRecaptchaKey] = useState<string | undefined>();

  const initialValues = {
    firstName: viewer?.first_name,
    lastName: viewer?.last_name,
    email: viewer?.email,
    street: viewer?.street,
    apt: viewer?.apt,
    city: viewer?.city,
    zip: viewer?.zip,
    country: viewer?.country,
    state: viewer?.state,
  };

  // TODO possibly move to page
  const location = useLocation();
  const { pathname } = location;

  const onFinish = (data: BillingInformationFormData) => {
    if (googleRecaptchaKey && price) {
      getStripeData(
        {
          recaptchaKey: googleRecaptchaKey,
          donationAmount: price.amount,
          payerInformation: data,
          campaignId: campaign.id,
          priceId: price.stripeId,
        },
        {
          onSuccess(data) {
            getPayment(
              { id: data.id, pathname: pathname.slice(1) },
              {
                onSuccess(paymentData) {
                  window.location.href = paymentData.checkoutUrl;
                },
              },
            );
          },
        },
      );
    } else {
      message.error('There was a problem processing your payment');
    }
  };

  const countryOptions: Option[] = useMemo(
    () => countries.map((country) => ({ label: country.name, value: country.code })),
    [],
  );

  const stateOptions: Option[] = useMemo(() => states.map((state) => ({ label: state.name, value: state.code })), []);

  return (
    <Form<BillingInformationFormData> onFinish={onFinish} form={form} initialValues={initialValues}>
      <GoogleReCaptcha onVerify={setGoogleRecaptchaKey} action="donation_create" />
      <Row item={{ gutter: variables.spaceMd.value }}>
        <Col item={{ span: 12 }}>
          <TextInput
            item={{
              name: 'firstName',
              label: 'First name',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 12 }}>
          <TextInput
            item={{
              name: 'lastName',
              label: 'Last name',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <TextInput
            item={{
              name: 'email',
              label: 'E-mail',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 18 }}>
          <TextInput
            item={{
              name: 'street',
              label: 'Street',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 6 }}>
          <TextInput
            item={{
              name: 'apt',
              label: 'Apt',
            }}
          />
        </Col>
        <Col item={{ span: 18 }}>
          <TextInput
            item={{
              name: 'city',
              label: 'City',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 6 }}>
          <TextInput
            item={{
              name: 'zip',
              label: 'Zip',
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: t('This field is required'),
                },
              ],
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              label: 'Country',
              name: 'country',
              'data-test-id': 'select-country',
              rules: [
                {
                  required: true,
                  message: t('This field is required'),
                },
              ],
            }}
            input={{
              showSearch: true,
              placeholder: t('Country'),
              options: countryOptions,
              getOptionLabel: (option) => option.label,
              getOptionValue: (option) => option.value,
            }}
          />
        </Col>

        <Form.Item noStyle dependencies={['country']}>
          {({ getFieldValue }) => {
            if (getFieldValue('country') !== 'US') {
              return null;
            }

            return (
              <Col item={{ span: 24 }}>
                <SelectInput
                  item={{
                    label: 'State',
                    name: 'state',
                    'data-test-id': 'select-state',
                    rules: [
                      {
                        required: true,
                        message: t('This field is required'),
                      },
                    ],
                  }}
                  input={{
                    showSearch: true,
                    placeholder: t('State'),
                    options: stateOptions,
                    getOptionLabel: (option) => option.label,
                    getOptionValue: (option) => option.value,
                  }}
                />
              </Col>
            );
          }}
        </Form.Item>
      </Row>
      <ModalFormActions
        submit={{
          children: 'Continue to donation page',
          loading: isLoadingPayment || isLoadingStripeData,
        }}
      />
    </Form>
  );
};

export default StripeDonationBillingInformationForm;
