import { transformError, transformResponse } from '../common/recoil/queries';
import { ID } from '../common/types';
import { Message } from '../models';
import { createChatroomMessage } from '../services';
import { MessageDataObject } from '../services/data-objects';
import { ChatroomMessage } from '../types';

const sendMessage = async (data: ChatroomMessage, chatroomId: ID) => {
  try {
    const responseMessage = await createChatroomMessage(chatroomId, data);
    return transformResponse<MessageDataObject, Message>(
      responseMessage,
      (data: MessageDataObject) => new Message(data),
    );
  } catch (error) {
    return transformError(error);
  }
};

export default sendMessage;
