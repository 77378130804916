import { type ReduxUser } from 'models';
import { useEventActions } from 'features/events/hooks';
import { type Event } from 'features/todos/types';
import { Button, CheckCircleFillIcon, DownLine, Dropdown } from 'ui';

type P = {
  data: Event;
  viewer: ReduxUser;
};

const AcceptedEventItem = ({ data, viewer }: P) => {
  const { handleJoinStatusButtonDropdown, isAccepting, isRejecting } = useEventActions({ data, viewer });

  return (
    <Dropdown menu={handleJoinStatusButtonDropdown()} trigger={['click']}>
      <Button
        size="small"
        type="default"
        loading={isAccepting || isRejecting}
        icon={<CheckCircleFillIcon type="success" />}
      >
        Going
        <DownLine />
      </Button>
    </Dropdown>
  );
};

export default AcceptedEventItem;
