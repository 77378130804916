import { useEffect, useState, useCallback } from 'react';

import variables from 'common/styles/variables.json';
import { Button, message, Row, Spin, Title, Col } from 'ui';

import { generateWithingsCodeRequestUrl, linkWithings, unlinkWithings } from './services/api';

type P = {
  user: any;
  search?: string;
  onRequestReloadUser: () => Promise<void>;
};

const WithingsSync = ({ user, search, onRequestReloadUser }: P) => {
  const [loading, setLoading] = useState(false);
  const withingsCodeRequestUrl = generateWithingsCodeRequestUrl();

  const isLinkedWithings = () => {
    return user?.withings?.user_id;
  };

  const onUnlinkWithings = async () => {
    setLoading(true);

    const response = await unlinkWithings(user.withings.user_id);
    if (response.ok) {
      await onRequestReloadUser();
    } else {
      message.error('Something went wrong');
    }

    setLoading(false);
  };

  const renderUnlinkWithings = () => {
    return (
      <Button onClick={onUnlinkWithings} size="large" type="primary" danger>
        Unlink my Withings Account
      </Button>
    );
  };

  const renderLinkWithings = () => {
    return (
      <Button href={withingsCodeRequestUrl} size="large" type="primary">
        Link with my Withings Account
      </Button>
    );
  };

  const linkWithingsAccount = useCallback(async () => {
    if (search) {
      const query = new URLSearchParams(search);
      const code = query.get('code');

      if (code) {
        setLoading(true);

        const response = await linkWithings(code);
        if (response.ok) {
          await onRequestReloadUser();
        } else {
          message.error('Something went wrong');
        }

        setLoading(false);
      }
    }
  }, [search, onRequestReloadUser]);

  useEffect(() => {
    linkWithingsAccount();
  }, [linkWithingsAccount]);

  return (
    <Row item={{ gutter: [0, variables.spaceXs.value] }}>
      <Col item={{ span: 24 }}>
        <Title level={5}>Withings Sync</Title>
      </Col>
      <Col item={{ span: 24 }}>
        {loading ? <Spin size="large" /> : <>{isLinkedWithings() ? renderUnlinkWithings() : renderLinkWithings()}</>}
      </Col>
    </Row>
  );
};

export default WithingsSync;
