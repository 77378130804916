import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type ResponseErrorDataObject } from 'common/services/api';
import { type ResetPassword } from '../../models';

import { resetPassword } from '../../services';

export const useResetPasswordQuery = () =>
  useMutation<any, ApiClientResponseError<ResponseErrorDataObject>, ResetPassword, any>({
    mutationFn: async (data: ResetPassword) => resetPassword(data),
  });
