import * as React from 'react';

import './font.css';
import './assets/css/pba-codes.css';
import './assets/css/animation.css';
import './assets/pba-new/style.css';

// TODO this was needed to be able used in new components with TS `<HTMLElement, any>`
export const Icon = React.forwardRef<HTMLElement, any>((props, ref) => {
  const { className, name, size, color, style, ...htmlProps } = props;
  return (
    <i
      ref={ref}
      className={`icon-${props.name} ${props.className}`}
      style={{
        color: props.color,
        fontSize: props.size,
        ...style,
      }}
      {...htmlProps}
    />
  );
});

Icon.defaultProps = {
  color: 'inherit',
};
