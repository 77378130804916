import * as React from 'react';
import { Trans } from 'react-i18next';

import { ThemeConsumer } from '../../atoms/theme';
import { Text, H2 } from '../../atoms/text';
import { Box } from '../../atoms/box';
import { UserZoneLayout, TABS } from '../../layouts/UserZoneLayout';
import { HelpSideNav } from './HelpSideNav';

export function PageHelp() {
  return (
    <ThemeConsumer>
      {(theme) => (
        <UserZoneLayout navigation={<HelpSideNav />} contentId={TABS.help}>
          <Box className="page-help" id="help-content">
            <H2>
              <Trans>General inquiries</Trans>
            </H2>
            <div>
              <a href={`mailto:${theme.abuse_reports_email}`} className="help-link">
                {theme.abuse_reports_email}
              </a>
            </div>
            <H2>
              <Trans>Cancel Payment</Trans>
            </H2>
            <Text>
              {/*TODO add recurring donation to 'brand'*/}
              <Trans>To cancel or change your recurring donation, please email us at:</Trans>{' '}
              <a href={`mailto:${theme.abuse_reports_email}`} className="help-link">
                {theme.abuse_reports_email}
              </a>
            </Text>
            {theme.screens.help_screen.map((link) => (
              <div key={link.title}>
                <H2>
                  <Trans>{link.title}</Trans>
                </H2>
                <a href={link.link} target="_blank" rel="noopener noreferrer" className="help-link">
                  <Trans>{link.description}</Trans>
                </a>
              </div>
            ))}
          </Box>
        </UserZoneLayout>
      )}
    </ThemeConsumer>
  );
}
