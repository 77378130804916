import { type EventType } from 'models';
import type React from 'react';

import variables from 'common/styles/variables.json';

import { Col, Row } from 'ui';
import CardOption from './CardOption';

type P = {
  options: EventType[];
  onChange: (eventType: EventType) => void;
};

const TypeStepEventForm: React.FC<P> = ({ options, onChange }) => (
  <Row item={{ gutter: [variables.spaceMd.value, variables.spaceMd.value], 'data-test-id': 'event_modal-event-type' }}>
    {options.includes('conference') && (
      <Col item={{ span: 24 }}>
        <CardOption
          type="conference"
          title="Video Conference"
          description={['Up to 64 participants total', 'Interactive mode: all can share camera and audio']}
          onChange={onChange}
        />
      </Col>
    )}
    {options.includes('webinar') && (
      <Col item={{ span: 24 }}>
        <CardOption
          type="webinar"
          title="Webinar/Webcast"
          description={[
            'Up to 64 admins/presenters with 1000 participants total',
            'Presentation mode: Attendees can view, hear, chat, etc.',
          ]}
          onChange={onChange}
        />
      </Col>
    )}
    {options.includes('telehealth_visit') && (
      <Col item={{ span: 24 }}>
        <CardOption
          type="telehealth_visit"
          title="Telehealth Visit"
          description={['Up to 64 participants total', 'Interactive mode for members of Medical Communities']}
          onChange={onChange}
        />
      </Col>
    )}
  </Row>
);

export default TypeStepEventForm;
