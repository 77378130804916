import { Profile } from '../common/models';
import { ID } from '../common/types';
import { Chatroom } from '../models';

// TODO this should be somewhere specific for project

export const canManageChatroomMembers = (profile: Profile, chatroomsData: Chatroom): boolean =>
  profile._id === chatroomsData.author && chatroomsData.users.length > 2;

export const canRenameChatroom = (profile: Profile, chatroomsAuthorId: ID): boolean =>
  profile._id === chatroomsAuthorId;

export const canDeleteChatroom = (profile: Profile, chatroomsAuthorId: ID): boolean =>
  profile._id === chatroomsAuthorId;
