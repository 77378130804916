import * as React from 'react';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';

import { Box } from '../box';
import { Button } from '../button';
import { Icon } from '../icon';
import { SOCIAL_NETWORKS, polling, openPopup } from '../../utils/share';
import { Text, H2 } from '../text';
import { ThemeConsumer } from '../theme';

export class Renderer extends React.Component {
  componentDidMount() {
    window.addEventListener('blur', this.onBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.onBlur);
  }

  onBlur = () => {
    clearTimeout(this.timeout);
  };

  onSocialShare = (action) => {
    const socialNetwork = SOCIAL_NETWORKS.find((sn) => sn.name.toLowerCase() === action);
    if (!socialNetwork) return;

    const text = this.props.t(
      `I just donated to ${this.props.campaign.organization.name}’s campaign. Join me in supporting this great cause!`,
    );
    const redirect_uri = `${window.location.origin}/donate/${this.props.campaign.id}`;
    const url = socialNetwork.generateLink({ text, redirect_uri });

    if (action === 'email') {
      if (socialNetwork.alert) {
        this.timeout = setTimeout(() => {
          socialNetwork.alert();
        }, 500);
      }

      window.location.href = url;
    } else {
      polling(openPopup(url, socialNetwork.domain), null, socialNetwork.domain);
    }
  };

  renderOtherNetworks = (theme) => (
    <ThemeConsumer>
      {(theme) => (
        <Box style={{ marginBottom: 5 }}>
          {SOCIAL_NETWORKS.map((socialNetwork) => (
            <Box
              key={socialNetwork.name.toLocaleLowerCase()}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              style={{ padding: '5px 0', borderBottom: '1px solid #eceff1' }}
            >
              <Box flexDirection="row" alignItems="center">
                <div style={{ width: 30 }}>
                  <Icon name={socialNetwork.icon_name} color={socialNetwork.icon_color} size={16} />
                </div>
                <Text color={theme.color.gray} size="15px">
                  {socialNetwork.name}
                </Text>
              </Box>
              <Box>
                <div style={{ width: 100 }}>
                  <Button
                    block
                    type="button"
                    color={theme.color.brand}
                    onClick={() => this.onSocialShare(socialNetwork.name.toLocaleLowerCase())}
                    size="sm"
                    textColor="white"
                    title="Share"
                  />
                </div>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </ThemeConsumer>
  );

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <div style={{ padding: 15 }}>
            <Box justifyContent="center" alignItems="center">
              <H2 size="22px" style={{ marginTop: 0, marginBottom: 20 }}>
                <Trans>Share Campaign</Trans>
              </H2>

              {this.renderOtherNetworks(theme)}
            </Box>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export const ShareCampaign = withTranslation()(Renderer);
