import { AppLayout } from 'old/layouts/AppLayout';
import { useParams } from 'react-router-dom';
import { ChannelsCampaigns } from 'features/channels';
import { useGetPathToEntityDetail } from '../../hooks';

const PageChannelsCampaigns = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const getPathToEntityDetail = useGetPathToEntityDetail();

  return (
    <AppLayout
      center={
        <ChannelsCampaigns
          getPathToCampaignDetail={(id) => getPathToEntityDetail(id, { entityType: 'campaign', organizationId })}
          organizationId={organizationId}
        />
      }
    />
  );
};

export default PageChannelsCampaigns;
