import { generateQueryKey } from 'query';

export * from './mutations';
export * from './useGenders';
export * from './useProfessions';
export * from './useEducationsLevel';
export * from './useInterests';
export * from './useProfileQuery';
export * from './useCalendarSyncQuery';

const featureKey = 'user-profile';

export const getEducationLevelsQueryKey = () => generateQueryKey([featureKey, 'education-levels']);

export const getProfessionsQueryKey = () => generateQueryKey([featureKey, 'professions']);

export const getGendersQueryKey = () => generateQueryKey([featureKey, 'genders']);

export const getInterestsQueryKey = () => generateQueryKey([featureKey, 'interests']);

export const getProfileQueryKey = () => generateQueryKey([featureKey, 'profile']);

export const getCalendarSyncQueryKey = () => generateQueryKey([featureKey, 'calendars']);
