import { isPracticeEnabled } from '../../utils';
import { ID } from '../types';

const routes = {
  users: {
    login: '/users/login',
    logout: '/users/logout',
    profile: '/profile',
  },
  chatrooms: {
    find: '/chatrooms',
    create: '/chatrooms',
    get: (chatroom: string) => `/chatrooms/${chatroom}`,
    patch: (chatroom: string) => `/chatrooms/${chatroom}`,
    delete: (chatroom: string) => `/chatrooms/${chatroom}`,
    status: (chatroom: string) => `/chatrooms/${chatroom}/status`,
    members: {
      add: (chatroom: string) => `/chatrooms/${chatroom}/members`,
      remove: (chatroom: string, member: string) => `/chatrooms/${chatroom}/members/${member}`,
    },
    messages: {
      create: (chatroom: string) => `/chatrooms/${chatroom}/messages`,
      find: (chatroom: string) => `/chatrooms/${chatroom}/messages`,
    },
    media: {
      create: '/media',
      getFile: (media: string) => `/media/resource/${media}`,
      getAvatarFile: (user: string, type: string, alt: string) => `/media/resource/${alt}/${type}/${user}`,
      alt: (media: string, alt: string) => `/media/resource/${media}/${alt}`,
      delete: (media: string) => `/media/${media}`,
      get: (media: string) => `/media/${media}`,
      getMetaData: (url: string) => `/page-meta?url=${url}`,
    },
  },
  practices: {
    find: '/practices',
    members: (practice: ID | null) => (practice && isPracticeEnabled() ? `/practices/${practice}/members` : '/users'),
  },
};

export default routes;
