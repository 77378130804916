import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

export const useNavigateToJoinedCommunities = () => {
  const { push } = useHistory();

  return () => {
    push(routes.community.joined);
  };
};
