import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { AppLayout } from '../../layouts/AppLayout';
import { ThemeConsumer } from '../../atoms/theme';
import { Box } from '../../atoms/box';
import { Loader } from '../../atoms/loader';
import { OrganizationPreview } from '../../atoms/organization-preview';
import { Card } from 'ui';

class Renderer extends React.Component {
  componentDidMount() {
    this.props.getOrganization(this.props.organizationId);
  }

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <AppLayout
            center={
              <Box flexDirection="row" justifyContent="center">
                {this.props.organization ? (
                  <Card shrinked>
                    <OrganizationPreview
                      data={this.props.organization}
                      loading={this.props.loadingOrganization}
                      onJoinNow={() => this.props.joinOrganization(this.props.organizationId)}
                    />
                  </Card>
                ) : (
                  <Loader />
                )}
              </Box>
            }
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  organization: select.organizations.find(state, props.match.params.organizationId),
  organizationId: props.match.params.organizationId,
  loadingOrganization: state.loading.effects.organizations.findAsync,
});

const mapDispatch = (dispatch, props) => ({
  getOrganization: (id) => dispatch.organizations.findAsync(id),
  joinOrganization: (id) => dispatch.organizations.joinAsync(id),
});

export const PageOrganizationAbout = connect(mapState, mapDispatch)(Renderer);
