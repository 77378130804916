import { type ComponentProps } from 'react';
import { ListCheckIcon } from 'ui';
import { type PollTodo } from '../../../../types';
import { BaseTodoWidgetItem } from '../BaseTodoWidgetItem';

type P = {
  type?: ComponentProps<typeof BaseTodoWidgetItem>['type'];
  todo: PollTodo;
  className?: string;
};

const PollTodoWidgetItem = ({ type, todo, className }: P) => {
  return (
    <BaseTodoWidgetItem
      type={type}
      className={className}
      path={todo.path}
      icon={<ListCheckIcon />}
      title="Complete poll"
      description={todo.poll.title}
      additionalDescription={todo.poll.description}
      mode="by"
      time={todo.endTime}
    />
  );
};

export default PollTodoWidgetItem;
