import { BaseUser } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { type ListUserDataObject } from 'models/service';
import { getMembers } from '../services/getMembers';
import { getCommunityMembersKey } from './';

type P = {
  communityId: string;
  pageSize: number;
};

export const useMembersQuery = ({ communityId, pageSize }: P) =>
  useInfiniteQuery<BaseUser[], ApiClientResponseError<DefaultResponseErrorDataObject>>(
    getCommunityMembersKey({ communityId, pageSize }),
    async ({ pageParam: pageParameter = undefined }: QueryFunctionContext<QueryKey, string>) => {
      const { data: members } = await getMembers(communityId, {
        limit: pageSize,
        cursor: pageParameter,
      });

      return members.map((member: ListUserDataObject) => new BaseUser(member));
    },
    {
      getNextPageParam: (lastPage) => (lastPage.length < pageSize ? undefined : lastPage[lastPage.length - 1]?.id),
      useErrorBoundary: true,
    },
  );
