import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans, withTranslation } from 'react-i18next';

import { Icon } from '../../atoms/icon';
import { Text, H3 } from '../../atoms/text';
import { Avatar, Link } from 'ui';
import { Loader } from '../../atoms/loader';
import { Menu, MenuItem, MenuList, MenuButton } from '../../atoms/menu';
import { CampaignsLayout } from '../../layouts/CampaignsLayout';
import numbro from 'numbro';
import { images } from 'common/utils';

class Renderer extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  renderSubscriptionContextMenu(donation) {
    return (
      <Menu>
        <MenuButton style={{ backgroundColor: 'transparent', border: 0, outline: 'none' }}>
          <Icon name="menu" />
        </MenuButton>
        <MenuList>
          <MenuItem
            onSelect={async () => {
              if (await window.confirm(this.props.t('Do you wish to cancel this subscription?'))) {
                this.props.cancelRecurringDonation(donation);
              }
            }}
          >
            <Trans>Cancel donation</Trans>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  getIntervalLabel(interval) {
    switch (interval) {
      case 'one-time':
        return <Trans>One Time</Trans>;
      case 'monthly':
        return <Trans>Monthly</Trans>;
      case 'quarterly':
        return <Trans>Quarterly</Trans>;
      case 'annually':
        return <Trans>Annually</Trans>;
      default:
        console.log(interval);
    }
  }

  renderRow = (donation) => {
    if (!donation.campaign) {
      return <div />;
    }

    return (
      <div key={donation.id} style={{ borderBottom: '1px solid #ECEFF1', padding: '10px 0' }}>
        <div style={{ display: 'flex' }}>
          <div>
            <Avatar
              size={35}
              photo={donation.campaign.cover_photo?.url ?? images.default.campaignCoverPhoto}
              name={donation.campaign.name}
            />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>
            <Link
              style={{ textDecoration: 'inherit' }}
              to={`/campaigns/listing/${donation.campaign.community.organization.id}/${donation.campaign.id}`}
            >
              <div>
                <Text weight="600" lineHeight="18px">
                  {donation.campaign.name}
                </Text>
              </div>
              <div>
                <Text size="12px">
                  {donation.braintree_subscription_status === 'active' ? <Trans>Started in</Trans> : null}{' '}
                  {this.props.t('dateFormatShortMonthDayYearTime', { date: new Date(donation.created_at) })}
                </Text>
                {donation.status === 'failed' || donation.status === 'deleted' ? (
                  <Text
                    style={{ marginLeft: 20, backgroundColor: '#fc4747', borderRadius: '3px', padding: '0 4px' }}
                    color="white"
                  >
                    {donation.status}
                  </Text>
                ) : null}
              </div>
            </Link>
          </div>
          <div style={{ marginRight: 15, display: 'flex', justifyContent: 'center' }}>
            {donation.braintree_subscription_status === 'active' && this.renderSubscriptionContextMenu(donation)}
          </div>
          <div style={{ textAlign: 'right', width: 80 }}>
            <div>
              <H3
                style={{
                  paddingTop: 0,
                  color: donation.braintree_subscription_status !== 'active' ? '#90A4AE' : 'inherit',
                }}
              >
                {donation.amount
                  ? numbro(donation.amount).formatCurrency({ mantissa: 2, thousandSeparated: true })
                  : null}
              </H3>
            </div>
            <div>
              <Text size="12px">{this.getIntervalLabel(donation.interval)}</Text>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const displayLoader = !!this.props.loadingCollection;
    const campaignsByOrganization = this.props.campaignsByOrganization;

    return (
      <CampaignsLayout campaignsByOrganization={campaignsByOrganization} displayLoader={displayLoader}>
        {this.props.fetching ? (
          <Loader />
        ) : (
          <div>
            <div>
              <div style={{ marginBottom: 15 }}>
                <Text style={{ textTransform: 'uppercase' }} size="12px" weight="600">
                  <Trans>Active subscriptions</Trans>
                </Text>
              </div>

              {this.props.activeDonations.length > 0 ? (
                this.props.activeDonations.map(this.renderRow)
              ) : (
                <Text style={{ marginBottom: 15 }} component="div">
                  <Trans>You have no active subscriptions.</Trans>
                </Text>
              )}
            </div>
            <div>
              <div style={{ marginBottom: 5 }}>
                <Text style={{ textTransform: 'uppercase' }} size="12px" weight="600">
                  <Trans>History</Trans>
                </Text>
              </div>
              {this.props.historyDonations.length > 0 ? (
                this.props.historyDonations.map(this.renderRow)
              ) : (
                <Text style={{ marginTop: 15 }} component="div">
                  <Trans>You have no history donations.</Trans>
                </Text>
              )}
            </div>
          </div>
        )}
      </CampaignsLayout>
    );
  }
}

const mapState = (state, props) => ({
  historyDonations: select.donations.historyDonations(state),
  activeDonations: select.donations.activeDonations(state),
  fetching: state.loading.effects.donations.getDonationsForCurrentUser,
  campaignsByOrganization: select.campaigns.getBy(state, 'organization'),
  loadingCollection: state.loading.effects.campaigns.getAsync,
});

const mapDispatch = (dispatch, props) => ({
  fetch: () => dispatch.donations.getDonationsForCurrentUser(),
  cancelRecurringDonation: (donation) => dispatch.donations.cancelRecurringDonation(donation),
});

export const PageDonationsHistory = withTranslation()(connect(mapState, mapDispatch)(Renderer));
