import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { Card, Link, Title, Breadcrumb, Skeleton } from 'ui';
import { ProgramsSlider } from '../components';
import { useOrganizationIdForRequests } from '../hooks';
import { useOrganizationsQuery } from '../queries';
import { AllPrograms } from './AllPrograms';

type P = {
  getPathToProgramDetail: (id: string) => string;
  navigateToJoinedPrograms: () => void;
  organizationId: string;
};

const ChannelsPrograms = ({ getPathToProgramDetail, navigateToJoinedPrograms, organizationId }: P) => {
  const { t } = useTranslation();
  // TODO Solve default organization logic in better way - perhaps BE update?
  const { data: channelsOrganizations, isLoading: isChannelsOrganizationLoading } = useOrganizationsQuery();

  const organizationIdForRequests = useOrganizationIdForRequests(organizationId, channelsOrganizations);

  const breadcrumbRoutes = [
    {
      path: routes.channels.index,
      breadcrumbName: t('Channels'),
    },
    {
      path: routes.channels.organizationChannel(organizationId, 'programs'),
      breadcrumbName: t('Programs'),
    },
  ];

  return (
    <>
      <Breadcrumb
        routes={breadcrumbRoutes.map((route) => ({ ...route, breadcrumbName: t(route.breadcrumbName) }))}
        itemRender={({ path, breadcrumbName }: { path: string; breadcrumbName: string }) => {
          return <Link to={path}>{breadcrumbName}</Link>;
        }}
      />
      <Title>{t('Programs')}</Title>
      {isChannelsOrganizationLoading || !channelsOrganizations ? (
        <Skeleton active paragraph={{ rows: 16 }} />
      ) : (
        <>
          <ProgramsSlider
            getPathToProgramDetail={getPathToProgramDetail}
            navigateToJoinedPrograms={navigateToJoinedPrograms}
            organizationId={organizationIdForRequests}
            title="Featured"
            view="detail"
          />
          <Card>
            <Title level={3}>{t('All')}</Title>
            <AllPrograms
              getPathToProgramDetail={getPathToProgramDetail}
              navigateToJoinedPrograms={navigateToJoinedPrograms}
              organizationId={organizationIdForRequests}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default ChannelsPrograms;
