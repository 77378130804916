import { transformError, transformResponse } from '../common/recoil/queries';
import { WebPageMetaData } from '../models';
import { getMetadata } from '../services';
import { WebPageMetaDataDataObject } from '../services/data-objects';

const getLinkPreviewData = async (url: string) => {
  try {
    const response = await getMetadata(url);
    return transformResponse<WebPageMetaDataDataObject, WebPageMetaData>(
      response,
      (response: WebPageMetaDataDataObject) => new WebPageMetaData(response),
    );
  } catch (error) {
    return transformError(error);
  }
};

export default getLinkPreviewData;
