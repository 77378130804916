import * as React from 'react';
import { Field } from 'formik';

import { Icon } from '../icon';
import { Uploader } from '../uploader';

function UploadStateComponent(props) {
  return props.uploading ? <Icon size={20} name="rotate-right" className="animate-spin" /> : null;
}

export class FileField extends React.Component {
  static defaultProps = {
    multiple: false,
    UploadStateComponent: UploadStateComponent,
  };

  render() {
    const { name, ...uploaderProps } = this.props;
    return (
      <Field name={name}>
        {(fieldProps) => (
          <Uploader
            {...uploaderProps}
            onChange={(files) => {
              fieldProps.form.setFieldValue(
                name,
                this.props.multiple ? [...(fieldProps.field.value || []), ...files] : [...files],
                true,
              );
              fieldProps.form.setFieldTouched(name, true, true);
            }}
          />
        )}
      </Field>
    );
  }
}
