import qs from 'qs';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { type EventFeedbackOptions } from 'features/events';

export const useFeedbackOptions = <T = unknown>(): EventFeedbackOptions => {
  const { replace } = useHistory<T>();
  const location = useLocation();

  return useMemo(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (typeof query.feedback === 'string' && typeof query.eventId === 'string') {
      return {
        eventId: query.eventId,
        feedback: true,
        onFinish() {
          delete query.feedback;
          delete query.eventId;
          replace({ pathname: location.pathname, search: qs.stringify(query) });
        },
      };
    }

    return {
      feedback: false,
    };
  }, [location.search, location.pathname, replace]);
};
