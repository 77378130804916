import { type CommunityBase, type ReduxUser, type EventType } from 'models';

export const canCreateEventInCommunity = ({
  viewer,
  community,
  eventType,
}: {
  viewer: ReduxUser;
  community: CommunityBase;
  eventType: EventType;
}) => {
  if (eventType === 'telehealth_visit') {
    return viewer.admin_communities.includes(community.id) && community.isPossibleToCreateEvent(eventType, 'admin');
  }

  return community.isPossibleToCreateEvent(
    eventType,
    viewer.admin_communities.includes(community.id) ? 'admin' : 'member',
  );
};

export const canRequestEventInCommunity = ({
  viewer,
  community,
  eventType,
}: {
  viewer: ReduxUser;
  community: CommunityBase;
  eventType: EventType;
}) => !viewer.admin_communities.includes(community.id) && community.isPossibleToCreateEvent(eventType, 'member');
