import cs from 'classnames';
import { type ContentObjectRedux, type ReduxUser } from 'models';
import { CampaignRenderer, EventRenderer, PollRenderer, PostRenderer } from 'old/atoms/feed-object';
import { dispatch } from 'old/store';
import { useEffect } from 'react';
import { routes } from 'routes';
import { useOnRequestEventDetail } from 'pages/hooks';
import { Card } from 'ui';

type P = {
  item: ContentObjectRedux;
  viewer: ReduxUser;
  view: 'feed' | 'form';
};

const ContentObjectPreview = ({ item, viewer, view }: P) => {
  const onRequestEventDetail = useOnRequestEventDetail(); // TODO this should be passed from pages

  useEffect(() => {
    // TODO this belongs to queries probably
    const fetchItemDetail = async () => {
      if (!item?.post && !item?.poll && !item?.event && !item?.campaign) {
        await dispatch.feed.findAsync({ postId: item?.id });
      }
    };

    fetchItemDetail();
  }, [item?.campaign, item?.event, item?.id, item?.poll, item?.post]);

  // TODO there will be problem with this, because post_in_communities is loaded in CO detail request and new PostRenderers will crash
  const pills = item.post_in_communities?.map((community) => ({
    name: community.name,
    link: routes.community.feed(community.id),
  }));

  const renderContentObject = () => {
    if (['post', 'kudo'].includes(item.type)) {
      return (
        <PostRenderer
          item={item}
          viewer={viewer}
          isOnFeed
          authorName={item.author?.name}
          pills={pills}
          isRepost
          hideActions
        />
      );
    }

    if (item.type === 'poll') {
      return (
        <PollRenderer
          item={item}
          viewer={viewer}
          isOnFeed
          authorName={item.author?.name}
          pills={pills}
          isRepost
          hideActions
        />
      );
    }

    if (item.type === 'event') {
      return (
        <EventRenderer
          item={item}
          viewer={viewer}
          isOnFeed
          authorName={item.author?.name}
          pills={pills}
          isRepost
          hideActions
          onRequestEventDetail={onRequestEventDetail}
        />
      );
    }

    if (item.type === 'campaign' && item.campaign) {
      return (
        <CampaignRenderer
          item={item}
          viewer={viewer}
          isOnFeed
          authorName={item.author?.name}
          pills={pills}
          isRepost
          hideActions
        />
      );
    }

    return null;
  };

  return (
    <Card
      marginBottom={false}
      bordered
      className={cs('content-object-preview', { 'content-object-preview--scrollable': view === 'form' })}
    >
      {renderContentObject()}
    </Card>
  );
};

export default ContentObjectPreview;
