import { type VideoDetail } from 'models';
import { type MediaDataObject } from 'models/service';
import { VideoDragger, Col, Row, Form, ModalFormActions, type UploadFile, HlsVideoPlayer } from 'ui';
import { useFormFileUpload } from './hooks';

type VideoUploadFormData = {
  videoTrailer: [UploadFile];
  videoFull: [UploadFile];
};

type P = {
  video: VideoDetail;
  trailerData?: MediaDataObject;
  fullData?: MediaDataObject;
};

const VideoUploadForm = ({ video, trailerData, fullData }: P) => {
  const [form] = Form.useForm<VideoUploadFormData>();

  const {
    initialValue: trailerInitialValue,
    startUpload: startUploadTrailer,
    retryUpload: retryUploadTrailer,
    removeUpload: removeUploadTrailer,
  } = useFormFileUpload({
    form,
    contentType: 'content_object',
    ref: video.id,
    type: 'trailer',
    formName: 'videoTrailer',
    mediaData: trailerData,
  });

  const {
    initialValue: videoInitialValue,
    startUpload: startUploadFull,
    retryUpload: retryUploadFull,
    removeUpload: removeUploadFull,
  } = useFormFileUpload({
    form,
    contentType: 'content_object',
    ref: video.id,
    type: 'full',
    formName: 'videoFull',
    mediaData: fullData,
  });

  // TODO: implement real publish logic
  const onFinish = (values: VideoUploadFormData) => {
    console.log('Form values:', values);
  };

  const initialValues: Partial<VideoUploadFormData> = {
    videoTrailer: trailerInitialValue,
    videoFull: videoInitialValue,
  };

  return (
    <Form<VideoUploadFormData> initialValues={initialValues} onFinish={onFinish} form={form}>
      <Row item={{ gutter: 16 }}>
        <Col item={{ span: 12 }}>
          <Form.Item
            label="Trailer"
            name="videoTrailer"
            valuePropName="fileList"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <VideoDragger
              startUpload={startUploadTrailer}
              removeUpload={removeUploadTrailer}
              retryUpload={retryUploadTrailer}
            />
          </Form.Item>
        </Col>

        <Col item={{ span: 12 }}>
          <Form.Item
            label="Video"
            name="videoFull"
            valuePropName="fileList"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <VideoDragger startUpload={startUploadFull} removeUpload={removeUploadFull} retryUpload={retryUploadFull} />
          </Form.Item>
        </Col>
      </Row>

      {/* TODO: this is for test */}
      {trailerData?.alts?.stream?.path ? (
        <Col item={{ span: 24 }}>
          <HlsVideoPlayer video={{ src: trailerData.alts.stream.path }} />
        </Col>
      ) : null}

      {/* TODO: this is for test */}
      {fullData?.alts?.stream?.path ? (
        <Col item={{ span: 24 }}>
          <HlsVideoPlayer video={{ src: fullData.alts.stream.path }} />
        </Col>
      ) : null}

      <Form.Item noStyle dependencies={['videoTrailer', 'videoFull']}>
        {({ getFieldValue }) => {
          if (
            getFieldValue('videoTrailer')?.[0]?.status !== 'done' ||
            getFieldValue('videoFull')?.[0]?.status !== 'done'
          ) {
            return null;
          }

          return (
            <Col item={{ span: 24 }}>
              <ModalFormActions
                submit={{
                  children: 'Debug',
                }}
              />
            </Col>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default VideoUploadForm;
