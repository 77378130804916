import variables from 'common/styles/variables.json';
import { Col, Row, Skeleton } from 'ui';

const PersonalInfoSkeleton = () => {
  return (
    <Row item={{ gutter: [variables.spaceMd.value, variables.spaceXl.value] }}>
      <Col item={{ span: 24 }}>
        <Skeleton active title={false} paragraph={{ rows: 3 }} />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 12 }}>
        <Skeleton.Input active block />
      </Col>
      <Col item={{ span: 20 }} />
      <Col item={{ span: 4 }}>
        <Skeleton.Button active block />
      </Col>
    </Row>
  );
};

export default PersonalInfoSkeleton;
