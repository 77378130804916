import { AxiosError, AxiosResponse } from 'axios';

export function normalizeSuccessResponse<T>(response: AxiosResponse<T>): ApiClientResponse<T> {
  return {
    ...response,
    ok: true,
  };
}

export function normalizeErrorResponse<T>(error: AxiosError<T>) {
  return {
    ...error.response,
    ok: false,
  };
}

export interface ApiClientResponse<T> extends AxiosResponse<T> {
  ok: boolean;
}
