import { type EventType } from 'models';
import { type ReactNode } from 'react';
import { RoundElement, Row, Col, Title, ConferenceIcon, WebcastIcon, TelehealthIcon } from 'ui';

type P = {
  title: ReactNode | string;
  content?: ReactNode;
  value: EventType;
  onClick: (eventType: EventType) => void;
  className?: string;
  id?: string;
};

const Card = ({ title, content, value, onClick, className, ...htmlProperties }: P) => {
  const getImage = () => {
    switch (value) {
      case 'conference':
        return <ConferenceIcon />;
      case 'webinar':
        return <WebcastIcon />;
      case 'telehealth_visit':
        return <TelehealthIcon />;
    }
  };

  return (
    <button
      onClick={() => {
        onClick(value);
      }}
      className={`t_card ${className ?? ''}`}
      id={htmlProperties.id}
      type="button"
    >
      <Row>
        <Col item={{ className: 't_card__content-left' }}>
          <RoundElement className="t_card__icon" size="small">
            {getImage()}
          </RoundElement>
        </Col>
        <Col item={{ className: 't_card__content-right' }}>
          <Title level={5} className="t_card__title">
            {title}
          </Title>

          {content}
        </Col>
      </Row>
    </button>
  );
};

export default Card;
