import * as React from 'react';

import { Text } from '../text';
import { ThemeConsumer } from '../theme';

// $FlowMissingDefinition
export const Button = React.forwardRef((props, ref) => {
  const { block, color, disabled, onClick, outline, size, textColor = props.color, title } = props;

  return (
    <ThemeConsumer>
      {(theme) => {
        const bgColor = theme.resolveColor(color);
        return (
          <button
            ref={ref}
            type={props.type}
            disabled={disabled}
            onClick={onClick}
            className={`button button--${size} ${props.className}`}
            style={{
              opacity: disabled ? 0.5 : 1,
              width: block ? '100%' : 'auto',
              borderColor: outline ? bgColor : undefined,
              borderWidth: outline ? '1px' : undefined,
              borderStyle: outline ? 'solid' : undefined,
              backgroundColor: outline ? 'transparent' : bgColor,
              ...props.style,
            }}
            id={props.id}
          >
            {typeof title !== 'function' ? (
              <Text
                className={`button__title button__title--${size}`}
                style={{
                  color: theme.resolveColor(textColor),
                }}
              >
                {title}
              </Text>
            ) : (
              title({ textColor })
            )}
          </button>
        );
      }}
    </ThemeConsumer>
  );
});

Button.defaultProps = {
  block: false,
  disabled: false,
  outline: false,
};
