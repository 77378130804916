import { useMutation } from '@tanstack/react-query';
import { type Moment } from 'moment';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';

import { type WithingsOrderPostDataObject } from 'models/service/content-object-deprecated';
import { createWithingsOrderPost } from '../../services';

export const useCreateWithingsOrderPostQuery = () =>
  useMutation<
    WithingsOrderPostDataObject,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      programId: string;
      programTopicId: string;
      publishAt: Moment;
      textContent: string;
      htmlContent: string;
      title?: string;
      todoDueDate?: Moment;
    }
  >({
    async mutationFn({ programId, programTopicId, publishAt, textContent, htmlContent, title, todoDueDate }) {
      const createOrderPostResponse = await createWithingsOrderPost({
        programId,
        programTopicId,
        publishAt,
        textContent,
        htmlContent,
        title,
        todoDueDate,
      });

      return createOrderPostResponse.data;
    },
  });
