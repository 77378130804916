import { type Pagination } from 'models';
import { useEffect, useState } from 'react';
import { useEventsQuery } from 'features/events/queries';

export const useFeaturedEvents = ({
  onSeeAll,
  organizationId,
  pagination,
}: {
  onSeeAll?: () => void;
  organizationId?: string;
  pagination: Pagination;
}) => {
  const [featured, setFeatured] = useState<boolean>(true);

  useEffect(() => {
    setFeatured(true);
  }, [organizationId]);

  const query = useEventsQuery({
    scope: 'channels',
    pagination,
    organizationId,
    featured,
  });

  const hasNoFeaturedEvents = featured && onSeeAll && query.data?.length === 0 && pagination.position === 0;
  if (hasNoFeaturedEvents) {
    setFeatured(false);
  }

  return { featured, query };
};
