import * as React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '../box';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';

function SectionButton(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div style={{ cursor: 'pointer' }} onClick={props.onSeeAll}>
          <Text
            size="11px"
            weight="300"
            color={theme.color.brand}
            lineHeight="13px"
            style={{ textTransform: 'uppercase' }}
          >
            <Trans>See all</Trans>
          </Text>
        </div>
      )}
    </ThemeConsumer>
  );
}

export function Section(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          style={{
            padding: '7px 15px',
            minHeight: 40,
            userSelect: 'none',
            borderBottom: '1px solid #c8c7cc',
          }}
        >
          <Text
            size="11px"
            weight="300"
            color={theme.color.lightGray}
            lineHeight="13px"
            style={{ textTransform: 'uppercase' }}
          >
            {props.title}
          </Text>
          {props.showSeeAll ? <SectionButton onSeeAll={props.onSeeAll} /> : null}
        </Box>
      )}
    </ThemeConsumer>
  );
}
