import { Space as SpaceAntd } from 'antd';
import cn from 'classnames';
import { type ComponentProps } from 'react';
import variables from 'common/styles/variables.json';

type SpaceAntdProperties = ComponentProps<typeof SpaceAntd>;

type P = Omit<SpaceAntdProperties, 'size'> & {
  size?: SpaceAntdProperties['size'] | 'extra-large' | 'extra-small';
  block?: boolean;
};

const Space = ({ size, block, ...rest }: P) => {
  let antdSize = size === 'extra-large' ? variables.spaceXl.value : size;
  antdSize = antdSize === 'extra-small' ? variables.spaceXxs.value : antdSize;

  return <SpaceAntd className={cn('ui-space', { 'ui-space--block': block })} size={antdSize} {...rest} />;
};

export default Space;
