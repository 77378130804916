import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, type ModalFuncProps } from 'antd';

type P = ModalFuncProps;

const modalConfirm = (options: P) =>
  Modal.confirm({
    cancelButtonProps: { type: 'default' },
    icon: <QuestionCircleOutlined />,
    ...options,
  });

export default modalConfirm;
