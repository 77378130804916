import { transformError, transformResponse } from '../../common/recoil/queries';
import { ID, PaginationData, PaginationOptions } from '../../common/types';
import { Chatroom, Message } from '../../models';
import { findChatrooms, FindChatroomsDataObject } from '../../services';
import { isPracticeEnabled } from '../../utils';

export const getChatrooms = async ({ skip, limit }: PaginationOptions, practice: ID, search?: string) => {
  try {
    const response = await findChatrooms({
      $skip: skip,
      $limit: limit,
      $sort: {
        updatedAt: -1,
      },
      ...(isPracticeEnabled() && { ref: practice }),
      status: 'active',
      ...(search ? { $search: search } : {}),
    });

    return transformResponse<FindChatroomsDataObject, PaginationData<Chatroom>>(
      response,
      (pagination: FindChatroomsDataObject) => ({
        limit: pagination.limit,
        skip: pagination.skip,
        total: pagination.total,
        data: pagination.data.map(
          ({ statuses, message, ...rest }) =>
            new Chatroom({
              ...rest,
              message: message ? new Message(message) : message,
              statuses: statuses.map((status) => new Message(status)),
            }),
        ),
      }),
    );
  } catch (error) {
    return transformError(error);
  }
};
