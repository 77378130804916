import { Entity } from './entity';
import { Image } from './image';
import { Organization } from './organization';
import {
  type CampaignDataObject,
  type PriceDataObject,
  type PriceGroupDataObject,
  type PriceInterval,
  type CampaignSlimDataObject,
} from './service';

export type CampaignPaymentMethod = 'braintree' | 'stripe';

export type Price = {
  stripeId: string;
  amount: string;
};

export type PriceGroup = Partial<Record<PriceInterval, Price[]>>;

const createPrice = (priceObject: PriceDataObject): Price => ({
  stripeId: priceObject.stripe_id,
  amount: priceObject.amount,
});

const createGroupPrices = (priceGroup: PriceGroupDataObject): PriceGroup => {
  const data: PriceGroup = {};
  for (const [key, value] of Object.entries(priceGroup)) {
    data[key] = [];
    for (const priceData of value) {
      data[key].push(createPrice(priceData));
    }
  }

  return data;
};

export class Campaign extends Entity {
  paymentMethods: CampaignPaymentMethod[];
  organization: Organization;
  prices: PriceGroup;

  constructor(data: CampaignDataObject) {
    super(data);
    this.paymentMethods = data.payment_methods;
    this.organization = new Organization(data.organization);
    this.prices = createGroupPrices(data.prices);
  }
}

export type CampaignSlim = Entity & {
  startTime: Date;
  endTime: Date;
  createdAt: Date;
  amountGoal: string;
  coverPhoto?: Image;
  organization: { id: string };
};

export const getCampaignSlimData = (data: CampaignSlimDataObject): CampaignSlim => ({
  id: data.id,
  name: data.name,
  startTime: new Date(data.start_time),
  endTime: new Date(data.end_time),
  createdAt: new Date(data.created_at),
  amountGoal: data.amount_goal,
  coverPhoto: data.cover_photo ? new Image(data.cover_photo) : undefined,
  organization: {
    id: data.organization.id,
  },
});
