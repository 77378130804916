/**
 *
 */

import { schema } from 'normalizr';

export const CategorySchema = new schema.Entity('categories');

export const NotificationSchema = new schema.Entity('notifications');

export const ChoiceSchema = new schema.Entity('choices');

export const QuestionSchema = new schema.Entity('questions', {
  choices: [ChoiceSchema],
});

export const PollSchema = new schema.Entity('polls', {
  questions: [QuestionSchema],
});

export const KudoSchema = new schema.Entity('kudos');

export const FileSchema = new schema.Entity('files');

export const AppConfigSchema = new schema.Entity('appConfig');

export const UserSchema = new schema.Entity('users');

export const AssetSchema = new schema.Entity('assets', {
  author: UserSchema,
  metadata: FileSchema,
});

export const CommunitySchema = new schema.Entity('communities', {
  administrators: [UserSchema],
  assets: [AssetSchema],
  categories: [CategorySchema],
});

export const OrganizationSchema = new schema.Entity('organizations', {
  administrators: [UserSchema],
  categories: [CategorySchema],
  communities: [CommunitySchema],
  default_community: CommunitySchema,
});

export const ProgramSchema = new schema.Entity('programs', {
  // cover_photo: FileSchema,
  administrators: [UserSchema],
  assets: [AssetSchema],
  author: UserSchema,
  invited_communities: [CommunitySchema],
  invited_organizations: [OrganizationSchema],
  invited_users: [UserSchema],
  users: [UserSchema],
});

export const ReplySchema = new schema.Entity('replies', {
  attachments: [FileSchema],
  author: UserSchema,
});

export const EventSchema = new schema.Entity('events', {
  // cover_photo: FileSchema,
  attachments: [FileSchema],
  attendees: [UserSchema],
  author: UserSchema,
  going_invitees: [UserSchema],
  invitees: [UserSchema],
  post_in_communities: [CommunitySchema],
  replies: [ReplySchema],
});

export const CampaignSchema = new schema.Entity('campaigns', {
  community: CommunitySchema,
  organization: OrganizationSchema,
});

export const ContentObjectSchema = new schema.Entity('content_objects', {
  attachments: [FileSchema],
  author: UserSchema,
  campaign: CampaignSchema,
  program: ProgramSchema,
  event: EventSchema,
  kudos: [KudoSchema],
  poll: PollSchema,
  post_in_communities: [CommunitySchema],
  replies: [ReplySchema],
});

ReplySchema.define({ replies: [ReplySchema] });
ProgramSchema.define({ timeline: [ContentObjectSchema] });
EventSchema.define({ event_content_object: ContentObjectSchema });
CommunitySchema.define({
  events: [EventSchema],
  members: [UserSchema],
  organization: OrganizationSchema,
  pinned_timeline: [ContentObjectSchema],
});
UserSchema.define({
  joined_communities: [CommunitySchema],
  joined_organizations: [OrganizationSchema],
});
ContentObjectSchema.define({ shared_content_object: ContentObjectSchema });
