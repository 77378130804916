import * as React from 'react';
import { Trans } from 'react-i18next';
import { Navigation, NavLink } from '../../atoms/navigation';
import { DonationReportSummary } from '../page-admin-donation-report';

export function CampaignsSideNav({ scope }) {
  return (
    <div>
      <Navigation direction="vertical" compact id="campaigns-dashboard-navigation">
        <NavLink to="/admin/campaigns/current" id="nav-current-campaigns">
          <Trans>Current Campaigns</Trans>
        </NavLink>
        <NavLink to="/admin/campaigns/finished" id="nav-history">
          <Trans>History</Trans>
        </NavLink>
        <NavLink to="/admin/campaigns/cheque-pledge" id="nav-checklist">
          <Trans>Received Check List</Trans>
        </NavLink>
        <NavLink to="/admin/campaigns/donation-report" id="nav-report">
          <Trans>Donation Report</Trans>
        </NavLink>
        <NavLink to="/admin/campaigns/send-donation-report" id={'nav-send-donation-report'}>
          <Trans>Send Donation Report</Trans>
        </NavLink>
      </Navigation>

      {scope === 'donation-report' && <DonationReportSummary />}
    </div>
  );
}
