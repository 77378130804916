import { useState, useMemo } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
// Import routers
import { getSession, setSession } from '../utils/session-storage';
import { MobileAppRouter } from './MobileAppRouter';
import { WebAppRouter } from './WebAppRouter';
// Pages
import { routes } from 'routes';
import { MobilePageShareContentObject } from '../pages/mobile-page-share-content-object';
import { MobilePageRichTextEditor } from '../pages/mobile-rich-text-editor';
import { PageConferenceEnded, PageUnsubscribe, PagePrivacyPolicy } from 'pages';

window.referrer = window.location.href;

const sessionStorageKey = 'mobileUserContinueInBrowser';
const getContinueInBrowser = () => getSession(sessionStorageKey);
const setContinueInBrowser = () => setSession(sessionStorageKey, true);

const getPlatform = () => {
  if (/android/i.test(navigator.userAgent)) {
    return 'android';
  }

  if (/iphone|ipad|ipod/i.test(navigator.userAgent)) {
    return 'ios';
  }

  return 'web';
};

export function MainRouter() {
  const platform = useMemo(() => getPlatform(), []);
  const [isMobileRouter, setIsMobileRouter] = useState(!getContinueInBrowser() && platform !== 'web');

  const onContinueInBrowser = () => {
    setIsMobileRouter(false);
    setContinueInBrowser();
  };

  return (
    <BrowserRouter>
      <Switch>
        {/* TODO START remove in #63371 */}
        <Redirect exact from="/privacy.html" to={routes.privacyPolicy} />
        <Redirect exact from="/privacy-myowndoctor.html" to={routes.privacyPolicy} />
        {/* TODO END remove in #63371 */}
        <Route path="/mobile-app/rich-text-editor" component={MobilePageRichTextEditor} />
        <Route path="/mobile-app/share-content-object" component={MobilePageShareContentObject} />
        <Route path="/email-unsubscribe" component={PageUnsubscribe} />
        <Route path="/conference-ended" component={PageConferenceEnded} />
        <Route path={routes.privacyPolicy} component={PagePrivacyPolicy} />

        {isMobileRouter ? (
          <MobileAppRouter platform={platform} onContinueInBrowser={onContinueInBrowser} />
        ) : (
          <WebAppRouter />
        )}
      </Switch>
    </BrowserRouter>
  );
}
