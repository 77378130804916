import { transformError, transformResponse } from '../common/recoil/queries';
import { ID } from '../common/types';
import { Chatroom, Message } from '../models';
import { PatchChatroomRequest } from '../services';
import { patchChatroom as patchChatroomRequest } from '../services/chatrooms/patch';
import { ChatroomDataObject } from '../services/data-objects';

const patchChatroom = async (chatroomId: ID, data: PatchChatroomRequest) => {
  try {
    const response = await patchChatroomRequest(chatroomId, data);
    return transformResponse<ChatroomDataObject, Chatroom>(
      response,
      ({ statuses, message, ...rest }) =>
        new Chatroom({
          ...rest,
          message: message ? new Message(message) : undefined,
          statuses: [],
        }),
    );
  } catch (error) {
    return transformError(error);
  }
};

export default patchChatroom;
