import cn from 'classnames';
import { useEffect, useState } from 'react';
import { getLinkPreviewData } from '../../../../actions';
import { WebPageMetaData } from '../../../../models';
import { Image, YoutubeEmbedded } from '../../../../ui';
import { getLinksFromText, getYoutubeLinksAndIds } from '../../../../utils';

type P = {
  text: string;
  isAuthor: boolean;
};

const MessageLinkPreview = ({ text, isAuthor }: P) => {
  const [metaData, setMetaData] = useState<WebPageMetaData | null>(null);
  const [youtubeIdToPreview, setYoutubeIdToPreview] = useState<string>();
  const [linkForPreview, setLinkForPreview] = useState<string>();

  useEffect(() => {
    const getDataForLinkPreview = async () => {
      if (linkForPreview) {
        const responseData = await getLinkPreviewData(linkForPreview);
        if (!responseData.error) {
          return setMetaData(responseData.result);
        }
      }

      return null;
    };

    getDataForLinkPreview().catch((error) => console.error(error));
  }, [linkForPreview]);

  useEffect(() => {
    if (text) {
      const textLinks = getLinksFromText(text);
      const youtubeLinksArray = textLinks.length > 0 ? getYoutubeLinksAndIds(textLinks) : [];
      if (youtubeLinksArray.length > 0) {
        setYoutubeIdToPreview(youtubeLinksArray[0].id);
      } else if (textLinks.length > 0) {
        setLinkForPreview(textLinks[0]);
      } else {
        // Do nothing
      }
    }
  }, [text]);

  const shouldComponentRender = youtubeIdToPreview || (linkForPreview && metaData && metaData.title && metaData.image);

  if (!shouldComponentRender) {
    return null;
  }

  return (
    <div className={cn('chat-message-link-preview', { 'chat-message-link-preview--sender': !isAuthor })}>
      {youtubeIdToPreview ? (
        <YoutubeEmbedded className="chat-message-link-preview__youtube" videoId={youtubeIdToPreview} />
      ) : (
        <div className="chat-message-link-preview__website">
          <div className="chat-message-link-preview__website__image">
            {<Image preview={false} src={metaData?.image} width={150} height={150} />}
          </div>
          <div className="chat-message-link-preview__website__text">
            <a
              className="chat-message-link-preview__website__text__title"
              target="_blank"
              rel="noreferrer"
              href={linkForPreview}
            >
              {metaData?.title}
            </a>
            <div className="chat-message-link-preview__website__text__description">{metaData?.description}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageLinkPreview;
