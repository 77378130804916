import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { withPermissions } from '../../atoms/auth';
import { Icon } from '../../atoms/icon';
import { Text } from '../../atoms/text';
import { FileIcon } from '../../atoms/file';
import { ThemeConsumer } from '../../atoms/theme';
import { calculateFileSize } from '../../utils/files';
import { Menu, MenuButton, MenuList, MenuItem } from '../../atoms/menu';

const ENTER_CODE = 13;
const ESCAPE_CODE = 27;

export class Renderer extends React.Component {
  state = {
    value: this.props.asset.name,
  };

  inputRef = null;

  get fullName() {
    return `${this.props.asset.author.first_name} ${this.props.asset.author.last_name}`;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.editable && this.props.editable) {
      this.setState({ value: this.props.asset.name });
      setTimeout(() => this.inputRef.focus(), 10);
    }
  }

  onChange = (event) => {
    this.setState({ value: event.target.value });
  };

  onFocus = () => {
    this.inputRef.select();
  };

  onBlur = () => {
    this.props.onChange(this.state.value);
  };

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case ENTER_CODE:
        this.onBlur();
        break;
      case ESCAPE_CODE:
        this.setState({ value: this.props.asset.name });
        this.props.onChange();
        window.getSelection().removeAllRanges();
        break;
      default:
    }
  };

  renderInput = () => {
    return (
      <input
        ref={(ref) => (this.inputRef = ref)}
        type="text"
        className={`asset-row__name ${this.props.editable ? '' : 'asset-row__name--not-editable'}`}
        disabled={!this.props.editable}
        value={this.state.value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        id="input_created-item"
      />
    );
  };

  renderOptions = (asset) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #edeff2' }} id="item_options">
        {withPermissions(
          this.props.permissions,
          <Menu>
            <MenuButton style={{ border: 0, background: 'none' }}>
              <Icon name="menu" size={20} />
            </MenuButton>
            <MenuList>
              <MenuItem onSelect={() => this.props.onDelete(asset)} id={'delete'}>
                <Trans>Delete</Trans>
              </MenuItem>
              <MenuItem onSelect={() => this.props.onEdit(true)} id={'edit'}>
                <Trans>Rename</Trans>
              </MenuItem>
            </MenuList>
          </Menu>,
          null,
        )}
      </div>
    );
  };

  render() {
    const { asset } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="asset-row">
            {asset.type === 'folder' ? (
              <div className={`asset-row__folder ${this.props.onClick ? `asset-row__folder--click` : ''}`}>
                <div
                  className="asset-row__name-box"
                  onClick={() => (!this.props.editable ? this.props.onClick(asset) : undefined)}
                >
                  <div className="asset-row__name-box__image">
                    <img
                      alt="file-folder"
                      src="/assets/file-icons/file_folder.png"
                      srcSet="/assets/file-icons/file_folder@2x.png 2x, /assets/file-icons/file_folder@3x.png 3x"
                    />
                  </div>
                  <div className="asset-row__input-box">{this.renderInput()}</div>
                </div>
                {this.props.hover ? this.renderOptions(asset) : null}
              </div>
            ) : (
              <div style={{ display: 'flex', flex: 3 }}>
                <a
                  href={this.props.editable || !asset.metadata ? '!#' : asset.metadata.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ flex: 1, textDecoration: 'none', color: 'inherit' }}
                >
                  <div className="asset-row__name-box">
                    <div style={{ padding: '0 15px 0 25px', height: 60 }}>
                      {asset.metadata && (
                        <FileIcon
                          url={asset.metadata.preview_url || asset.metadata.url}
                          fileType={asset.metadata.content_type}
                          extension={asset.metadata.filename.split(/\.(?=[^.]+$)/)[1]}
                        />
                      )}
                    </div>
                    <div className="asset-row__input-box">{this.renderInput()}</div>
                  </div>
                </a>
                {this.props.hover ? this.renderOptions(asset) : null}
              </div>
            )}
            <div className="asset-row__column">{asset.extension && asset.extension}</div>
            <div className="asset-row__column">
              {asset.updated_at &&
                this.props.t('dateFormatShortMonthDayYearTime', { date: new Date(asset.updated_at) })}
            </div>
            <div className="asset-row__column">{asset.metadata && calculateFileSize(asset.metadata.byte_size)}</div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export const AssetDetail = withTranslation()(Renderer);

export function EmptyAssetsRow(props) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'table-cell', textAlign: 'center' }}>
        <Text>{props.title}</Text>
      </div>
    </div>
  );
}

export class AssetRow extends React.Component {
  state = {
    isModalOpen: false,
    hover: false,
    editable: false,
  };

  onChangeAsset(asset) {
    return (value) => {
      if (value) {
        asset.name = value;
        this.props.onDropdownSelect('rename', asset);
      }
      this.setState({ editable: false });
    };
  }

  render() {
    const { asset } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <div
            className="asset-manager-row"
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <AssetDetail
              asset={asset}
              hover={this.state.hover}
              onClick={asset.type === 'folder' ? this.props.onClickFolder : undefined}
              editable={this.state.editable}
              permissions={this.props.permissions}
              onChange={this.onChangeAsset(asset)}
              onEdit={(editable) => this.setState({ editable: editable })}
              onDelete={(asset) => this.props.onDropdownSelect('delete', asset)}
            />
          </div>
        )}
      </ThemeConsumer>
    );
  }
}
