import { format, parseISO, isDate, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Box } from '../../box';
import { Text } from '../../text';
import { ThemeConsumer } from '../../theme';

export function EventItem(props) {
  const { t } = useTranslation();
  const parsedDate = parseISO(props.itemProps.start_time);
  const isValidDate = isDate(parsedDate) && isValid(parsedDate);

  return (
    <ThemeConsumer>
      {(theme) => (
        <Box
          {...props.downshiftProps}
          flexDirection="row"
          alignItems="center"
          style={{ padding: '7px 15px', cursor: 'pointer' }}
        >
          <Box style={{ marginRight: 10 }}>
            <div
              style={{
                border: '1px solid #cfd8dc',
                borderRadius: '2px',
                padding: '7px 10px',
                textAlign: 'center',
              }}
            >
              <Text component="p" size="13px" weight="bold" color={theme.color.lightGray} lineHeight="15px">
                {isValidDate ? format(parsedDate, 'd') : ''}
              </Text>
              <Text component="p" size="9px" weight="600" color={theme.color.lightGray} lineHeight="9px">
                {t('dateFormatShortMonth', { date: parsedDate })}
              </Text>
            </div>
          </Box>
          <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Text
              component="p"
              size="14px"
              weight="500"
              color={theme.color.gray}
              lineHeight="19px"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {props.title}
            </Text>
            <Text
              component="time"
              size="12px"
              weight="500"
              color="#7E939E"
              lineHeight="21px"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexGrow: 1 }}
            >
              {t('dateFormatShortMonthDayYearTime', {
                date: parsedDate,
              })}
              {props.itemProps.place && `, ${props.itemProps.place}`}
            </Text>
          </Box>
        </Box>
      )}
    </ThemeConsumer>
  );
}
