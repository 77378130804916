import { atom } from 'recoil';
import { ID, PaginationOptions } from '../../common/types';
import { Chatroom } from '../../models';
import {
  defaultActiveChatroom,
  defaultChatrooms,
  defaultChatroomsPaginationOptions,
  defaultChatroomsSearch,
  defaultDeletedChatrooms,
} from '../defaults';

export const chatrooms = atom<Chatroom[]>({
  key: 'Chatrooms',
  default: defaultChatrooms,
});

export const activeChatroom = atom<ID | null>({
  key: 'ActiveChatroomId',
  default: defaultActiveChatroom,
});

export const deletedChatrooms = atom<string[]>({
  key: 'DeletedChatrooms',
  default: defaultDeletedChatrooms,
});

export const chatroomsSearch = atom<string | undefined>({
  key: 'ChatroomsSearch',
  default: defaultChatroomsSearch,
});

export const chatroomsPaginationOptions = atom<PaginationOptions>({
  key: 'ChatroomsPaginationOptions',
  default: defaultChatroomsPaginationOptions,
});
