import * as React from 'react';
import { Trans } from 'react-i18next';

import { ThemeConsumer } from '../../atoms/theme';
import { Box } from '../../atoms/box';
import { H1, Text } from '../../atoms/text';
import { CampaignService } from '../../services/CampaignService';
import { images } from 'common/utils';

export class AdminPageCampaignDetail extends React.Component {
  state = {
    campaign: null,
  };

  async componentWillMount() {
    const { donation_id } = this.props.match.params;
    if (donation_id) {
      const response = await CampaignService.find(donation_id);
      if (response.ok) {
        this.setState({ campaign: response.data });
      }
    }
  }

  render() {
    const { campaign } = this.state;
    return campaign === null ? null : (
      <ThemeConsumer>
        {(theme) => (
          <Box>
            {campaign.profile_photo ? (
              <Box>
                <img src={campaign.profile_photo?.url ?? images.default.campaignCoverPhoto} alt="profile" />
              </Box>
            ) : (
              ''
            )}
            <Box>
              <H1>{campaign.name}</H1>
              <Text>{campaign.description}</Text>
            </Box>
            <Box>
              <Box flexDirection="row" className="admin-campaign-detail__amounts">
                {campaign.amounts.map((amount) => (
                  <div className="admin-campaign-detail__amount">
                    <Text className="admin-campaign-detail__amount__text">${amount}</Text>
                  </div>
                ))}
              </Box>
              <Box className="admin-campaign-detail__other-amounts-wrapper">
                <div className="admin-campaign-detail__other-amounts">
                  <Text className="admin-campaign-detail__other-amounts__text">
                    <Trans>Other Amounts</Trans>
                  </Text>
                </div>
              </Box>
            </Box>
          </Box>
        )}
      </ThemeConsumer>
    );
  }
}
