import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import PageMessages from 'pages/page-messages/PageMessages';

class Render extends React.Component {
  render() {
    return <PageMessages {...this.props} />;
  }
}

const mapState = (state) => ({
  nodeAPIUser: select.session.nodeAPIUser(state),
  unreadMessages: select.session.unreadMessages(state),
});

const mapDispatch = (dispatch) => ({
  updateUnreadCount: (unreadCount) => dispatch.session.updateChatroomStats(unreadCount),
  setUnreadMessages: (count) => dispatch.session.setUnreadMessages(count),
});

export const PageMessagesOld = connect(mapState, mapDispatch)(Render);
