import * as React from 'react';
import { Field } from 'formik';
import { Text } from '../text';
import { Radio } from './base-atoms/Radio';
import { FieldError } from './FieldError';

export function RadioGroup(props) {
  return (
    <React.Fragment>
      <div className={`radio-group-old ${props.inline ? 'radio-group-old--inline' : ''}`}>
        <Field name={props.name}>
          {(fieldProps) =>
            props.values.map((item, idx) => (
              <label key={item.value.toString()} disabled={item.disabled} className="radio-group-old__base">
                <div className="radio-group-old__icon">
                  <Radio
                    {...fieldProps.field}
                    onChange={(event) => {
                      fieldProps.form.setFieldValue(fieldProps.field.name, item.value, true);
                      fieldProps.field.onBlur(event);

                      if (item.onChange) {
                        item.onChange(event);
                      }
                    }}
                    value={item.value.toString()}
                    checked={fieldProps.field.value === item.value}
                    disabled={item.disabled}
                  />
                </div>
                <Text size="17px" lineHeight="40px" weight="100" id={`item-${props.name}[${idx}]`}>
                  {item.label}
                </Text>
              </label>
            ))
          }
        </Field>
      </div>
      <FieldError name={props.name} />
    </React.Fragment>
  );
}
