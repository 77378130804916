import { ReactNode } from 'react';

type P = {
  children: ReactNode;
}

const CommentWrapper = ({ children }: P) => {
  return (
    <div className="comment-wrapper">
      {children}
    </div>
  )
};

export default CommentWrapper;