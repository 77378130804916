import { type EntityOptions } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { Card, CompassIcon, Empty } from 'ui';
import { CampaignsSlider, CommunitiesSlider, ProgramsSlider, EventsSlider } from '../components';

type P = {
  navigateToJoinedCommunities: () => void;
  navigateToJoinedPrograms: () => void;
  navigateToOrganizationChannel: (id: string, scope: 'campaigns' | 'communities' | 'events' | 'programs') => void;
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
  organizationId: string;
  organizationIdForRequests?: string;
};

const DefaultChannels = ({
  navigateToJoinedCommunities,
  navigateToJoinedPrograms,
  navigateToOrganizationChannel,
  getPathToEntityDetail,
  organizationId,
  organizationIdForRequests,
}: P) => {
  const [isEmpty, setIsEmpty] = useState<{
    communitiesEmpty: boolean;
    programsEmpty: boolean;
    eventsEmpty: boolean;
    campaignsEmpty: boolean;
  }>({
    programsEmpty: false,
    communitiesEmpty: false,
    eventsEmpty: false,
    campaignsEmpty: false,
  });

  // TODO this hack is needed together with useEffect in slider components to set empty state correctly
  const [isEmptyState, setIsEmptyState] = useState(false);

  useEffect(() => {
    setIsEmptyState(Object.values(isEmpty).every(Boolean));
  }, [isEmpty]);

  const setCommunitiesEmpty = useCallback((isEmpty: boolean) => {
    setIsEmpty((prevstate) => ({ ...prevstate, communitiesEmpty: isEmpty }));
  }, []);

  const setProgramsEmpty = useCallback((isEmpty: boolean) => {
    setIsEmpty((prevstate) => ({ ...prevstate, programsEmpty: isEmpty }));
  }, []);

  const setEventsEmpty = useCallback((isEmpty: boolean) => {
    setIsEmpty((prevstate) => ({ ...prevstate, eventsEmpty: isEmpty }));
  }, []);

  const setCampaignsEmpty = useCallback((isEmpty: boolean) => {
    setIsEmpty((prevstate) => ({ ...prevstate, campaignsEmpty: isEmpty }));
  }, []);

  return (
    <>
      {isEmptyState ? (
        <Card>
          <Empty
            image={<CompassIcon />}
            description={{
              title: 'No channels',
              subtitle: 'There are no channels to display here',
            }}
          />
        </Card>
      ) : null}
      <CommunitiesSlider
        getPathToCommunityDetail={(id) => getPathToEntityDetail(id, { entityType: 'community' })}
        navigateToJoinedCommunities={navigateToJoinedCommunities}
        organizationId={organizationIdForRequests}
        title="Communities"
        onSeeAll={() => {
          navigateToOrganizationChannel(organizationId, 'communities');
        }}
        view="preview"
        onEmptyState={setCommunitiesEmpty}
      />
      <EventsSlider
        getPathToEventDetail={(id) => getPathToEntityDetail(id, { entityType: 'event' })}
        organizationId={organizationIdForRequests}
        title="Events"
        onSeeAll={() => {
          navigateToOrganizationChannel(organizationId, 'events');
        }}
        view="preview"
        onEmptyState={setEventsEmpty}
      />
      <ProgramsSlider
        getPathToProgramDetail={(id) => getPathToEntityDetail(id, { entityType: 'program' })}
        navigateToJoinedPrograms={navigateToJoinedPrograms}
        organizationId={organizationIdForRequests}
        title="Programs"
        onSeeAll={() => {
          navigateToOrganizationChannel(organizationId, 'programs');
        }}
        view="preview"
        onEmptyState={setProgramsEmpty}
      />
      <CampaignsSlider
        getPathToCampaignDetail={(id) => getPathToEntityDetail(id, { entityType: 'campaign', organizationId })}
        organizationId={organizationIdForRequests}
        title="Campaigns"
        onSeeAll={() => {
          navigateToOrganizationChannel(organizationId, 'campaigns');
        }}
        view="preview"
        onEmptyState={setCampaignsEmpty}
      />
    </>
  );
};

export default DefaultChannels;
