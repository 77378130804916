import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../common/services';

import { ID } from '../../common/types';
import { ChatroomStatus } from '../../types';
import { ChatroomDataObject } from '../data-objects';

export type PatchChatroomRequest = {
  name?: string;
  status?: ChatroomStatus;
};

export const patchChatroom = (chatroom: ID, data: PatchChatroomRequest) =>
  nodeApiClient.patch<ChatroomDataObject, AxiosResponse<ChatroomDataObject>>(routes.chatrooms.patch(chatroom), data);
