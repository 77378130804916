import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { AppLayout } from '../../layouts/AppLayout';
import { NotificationsList } from '../../widgets/notifications-list';
import { NotificationsNav } from '../../widgets/notifications-list';
import { Card, Row, Col, Title } from 'ui';

class Renderer extends React.Component {
  render() {
    return (
      <AppLayout
        center={
          <>
            <Row>
              <Col item={{ flex: '1' }}>
                <Title level={2} marginTop={false}>
                  Notifications
                </Title>
              </Col>
              <Col>
                <NotificationsNav />
              </Col>
            </Row>

            <Card shrinked>
              <NotificationsList limit={20} viewer={this.props.viewer} />
            </Card>
          </>
        }
      />
    );
  }
}

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export const PageNotifications = connect(mapState)(Renderer);
