import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type EventContentObjectDataObject } from 'models/service';

import { type EventRequestData } from './createEvent';

export const updateEvent = async (id: string, event: EventRequestData) =>
  apiClient.put<EventContentObjectDataObject, ApiClientResponse<EventContentObjectDataObject>>(
    routes.events.update(id),
    {
      event,
    },
  );
