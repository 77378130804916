import i18next from 'i18n';
import { ChangeRequest, type EventType, type Tag } from 'models';
import { Entity } from './entity';
import { Image } from './image';
import { NotificationSetting } from './notifications';
import { OrganizationListItem } from './organization';
import { type Privacy } from './privacy';
import {
  type CommunityDataObject,
  type CommunityBaseDataObject,
  type CommunityListItemDataObject,
  type CommunityNotificationSettingsDataObject,
  type AdministratorDataObject,
  type CommunityMetaDataObject,
  type CommunitySettingsDataObject,
  type CommunityMetaItemDataObject,
  type CommunitySlimDataObject,
  type ChangeRequestDataObject,
} from './service';

import { BaseUser } from './user';

class PushNotificationsSettings {
  enableNotifications: NotificationSetting;
  newsfeed: NotificationSetting;
  events: NotificationSetting;
  program: NotificationSetting;
  donations: NotificationSetting;

  constructor(data: CommunityNotificationSettingsDataObject) {
    this.enableNotifications = new NotificationSetting(data.push_notifications_settings.enable_notifications);
    this.newsfeed = new NotificationSetting(data.push_notifications_settings.newsfeed);
    this.events = new NotificationSetting(data.push_notifications_settings.events);
    this.program = new NotificationSetting(data.push_notifications_settings.program);
    this.donations = new NotificationSetting(data.push_notifications_settings.donations);
  }
}

class EmailNotificationsSettings {
  enableNotifications: NotificationSetting;
  newsfeed: NotificationSetting;
  events: NotificationSetting;
  program: NotificationSetting;
  donations: NotificationSetting;

  constructor(data: CommunityNotificationSettingsDataObject) {
    this.enableNotifications = new NotificationSetting(data.email_notifications_settings.enable_notifications);
    this.newsfeed = new NotificationSetting(data.email_notifications_settings.newsfeed);
    this.events = new NotificationSetting(data.email_notifications_settings.events);
    this.program = new NotificationSetting(data.email_notifications_settings.program);
    this.donations = new NotificationSetting(data.email_notifications_settings.donations);
  }
}

class SmsNotificationsSettings {
  enableNotifications?: NotificationSetting;
  invitations?: NotificationSetting;

  constructor(data: CommunityNotificationSettingsDataObject) {
    this.enableNotifications = data.sms_notifications_settings?.enable_notifications
      ? new NotificationSetting(data.sms_notifications_settings.enable_notifications)
      : undefined;
    this.invitations = data.sms_notifications_settings?.invitations
      ? new NotificationSetting(data.sms_notifications_settings.invitations)
      : undefined;
  }
}

export class CommunityNotificationSettings {
  pushNotificationsSettings: PushNotificationsSettings;
  emailNotificationsSettings: EmailNotificationsSettings;
  smsNotificationsSettings: SmsNotificationsSettings;

  constructor(data: CommunityNotificationSettingsDataObject) {
    this.pushNotificationsSettings = new PushNotificationsSettings(data);
    this.emailNotificationsSettings = new EmailNotificationsSettings(data);
    this.smsNotificationsSettings = new SmsNotificationsSettings(data);
  }
}

class CommunitySettings {
  allowPosts?: boolean;
  allowComments?: boolean;
  allowInvites?: boolean;
  allowUnsubscribe?: boolean;
  viewMembers?: boolean;
  allowedEventsAdmin?: EventType[];
  allowedEventsMember?: EventType[];

  constructor(data: CommunitySettingsDataObject) {
    this.allowPosts = data.allow_posts;
    this.allowComments = data.allow_comments;
    this.allowInvites = data.allow_invites;
    this.allowUnsubscribe = data.allow_unsubscribe;
    this.viewMembers = data.view_members;
    this.allowedEventsAdmin = data.allowed_events_admin;
    this.allowedEventsMember = data.allowed_events_member;
  }
}

export type MetaItemTranslations = {
  en: string;
  es: string;
};

export class MetaItem {
  name: string;
  translations: MetaItemTranslations;
  children?: MetaItem[];

  constructor(data: CommunityMetaItemDataObject) {
    this.name = data.name;
    this.translations = data.translations;
    this.children = data.children?.map((metaItem) => new MetaItem(metaItem));
  }

  getTranslation = () => {
    return this.translations[i18next.language] ?? this.translations.en;
  };
}

class CommunityMeta {
  patientTypes?: MetaItem[];
  serviceTypes?: MetaItem[];
  telehealthVisitDurations?: number[];

  constructor(data: CommunityMetaDataObject) {
    this.patientTypes = data.patient_types?.map((metaItem) => new MetaItem(metaItem));
    this.serviceTypes = data.service_types?.map((metaItem) => new MetaItem(metaItem));
    this.telehealthVisitDurations = data.telehealth_visit_durations;
  }
}

export class CommunitySlim extends Entity {
  coverPhoto?: Image;

  constructor(data: CommunitySlimDataObject) {
    super(data);
    this.coverPhoto = data.cover_photo ? new Image(data.cover_photo) : undefined;
  }
}

export abstract class CommunityBase extends CommunitySlim {
  organizationId: string;

  shortName: string;
  description: string;
  htmlDescription: string;

  privacy: Privacy;
  membersCount: number;
  autoJoin: boolean;
  settings: CommunitySettings;

  profilePhoto?: Image;
  joined: boolean;
  meta?: CommunityMeta;
  changeRequests: ChangeRequest[];

  protected constructor(data: CommunityBaseDataObject) {
    super(data);
    this.id = data.id;
    this.organizationId = data.organization_id;

    this.name = data.name;
    this.shortName = data.short_name;
    this.description = data.description;
    this.htmlDescription = data.html_description;

    this.privacy = data.privacy;
    this.membersCount = data.members_count;
    this.autoJoin = data.auto_join;
    this.settings = new CommunitySettings(data.settings);

    this.profilePhoto = data.profile_photo ? new Image(data.profile_photo) : undefined;

    this.joined = data.joined;

    this.meta = data.meta ? new CommunityMeta(data.meta) : undefined;

    this.changeRequests = data.change_requests?.map((request: ChangeRequestDataObject) => new ChangeRequest(request));
  }

  isPossibleToCreateEvent(
    eventTypes: EventType | EventType[],
    role: 'admin' | 'member',
    rule: 'every' | 'some' = 'every',
  ) {
    const { settings } = this;
    eventTypes = Array.isArray(eventTypes) ? eventTypes : [eventTypes];

    if (role === 'member') {
      return rule === 'some'
        ? eventTypes.some((eventType) => settings.allowedEventsMember?.includes(eventType))
        : eventTypes.every((eventType) => settings.allowedEventsMember?.includes(eventType));
    }

    return rule === 'some'
      ? eventTypes.some((eventType) => settings.allowedEventsAdmin?.includes(eventType))
      : eventTypes.every((eventType) => settings.allowedEventsAdmin?.includes(eventType));
  }
}

export class CommunityListItem extends CommunityBase {
  categories: Entity[];
  organization: OrganizationListItem;

  constructor(data: CommunityListItemDataObject) {
    super(data);
    this.categories = data.categories.map((category: any) => ({ id: category.id, name: category.name }));
    this.organization = new OrganizationListItem(data.organization);
  }
}

export class Community extends CommunityBase {
  // TODO type when used
  categories: any[];
  organization: OrganizationListItem;
  default: boolean;
  modInvitationToken: string | null;
  administrators?: BaseUser[];
  tags?: Tag[];

  constructor(data: CommunityDataObject) {
    super(data);
    this.categories = data.categories;
    this.organization = new OrganizationListItem(data.organization);
    this.default = data.default;
    this.modInvitationToken = data.mod_invitation_token;
    this.administrators = data.administrators?.map(
      (administrator: AdministratorDataObject) => new BaseUser(administrator),
    );
    this.tags = data.tags.map((tagItem) => ({
      id: tagItem.id,
      name: tagItem.name,
    }));
  }
}

export const hasChangeRequestStatus = ({ community, status }: { community: Community; status: 'pending' }): boolean =>
  community.changeRequests.some((request) => request.kind === 'community_request_to_join' && request.status === status);
