import { type ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { Button } from 'ui';
import { useNodeUserQuery } from '../queries';

const DirectMessageButton = ({
  legacyUserId,
  viewer,
  children,
  className,
  type,
}: {
  legacyUserId: string;
  viewer: { id: string };
  children?: ReactNode;
  className?: string;
  type?: 'default' | 'text';
}) => {
  const { push } = useHistory();
  const [enabled, setEnabled] = useState(false);

  const { data, isFetching } = useNodeUserQuery({ railsId: legacyUserId }, { enabled });

  if (enabled && data && !isFetching) {
    push(routes.chat.create(), { userToChat: { _id: data._id, firstName: data.firstName, lastName: data.lastName } });
    return null;
  }

  return viewer.id === legacyUserId ? null : (
    <Button
      onClick={() => {
        setEnabled(true);
      }}
      type={type}
      className={className}
      loading={isFetching}
      size="large"
    >
      {children}
    </Button>
  );
};

export default DirectMessageButton;
