import ct from 'countries-and-timezones';

export const getCurrentUserTimezone = () => {
  // eslint-disable-next-line new-cap
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  let currentUserTimezone = timeZone;

  const allTimezones = Object.values(getAllTimezones());
  const timezoneExists = allTimezones.some((timeZoneObject) => timeZoneObject.value === currentUserTimezone);

  if (!timezoneExists) {
    currentUserTimezone = 'America/Chicago';
  }

  return currentUserTimezone;
};

const getAllTimezones = () => {
  const america: Array<{ label: string; value: string; utcOffset: number }> = [];
  const rest: Array<{ label: string; value: string; utcOffset: number }> = [];

  // eslint-disable-next-line import/no-named-as-default-member
  for (const timezone of Object.values(ct.getAllTimezones({ deprecated: true }))) {
    if (timezone.countries?.length > 0) {
      const country = {
        label: `(GMT${timezone.utcOffsetStr}) ${timezone.name}`,
        value: timezone.name,
        utcOffset: timezone.utcOffset,
      };

      if (country.value.startsWith('America/')) {
        america.push(country);
      } else {
        rest.push(country);
      }
    }
  }

  const sortedAmerica = america.sort((a, b) => a.utcOffset - b.utcOffset);
  const sortedRest = rest.sort((a, b) => a.utcOffset - b.utcOffset);

  return [...sortedAmerica, ...sortedRest];
};

export const timezones = getAllTimezones();
