import { AxiosResponse } from 'axios';
import PromisePool from 'native-promise-pool';
import { SuccessState, transformResponse } from '../common/recoil/queries';

import { ID } from '../common/types';
import { Chatroom, Message } from '../models';
import { addMemberToChatroom, removeMemberFromChatroom } from '../services';
import { ChatroomDataObject } from '../services/data-objects';
import { ParticipantBase } from '../types';

const manageChatroomMembers = async (chatroom: ID, membersToAdd: { _id: string; participant: ParticipantBase }[], membersToRemove: { _id: string; participant?: ParticipantBase }[]) => {
  const pool = new PromisePool(10);
  const poolPromises: Promise<SuccessState<Chatroom>>[] = [];

  for (const member of membersToAdd) {
    poolPromises.push(pool.open(() => addMemberToChatroom(chatroom, { id: member._id, participant: {
      firstName: member.participant.firstName,
      lastName: member.participant.lastName,
    }}).then(transform)));
  }

  for (const member of membersToRemove) {
    poolPromises.push(pool.open(() => removeMemberFromChatroom(chatroom, member._id).then(transform)));
  }

  return Promise.all(poolPromises);
};

const transform = (response: AxiosResponse<ChatroomDataObject>) =>
  transformResponse<ChatroomDataObject, Chatroom>(
    response,
    ({ statuses, message, ...rest }) =>
      new Chatroom({
        ...rest,
        message: message ? new Message(message) : undefined,
        statuses: [],
      }),
  );

export default manageChatroomMembers;
