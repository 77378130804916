import { type Form, Input } from 'antd';
import { type ComponentProps } from 'react';
import { Item } from './Item';

const { TextArea } = Input;

type ItemP = Omit<ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
};

type InputP = Omit<ComponentProps<typeof TextArea>, 'css'> & {
  'data-test-id'?: string;
};

type P = {
  item: ItemP;
  input?: InputP;
};

const TextAreaInput = ({ item, input }: P) => {
  return (
    <Item {...item}>
      <TextArea rows={4} {...input} />
    </Item>
  );
};

export default TextAreaInput;
