import { createContext, Component } from 'react';

export const Context = createContext({
  direction: 'vertical',
  compact: false,
  nested: false,
  openGroups: false,
});

export class Navigation extends Component {
  static defaultProps = {
    compact: false,
    openGroups: false,
  };

  contextValue = {
    direction: this.props.direction,
    compact: this.props.compact,
    nested: false,
    openGroups: this.props.openGroups,
  };

  render() {
    const { direction, compact, children } = this.props;
    return (
      <Context.Provider value={this.contextValue}>
        <div
          className={`navigation navigation--${direction === 'vertical' ? 'vertical' : 'horizontal'} navigation--${
            compact ? 'compact' : 'regular'
          } `}
          id={this.props.id}
          role="tablist"
          aria-orientation={direction === 'vertical' ? 'vertical' : 'horizontal'}
        >
          {children}
        </div>
      </Context.Provider>
    );
  }
}
