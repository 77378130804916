import { format, isPast, parseISO, isDate, isValid } from 'date-fns';
import { isEventInProgress } from 'models';

export function inProgress(event, endDifferenceInMinutes, startDifferenceInMinutes) {
  return isEventInProgress({
    startTime: event.start_time,
    endTime: event.end_time,
    endDifferenceInMinutes,
    startDifferenceInMinutes,
  });
}

export function isLive(event) {
  return inProgress(event);
}

export function isWithinTime(event) {
  return inProgress(event, 0);
}

export function createGroups(data) {
  return [...data].reduce((acc, event) => {
    const parsedDate = parseISO(event.start_time);
    const isValidDate = isDate(parsedDate) && isValid(parsedDate);

    const date = isValidDate ? format(parsedDate, 'd-MMM-yy') : '';
    if (!acc[date]) {
      acc[date] = [];
    }
    isLive(event) ? acc[date].unshift(event) : acc[date].push(event);
    return acc;
  }, {});
}

export function isOpenToResponse(event) {
  return !isPast(parseISO(event.end_time));
}

export function isOpenToJoin(event) {
  if (event.bmg_id && event.current_user_rsvp === 'going') {
    const offset = {
      moderator: -60,
      presenter: -60,
      attendee: -15,
    };

    return inProgress(event, 58, offset[event.current_user_role] ?? offset.attendee);
  }

  return false;
}

export function makeCheckboxProps(formik) {
  return {
    ...formik.field,
    checked: formik.field.value === true,
    onChange: (event) => {
      formik.field.onChange({ target: { name: event.target.name, value: event.target.checked } });
    },
  };
}
