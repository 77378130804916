import { type FC } from 'react';
import { type RouteComponentProps, useLocation } from 'react-router-dom';

import { Header, ContentLeft, Footer, NewPassword, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

interface RouteParams {
  token: string;
}

interface P extends RouteComponentProps<RouteParams> {}

export type NewPasswordLocationState = OnboardingLocationState & { email?: string };

const PageNewPassword: FC<P> = ({ match }) => {
  const { state } = useLocation<NewPasswordLocationState | undefined>();
  const { onNavigateToSignUp, onReplaceToLogin, onNavigateToPrimaryLogin } =
    useOnboardingNavigation<NewPasswordLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Sign up',
      type: 'primary',
      onClick() {
        onNavigateToSignUp();
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={
        <NewPassword
          onSuccessChangePassword={onReplaceToLogin}
          token={match.params.token}
          email={state?.email}
          onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
        />
      }
      footer={<Footer />}
    />
  );
};

export default PageNewPassword;
