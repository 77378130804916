import { isProgramContentObject, type ContentObjectRedux, type ReduxUser } from 'models';

const areAllCommunitiesPublic = (contentObject: ContentObjectRedux) => {
  return contentObject.post_in_communities.every((community) => community.privacy === 'public');
};

const isSomeCommunitySecret = (contentObject: ContentObjectRedux) => {
  return contentObject.post_in_communities.some((community) => community.privacy === 'secret');
};

const canAdminRepostInPrivateCommunity = (contentObject: ContentObjectRedux, viewer: ReduxUser) => {
  return (
    !isSomeCommunitySecret(contentObject) &&
    contentObject.post_in_communities.every(
      (community) =>
        community.privacy === 'private' && (viewer.superadmin || viewer.admin_communities.includes(community.id)),
    )
  );
};

export const canRepostContentObject = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject: ContentObjectRedux;
}) => {
  if (isProgramContentObject(contentObject)) {
    return false;
  }

  if (isSomeCommunitySecret(contentObject)) {
    return false;
  }

  return areAllCommunitiesPublic(contentObject) || canAdminRepostInPrivateCommunity(contentObject, viewer);
};
