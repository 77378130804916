import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans, withTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns';

import { Box } from '../atoms/box/Box';
import { downloadFile } from '../atoms/file-downloader';
import { EventEditor } from '../atoms/event-editor';
import { EventFetcher } from '../smart-atoms/event-fetcher';
import { EventMenu } from './EventMenu';
import { EventService } from '../services/EventService';
import { H1 } from '../atoms/text';
import { Loader } from '../atoms/loader';
import { Pill } from '../atoms/pill';
import { Text } from '../atoms/text';
import { TopBarLink } from '../layouts/TopBarLink';
import { withPermissions } from '../atoms/auth';
import { Icon } from '../atoms/icon';
import { ChangeEventDuringApprovalModal } from 'features/events/components/ChangeEventDuringApprovalModal';
import { getLastChangeRequestStatusRedux } from 'features/events/utils';
import { Card, Link } from 'ui';
import { images } from 'common/utils';
import { canEditContentObject, canDeleteContentObject, canReportContentObject } from 'permissions';

class EventLayoutRenderer extends React.Component {
  state = {
    isEditorVisible: this.props.location.pathname.includes('/edit'),
    isDuplicateEvent: false,
    isDraftEdit: false,
    isDraftDelete: false,
  };

  downloadCalendar = (event) => {
    downloadFile(() => EventService.calendar(event.id), `${event.name}.ics`);
  };

  openModal = (isDraftEvent) => {
    if (isDraftEvent) {
      this.setState({ isDraftEdit: true });
    } else {
      this.setState({ isEditorVisible: true });
    }
  };

  openDeleteModal = () => {
    this.setState({ isDraftDelete: true });
  };

  openDuplicateModal = () => {
    this.setState({ isDuplicateEvent: true, isEditorVisible: true });
  };

  closeModal = () => {
    this.setState({ isEditorVisible: false, isDuplicateEvent: false, isDraftEdit: false, isDraftDelete: false });
  };

  renderSettingsElement = (event) => {
    const isProgramEvent = event.program || event.program_id;
    const duplicateEnabled = !isProgramEvent;

    const isDraftEvent = event.status === 'draft' && getLastChangeRequestStatusRedux(event) === 'pending';

    let menu = (
      <EventMenu
        onEditEvent={
          canEditContentObject({ viewer: this.props.user, contentObject: event, view: 'detail' })
            ? () => this.openModal(isDraftEvent)
            : undefined
        }
        onDeleteEvent={
          canDeleteContentObject({ viewer: this.props.user, contentObject: event, view: 'detail' })
            ? isDraftEvent
              ? this.openDeleteModal
              : () => this.props.onDeleteEvent(event)
            : false
        }
        onDuplicateEvent={duplicateEnabled && this.openDuplicateModal}
      />
    );

    if (event?.author?.id === this.props.user.id) {
      return menu;
    }

    return withPermissions(
      [
        { scope: 'superadmin' },
        {
          scope: 'manage-community',
          subj: event?.post_in_communities?.map((community) => community.id) || [],
        },
      ],
      menu,
      <EventMenu onReportEvent={() => this.props.onReportEvent({ ...event, id: event.contentObjectId })} />,
    );
  };

  render() {
    const { calendars } = this.props.user;

    return (
      <EventFetcher
        eventId={this.props.eventId}
        renderLoading={() => <Loader />}
        renderLoaded={(data, loadData) => {
          return (
            <>
              <div className="event">
                <Card shrinked>
                  <div className="event__header">
                    <div className="event__header__image">
                      <img src={data?.cover_photo?.url ?? images.default.eventCoverPhoto} alt={data.name} />
                    </div>
                    <div className="event__name">
                      <Box alignItems="center">
                        <H1 weight="100" color="white" style={{ marginBottom: 10, textAlign: 'center' }} id={'title'}>
                          {data.name}
                        </H1>
                        <Text
                          color="white"
                          size="17px"
                          weight="100"
                          style={{ marginBottom: 15 }}
                          id={'duration_description'}
                        >
                          {isSameDay(data.start_time, data.end_time)
                            ? this.props.t('eventLayoutDurationSameDate', {
                                dateFrom: new Date(data.start_time),
                                dateTo: new Date(data.end_time),
                              })
                            : this.props.t('eventLayoutDuration', {
                                dateFrom: new Date(data.start_time),
                                dateTo: new Date(data.end_time),
                              })}
                          {data.place}
                        </Text>

                        {calendars && calendars.length === 0 ? (
                          <React.Fragment>
                            {!is_past(data.end_time) ? (
                              <Text
                                onClick={() => this.downloadCalendar(data)}
                                color="white"
                                size="15px"
                                weight="100"
                                style={{ marginBottom: 15, cursor: 'pointer' }}
                              >
                                <Trans>Add To Calendar</Trans>
                              </Text>
                            ) : null}

                            <Link
                              to={`/profile/calendar-sync?r=${this.props.location.pathname}`}
                              style={{
                                marginBottom: 15,
                                color: '#ffffff',
                                fontSize: 15,
                                fontWeight: 100,
                                textDecoration: 'none',
                              }}
                            >
                              <Trans>Calendar Sync</Trans>
                            </Link>
                          </React.Fragment>
                        ) : null}

                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {data.post_in_communities
                            ? data.post_in_communities.map((community) => (
                                <Link
                                  to={`/communities/${community.id}`}
                                  key={community.id}
                                  id={`community-${community.id}`}
                                  style={{ padding: 5, cursor: 'pointer' }}
                                >
                                  <Pill color="white" title={community.name} />
                                </Link>
                              ))
                            : null}
                        </div>
                      </Box>
                    </div>
                  </div>
                  <Box className="event__navbar">
                    <TopBarLink to={`/events/${data.id}/about`} id="about">
                      <Trans>About</Trans>
                    </TopBarLink>
                    <TopBarLink to={`/events/${data.id}/attendees`} id="attendees">
                      <Trans>Registered</Trans>
                      {` (${data.going_invitees_count || 0})`}
                    </TopBarLink>
                    <div className="event__menu-container">
                      {this.renderSettingsElement(data)}
                      <div className="event__menu-container__bookmark">
                        <Icon
                          name={`pba-bookmark${data?.bookmarked ? '-filled' : '-empty'}`}
                          size={25}
                          color="#717E94"
                          onClick={async () => {
                            await this.props.onBookmarkEvent({ ...data, id: data.top_parent_id });
                            loadData();
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                </Card>
                {this.props.children(data)}
              </div>
              <ChangeEventDuringApprovalModal
                type={this.state.isDraftDelete ? 'isDraftDelete' : this.state.isDraftEdit ? 'isDraftEdit' : undefined}
                closeModal={this.closeModal}
              />
              <EventEditor
                draft={data.event}
                editorType={this.state.isDuplicateEvent ? 'duplicate' : 'update'}
                isEditorOpen={this.state.isEditorVisible}
                onRequestClose={this.closeModal}
                onSuccess={(event) => {
                  if (this.state.isDuplicateEvent) {
                    this.props.onDuplicateEvent?.(event);
                  }

                  this.closeModal();
                  loadData();
                  this.props.onEventEdit?.();
                }}
              />
            </>
          );
        }}
      />
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const EventLayout = withTranslation()(connect(mapState)(EventLayoutRenderer));
