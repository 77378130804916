import { createModel } from '@rematch/core';
import { normalize, denormalize } from 'normalizr';

import { dispatch, getState } from '../store';
import { AppService } from '../services/AppService';
import { AppConfigSchema } from './schemas';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [AppConfigSchema] : AppConfigSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);
  return normalizedData.result;
}

const INITIAL_STATE = [];

export const model = createModel({
  name: 'adminAppConfigs',
  state: INITIAL_STATE,
  reducers: {
    'session/reset': () => INITIAL_STATE,

    set: (state, payload) => {
      return payload;
    },

    push: (state, name) => {
      return state.includes(name) ? state : state.concat(name);
    },

    delete: (state, payload) => {
      return state.filter((name) => name !== payload.name);
    },
  },
  selectors: {
    find(state, name) {
      return denormalize(name, AppConfigSchema, getState().entities);
    },

    get(state) {
      return denormalize(state, [AppConfigSchema], getState().entities);
    },
  },
  effects: (dispatch) => ({
    async getAsync() {
      const response = await AppService.get();

      if (response.ok) {
        this.set(normalizeData(response.data));
      }

      return response;
    },

    async createAsync(payload) {
      const response = await AppService.create(payload);

      if (response.ok) {
        this.push(normalizeData(response.data));
      }

      return response;
    },

    async updateAsync(name, payload) {
      const response = await AppService.update(name, payload);

      if (response.ok) {
        this.push(normalizeData(response.data));
      }

      return response;
    },

    async findAsync(name) {
      const response = await AppService.getConfig(name);
      if (response.ok) {
        this.push(normalizeData(response.data));
      }
      return response.data;
    },

    async deleteAsync(name) {
      const response = await AppService.delete(name);

      if (response.ok) {
        this.delete({ name });
      }

      return response;
    },
  }),
});
