import { type Community, type ReduxUser } from 'models';
import { useParams } from 'react-router-dom';
import { CommunityLayout, CommunityEvents } from 'features/communities';
import { useFeedbackOptions } from '../hooks';

type CommunityEventsParams = { communityId: string };

type P = {
  community: Community;
  onStatusChange: () => void;
  viewer: ReduxUser;
};

const PageCommunityEvents = ({ viewer, onStatusChange }: P) => {
  const { communityId } = useParams<CommunityEventsParams>();
  const feedbackOptions = useFeedbackOptions();

  return (
    <CommunityLayout
      viewer={viewer}
      communityId={communityId}
      tab="/events"
      onStatusChange={onStatusChange}
      children={(community) => (
        <CommunityEvents viewer={viewer} community={community} feedbackOptions={feedbackOptions} />
      )}
    />
  );
};

export default PageCommunityEvents;
