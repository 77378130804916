import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin as SpinA } from 'antd';
import { ComponentProps, FC } from 'react';

type P = ComponentProps<typeof SpinA>;

const Spin: FC<P> = ({ size = 'large', ...props }: P) => {
  const icon = <Loading3QuartersOutlined />;

  return <SpinA {...props} size={size} indicator={icon} />;
};

export default Spin;
