import * as React from 'react';

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';
const API_KEY = process.env.REACT_APP_DROPBOX_API_KEY;

export class DropboxChooser extends React.Component {
  async componentDidMount() {
    this.injectDropboxScripts();
  }

  componentDidUpdate() {
    if (this.props.opened) {
      this.onSelect();
      this.props.onDropdownClose?.();
    }
  }

  injectDropboxScripts() {
    if (!document.getElementById(SCRIPT_ID)) {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src = DROPBOX_SDK_URL;
      script.dataset.appKey = API_KEY;
      if (document.head) {
        document.head.appendChild(script);
      }
    }
  }

  onSelect = () => {
    window.Dropbox.choose({
      success: this.props.onSelect,
      cancel: this.props.onCancel,
      linkType: 'direct', // or "preview"
    });
  };

  render() {
    return React.cloneElement(this.props.children, { onClick: this.onSelect });
  }
}
