import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Title } from 'ui';
import { LinkWasSent, PasswordLessLoginForm } from '../components';
import { useRequestMagicLinkQuery } from '../queries';

type P<T extends { referrer?: string }> = {
  onNavigateToPrimaryLogin: () => void;
  onReplaceCheckMagicLink: () => void;
  state?: T;
};

const PasswordlessLogin = <T extends { referrer?: string }>({
  state,
  onReplaceCheckMagicLink,
  onNavigateToPrimaryLogin,
}: P<T>) => {
  const { t } = useTranslation();
  const { mutate: requestMagicLink, isLoading: isRequestLoading } = useRequestMagicLinkQuery();

  const [wasSent, setWasSent] = useState({
    wasSent: false,
    email: '',
  });

  const onSubmit = (submitData: { email: string }) => {
    requestMagicLink(
      { ...submitData, redirectUrl: state?.referrer ?? '' },
      {
        onSuccess() {
          setWasSent({ email: submitData.email, wasSent: true });
        },
        onError() {
          message.error(t('Something went wrong, please try again later.'));
        },
      },
    );
  };

  const requestAnotherMagicLink = () => {
    onSubmit({ email: wasSent.email });
  };

  return (
    <>
      {wasSent.wasSent ? (
        <LinkWasSent
          onUserClickBack={onNavigateToPrimaryLogin}
          requestAnotherMagicLink={requestAnotherMagicLink}
          onReplaceCheckMagicLink={onReplaceCheckMagicLink}
          isRequestLoading={isRequestLoading}
        />
      ) : (
        <>
          <Title level={1}>{t('Send email link')}</Title>
          <div className="onboarding-passwordless-login__form-wraper">
            <PasswordLessLoginForm
              onSubmit={onSubmit}
              loading={isRequestLoading}
              onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PasswordlessLogin;
