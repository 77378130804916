import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans, withTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { UserZoneLayout, TABS } from '../../layouts/UserZoneLayout';
import { InviteSideNav } from './InviteSideNav';
import { Text, H4 } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { CommunitiesField } from '../../atoms/form/smart-fields';
import { Button } from '../../atoms/button';
import { showSuccessErrorAlert, showAlert } from '../../atoms/alert';
import { UserService } from '../../services/UserService';
import { ReactMultiEmail, isEmail } from 'ui';

const INITIAL_VALUES = {
  community_ids: [],
  emails: [],
};

const users = {
  new: new Set(),
  existing: new Set(),
};

export class Renderer extends React.Component {
  componentDidMount() {
    users.new = new Set();
  }

  validateSubmittedEmails = async (emails) => {
    const existingEmails = emails.filter((email) => users.existing.has(email));
    const potentialNewEmails = emails.filter((email) => {
      return !users.new.has(email) && !users.existing.has(email);
    });

    for (let start = 0; start < potentialNewEmails.length; start += 5) {
      const part = potentialNewEmails.slice(start, start + 5);

      const results = await Promise.all(
        part.map(async (email) => {
          return {
            email,
            response: await UserService.exists({ email }),
          };
        }),
      );

      for (const { email, response } of results) {
        if (response.ok) {
          existingEmails.push(email);
          users.existing.add(email);
        } else {
          users.new.add(email);
        }
      }
    }

    return existingEmails;
  };

  onSuccess = () => {
    showAlert({ type: 'success', message: <Trans>Successfully invited!</Trans> });
  };

  onSubmit = async (values, formik) => {
    const result = {
      emails: values.emails,
    };

    formik.setSubmitting(true);

    if (result.emails.length > 0) {
      const existingEmails = await this.validateSubmittedEmails(result.emails);
      if (existingEmails.length) {
        const start = existingEmails.length > 1 ? this.props.t('These users') : this.props.t('This user');

        const emails = existingEmails.map((email) => `"${email}"`).join(', ');

        const end =
          existingEmails.length > 1 ? this.props.t('are already on the app.') : this.props.t('is already on the app.');

        formik.setFieldError('emails', `${start} ${emails} ${end}`);

        formik.setSubmitting(false);
        return;
      }
    }

    const communitiesData = [];

    for (const community of values.community_ids) {
      for (const email of values.emails) {
        communitiesData.push({
          change_request: {
            kind: 'community_invitation',
            subject_type: 'Community',
            subject_id: community.id,
            meta: {
              invitation_email: email,
            },
          },
        });
      }
    }

    const response = await this.props.inviteNonRegisteredUsers(communitiesData);

    showSuccessErrorAlert({
      isOk: response.ok,
      successMessage: <Trans>Successfully invited!</Trans>,
      errorMessage: response?.data?.message,
    });

    formik.setSubmitting(false);

    if (response.ok) {
      formik.setValues(INITIAL_VALUES);
    }
  };

  render() {
    return (
      <UserZoneLayout navigation={<InviteSideNav />} contentId={TABS.inviteFriends}>
        <ThemeConsumer>
          {(theme) => (
            <div>
              <H4 color={theme.color.lightGray}>
                <Trans>SEND INVITATIONS</Trans>
              </H4>
              <Formik enableReinitialize initialValues={INITIAL_VALUES} onSubmit={this.onSubmit}>
                {(formik) => (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        paddingBottom: 5,
                        margin: '5px 0',
                        borderBottom: '1px solid #eceff1',
                      }}
                    >
                      <Text style={{ width: 70, lineHeight: '36px' }}>
                        <Trans>Invite to:</Trans>
                      </Text>
                      <CommunitiesField
                        placeholder={this.props.t('Search Community')}
                        name="community_ids"
                        scope="joined"
                        currentSelectedData={formik.values.community_ids}
                        id="invite-to-community"
                      />
                    </div>
                    <div style={{ margin: '5px 0' }} id="email-addresses">
                      <Text size="12px" style={{ display: 'inline-block', padding: '10px 5px' }}>
                        <Trans>
                          Type email address and press enter, space or comma - Or copy/paste from a spreadsheet!
                        </Trans>
                      </Text>
                      <ReactMultiEmail
                        placeholder={this.props.t('Email addresses...')}
                        emails={formik.values.emails}
                        style={{ minHeight: 254, marginBottom: 32 }}
                        onChange={(emails) => {
                          formik.setFieldValue('emails', emails);
                        }}
                        validateEmail={(email) => isEmail(email)}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              {email}
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                      {formik.errors.emails && (
                        <Text color="red" style={{ paddingTop: 5, display: 'inline-block' }}>
                          {formik.errors.emails}
                        </Text>
                      )}
                    </div>
                    <div style={{ paddingTop: 10 }}>
                      <Button
                        size="md"
                        title={formik.isSubmitting ? <Trans>Inviting...</Trans> : <Trans>Invite</Trans>}
                        type="submit"
                        color={theme.color.successColor}
                        onClick={formik.submitForm}
                        textColor="white"
                        style={{ marginTop: 10 }}
                        disabled={
                          formik.isSubmitting ||
                          formik.values.community_ids.length < 1 ||
                          formik.values.emails.length < 1
                        }
                        id="btn-invite"
                      />
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          )}
        </ThemeConsumer>
      </UserZoneLayout>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

const mapDispatch = (dispatch, props) => ({
  inviteNonRegisteredUsers: async (params) => {
    return dispatch.communities.inviteUsers({ params });
  },
});

export const PageInviteFriends = withTranslation()(connect(mapState, mapDispatch)(Renderer));
