import cn from 'classnames';
import { ComponentProps, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Empty, Select, Spin } from '../';

export type { LabeledValue } from 'antd/lib/select';

export type SelectItem = {
  label: string;
  value: string;
};

type LoadableData<T> = {
  state: 'hasError' | 'hasValue' | 'loading';
  contents: T[];
};

type P<T> = Omit<ComponentProps<typeof Select>, 'onSearch' | 'options'> & {
  options?: LoadableData<T> | T[];
  onSearch?: (value: string) => void;
  createOption?: (option: T) => SelectItem;
  createCustomOptions?: () => JSX.Element | JSX.Element[] | undefined;
  loadingText?: string;
};

// TODO put all logic in to useReducer

const SelectSearch = <T extends Record<string, unknown>>({
  className,
  options: data,
  createOption,
  createCustomOptions,
  onSearch,
  loading = false,
  filterOption = false,
  size = 'large',
  loadingText,
  ...rest
}: P<T>) => {
  const { t } = useTranslation(['translationChat']);
  let isLoading = loading;
  let selectOptions: SelectItem[] | undefined;

  const { customSelectClassName } = useMemo(
    () => ({
      customSelectClassName: cn('select-search', className),
    }),
    [className],
  );

  if (Array.isArray(data)) {
    if (createOption) {
      selectOptions = data.map((item: T) => createOption(item));
    }
    // eslint-disable-next-line sonarjs/elseif-without-else
  } else if (data) {
    switch (data.state) {
      case 'loading':
        isLoading = true;
        break;
      case 'hasValue':
        isLoading = false;
        if (createOption) {
          selectOptions = data.contents.map((item: T) => createOption(item));
        }

        break;
      case 'hasError':
        isLoading = false;
        break;
    }
  }

  const renderNotFoundContent = isLoading ? <Spin tip={loadingText ?? t('chat.search.loading')} /> : <Empty />;

  return (
    <Select
      {...rest}
      className={customSelectClassName}
      filterOption={
        onSearch
          ? filterOption
          : (search, item) => {
              return (item?.label as string)?.toLocaleLowerCase().includes(search?.toLocaleLowerCase());
            }
      }
      size={size}
      loading={isLoading}
      notFoundContent={renderNotFoundContent}
      options={selectOptions}
      onSearch={onSearch}
    >
      {createCustomOptions?.()}
    </Select>
  );
};

export default SelectSearch;
