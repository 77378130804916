import * as React from 'react';

import { Text } from '../text';
import { ThemeConsumer } from '../theme';

export function Pill({ color, title, truncate }) {
  return (
    <ThemeConsumer>
      {(theme) => {
        const COLOR = theme.color[color] || color;
        return (
          <div className={`pill ${truncate ? 'pill--truncate' : ''}`} style={{ borderColor: COLOR }} title={title}>
            <Text color={COLOR} size="12px" weight="500" className="pill__text">
              {title}
            </Text>
          </div>
        );
      }}
    </ThemeConsumer>
  );
}
