import { useTranslation } from 'react-i18next';
import { Row, Col, Button, AppLogo, type ButtonProps } from 'ui';

type P = {
  items: HeaderItem[];
};

export type HeaderItem = {
  label: string;
  type: ButtonProps['type'];
  onClick: () => void;
};

export const Header = ({ items }: P) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col item={{ span: 12, className: 'onboarding-header__header-left' }}>
        <AppLogo />
      </Col>
      <Col item={{ span: 12, className: 'onboarding-header__header-right' }}>
        {items.map((item, index) => (
          <Button
            {...item}
            className="onboarding-header__header-right__button"
            size="large"
            key={`onboarding-header-${index}`}
          >
            {t(item.label)}
          </Button>
        ))}
      </Col>
    </Row>
  );
};
