import { useMutation } from '@tanstack/react-query';

import { createJoinEvent, type JoinEvent } from 'models';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';
import { joinEvent } from '../services';

export const useEnterBigMarker = () => {
  return useMutation<
    JoinEvent,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    { userIds: string[]; eventId: string; email?: string; exitUrl?: string }
  >({
    async mutationFn({ userIds, eventId, email, exitUrl }) {
      const response = await joinEvent({ eventId, userIds, email, exitUrl });

      return createJoinEvent(response.data);
    },
  });
};
