import { transformError, transformResponse } from '../common/recoil/queries';
import { ID } from '../common/types';
import { Chatroom, Message } from '../models';
import { getChatroom as getChatroomRequest } from '../services';
import { ChatroomDataObject } from '../services/data-objects';

const getChatroom = async (chatroomId: ID, practiceId: ID | null) => {
  try {
    const responseChatroom = await getChatroomRequest(chatroomId);
    const chatroomData = transformResponse<ChatroomDataObject, Chatroom>(
      responseChatroom,
      ({ statuses, message, ...rest }) =>
        new Chatroom({
          ...rest,
          message: message ? new Message(message) : undefined,
          statuses: [],
        }),
    );

    const chatroom = chatroomData.result;

    return {
      error: null,
      result: {
        chatroom,
      },
    };
  } catch (error) {
    return transformError(error);
  }
};

export default getChatroom;
