import { type ComponentProps } from 'react';
import { LightLineIcon } from 'ui';
import { type UserTodo } from '../../../../types';
import { BaseTodoWidgetItem } from '../BaseTodoWidgetItem';

type P = {
  type?: ComponentProps<typeof BaseTodoWidgetItem>['type'];
  todo: UserTodo;
  className?: string;
};

const UserTodoWidgetItem = ({ type, todo, className }: P) => {
  return (
    <BaseTodoWidgetItem
      type={type}
      className={className}
      path={todo.path}
      icon={<LightLineIcon />}
      title="Onboarding"
      description="Make it yours!"
      additionalDescription="Express yourself with a profile picture! Your phone number, date of birth, and timezone are only shared with your organization’s administrators."
      mode="by"
      time={todo.endTime}
    />
  );
};

export default UserTodoWidgetItem;
