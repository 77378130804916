import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import qs from 'qs';
import { Trans } from 'react-i18next';

import { AppLayout } from '../../layouts/AppLayout';
import { Loader } from '../../atoms/loader';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { InViewport } from '../../atoms/in-viewport';

import { searchItemTypes } from '../../atoms/global-search/GlobalSearch';
import { NoResultsItem } from '../../atoms/global-search/search-items';
import { SearchBox } from './SearchBox';
import { Card } from 'ui';

const RESULTS_QUERY_LIMIT = 20;

export class Renderer extends React.Component {
  state = {
    hasMoreResults: true,
  };

  componentWillUnmount() {
    this.props.resetResults();
  }

  componentWillUpdate(nextProps) {
    if (
      // reset current results on new request
      this.props.location.search !== nextProps.location.search ||
      this.props.match.params.scope !== nextProps.match.params.scope
    ) {
      this.props.resetResults();
      this.setState({
        hasMoreResults: true,
      });
      const query = qs.parse(nextProps.location.search, { ignoreQueryPrefix: true });
      this.props.getResults({
        scope: nextProps.match.params.scope,
        query: query?.query,
      });
    }
  }

  getNextResults = async () => {
    if (this.props.loadingResults === false) {
      const lastResult = this.props.results[this.props.results.length - 1];
      const resp = await this.props.getResults({
        cursor: lastResult ? lastResult.id : undefined,
      });
      this.setState({ hasMoreResults: resp.data.length > 0 });
    }
  };

  renderSearchHeader = () => {
    const type = this.props.match.params.scope;

    return (
      <Text color="#90A4AE" style={{ textTransform: 'uppercase', fontSize: 12 }}>
        <Trans>Results for</Trans>: "{this.props.query}" ({searchItemTypes[type].header})
      </Text>
    );
  };

  renderResults = () => {
    const type = this.props.scope;

    return this.props.results.map((item) => (
      <SearchBox key={item.id} onClick={() => searchItemTypes[type].path({ history: this.props.history, item })}>
        {searchItemTypes[type].component({
          title: searchItemTypes[type].itemToString(item),
          itemProps: item,
          user: this.props.user,
        })}
      </SearchBox>
    ));
  };

  render() {
    const { scope, loadingResults } = this.props;
    return (
      <AppLayout
        center={
          <ThemeConsumer>
            {(theme) => (
              <Card shrinked title={this.renderSearchHeader()}>
                <div style={{ padding: '20px 0' }}>
                  {!scope && !loadingResults ? <NoResultsItem /> : null}
                  {scope ? this.renderResults() : null}
                  {loadingResults ? (
                    <Loader />
                  ) : this.state.hasMoreResults ? (
                    <InViewport key="page-global-viewport" onEnter={this.getNextResults} />
                  ) : null}
                </div>
              </Card>
            )}
          </ThemeConsumer>
        }
      />
    );
  }
}

const mapState = (state) => ({
  loadingResults: state.loading.effects.globalSearch.get,
  results: select.globalSearch.collection(state),
  query: select.globalSearch.query(state),
  scope: select.globalSearch.scope(state),
  user: select.session.user(state),
});

const mapDispatch = (dispatch, props) => ({
  getResults: (params) => {
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    return dispatch.globalSearch.get({
      limit: RESULTS_QUERY_LIMIT,
      scope: props.match.params.scope,
      query: query?.query,
      ...params,
    });
  },
  resetResults: () => dispatch.globalSearch.resetResults(),
});

export const PageGlobalSearch = connect(mapState, mapDispatch)(Renderer);
