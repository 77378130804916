import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { select } from '@rematch/select';

import { Modal } from '../modal';
import { CourseDuplicateForm } from './CourseDuplicateForm';
import { showSuccessErrorAlert } from '../alert';

class Renderer extends React.Component {
  state = {
    loading: false,
  };

  autocompleteData = (value) => {
    return this.props.user.joined_communities.filter((community) =>
      community.name.toLowerCase().includes(value ? value.toLowerCase() : ''),
    );
  };

  duplicateCourse = async (result) => {
    if (await window.confirm(this.props.t('Are you sure you want to duplicate this program?'))) {
      this.setState({ loading: true });
      const response = await this.props.duplicateCourse(this.props.program, result.start_date, result.duplicate_users);
      this.setState({ loading: false });

      if (response.ok) {
        this.props.redirect(`/courses/${response.data.id}`);
      }

      showSuccessErrorAlert({
        isOk: response.ok,
        successMessage: <Trans>Program successfully duplicated.</Trans>,
        errorMessage: response.data?.message,
      });
    }
  };

  closeModal = () => {
    this.props.onRequestClose();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isDuplicateOpen}
        onRequestClose={this.closeModal}
        title={<Trans>Duplicate program</Trans>}
        showFooter={false}
      >
        {() => (
          <div style={{ padding: '0 25px' }}>
            <CourseDuplicateForm
              onSubmit={this.duplicateCourse}
              program={this.props.program}
              loading={this.state.loading}
            />
          </div>
        )}
      </Modal>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
});

const mapDispatch = (dispatch, props) => ({
  duplicateCourse: (course, start_date, duplicate_users) =>
    dispatch.courses.duplicate({ id: course.id, start_time: start_date, duplicate_users: duplicate_users }),
});

export const CourseDuplicate = withTranslation()(connect(mapState, mapDispatch)(Renderer));
