import * as React from 'react';
import { Formik } from 'formik';
import { Trans, withTranslation } from 'react-i18next';
import { isToday, isPast, parseISO, isDate } from 'date-fns';

import { Button } from '../button';
import { RadioGroup, DateTimeField } from '../form';
import { ThemeConsumer } from '../theme';
import { validator } from '../../utils/validator';
import { Loader } from '../../atoms/loader';

const RULES = {
  start_date: 'required',
  duplicate_users: 'required',
};

export class Renderer extends React.Component {
  onSubmit = async (values, formik) => {
    formik.setSubmitting(true);

    const parsedStartDate = isDate(values.start_date) ? values.start_date : parseISO(values.start_date);
    if (isPast(parsedStartDate) || isToday(parsedStartDate)) {
      formik.setFieldError('start_date', this.props.t(`Please choose date in the future`));
      return;
    }

    const result = {
      ...values,
      start_date: parsedStartDate,
    };

    const response = await this.props.onSubmit(result);
    formik.setSubmitting(false);

    if (response?.ok) {
      this.props.onSuccess(response.data);
    }
  };

  onStartDateChange(formik) {
    return (e) => {
      const { name, value } = e.target;
      formik.setFieldValue(name, value);
    };
  }

  render() {
    const { program } = this.props;

    return (
      <Formik
        validate={(values, props) => {
          return validator.validateAll(values, RULES);
        }}
        initialValues={{
          start_date: program.start_time || new Date(),
          duplicate_users: true,
        }}
        onSubmit={this.onSubmit}
      >
        {(formik) => (
          <ThemeConsumer>
            {(theme) => {
              return (
                <>
                  <div className="duplicate-form__date">
                    <DateTimeField
                      name="start_date"
                      label={<Trans>Start date</Trans>}
                      range={{ before: [100, 'year'], after: [10, 'year'] }}
                      onChange={this.onStartDateChange(formik)}
                    />
                  </div>

                  <div className="duplicate-form__users">
                    <RadioGroup
                      inline
                      name="duplicate_users"
                      values={[
                        {
                          value: true,
                          label: <Trans>Duplicate with users</Trans>,
                        },
                        {
                          value: false,
                          label: <Trans>Duplicate without users</Trans>,
                        },
                      ]}
                    />
                  </div>

                  <Button
                    size="lg"
                    title={
                      this.props.loading ? (
                        <Loader color={theme.color.lightGray} size={30} />
                      ) : (
                        this.props.t('Duplicate')
                      )
                    }
                    color={theme.color.brand}
                    onClick={formik.submitForm}
                    textColor="white"
                    disabled={this.props.loading}
                    style={{ padding: '0 60px' }}
                  />
                </>
              );
            }}
          </ThemeConsumer>
        )}
      </Formik>
    );
  }
}

export const CourseDuplicateForm = withTranslation()(Renderer);
