import * as React from 'react';
import { Trans } from 'react-i18next';
import { Icon } from '../icon';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { PostEditor } from './PostEditor';
import { EMPTY_DRAFT } from './emptyDraftState';
import { HeaderWithAccessoryView } from './components';
import { Modal } from 'ui';

export class PostEditorTrigger extends React.Component {
  state = {
    editorIsActive: false,
  };

  getEmptyDraft = () => ({
    ...EMPTY_DRAFT,
    post_in_communities: this.props.initialPostInCommunities ? this.props.initialPostInCommunities : [],
  });

  render() {
    return (
      <React.Fragment>
        {this.props.draft !== undefined && ['post', 'kudo'].includes(this.props.draft.type) && (
          <Modal
            title={
              <React.Fragment>
                <Trans>What is on your mind</Trans>, {this.props.viewer.first_name}?
              </React.Fragment>
            }
            isOpened={true}
            onCancel={this.props.onRequestClose}
          >
            {this.props.draft ? (
              <PostEditor
                fields={[
                  'post_in_communities',
                  'html_content',
                  'attachments',
                  'pinned',
                  'kudos',
                  'emojis',
                  'whiteboard',
                ]}
                draft={this.props.draft}
                viewer={this.props.viewer}
                onSubmit={this.props.onSubmit}
                maxWidth={this.props.maxWidth}
              />
            ) : null}
          </Modal>
        )}
        {this.state.editorIsActive === false ? (
          <ThemeConsumer>
            {(theme) => {
              return (
                !this.props.isDisabled && (
                  <div
                    onClick={() => this.setState({ editorIsActive: true })}
                    data-test-id="panel_start-conversation"
                    style={{ cursor: 'pointer', width: '100%' }}
                  >
                    <HeaderWithAccessoryView viewer={this.props.viewer}>
                      <React.Fragment>
                        <Text size="14px" weight={300} color={theme.color.lightGray} id="item_start-conversation">
                          <Trans>Create post...</Trans>
                        </Text>
                        <Icon size={28} name="pba-attachment" color={theme.color.lightGray} />
                      </React.Fragment>
                    </HeaderWithAccessoryView>
                  </div>
                )
              );
            }}
          </ThemeConsumer>
        ) : (
          <PostEditor {...this.props} draft={this.getEmptyDraft()} />
        )}
      </React.Fragment>
    );
  }
}
