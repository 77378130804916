import { type NodeUser, type ReduxUser } from 'models';
import { useEffect } from 'react';
import { useNodeUserQuery } from 'features/chat/queries';
import { Loading } from 'ui';

type P = {
  viewer: ReduxUser;
  memberId: string;
  navigateToChat: (user: NodeUser) => void;
  navigateToProfile: (userId: string) => void;
};

const MemberMessage = ({ viewer, memberId, navigateToChat, navigateToProfile }: P) => {
  const {
    data: member,
    isFetching: isFetchingMember,
    error: memberFetchError,
  } = useNodeUserQuery({ railsId: memberId }, { useErrorBoundary: false });

  useEffect(() => {
    if (member && !isFetchingMember) {
      if (viewer.id === memberId) {
        navigateToProfile(memberId);
      } else {
        navigateToChat(member);
      }
    }
  }, [member, isFetchingMember, navigateToChat, viewer.id, memberId, navigateToProfile]);

  useEffect(() => {
    if (memberFetchError) {
      navigateToProfile(memberId);
    }
  }, [memberFetchError, memberId, navigateToProfile]);

  return <Loading />;
};

export default MemberMessage;
