import { useTranslation } from 'react-i18next';
import { Badge, MenuLinkItem } from '../../../../..';
import { type NavigationItem, type NavigationMenuItemConfig } from '../config';

export type BadgeOptions = {
  [key in NavigationItem]?: {
    count: number;
  };
};

const NavigationMenuLabel = ({
  config,
  isCollapsed,
  badges,
}: {
  config: NavigationMenuItemConfig;
  isCollapsed: boolean;
  badges?: BadgeOptions;
}) => {
  const { t } = useTranslation();

  if (config.badge && !isCollapsed) {
    return (
      <Badge className="navigation-menu-label-badge" color="red" count={badges?.[config.badge]?.count}>
        <MenuLinkItem className="navigation-menu-label-badge__link" to={config.href} text={t(config.text)} />
      </Badge>
    );
  }

  return <MenuLinkItem to={config.href} text={t(config.text)} />;
};

export default NavigationMenuLabel;
