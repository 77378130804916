import { AppLayout } from 'old/layouts/AppLayout';
import { useParams } from 'react-router-dom';
import { CustomChannelsDetail } from 'features/channels';
import { useGetPathToEntityDetail } from '../../hooks';

const PageCustomChannelsDetail = () => {
  const { channelId } = useParams<{ channelId: string }>();
  const getPathToEntityDetail = useGetPathToEntityDetail();

  return (
    <AppLayout center={<CustomChannelsDetail getPathToEntityDetail={getPathToEntityDetail} channelId={channelId} />} />
  );
};

export default PageCustomChannelsDetail;
