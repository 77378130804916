import axios, { type AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import qs from 'qs';
import { getCurrentNodeToken } from 'features/auth/services/nodeApi';
import { defaultHeadersInterceptor } from '../';

export const nodeApiClient = axios.create({
  responseType: 'json',
  withCredentials: true,
  baseURL: String(process.env.REACT_APP_NODE_API_URL),
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'indices' });
  },
});

nodeApiClient.interceptors.request.use((config) => defaultHeadersInterceptor(config));

nodeApiClient.interceptors.request.use((config) => {
  generateNodeAuthHeader(config);
  return config;
});

const generateNodeAuthHeader = <T extends AxiosRequestConfig>(config: T): T => {
  const accessToken = getCurrentNodeToken();
  if (accessToken && config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

export interface NodeApiClientResponse<T> extends AxiosResponse<T> {}

export interface NodeApiClientResponseError<T> extends AxiosError<T> {}

// TODO will be specified later
export type NodeApiError = unknown;

export type PaginationData<T> = {
  total: number;
  limit: number;
  skip: number;
  data: T[];
};
