import { Modal } from 'ui';
import { type SupportedOboardingTourStep } from '../../../../models';
import { OtherCalendarForm } from './OtherCalendarForm';

type P = {
  step: SupportedOboardingTourStep;
  isOpened: boolean;
  isLoading: boolean;
  onSuccess: (options: { calendars: string[] }) => Promise<void> | void;
  onCancel: () => void;
};

const OtherCalendarModal = ({ step, isOpened, isLoading, onSuccess, onCancel }: P) => {
  return (
    <Modal isOpened={isOpened} disableBack destroyOnClose onCancel={onCancel}>
      <OtherCalendarForm step={step} isLoading={isLoading} onSuccess={onSuccess} />
    </Modal>
  );
};

export default OtherCalendarModal;
