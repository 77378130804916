import { type RadioChangeEvent } from 'antd/lib/radio/interface';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Text } from 'ui';

type Item = {
  label: string;
  value?: string;
  icon?: ReactNode;
};

type P = {
  value?: string;
  onChange?: (value: RadioChangeEvent) => void;
  items: Item[];
};

const RadioPills = ({ value, onChange, items }: P) => {
  const { t } = useTranslation();

  return (
    <div className="radio-pills__wrapper">
      <Radio.Group onChange={onChange} value={value} size="large" className="radio-pills">
        {items.map((item) => (
          <Radio.Button className="radio-pills__item" value={item.value} key={item.value}>
            <Text className="radio-pills__item__content">
              {item.icon}
              {t(item.label)}
            </Text>
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default RadioPills;
