import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type StripePayment } from '../types';

type CreateStripeDonationRequest = {
  donation: {
    campaign_id: string;
    price_id: string;
    recaptcha_token: string;
    donation: {
      amount: string;
    };
    payer: {
      first_name: string;
      last_name: string;
      email: string;
      street: string;
      apt?: string;
      city: string;
      zip: string;
      country: string;
      state?: string;
    };
  };
};

export const createStripeDonation = async (data: CreateStripeDonationRequest) =>
  apiClient.post<StripePayment, ApiClientResponse<StripePayment>>(routes.campaigns.stripeDonation.create, data);
