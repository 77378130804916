import * as React from 'react';
import { Trans } from 'react-i18next';

import { Text, H3 } from '../../atoms/text';
import { Avatar, Link } from 'ui';
import { Box } from '../../atoms/box';
import { Icon } from '../../atoms/icon';
import { Menu, MenuButton, MenuList, MenuItem } from '../../atoms/menu';
import { ThemeConsumer } from '../../atoms/theme';
import numbro from 'numbro';
import { images, getCountryNameByCode } from 'common/utils';

export class ChequePledgePreview extends React.Component {
  formatAddress = () => {
    const { donation } = this.props;
    return (
      <React.Fragment>
        <Text size="12px" style={{ marginTop: 2 }}>
          {`${donation.first_name} ${donation.last_name} ${donation.email ? donation.email : ''}`}
        </Text>
        <Text size="12px">{`${donation.apt} ${donation.street}, ${donation.city} ${donation.zip}, ${getCountryNameByCode(
          donation.country,
        )}`}</Text>
      </React.Fragment>
    );
  };

  renderMenu = () => (
    <Menu>
      <MenuButton style={{ border: 0, background: 'none' }}>
        <Icon name="menu" size={20} />
      </MenuButton>
      <MenuList>
        <MenuItem onSelect={() => this.props.editCheque(this.props.donation)}>
          <Trans>Edit</Trans>
        </MenuItem>
        <MenuItem onSelect={() => this.props.deleteCheque(this.props.donation)}>
          <Trans>Delete</Trans>
        </MenuItem>
      </MenuList>
    </Menu>
  );

  render() {
    const { donation } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <div key={donation.id} style={{ borderBottom: '1px solid #ECEFF1', padding: '10px 0' }}>
            <div style={{ display: 'flex' }}>
              <div>
                <Avatar
                  size={35}
                  photo={donation.campaign.cover_photo?.url ?? images.default.campaignCoverPhoto}
                  name={donation.campaign.name}
                />
              </div>
              <div style={{ flex: 1, paddingLeft: 15 }}>
                <Link
                  style={{ textDecoration: 'inherit' }}
                  to={`/campaigns/listing/${donation.campaign.community.organization.id}/${donation.campaign.id}`}
                >
                  <div>
                    <Text weight="600" lineHeight="18px">
                      {donation.campaign.name}
                    </Text>
                  </div>
                  <Box flexDirection="column">{this.formatAddress()}</Box>
                </Link>
              </div>
              <Box alignItems="center" justifyContent="center" style={{ marginRight: 15 }}>
                {this.props.editCheque && this.props.deleteCheque ? this.renderMenu() : null}
              </Box>
              <Box alignItems="center" justifyContent="center" style={{ textAlign: 'right', width: 80 }}>
                <div>
                  <H3>{numbro(donation.amount).formatCurrency({ mantissa: 2, thousandSeparated: true })}</H3>
                </div>
              </Box>
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }
}
