import { NodeUser } from 'models';
import { useQuery } from 'query';
import { getUserIdFromLegacyId, type NodeApiClientResponseError, type NodeApiError } from 'common/services';
import { getUserNodeQuery } from './';

export const useNodeUserQuery = (
  { railsId }: { railsId: string },
  options: { enabled?: boolean; useErrorBoundary?: boolean } = {},
) =>
  useQuery<NodeUser, NodeApiClientResponseError<NodeApiError>>(
    getUserNodeQuery({ railsId }),
    async () => {
      const { data: responseData } = await getUserIdFromLegacyId(railsId);
      const [userDataObject] = responseData.data;

      if (!userDataObject) {
        throw new Error(`User not found ${railsId}`);
      }

      return new NodeUser(userDataObject);
    },
    {
      useErrorBoundary: true,
      ...options,
    },
  );
