import { type Location } from 'history';
import { type Community, type ReduxUser } from 'models';
// TODO once we get rid off redux replace this only for new AppLayout
import { hasMembershipInSubject } from 'models';
import { AppLayout } from 'old/layouts/AppLayout';
import qs from 'qs';
import { type ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { AxiosErrorHandler } from 'common/error-handlers';
import { canViewCommunityMembers } from 'permissions';
import { Card, ScrollToTop, Skeleton } from 'ui';

import { CommunityHeader, type CommunityTabItem, mapCommunityTabToPathName } from '../components';
import { useCommunityQuery } from '../queries';

const getInvitationToken = (location: Location) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (typeof query.invitation_token === 'string') {
    return query.invitation_token;
  }

  return undefined;
};

type P = {
  viewer: ReduxUser;
  communityId: string;
  tab: CommunityTabItem;
  onStatusChange: () => unknown;
  children: (community: Community) => ReactNode;
};

const CommunityLayout = ({ viewer, communityId, tab, onStatusChange, children }: P) => {
  const { replace, location } = useHistory();

  const invitationToken = getInvitationToken(location);
  const { isLoading, isFetching, isSuccess, data, error } = useCommunityQuery(communityId, invitationToken);

  const isMemberInCommunity = hasMembershipInSubject({ viewer, subjectType: 'Community', subjectId: communityId });

  // Permissions
  // TODO maybe move to somewhere else with business logic?
  if (invitationToken && isMemberInCommunity) {
    replace(mapCommunityTabToPathName(communityId, '/feed'));
    return null;
  }

  if (data && !canViewCommunityMembers({ viewer, community: data }) && tab === '/members') {
    replace(mapCommunityTabToPathName(communityId, '/feed'));
    return null;
  }

  if (isSuccess && !isFetching && tab !== '/about' && !isMemberInCommunity) {
    replace(mapCommunityTabToPathName(communityId, '/about'));
    return null;
  }

  return (
    <AppLayout
      center={
        <AxiosErrorHandler error={error}>
          {isLoading || !data ? (
            <Card>
              <Skeleton className="community-layout__header-skeleton" active avatar paragraph={{ rows: 12 }} />
            </Card>
          ) : (
            <>
              <Card shrinked>
                <CommunityHeader viewer={viewer} community={data} tab={tab} onStatusChange={onStatusChange} />
              </Card>
              <ScrollToTop />
              {children(data)}
            </>
          )}
        </AxiosErrorHandler>
      }
    />
  );
};

export default CommunityLayout;
