import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { can } from 'old/utils/permissions';

import { showAlert } from '../../atoms/alert';
import { canViewProgramMembersRedux } from 'permissions';

export class Renderer extends React.Component {
  componentDidMount = async () => {
    const currentPath = this.props.history.location.pathname;
    const response = await this.loadData();
    
    // program needs to be defined like this, because this.props.data are sometimes data from programs list
    const program = (response?.ok && response.data) ?? this.props.data;

    if (
      !can(program, 'Course', 'viewProgram') &&
      currentPath !== `/courses/${this.props.courseId}/about`
    ) {
      this.props.history.push(`/courses/${this.props.courseId}/about`);
    } else if (
      !canViewProgramMembersRedux({ viewer: this.props.user, program }) &&
      currentPath === `/courses/${this.props.courseId}/members`
    ) {
      this.props.history.push(`/courses/${this.props.courseId}/timeline`);
    }
  };

  loadData = async () => {
    const response = await this.props.loadData();

    if (!response.ok) {
      showAlert({ type: 'error', message: response.data.message });
    }

    return response;
  };

  render() {
    if (this.props.data?.name && this.props.fetchUsers && this.props.data.users) {
      return this.props.renderLoaded(this.props.data);
    } else if (this.props.data?.name) {
      return this.props.renderLoaded(this.props.data);
    } else {
      return this.props.renderLoading();
    }
  }
}

const mapState = (state, props) => ({
  data: select.courses.find(state, props.courseId),
});

const mapDispatch = (dispatch, props) => ({
  loadData: (data) => async () => {
    const response = await dispatch.courses.find(props.courseId);
    const program = (response?.ok && response.data) ?? data;

    if (props.fetchUsers && program?.settings?.view_members) {
      dispatch.courses.getUsers({ courseId: props.courseId, sort: 'name' });
    }
    return response;
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  loadData: dispatchProps.loadData(stateProps.data),
});

export const CourseFetcher = connect(mapState, mapDispatch, mergeProps)(Renderer);
