import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { DataCard, ProgramIcon } from 'ui';
import { type ProgramSlim } from '../../types';

type P = {
  getPathToProgramDetail: (id: string) => string;
  program: ProgramSlim;
  showPrice?: boolean;
  showTag?: boolean;
};

const ProgramCard = ({ getPathToProgramDetail, program, showPrice, showTag }: P) => {
  const { t } = useTranslation();

  const calcProgramPrice = (
    program: ProgramSlim,
  ): { currentPrice: number | 'free'; originalPrice: number | undefined } => {
    const originalPrice = Number.parseFloat(program.price ?? '0');
    const paidBySponsors =
      program.sponsors?.reduce((previous, current) => previous + Number.parseFloat(current.price ?? '0'), 0) ?? 0;
    const currentPrice = Math.max(originalPrice - paidBySponsors, 0);

    return {
      originalPrice: originalPrice === currentPrice ? undefined : originalPrice,
      currentPrice: currentPrice === 0 ? 'free' : currentPrice,
    };
  };

  return (
    <DataCard
      title={program.name}
      description={{ dateFrom: program.startTime, dateTo: program.endTime, dateFormat: 'dateRangePicker' }}
      prices={
        showPrice
          ? {
              currentPrice: calcProgramPrice(program).currentPrice,
              originalPrice: calcProgramPrice(program).originalPrice,
            }
          : undefined
      }
      image={{
        src: program.coverPhoto?.url ?? images.default.programCoverPhoto,
        alt: t('Program cover image'),
      }}
      tag={showTag ? { className: 'program-entity-card__tag', text: t('Program'), icon: <ProgramIcon /> } : undefined}
      className="program-entity-card"
      path={getPathToProgramDetail(program.id)}
    />
  );
};

export default ProgramCard;
