import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { type BreadcrumbProperties, type BreadcrumbRoute, PageHeader, Card, Link } from '../../';

type P = {
  title: {
    icon?: ReactNode;
    text: string;
  };
  routes: BreadcrumbRoute[];
  children?: ReactNode;
};

// TODO this is wrong name now it is not full width
const FullwidthPageHeader = ({ routes, title, children }: P) => {
  const { t } = useTranslation();

  const breadcrumb: BreadcrumbProperties = {
    routes: routes.map((route) => ({ ...route, breadcrumbName: t(route.breadcrumbName) })),
    itemRender({ path, breadcrumbName }: { path: string; breadcrumbName: string }) {
      return <Link to={path}>{breadcrumbName}</Link>;
    },
  };

  return (
    <Card className="default-page-header">
      <PageHeader
        title={
          <div className="default-page-header__icon-title">
            {title.icon}
            {t(title.text)}
          </div>
        }
        breadcrumb={breadcrumb}
      />
      {children}
    </Card>
  );
};

export default FullwidthPageHeader;
