import { addMinutes, isAfter, isBefore } from 'date-fns';
import { type EventInviteeRole, AbstractEvent, type UserRSVP } from 'models';

const isAfterOpenToJoin = (time: Date) => {
  const endTime = addMinutes(time, AbstractEvent.leaveOffset);
  return isAfter(new Date(), endTime);
};

const isBeforeOpenToJoin = (role: EventInviteeRole, time: Date) => {
  const startTime = addMinutes(time, AbstractEvent.getEnterOffset(role));
  return isBefore(new Date(), startTime);
};

// TODO this type should be replaced by some event type
export const isOpenToJoin = (event: {
  startTime: Date;
  endTime: Date;
  bmgId: string | null;
  currentUserRsvp: UserRSVP;
  currentUserRole: EventInviteeRole;
}) => {
  if (event.bmgId && event.currentUserRsvp === 'going') {
    return !(isBeforeOpenToJoin(event.currentUserRole, event.startTime) || isAfterOpenToJoin(event.endTime));
  }

  return false;
};
