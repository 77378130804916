import { useTranslation } from 'react-i18next';
import { TextInput, TextAreaInput, SelectInput, Form, type FormListFieldData, Title } from 'ui';
import { type ActivityOption, type TaskKindOption } from './ActivityTypes';

type P = {
  type: ActivityOption;
  disabledEdit: boolean;
  disabledEditWithings: boolean;
  onSelectTaskKind: (kind: TaskKindOption) => void;
};

const ActivityFormStep3 = ({ type, disabledEdit, disabledEditWithings, onSelectTaskKind }: P) => {
  const { t } = useTranslation();
  const name = 'tasks';

  const renderTextInput = (field: FormListFieldData, title: string, placeholder: string, withKindSelect?: boolean) => {
    return (
      <>
        <Form.Item required={false} key={field.key}>
          <TextInput
            item={{
              ...field,
              name: [field.name, 'name'],
              label: title,
              rules: [{ required: true, message: t('Name is required'), whitespace: true }],
            }}
            input={{
              placeholder: t(placeholder),
              id: `task_name-${field.name}`,
              disabled: disabledEdit,
            }}
          />
        </Form.Item>
        {withKindSelect ? (
          <SelectInput
            item={{
              ...field,
              name: [field.name, 'kind'],
              label: 'What type of response data is required?',
              rules: [{ required: true, message: t('Kind is required'), whitespace: true }],
            }}
            input={{
              options: [
                { label: 'Manual', value: 'default' },
                { label: 'Withings weight', value: 'withings_weight' },
                { label: 'Withings duration', value: 'withings_duration' },
                { label: 'Withings distance', value: 'withings_distance' },
                { label: 'Withings calories', value: 'withings_calories' },
                { label: 'Withings steps', value: 'withings_steps' },
                { label: 'Withings heart rate', value: 'withings_heart_pulse' },
              ],
              getOptionLabel: (option) => option.label,
              getOptionValue: (option) => option.value,
              onChange(kind: any) {
                onSelectTaskKind(kind);
              },
              disabled: disabledEdit || disabledEditWithings,
            }}
          />
        ) : null}
      </>
    );
  };

  const renderSelectInput = (field: FormListFieldData, label: string, placeholder: string, required: string) => {
    return (
      <Form.List name={[field.name, 'bulk']}>
        {(fields) => {
          return (
            <>
              {fields.map((field) => (
                <Form.Item required key={field.key}>
                  <TextAreaInput
                    item={{
                      ...field,
                      label: t(label),
                      rules: [{ required: true, message: t(required) }],
                    }}
                    input={{
                      placeholder: t(placeholder),
                      'data-test-id': `activity_add-bulk-${field.name}`,
                      disabled: disabledEdit,
                    }}
                  />
                </Form.Item>
              ))}
            </>
          );
        }}
      </Form.List>
    );
  };

  // TODO maybe better name
  const renderTaskSwitch = (field: FormListFieldData) => {
    switch (field.name) {
      case 0:
        return (
          <>
            {renderTextInput(field, 'What?', 'What is expected? (e.g.:select the activity you did today)')}
            {renderSelectInput(
              field,
              'List of options (divide items by commas)',
              'Define options... (e.g..: run, walk, bike, jogging)',
              'List of options is required',
            )}
          </>
        );
      case 1:
        return (
          <>
            {renderSelectInput(
              field,
              'List of units (divide items by commas)',
              'Define units... (e.g.: minutes, lbs, miles)',
              'List of units is required',
            )}
          </>
        );
      default:
        return null;
    }
  };

  const renderTask = (field: FormListFieldData) => {
    switch (type) {
      case 'extended-feedback':
        return renderTextInput(field, 'What?', 'What is expected? (e.g.: personal feedback to the webinar)');
      case 'numeric-feedback':
        return renderTextInput(field, 'What?', 'What is expected? (e.g.: your blood sugar value)', true);

      default:
        return (
          <Form.Item
            noStyle
            shouldUpdate={(previousValues, currentValues) => previousValues[name] !== currentValues[name]}
          >
            {renderTaskSwitch(field)}
          </Form.Item>
        );
    }
  };

  return (
    <div className="activity-form-step-3">
      <Title level={3}>Describe the question or response.</Title>
      <Form.List name={name}>
        {(fields) => {
          return fields.map((field) => renderTask(field));
        }}
      </Form.List>
    </div>
  );
};

export default ActivityFormStep3;
