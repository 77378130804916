import * as React from 'react';
import { InputElement } from './InputElement';
import { Tag } from './Tag';
import { Icon } from '../icon';

export class TagsBox extends React.Component {
  state = {
    height: null,
  };

  $containerRef = React.createRef();
  $pillRef = React.createRef();

  componentDidUpdate() {
    if (!this.$containerRef.current || !this.$pillRef.current) {
      return;
    }

    const containerHeight = this.$containerRef.current.clientHeight;
    const elementHeight = this.$pillRef.current.clientHeight;

    if (this.props.maxRows) {
      const newHeight = elementHeight * this.props.maxRows;

      if (newHeight === this.state.height) {
        return;
      }

      if (newHeight < containerHeight) {
        this.setState({ height: elementHeight * this.props.maxRows });
      } else if (this.state.height !== null) {
        this.setState({ height: null });
      }
    }
  }

  removeItem = (itemAsString) => {
    const nextValue = this.props.selection.filter((i) => this.props.itemToString(i) !== itemAsString);
    const removedValue = this.props.selection.find((i) => this.props.itemToString(i) === itemAsString);
    this.props.onChange(nextValue, removedValue);
  };

  render() {
    return (
      <div className="tags">
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            margin: '0 -4px',
            maxHeight: this.state.height,
            overflow: this.state.height ? 'auto' : undefined,
          }}
          ref={this.$containerRef}
        >
          {this.props.selection.map((item, index) => (
            <div style={{ padding: 4 }} key={index} ref={this.$pillRef}>
              <Tag
                accessoryView={
                  this.props.disabled ? null : (
                    <div
                      style={{ cursor: 'pointer', paddingLeft: 10, lineHeight: '1px' }}
                      onClick={() => {
                        this.removeItem(this.props.itemToString(item));
                      }}
                    >
                      <Icon name="close-bold" size={18} />
                    </div>
                  )
                }
              >
                {this.props.itemToString(item)}
              </Tag>
            </div>
          ))}
        </div>
        {this.props.maxCount ? (
          this.props.selection.length < this.props.maxCount ? (
            <div style={{ flexGrow: 1, margin: '4px 0' }}>
              <InputElement {...this.props.inputProps} id={this.props?.id} />
            </div>
          ) : null
        ) : (
          <div style={{ flexGrow: 1, margin: '4px 0' }}>
            <InputElement {...this.props.inputProps} id={this.props?.id} />
          </div>
        )}
      </div>
    );
  }
}
