import * as React from 'react';
import { Trans } from 'react-i18next';
import { Navigation, NavGroup } from '../../atoms/navigation';
import { NavLinkRadio } from '../../atoms/navigation/NavLinkRadio';
import { Loader } from '../../atoms/loader';

class Renderer extends React.Component {
  render() {
    const campaignsCount = this.props.campaignsByOrganization.reduce((prev, curr) => {
      return prev + curr.collection.length;
    }, 0);
    return (
      <React.Fragment>
        <Navigation direction="vertical">
          <h3 className="navigation--heading">
            <Trans>Filter by Organization</Trans>
          </h3>
          {campaignsCount <= 1 && this.props.displayLoader ? (
            <Loader />
          ) : (
            <NavGroup path="/campaigns/listing" id="campaigns-filter">
              <NavLinkRadio key="all" to="/campaigns/listing" isActive={!this.props.organizationId}>
                <Trans>All</Trans>
                {` (${campaignsCount})`}
              </NavLinkRadio>
              {this.props.campaignsByOrganization.map((group) => {
                const isActive = this.props.organizationId === group.organization.id;
                return (
                  <NavLinkRadio
                    key={group.organization.id}
                    to={`/campaigns/listing/${group.organization.id}`}
                    isActive={isActive}
                    style={{ lineHeight: 1.2 }}
                  >
                    <Trans>{group.organization.name}</Trans>
                  </NavLinkRadio>
                );
              })}
            </NavGroup>
          )}
        </Navigation>
      </React.Fragment>
    );
  }
}

export const CampaignsSideNav = Renderer;
