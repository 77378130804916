import { useMemo } from 'react';
import { type NonCustomOnboardingTourStepOption } from '../../../models';

export const useSupportedStepDescription = ({ step }: { step: NonCustomOnboardingTourStepOption }) =>
  useMemo(() => {
    switch (step) {
      case 'profile':
        return 'Express yourself with a profile picture! Your phone number, date of birth, and timezone are only shared with your organization’s administrators.';
      case 'consents':
        return 'To continue, please read and agree to our standard consents.';
      case 'calendar':
        return "Syncing your calendar with our app is a quick and easy way to stay on top of your appointments and tasks. Don't miss out on important events – sync your calendar now!";
    }
  }, [step]);
