import { Avatar as AvatarAD } from 'antd';
import type React from 'react';
import { type UploadedFile } from '../../models/uploaded-file';

type ItemP = React.ComponentProps<typeof AvatarAD> & {
  photo: UploadedFile | string;
};

const getInitials = (name: string[]) =>
  name
    .slice(0, name.length + 1)
    .map((string_) => string_[0])
    .join('');

const Avatar = ({ photo, children, ...rest }: ItemP) => {
  // TODO make proper default with correct planned task
  const content = typeof children === 'string' ? getInitials(children.split(' ')) : children;
  const source = (typeof photo === 'object' ? photo.url : photo) ?? (content ? undefined : '/assets/user_profile.png');

  return (
    <AvatarAD src={source} {...rest}>
      {content}
    </AvatarAD>
  );
};

export default Avatar;
