import * as React from 'react';
import ClickOutside from 'react-click-outside';
import { Field } from 'formik';
import { Manager, Reference, Popper } from 'react-popper';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';
import { format, isDate, isValid } from 'date-fns';
import { withTranslation } from 'react-i18next';
import { FieldBorder } from '../base-atoms/FieldBorder';
import { FloatingFieldLabel } from '../base-atoms/FloatingFieldLabel';
import { Input } from '../base-atoms/TextualInput';
import { FieldError, isInvalid } from '../FieldError';
import { YearMonthSelector } from './YearMonthSelector';

const FORMAT_SERIALIZE = 'yyyy-MM-dd';
const FORMAT_DISPLAY = 'MMM d, yyyy';

/**
 * Helpers
 */

function deserializeValue(value) {
  return value ? new Date(value) : undefined;
}

function serializeValue(date) {
  return format(date, FORMAT_SERIALIZE);
}

function formatValue(date) {
  return (date && isDate(date) && isValid(date)) ? format(date, FORMAT_DISPLAY) : '';
}

function getSelectedDays(date) {
  return date ? [date] : [];
}

function getInitialMonth(date) {
  return date ? date : undefined;
}

export class Renderer extends React.Component {
  pickerRef = React.createRef();

  state = {
    open: false,
  };

  openOverlay = () => {
    this.setState({ open: true });
  };

  closeOverlay = () => {
    this.setState({ open: false });
  };

  showMonth = (date) => {
    // $FlowExpectedError: missing typedef for showMonth
    this.pickerRef.current.showMonth(date);
  };

  render() {
    return (
      <ClickOutside onClickOutside={this.closeOverlay}>
        <Field name={this.props.name}>
          {(fieldProps) => {
            const { name, label, onChange } = this.props;
            const value = deserializeValue(fieldProps.field.value);
            const enabled = this.props.disabled !== true;
            return (
              <>
                <FloatingFieldLabel visible={value !== undefined}>{label}</FloatingFieldLabel>
                <Manager>
                  <Reference>
                    {(refProps) => (
                      <>
                        <Input
                          {...refProps}
                          name={name}
                          autoComplete="off"
                          disabled={this.props.disabled}
                          onFocus={this.openOverlay}
                          placeholder={this.props.placeholder || this.props.t(this.props.label.props.id)}
                          value={value ? formatValue(value) : ''}
                        />
                        <FieldBorder invalid={isInvalid(name, fieldProps.form)} />
                      </>
                    )}
                  </Reference>
                  {enabled ? (
                    <Popper placement="auto-start">
                      {({ ref, style, placement, arrowProps }) =>
                        this.state.open === false ? null : (
                          <div
                            name={name + '-datePicker'}
                            data-placement={placement}
                            ref={ref}
                            style={{
                              zIndex: 1000,
                              background: '#ffffff',
                              border: '1px solid #cccccc',
                              ...style,
                            }}
                          >
                            <DayPicker
                              captionElement={({ date }) => (
                                <YearMonthSelector date={date} range={this.props.range} onChange={this.showMonth} />
                              )}
                              initialMonth={getInitialMonth(value)}
                              navbarElement={() => null}
                              onDayClick={(date, modifiers = {}) => {
                                if (modifiers.disabled) {
                                  return;
                                }
                                const value = serializeValue(date);
                                fieldProps.form.setFieldValue(name, new Date(value + 'T00:00:00'), true);
                                fieldProps.form.setFieldTouched(name, true, true);
                                this.closeOverlay();
                                onChange?.(value);
                              }}
                              ref={this.pickerRef}
                              selectedDays={getSelectedDays(value)}
                              value={value}
                              disabledDays={this.props.disabledDays}
                            />
                          </div>
                        )
                      }
                    </Popper>
                  ) : null}
                </Manager>
                <FieldError name={name} />
              </>
            );
          }}
        </Field>
      </ClickOutside>
    );
  }
}

export const DateField = withTranslation()(Renderer);
