import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { Button } from '../../atoms/button';
import { Text } from '../../atoms/text';
import { Loader } from '../../atoms/loader';
import { TextField, SelectField } from '../../atoms/form';
import { ThemeConsumer } from '../theme';
import { validator } from '../../utils/validator';
import { countries, states } from 'common/utils';

export class Renderer extends React.Component {
  render() {
    const { donation, campaignId, onSubmit } = this.props;
    const campaign = campaignId ? campaignId : donation ? donation.campaign.id : '';

    return (
      <Formik
        initialValues={{
          campaign_id: campaign,
          amount: donation ? donation.amount : '',
          type: 'cheque',
          first_name: donation ? donation.first_name : '',
          last_name: donation ? donation.last_name : '',
          email: donation ? donation.email : '',
          street: donation ? donation.street : '',
          apt: donation ? donation.apt : '',
          city: donation ? donation.city : '',
          zip: donation ? donation.zip : '',
          country: donation ? donation.country : '',
          state: donation ? donation.state : '',
        }}
        onSubmit={async (values, formik) => {
          const cheque = {
            campaign_id: values.campaign_id,
            type: values.type,
            donation: {
              amount: values.amount,
            },
            payer: {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email ? values.email : null,
              street: values.street,
              apt: values.apt,
              city: values.city,
              zip: values.zip,
              country: values.country,
              state: values.country === 'US' ? values.state : null,
            },
          };
          formik.setSubmitting(true);
          await onSubmit(cheque);
          formik.setSubmitting(false);
        }}
        validate={(values) =>
          validator.validateAll(
            values,
            {
              campaign_id: 'required',
              amount: 'required|min:1',
              first_name: 'required',
              last_name: 'required',
              email: 'email',
              street: 'required',
              city: 'required',
              zip: 'required',
              country: 'required',
              state: 'required_if:country,US',
            },
            {
              min: {
                numeric: this.props.t('donationMinimum', { min: 1 }),
              },
            },
          )
        }
      >
        {(formik) => {
          return (
            <ThemeConsumer>
              {(theme) => (
                <div className="cheque-pledge-form">
                  <Text size={24}>
                    <Trans>Donation Amount</Trans>
                  </Text>
                  <div className="cheque-pledge-form__amount">
                    <TextField
                      type="number"
                      min={0}
                      name="amount"
                      label={<Trans>Amount</Trans>}
                      placeholder={this.props.t('Amount')}
                    />
                  </div>
                  <Text style={{ marginTop: 20 }} size={24} component="div">
                    <Trans>Payer Information</Trans>
                  </Text>
                  <TextField
                    name="first_name"
                    label={<Trans>First Name</Trans>}
                    placeholder={this.props.t('First Name')}
                  />
                  <TextField
                    name="last_name"
                    label={<Trans>Last Name</Trans>}
                    placeholder={this.props.t('Last Name')}
                  />
                  <TextField name="email" label={<Trans>E-mail</Trans>} placeholder={this.props.t('E-mail')} />
                  <SelectField
                    name="country"
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    label={<Trans>Country</Trans>}
                    options={countries.map((option) => ({
                      label: option.name,
                      value: option.code,
                    }))}
                    placeholder={this.props.t('Country')}
                  />

                  {formik.values['country'] === 'US' && (
                    <SelectField
                      name="state"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      label={<Trans>State</Trans>}
                      options={states.map((option) => ({
                        label: option.name,
                        value: option.code,
                      }))}
                      placeholder={this.props.t('State')}
                    />
                  )}
                  <TextField name="city" label={<Trans>City</Trans>} placeholder={this.props.t('City')} />
                  <TextField name="zip" label={<Trans>ZIP</Trans>} placeholder={this.props.t('ZIP')} />
                  <TextField name="street" label={<Trans>Street</Trans>} placeholder={this.props.t('Street')} />
                  <TextField
                    name="apt"
                    placeholder={this.props.t('Apt., Suite, Building')}
                    label={<Trans>Apt., Suite, Building</Trans>}
                  />
                  <div style={{ width: '40%', marginTop: 50 }}>
                    <Button
                      block
                      type="submit"
                      color={theme.color.brand}
                      textColor="white"
                      size="lg"
                      title={formik.isSubmitting ? <Loader size={16} /> : <Trans>Submit</Trans>}
                      onClick={formik.submitForm}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
              )}
            </ThemeConsumer>
          );
        }}
      </Formik>
    );
  }
}

export const ChequePledgeForm = withTranslation()(Renderer);
