import { CommunityNotificationSettings } from 'models';
import { useQuery } from 'query';

import { getNotificationSettings } from '../services';
import { getCommunityNotificationsKey } from './';

export const useNotificationSettingsQuery = ({ id }: { id: string }) =>
  useQuery<CommunityNotificationSettings>(
    getCommunityNotificationsKey({ id }),
    async () => {
      const { data } = await getNotificationSettings({ id });
      return new CommunityNotificationSettings(data);
    },
    // TODO this is not final solution for this problem, should be replaced in future
    { refetchOnMount: 'always' },
  );
