import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type EventContentObjectDataObject, type PublicEventContentObjectDataObject } from 'models/service';

type EventParams = {
  invitation_token?: string;
};

export const getEvent = async <T extends EventContentObjectDataObject | PublicEventContentObjectDataObject>(
  id: string,
  params?: EventParams,
) =>
  apiClient.get<T, ApiClientResponse<T>>(routes.events.get(id), {
    params,
  });
