import * as React from 'react';

import { Text } from '../text';

export class ShowMore extends React.Component {
  state = {
    expanded: false,
    innerComponentHeight: 0,
  };

  componentDidMount() {
    this.setState({
      innerComponentHeight: this.props.innerComponentRef.current
        ? this.props.innerComponentRef.current.clientHeight
        : 0,
    });
  }

  toggleReadMore = () =>
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));

  render() {
    const { cutoffHeight, children, expandedText, collapsedText } = this.props;

    if (this.state.innerComponentHeight <= cutoffHeight) {
      return children;
    }

    return (
      <React.Fragment>
        <div style={{ overflow: 'hidden', maxHeight: `${this.state.expanded ? 'none' : cutoffHeight + 'px'}` }}>
          {children}
        </div>
        <div className="show-more" onClick={this.toggleReadMore}>
          <Text className="show-more__text" size={12}>
            {this.state.expanded ? expandedText : collapsedText}
          </Text>
        </div>
      </React.Fragment>
    );
  }
}
