import { type Pagination } from 'models';
import { generateQueryKey } from 'query';

export * from './mutations';
export * from './useCampaignsQuery';
export * from './useCampaignsInfiniteQuery';

export const getCampaignsInfiniteQueryKey = (params: {
  pageSize?: number;
  organizationId?: string;
  featured?: boolean;
}) => generateQueryKey(['campaigns', 'list', 'infinite', params]);

export const getCampaignsQueryKey = (params: {
  organizationId?: string;
  featured?: boolean;
  pagination?: Pagination;
}) => generateQueryKey(['campaigns', 'list', params]);
