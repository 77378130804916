import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { ScrollToTop, Link } from 'ui';

import { CampaignDonationForm, CampaignsListing } from '../../atoms/campaigns';
import { Loader } from '../../atoms/loader';
import { CampaignsLayout } from '../../layouts/CampaignsLayout';
import { CampaignsSideNav } from './CampaignsSideNav';
import { CampaignCover } from '../../atoms/campaigns/CampaignCover';
import { CampaignDetail } from '../../atoms/campaigns';
import { Icon } from '../../atoms/icon';
import { Trans } from 'react-i18next';
import { ThemeConsumer } from '../../atoms/theme';
import { Button } from '../../atoms/button';
import { showAlert } from '../../atoms/alert';

class Renderer extends React.Component {
  componentDidMount() {
    this.props.getCollection();
    if (this.props.match.params.campaignId) {
      this.props.findCampaign();
    }
  }

  componentDidUpdate(prevProps) {
    // User navigated to particular campaign.
    if (
      this.props.match.params.campaignId &&
      this.props.match.params.campaignId !== prevProps.match.params.campaignId
    ) {
      this.props.findCampaign();
    }

    // Scroll to top after organization change
    if (
      this.props.match.params.organizationId &&
      this.props.match.params.organizationId !== prevProps.match.params.organizationId
    ) {
      window.scrollTo(0, 0);
    }

    // User navigated to campaign listing
    if (prevProps.match.params.campaignId !== undefined && this.props.match.params.campaignId === undefined) {
      this.props.getCollection();
    }
  }

  onCopyToClipboard = () => {
    // create node, copy url and remove node
    const fullHostname = `${window.location.protocol}//${window.location.hostname}${
      window.location.port && ':' + window.location.port
    }`;
    const publicCampaignURL = `${fullHostname}/donate/${this.props.campaign.id}`;
    const dummy = document.createElement('input');
    // $FlowMissingDefinition
    document.body.appendChild(dummy);
    dummy.value = publicCampaignURL;
    dummy.select();
    document.execCommand('copy');
    // $FlowMissingDefinition
    document.body.removeChild(dummy);

    showAlert({ type: 'success', message: <Trans>Link has been copied to clipboard</Trans> });
  };

  renderCampaignDetail() {
    const { campaign, donate, loadingPayment } = this.props;
    
    return !this.props.loadingCampaign && campaign && campaign.amounts ? (
      <React.Fragment>
        <ThemeConsumer>
          {(theme) => (
            <div className="campaign__top-bar">
              <Link
                to={`/campaigns/listing/${campaign.organization.id}`}
                style={{
                  lineHeight: '20px',
                  color: theme.color.brand,
                  fontSize: 13,
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  name="pba-arrow-left"
                  color="inherit"
                  size={25}
                  style={{ display: 'inline-block', lineHeight: 1, marginRight: 5 }}
                />
                <Trans>BACK</Trans>
              </Link>
              <div className="top-bar-right">
                <div className="campaign__top-bar__copy-btn">
                  {document.queryCommandSupported('copy') && (
                    <Button
                      size="md"
                      outline
                      color={theme.color.brand}
                      onClick={this.onCopyToClipboard}
                      type="button"
                      title={
                        <React.Fragment>
                          <Trans>Copy to Clipboard</Trans>
                          <Icon name="link-ext" size={15} style={{ marginLeft: 7 }} />
                        </React.Fragment>
                      }
                      id={'copy-to-clipboard'}
                    />
                  )}
                </div>

                <div className="campaign__top-bar__bookmark">
                  <Icon
                    name={`pba-bookmark${campaign?.content_object?.bookmarked ? '-filled' : '-empty'}`}
                    size={25}
                    color="#717E94"
                    onClick={async () => {
                      await this.props.bookmarkObject(campaign.content_object, campaign);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </ThemeConsumer>
        <CampaignCover campaign={campaign} />
        <CampaignDetail campaign={campaign} viewer={this.props.viewer} />
        <CampaignDonationForm campaign={campaign} onSubmit={donate} loadingPayment={loadingPayment} />
        <ScrollToTop />
      </React.Fragment>
    ) : (
      <Loader />
    );
  }

  renderCampaignListing(displayLoader) {
    const scopeId = this.props.match.params.organizationId;
    let filteredGroups = this.props.campaignsByOrganization;
    if (scopeId) {
      filteredGroups = filteredGroups.filter((group) => group.organization.id === scopeId);
    }

    return (
      <div id="campaigns-content">
        <CampaignsListing
          campaignsByOrganization={filteredGroups}
          onRequestCampaignDetail={(campaign) => {
            this.props.history.push(`/campaigns/listing/${campaign.organization.id}/${campaign.id}`);
          }}
        />
        {displayLoader && <Loader />}
      </div>
    );
  }

  render() {
    const displayLoader = !!this.props.loadingCollection;
    const campaignsByOrganization = this.props.campaignsByOrganization.sort((a, b) => {
      return a.organization.name.localeCompare(b.organization.name);
    });

    return (
      <CampaignsLayout
        campaignsByOrganization={campaignsByOrganization}
        displayLoader={displayLoader}
        navigation={
          <CampaignsSideNav
            organizationId={this.props.match.params.organizationId}
            displayLoader={displayLoader}
            campaignsByOrganization={campaignsByOrganization}
          />
        }
      >
        {this.props.match.params.campaignId ? this.renderCampaignDetail() : this.renderCampaignListing(displayLoader)}
      </CampaignsLayout>
    );
  }
}

const mapState = (state, props) => ({
  campaign: select.campaigns.find(state, props.match.params.campaignId),
  campaignsByOrganization: select.campaigns.getBy(state, 'organization'),
  loadingCampaign: state.loading.effects.campaigns.findAsync,
  loadingCollection: state.loading.effects.campaigns.getAsync,
  loadingPayment: state.loading.effects.donations.donate,
  viewer: select.session.user(state),
});

const mapDispatch = (dispatch, props) => ({
  donate: (donationRequest) =>
    dispatch.donations.donate({
      campaignId: props.match.params.campaignId,
      donationRequest: donationRequest,
    }),
  findCampaign: () => dispatch.campaigns.findAsync(props.match.params.campaignId),
  getCollection: () => dispatch.campaigns.getAsync(),
  bookmarkObject: (object, campaign) => {
    return dispatch.feed.bookmarkCampaignAsync({ object, campaign });
  },
});

export const PageCampaigns = connect(mapState, mapDispatch)(Renderer);
