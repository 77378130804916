import { type ReduxUser, type ProgramContentObjectRedux } from 'models';
import { canShowComments } from 'permissions';
import { CommentButton } from '../CommentButton';
import { ShareButton } from '../ShareButton';
import { ViewButton } from '../ViewButton';

type P = {
  item: ProgramContentObjectRedux;
  onShowComments?: () => void;
  viewer: ReduxUser;
  view: 'bookmark' | 'other'; // TODO move to some `type`
};

const ProgramActionBar = ({ viewer, item, onShowComments, view }: P) => {
  return (
    <div className="program-action-bar">
      {view === 'bookmark' ? <ViewButton item={item} /> : null}
      {view === 'other' ? (
        <>
          {canShowComments({ viewer, contentObject: item }) ? <CommentButton onShowComments={onShowComments} /> : null}
          <ShareButton item={item} viewer={viewer} />
        </>
      ) : null}
    </div>
  );
};

export default ProgramActionBar;
