import { Tag } from '../';
import { Space } from '../../Space';

export type TagItem = {
  id?: string;
  name: string;
};

type P = {
  tags: TagItem[];
};

const TagList = ({ tags }: P) => (
  <Space size={[0, 8]} wrap>
    {tags.map((tagItem) => (
      <Tag className="tag-list__tag-item" key={tagItem.id}>
        {tagItem.name}
      </Tag>
    ))}
  </Space>
);

export default TagList;
