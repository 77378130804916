import { normalize, denormalize } from 'normalizr';

import { dispatch, getState } from '../store';
import { EventService } from '../services/EventService';
import { EventSchema } from './schemas';

const GLOBAL_FEED_KEY = 'feed?pinned=no&community=global';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [EventSchema] : EventSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

/**
 * Typings
 */

const INITIAL_STATE = [];

/**
 * Model
 */
export const model = {
  name: 'events',
  state: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    'session/reset': () => INITIAL_STATE,
    push: (state, payload) => [...state, ...payload],
    set: (state, payload) => payload,
    delete: (state, payload) => state.filter((id) => id !== payload),
  },
  selectors: {
    get: (state) => {
      return denormalize(state, [EventSchema], getState().entities);
    },

    find: (state, eventId) => {
      return denormalize(eventId, EventSchema, getState().entities);
    },
  },
  effects: {
    async fetchAsync({ reset = false, ...params }) {
      if (reset) {
        this.reset();
      }

      const response = await EventService.get(params);

      if (response.ok) {
        this.push(normalizeData(response.data));
      }
      return response;
    },

    async statusAsync(event) {
      const response = await EventService.setStatus(event.id, event.current_user_rsvp);
      if (response.ok) {
        normalizeData(event);
      }
      return response;
    },

    async findAsync(eventId) {
      const response = await EventService.find(eventId);

      if (response.ok) {
        normalizeData({ ...response.data, ...response.data.event, contentObjectId: response.data.id });
      }

      return response;
    },

    async deleteAsync(object) {
      const { event } = object;
      const response = await EventService.delete(event.id);
      if (response.ok) {
        this.delete(event.id);
        dispatch.feed.destroyObject({
          object: { id: object.id },
          feed: GLOBAL_FEED_KEY,
        });
      }
      return response;
    },

    update(data) {
      normalizeData({ ...data, ...data.event });

      return data;
    },

    create(object) {
      const event = {
        ...object.event,
        post_in_communities: object.post_in_communities,
      };

      this.push([normalizeData(event)]);
      dispatch.feed.unshiftObjects({
        ids: [object.id],
        feed: GLOBAL_FEED_KEY,
      });

      return object;
    },

    async fetchInviteesAsync({ id, status, limit, cursor }) {
      const response = await EventService.getInvitees(id, { status, limit, cursor });
      // Do not store response to event invitees, it modifies other screens byt status, what does not make sense
      return response;
    },
  },
};
