import { getAppConfig } from '../atoms/app-config-gate';
import { polling, openPopup } from '../utils/socialAuthHelpers';

/**
 * Implementation
 */
export const AzureService = {
  async login(params = {}) {
    const REDIRECT_URI = params.redirectUri || window.location.href;
    const AZURE_CLIENT_ID = getAppConfig().constants.AZURE_ID;
    const SCOPES = ['openid', 'profile', 'User.Read', ...(params.scope || [])].join('%20');

    return await new Promise((resolve, reject) => {
      polling(
        openPopup(
          `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${AZURE_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&response_mode=query&scope=${SCOPES}&state=L93n5tkc3dqgp8kv6954uX5JhbnvHSF2`,
          'azure-login-popup',
        ),
        (query) => {
          if (query) {
            resolve({
              provider: 'azure',
              grant_type: 'authorization_code',
              code: query.get('code'),
              redirect_uri: REDIRECT_URI,
            });
          } else {
            reject(new Error('Failed to authorize with Azure'));
          }
        },
        'login.microsoftonline.com',
      );
    });
  },
};
