import { type Form, Checkbox } from 'antd';
import cn from 'classnames';
import { type ComponentProps, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../Item';

type ItemP = Omit<ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: ReactNode | string;
  hidden?: boolean;
};

type InputP = Omit<ComponentProps<typeof Checkbox>, 'css'> & {
  'data-test-id'?: string;
  'aria-label'?: string; // This is missing from antd, similar to other HTML properties
};

type P = {
  item: ItemP;
  input?: InputP;
};

const CheckboxInput = ({ item, input }: P) => {
  const { t } = useTranslation();

  return (
    <Item {...item} className={cn('checkbox-input', item.className)} label={null}>
      <Checkbox {...input}>{typeof item.label === 'string' ? t(item.label) : item.label}</Checkbox>
    </Item>
  );
};

export default CheckboxInput;
