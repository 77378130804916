import { useEffect, useRef, useState } from 'react';

export const useDebounce = <T>(value: T, delay = 500) => {
  const didMount = useRef(false);
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    setIsDebouncing(true);

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { value: debouncedValue, isDebouncing };
};
