import { useState, useRef, useCallback, useMemo } from 'react';
import { useUserExists } from '../../../queries';

type UserValidationResult = {
  value: string;
  loading: boolean;
  valid: boolean;
  queue: Array<(value: UserValidationResult) => void>;
};

export type ValidationType = 'email' | 'phone';

export const useValidateUsers = () => {
  const [loading, setLoading] = useState(false);
  const validations = useRef<Record<string, UserValidationResult>>({});

  const { mutateAsync: validateDataAsync } = useUserExists();

  const validate = useCallback(
    async ({ type, value }: { type: ValidationType; value: string }) => {
      let loadedValue = validations.current[value];

      if (!loadedValue) {
        setLoading(true);
        loadedValue = { loading: true, value, valid: false, queue: [] };
        validations.current[value] = loadedValue;

        try {
          await validateDataAsync({ type, value });
          loadedValue.valid = false;
        } catch {
          loadedValue.valid = true;
        } finally {
          loadedValue.loading = false;
        }

        for (const queueWaiter of loadedValue.queue) queueWaiter(loadedValue);
        loadedValue.queue = [];

        // eslint-disable-next-line unicorn/no-array-reduce
        const isAnythingLoading = Object.values(validations.current).reduce(
          (isLoading, validation) => isLoading || validation.loading,
          false,
        );
        setLoading(isAnythingLoading);
        // eslint-disable-next-line sonarjs/elseif-without-else
      } else if (loadedValue.loading) {
        // eslint-disable-next-line no-promise-executor-return
        return new Promise<UserValidationResult>((resolve) => loadedValue.queue.push(resolve));
      }

      return loadedValue;
    },
    [validateDataAsync],
  );

  return useMemo(
    () => ({
      validate,
      isLoading: loading,
    }),
    [validate, loading],
  );
};
