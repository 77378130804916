import { InviteType, type CommunityListItem, type ReduxUser, type EventType } from 'models';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { type EventContentObjectDataObject } from 'models/service';

import { Modal, message } from 'ui';

import { useCreateEvent } from '../../queries';
import { type DeprecatedEventProgramProperties } from '../../types';

import {
  DataStepEventForm,
  type TelehealthEventFormValues,
  type DefaultEventData,
  type TelehealthEventData,
} from './DataStepEventForm';

import { TypeStepEventForm } from './TypeStepEventForm';

type EventFormStep = 'data' | 'type';

type P = {
  isOpened: boolean;
  viewer: ReduxUser;
  communities: CommunityListItem[];
  onCancel: () => void;
  onSuccess: (data: EventContentObjectDataObject) => Promise<void> | void;
  onRequestReturn?: () => void;
  eventTypes?: EventType[];
  program?: DeprecatedEventProgramProperties;
  communityId?: string;
  initialData?: Partial<TelehealthEventFormValues>;
};

const defaultEventTypes: EventType[] = ['conference', 'webinar'];

const CreateEventFormModal = ({
  isOpened,
  viewer,
  eventTypes = defaultEventTypes,
  program,
  communityId,
  communities,
  initialData,
  onCancel,
  onSuccess,
  onRequestReturn,
}: P) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<EventFormStep>(initialData ? 'data' : 'type');
  const [eventType, setEventType] = useState<EventType>(initialData ? 'telehealth_visit' : 'conference');
  const [inviteType, setInviteType] = useState<InviteType>(InviteType.empty);
  const { isLoading: isCreatingEvent, mutate: createEvent } = useCreateEvent();

  const isInviteOpened = Boolean(inviteType);

  const onModalBack = useMemo(() => {
    if (isInviteOpened) {
      return () => {
        setInviteType(InviteType.empty);
      };
    }

    // TODO `initialData` is hack here kinda only for `CreateTelehealthEvent`
    if (step === 'data' && !initialData) {
      return () => {
        setStep('type');
      };
    }

    return onRequestReturn;
  }, [onRequestReturn, isInviteOpened, initialData, step]);

  const onSelectEventType = useCallback(
    (eventType: EventType) => {
      setEventType(eventType);
      setStep('data');
    },
    [setEventType, setStep],
  );

  const getTypeStepTitleName = () => {
    switch (eventType) {
      case 'conference':
        return 'Create Video conference';
      case 'webinar':
        return 'Create Webinar / Webcast';
      case 'telehealth_visit':
        return 'Create Telehealth Visit';
    }
  };

  const getTitleName = () => {
    switch (step) {
      case 'type':
        return 'Create Event';
      case 'data':
        return getTypeStepTitleName();
    }
  };

  const onSubmit = (data: DefaultEventData | TelehealthEventData) => {
    createEvent(
      { data, communities },
      {
        onSuccess(data) {
          message.success(t('Event was created.'));
          onSuccess(data.eventContentDataObject);
        },
        onError(error) {
          message.error(error.response?.data?.message ?? 'Failed to create event.');
        },
      },
    );
  };

  return (
    <Modal
      isOpened={isOpened}
      title={getTitleName()}
      onCancel={onCancel}
      disableBack={!onModalBack}
      onBackClick={onModalBack}
      closable={!isInviteOpened}
      destroyOnClose
    >
      {step === 'type' ? (
        <TypeStepEventForm onChange={onSelectEventType} options={eventTypes} />
      ) : (
        <DataStepEventForm
          isLoading={isCreatingEvent}
          viewer={viewer}
          program={program}
          onSubmit={onSubmit}
          inviteType={inviteType}
          onInviteesTypeChanged={setInviteType}
          communities={communities}
          communityId={communityId}
          initialData={initialData}
          eventType={eventType}
          mode="create"
        />
      )}
    </Modal>
  );
};

export default CreateEventFormModal;
