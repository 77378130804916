import { AppLayout } from 'old/layouts/AppLayout';
import { JoinedCommunities } from 'features/communities';
import { useGetPathToEntityDetail } from '../hooks';

const PageJoinedCommunities = () => {
  const getPathToEntityDetail = useGetPathToEntityDetail();

  return (
    <AppLayout
      center={
        <JoinedCommunities getPathToCommunityDetail={(id) => getPathToEntityDetail(id, { entityType: 'community' })} />
      }
    />
  );
};

export default PageJoinedCommunities;
