import { generateQueryKey } from '../../../query';
import { type EntityType } from '../types';

export * from './useOrganizationsQuery';
export * from './useChannelsInfiniteQuery';
export * from './useChannelEntitiesQuery';
export * from './useChannelDetailQuery';

export const getChannelsFeatureQueryKey = () => generateQueryKey(['channels']);

export const getChannelsOrganizationsQueryKey = () =>
  generateQueryKey([...getChannelsFeatureQueryKey(), 'organizations']);

export const getChannelsQueryKey = (params: { pageSize?: number; organizationId?: string }) =>
  generateQueryKey([...getChannelsFeatureQueryKey(), params]);

export const getChannelEntitiesQueryKey = (params: {
  pageSize?: number;
  channelId?: string;
  featured?: boolean;
  subject?: EntityType;
}) => generateQueryKey([...getChannelsFeatureQueryKey(), 'entities', params]);

export const getChannelDetailQueryKey = (params: { channelId?: string }) =>
  generateQueryKey([...getChannelsFeatureQueryKey(), 'detail', params]);
