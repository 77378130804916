import { Icon } from '../icon';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

export function Count(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          className={`count ${props.onClick ? 'count--clicked' : ''} ${props.className}`}
          onClick={props.onClick}
          style={props.style}
        >
          {props.iconName === 'like' ? (
            props.liked ? (
              <HeartFilled style={{ fontSize: 16, marginRight: 4, color: theme.color.red }} />
            ) : (
              <HeartOutlined style={{ fontSize: 16, marginRight: 4, color: theme.color.lightGray }} />
            )
          ) : (
            <Icon name={props.iconName} size={16} color={props.iconColor} id={props.id} className="count__icon" />
          )}
          <Text size="14px" weight="600" lineHeight="1.3">
            {props.children}
          </Text>
        </div>
      )}
    </ThemeConsumer>
  );
}
