import { select } from '@rematch/select';
import { type ReduxUser } from 'models';
import { UserZoneLayout, TABS } from 'old/layouts/UserZoneLayout';
import { SettingsSideNav } from 'old/pages/page-settings/SettingsSideNav';
import { connect } from 'react-redux';
import { SettingsNotifications } from 'features/user-profile';

type P = {
  isLoadingUser: boolean;
  user: ReduxUser;
  fetchUser: () => Promise<void> | void;
};

const ProfileSettingsNotifications = ({ isLoadingUser, user, fetchUser }: P) => {
  return (
    <UserZoneLayout navigation={<SettingsSideNav />} contentId={TABS.settings}>
      <SettingsNotifications user={user} onSuccess={fetchUser} isLoadingRedux={isLoadingUser} />
    </UserZoneLayout>
  );
};

// @ts-expect-error old code
const mapStateToProperties = (state) => ({
  // @ts-expect-error old code
  user: select.session.user(state),
  isLoadingUser: state.loading.effects.session.fetchUser,
});

// @ts-expect-error old code
const mapDispatch = (dispatch) => {
  return {
    fetchUser() {
      return dispatch.session.fetchUser();
    },
  };
};

export default connect(mapStateToProperties, mapDispatch)(ProfileSettingsNotifications);
