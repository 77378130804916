import * as React from 'react';
import { Formik } from 'formik';
import { Trans, withTranslation } from 'react-i18next';

import { Box } from '../../atoms/box';
import { Button } from '../../atoms/button';
import { CommunitiesField } from '../../atoms/form/smart-fields';
import { FieldError } from '../form/FieldError';
import { PostEditor } from '../post-editor/PostEditor';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { validator } from '../../utils/validator';

import { Loader } from '../loader';

export class Renderer extends React.Component {
  state = {
    editorIsActive: false,
  };

  render() {
    return (
      <Formik
        validate={(values, props) => {
          return validator.validateAll(values, {
            post_in_communities: 'required|min:1',
            text_content: 'required',
          });
        }}
        initialValues={this.props.initialValues}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true);
          const response = await this.props.onSubmit(values);
          formik.setSubmitting(false);
          if (response?.ok) {
            this.props.onSuccess(response);
          }
        }}
      >
        {(formik) => (
          <ThemeConsumer>
            {(theme) => (
              <React.Fragment>
                <div style={{ borderBottom: '1px solid #ECEFF1', padding: '15px 0 8px 0px' }}>
                  <Box flexDirection="row" alignItems="center">
                    <div style={{ width: 80 }}>
                      <Text size="14px" color={theme.color.lightGray}>
                        <Trans>Share on:</Trans>
                      </Text>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <CommunitiesField
                        placeholder={this.props.t('Search Community')}
                        name="post_in_communities"
                        scope="joined"
                        allowPosts={true}
                        currentSelectedData={formik.values.post_in_communities}
                        id={'search-communities'}
                      />
                    </div>
                  </Box>
                </div>
                {this.state.editorIsActive ? (
                  <PostEditor
                    viewer={this.props.viewer}
                    fields={this.props.fields}
                    placeholder={this.props.t('Insert comment...')}
                    onChange={(name, value) => {
                      formik.setFieldValue(name, value);
                    }}
                    id={'insert-comment'}
                  />
                ) : (
                  <div
                    style={{ borderBottom: '1px solid #ECEFF1', padding: '15px 0 8px 0px' }}
                    onClick={() => this.setState({ editorIsActive: true })}
                  >
                    <Text size="14px" color={theme.color.lightGray}>
                      <Trans>Insert comment...</Trans>
                    </Text>
                  </div>
                )}
                <FieldError name="text_content" />
                {this.props.children}

                <Box alignItems="center" justifyContent="center">
                  <div style={{ width: 150, margin: '30px 0' }}>
                    <Button
                      block
                      type="submit"
                      color={theme.color.brand}
                      size="sm"
                      textColor="white"
                      title={
                        formik.isSubmitting ? <Loader color={theme.color.lightGray} size={21} /> : this.props.t('Share')
                      }
                      disabled={formik.isSubmitting}
                      onClick={formik.submitForm}
                      id={'share'}
                    />
                  </div>
                </Box>
              </React.Fragment>
            )}
          </ThemeConsumer>
        )}
      </Formik>
    );
  }
}

export const ShareContentObjectEditor = withTranslation()(Renderer);
