import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type EventSlimDataObject } from 'models/service';

type EventsRequest = {
  cursor?: string;
  limit?: number;
  offset?: number;
  scope?: 'channels';
  organization_id?: string;
  featured?: boolean;
};

export const getEvents = async (params: EventsRequest) =>
  apiClient.get<EventSlimDataObject[], ApiClientResponse<EventSlimDataObject[]>>(routes.events.find, {
    params,
  });
