import { type ReduxUser, isProgramContentObject, type ContentObjectRedux } from 'models';
import { BasicActionBar } from './BasicActionBar';
import { ProgramActionBar } from './ProgramActionBar';

type P = {
  item: ContentObjectRedux;
  onShowComments?: () => void;
  viewer: ReduxUser;
  view: 'bookmark' | 'other'; // TODO move to some `type`
};

const ActionBar = ({ item, viewer, onShowComments, view }: P) => {
  return isProgramContentObject(item) ? (
    <ProgramActionBar item={item} viewer={viewer} onShowComments={onShowComments} view={view} />
  ) : (
    <BasicActionBar item={item} viewer={viewer} onShowComments={onShowComments} view={view} />
  );
};

export default ActionBar;
