import { type FolderType } from 'models';
import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type FolderAssetDataObject } from 'models/service';

type CreateFolderRequest = {
  community_id?: string;
  parent_id?: string;
  type: typeof FolderType;
  name: string;
};

export const createFolder = async (data: CreateFolderRequest) =>
  apiClient.post<FolderAssetDataObject, ApiClientResponse<FolderAssetDataObject>>(routes.files.create, data);
