import { select } from '@rematch/select';
import { TimezoneConfirmationModal } from 'features/timezone/';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Renderer extends Component {
  navigateToChangeTimezeone = () => {
    this.props.history.push('/profile/personal-info');
  };

  render() {
    const { viewer } = this.props;

    if (!viewer) {
      return null;
    }

    return (
      <TimezoneConfirmationModal
        viewer={viewer}
        navigateToChangeTimezone={this.navigateToChangeTimezeone}
        onConfirm={this.props.fetchUser}
      />
    );
  }
}

const mapState = (state) => ({
  viewer: select.session.user(state),
});

const mapDispatch = (dispatch) => {
  return {
    fetchUser: () => {
      return dispatch.session.fetchUser();
    },
  };
};

export const TimezoneConfirmation = withRouter(connect(mapState, mapDispatch)(Renderer));
