import { type AutocompleteUser, type InviteType } from 'models';
import { type FormInstance } from 'ui';

import { type DeprecatedEventProgramProperties } from '../../../../types';
import { type InviteesType } from '../../../EventInvitees';

import CommunitiesEventInviteesPicker from './CommunitiesEventInviteesPicker';
import { type CommonFormValues } from './hooks';
import ProgramEventInviteesPicker from './ProgramEventInviteesPicker';

type P = {
  // TODO this should be type
  role?: 'admin' | 'member';
  allowedInvites?: InviteesType[];
  form: FormInstance<CommonFormValues>;
  inviteType: InviteType;
  onInvite: (inviteType: InviteType, invitees: AutocompleteUser[], emailInvitees?: string[] | undefined) => void;
  communityId?: string;
  postInProgram?: DeprecatedEventProgramProperties;
};

const EventInviteesPicker = ({ communityId, postInProgram, ...rest }: P) => {
  if (postInProgram) {
    return <ProgramEventInviteesPicker postInProgram={postInProgram} {...rest} />;
  }

  if (communityId) {
    return <CommunitiesEventInviteesPicker communityId={communityId} {...rest} />;
  }

  return null;
};

export default EventInviteesPicker;
