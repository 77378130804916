import { isPast, parseISO } from 'date-fns';
import { type ReduxUser } from 'models';
import { isWithinTime } from 'old/utils/events';
import { canManageCommunityRedux } from './canManageCommunity';

export const canEditContentObject = ({
  viewer,
  contentObject,
  view = 'feed',
}: {
  viewer: ReduxUser;
  contentObject: any;
  view?: 'detail' | 'feed'; // TODO fix detail logic
}) => {
  if (view === 'feed' && contentObject?.program) {
    return false;
  }

  switch (contentObject?.type) {
    case 'event':
      return canEditEvent({ viewer, contentObject });
    case 'kudo':
    case 'post':
    case 'poll':
      return (
        isAuthor({ viewer, contentObject }) ||
        canManageAnyCommunity({ viewer, postInCommunities: contentObject?.post_in_communities })
      );
    default:
      return false;
  }
};

const canEditEvent = ({ viewer, contentObject }: { viewer: ReduxUser; contentObject: any }) => {
  const isEventWithinTime = isWithinTime(contentObject.event);
  const isEventStartInPast = isPast(parseISO(contentObject.event?.start_time));

  if (isEventStartInPast && !isEventWithinTime) {
    return false;
  }

  return (
    isAuthor({ viewer, contentObject }) ||
    canManageAnyCommunity({ viewer, postInCommunities: contentObject?.post_in_communities })
  );
};

const isAuthor = ({ viewer, contentObject }: { viewer: ReduxUser; contentObject: any }) =>
  viewer.id === contentObject?.author?.id;

const canManageAnyCommunity = ({ viewer, postInCommunities }: { viewer: ReduxUser; postInCommunities?: any[] }) =>
  postInCommunities?.some?.((community) => canManageCommunityRedux({ viewer, community }));
