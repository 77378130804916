import React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { ProfileSideNav } from '../../pages/page-profile/ProfileSideNav';
import { UserZoneLayout, TABS } from '../../layouts/UserZoneLayout';
import { dispatch } from '../../store';
import WithingsSync from '../../../features/auth/WithingsSync';

class PageWithingsSyncRenderer extends React.Component {
  render() {
    return (
      <UserZoneLayout navigation={<ProfileSideNav user={this.props.user} />} contentId={TABS.profile}>
        <WithingsSync
          user={this.props.user}
          search={this.props.location?.search}
          onRequestReloadUser={dispatch.session.fetchUser}
        />
      </UserZoneLayout>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const PageWithingsSync = connect(mapState)(PageWithingsSyncRenderer);
