import { transformError, transformResponse } from '../../common/recoil/queries';
import { ID, PaginationData } from '../../common/types';
import { MessagePayload, Message } from '../../models';

import { findChatroomMessages, FindChatroomMessagesDataObject } from '../../services';

import { MessageType } from '../../types';
import { defaultMessagesPaginationOptions } from '../defaults';

export const getMessages = async (lastCreatedAt: Date | undefined, chatroomId: ID) => {
  const { skip, limit } = defaultMessagesPaginationOptions;
  try {
    const response = await findChatroomMessages(chatroomId, {
      $skip: skip,
      $limit: limit,
      $sort: {
        createdAt: -1,
      },
      type: 'message',
      createdAt: lastCreatedAt
        ? {
            $lt: lastCreatedAt.toISOString(),
          }
        : undefined,
    });

    return transformResponse<FindChatroomMessagesDataObject, PaginationData<Message>>(
      response,
      (pagination: FindChatroomMessagesDataObject) => ({
        limit: pagination.limit,
        skip: pagination.skip,
        total: pagination.total,
        data: pagination.data.map(
          (message: {
            _id: string;
            chatroom: string;
            author?: string | undefined;
            type: MessageType;
            text: string;
            createdAt: string;
            updatedAt: string;
            payload: MessagePayload[];
            status?: string | undefined;
          }) => new Message({ ...message }),
        ),
      }),
    );
  } catch (error) {
    return transformError(error);
  }
};
