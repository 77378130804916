import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Button, Col, Row, Space, Text, Title } from 'ui';
import { StoreButton, type Platform } from './StoreButton';

type P = {
  platform: Platform;
  onContinueInBrowser: () => void;
};

const RedirectToMobileApp = ({ platform, onContinueInBrowser }: P) => {
  const { t } = useTranslation();

  const onClick = () => {
    onContinueInBrowser();
  };

  return (
    <Row item={{ justify: 'center', className: 'redirect-to-mobile-app' }}>
      <Col>
        <Text strong>{`${t('Welcome to')} ${process.env.REACT_APP_TITLE}!`}</Text>
        <Title level={2} className="redirect-to-mobile-app__header">
          What’s next?
        </Title>

        <Space size={variables.spaceMd.value * 4} direction="vertical">
          <Text type="secondary">Download the app for the best experience on your mobile device.</Text>

          <Space size={variables.spaceLg.value} direction="vertical">
            <StoreButton platform={platform} />
            <Button onClick={onClick} size="large">
              Or, Continue in browser
              <ArrowRightOutlined />
            </Button>
          </Space>
        </Space>
      </Col>
    </Row>
  );
};

export default RedirectToMobileApp;
