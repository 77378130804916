import { Card as CardAD } from 'antd';
import cs from 'classnames';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../index';

type P = React.ComponentProps<typeof CardAD> & {
  shrinked?: boolean;
  shrinkedLeft?: boolean;
  shrinkedRight?: boolean;
  marginBottom?: boolean;
};

const Card = ({
  title,
  className,
  marginBottom = true,
  shrinked,
  shrinkedLeft,
  shrinkedRight,

  ...rest
}: P) => {
  const { t } = useTranslation();

  return (
    <CardAD
      bordered={false}
      className={cs('card-layout', className, {
        'card-layout--shrinked': shrinked,
        'card-layout--shrinked-left': shrinkedLeft,
        'card-layout--shrinked-right': shrinkedRight,
        'card-layout--margin-bottom': marginBottom,
      })}
      title={typeof title === 'string' ? <Title level={5}>{t(title)}</Title> : title}
      {...rest}
    />
  );
};

export default Card;
