import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Trans } from 'react-i18next';

class Renderer extends React.Component {
  state = {
    campaigns: 0,
    donations: 0,
    amount: 0,
  };

  async componentDidMount() {
    this.setState((await this.props.getDonationsSummary(this.props.filter)).data);
  }

  async componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter)) {
      this.setState((await this.props.getDonationsSummary(this.props.filter)).data);
    }
  }

  render() {
    return (
      <div className="donation-report-summary" id="donations-summary">
        <div className="donation-report-summary__header">
          <Trans>Summary</Trans>
        </div>

        <div className="donation-report-summary__body">
          <div className="donation-report-summary__line" id="campaigns-no">
            <div className="donation-report-summary__line__header">
              <Trans>NO. OF CAMPAIGNS</Trans>
            </div>
            <div className="donation-report-summary__line__value">{this.state.campaigns}</div>
          </div>

          <div className="donation-report-summary__line" id="donations-no">
            <div className="donation-report-summary__line__header">
              <Trans>NO. OF DONATIONS</Trans>
            </div>
            <div className="donation-report-summary__line__value">
              {Intl.NumberFormat('en-US').format(this.state.donations)}
            </div>
          </div>

          <div className="donation-report-summary__line" id="amount-donated">
            <div className="donation-report-summary__line__header">
              <Trans>AMOUNT DONATED</Trans>
            </div>
            <div className="donation-report-summary__line__value">
              {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.amount)}
            </div>
          </div>
        </div>

        <div className="donation-report-summary__comment">
          <Trans>Values changes accordingly with filter settings</Trans>
        </div>
      </div>
    );
  }
}

const mapState = (state, props) => ({
  filter: state.donations.filter,
});

const mapDispatch = (dispatch, props) => ({
  getDonationsSummary: (filter) => dispatch.donations.getSummary({ filter }),
});

export const DonationReportSummary = connect(mapState, mapDispatch)(Renderer);
