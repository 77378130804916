import { type CommunitySlim } from 'models';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { CommunityIcon, DataCard } from 'ui';

type P = {
  getPathToCommunityDetail: (id: string) => string;
  community: CommunitySlim;
  showTag?: boolean;
};

const CommunityCard = ({ getPathToCommunityDetail, community, showTag }: P) => {
  const { t } = useTranslation();

  return (
    <DataCard
      title={community.name}
      image={{
        src: community.coverPhoto?.url ?? images.default.communityCoverPhoto,
        alt: t('Community cover image'),
      }}
      tag={showTag ? { className: 'community-card__tag', text: t('Community'), icon: <CommunityIcon /> } : undefined}
      className="community-card"
      path={getPathToCommunityDetail(community.id)}
    />
  );
};

export default CommunityCard;
