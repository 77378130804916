import { useTranslation } from 'react-i18next';
import { Form, message, ModalFormActions, PhoneInput } from 'ui';
import { useProgramChangeRequest } from '../queries/mutations';

type P = {
  programId: string;
  userPhone?: string;
  onSuccess: (programId: string) => void;
};

export type ContactUsFormValues = {
  invitationPhone: string;
};

const ContactUsForm = ({ programId, userPhone, onSuccess }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ContactUsFormValues>();
  const { mutate: programChangeRequest, isLoading } = useProgramChangeRequest();

  const onFinish = (data: ContactUsFormValues) => {
    programChangeRequest(
      { data, programId },
      {
        onError() {
          message.error('Failed to request contact');
        },
        onSuccess() {
          message.success('Requests are generally reviewed withing 24 hours');
          onSuccess(programId);
        },
      },
    );
  };

  return (
    <Form<ContactUsFormValues> onFinish={onFinish} form={form} initialValues={{ invitationPhone: userPhone }}>
      <PhoneInput
        input={{
          placeholder: '123 456 7890',
        }}
        item={{
          name: 'invitationPhone',
          label: 'Your phone number',
          rules: [
            {
              required: true,
              message: t('This field is required'),
            },
          ],
        }}
      />
      <ModalFormActions
        submit={{
          children: 'Send request',
          loading: isLoading,
        }}
      />
    </Form>
  );
};

export default ContactUsForm;
