import { useLocation } from 'react-router-dom';
import { ContentLeft, Footer, Header, PasswordLogin, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnSuccessLogin, useExpiredPassword, useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

export type LoginLocationState = OnboardingLocationState;

const PagePasswordLogin = () => {
  const { state } = useLocation<LoginLocationState | undefined>();
  const { onNavigateToSignUp, onNavigateToPrimaryLogin, onNavigateToForgotPassword } =
    useOnboardingNavigation<LoginLocationState>();
  const { onSuccessLogin } = useOnSuccessLogin<LoginLocationState>();
  const { onExpiredPassword } = useExpiredPassword<LoginLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Sign up',
      type: 'primary',
      onClick() {
        onNavigateToSignUp();
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={
        <PasswordLogin<LoginLocationState>
          publicCommunityId={state?.join_community_id}
          onSuccessLogin={onSuccessLogin}
          onExpiredPassword={onExpiredPassword}
          state={state}
          onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
          onNavigateToForgotPassword={onNavigateToForgotPassword}
        />
      }
      footer={<Footer />}
    />
  );
};

export default PagePasswordLogin;
