import { useQueryClient } from '@tanstack/react-query';
import { type CalendarSyncProvider } from 'models';
import { useMutation } from 'query';
import { getCalendarSyncQueryKey } from '..';
import { deleteCalendarSync } from '../../services';

export const useRemoveCalendarSyncMutation = ({ provider }: { provider: CalendarSyncProvider }) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Record<string, unknown>>(
    async () => {
      const response = await deleteCalendarSync({ provider });

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(getCalendarSyncQueryKey());
      },
    },
  );
};
