import { type Community } from 'models';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Text, modalConfirm, message, DeleteIcon } from 'ui';
import { useDeleteUserFromComunity } from '../../../queries';
import { type User } from '../../UserList';

type P = {
  community: Community;
};

export const useDeleteMember = ({ community }: P) => {
  const { t } = useTranslation();

  const { mutateAsync: deleteUser } = useDeleteUserFromComunity();

  return useCallback(
    (user: User) => {
      modalConfirm({
        title: t('Remove user from?', {
          fullName: user.fullName,
          groupName: community.name,
        }),
        content: <Text>{t('This action is irreversible.')}</Text>,
        cancelText: t('Cancel'),
        okText: t('Yes, remove'),
        okButtonProps: {
          danger: true,
        },
        async onOk() {
          try {
            await deleteUser(
              {
                userId: user.id,
                communityId: community.id,
              },
              {
                onSuccess() {
                  message.success(t('User removed successfully'));
                },
                onError() {
                  message.error(t('Something went wrong. The user could’t be removed.'));
                },
              },
            );
          } catch {}
        },
        icon: <DeleteIcon style={{ color: variables.brandRedColor.value }} />,
      });
    },
    [community.id, community.name, deleteUser, t],
  );
};
