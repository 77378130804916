import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const StorageService = {
  send(file, onProgress) {
    // TODO move formdata
    const form = new FormData();
    form.append('file', file);
    return http.post(
      '/file_uploads',
      file.url ? file : form,
      onProgress
        ? {
            onUploadProgress: onProgress,
          }
        : undefined,
    );
  },
  get(id) {
    return http.get(`/file_uploads/${id}`);
  },
};
