import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Spin, Toast } from '../';

// TODO: Add 'non-reverse' scroll functionality
type P<T> = {
  hasNextPage: boolean;
  isLoading?: boolean;
  hasNewData?: boolean;
  reverse?: boolean;
  className: string;
  loadingTitle: string;
  toastTitle?: string;
  bottomOffset?: number;
  onLoadMore: () => void;
  onScrollAtStart?: () => void;
  data: T[];
  createItem: (item: T) => ReactNode;
  compareFirstItems?: (item1?: T, item2?: T) => boolean;
  isEqual?: (item1: T, item2: T) => boolean;
};

function InfiniteScroll<T>({
  hasNextPage,
  isLoading = false,
  reverse = false,
  hasNewData = false,
  className,
  loadingTitle,
  toastTitle,
  bottomOffset = 0,
  onLoadMore,
  onScrollAtStart,
  isEqual,
  data,
  createItem,
  compareFirstItems: compareItem,
}: P<T>) {
  const [infiniteScrollReference] = useInfiniteScroll({
    onLoadMore: () => {
      if (!isLoadingReference.current) {
        isLoadingReference.current = true;
        onLoadMore();
      }
    },
    hasNextPage,
    loading: isLoading,
  });
  const rootReference = useRef<HTMLDivElement | null>(null);
  const distanceReference = useRef<{ direction: 'bottom' | 'top'; value: number }>();
  const isLoadingReference = useRef<boolean>(false);
  const [currentData, setCurrentData] = useState<T[]>(data);
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);

  useEffect(() => {
    const rootNode = rootReference.current;

    if (
      rootNode &&
      reverse &&
      (
        currentData.length !== data.length ||
        !isEqual?.(currentData[0], data[0]) ||
        !isEqual?.(currentData[currentData.length - 1], data[data.length - 1])
      )
    ) {
      if (currentData.length > 0 && !compareItem?.(currentData[currentData.length - 1], data[data.length - 1])) {
        const distanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
        if (distanceToBottom <= rootNode.offsetHeight + bottomOffset) {
          distanceReference.current = { direction: 'bottom', value: 0 };
        } else {
          distanceReference.current = { direction: 'top', value: rootNode.scrollTop };
          setIsToastVisible(true);
        }
      } else {
        distanceReference.current = { direction: 'bottom', value: rootNode.scrollHeight - rootNode.scrollTop };
      }
      setCurrentData(data);
      // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (rootNode && !reverse) {
      distanceReference.current = { direction: 'top', value: rootNode.scrollTop };
      setCurrentData(data);
    }
  }, [data, reverse, bottomOffset, compareItem, currentData, isEqual]);

  useEffect(() => {
    const rootNode = rootReference.current;
    const { direction, value } = distanceReference.current ?? { direction: 'bottom', value: 0 };

    if (rootNode) {
      if (reverse) {
        rootNode.scrollTop = direction === 'top' ? value : rootNode.scrollHeight - value;
      } else {
        rootNode.scrollTop = value;
      }

      isLoadingReference.current = false;
    }
  }, [currentData, distanceReference.current, reverse]);

  const handleRootScroll = useCallback(() => {
    const rootNode = rootReference.current;
    if (rootNode && reverse) {
      const distanceToRootBottom = rootNode.scrollHeight - rootNode.scrollTop;

      if (isToastVisible && distanceToRootBottom <= rootNode.offsetHeight + bottomOffset) {
        setIsToastVisible(false);
        onScrollAtStart?.();
      }
    }
  }, [bottomOffset, isToastVisible, onScrollAtStart, reverse]);

  const handleToastClick = () => {
    const rootNode = rootReference.current;

    if (rootNode && reverse && !isLoading) {
      rootNode.scrollTo({
        top: rootNode.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const renderLoadingElement = () => (
    <div className="infinite-scroll-loading" ref={infiniteScrollReference}>
      <Spin key={0} tip={loadingTitle} />
    </div>
  );

  const renderToastElement = () => {
    if (isToastVisible && hasNewData) {
      return <Toast text={toastTitle ?? ''} onClick={handleToastClick} />;
    }

    return null;
  };

  return (
    <>
      <div className={className} ref={rootReference} onScroll={handleRootScroll}>
        {reverse ? (
          <>
            {hasNextPage && renderLoadingElement()}
            {currentData.map((d: T) => createItem(d))}
          </>
        ) : (
          <>
            {currentData.map((d: T) => createItem(d))}
            {hasNextPage && renderLoadingElement()}
          </>
        )}
      </div>
      {renderToastElement()}
    </>
  );
}

export default InfiniteScroll;
