import { createModel } from '@rematch/core';

import { update } from './helpers';
import { AdminService } from '../services/AdminService';
import { CoursesService } from '../services/CoursesService';

export const model = createModel({
  name: 'adminCourses',
  state: [],
  reducers: {
    setCollection: (state, payload) => {
      return payload;
    },
    pushAfterDuplicate: (state, payload) => {
      if (!state.map((c) => c.status).includes(payload.status)) {
        return state;
      }
      return state.concat(payload);
    },
    push: (state, payload) => {
      if (state.map((c) => c.id).includes(payload.id)) {
        return state;
      }
      return update(state, { $unshift: [payload] });
    },
    deleteCourse: (state, course) => state.filter((c) => c.id !== course.id),
  },
  selectors: {
    find(state, payload) {
      return state.find((c) => c.id === payload.id);
    },
    get(state) {
      return state;
    },
  },
  effects: (dispatch) => ({
    async get(data) {
      const response = await AdminService.getCourses({ status: data.status });
      if (response.ok) {
        this.setCollection(response.data);
      }
      return response;
    },
    async find(id) {
      const response = await CoursesService.find(id);
      if (response.ok) {
        this.push(response.data);
      }
    },
    async delete(course) {
      const response = await CoursesService.delete(course.id);

      if (response.ok) {
        this.deleteCourse(course);
      }
      return response;
    },
  }),
});
