import type React from 'react';
import { images } from 'common/utils';
import { Avatar, Text } from '..';
import { type AutocompleteUser } from '../../models';

type P = {
  user: AutocompleteUser;
  cardAction?: React.ReactNode;
};

const ProfileCard: React.FC<P> = ({ user, cardAction }) => {
  return (
    <div className="profile-card">
      <div className="profile-card__avatar">
        <Avatar photo={user.profile_photo?.url ?? images.default.userProfilePhoto} size={48} />
      </div>
      <div className="profile-card__name">
        <Text className="profile-card__name-text">
          {user.first_name} {user.last_name}
        </Text>
      </div>
      <div className="profile-card__action">{cardAction}</div>
    </div>
  );
};

export default ProfileCard;
