import cn from 'classnames';
import { type ReactNode } from 'react';

type P = {
  children: ReactNode;
  aside?: ReactNode;
  fullWidth?: boolean;
  className?: string;
};

const HeaderRow = ({ children, aside, fullWidth, className }: P) => {
  return (
    <div className={cn('content-object-header-row', className)}>
      {aside ? <div className="content-object-header-row__aside">{aside}</div> : null}
      <div
        className={cn('content-object-header-row__content', {
          'content-object-header-row__content--solo': !aside && !fullWidth,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default HeaderRow;
