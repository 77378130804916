import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button } from 'ui';

class Renderer extends React.Component {
  render() {
    return (
      <>
        <Button type="link" onClick={this.props.readAllNotifications} className="widget-notification-nav__button">
          Mark all as read
        </Button>
        <Button
          type="link-secondary"
          onClick={() => {
            this.props.history.push('/settings/notifications');
          }}
          className="widget-notification-nav__button"
        >
          Notifications Settings
        </Button>
      </>
    );
  }
}

const mapState = () => ({});

const mapDispatch = (dispatch) => ({
  readAllNotifications: async () => {
    if (await window.confirm('Are you sure you want to mark all notifications as read?')) {
      dispatch.notifications.readAllAsync();
    }
  },
});

export const NotificationsNav = connect(mapState, mapDispatch)(withRouter(Renderer));
