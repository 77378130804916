export class WebPageMetaData {
  code?: string;
  title?: string;
  description?: string;
  image?: string;

  constructor({
    code,
    title,
    description,
    image,
  }: {
    code?: string;
    title?: string;
    description?: string;
    image?: string;
  }) {
    this.code = code;
    this.title = title;
    this.description = description;
    this.image = image;
  }
}
