const YOUTUBE_ID_LENGTH = 11;
export const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[\dA-Za-z][\dA-Za-z-]+[\dA-Za-z]\.\S{2,}|www\.[\dA-Za-z][\dA-Za-z-]+[\dA-Za-z]\.\S{2,}|https?:\/\/(?:www\.|(?!www))[\dA-Za-z]+\.\S{2,}|www\.[\dA-Za-z]+\.\S{2,})/g;

export const getLinksFromText = (text: string) => {
  const match = text?.match(urlRegex);
  return match ? match : [];
};

export const getYoutubeLinksAndIds = (urlArray: string[]) => {
  const youtubeLinksAndIdsArray = [];
  const youtubeRegex =
    /^https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^\n"#&'/<>?]*)/i;

  for (const url of urlArray) {
    const match = youtubeRegex.exec(url);
    if (match?.[1].length === YOUTUBE_ID_LENGTH) {
      youtubeLinksAndIdsArray.push({ url: match[0], id: match[1] });
    }
  }

  return youtubeLinksAndIdsArray.length > 0 ? youtubeLinksAndIdsArray : [];
};

export const getInitials = (firstName?: string | null, lastName?: string | null): string =>
  `${firstName ? firstName.slice(0, 1).toUpperCase() : ''}${lastName ? lastName.slice(0, 1).toUpperCase() : ''}`;

export const isPracticeEnabled = () => process.env.REACT_APP_IS_PRACTICE_ENABLED === 'true';

export const isStringEmptyOrOnlyWhiteSpace = (message: string) => {
  return !/\S/.test(message);
};

export const getImageDimensions = (file: Blob): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      if (event !== null && event.target !== null) {
        img.src = event.target.result as string;
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = (error) => {
          reject(error);
        };
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};
