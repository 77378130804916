// TODO: Move these types
export type ChannelsOrganization = {
  id: string;
  name: string;
  description: string;
  default?: boolean;
  customChannels: boolean;
};

export type ChannelsOrganizationDataObject = {
  id: string;
  name: string;
  description: string;
  default?: boolean;
  custom_channels: boolean;
};

export const getChannelsOrganizationData = (data: ChannelsOrganizationDataObject): ChannelsOrganization => ({
  id: data.id,
  name: data.name,
  description: data.description,
  default: data.default,
  customChannels: data.custom_channels,
});
