import { setProvidedApiClient, UploadFile, type UploadSubscriber } from '@pba/media-chunked-upload';
import { createContext, useContext, useCallback, useMemo, useState, type ReactNode } from 'react';
import { mediaModuleClient } from 'common/services';
import { message } from 'ui';
import { useUploadBeforeUnload } from './hooks';

export type { UploadFile, UploadSubscriber } from '@pba/media-chunked-upload';

export type UploadSuccessAction = ({ id, ref }: { id: string; ref: string }) => void;
export type ContentType = 'content_object';
export type VideoType = 'full' | 'trailer';
export type UploadContextType = {
  [C in ContentType]?: {
    [K in string]?: {
      [V in VideoType]?: UploadFile;
    };
  };
};

const UploadContext = createContext<{
  uploads: UploadContextType;
  setUpload: ({
    contentType,
    ref,
    videoType,
    file,
    onSuccessAction,
  }: {
    contentType: ContentType;
    ref: string;
    videoType: VideoType;
    file: File;
    onSuccessAction: UploadSuccessAction;
  }) => void;
  removeUpload: (params: { contentType: ContentType; ref: string; videoType: VideoType }) => void;
} | null>(null);

setProvidedApiClient({ axiosInstance: mediaModuleClient });

export const UploadProvider = ({ children }: { children: ReactNode }) => {
  const [uploads, setUploads] = useState<UploadContextType>({});

  const setUpload = useCallback(
    ({
      contentType,
      ref,
      videoType,
      file,
      onSuccessAction,
    }: {
      contentType: ContentType;
      ref: string;
      videoType: VideoType;
      file: File;
      onSuccessAction: UploadSuccessAction;
    }) => {
      setUploads((previousUploads) => {
        const newUploads = { ...previousUploads };

        const previousUpload = newUploads[contentType]?.[ref]?.[videoType];
        if (previousUpload && ['initial', 'uploading', 'error'].includes(previousUpload.state.status)) {
          previousUpload.abort();
        }

        const newUploadFile = new UploadFile(file, {
          mimeType: file.type,
          ref,
          type: contentType,
          name: file.name,
        });

        newUploads[contentType] = {
          ...newUploads[contentType],
          [ref]: {
            ...newUploads[contentType]?.[ref],
            [videoType]: newUploadFile,
          },
        };

        const subscriber: UploadSubscriber = {
          onSuccess(response) {
            onSuccessAction({ ref, id: response._id });
            message.success('File uploaded successfully');
            // TODO: need to unsubscribe from the upload in every place where we call subscribe
            newUploadFile.unsubscribe(subscriber);
          },
          onError() {
            message.error('Error uploading file');
          },
        };

        newUploadFile.subscribe(subscriber);

        newUploadFile.upload();

        return newUploads;
      });
    },
    [],
  );

  useUploadBeforeUnload({ uploads });

  const removeUpload = useCallback(
    ({ contentType, ref, videoType }: { contentType: ContentType; ref: string; videoType: VideoType }) => {
      setUploads((previousUploads) => {
        return {
          ...previousUploads,
          [contentType]: {
            ...previousUploads[contentType],
            [ref]: {
              ...previousUploads[contentType]?.[ref],
              [videoType]: undefined,
            },
          },
        };
      });
    },
    [],
  );

  const value = useMemo(() => ({ uploads, setUpload, removeUpload }), [uploads, setUpload, removeUpload]);

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export const useFileUpload = ({
  contentType,
  ref,
  videoType,
}: {
  contentType: ContentType;
  ref: string;
  videoType: VideoType;
}) => {
  const context = useContext(UploadContext);

  if (context === null) {
    throw new Error('useFileUpload must be used within an UploadProvider');
  }

  const { uploads, setUpload, removeUpload } = context;

  const startUpload = useCallback(
    ({ file, onSuccessAction }: { file: File; onSuccessAction: UploadSuccessAction }) => {
      setUpload({ contentType, ref, videoType, file, onSuccessAction });
    },
    [contentType, ref, videoType, setUpload],
  );

  const removeCurrentUpload = useCallback(() => {
    removeUpload({ contentType, ref, videoType });
  }, [removeUpload, contentType, ref, videoType]);

  return useMemo(
    () => ({
      file: uploads[contentType]?.[ref]?.[videoType],
      startUpload,
      removeUpload: removeCurrentUpload,
    }),
    [uploads, contentType, ref, videoType, startUpload, removeCurrentUpload],
  );
};
