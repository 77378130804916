import { getAppConfig } from '../atoms/app-config-gate';
import { FacebookIcon, LinkedInIcon, MailIcon, message, XIcon } from 'ui';

const { colors } = getAppConfig();

export const SOCIAL_NETWORKS = [
  {
    name: 'Facebook',
    icon_name: 'facebook',
    icon_color: colors.facebookBlue,
    renderIcon: ({ className }) => <FacebookIcon className={className} />,
    domain: 'facebook.com',
    generateLink: ({ redirect_uri }) => {
      return `https://www.facebook.com/sharer/sharer.php?u=${window.encodeURIComponent(redirect_uri)}&src=sdkpreparse`;
    },
  },
  {
    name: 'LinkedIn',
    icon_name: 'linkedin',
    icon_color: colors.linkedinBlue,
    renderIcon: ({ className }) => <LinkedInIcon className={className} />,
    domain: 'linkedin.com',
    generateLink: ({ redirect_uri }) => {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${window.encodeURIComponent(redirect_uri)}`;
    },
  },
  {
    name: 'X',
    icon_name: 'twitter',
    icon_color: colors.twitterBlue,
    renderIcon: ({ className }) => <XIcon className={className} />,
    domain: 'twitter.com',
    generateLink: ({ text, redirect_uri }) => {
      return `https://twitter.com/intent/tweet?text=${window.encodeURIComponent(text)}&url=${window.encodeURIComponent(
        redirect_uri,
      )}`;
    },
  },
  {
    name: 'Email',
    icon_name: 'invite-sidebar',
    icon_color: colors.gray,
    renderIcon: ({ className }) => <MailIcon className={className} />,
    domain: '',
    alert: () => {
      message.info('shareEmailInfoMessage');
    },
    generateLink: ({ text, redirect_uri }) => {
      return `mailto:?subject=Share from ${process.env.REACT_APP_TITLE || ''}&body=${text} ${redirect_uri}`;
    },
  },
];

export function openPopup(url, name) {
  const width = 640;
  const height = 480;
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  return window.open(
    url,
    name,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
  );
}

export function polling(popup, cb, include) {
  const closeDialog = (polling) => {
    clearInterval(polling);
    if (!popup) return;
    popup.close();
  };

  const polling = setInterval(() => {
    if (!popup || popup.closed || popup.closed === undefined) {
      closeDialog(polling);
    }

    try {
      if (popup.location.hostname && !popup.location.hostname.includes(include)) {
        if (cb) {
          if (popup.location.search) {
            cb(new URLSearchParams(popup.location.search));
          } else {
            cb(null);
          }
        }

        closeDialog(polling);
      }
    } catch (error) {
      // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
      // A hack to get around same-origin security policy errors in IE.
    }
  }, 500);
}
