import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useCalendarOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      calendars: [
        { label: t('Yahoo'), value: 'yahoo' },
        { label: t('AOL'), value: 'aol' },
        { label: t('Proton'), value: 'proton' },
        { label: t('Zoho'), value: 'zoho' },
        { label: t('Other (not listed here)'), value: 'other' },
      ],
      noCalendar: 'no_calendar',
    }),
    [t],
  );
};
