import * as React from 'react';
import Popup from 'reactjs-popup';
import { SearchInput } from '../../atoms/search-input';
import { Icon } from '../../atoms/icon';
import { Trans } from 'react-i18next';

class Renderer extends React.Component {
  state = {
    open: false,
    communitiesGroupedByOrganization: [],
    chosen: [],
  };

  async componentDidUpdate(prevProps) {
    if (this.props.communities.length !== prevProps.communities.length) {
      this.setState({ communitiesGroupedByOrganization: this.groupByName(this.props.communities) });
    }
  }

  groupByName = (communities) => {
    let i = -1;
    let previousName = '';
    const communitiesGroupedByOrganization = communities
      .sort((a, b) => a.organization.name.localeCompare(b.organization.name))
      .reduce((groups, game) => {
        const name = game.organization.name;
        if (name !== previousName) {
          previousName = name;
          i++;
        }

        if (!groups[i]) {
          groups[i] = [];
        }
        groups[i].push(game);

        return groups;
      }, []);

    return communitiesGroupedByOrganization.map((cs) =>
      cs.sort((a, b) => b.organization.name.localeCompare(a.organization.name)),
    );
  };

  getCheckedCommunity = (id) => this.state.chosen.find((community) => community.id === id);

  getCheckedOrganization = (communities) =>
    communities.filter((community) => this.state.chosen.find((innerCommunity) => innerCommunity.id === community.id))
      .length === communities.length;

  onSearch = (value) => {
    const searchedCommunities = value.target.value
      ? this.props.communities.filter(
          (communities) => communities.name.search(new RegExp(value.target.value, 'i')) > -1,
        )
      : this.props.communities;

    this.setState({ communitiesGroupedByOrganization: this.groupByName(searchedCommunities) });
  };

  onCheck = (community) => {
    if (!Array.isArray(community)) {
      if (this.getCheckedCommunity(community.id)) {
        this.setState({ chosen: this.state.chosen.filter((innerCommunity) => innerCommunity.id !== community.id) });
      } else {
        this.setState({ chosen: this.state.chosen.concat([community]) });
      }
    } else {
      if (this.getCheckedOrganization(community)) {
        this.setState({
          chosen: this.state.chosen.filter((innerCommunity) => !community.map((c) => c.id).includes(innerCommunity.id)),
        });
      } else {
        this.setState({ chosen: this.state.chosen.concat(community) });
      }
    }
  };

  closePopup = () => {
    this.setState({ open: true }, () => this.setState({ open: false }));

    this.props.onChange(this.state.chosen);
  };

  render() {
    return (
      <div style={{ display: 'inline-block', lineHeight: '28px', marginLeft: 24 }}>
        <Popup
          trigger={() => (
            <span>
              <span style={{ color: '#455a64', fontSize: 15, cursor: 'pointer' }}>
                <Trans>More filters</Trans>
                <img
                  alt="arrow down"
                  src="/assets/arrow-down.svg"
                  style={{ marginLeft: 3, width: 9, display: 'inline-block' }}
                />
              </span>
            </span>
          )}
          position="bottom right"
          offsetY={10}
          open={this.state.open}
          closeOnDocumentClick={this.state.chosen.length === 0}
          closeOnEscape={this.state.chosen.length === 0}
          contentStyle={{
            backgroundColor: '#22333C',
            borderRadius: 4,
            boxShadow: 'none',
            border: 0,
            color: '#ECEFF1',
            padding: '16px 30px',
            width: 446,
            height: 524,
            lineHeight: '28px',
            fontWeight: 'bold',
            fontSize: '12px',
          }}
          arrowStyle={{ color: '#22333c', filter: 'none' }}
        >
          <div style={{ color: '#eceff1', fontSize: 13, lineHeight: '40px', marginBottom: 5 }}>
            <Trans>Filter by Organization and Community</Trans>
          </div>
          <SearchInput
            placeholder={'Search...'}
            outerDivCss={{
              height: 29,
              backgroundColor: '#324149',
              position: 'relative',
              overflow: 'hidden',
              marginBottom: 20,
              flexBasis: 29,
            }}
            iconSize={14}
            iconColor={'#90A4AE'}
            inputCss={{
              color: '#90A4AE',
              position: 'relative',
              width: '100%',
              background: 'none',
              border: 0,
              outline: 'none',
              fontSize: 13,
              lineHeight: `${22 / 14}px`,
              paddingLeft: 34,
              paddingRight: 10,
            }}
            onKeyUp={this.onSearch}
          />

          <div style={{ overflow: 'auto', height: 350 }}>
            {this.state.communitiesGroupedByOrganization.map((group, i) => (
              <div key={i} style={{ borderBottom: '1px solid #455a6' }}>
                <div style={{ padding: '5px 0', display: 'flex' }}>
                  <div style={{ alignItems: 'center', marginRight: 15, display: 'flex' }}>
                    <React.Fragment>
                      <input
                        name={group[0].organization.id}
                        id={group[0].organization.id}
                        type="checkbox"
                        onChange={() => this.onCheck(group)}
                        hidden
                      />
                      <label htmlFor={group[0].organization.id}>
                        <i
                          style={{
                            width: 16,
                            height: 16,
                            borderRadius: '3px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            transition: 'background-color 200ms ease-in-out',
                            border: '1px solid #90a4ae',
                            backgroundColor: `${this.getCheckedOrganization(group) ? '#EB6B3F' : 'transparent'}`,
                          }}
                        >
                          {this.getCheckedOrganization(group) ? <Icon name="check" color="white" size={10} /> : null}
                        </i>
                      </label>
                    </React.Fragment>
                  </div>

                  <div>{group[0].organization.name}</div>
                </div>
                {group.map((community, j) => (
                  <div key={j} style={{ padding: '5px 0', display: 'flex', marginLeft: 27 }}>
                    <div style={{ alignItems: 'center', marginRight: 15, display: 'flex' }}>
                      <React.Fragment>
                        <input
                          name={community.id}
                          id={community.id}
                          type="checkbox"
                          onChange={() => this.onCheck(community)}
                          hidden
                        />
                        <label htmlFor={community.id}>
                          <i
                            style={{
                              width: 16,
                              height: 16,
                              borderRadius: '3px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              cursor: 'pointer',
                              transition: 'background-color 200ms ease-in-out',
                              border: '1px solid #90a4ae',
                              backgroundColor: `${this.getCheckedOrganization(group) ? '#EB6B3F' : 'transparent'}`,
                            }}
                          >
                            {this.getCheckedCommunity(community.id) ? (
                              <Icon name="check" color="white" size={10} />
                            ) : null}
                          </i>
                        </label>
                      </React.Fragment>
                    </div>

                    <div>{community.name}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'flex-end', widows: '100%', height: 28, marginTop: 17 }}
            onClick={this.closePopup}
          >
            <div
              style={{
                width: 87,
                height: 28,
                borderRadius: '100px',
                backgroundColor: '#fc612d',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <Trans>Apply</Trans>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export const OrganizationsFilter = Renderer;
