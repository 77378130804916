import * as React from 'react';
import { Form, Formik } from 'formik';
import { Trans, withTranslation } from 'react-i18next';

import { Modal } from '../../atoms/modal';
import { Button } from '../../atoms/button';
import { TextField } from '../../atoms/form';
import { RequestError } from '../../atoms/error';
import { validator } from '../../utils/validator';
import { ThemeConsumer } from '../../atoms/theme';

const INITIAL_FORM_VALUES = {
  name: '',
};

const styles = {
  modal: {
    content: {
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, 0%)',
    },
  },
};

export class Renderer extends React.Component {
  state = {
    response: null,
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <Formik
            validate={(values, props) => {
              return validator.validateAll(values, {
                name: 'required',
              });
            }}
            initialValues={INITIAL_FORM_VALUES}
            onSubmit={async (values, formik) => {
              formik.setSubmitting(true);
              this.setState({ response: null });

              const result = { folderName: values.name, parentId: this.props.parentId };

              const response = await this.props.onCreateFolder(result);
              formik.resetForm(INITIAL_FORM_VALUES);

              this.setState({ response: response });
              formik.setSubmitting(false);

              if (response.ok) {
                this.props.onCreateFolderSuccess();
              }
            }}
          >
            {(formik) => {
              return (
                <Modal
                  isOpen={this.props.isOpen}
                  onRequestClose={this.props.onRequestClose}
                  styles={styles.modal}
                  showFooter={false}
                >
                  {(modal) => (
                    <Form>
                      <TextField name="name" placeholder={this.props.t('Folder name')} id="input_folder-name" />
                      <RequestError response={this.state.response} />
                      <Button
                        type="submit"
                        color={theme.color.brand}
                        textColor="white"
                        size="md"
                        title={<Trans>Create Folder</Trans>}
                        disabled={!formik.isValid || formik.isSubmitting}
                        id="btn_submit-create-folder"
                      />
                    </Form>
                  )}
                </Modal>
              );
            }}
          </Formik>
        )}
      </ThemeConsumer>
    );
  }
}

export const CreateFolderModal = withTranslation()(Renderer);
