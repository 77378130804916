import * as React from 'react';
import { Trans } from 'react-i18next';
import { Field } from 'formik';

import { ThemeConsumer } from '../theme';
import { Input } from './base-atoms/Input';
import { FieldError, isInvalid } from './FieldError';
import { Icon } from '../icon';

export class TextField extends React.Component {
  static defaultProps = {
    secret: false,
    type: 'text',
  };

  state = {
    revealSecret: false,
  };

  renderRevealButton = () => (
    <div
      onClick={() => {
        this.setState({ revealSecret: !this.state.revealSecret });
      }}
      style={{
        marginLeft: 10,
        paddingBottom: 5,
        fontSize: 10,
        color: '#90a4ae',
        textTransform: 'uppercase',
        cursor: 'pointer',
      }}
    >
      {this.state.revealSecret ? <Trans>Hide</Trans> : <Trans>Show</Trans>}
    </div>
  );

  render() {
    return (
      <Field name={this.props.name} className="text-field">
        {(fieldProps) => {
          const { label, secret, ...htmlProps } = this.props;
          const invalid = isInvalid(this.props.name, fieldProps.form);
          return (
            <label className="text-field__wrapper">
              <div className={`text-field__label ${this.props.disabled ? 'disabled' : ''}`}>{label}</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: `1px solid ${invalid ? 'red' : '#c8c7cc'}`,
                }}
              >
                <Input
                  {...htmlProps}
                  {...fieldProps.field}
                  type={this.state.revealSecret ? 'text' : this.props.secret ? 'password' : this.props.type}
                />
                {this.props.secret ? this.renderRevealButton() : null}
                {fieldProps.field.value && !this.props.disabled ? (
                  <ThemeConsumer>
                    {(theme) => (
                      <span
                        style={{
                          marginLeft: 10,
                          padding: 5,
                          paddingBottom: 12,
                          cursor: 'pointer',
                        }}
                        onClick={() => fieldProps.form.setFieldValue(this.props.name, '')}
                      >
                        <Icon name={'close'} size={10} color={theme.color.lightGray} />
                      </span>
                    )}
                  </ThemeConsumer>
                ) : null}
              </div>
              <FieldError name={this.props.name} />
            </label>
          );
        }}
      </Field>
    );
  }
}
