import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { ContentObjectService } from '../../services/ContentObjectService';
import { dispatch } from '../../store';
import { http } from '../../services/shared';
import { Loader } from '../../atoms/loader';
import { ShareContentObjectEditor } from '../../atoms/share-content-object';

const OLD_RN_BRIDGE = 1;
const NEW_RN_BRIDGE = 2;
const WITHOUT_RN_BRIDGE = 0;

function findReactNativeBridge() {
  /* eslint-disable */
  if (typeof WebViewBridge !== 'undefined') {
    return OLD_RN_BRIDGE;
  }
  /* eslint-enable */

  if (typeof window.ReactNativeWebView !== 'undefined') {
    return NEW_RN_BRIDGE;
  }

  return WITHOUT_RN_BRIDGE;
}

function onSendDataToMobile(data) {
  switch (findReactNativeBridge()) {
    case OLD_RN_BRIDGE:
      /* eslint-disable */
      WebViewBridge.send(JSON.stringify(data));
      /* eslint-enable */
      break;
    case NEW_RN_BRIDGE:
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
      break;
    default:
  }
}

class Renderer extends React.Component {
  state = {
    values: null,
    queries: null,
  };

  defaultViewPort = null;

  async componentDidMount() {
    const queries = JSON.parse(
      '{"' +
        decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
        '"}',
    );

    this.setState({ queries: queries });

    http.setHeader('x-app-name', queries.config);

    http.addAsyncRequestTransform(async (request) => {
      if (request.doNotIntercept) {
        return request;
      }

      request.headers = request.headers || {};
      request.headers.Authorization = `Bearer ${queries.token}`;

      return request;
    });

    await dispatch.session.fetchUser();

    this.defaultViewPort = document.querySelector('meta[name="viewport"]').getAttribute('content');
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no',
      );

    switch (findReactNativeBridge()) {
      case OLD_RN_BRIDGE:
        this.initOldMobileLitener();
        break;
      case NEW_RN_BRIDGE:
        this.initNewMobileListener();
        break;
      default:
    }

    onSendDataToMobile({ type: 'finish-load' });
  }

  componentWillUnmount() {
    document.querySelector('[meta name="viewport"]').setAttribute('content', this.defaultViewPort);
  }

  initOldMobileLitener = () => {
    /* eslint-disable */
    WebViewBridge.onMessage = (message) => {
      const response = JSON.parse(message);

      switch (response.type) {
        case 'data':
          this.setState({ values: response.data });
          break;
        default:
      }
    };
    /* eslint-enable */
  };

  initNewMobileListener = () => {
    const listener = (e) => {
      if (e.isTrusted) {
        return;
      }

      const response = JSON.parse(e.data);

      switch (response.type) {
        case 'data':
          this.setState({ values: response.data });
          break;
        default:
      }
    };

    if (this.state.queries.platform === 'ios') {
      window.addEventListener('message', listener);
    } else {
      document.addEventListener('message', listener);
    }
  };

  render() {
    return this.props.user && this.state.queries && this.state.values ? (
      <div style={{ backgroundColor: '#ffffff', padding: '0 15px' }}>
        <ShareContentObjectEditor
          initialValues={this.state.values}
          viewer={this.props.user}
          fields={this.state.queries.fields.split(',')}
          onSuccess={(response) => {
            onSendDataToMobile({ type: 'success', data: response });
          }}
          onSubmit={async (draft) => {
            const response = await ContentObjectService.create({ ...draft, parent_id: null });

            onSendDataToMobile({ type: 'submit', data: response });

            return response;
          }}
        />
      </div>
    ) : (
      <Loader color="gray" size={30} />
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const MobilePageShareContentObject = connect(mapState)(Renderer);
