import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { CategoriesService } from '../../../services/CategoriesService';
import { Autocomplete } from '../../autocomplete';
import { TagsBox } from '../../tags';

export class Renderer extends React.Component {
  itemToString = (item) => (item === null ? '' : item.name);

  getDataForValue = async (value) => {
    const response = await CategoriesService.list({ type: this.props.type });
    return response.data.filter((state) => state.name.toLowerCase().includes(value ? value.toLowerCase() : ''));
  };

  appendToSelection = (item) => {
    if (item !== null) {
      this.props.onChange(this.props.selection.concat(item));
    }
  };

  render() {
    return (
      <Autocomplete
        clearOnSelect
        currentSelectedData={this.props.currentSelectedData}
        dataForValue={this.getDataForValue}
        itemToString={this.itemToString}
        getRowId={(item) => item.id}
        placeholder={this.props.placeholder}
        onSelect={this.appendToSelection}
        renderInput={(ref, inputProps) => (
          <div ref={ref}>
            <TagsBox
              inputProps={inputProps}
              placeholder={this.props.t('Post in...')}
              selection={this.props.selection}
              itemToString={this.itemToString}
              onChange={this.props.onChange}
              data-test-id={this.props?.['data-test-id']}
            />
          </div>
        )}
      />
    );
  }
}

export const CategoriesInput = withTranslation()(Renderer);
