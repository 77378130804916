import { type ReduxUser, type ProgramContentObjectRedux } from 'models';
import Rating from 'features/programs/feed/Rating';
import { canRateProgramObject, canViewRating as canViewRatingFunction } from 'permissions';
import { useReloadContentObjectMutation } from '../../../queries';
import { CommentCount } from '../CommentCount';
import { StatusBarLayout } from '../StatusBarLayout';

type P = {
  item: ProgramContentObjectRedux;
  viewer: ReduxUser;
  onShowComments?: () => void;
};

const ProgramStatusBar = ({ item, viewer, onShowComments }: P) => {
  const {
    id,
    comments_count: commentsCount = 0,
    evaluation_rating_average: ratingAverage = '',
    program,
    evaluation,
  } = item;
  const canRate = canRateProgramObject({ viewer, program });
  const canViewRating = canViewRatingFunction({ viewer, contentObject: item });
  const { mutateAsync: reloadObject } = useReloadContentObjectMutation();

  const rating = (
    <Rating
      contentObjectId={id}
      rating={evaluation ?? 0}
      ratingAverage={ratingAverage}
      canRate={canRate}
      canViewRating={canViewRating}
      onRate={async () => reloadObject({ id })}
    />
  );

  const showComments = commentsCount > 0;

  return showComments || canViewRating || canRate ? (
    <StatusBarLayout
      left={
        <>
          {canViewRating ? rating : null}
          <div className="program-status-bar">
            {showComments ? <CommentCount count={commentsCount} onShowComments={onShowComments} /> : null}
          </div>
        </>
      }
      right={<>{canRate ? rating : null}</>}
    />
  ) : null;
};

export default ProgramStatusBar;
