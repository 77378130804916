import * as React from 'react';

import { ThemeConsumer } from '../../atoms/theme';
import { Table } from '../../atoms/table';
import { MemberRow } from './MemberRow';
import { InViewport } from '../../atoms/in-viewport';
import { Loader } from '../../atoms/loader';

export class MembersList extends React.Component {
  static defaultProps = {
    hasMoreObjects: true,
    loading: true,
  };

  render() {
    const { members, onRequestUserProfile, hasMoreObjects, onGetNextObjects, dateType } = this.props;

    return (
      <ThemeConsumer>
        {() => (
          <>
            <Table data-test-id="members-list">
              <>
                {members.map((member, idx) => {
                  return (
                    <MemberRow
                      dateType={dateType}
                      key={member.id}
                      member={member}
                      onRequestUserProfile={onRequestUserProfile}
                      data-test-id={`member[${idx}]`}
                      onDeleteMember={this.props.onDeleteMember}
                      courseName={this.props.courseName}
                    />
                  );
                })}
              </>
            </Table>
            {this.props.loading ? (
              <Loader />
            ) : hasMoreObjects ? (
              <InViewport key="members-list" onEnter={onGetNextObjects} />
            ) : null}
          </>
        )}
      </ThemeConsumer>
    );
  }
}
