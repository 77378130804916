import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { PageCommunityAssets as PageCommunityAssetsNew } from 'pages';

import { dispatch } from 'old/store';

class Renderer extends React.Component {
  render() {
    return <PageCommunityAssetsNew viewer={this.props.viewer} fetchUser={dispatch.session.fetchUser} />;
  }
}

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export const PageCommunityAssets = connect(mapState)(Renderer);
