import { Component } from 'react';

import { GalleryPreview } from 'old/atoms/gallery-preview';
import { isVisualFile } from 'old/utils/files';

export class Renderer extends Component {
  render() {
    const { item } = this.props;
    const imageAttachments = item.attachments?.filter(isVisualFile);

    return imageAttachments?.length ? <GalleryPreview images={imageAttachments.map((img) => img.url)} /> : null;
  }
}

export const VisualAttachments = Renderer;
