const SESSION_STORAGE_PREFIX = (process.env.REACT_APP_CONFIG || '').toUpperCase().replace(/-/g, '_');

function getKey(key) {
  return `${SESSION_STORAGE_PREFIX}_${key}`;
}

export function getSession(key) {
  return sessionStorage.getItem(getKey(key));
}

export function setSession(key, value) {
  sessionStorage.setItem(getKey(key), value);
}
