import { type CalendarSyncDataObject } from './service';

export type CalendarSyncProvider = 'azure' | 'google';

export type CalendarSync = {
  provider: CalendarSyncProvider;
};

export const createCalendarySync = (data: CalendarSyncDataObject) => ({
  provider: data.provider,
});
