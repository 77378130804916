import { apiClient, routes } from 'common/services';
import { type RequestResetPassword } from '../models';

export const requestResetPassword = async ({ email, domain }: RequestResetPassword) => {
  const data = {
    user: { email },
    domain,
  };

  return apiClient.post(routes.user.password, data, { skipAuth: true });
};
