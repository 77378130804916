import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { confirmTimezone } from '../services/confirmTimezone';

type ConfirmTimezoneVariables = {
  userId: string;
  timeZone: string;
};

export const useConfirmTimezone = () => {
  return useMutation<void, ApiClientResponseError<DefaultResponseErrorDataObject>, ConfirmTimezoneVariables>({
    async mutationFn({ userId, timeZone }) {
      await confirmTimezone(userId, timeZone);
    },
  });
};
