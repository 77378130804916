import * as React from 'react';
import { Loading } from 'ui';
import { Loader } from '../loader';

export class InViewport extends React.Component {
  ref = React.createRef();

  componentDidMount() {
    this.observer = new IntersectionObserver(this.observe, {});
    if (this.ref.current) {
      this.observer.observe(this.ref.current);
    }
  }

  componentWillUnmount() {
    if (this.ref.current) {
      this.observer.unobserve(this.ref.current);
    }
    this.observer.disconnect();
  }

  observe = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && this.props.onEnter) {
        this.props.onEnter();
      }
    });
  };

  render() {
    return <div ref={this.ref}>{this.props.useNewLoader ? <Loading /> : <Loader size={this.props.size} />}</div>;
  }
}
