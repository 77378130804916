import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const CategoriesService = {
  find: (id) => http.get(`/categories/${id}`),
  list: (params) => http.get(`/categories`, params),
  organizations: (ids) => http.get(`/categories/organizations?ids=${ids.join(',')}`),
  communities: (id) => http.get(`/categories/${id}/communities`),
};
