import { type ReduxUser } from 'models';
import { AxiosErrorHandler } from 'common/error-handlers';
import { Loading } from 'ui';
import { SharedEventContent } from './components';
import { useEventPublicQuery, useInvitationTokenQuery } from './queries';

type P = {
  eventId: string;
  invitationToken: string;
  viewer?: ReduxUser;
};

export const SharedEvent = ({ eventId, invitationToken, viewer }: P) => {
  const {
    isLoading: isLoadingEvent,
    isFetching: isFetchingEvent,
    data: eventData,
    error: eventError,
  } = useEventPublicQuery({ eventId, invitationToken }, { useErrorBoundary: false });

  const {
    isLoading: isLoadingInvitationToken,
    isFetching: isFetchingInvitationToken,
    data: invitationTokenData,
    error: tokenError,
  } = useInvitationTokenQuery({ invitationToken }, { useErrorBoundary: false });

  return (
    <AxiosErrorHandler error={eventError ?? tokenError} actions={false}>
      {(isLoadingEvent && isFetchingEvent) ||
      (isLoadingInvitationToken && isFetchingInvitationToken) ||
      !eventData ||
      !invitationTokenData ? (
        <Loading />
      ) : (
        <SharedEventContent invitationToken={invitationTokenData} eventContentObject={eventData} viewer={viewer} />
      )}
    </AxiosErrorHandler>
  );
};
