import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../../common/services';

import { ID } from '../../../common/types';
import { ChatroomDataObject } from '../../data-objects';
import { ParticipantBase } from '../../../types';

export type AddMemberChatroomRequest = {
  id: ID;
  participant: ParticipantBase;
};

type AddMemberChatroomDataObject = ChatroomDataObject;

export const addMemberToChatroom = (chatroom: ID, data: AddMemberChatroomRequest) =>
  nodeApiClient.post<AddMemberChatroomDataObject, AxiosResponse<AddMemberChatroomDataObject>>(
    routes.chatrooms.members.add(chatroom),
    data,
  );
