import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type CampaignSlimDataObject } from 'models/service';

type CampaignsRequest = {
  cursor?: string;
  limit?: number;
  offset?: number;
  scope: 'channels';
  organization_id?: string;
  featured?: boolean;
};

export const getCampaigns = async (params: CampaignsRequest) =>
  apiClient.get<CampaignSlimDataObject[], ApiClientResponse<CampaignSlimDataObject[]>>(routes.campaigns.find, {
    params,
  });
