import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const CampaignsService = {
  get: (params) => http.get('/campaigns', params),
  getDonors: (params) => http.get('/campaigns/users', params),
  find: (id) => http.get(`/campaigns/${id}`),
  create: (payload) => http.post(`/campaigns`, { campaign: payload }),
  update: (id, payload) => http.put(`/campaigns/${id}`, payload),
  delete: (id) => http.delete(`/campaigns/${id}`),
  finish: (id) => http.post(`/campaigns/${id}/finish`),
};
