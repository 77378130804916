import { atom } from 'recoil';
import { ID } from '../types';

import {
  defaultSocketConnection,
  defaultUsers,
  defaultLegacyActivePracticeId,
} from './defaults';
import { Participant } from '../../models';

export const legacyActivePracticeId = atom<ID | null>({
  key: 'LegacyActivePracticeId',
  default: defaultLegacyActivePracticeId,
  // eslint-disable-next-line camelcase
  effects_UNSTABLE: [
    // TODO: update this when authorization will be in react
    ({ onSet, setSelf, trigger }) => {
      if (trigger === 'get' && localStorage.getItem('LegacyActivePracticeId')) {
        setSelf(localStorage.getItem('LegacyActivePracticeId'));
      }

      onSet((newID) => {
        if (newID) {
          localStorage.setItem('LegacyActivePracticeId', newID);
        } else {
          localStorage.removeItem('LegacyActivePracticeId');
        }
      });
    },
  ],
});

export const users = atom<Record<string,Participant>>({
  key: 'Users',
  default: defaultUsers,
});

export const socketConnection = atom<SocketIOClient.Socket>({
  key: 'SocketConnection',
  default: defaultSocketConnection,
  dangerouslyAllowMutability: true,
});
