import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { ThemeConsumer } from '../../atoms/theme';
import { Text } from '../../atoms/text';
import { CreateFolderModal } from './CreateFolderModal';
import { AssetRow } from './AssetRow';

export class Renderer extends React.Component {
  state = {
    assets: [],
    tree: [],
    currentId: null,
    createFolderModalIsOpen: false,
    sort: 'name',
    order: 'asc',
  };

  async componentDidMount() {
    this.fetchAssets();
  }

  fetchAssets = async (folderId) => {
    const response = await this.props.onRequestListing(folderId || this.state.currentId, {
      sort: this.state.sort,
      order: this.state.order,
    });
    if (response.ok) {
      this.setState({ assets: response.data });
    }
  };

  async deleteAsset(payload) {
    if (window.confirm(this.props.t('Are you sure?'))) {
      const response = await this.props.onDeleteAsset(payload.id);
      if (response.ok) {
        this.setState(({ assets }) => {
          return {
            assets: assets.filter((asset) => asset.id !== payload.id),
          };
        });
      }
    }
  }

  async renameAsset(asset) {
    const response = await this.props.onUpdateAsset(asset.id, { name: asset.name });
    if (response.ok) {
      this.setState(({ assets }) => {
        return {
          assets: assets.map((asset) => {
            if (asset.id === response.data.id) {
              return response.data;
            }
            return asset;
          }),
        };
      });
    }
  }

  onDropdownSelect = async (action, asset) => {
    switch (action) {
      case 'edit':
        break;
      case 'rename':
        this.renameAsset(asset);
        break;
      case 'move':
        break;
      case 'share':
        break;
      case 'delete':
        this.deleteAsset(asset);
        break;
      default:
        console.log(action);
    }
  };

  onChangeFolder = async (folderId) => {
    const tree = [...this.state.tree];
    folderId ? tree.push(folderId) : tree.splice(-1, 1);
    const response = await this.props.onRequestListing(tree[tree.length - 1]);

    if (response.ok) {
      this.setState({ assets: response.data, tree: tree, currentId: folderId || tree[tree.length - 1] });
    }
  };

  onRequestClose = () => {
    this.setState({ createFolderModalIsOpen: false });
  };

  onCreateFolderSuccess = () => {
    this.fetchAssets();
    this.setState({ createFolderModalIsOpen: false });
  };

  renderCreateFolderModal = () => {
    return (
      <CreateFolderModal
        isOpen={this.state.createFolderModalIsOpen}
        onRequestClose={this.onRequestClose}
        onCreateFolder={this.props.onCreateFolder}
        onCreateFolderSuccess={this.onCreateFolderSuccess}
        parentId={this.state.currentId}
      />
    );
  };

  onCreateFile = async (e) => {
    const files = Array.isArray(e) ? e : e.target.files;
    const values = Array.from(files).map((file) => ({
      file: file,
      parentId: this.state.currentId,
    }));

    await this.props.onCreateFile(values);
    this.fetchAssets();
  };

  setSorting = (sort) => {
    if (sort === this.state.sort) {
      let order = null;
      if (this.state.order === 'asc') {
        order = 'desc';
      } else {
        order = 'asc';
      }
      this.setState({ order: order }, this.fetchAssets);
    } else {
      this.setState({ sort: sort, order: 'desc' }, this.fetchAssets);
    }
  };

  renderOrdering(sort) {
    return (
      <div style={{ display: 'inline-block', width: 10, height: 1 }}>
        {sort === this.state.sort ? (
          <img src={`/assets/sort_${this.state.order}ending.svg`} alt={`${this.state.order}ending`} />
        ) : null}
      </div>
    );
  }

  renderTableHeader() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="asset-manager-sort" style={{ color: theme.color.brand }}>
            <div
              onClick={() => this.setSorting('name')}
              className={`asset-manager-sort__name ${
                this.state.sort === 'name' ? 'asset-manager-sort__name--active' : ''
              }`}
            >
              <div style={{ paddingLeft: 25 }}>
                <Trans>Name</Trans> {this.renderOrdering('name')}
              </div>
            </div>
            <div
              onClick={() => this.setSorting('type')}
              className={`asset-manager-sort__type ${
                this.state.sort === 'type' ? 'asset-manager-sort__type--active' : ''
              }`}
            >
              <Trans>Type</Trans> {this.renderOrdering('type')}
            </div>
            <div
              onClick={() => this.setSorting('updated_at')}
              className={`asset-manager-sort__updated ${
                this.state.sort === 'updated_at' ? 'asset-manager-sort__updated--active' : ''
              }`}
            >
              <Trans>Date Modified</Trans> {this.renderOrdering('updated_at')}
            </div>
            <div
              onClick={() => this.setSorting('size')}
              className={`asset-manager-sort__size ${
                this.state.sort === 'size' ? 'asset-manager-sort__size--active' : ''
              }`}
            >
              <Trans>Size</Trans> {this.renderOrdering('size')}
            </div>
          </div>
        )}
      </ThemeConsumer>
    );
  }

  render() {
    const { assets, tree, currentId } = this.state;
    return (
      <React.Fragment>
        {this.props.renderHeader
          ? this.props.renderHeader({
              tree: tree,
              currentId: currentId,
              createFolder: () => this.setState({ createFolderModalIsOpen: true }),
              changeFolder: () => this.onChangeFolder(),
              createFile: (e) => this.onCreateFile(e),
            })
          : null}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {this.renderTableHeader()}
          {assets.length > 0 ? (
            assets.map((asset) => (
              <AssetRow
                viewer={this.props.viewer}
                key={asset.id}
                asset={asset}
                permissions={this.props.permissions}
                onDropdownSelect={this.onDropdownSelect}
                onClickFolder={asset.type === 'folder' ? () => this.onChangeFolder(asset.id) : undefined}
              />
            ))
          ) : (
            <Text style={{ textAlign: 'center' }}>
              <Trans>No files</Trans>
            </Text>
          )}
        </div>
        {this.renderCreateFolderModal()}
      </React.Fragment>
    );
  }
}

export const AssetManager = withTranslation()(Renderer);
