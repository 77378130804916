import { Layout } from 'antd';
import cn from 'classnames';
import { type ReactNode } from 'react';

import { Row, Col } from 'ui';

const { Header, Content, Footer } = Layout;

type P = {
  header: ReactNode;
  contentLeft?: ReactNode;
  contentRight: ReactNode;
  footer: ReactNode;
  className?: string;
};

const LandingLayout = ({ header, contentLeft, contentRight, footer, className }: P) => {
  const rightSpan = contentLeft ? 12 : 24;

  return (
    <Layout className={cn('landing-layout', className)}>
      <Header className="landing-layout__header">{header}</Header>
      <Content className="landing-layout__content">
        <Row item={{ className: 'landing-layout__content__wrapper' }}>
          {contentLeft ? (
            <Col item={{ md: { span: 12 }, className: 'landing-layout__content__left' }}>{contentLeft}</Col>
          ) : null}
          <Col
            item={{
              xs: { span: 24 },
              md: { span: rightSpan, offset: 24 - rightSpan },
              className: 'landing-layout__content__right',
            }}
          >
            <div className="landing-layout__content__right-wrapper">{contentRight}</div>
          </Col>
        </Row>
      </Content>
      <Footer className="landing-layout__footer">{footer}</Footer>
    </Layout>
  );
};

export default LandingLayout;
