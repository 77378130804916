import * as React from 'react';

export function ErrorBox(props) {
  return (
    <div
      id={props.id ? 'error_' + props.id : undefined}
      style={{
        padding: 10,
        borderRadius: '4px',
        color: '#ef5d13',
        background: '#ffffff',
        border: '1px solid #ef5d13',
        textAlign: 'center',
      }}
    >
      {props.children}
    </div>
  );
}
