import * as React from 'react';
import { Field } from 'formik';

import { Textarea } from './base-atoms/TextualInput';
import { FieldError, isInvalid } from './FieldError';

export class TextAreaField extends React.Component {
  render() {
    return (
      <Field name={this.props.name}>
        {(fieldProps) => {
          const { label, ...htmlProps } = this.props;
          const invalid = isInvalid(this.props.name, fieldProps.form);
          return (
            <label className="text-area-field">
              <div className={`text-area-field__label ${this.props.disabled ? 'disabled' : ''}`}>{label}</div>
              <div
                className="text-area-field__area"
                style={{
                  boxShadow: `0 1px 0 0 ${invalid ? 'red' : '#c8c7cc'}`,
                }}
              >
                <Textarea {...htmlProps} {...fieldProps.field} />
              </div>
              <FieldError name={this.props.name} />
            </label>
          );
        }}
      </Field>
    );
  }
}
