import { Modal as ModalA } from 'antd';
import { ComponentProps, FC } from 'react';

type P = ComponentProps<typeof ModalA>;

const Modal: FC<P> = ({ centered = true, ...props }: P) => {
  return <ModalA {...props} centered={centered} />;
};

export default Modal;
