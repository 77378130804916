import { type ReduxUser, type ContentObjectRedux } from 'models';
import { canRepostContentObject } from './canRepostContentObject';

export const canShareContentObjectRedux = ({
  viewer,
  contentObject,
}: {
  viewer: ReduxUser;
  contentObject: ContentObjectRedux;
}) => canRepostContentObject({ viewer, contentObject });
