import { type Community, type ReduxUser } from 'models';
import { CommunityFeed as Feed } from 'old/pages/page-community-feed';
import { type EventFeedbackOptions } from 'features/events';
import { ContentWrapper } from 'ui';
import { Administrators, EventsListCard } from '../components';
import QuickActionsCard from './QuickActionsCard';

type P = {
  community: Community;
  viewer: ReduxUser;
  feedbackOptions: EventFeedbackOptions;
};

const CommunityFeed = ({ community, viewer, feedbackOptions }: P) => (
  <ContentWrapper
    aside={
      <>
        <QuickActionsCard community={community} viewer={viewer} />
        <EventsListCard community={community} viewer={viewer} feedbackOptions={feedbackOptions} />
        <Administrators community={community} viewer={viewer} />
      </>
    }
  >
    <Feed communityId={community.id} />
  </ContentWrapper>
);

export default CommunityFeed;
