import { Component } from 'react';
import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { enterBigMarkerConference } from '../../utils/big-marker';
import { Text } from '../../atoms/text';
import { Loader } from '../../atoms/loader';
import { can } from '../../utils/permissions';

class PageCallsRenderer extends Component {
  state = {
    isLoading: true,
    errorMessage: null,
  };

  componentDidMount = async () => {
    await this.props.loadData();
    this.setState({ isLoading: false });
  };

  redirectToBM = async () => {
    const email = new URLSearchParams(this.props.location.search).get('email');
    const eventId = this.props.match.params.id;

    const exitUrl =
      this.props.user && this.props.object
        ? can(this.props.object.event, 'Event', 'isEmailInvitee')
          ? `${window.location.origin}/conference-ended?url=${btoa(window.location.origin)}`
          : `${window.location.origin}/events/${eventId}/about?feedback=true&eventId=${eventId}`
        : window.location.origin;

    const response = await enterBigMarkerConference({
      userIds: this.props.user ? [this.props.user.id] : [],
      email: email,
      eventId: eventId,
      exitUrl,
    });

    if (response) {
      this.setState({ errorMessage: this.props.t('BigMarker event is not ready yet. Try again later.') });
    }
  };

  render() {
    if (this.state.isLoading || this.props.isLoading) {
      return <Loader />;
    }

    if (this.state.errorMessage) {
      return (
        <Text size={16} component="div" style={{ textAlign: 'center', paddingTop: 30 }}>
          {this.state.errorMessage}
        </Text>
      );
    }

    this.redirectToBM().catch(console.error);
    return null;
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  object: select.events.find(state, new URLSearchParams(props.location.search).get('event_id')),
  isLoading: state.loading.effects.events.find,
});

const mapDispatch = (dispatch, props) => ({
  loadData: () => dispatch.events.findAsync(new URLSearchParams(props.location.search).get('event_id')),
});

export const PageCalls = withTranslation()(connect(mapState, mapDispatch)(PageCallsRenderer));
