import { Component } from 'react';
import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { dispatch } from 'old/store';

import { AppLayout as NewAppLayout } from 'ui';

class Renderer extends Component {
  state = {};

  componentDidMount = async () => {
    await dispatch.session.fetchUser();
    this.props.getBadges();
  };

  render() {
    const { right, center, user, nodeUser, badges, unreadMessages, logOut, history, location } =
      this.props;

    return (
      <NewAppLayout
        children={center}
        aside={right}
        user={user}
        nodeUser={nodeUser?.result}
        badges={badges}
        unreadMessages={unreadMessages}
        onLogOut={async () => {
          const { pathname } = location;
          // Hotfix so if user logs out from page that has public view and redirects to login page doesn't get Faild to load data message
          // It's important that history.push is called before logOut
          history.push({ pathname: '/login', state: { referrer: pathname } });
          await logOut();
        }}
      />
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
  nodeUser: select.session.nodeAPIUser(state),
  badges: select.notifications.badges(state),
  unreadMessages: select.session.unreadMessages(state),
});

const mapDispatch = (dispatch) => ({
  logOut: dispatch.session.logOut,
  getBadges: dispatch.notifications.getBadgesAsync,
});

export const AppLayout = connect(mapState, mapDispatch)(withRouter(Renderer));
