import { Trans } from 'react-i18next';
import { getAppConfig } from '../../atoms/app-config-gate';

export const notifications = [
  { value: 'newsfeed', label: <Trans>Newsfeed</Trans>, id: 'newsfeed' },
  { value: 'events', label: <Trans>Conferences and Events</Trans>, id: 'events' },
  { value: 'program', label: <Trans>Program</Trans>, id: 'program' },
  { value: 'donations', label: <Trans>Donations</Trans>, id: 'donations' },
].filter((item) => {
  return !(!getAppConfig().feature_flags.campaigns && item.value === 'donations');
});

export function mapperToArray(obj) {
  return Object.keys(obj).map((key) => (obj[key].value === true ? key : null));
}

export const mapperToObject = (settings, arr) => {
  return settings.reduce((acc, curr) => {
    acc[curr.value] = arr.includes(curr.value);
    return acc;
  }, {});
};

function getNotificationValue(notifications, settings, values) {
  return notifications.map((notification) => {
    const disabling = settings[notification.value];
    return {
      ...notification,
      disabled: !disabling.change_allowed,
      value: values.includes(notification.value) ? notification.value : '',
      data: notification.value,
    };
  });
}

const transformNotificationIds = (notifications, key) => {
  return notifications.map((notification) => ({
    ...notification,
    id: `${key}-${notification.id}`,
  }));
};

export function buildPushNotificationsValues(settings, values) {
  const transformedPushNotifications = transformNotificationIds(notifications, 'push');

  if (Object.keys(settings).length === 0) {
    return transformedPushNotifications;
  }
  return getNotificationValue(transformedPushNotifications, settings, values);
}

export function buildEmailNotificationsValues(settings, values) {
  const transformedEmailNotifications = transformNotificationIds(notifications, 'email');

  if (Object.keys(settings).length === 0) {
    return transformedEmailNotifications;
  }
  return getNotificationValue(transformedEmailNotifications, settings, values);
}
