import { type ReactNode } from 'react';
import variables from 'common/styles/variables.json';
import { Card, Col, Empty, Row, Skeleton, Text } from 'ui';
import { SliderControls } from './SliderControls';

type P = {
  items?: ReactNode[];
  title?: string;
  clickActions?: { onNext?: () => void; onPrevious?: () => void; onSeeAll?: () => void };
  queryData: {
    limit: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  isLoading?: boolean;
  emptyState?: ReactNode;
  hideArrows?: boolean;
  view?: 'detail' | 'preview';
  description?: string;
};

const Slider = ({ items, title, clickActions, queryData, isLoading, emptyState, hideArrows, view, description }: P) => {
  if (view === 'detail' && items && items.length === 0 && !queryData.hasPreviousPage) {
    return null;
  }

  return (
    <Card className="slider">
      <Row>
        <Col item={{ span: 24 }}>
          <SliderControls
            title={title}
            clickActions={clickActions}
            queryData={queryData}
            isLoading={isLoading}
            hideArrows={hideArrows}
          />
        </Col>
        {description ? (
          <Col item={{ span: 24, md: 12, className: 'slider__description' }}>
            <Text>{description}</Text>
          </Col>
        ) : null}
      </Row>

      {isLoading ? (
        <Skeleton active paragraph={{ rows: 6 }} />
      ) : (
        <>
          {items && items.length > 0 ? (
            <Row item={{ gutter: [variables.spaceMd.value, 0] }}>
              {items.map((sliderItem) => (
                // eslint-disable-next-line react/jsx-key -- TODO add key
                <Col item={{ span: 24 / queryData.limit }}>{sliderItem}</Col>
              ))}
            </Row>
          ) : (
            emptyState ?? <Empty />
          )}
        </>
      )}
    </Card>
  );
};

export default Slider;
