import { selector } from 'recoil';
import { isPracticeEnabled } from '../../utils';
import { ID } from '../types';
// TODO this is really wrong, we need some new selector what will be set from outside instead
import { legacyActivePracticeId } from './atoms';
import { getPractice } from './queries';

export const activePracticeIdState = selector<ID | null>({
  key: 'ActivePracticeStateID',
  get: async ({ get }) => {
    if (isPracticeEnabled()) {
      const legacyPracticeId = get(legacyActivePracticeId);
      if (legacyPracticeId) {
        const practiceData = await getPractice({ legacyId: legacyPracticeId });

        if (!practiceData.error && practiceData.result.data.length > 0) {
          return practiceData.result.data[0]._id;
        }
      }

      return legacyPracticeId;
    }

    return null;
  },
});
