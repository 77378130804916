import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type OnboardingTourStepDataObject } from '../models/service';

type DefaultOnboardingStepRequest = {
  status: 'completed';
};

type CalendarOnboardingStepRequest = {
  status: 'completed';
  meta: {
    selected_calendar_options: string[];
  };
};

type OnboardingStepRequest = CalendarOnboardingStepRequest | DefaultOnboardingStepRequest;

export const updateOnboardingStep = async (id: string, data: OnboardingStepRequest) =>
  apiClient.patch<OnboardingTourStepDataObject, ApiClientResponse<OnboardingTourStepDataObject>>(
    routes.onboardingSteps.update(id),
    data,
  );
