import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../common/services';
import { MessagePayload } from '../../../../models';
import { Image, ImagePreview, Tooltip } from '../../../../ui';
import { AttachmentGrayIcon, AttachmentWhiteIcon } from '../../../../ui/Icons';

type P = {
  payload: MessagePayload[];
  isAuthor: boolean;
  messageDate: Date;
};

const MessageAttachments: FC<P> = ({ payload, isAuthor, messageDate }) => {
  const { t } = useTranslation(['translationChat']);

  const imageAttachments = payload.filter((payloadItem) => {
    if (payloadItem.meta.mimeType) {
      return payloadItem.meta.mimeType.startsWith('image');
    }

    return null;
  });
  const otherTypeAttachments = payload.filter((payloadItem) => {
    if (payloadItem.meta.mimeType) {
      return !payloadItem.meta.mimeType.startsWith('image');
    }

    return undefined;
  });

  // TODO We need support for thumbnail for big picture when only 1 photo is sent in chat. Currently only 200*200 is available

  const returnUrlForImage = (mediaItem: MessagePayload, thumbnail = false) => {
    if (mediaItem.ref) {
      if (thumbnail) {
        return new URL(routes.chatrooms.media.alt(mediaItem.ref, '200'), process.env.REACT_APP_MEDIA_MODULE_URL).href;
      }

      return new URL(routes.chatrooms.media.getFile(mediaItem.ref), process.env.REACT_APP_MEDIA_MODULE_URL).href;
    }

    return undefined;
  };

  const returnPropertiesForImage = (imagePayload: MessagePayload) => {
    const imageDefaultProperties = {
      key: imagePayload.ref,
      src: returnUrlForImage(imagePayload, true),
      preview: {
        src: returnUrlForImage(imagePayload),
        maskClassName: 'chat-message-attachments__images__mask',
      },
    };
    return imageAttachments.length === 1
      ? {
          ...imageDefaultProperties,
          height: 350,
        }
      : {
          ...imageDefaultProperties,
          height: 200,
          width: 200,
        };
  };

  // TODO edit and fix media payload - name is used from meta which is not correct
  return (
    <div className={cn('chat-message-attachments', { 'chat-message-attachments--sender': !isAuthor })}>
      {imageAttachments.length > 0 && (
        <div className="chat-message-attachments__images">
          <ImagePreview>
            <Tooltip
              title={t('chat.chatroomDetail.messageTooltipLabel', { date: messageDate })}
              placement={isAuthor ? 'right' : 'left'}
              className="chat-message-attachments__images__tooltip-wrapper"
            >
              {imageAttachments.map((image) => (
                <Image
                  className="chat-message-attachments__images__item"
                  {...returnPropertiesForImage(image)}
                  key={image.ref}
                />
              ))}
            </Tooltip>
          </ImagePreview>
        </div>
      )}
      {otherTypeAttachments.length > 0 && (
        <div className="chat-message-attachments__files">
          {otherTypeAttachments.map((file) => {
            if (file.ref) {
              return (
                <Tooltip
                  title={t('chat.chatroomDetail.messageTooltipLabel', { date: messageDate })}
                  placement={isAuthor ? 'right' : 'left'}
                  className="chat-message-attachments__files__tooltip-wrapper"
                  key={file.ref}
                >
                  <a
                    href={
                      new URL(routes.chatrooms.media.getFile(file.ref), process.env.REACT_APP_MEDIA_MODULE_URL).href
                    }
                    className={'chat-message-attachments__files__download p--large'}
                  >
                    {isAuthor ? <AttachmentWhiteIcon /> : <AttachmentGrayIcon />}
                    {file.meta.name}
                  </a>
                </Tooltip>
              );
            }

            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default MessageAttachments;
