import * as React from 'react';
import { Trans } from 'react-i18next';
import { Field } from 'formik';

import { ThemeConsumer } from '../theme';
import { Input } from './base-atoms/Input';
import { FieldError, isInvalid } from './FieldError';
import { Icon } from '../icon';

export class LabelTextField extends React.Component {
  static defaultProps = {
    secret: false,
    type: 'text',
  };

  state = {
    revealSecret: false,
  };

  renderRevealButton = () => (
    <div
      className="label-text-field__reveal-button"
      onClick={() => {
        this.setState({ revealSecret: !this.state.revealSecret });
      }}
    >
      {this.state.revealSecret ? <Trans>Hide</Trans> : <Trans>Show</Trans>}
    </div>
  );

  render() {
    return (
      <Field name={this.props.name} className="label-text-field">
        {(fieldProps) => {
          const { label, secret, ...htmlProps } = this.props;
          const invalid = isInvalid(this.props.name, fieldProps.form);
          return (
            <label className="label-text-field__label-wrapper">
              <span
                className={`label-text-field__label ${label && !!fieldProps.field.value ? 'visible' : ''} ${
                  this.props.disabled ? 'disabled' : ''
                }`}
              >
                {label}
              </span>
              <div className={`label-text-field__input ${invalid ? 'label-text-field__input--invalid' : ''}`}>
                <Input
                  {...htmlProps}
                  {...fieldProps.field}
                  type={this.state.revealSecret ? 'text' : this.props.secret ? 'password' : this.props.type}
                />
                {this.props.secret ? this.renderRevealButton() : null}
                {fieldProps.field.value && !this.props.disabled ? (
                  <ThemeConsumer>
                    {(theme) => (
                      <span
                        className="label-text-field__close"
                        onClick={() => fieldProps.form.setFieldValue(this.props.name, '')}
                      >
                        <Icon name={'close'} size={10} color={theme.color.lightGray} />
                      </span>
                    )}
                  </ThemeConsumer>
                ) : null}
              </div>
              <FieldError name={this.props.name} />
            </label>
          );
        }}
      </Field>
    );
  }
}
