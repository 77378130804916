import { Avatar, Space } from 'antd';
import type React from 'react';

export type Title = {
  id?: string;
  url: string;
  title?: string;
  email?: string;
};

type P = {
  titles: Title[];
};

const AvatarList: React.FC<P> = ({ titles }) => (
  <Space size={16}>
    <Space size={-20}>
      {titles.slice(0, 5).map((title: Title) => (
        <Avatar key={`avatar_list_${title.id}`} size="large" src={title.url}>
          {title.title?.slice(0, 2).toUpperCase()}
        </Avatar>
      ))}
    </Space>

    {titles.length > 5 && <span>+ {titles.length - 5}</span>}
  </Space>
);

export default AvatarList;
