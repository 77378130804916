import { type EventType } from 'models';
import { useTranslation } from 'react-i18next';

import { Check, List } from 'ui';

import { Card } from './Card';

type P = {
  type: EventType;
  title: string;
  description: string[];
  onChange: (eventType: EventType) => void;
};

const CardOption = ({ type, title, description, onChange }: P) => {
  const { t } = useTranslation();

  return (
    <Card
      value={type}
      id={`event_card-${type}`}
      onClick={() => {
        onChange(type);
      }}
      title={t(title)}
      content={
        <List
          size="small"
          split={false}
          dataSource={description.map((desc) => t(desc))}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta avatar={<Check style={{ fontSize: '14px' }} />} title={item} />
            </List.Item>
          )}
        />
      }
    />
  );
};

export default CardOption;
