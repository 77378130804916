import { type CommunityListItem } from 'models';
import { useTranslation } from 'react-i18next';
import { SelectInput, type SelectInputProperties, type SelectItemProperties } from 'ui';

type P = {
  communities: CommunityListItem[];
  item?: SelectItemProperties;
  input?: Omit<SelectInputProperties<CommunityListItem>, 'getOptionLabel' | 'getOptionValue' | 'options'>;
};

const EventCommunity = ({ communities, item, input }: P) => {
  const { t } = useTranslation();

  return (
    <SelectInput
      item={{
        name: 'communityId',
        'data-test-id': 'select_communities',
        ...item,
      }}
      input={{
        showSearch: true,
        placeholder: t('Choose community...'),
        options: communities,
        getOptionLabel: (option) => option.name,
        getOptionValue: (option) => option.id,
        ...input,
      }}
    />
  );
};

export default EventCommunity;
