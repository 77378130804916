import { Profile } from 'models';
import { useQuery } from 'query';
import { getProfile } from '../services';
import { getProfileQueryKey } from '.';

// This is forcing refetch on mount of Consents and do not use old `Profile`
const defaultOptions = { staleTime: 0, chageTime: 0 } as const;

export const useProfileQuery = (
  options: { staleTime?: number; cacheTime?: number; useErrorBoundary?: boolean } = defaultOptions,
) =>
  useQuery<Profile>(
    getProfileQueryKey(),
    async () => {
      const response = await getProfile();

      return new Profile(response.data);
    },
    options,
  );
