import * as React from 'react';
import { Trans } from 'react-i18next';
import { select } from '@rematch/select';
import { connect } from 'react-redux';

import { CategoriesLayout, NextButton } from '../../layouts/CategoriesLayout';
import { CategoriesCardList, CategoriesFilters } from '../../atoms/categories-card-list';

export class Renderer extends React.Component {
  state = {
    selectedCategories: [],
  };

  async componentDidMount() {
    if (this.props.user.joined_communities.length > 0) {
      this.props.history.push('/');
    }
  }

  goToNextPage = () => {
    this.props.history.push(`/join-first-community/${this.state.selectedCategories.join(',')}`);
  };

  setSelectedCategories = (categories) => {
    this.setState({ selectedCategories: categories });
  };

  render() {
    return (
      <CategoriesLayout
        buttons={
          <NextButton
            title={<Trans>Next</Trans>}
            disabled={this.state.selectedCategories.length === 0}
            onClick={this.goToNextPage}
          />
        }
      >
        <div style={{ maxWidth: 900, marginRight: 'auto', marginLeft: 'auto' }}>
          <CategoriesFilters
            title={<Trans>Browse Organizations</Trans>}
            subtitle={<Trans>to Get Started</Trans>}
            value=""
          />
          <CategoriesCardList
            selectedCategories={this.state.selectedCategories}
            searchValue=""
            onSelectionChange={this.setSelectedCategories}
          />
        </div>
      </CategoriesLayout>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
});

export const PageCommunityCategories = connect(mapState)(Renderer);
