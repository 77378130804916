import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Form, Formik } from 'formik';

import { ProfileSideNav } from './ProfileSideNav';
import { UserZoneLayout, TABS } from '../../layouts/UserZoneLayout';
import { validator } from '../../utils/validator';
import { Button } from '../../atoms/button';
import { SelectField } from '../../atoms/form';
import { ThemeConsumer } from '../../atoms/theme';

export class Renderer extends React.Component {
  languages = [
    {
      name: this.props.t('English'),
      code: 'en',
    },
    {
      name: this.props.t('Spanish'),
      code: 'es',
    },
  ];

  render() {
    return (
      <UserZoneLayout navigation={<ProfileSideNav user={this.props.user} />} contentId={TABS.profile}>
        <Formik
          initialValues={{ language: localStorage.getItem('lang') }}
          onSubmit={async (values, formik) => {
            formik.setSubmitting(true);
            i18next.changeLanguage(values.language);
            formik.setSubmitting(false);
          }}
          validate={(values) =>
            validator.validateAll(values, {
              language: 'required',
            })
          }
        >
          {(formik) => {
            return (
              <ThemeConsumer>
                {(theme) => (
                  <Form>
                    <div style={{ padding: '0 33px' }}>
                      <SelectField
                        name="language"
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        label={<Trans>Language</Trans>}
                        options={this.languages.map((option) => ({
                          label: option.name,
                          value: option.code,
                        }))}
                        placeholder={this.props.t('Select language')}
                        id="language"
                      />
                      <div style={{ width: 200, marginTop: 20 }}>
                        <Button
                          block
                          type="submit"
                          color={theme.color.brand}
                          textColor="white"
                          size="lg"
                          title={this.props.t('Apply')}
                          disabled={formik.isSubmitting}
                          id="btn-apply"
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </ThemeConsumer>
            );
          }}
        </Formik>
      </UserZoneLayout>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const UserLanguage = withTranslation()(connect(mapState)(Renderer));
