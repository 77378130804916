import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { debounce } from 'throttle-debounce';
import { AutocompleteService } from '../../../services/AutocompleteService';
import { Autocomplete } from '../../autocomplete';
import { TagsBox } from '../../tags';
import { can } from '../../../utils/permissions';

export class Renderer extends React.Component {
  itemToString = (item) => {
    if (item === null) {
      return '';
    } else {
      return item.organization ? `${item.name} (${item.organization.name})` : item.name;
    }
  };

  getDataForValue = async (value) => {
    let response;
    if (this.props.communities) {
      const regex = new RegExp(value ? value : '', 'gi');
      response = this.props.communities.filter((community) => community.name.match(regex));
    } else {
      response = await AutocompleteService.getCommunities({
        scope: this.props.scope,
        allow_posts: this.props.allowPosts,
        search: value,
      });
      response = response.data;
    }
    const selectionIds = this.props.selection.map((item) => item.id);
    return response.filter(
      (item) => !selectionIds.includes(item.id) && (!this.props.onlyPostable || can(item, 'ContentObject', 'post')),
    );
  };

  generateDataForValue = () => {
    if (this.props.communities) {
      return this.getDataForValue;
    }

    return debounce(550, async (value, cb) => {
      const data = await this.getDataForValue(value);
      return cb(data);
    });
  };

  handleChange = (item) => {
    if (Array.isArray(item)) {
      this.props.onChange(item);
    } else if (item !== null) {
      this.props.onChange(this.props.selection.concat(item));
    }
  };

  render() {
    return (
      <Autocomplete
        disabled={this.props.disabled}
        clearOnSelect
        currentSelectedData={this.props.selection}
        dataForValue={this.generateDataForValue()}
        itemToString={this.itemToString}
        getRowId={(item) => item.id}
        placeholder={this.props.placeholder}
        onSelect={this.handleChange}
        renderInput={(ref, inputProps) => (
          <div ref={ref}>
            <TagsBox
              disabled={this.props.disabled}
              inputProps={inputProps}
              placeholder={this.props.t('Post in...')}
              selection={this.props.selection}
              itemToString={this.itemToString}
              onChange={this.handleChange}
              data-test-id={this.props?.['data-test-id']}
              id={this.props?.id}
            />
          </div>
        )}
      />
    );
  }
}

export const CommunitiesInput = withTranslation()(Renderer);
