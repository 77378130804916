import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';

import { createGroups, isOpenToJoin, isOpenToResponse } from '../../utils/events';
import { enterBigMarkerConference } from '../../utils/big-marker';
import { EventDateList } from './EventDateList';
import { InViewport } from '../../atoms/in-viewport';
import { Loader } from '../../atoms/loader';
import { showAlert } from '../../atoms/alert';
import { getFeedKey } from '../../models/feed';
import { can } from '../../utils/permissions';
import { Result, Button, Empty, Text } from 'ui';
import { EventFeedback } from 'features/events';

export class Renderer extends React.Component {
  static defaultProps = {
    scope: 'Upcoming',
    limit: 3,
    loadMore: true,
  };

  state = {
    hasMoreEvents: true,
    failedToLoad: false,
  };

  componentWillMount() {
    this.props.resetEvents();
  }

  isRequiredLoadMore = () =>
    this.state.hasMoreEvents && (this.props.loadMore || this.props.events.length < this.props.limit);

  reloadEvents = async () => {
    if (this.props.loading) {
      return;
    }

    this.setLoadErrorStatus(false);

    const resp = await this.props.getEvents({
      reset: true,
      limit: this.props.limit,
      type: this._translateScope(this.props.scope),
      community_id: this.props.community ? this.props.community.id : undefined,
    });

    if (resp.ok) {
      this.setState({ hasMoreEvents: resp.data.length === this.props.limit });
    } else {
      this.setLoadErrorStatus();
    }
  };

  componentDidMount = async () => {
    this.reloadEvents();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.scope !== this.props.scope) {
      this.reloadEvents();
    }
  };

  _translateScope = (scope) => {
    return {
      Upcoming: 'upcoming',
      Past: 'past',
    }[scope];
  };

  getNextEvents = async () => {
    if (!this.props.loading && this.isRequiredLoadMore()) {
      const lastEvent = this.props.events[this.props.events.length - 1];
      const resp = await this.props.getEvents({
        limit: this.props.limit,
        cursor: lastEvent ? lastEvent.id : undefined,
        type: this._translateScope(this.props.scope),
        community_id: this.props.community ? this.props.community.id : undefined,
      });

      if (resp.ok) {
        this.setState({ hasMoreEvents: resp.data.length === this.props.limit, initialLoad: false });
      } else {
        this.setLoadErrorStatus();
      }
    }
  };

  setLoadErrorStatus = (isError = true) => {
    this.setState({ hasMoreEvents: !isError, failedToLoad: isError });
  };

  onEventActionClick = (action, event) => {
    switch (action) {
      case 'join':
        this.onRequestEnterConference(event);
        break;
      case 'going':
      case 'not_going':
        if (isOpenToResponse(event)) {
          this.props.changeStatus({ id: event.id, current_user_rsvp: action });
        } else {
          showAlert({ type: 'info', message: <Trans>This event has started already</Trans> });
        }
        break;
      default:
        console.log('Unhandled action:', action);
    }
  };

  onRequestEnterConference = async (event) => {
    let errorMessage = null;
    if (isOpenToJoin(event)) {
      errorMessage = await enterBigMarkerConference({
        userIds: [this.props.user.id],
        eventId: event.id,
        exitUrl: can(event, 'Event', 'isEmailInvitee')
          ? `${window.location.origin}/conference-ended?url=${btoa(window.location.origin)}`
          : undefined,
      });
    } else {
      errorMessage = <Trans>This video conference is already over</Trans>;
    }

    if (errorMessage) {
      showAlert({ type: 'info', message: errorMessage });
    }
  };

  render() {
    const events = createGroups(this.props.events);
    return (
      <TransitionGroup component={null}>
        <CSSTransition key="no-events" timeout={400} classNames="event">
          <React.Fragment>
            {Object.keys(events).length === 0 && !this.props.loading && !this.state.failedToLoad ? (
              <Empty
                description={
                  this.props.scope === 'Upcoming' ? (
                    <Text>You have no upcoming events</Text>
                  ) : (
                    <Text>You have no past events</Text>
                  )
                }
              />
            ) : null}
          </React.Fragment>
        </CSSTransition>

        <EventFeedback {...this.props.feedbackOptions} />

        <CSSTransition key="loader" timeout={400} classNames="event">
          <React.Fragment>
            {Object.keys(events).map((key, index) => {
              return (
                <CSSTransition key={index} timeout={400} classNames="event">
                  <EventDateList border={index > 0} data={events[key]} onActionClick={this.onEventActionClick} />
                </CSSTransition>
              );
            })}
            {this.props.loading ? (
              <Loader size={30} />
            ) : this.isRequiredLoadMore() ? (
              <InViewport size={30} key="event-list-viewport" onEnter={this.getNextEvents} />
            ) : this.state.failedToLoad ? (
              <Result
                status="error"
                subTitle={<Trans>Sorry, something went wrong.</Trans>}
                extra={
                  <Button type="primary" onClick={() => this.setLoadErrorStatus(false)}>
                    Retry
                  </Button>
                }
              />
            ) : null}
          </React.Fragment>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  events: select.events.get(state),
  loading: state.loading.effects.events.fetchAsync,
});

const mapDispatch = (dispatch, props) => ({
  resetEvents: (params) => dispatch.events.reset(),
  getEvents: (params) => dispatch.events.fetchAsync(params),
  getEvent: (id) => {
    const feed = getFeedKey(props);
    return dispatch.feed.findEventContentObjectAsync({
      feed: feed,
      eventId: id,
    });
  },
  changeStatus: (event) => dispatch.events.statusAsync(event),
});

export const EventsList = withRouter(connect(mapState, mapDispatch)(Renderer));
