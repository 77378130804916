import { Badge } from '../../../../..';
import { type NavigationMenuItemConfig } from '../config';
import { type BadgeOptions } from '../NavigationMenuLabel';

const NavigationMenuIcon = ({
  pathname,
  config,
  isCollapsed,
  isActive,
  badges,
}: {
  pathname: string;
  config: NavigationMenuItemConfig;
  isCollapsed: boolean;
  isActive: (pathname: string, key: string, alias?: string) => boolean;
  badges?: BadgeOptions;
}) => {
  const correctIcon = isActive(pathname, config.key, config.alias) ? config.activeIcon : config.icon;

  if (config.badge && isCollapsed) {
    return (
      <Badge className="navigation-menu-icon-badge" color="red" count={badges?.[config.badge]?.count}>
        {correctIcon}
      </Badge>
    );
  }

  return correctIcon;
};

export default NavigationMenuIcon;
