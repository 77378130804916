import * as React from 'react';
import { Field } from 'formik';
import { Trans, withTranslation } from 'react-i18next';

import { Text } from '../text';
import { Modal } from '../../atoms/modal';
import { TagsBox } from '../../atoms/tags';
import { ThemeConsumer } from '../../atoms/theme';
import { Autocomplete } from '../../atoms/autocomplete';
import { AutocompleteService } from '../../services/AutocompleteService';
import { FieldError } from './FieldError';
import { Avatar, Link } from 'ui';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  static defaultProps = {
    scope: 'all',
  };

  state = {
    isRemovingLast: false,
    isRemovingCurrentUser: false,
  };

  itemToString = (item) => {
    return item === null ? (
      ''
    ) : (
      <React.Fragment>
        {item.first_name} {item.last_name}
        <br />
        {item.email}
      </React.Fragment>
    );
  };

  itemToStringForTags = (item) => {
    return item === null ? '' : `${item.first_name} ${item.last_name}`;
  };

  checkRemoving(admins, newAdmins) {
    if (this.props.allRemovable || newAdmins.length > 0) {
      if (
        !admins.map((item) => item.id).includes(this.props.currentUser.id) ||
        newAdmins.map((item) => item.id).includes(this.props.currentUser.id) ||
        this.props.allRemovable
      ) {
        return true;
      } else {
        this.setState({ isRemovingCurrentUser: true });
      }
    } else {
      this.setState({ isRemovingLast: true });
    }
    return false;
  }

  renderInviteModal = (formik, admins) => {
    const getDataForValue = async (value) => {
      const response = await AutocompleteService.getMembers({
        scope: this.props.scope,
        search: value,
        limit: 100,
      });
      const selectionIds = admins.map((item) => item.id);
      return response.data.filter((item) => !selectionIds.includes(item.id));
    };

    const label = this.props.label ? this.props.label : this.props.t('Administrators');

    return (
      <Modal
        title={`Invite ${label}`}
        showFooter={false}
        trigger={(ref) => (
          <div className="admin-field-row__invite-button" ref={ref} id={`btn_invite-${label.toLowerCase()}`}>
            <Text weight="600" lineHeight="28px">
              + <Trans>Invite</Trans>
            </Text>
          </div>
        )}
      >
        {(modal) => {
          return (
            <div>
              <Autocomplete
                clearOnSelect
                clickable={false}
                currentSelectedData={admins}
                dataForValue={getDataForValue}
                itemToString={this.itemToString}
                getRowId={(item) => item.id}
                placeholder={this.props.placeholder}
                onSelect={(user) => {
                  if (user) {
                    const administrators = formik.values[this.props.name];
                    administrators.push(user);
                    formik.setFieldValue(this.props.name, administrators);
                    formik.setFieldTouched(this.props.name, true);
                    this.setState({ isRemovingLast: false, isRemovingCurrentUser: false });
                  }
                }}
                renderInput={(ref, inputProps) => (
                  <div ref={ref}>
                    <TagsBox
                      inputProps={inputProps}
                      placeholder={this.props.placeholder}
                      selection={admins}
                      itemToString={this.itemToStringForTags}
                      onChange={(array) => {
                        if (this.checkRemoving(admins, array)) {
                          formik.setFieldValue(this.props.name, array);
                          formik.setFieldTouched(this.props.name, true);
                          this.setState({ isRemovingLast: false, isRemovingCurrentUser: false });
                        }
                      }}
                      id={'search-admin'}
                    />
                  </div>
                )}
              />
              {formik.touched[this.props.name] ? (
                <Text style={{ marginTop: 10, display: 'inline-block' }}>
                  <Trans>
                    Please click X and scroll down and submit the form to reflect the changes in the "{label}" section
                  </Trans>
                </Text>
              ) : null}
              {!this.props.allRemovable && (this.state.isRemovingLast || this.state.isRemovingCurrentUser) ? (
                <Text color="red" style={{ marginTop: 10, display: 'inline-block' }}>
                  {this.state.isRemovingLast && <Trans>You cannot remove the last user!</Trans>}
                  {this.state.isRemovingCurrentUser && <Trans>You cannot remove yourself!</Trans>}
                </Text>
              ) : null}
            </div>
          );
        }}
      </Modal>
    );
  };

  render() {
    const label = this.props.label ? this.props.label : this.props.t('Administrators');

    return (
      <ThemeConsumer>
        {(theme) => (
          <Field name={this.props.name}>
            {(fieldProps) => {
              return (
                <div style={{ padding: '10px 0' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text size="12px" lineHeight="28px" component="p" color={theme.color.lightGray}>
                      {label}
                    </Text>
                    {this.renderInviteModal(fieldProps.form, fieldProps.field.value)}
                  </div>
                  <div style={{ paddingTop: 5 }}>
                    {!fieldProps.field.value.length && (
                      <Text
                        size="12px"
                        lineHeight="28px"
                        component="p"
                        color={theme.color.lightGray}
                        style={{ textAlign: 'center' }}
                      >
                        <Trans>No</Trans> {label}
                      </Text>
                    )}
                    {fieldProps.field.value.map((admin) => (
                      <div className="admin-field-row" key={admin.id}>
                        <div style={{ display: 'flex', cursor: 'pointer' }}>
                          <Link to={`/members/${admin.id}`} className="admin-field-row__link" target="_blank">
                            <Avatar size={30} photo={admin.profile_photo ?? images.default.userProfilePhoto} />
                            <span className="admin-field-row__name">{`${admin.first_name} ${admin.last_name}`}</span>
                          </Link>
                        </div>
                        {(fieldProps.field.value.length > 1 && admin.id !== this.props.currentUser.id) ||
                        this.props.allRemovable ? (
                          <button
                            type="button"
                            onClick={() => this.props.removeAdmin(admin)}
                            className="admin-field-row__button"
                          >
                            -
                          </button>
                        ) : null}
                      </div>
                    ))}
                  </div>
                  <FieldError name={this.props.name} />
                </div>
              );
            }}
          </Field>
        )}
      </ThemeConsumer>
    );
  }
}

export const AdminsField = withTranslation()(Renderer);
