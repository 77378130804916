import { type Profile } from 'models';
import { useMemo } from 'react';
import {
  mapToNonCustomOnboardingTourStepOption,
  type NonCustomOnboardingTourStepOption,
  type OnboardingTourStep,
  type SupportedOboardingTourStep,
} from '../../models';

export const useSupportedOnboardingTourSteps = ({ viewer, steps }: { viewer: Profile; steps: OnboardingTourStep[] }) =>
  useMemo(() => {
    const remainingSteps: SupportedOboardingTourStep[] = [];

    for (const step of steps) {
      const localStep = mapToNonCustomOnboardingTourStepOption(step.type);

      if (isValidStep(localStep, viewer)) {
        remainingSteps.push({
          step: localStep,
          data: step,
        });
      }
    }

    return remainingSteps.sort((step1, step2) => step1.data.order - step2.data.order);
  }, [viewer, steps]);

const isValidStep = (
  step: NonCustomOnboardingTourStepOption | undefined,
  viewer: Profile,
): step is NonCustomOnboardingTourStepOption => {
  if (!step) {
    return false;
  }

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return -- This is more readable
  if (step === 'consents' && viewer.requiredConsents.length === 0) {
    return false;
  }

  return true;
};
