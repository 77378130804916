import { type ReduxUser, type CommunityListItem } from 'models';
import { useState, useMemo } from 'react';

import { canRequestEventInCommunity } from 'permissions';
import { TelehealthButton, type TelehealthButtonType } from 'ui';

import TelehealthRequestFormModal from './TelehealthRequestFormModal';
import TelehealthRequestNoticeModal from './TelehealthRequestNoticeModal';
import { TelehealthRequestPersonalInfoModal } from './TelehealthRequestPersonalInfoModal';

type P = {
  viewer: ReduxUser;
  communities: CommunityListItem[];
  buttonType: TelehealthButtonType;
  communityId?: string;
};

type Step = 'notice' | 'personalForm' | 'telehealthForm' | undefined;

const TelehealthRequest = ({ viewer, communities, buttonType, communityId }: P) => {
  const [step, setStep] = useState<Step>();

  const medicalCommunities = useMemo(() => {
    return communities.filter((community) =>
      canRequestEventInCommunity({
        viewer,
        community,
        eventType: 'telehealth_visit',
      }),
    );
  }, [viewer, communities]);

  const gotTeStep = (step?: Step) => () => {
    setStep(step);
  };

  return (
    <>
      <TelehealthButton buttonType={buttonType} onClick={gotTeStep('notice')}>
        Request telehealth visit
      </TelehealthButton>

      <TelehealthRequestNoticeModal
        isOpened={step === 'notice'}
        onClose={() => {
          setStep(undefined);
        }}
        onSubmit={gotTeStep('personalForm')}
      />

      <TelehealthRequestPersonalInfoModal
        viewer={viewer}
        isOpened={step === 'personalForm'}
        onCancel={() => {
          setStep(undefined);
        }}
        onSuccess={gotTeStep('telehealthForm')}
      />

      <TelehealthRequestFormModal
        communityId={communityId}
        isOpened={step === 'telehealthForm'}
        communities={medicalCommunities}
        onClose={gotTeStep(undefined)}
      />
    </>
  );
};

export default TelehealthRequest;
