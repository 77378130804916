import { create } from 'apisauce';
import { select } from '@rematch/select';

import { dispatch, getState } from '../store';
import { AppService } from './AppService';
import { appVersion } from 'common/services/api';
import { getAccessToken } from 'common/services/auth';
import { CHANGELOG_STORAGE_KEY } from 'features/changelog';

const CONTENT_TYPE_HEADER = 'content-type';

export const http = create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'x-app-name': process.env.REACT_APP_CONFIG,
    'x-app-platform': 'fe',
    'x-app-version': appVersion,
  },
});

const clearCache = async () => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      for (const name of names) caches.delete(name);
    });
  }

  await dispatch.session.resetOnlyToUser();

  window.location.reload(true);
};

export async function isApplicationCurrent() {
  let script = null;
  const elements = document.querySelectorAll('body script');
  const mainRegex = /\w*main\.([A-Za-z0-9]*)(\.)?js/g;

  elements.forEach((e) => {
    const res = e.src.match(mainRegex);
    if (res) {
      script = e;
    }
  });

  if (!script) {
    return true;
  }

  try {
    const result = await AppService.head(`${script.src}?t=${new Date().getTime()}`);
    const { headers } = result;
    return (
      !Object.prototype.hasOwnProperty.call(headers, CONTENT_TYPE_HEADER) ||
      (Object.prototype.hasOwnProperty.call(headers, CONTENT_TYPE_HEADER) &&
        headers[CONTENT_TYPE_HEADER].includes('application/javascript'))
    );
  } catch (e) {
    console.log('err', e);
    return true;
  }
}

http.addAsyncRequestTransform(async (request) => {
  if (request.doNotIntercept) {
    return request;
  }

  const accessToken = await getAccessToken();

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
});

http.addResponseTransform((response) => {
  const state = getState();
  const user = select.session.user(state);
  const isAuthenticated = select.session.isAuthenticated(state);

  if (user && response.headers) {
    const notificationTimestamp = select.notifications.timestamp(state);
    // session update
    if (
      response.headers['last-session-timestamp'] &&
      user.session_updated_at < parseInt(response.headers['last-session-timestamp'], 10) &&
      !state.loading.effects.session.fetchUser
    ) {
      dispatch.session.fetchUser();
    }

    // notification update
    if (
      response.headers['last-notification-timestamp'] &&
      parseInt(notificationTimestamp, 10) < parseInt(response.headers['last-notification-timestamp'], 10) &&
      !state.loading.effects.notifications.getBadgesAsync
    ) {
      dispatch.notifications.getBadgesAsync({
        timestamp: response.headers['last-notification-timestamp'],
      });
    }

    /**
     * Logout if is the token invalid
     */
    if (
      ((response.data && ['invalid_grant', 'invalid_token', 'user_banned'].includes(response.data.error)) ||
        response.status === 401) &&
      isAuthenticated
    ) {
      dispatch.session.logOut();
    }

    // Check version && clear cache
    if (response.headers['x-version-fe']) {
      const apiVersion = response.headers['x-version-fe'];
      const currentVersion = isApplicationCurrent();
      const localVersion = localStorage.getItem('version');

      if (!currentVersion || apiVersion !== localVersion) {
        localStorage.setItem('version', apiVersion);

        if (localVersion) {
          // TODO remove this when info will be stored on BE `58209` FE issue for clean up
          // This is now triggering showing changelog
          if (!localStorage.getItem(CHANGELOG_STORAGE_KEY)) {
            localStorage.setItem(CHANGELOG_STORAGE_KEY, '0.0.1');
          }

          clearCache();
        }
      }
    }
  }

  return response;
});
