import { type ReactNode } from 'react';

type P = {
  right: ReactNode;
  left: ReactNode;
};

const StatusBarLayout = ({ right, left }: P) => {
  return (
    <div className="footer-status-bar">
      <div className="footer-status-bar--left">{left}</div>
      <div className="footer-status-bar--right">{right}</div>
    </div>
  );
};

export default StatusBarLayout;
