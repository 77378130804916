import * as React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '../../atoms/box/Box';
import { Button } from '../../atoms/button';
import { Loader } from '../../atoms/loader';
import { Count } from '../../atoms/count';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { images } from 'common/utils';

export function OrganizationItem(props) {
  const { organization } = props;

  function onClick(e) {
    const tagName = e.target.tagName.toLowerCase();
    if (tagName !== 'span' && tagName !== 'button') {
      props.onClick(e);
    }
  }

  function renderButtons(theme) {
    const { organization } = props;
    const defaultButtonProps = {
      size: 'md',
      type: 'button',
      color: theme.color.green,
      textColor: 'white',
      block: true,
    };

    if (props.joining) {
      return <Button title={<Loader size={20} color={theme.color.white} />} disabled {...defaultButtonProps} />;
    }
    if (organization.privacy === 'public') {
      return organization.joined === true ? (
        <Button title={<Trans>Joined</Trans>} disabled {...defaultButtonProps} />
      ) : (
        <Button title={<Trans>Join Now</Trans>} onClick={props.onJoinNow} {...defaultButtonProps} />
      );
    }
    if (organization.privacy === 'private') {
      return organization.joined === 'pending' ? (
        <Button title={<Trans>Request sent</Trans>} outline disabled {...defaultButtonProps} />
      ) : (
        <Button title={<Trans>Request to Join</Trans>} onClick={props.onRequestToJoin} {...defaultButtonProps} />
      );
    }

    return null;
  }

  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          onClick={onClick}
          className="organization-item"
          style={{ backgroundImage: `url(${organization.cover_photo ? organization.cover_photo.url : images.default.organizationProfilePhoto})` }}
        >
          <Box style={{ position: 'relative', height: '100%' }}>
            <div style={{ flex: 1 }}>
              {organization.short_name ? (
                <Text size="24px" lineHeight="1" color="white" weight="bold" component="p">
                  {organization.short_name}
                </Text>
              ) : null}
              <Text size="20px" lineHeight="1.2" color="white" weight="300" component="p">
                {organization.name}
              </Text>
            </div>
            <Box flexDirection="row" alignItems="center">
              <Box flexDirection="row" alignItems="center" alignSelf="flex-end" flex="1">
                <div style={{ width: 140 }}>{renderButtons(theme)}</div>
                <div style={{ textAlign: 'center' }}>
                  <Count iconName="user" iconColor="white" color="white" style={{ marginLeft: 15 }}>
                    {`${organization.users_count}`}
                  </Count>
                </div>
              </Box>
              {organization.white_profile_photo ? (
                <img src={organization.white_profile_photo?.url ?? images.default.organizationProfilePhoto} alt="" style={{ width: 70 }} />
              ) : null}
            </Box>
          </Box>
        </div>
      )}
    </ThemeConsumer>
  );
}
