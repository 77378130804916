import { select } from '@rematch/select';
import moment from 'moment';

import { getState } from '../store';
import {
  canLeaveCommunityRedux,
  canDeleteProgramMemberRedux,
  canShareContentObjectRedux,
  canSeeFuturePosts,
  canRateProgramObject,
  canCommentContentObject,
  canCreateProgram,
} from 'permissions';

export function can(record, type, action) {
  const state = getState();
  const viewer = select.session.user(state);

  const recordActions = {
    ContentObject: {
      delete: (record) => record.author.id === viewer.id || viewer.superadmin,
      share: (record) => canShareContentObjectRedux({ viewer, contentObject: record }),
      comment: (record) => canCommentContentObject({ viewer, contentObject: record }),
      post: (community) => {
        return (
          viewer.superadmin ||
          viewer.admin_communities.includes(community.id) ||
          (community.organization && viewer.admin_organizations.includes(community.organization.id)) ||
          (community.settings ? community.settings.allow_comments : false)
        );
      },
      viewPollChoicePercentage: (communities) => {
        return (
          viewer.superadmin ||
          communities?.some(
            (community) =>
              viewer.admin_communities.includes(community.id) ||
              (community.organization && viewer.admin_organizations.includes(community.organization.id)),
          )
        );
      },
    },
    Course: {
      create: () => canCreateProgram({ viewer }),
      deleteMember: (course) => canDeleteProgramMemberRedux({ viewer, program: course }),
      contentCreator: (course) =>
        viewer.superadmin || viewer.admin_programs.includes(course?.id) || viewer.author_programs.includes(course?.id),
      editProgramContent: (course) => recordActions.Course.contentCreator(course) && course.status !== 'finished',
      createProgramContent: (course) => recordActions.Course.editProgramContent(course),
      deleteProgramContent: (course) => recordActions.Course.contentCreator(course),
      editProgram: (course) =>
        course.status !== 'finished' &&
        moment(course.end_time).isAfter() &&
        recordActions.Course.contentCreator(course),
      editAdmins: (course) => !course.id || viewer.author_programs.includes(course?.id),
      finish: (course) => recordActions.Course.contentCreator(course) && course.status === 'published',
      publishable: (course) => recordActions.Course.contentCreator(course) && course.status === 'draft',
      publish: (course) => recordActions.Course.publishable(course) && moment(course.start_time).isAfter(),
      duplicate: (course) => viewer.superadmin || viewer.author_programs.includes(course?.id),
      delete: (course) => recordActions.Course.duplicate(course),
      seeFuturePosts: (course) => canSeeFuturePosts({ viewer, program: course }),
      viewProgram: (course) =>
        (viewer.joined_programs.some((program) => program.id === course?.id) && course.status === 'published') ||
        viewer.admin_programs.includes(course?.id) ||
        viewer.author_programs.includes(course?.id) ||
        viewer.superadmin,
      rateProgramObject: (course) => canRateProgramObject({ viewer, program: course }),
      downloadReport: (course) => recordActions.Course.contentCreator(course),
      inviteMembers: (course) =>
        course?.status === 'published' &&
        (recordActions.Course.seeFuturePosts(course) ||
          (course.organization && viewer.admin_organizations.includes(course.organization.id))),
      isMemberOfProgram: (course) =>
        viewer.joined_programs.some((program) => program.id === course?.id) ||
        viewer.admin_programs.includes(course?.id) ||
        viewer.author_programs.includes(course?.id),
      joinedAsUser: (course) => viewer.joined_programs.some((program) => program.id === course?.id),
      hasUserSentJoinRequest: (course) => course?.privacy === 'private' && course?.requested_to_join,
      hasUserSentContactUsRequest: (course) => course.requested_to_contact && course.price_option === 'contact_us',
      canUserRequestContactUs: (course) =>
        course.author?.id !== viewer.id &&
        course.price_option === 'contact_us' &&
        !course.requested_to_contact &&
        !viewer.joined_programs.some((program) => program.id === course?.id),
    },
    Activity: {
      submitData: (activity) => !activity?.is_withings && !activity?.is_dexcom,
    },
    Community: {
      joinWithoutPermission: (community) =>
        viewer.superadmin || viewer.admin_organizations.includes(community.organization.id),
      edit: (community) =>
        recordActions.Community.joinWithoutPermission(community) || viewer.admin_communities.includes(community.id),
      joined: (community) => viewer.joined_communities.some((joined) => joined.id === community?.id),
      leaveCommunity: (community) => canLeaveCommunityRedux({ viewer, community }),
    },
    Event: {
      isEmailInvitee: (event) => event.email_invitees?.some((invitee) => invitee.email === viewer.email),
    },
  };

  return recordActions[type][action](record);
}
