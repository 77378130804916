import qs from 'qs';
import { useLocation, useParams } from 'react-router-dom';
import { ContentLeft, Footer, Header, type HeaderItem, MagicLinkCheck } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation, useOnSuccessLogin } from '../hooks';
import { type OnboardingLocationState } from '../types';

export type CheckMagicLinkLocationState = OnboardingLocationState;

const PageCheckMagicLink = () => {
  const { token } = useParams<{ token?: string }>();
  const location = useLocation<CheckMagicLinkLocationState>();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const referrer = typeof query['redirect-url'] === 'string' ? query['redirect-url'] : '/';
  const { onSuccessLogin } = useOnSuccessLogin<CheckMagicLinkLocationState>({ referrer });
  const { onNavigateToSignUp, onNavigateToPrimaryLogin } = useOnboardingNavigation<CheckMagicLinkLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Sign up',
      type: 'primary',
      onClick() {
        onNavigateToSignUp();
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={
        <MagicLinkCheck
          onSuccessLogin={onSuccessLogin}
          token={token}
          onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
        />
      }
      footer={<Footer />}
    />
  );
};

export default PageCheckMagicLink;
