import { type BaseOptionType } from 'antd/lib/select';
import { useState } from 'react';
import { DebounceSelect, type DebounceSelectProperties } from 'ui';
import { useUsersQuery } from '../../../queries';

type P<ValueType, OptionType extends BaseOptionType> = DebounceSelectProperties<ValueType, OptionType> & {
  communityId: string;
};

type Option = { label: string; value: string };

const UsersInput = ({ autoClearSearchValue = false, communityId, ...rest }: P<string, Option>) => {
  const [search, setSearch] = useState<string>();
  const { data: users, isLoading, isFetching } = useUsersQuery({ search, excludeCommunityId: communityId });

  const options = users?.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  return (
    <DebounceSelect<string, Option>
      showSearch
      mode="multiple"
      autoClearSearchValue={autoClearSearchValue}
      options={options}
      filterOption={false}
      loading={isLoading || isFetching}
      onSearch={setSearch}
      {...rest}
    />
  );
};

export default UsersInput;
