import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { InfinityLoading, Loading, Select, type SelectProperties } from 'ui';

type P = Omit<SelectProperties, 'options'> & {
  loadingProps: {
    hasMoreData?: boolean;
    fetchNextPage?: () => void;
  };
  isLoading: boolean;
  isDebounce?: boolean;
  onSearch?: ((value: string) => void) | undefined;
  options: Array<{
    value: string;
    label: string;
  }>;
};

const InfinityScrollSelectDeprecated = ({
  options,
  isDebounce,
  onSearch,
  isLoading,
  loadingProps: { hasMoreData, fetchNextPage },
  ...selectProperties
}: P) => {
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

  const debounceOnSearchChange = useMemo(
    () =>
      debounce((search: string) => {
        onSearch?.(search);
        setIsDebouncing(false);
      }, 500),
    [onSearch],
  );

  const debounceSearch = (search: string) => {
    if (isDebounce) {
      setIsDebouncing(true);
      debounceOnSearchChange(search);
    } else {
      onSearch?.(search);
    }
  };

  const isFetchingData = isLoading || isDebouncing;

  return (
    <Select
      showSearch={onSearch ? Boolean(onSearch) : false}
      filterOption={false}
      onSearch={debounceSearch}
      loading={isFetchingData}
      notFoundContent={isFetchingData ? <Loading size="default" /> : undefined}
      {...selectProperties}
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
      {hasMoreData && fetchNextPage ? (
        <Select.Option key="select_spinner" value="select_spinner" disabled>
          <InfinityLoading
            hasMoreData={hasMoreData}
            isLoading={isLoading}
            spinnerSize="default"
            onEnter={fetchNextPage}
          />
        </Select.Option>
      ) : null}
    </Select>
  );
};

export default InfinityScrollSelectDeprecated;
