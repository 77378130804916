import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../../box';
import { FileIcon } from '../../file';
import { Text } from '../../text';
import { ThemeConsumer } from '../../theme';

export function FileItem(props) {
  const { t } = useTranslation();

  return (
    <ThemeConsumer>
      {(theme) => (
        <Box
          {...props.downshiftProps}
          flexDirection="row"
          alignItems="center"
          style={{ padding: '7px 15px', cursor: 'pointer' }}
        >
          <Box style={{ marginRight: 10 }}>
            <FileIcon
              url={props.itemProps.file.url}
              fileType={props.itemProps.file.content_type}
              extension={props.itemProps.file.filename.split(/\.(?=[^.]+$)/)[1]}
            />
          </Box>
          <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Text
              component="p"
              size="14px"
              weight="500"
              color={theme.color.gray}
              lineHeight="19px"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {props.title}
            </Text>
            <Text
              component="time"
              size="12px"
              weight="500"
              color="#7E939E"
              lineHeight="21px"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {t('fileSearchItemDate', {
                date: new Date(props.itemProps.created_at),
                fileName: `, ${props.itemProps.file.content_type.split('/')[1].toUpperCase()}`,
              })}
            </Text>
          </Box>
        </Box>
      )}
    </ThemeConsumer>
  );
}
