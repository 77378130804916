import { Trans } from 'react-i18next';

import { Card } from 'ui';
import { EventMemberRow } from '../members-list';
import { Text } from '../text';

export function EventOrganizers({ event, viewer }) {
  const emailAdministrators = event?.email_invitees?.filter((invitee) => invitee.event_role === 'moderator');

  const hasAdministrators = event?.administrators && event?.administrators.length > 0;
  const hasEmailInviteesAdministrators = emailAdministrators && emailAdministrators.length > 0;

  return (
    <Card id="admins-card" title="Admin/Host">
      {hasAdministrators
        ? event?.administrators.map((admin) => (
            <EventMemberRow key={admin.id} viewer={viewer} member={admin} onDropdownSelect={() => {}} />
          ))
        : null}
      {hasEmailInviteesAdministrators
        ? emailAdministrators.map((admin) => (
            <EventMemberRow
              key={admin.id}
              viewer={viewer}
              member={admin}
              onClickToMember={() => {}}
              onDropdownSelect={() => {}}
            />
          ))
        : null}
      {!hasAdministrators && !hasEmailInviteesAdministrators ? (
        <Text>
          <Trans>No Results</Trans>
        </Text>
      ) : null}
    </Card>
  );
}
