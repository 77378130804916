import variables from 'common/styles/variables.json';
import { Skeleton, Row, Col } from 'ui';

const ProfileFormSkeleton = () => {
  return (
    <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value] }}>
      <Col item={{ span: 24 }}>
        <Skeleton.Avatar active size={100} shape="circle" />
      </Col>
      <Col item={{ span: 24 }}>
        <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value] }}>
          <Col item={{ span: 8 }}>
            <Skeleton.Input active size="large" block />
          </Col>
          <Col item={{ span: 8 }}>
            <Skeleton.Input active size="large" block />
          </Col>
          <Col item={{ span: 8 }}>
            <Skeleton.Input active size="large" block />
          </Col>
        </Row>
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value] }}>
          <Col item={{ span: 8 }}>
            <Skeleton.Input active size="large" block />
          </Col>
          <Col item={{ span: 8 }}>
            <Skeleton.Input active size="large" block />
          </Col>
          <Col item={{ span: 8 }}>
            <Skeleton.Input active size="large" block />
          </Col>
        </Row>
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Row item={{ gutter: [variables.spaceXs.value, 0] }}>
          <Col item={{ xs: 24, lg: 18 }}>
            <Skeleton.Input active size="large" block />
          </Col>
          <Col item={{ xs: 24, lg: 6 }}>
            <Skeleton.Input active size="large" block />
          </Col>
        </Row>
      </Col>
      <Col item={{ span: 24 }}>
        <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value] }}>
          <Col item={{ xs: 24, lg: 18 }}>
            <Skeleton.Input active size="large" block />
          </Col>
          <Col item={{ xs: 24, lg: 6 }}>
            <Skeleton.Input active size="large" block />
          </Col>
        </Row>
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input active size="large" block />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Button active size="large" block />
      </Col>
    </Row>
  );
};

export default ProfileFormSkeleton;
