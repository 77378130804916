import { type EventSlim } from 'models';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { DataCard, EventIcon } from 'ui';

type P = {
  getPathToEventDetail: (id: string) => string;
  event: EventSlim;
  showTag?: boolean;
};

const EventCard = ({ getPathToEventDetail, event, showTag }: P) => {
  const { t } = useTranslation();

  return (
    <DataCard
      title={event.name}
      description={{
        dateFrom: event.startTime,
        dateTo: event.endTime,
        dateFormat: 'dateFormatShortMonthDayYearTimeRange',
      }}
      image={{
        src: event.coverPhoto?.url ?? images.default.eventCoverPhoto,
        alt: t('Event cover image'),
      }}
      tag={showTag ? { className: 'event-card__tag', text: t('Event'), icon: <EventIcon /> } : undefined}
      className="event-card"
      path={getPathToEventDetail(event.id)}
    />
  );
};

export default EventCard;
