import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import variables from 'common/styles/variables.json';
import { Col, Row, Button, Form, TimeInput } from 'ui';

type P = {
  name: string;
  disabled?: boolean;
};

const ActivityTimes: React.FC<P> = ({ name, disabled }) => {
  const { t } = useTranslation();

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <>
            <div className="periodicity__time-label">{t('Time')}</div>
            {fields.map((field) => (
              <Form.Item required={false} key={field.key} className="periodicity__times">
                <Row item={{ gutter: variables.spaceMd.value }}>
                  <Col item={{ span: 21 }}>
                    <TimeInput
                      item={{
                        ...field,
                        name: [field.name, 'time'],
                        rules: [{ required: true, message: t('Time is required') }],
                      }}
                      input={{
                        disabled,
                      }}
                    />
                  </Col>
                  {!disabled && (
                    <Col item={{ span: 2, className: 'delete-button-wrapper' }}>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Form.Item>
            ))}
            {!disabled && (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  size="large"
                >
                  <PlusOutlined /> {t('Add new time')}
                </Button>
              </Form.Item>
            )}
          </>
        );
      }}
    </Form.List>
  );
};

export default ActivityTimes;
