import { type Profile } from 'models';
import {
  type OnboardingTourStep,
  type OnboardingTourStepOption,
  type UpdateOnboardingTourStepOptionHandler,
} from '../models';
import { FinishStep } from './FinishStep';
import { useSupportedOnboardingTourStep, useSupportedOnboardingTourSteps } from './hooks';
import { IntroStep } from './IntroStep';
import { NoSupportedStep } from './NoSupportedStep';
import { SupportedStep } from './SupportedStep';

type P = {
  viewer: Profile;
  step: OnboardingTourStepOption;
  steps: OnboardingTourStep[];
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
  onLogout: () => void;
  onFinish: () => void;
};

const OnboardingTour = ({ viewer, step, steps, onChangeStep, onLogout, onFinish }: P) => {
  const supportedSteps = useSupportedOnboardingTourSteps({ viewer, steps });
  const supportedStep = useSupportedOnboardingTourStep({ step, steps: supportedSteps });

  if (step === 'intro') {
    return <IntroStep viewer={viewer} onChangeStep={onChangeStep} steps={supportedSteps} onLogout={onLogout} />;
  }

  if (supportedStep) {
    return <SupportedStep viewer={viewer} step={supportedStep} steps={supportedSteps} onChangeStep={onChangeStep} />;
  }

  if (step === 'finish') {
    return <FinishStep viewer={viewer} onFinish={onFinish} />;
  }

  return <NoSupportedStep onChangeStep={onChangeStep} />;
};

export default OnboardingTour;
