import { Form } from 'antd';
import type React from 'react';
import { useTranslation } from 'react-i18next';

type ItemP<Values = any> = React.ComponentProps<typeof Form.Item<Values>> & {
  noOptional?: boolean;
};

export const Item = <Values = any,>({ label, rules, noOptional, extra, ...rest }: ItemP<Values>) => {
  const { t } = useTranslation();

  const isRequired = rules?.some((rule) => typeof rule === 'object' && rule.required);

  let customLabel = label;
  if (typeof label === 'string') {
    customLabel =
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      isRequired || noOptional ? (
        t(label)
      ) : (
        <>
          {t(label)}
          <span className="optional-label">&nbsp;({t('Optional')})</span>
        </>
      );
  }

  return (
    <Form.Item<Values>
      label={customLabel}
      rules={rules}
      extra={
        typeof extra === 'string' ? (
          <>
            {t('Info')}: {t(extra)}
          </>
        ) : (
          extra
        )
      }
      {...rest}
    />
  );
};
