import * as React from 'react';
import { Trans } from 'react-i18next';
import { Icon } from '../icon';

export const SearchInput = React.forwardRef((props, ref) => {
  const { hideIcon, outerDivCss, iconSize, iconColor, inputCss, ...htmlProps } = props;
  const placeholder = props.placeholder;

  return (
    <div
      style={
        outerDivCss || {
          flexGrow: 1,
          backgroundColor: '#eceff1',
          borderRadius: '3px',
          position: 'relative',
          overflow: 'hidden',
        }
      }
    >
      {!hideIcon && (
        <Icon
          size={iconSize || 28}
          name="pba-search"
          color={iconColor || '#90A4AE'}
          style={{
            position: 'absolute',
            top: '50%',
            left: 13,
            transform: 'translate(0, -50%)',
          }}
        />
      )}
      <input
        name="search"
        type="text"
        style={
          inputCss || {
            color: '#455a64',
            position: 'relative',
            height: 45,
            width: '100%',
            background: 'none',
            border: 0,
            outline: 'none',
            fontSize: 14,
            fontWeight: 22 / 14,
            paddingLeft: 45,
            paddingRight: 10,
          }
        }
        {...htmlProps}
        placeholder={placeholder}
        ref={ref}
      />
    </div>
  );
});

SearchInput.defaultProps = {
  placeholder: <Trans>Search...</Trans>,
};
