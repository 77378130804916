import { http } from './shared';

const transformAppConfig = (data) => {
  Object.keys(data).forEach((key) => (data[key] = data[key] === '' ? null : data[key]));
  return data;
};

/**
 * Implementation
 */
export const AppService = {
  async getConfig(slug) {
    return http.get(`/app_configs/${slug}`);
  },
  get: () => http.get('/app_configs'),
  head: (url) => http.head(url),
  create: (payload) => http.post(`/app_configs`, { app_config: transformAppConfig(payload) }),
  update: (name, payload) => http.put(`/app_configs/${name}`, { app_config: transformAppConfig(payload) }),
  delete: (name) => http.delete(`/app_configs/${name}`),
};
