import { type CommunityNotificationSettings } from 'models';
import { CheckboxInput } from 'ui';

export const isChangeAllowed = ({
  notificationSettings,
  notificationType,
  notificationEntity,
}: {
  notificationSettings: CommunityNotificationSettings;
  notificationType: 'emailNotificationsSettings' | 'pushNotificationsSettings';
  notificationEntity: 'donations' | 'events' | 'newsfeed' | 'program';
}) => {
  return notificationSettings[notificationType][notificationEntity].changeAllowed;
};

const NotificationCheckbox = ({
  notificationSettings,
  notificationType,
  notificationEntity,
  label,
}: {
  notificationSettings: CommunityNotificationSettings;
  notificationType: 'emailNotificationsSettings' | 'pushNotificationsSettings';
  notificationEntity: 'donations' | 'events' | 'newsfeed' | 'program';
  label: string;
}) => {
  if (!isChangeAllowed({ notificationSettings, notificationType, notificationEntity })) {
    return null;
  }

  return (
    <CheckboxInput
      item={{
        name: [notificationType, notificationEntity],
        label,
        valuePropName: 'checked',
      }}
    />
  );
};

export default NotificationCheckbox;
