import { atom } from 'recoil';
import { PaginationOptions } from '../../common/types';
import { defaultUsersPaginationOptions, defaultUsersSearch } from '../defaults';

export type LoginData = {
  action: 'login';
  legacyToken: string;
};
export type LogoutData = { action: 'logout'; legacyToken: string };
export const defaultCurrentAttemptLoginData: AuthData | null = null;
export type AuthData = LoginData | LogoutData;

export const usersSearch = atom<string | null>({
  key: 'UsersSearch',
  default: defaultUsersSearch,
});

export const usersPaginationOptions = atom<PaginationOptions>({
  key: 'UsersPaginationOptions',
  default: defaultUsersPaginationOptions,
});

export const currentLoginData = atom<AuthData | null>({
  key: 'CurrentLoginData',
  default: defaultCurrentAttemptLoginData,
});
