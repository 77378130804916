import cs from 'classnames';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Image, Link, Row, Tag, Text } from 'ui';
import { DataCardPrice } from './DataCardPrice';

type P = {
  title: string;
  path: string;
  description?: string | { dateFrom: Date; dateTo: Date; dateFormat: string };
  prices?: { currentPrice: number | 'free'; originalPrice?: number; label?: string };
  image: { src: string; alt?: string };
  tag?: { icon: ReactNode; text: string; className?: string };
  className?: string;
};

const DataCard = ({ title, description, prices, image, tag, className, path }: P) => {
  const { t } = useTranslation();

  return (
    <Link to={path} className={cs('data-card', className)} effects={{ focus: true }}>
      <Row>
        <Col item={{ span: 24, className: 'data-card__cover-photo' }}>
          {/* TODO: Image behaviour and properties will be fixed along with 16:9 update for the card */}
          <Image
            height={160}
            className="data-card__cover-photo__image"
            preview={false}
            src={image.src}
            alt={image.alt}
          />
        </Col>
        {tag ? (
          <Col item={{ span: 24 }}>
            <Tag className={tag.className} icon={tag.icon}>
              {tag.text}
            </Tag>
          </Col>
        ) : null}
        <Col item={{ span: 24 }}>
          <Text type="title" weight="bold" withinLink={{ hasHoverEffect: true }}>
            {t(title)}
          </Text>
        </Col>
        {description ? (
          <Col item={{ span: 24 }}>
            <Text size="small">
              {typeof description === 'string'
                ? description
                : t(description.dateFormat, { dateFrom: description.dateFrom, dateTo: description.dateTo })}
            </Text>
          </Col>
        ) : null}
        {prices ? (
          <Col item={{ span: 24 }}>
            <DataCardPrice prices={prices} />
          </Col>
        ) : null}
      </Row>
    </Link>
  );
};

export default DataCard;
