import { type SpinSize } from 'antd/lib/spin';
import { InViewport, Loading } from 'ui';

const InfinityLoading = ({
  isLoading,
  spinnerSize,
  hasMoreData,
  onEnter,
}: {
  isLoading: boolean;
  spinnerSize?: SpinSize;
  hasMoreData?: boolean;
  onEnter: () => unknown;
}) => {
  if (isLoading || hasMoreData) {
    return (
      <>
        {hasMoreData && !isLoading ? <InViewport onEnter={onEnter} /> : null}
        <Loading size={spinnerSize} />
      </>
    );
  }

  return null;
};

export default InfinityLoading;
