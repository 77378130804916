import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { AppLayout } from '../../layouts/AppLayout';
import { AssetManager } from '../../atoms/asset-manager';
import { AssetService } from '../../services/AssetService';
import { AttachmentMenu } from '../../atoms/post-editor/AttachmentMenu';
import { Box } from '../../atoms/box';
import { CourseLayout } from '../../layouts/CourseLayout';
import { Icon } from '../../atoms/icon';
import { Loader } from '../../atoms/loader';
import { ShadowBox, ShadowBoxHeader } from '../../atoms/shadow-box';
import { showSuccessErrorAlert } from '../../atoms/alert';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { withPermissions } from '../../atoms/auth';

class Renderer extends React.Component {
  state = {
    uploading: false,
  };

  requestListing = async (parentId, params) => {
    const course_id = this.props.match.params.courseId;
    const response = await AssetService.list({
      ...params,
      program_id: course_id,
      parent_id: parentId,
    });
    return response;
  };

  onCreateFolder = async (values) => {
    const course_id = this.props.match.params.courseId;
    const response = await AssetService.create({
      program_id: course_id,
      parent_id: values.parentId,
      type: 'folder',
      name: values.folderName,
    });
    showSuccessErrorAlert({
      isOk: response.ok,
      successMessage: <Trans>Folder Successfully Created.</Trans>,
    });

    return response;
  };

  onCreateFile = async (values) => {
    this.setState({ uploading: true });

    const promises = values.map((value) => {
      const data = new FormData();

      data.append('file', value.file);
      data.append('program_id', this.props.match.params.courseId);
      data.append('parent_id', value.parentId);
      data.append('type', 'file');
      data.append('name', value.file.name);

      return AssetService.create(data);
    });

    const responses = await Promise.all(promises);

    this.setState({ uploading: false });

    responses.forEach((response) => {
      showSuccessErrorAlert({
        isOk: response.ok,
        successMessage: <Trans>Upload Successful.</Trans>,
      });
    });

    return responses;
  };

  onUpdateAsset = async (id, payload) => {
    const response = await AssetService.update(id, payload);
    return response;
  };

  onDeleteAsset = async (id) => {
    const response = await AssetService.delete(id);
    return response;
  };

  permissions = (course) => [
    { scope: 'superadmin' },
    {
      scope: 'manage-course',
      subj: [course],
    },
  ];

  renderFileButton = () => {
    return (
      <div className="program-assets__add-file">
        <Icon size={20} name="add-file" color="inherit" />
        {this.state.uploading ? <Loader size={17} color="inherit" /> : <Trans>Add File</Trans>}
      </div>
    );
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <AppLayout
            center={
              <CourseLayout courseId={this.props.courseId} viewer={this.props.viewer} history={this.props.history}>
                {(course) => (
                  <ShadowBox>
                    <div className="program-assets">
                      <AssetManager
                        viewer={this.props.viewer}
                        permissions={this.permissions(course)}
                        onRequestListing={this.requestListing}
                        onCreateFolder={this.onCreateFolder}
                        onDeleteAsset={this.onDeleteAsset}
                        onCreateFile={this.onCreateFile}
                        onUpdateAsset={this.onUpdateAsset}
                        renderHeader={(props) => (
                          <ShadowBoxHeader
                            title={
                              <Box
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-between"
                                style={{ width: '100%' }}
                              >
                                <Text
                                  color={theme.color.lightGray}
                                  size="12px"
                                  weight="600"
                                  lineHeight="14px"
                                  style={{ textTransform: 'uppercase' }}
                                >
                                  {props.tree.length > 0 ? (
                                    <div
                                      onClick={() => props.changeFolder()}
                                      style={{ cursor: 'pointer' }}
                                      data-test-id="btn_go-back"
                                    >
                                      <Trans>Go Back</Trans>
                                    </div>
                                  ) : (
                                    <Trans>All Folders</Trans>
                                  )}
                                </Text>
                                {withPermissions(
                                  this.permissions(course),
                                  <Box flexDirection="row" alignItems="center">
                                    <button
                                      onClick={props.createFolder}
                                      className="program-assets__create"
                                      data-test-id="btn_create-folder"
                                    >
                                      <Icon size={20} name="file-empty" color="inherit" />
                                      <Trans>Create Folder</Trans>
                                    </button>

                                    <AttachmentMenu
                                      active
                                      onChange={props.createFile}
                                      onUpload={(uploading) => {}}
                                      AttachmentTriggerComponent={() => this.renderFileButton()}
                                      disableUpload
                                      justifyContent="center"
                                    />
                                  </Box>,
                                  null,
                                )}
                              </Box>
                            }
                          />
                        )}
                      />
                    </div>
                  </ShadowBox>
                )}
              </CourseLayout>
            }
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  courseId: props.match.params.courseId,
  viewer: select.session.user(state),
});

export const PageCourseAssets = connect(mapState)(Renderer);
