import { generateQueryKey } from 'query';

export * from './mutations';
export * from './useChangeRequestsQuery';
export * from './useOnboardingTourStepsQuery';

const featureKey = 'onboarding';

export const getChangeRequestsQueryKey = (id: string) => generateQueryKey([featureKey, 'change-requests', id]); // TODO MR to replace with underscores

export const getOnboardingTourStepsQueryKey = () => generateQueryKey([featureKey, 'tour', 'steps']);
