import { CrownOutlined, ShoppingOutlined } from '@ant-design/icons';
import cs from 'classnames';
import { type ReactNode } from 'react';

type BadgeType = 'default' | 'overall-no-icon' | 'overall' | 'shop-no-icon' | 'shop';
type P = {
  icon?: ReactNode;
  type: BadgeType;
  children?: ReactNode;
};

const PillBadge = ({ icon, type, children }: P) => {
  const renderIcon = (type: BadgeType) => {
    switch (type) {
      case 'overall':
        return <CrownOutlined />;
      case 'shop':
        return <ShoppingOutlined />;
      default:
        return icon;
    }
  };

  return (
    <div
      className={cs('pill-badge', {
        'pill-badge--overall': ['overall', 'overall-no-icon'].includes(type),
        'pill-badge--shop': ['shop', 'shop-no-icon'].includes(type),
      })}
    >
      {renderIcon(type)}
      {children === null || children === undefined ? null : <span className="pill-badge__body">{children}</span>}
    </div>
  );
};

export default PillBadge;
