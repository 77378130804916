import { type ReduxUser } from 'models';
import { GreetingsIcon } from './GreetingsIcon';
import { GreetingsTitle } from './GreetingsTitle';
import { useGreetingsScope } from './hooks';

type P = {
  isCollapsed: boolean;
  viewer: ReduxUser;
};

export type GreetingsScope = 'afternoon' | 'evening' | 'morning';

const Greetings = ({ isCollapsed, viewer }: P) => {
  const scope = useGreetingsScope();

  return (
    <div className="greetings">
      <GreetingsIcon scope={scope} className="greetings__image" />
      {isCollapsed ? null : <GreetingsTitle scope={scope} className="greetings__title" viewer={viewer} />}
    </div>
  );
};

export default Greetings;
