import { RightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Row, Col, Title, Button, Text } from 'ui';
import { type Consent, type ConsentOption } from '../../../types';

type P = {
  value?: Consent;
  onChange?: (data: Consent) => void;
  onSelect: (option: ConsentOption) => void;
};

const ConsentsFormItem = ({ onSelect, value, onChange }: P) => {
  const { t } = useTranslation();

  return (
    <Row
      item={{
        className: 'consents-form-item',
        align: 'middle',
      }}
    >
      <Col item={{ flex: 1 }}>
        <Title level={5} className="consents-form-item__title">
          {value?.name}
        </Title>
      </Col>
      <Col>
        <Button
          type="link"
          onClick={() => {
            if (value && onChange) {
              onSelect({ value, onChange });
            }
          }}
          size="large"
        >
          {value?.consented ? (
            <Text type="success">
              {t('Confirmed')} <CheckCircleOutlined />
            </Text>
          ) : (
            <>
              {t('Confirm')} <RightOutlined />
            </>
          )}
        </Button>
      </Col>
    </Row>
  );
};

export default ConsentsFormItem;
