import * as React from 'react';
import { Formik } from 'formik';
import { Trans } from 'react-i18next';

import { ThemeConsumer } from '../theme';
import { TextField } from '../form';
import { Box } from '../box';
import { Button } from '../button';
import { validator } from '../../utils/validator';
import { withTranslation } from 'react-i18next';

export class Renderer extends React.Component {
  render() {
    return (
      <Formik
        validate={(values, props) => {
          return validator.validateAll(values, {
            reason: 'required|min:3',
          });
        }}
        initialValues={{ reason: '' }}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true);
          this.props.onSubmit(values);
          formik.setSubmitting(false);
        }}
      >
        {(formik) => (
          <ThemeConsumer>
            {(theme) => (
              <React.Fragment>
                <TextField
                  name="reason"
                  placeholder={this.props.t('Why do you want to join this community?')}
                  label={<Trans>Why do you want to join this community?</Trans>}
                />

                <Box justifyContent="center" alignItems="center">
                  <Button
                    size="lg"
                    title={<Trans>Send</Trans>}
                    type="submit"
                    color={theme.color.green}
                    onClick={() => {
                      formik.submitForm();
                    }}
                    textColor="white"
                    disabled={!formik.isValid || formik.isSubmitting}
                  />
                </Box>
              </React.Fragment>
            )}
          </ThemeConsumer>
        )}
      </Formik>
    );
  }
}

export const JoinCommunityQuestionForm = withTranslation()(Renderer);
