import cs from 'classnames';
import { type RailsId } from 'models';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { Avatar, Col, PillBadge, Row, Skeleton, Text } from 'ui';
import { useUserQuery } from '../../queries/useUserQuery';

type P = {
  userId: RailsId;
  points: number;
  position: number;
  currentUser: boolean;
};

const LeaderboardTableItem = ({ userId, points, position, currentUser }: P) => {
  const { t } = useTranslation();
  const { isLoading, error, data } = useUserQuery(userId);

  return (
    <div
      className={cs('leaderboard-table-item', {
        'leaderboard-table-item--current-user': currentUser,
      })}
    >
      {isLoading ? (
        <Skeleton avatar paragraph={{ rows: 0 }} active />
      ) : (
        <Row item={{ gutter: 8, align: 'middle', className: 'leaderboard-table-item__row' }}>
          <Col item={{ className: 'leaderboard-table-item__standings' }}>
            <Text weight="bold">{position}</Text>
          </Col>
          <Col>
            <Avatar photo={data?.photo ?? images.default.userProfilePhoto}>{data?.fullName}</Avatar>
          </Col>
          <Col>{error ? t('Could not load user') : data.fullName}</Col>
          <Col item={{ className: 'leaderboard-table-item__score' }}>
            <PillBadge type="overall">{points}</PillBadge>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LeaderboardTableItem;
