import { useState } from 'react';
import { RateEventFeedbackModal } from './RateEventFeedback';

export type EventFeedbackProperties = {
  feedback: true;
  eventId: string;
  onFinish: () => void;
};
export type NoEventFeedbackProperties = { feedback: false };
export type EventFeedbackOptions = EventFeedbackProperties | NoEventFeedbackProperties;

type P = EventFeedbackOptions;

const EventFeedback = (props: P) => {
  const { feedback } = props;
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(true);

  if (!feedback) {
    return null;
  }

  const { eventId, onFinish } = props;

  const closeFeedback = () => {
    setIsFeedbackOpen(false);
    onFinish();
  };

  return (
    <RateEventFeedbackModal
      isOpened={isFeedbackOpen}
      eventId={eventId}
      onSuccess={closeFeedback}
      onCancel={closeFeedback}
    />
  );
};

export default EventFeedback;
