import { format, parseISO, isDate, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ShadowBox } from '../shadow-box';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';

function Wrapper(props) {
  const Component = props.shadow
    ? ShadowBox
    : ({ radius, className, ...bag }) => (
        <div
          {...bag}
          className={className}
          style={{ border: '1px solid #cfd8dc', borderRadius: props.radius || 3, ...props.style }}
        />
      );

  return <Component {...props} className={props.className} style={{ textAlign: 'center' }} />;
}

function EventAvatarXS(props) {
  const { t } = useTranslation();
  const dateToApply = props.date instanceof Date ? props.date : parseISO(props.date);
  const isValidDate = isDate(dateToApply) && isValid(dateToApply);

  return (
    <ThemeConsumer>
      {(theme) => (
        <Wrapper
          shadow={props.shadow}
          radius={props.radius}
          style={{ paddingTop: 5, paddingBottom: 5, width: 28, textAlign: 'center' }}
        >
          <time>
            <Text color={theme.color.lightGray} size="13px" lineHeight="1.15" weight="bold" component="p">
              {isValidDate ? format(dateToApply, 'd') : ''}
            </Text>
            <Text color={theme.color.lightGray} size="7px" lineHeight="1.2" weight="600" component="p">
              {`${t('dateFormatShortMonth', { date: dateToApply })} '${isValidDate ? format(dateToApply, 'YY') : ''}`}
            </Text>
          </time>
        </Wrapper>
      )}
    </ThemeConsumer>
  );
}

function EventAvatarMD(props) {
  const { t } = useTranslation();
  const dateToApply = props.date instanceof Date ? props.date : parseISO(props.date);
  const isValidDate = isDate(dateToApply) && isValid(dateToApply);

  return (
    <ThemeConsumer>
      {(theme) => (
        <Wrapper
          shadow={props.shadow}
          radius={props.radius}
          style={{ paddingTop: 8, paddingBottom: 8, width: 50, textAlign: 'center' }}
        >
          <time>
            <Text color={theme.color.lightGray} size="20px" lineHeight="1.2" weight="600" component="p">
              {isValidDate ? format(dateToApply, 'd') : ''}
            </Text>

            <Text color={theme.color.lightGray} size="11px" lineHeight="1.2" weight="bold" component="p">
              {`${t('dateFormatShortMonth', { date: dateToApply })} '${isValidDate ? format(dateToApply, 'yy') : ''}`}
            </Text>
          </time>
        </Wrapper>
      )}
    </ThemeConsumer>
  );
}

const components = {
  md: EventAvatarMD,
  xs: EventAvatarXS,
};

export function EventAvatar(props) {
  const Component = components[props.size];
  return <Component {...props} />;
}
