import { parseISO } from 'date-fns';
import { Image } from 'models';
import { type ProgramSlimDataObject } from './ProgramSlimDataObject';

type Sponsor = {
  price?: string;
};

// TODO this belongs to global models
export type ProgramSlim = {
  id: string;
  name: string;
  coverPhoto?: Image;
  startTime: Date;
  endTime: Date;
  price?: string;
  priceOption: 'contact_us' | 'free' | 'paid';
  sponsors: Sponsor[];
};

export const getProgramSlimData = (data: ProgramSlimDataObject): ProgramSlim => ({
  id: data.id,
  name: data.name,
  coverPhoto: data.cover_photo ? new Image(data.cover_photo) : undefined,
  startTime: parseISO(data.start_time),
  endTime: parseISO(data.end_time),
  price: data.price,
  priceOption: data.price_option,
  sponsors: data.sponsors.map((sponsor) => ({
    price: sponsor.price,
  })),
});
