import { useEffect, FC } from 'react';

import { DataState } from '../recoil/queries';
import { useCreateSocketIO } from '.';

type P = {
  currentLoginData: DataState<string>;
};

export const SocketConnection: FC<P> = ({ currentLoginData }) => {
  const token = currentLoginData.error ? undefined : currentLoginData.result;
  useCreateSocketIO({ token });

  return null;
};
