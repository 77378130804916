import { format, isToday, parseISO, isDate, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';

export function EventAvatar(props) {
  const { t } = useTranslation();
  const dateToApply = props.date instanceof Date ? props.date : parseISO(props.date);
  const isValidDate = isDate(dateToApply) && isValid(dateToApply);
  const isTodayBoolean = isToday(dateToApply);

  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          style={{
            textAlign: 'center',
            paddingTop: 8,
            paddingBottom: 8,
            flex: '0 0 50px',
            border: `1px solid ${isTodayBoolean ? 'transparent' : '#cfd8dc'}`,
            backgroundColor: isTodayBoolean ? theme.color.red : 'transparent',
            borderRadius: props.radius || 3,
            minWidth: 50,
          }}
        >
          <time>
            <Text
              color={isTodayBoolean ? theme.color.white : theme.color.lightGray}
              size="20px"
              lineHeight="1.2"
              weight="600"
              component="p"
            >
              {isValidDate ? format(dateToApply, 'd') : ''}
            </Text>

            <Text
              color={isTodayBoolean ? theme.color.white : theme.color.lightGray}
              size="11px"
              lineHeight="1.2"
              weight="bold"
              component="p"
            >
              {`${t('dateFormatShortMonth', { date: new Date(props.date) })} '${isValidDate ? format(dateToApply, 'yy') : ''}`}
            </Text>
          </time>
        </div>
      )}
    </ThemeConsumer>
  );
}
