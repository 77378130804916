import { type ReduxUser } from 'models';
import { Modal } from 'ui';
import { PersonalInfoContent } from './PersonalInfoContent';

type P = {
  isOpened: boolean;
  viewer: ReduxUser;
  onCancel: () => void;
  onSuccess: () => void;
};

const TelehealthRequestPersonalInfoModal = ({ isOpened, onCancel, onSuccess, viewer }: P) => {
  return (
    <Modal title="Confirm your information" isOpened={isOpened} onCancel={onCancel} disableBack destroyOnClose>
      <PersonalInfoContent viewer={viewer} onCancel={onCancel} onSuccess={onSuccess} />
    </Modal>
  );
};

export default TelehealthRequestPersonalInfoModal;
