import { type ContentObjectRedux, type ReduxUser } from 'models';
import { canSeeTags } from 'permissions';
import { Card, TagList } from 'ui';

type P = {
  event: ContentObjectRedux;
  viewer: ReduxUser;
};

const EventAbout = ({ event, viewer }: P) => {
  if (
    !event.tags ||
    event.tags.length === 0 ||
    !canSeeTags({ viewer, organizationId: event.post_in_communities[0].organization_id })
  ) {
    return null;
  }

  return (
    <Card title="About">
      <TagList tags={event.tags} />
    </Card>
  );
};

export default EventAbout;
