import { useMutation } from 'query';
import { default as ehrPortalRedirect } from '../../ehrPortalRedirect';

type Variables = {
  referrer: string;
};

export const useEhrPortalRedirect = () =>
  useMutation<undefined, Variables>(async ({ referrer }: Variables) => {
    await ehrPortalRedirect(referrer);
    return undefined;
  });
