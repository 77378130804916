import * as React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '../../box';
import { Text } from '../../text';
import { Avatar } from 'ui';
import { ThemeConsumer } from '../../theme';
import { images } from 'common/utils';

export function OrganizationItem(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box
          {...props.downshiftProps}
          flexDirection="row"
          alignItems="center"
          style={{ padding: '7px 15px', cursor: 'pointer' }}
        >
          <Box style={{ marginRight: 10 }}>
            <Avatar size={35} name={props.title} photo={props.itemProps.profile_photo ?? images.default.organizationWhiteProfilePhoto} />
          </Box>
          <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Text
              component="p"
              size="14px"
              weight="500"
              color={theme.color.gray}
              lineHeight="19px"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {props.title}
            </Text>
            <Text size="12px" weight="500" color="#7E939E" lineHeight="21px">
              {props.itemProps.joined ? <Trans>Joined</Trans> : <Trans>Not Joined</Trans>}
            </Text>
          </Box>
        </Box>
      )}
    </ThemeConsumer>
  );
}
