import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Field } from 'formik';

import { Avatar, Button, Dropdown } from 'ui';
import { Box } from '../box';
import { FieldError } from './FieldError';
import { Icon } from '../icon';
import { ImageCropperModal } from '../image-cropper';
import { Loader } from '../loader';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { Uploader } from '../uploader';
import { showAlert } from '../alert';

export const CROP_BOX_SIZES = {
  cover_photo: { w: 960, h: 540 },
  white_profile_photo: { w: 120, h: 120 },
  profile_photo: { w: 120, h: 120 },
  author_logo: { w: 512, h: 512 },
  favicon: { w: 512, h: 512 },
  logo: { w: 940, h: 230 },
  mobile_vector_logo: { w: 1024, h: 1024 },
  landing: { w: 2750, h: 1775 },
  iphone_55: { w: 1242, h: 2208 },
  iphone_65: { w: 1242, h: 2688 },
  ipad: { w: 2048, h: 2732 },
  google_graphic: { w: 1024, h: 500 },
  google_phone: { w: 1920, h: 3840 },
};

const ImageUploaderRenderer = (props) => (
  <div className="image-upload-renderer" id={'uploaded-image'}>
    {props.image ? (
      <img src={props.image.url} alt={props.image.filename} />
    ) : (
      <div className="image-upload-renderer__text">
        <Icon name="image-upload" size={28} style={{ lineHeight: '36px' }} />
        <Text size={13}>
          <Trans>Upload a photo</Trans>
        </Text>
      </div>
    )}
  </div>
);

export class Renderer extends React.Component {
  state = {
    originalFile: null,
    uploading: false,
  };

  $downloader = null;
  $uploader = null;
  $imageCropper = null;

  openImageCropperModal = () => {
    if (this.$imageCropper) {
      this.$imageCropper.open();
    }
  };

  openUploader = () => {
    if (this.$uploader) {
      this.$uploader.click();
    }
  };

  downloadFile = () => {
    if (this.$downloader) {
      this.$downloader.downloadItem();
    }
  };

  onImageCropperSubmit(fieldProps) {
    return (data) => {
      fieldProps.form.setFieldValue(fieldProps.field.name, data);
    };
  }

  onChange = (fieldProps) => {
    return (files) => {
      if (this.props.multiple) {
        fieldProps.form.setFieldValue(fieldProps.field.name, files);
      } else if (this.props.skipCropper) {
        fieldProps.form.setFieldValue(fieldProps.field.name, files[0]);
      } else {
        this.setState({ originalFile: files[0] }, this.openImageCropperModal);
      }
    };
  };

  getSrc(fieldProps) {
    return fieldProps.field.value ? fieldProps.field.value.download_url : '';
  }

  renderLabel = () => {
    return this.props.label ? (
      <ThemeConsumer>
        {(theme) => (
          <Text
            weight="600"
            size="14px"
            component="p"
            color={theme.color.lightGray}
            style={{
              marginBottom: 15,
              textAlign: this.props.labelAlign || 'left',
            }}
          >
            {this.props.label}
          </Text>
        )}
      </ThemeConsumer>
    ) : null;
  };

  renderCropperResultPreview = (fieldProps) => {
    switch (fieldProps.field.name) {
      case 'profile_photo':
      case 'white_profile_photo':
      case 'author_logo':
        return <Avatar size={115} photo={fieldProps.field.value} />;
      case 'cover_photo':
      default:
        switch (this.props.cropType) {
          case 'author_logo':
            return <Avatar size={45} photo={fieldProps.field.value} />;
          default:
            return <ImageUploaderRenderer image={fieldProps.field.value} />;
        }
    }
  };

  createMenuActions = (fieldProps) => {
    const { t, multiple, skipCropper, downloadable, name } = this.props;

    const actions = [
      {
        label: t('New'),
        key: 'new',
        onClick: this.openUploader,
      },
    ];

    if (!multiple && !skipCropper && fieldProps.field.value.filename) {
      actions.push({
        label: t('Edit'),
        key: 'edit',
        onClick: this.openImageCropperModal,
      });
    }

    if (downloadable) {
      actions.push({
        label: t('Download'),
        key: 'download',
        onClick: () => this.downloadFile(fieldProps.field.value),
      });
    }

    return actions;
  };

  validateImages = (fieldProps) => {
    const {
      imageWidth = 0,
      imageHeight = 0,
      minImageWidth = 0,
      minImageHeight = 0,
      maxImageWidth = 0,
      maxImageHeight = 0,
      dimension = null,
      aspectRatio = null,
      imageErrorMessage = <Trans>Image does not have right resolution</Trans>,
    } = this.props;

    if (
      [
        imageWidth,
        imageHeight,
        minImageWidth,
        minImageHeight,
        maxImageWidth,
        maxImageHeight,
        dimension,
        aspectRatio,
      ].every((prop) => !prop)
    ) {
      return null;
    }

    const validateImage = (width, height) => {
      let valid = true;

      if (aspectRatio && dimension) {
        valid =
          valid &&
          dimension.max >= height &&
          dimension.min <= width &&
          height / width === aspectRatio.height / aspectRatio.width;
      }

      if (imageWidth && imageHeight) {
        valid = valid && width === imageWidth && height === imageHeight;
      } else if (imageWidth) {
        valid = valid && width === imageWidth;
      } else if (imageHeight) {
        valid = valid && height === imageHeight;
      }

      if (minImageWidth && minImageHeight) {
        const requiredAspectRatio = minImageWidth / minImageHeight;
        const imageAspectRation = width / height;

        valid =
          valid && requiredAspectRatio === imageAspectRation && width >= minImageWidth && height >= minImageHeight;
      } else if (minImageWidth) {
        valid = valid && width >= minImageWidth;
      } else if (minImageHeight) {
        valid = valid && height >= minImageHeight;
      }

      if (maxImageWidth && maxImageHeight) {
        const requiredAspectRatio = maxImageWidth / maxImageHeight;
        const imageAspectRation = width / height;

        valid =
          valid && requiredAspectRatio === imageAspectRation && width <= maxImageWidth && height <= maxImageHeight;
      } else if (maxImageWidth) {
        valid = valid && width <= maxImageWidth;
      } else if (maxImageHeight) {
        valid = valid && height <= maxImageHeight;
      }

      return valid;
    };

    return async (files) => {
      const results = await Promise.all(
        files.map((file) => {
          return new Promise((resolve) => {
            const image = new Image();

            image.onload = function () {
              return resolve(validateImage(this.width, this.height));
            };

            image.onerror = function () {
              return resolve(false);
            };

            image.src = URL.createObjectURL(file);
          });
        }),
      );

      const isValid = results.every((valid) => valid);

      if (!isValid) {
        showAlert({
          type: 'error',
          message: imageErrorMessage,
        });
      }

      return isValid;
    };
  };

  render() {
    const {
      name,
      label,
      labelAlign,
      labelPosition,
      multiple,
      iconClass,
      cropType,
      disableAspectRatio,
      skipCropper,
      ...args
    } = this.props;

    return (
      <Field name={this.props.name}>
        {(fieldProps) => {
          return (
            <Box>
              {labelPosition !== 'bottom' ? this.renderLabel() : null}
              <div style={{ position: 'relative' }} id={this.props.id ? 'field_' + this.props.id : null}>
                <div onClick={this.openUploader} style={{ cursor: 'pointer' }}>
                  {this.renderCropperResultPreview(fieldProps)}
                </div>
                <div className={`image-upload-icon ${this.props.iconClass}`}>
                  {this.state.uploading ? (
                    <Loader size={24} />
                  ) : fieldProps.field.value ? (
                    <Dropdown
                      menu={{
                        items: this.createMenuActions(fieldProps),
                      }}
                      trigger={['click']}
                    >
                      <Button
                        icon={<Icon name="menu" size={22} />}
                        type="link"
                        style={{ border: 0, background: 'none', height: 40, padding: 0 }}
                        id={`upload-button-${name}`}
                      />
                    </Dropdown>
                  ) : (
                    <Icon
                      onClick={this.openUploader}
                      name="pba-attachment"
                      size={30}
                      color="#595959"
                      id={this.props.iconId ?? 'icon_attachment'}
                      style={{ display: 'block', cursor: 'pointer', marginTop: 5 }}
                    />
                  )}
                  <Uploader
                    onChange={this.onChange(fieldProps)}
                    validate={this.validateImages(fieldProps)}
                    multiple={multiple}
                    setUploading={(uploading) => {
                      this.setState({ uploading });
                    }}
                    TriggerComponent={({ uploading }) => {
                      return <div ref={(ref) => (this.$uploader = ref)} />;
                    }}
                    accept="image/*"
                    id={`input_upload_${this.props.name}`}
                  />
                </div>
              </div>
              {labelPosition === 'bottom' ? this.renderLabel() : null}
              <FieldError name={fieldProps.field.name} />
              {multiple || skipCropper ? null : (
                <ImageCropperModal
                  initialAspectRatio={
                    disableAspectRatio
                      ? null
                      : cropType
                      ? CROP_BOX_SIZES[cropType].w / CROP_BOX_SIZES[cropType].h
                      : CROP_BOX_SIZES[name].w / CROP_BOX_SIZES[name].h
                  }
                  // minCropBoxHeight={CROP_BOX_SIZES[this.props.name].h}
                  minContainerWidth={'100%'}
                  onSubmit={this.onImageCropperSubmit(fieldProps)}
                  ref={(ref) => (this.$imageCropper = ref)}
                  src={this.state.originalFile ? this.state.originalFile.download_url : this.getSrc(fieldProps)}
                  {...args}
                />
              )}
            </Box>
          );
        }}
      </Field>
    );
  }
}

export const CropperEditorField = withTranslation()(Renderer);
