import cs from 'classnames';
import { DirectMessageButton } from '../DirectMessageButton';

const IconDirectMessageButton = ({
  legacyUserId,
  viewer,
  small = false,
}: {
  legacyUserId: string;
  viewer: { id: string };
  small?: boolean;
}) => {
  return (
    <DirectMessageButton
      legacyUserId={legacyUserId}
      viewer={viewer}
      className={cs({ 'icon-direct-message-button': true, 'icon-direct-message-button--small': small })}
      type="text"
    >
      <i className="icon-pba-chat" />
    </DirectMessageButton>
  );
};

export default IconDirectMessageButton;
