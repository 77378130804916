import { Trans } from 'react-i18next';

export const notifications = [
  { value: 'newsfeed', label: <Trans>Newsfeed</Trans> },
  { value: 'events', label: <Trans>Conferences and Events</Trans> },
  { value: 'program', label: <Trans>Program</Trans> },
  { value: 'donations', label: <Trans>Donations</Trans> },
];

export const RULES = {
  default: {
    name: 'required|min:3',
    cover_photo: 'required',
    html_description: 'required|min:3',
    medical_community: 'required',
  },
  extended: {
    name: 'required|min:3',
    organization_id: 'required',
    cover_photo: 'required',
    html_description: 'required|min:3',
    medical_community: 'required',
  },
};

export const patientTypes = [
  {
    name: 'New Patient Visit',
    translations: {
      en: 'New Patient Visit',
      es: 'Visita de nuevo paciente',
    },
  },
  {
    name: 'Established Patient Visit',
    translations: {
      en: 'Established Patient Visit',
      es: 'Visita de paciente establecida',
    },
  },
];

export const serviceTypes = [
  {
    name: 'Medical visit',
    translations: {
      en: 'Medical visit',
      es: 'Visita medica',
    },
    children: [
      {
        name: 'General Medical/Episodic Care',
        translations: {
          en: 'General Medical/Episodic Care',
          es: 'Atención médica general/episódica',
        },
        children: [
          {
            name: 'Adult Medicine',
            translations: {
              en: 'Adult Medicine',
              es: 'Medicina para adultos',
            },
          },
          {
            name: 'Pediatric Medicine',
            translations: {
              en: 'Pediatric Medicine',
              es: 'Medicina Pediátrica',
            },
          },
          {
            name: `Women's Health`,
            translations: {
              en: `Women's Health`,
              es: 'La salud de la mujer',
            },
          },
          {
            name: 'Nephrology',
            translations: {
              en: 'Nephrology',
              es: 'Nefrología',
            },
          },
          {
            name: 'Cardiology',
            translations: {
              en: 'Cardiology',
              es: 'Cardiología',
            },
          },
          {
            name: 'Endocrinology',
            translations: {
              en: 'Endocrinology',
              es: 'Endocrinología',
            },
          },
          {
            name: 'Pulmonary',
            translations: {
              en: 'Pulmonary',
              es: 'Pulmonar',
            },
          },
          {
            name: 'Gastroenterology',
            translations: {
              en: 'Gastroenterology',
              es: 'Gastroenterología',
            },
          },
          {
            name: 'Infectious Disease',
            translations: {
              en: 'Infectious Disease',
              es: 'Enfermedad infecciosa',
            },
          },
          {
            name: 'Allergy and Immunology',
            translations: {
              en: 'Allergy and Immunology',
              es: 'Alergia e Inmunología',
            },
          },
          {
            name: 'Dermatology',
            translations: {
              en: 'Dermatology',
              es: 'Dermatología',
            },
          },
          {
            name: 'Rheumatology',
            translations: {
              en: 'Rheumatology',
              es: 'reumatología',
            },
          },
          {
            name: 'Neurology',
            translations: {
              en: 'Neurology',
              es: 'Neurología',
            },
          },
          {
            name: 'Ortho/Sports Medicine',
            translations: {
              en: 'Ortho/Sports Medicine',
              es: 'Ortopedia/Medicina Deportiva',
            },
          },
          {
            name: 'Occupational Medicine',
            translations: {
              en: 'Occupational Medicine',
              es: 'Medicina Ocupacional',
            },
          },
        ],
      },
      {
        name: 'HEDIS/Care Gap',
        translations: {
          en: 'HEDIS/Care Gap',
          es: 'HEDIS/brecha de atención',
        },
      },
      {
        name: 'Care Coordination',
        translations: {
          en: 'Care Coordination',
          es: 'Coordinación de Atención',
        },
      },
      {
        name: 'CCRPM',
        translations: {
          en: 'CCRPM',
          es: 'CCRPM',
        },
      },
      {
        name: 'Hospital Discharge/Transition of Care',
        translations: {
          en: 'Hospital Discharge/Transition of Care',
          es: 'Alta hospitalaria/Transición de la atención',
        },
      },
      {
        name: 'Care Manager',
        translations: {
          en: 'Care Manager',
          es: 'Gerente de cuidado',
        },
      },
      {
        name: 'Collaborative Care Management',
        translations: {
          en: 'Collaborative Care Management',
          es: 'Gestión colaborativa de la atención',
        },
      },
    ],
  },
  {
    name: 'Mental / BH visit',
    translations: {
      en: 'Mental / BH visit',
      es: 'Visita mental/BH',
    },
    children: [
      {
        name: 'Psychotherapy',
        translations: {
          en: 'Psychotherapy',
          es: 'Psicoterapia',
        },
        children: [
          {
            name: 'Individual',
            translations: {
              en: 'Individual',
              es: 'Individual',
            },
          },
          {
            name: 'Couples',
            translations: {
              en: 'Couples',
              es: 'Parejas',
            },
          },
          {
            name: 'Family',
            translations: {
              en: 'Family',
              es: 'Familia',
            },
          },
          {
            name: 'Group',
            translations: {
              en: 'Group',
              es: 'Grupo',
            },
          },
        ],
      },
      {
        name: 'Psychiatry',
        translations: {
          en: 'Psychiatry',
          es: 'Psiquiatría',
        },
      },
      {
        name: 'HEDIS/Care Gap',
        translations: {
          en: 'HEDIS/Care Gap',
          es: 'HEDIS/brecha de atención',
        },
      },
      {
        name: 'Collaborative Care Management',
        translations: {
          en: 'Collaborative Care Management',
          es: 'Gestión colaborativa de la atención',
        },
      },
      {
        name: 'Hospital Discharge/Transition of Care',
        translations: {
          en: 'Hospital Discharge/Transition of Care',
          es: 'Alta hospitalaria/Transición de la atención',
        },
      },
      {
        name: 'Care Manager',
        translations: {
          en: 'Care Manager',
          es: 'Gerente de cuidado',
        },
      },
    ],
  },
];

export const telehealthVisitDurations = [15, 20, 30, 45, 60];
