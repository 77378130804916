import { http } from './shared';

export const CommunityService = {
  get: (params) => http.get(`/communities`, params),
  find: (id, params) => http.get(`/communities/${id}`, params),
  findForPublic: (id) => http.get(`/communities/${id}/public`),
  delete: (id) => http.delete(`/communities/${id}`),
  getEdit: (id) => http.get(`/communities/${id}/edit`),
  getMembers: (id, params) => http.get(`/communities/${id}/members`, params),
  getUsers: (id, params) => http.get(`/communities/${id}/users`, params),
  create: (payload) => http.post(`/communities`, { community: payload }),
  update: (id, payload) => http.put(`/communities/${id}`, { community: payload }),
  join: (id, payload) => http.post(`/communities/${id}/join`, payload),
  joinWithCode: (code) => http.post(`/communities/join_with_code`, { code: code }),
  leave: (id) => http.delete(`/communities/${id}/join`),
  acceptRequest: (id, user_id) => http.post(`/communities/${id}/accept/${user_id}`),
  getUserCommunitySettings: (id) => http.get(`/communities/${id}/user_notifications_settings`),
  putUserCommunitySettings: (id, data) => http.put(`/communities/${id}/user_notifications_settings`, data),
  declineRequest: (id, user_id) => http.post(`/communities/${id}/decline/${user_id}`),
  removeJoinedMember: (id, memberId) => http.delete(`/communities/${id}/join`, { user_id: memberId }),
  // TODO this will be moved to new, remove from here
  inviteUsers: (params) => http.post(`/change_requests.json`, params),
  inviteNonRegistered: (id, emails) =>
    http.post(`/communities/${id}/invite`, {
      emails: emails,
    }),
  getInvitationToken: (id) => http.post(`/communities/${id}/public_invite`),
};
