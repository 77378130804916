import { useEffect } from 'react';
import { type OnboardingTourStepOption } from '../../models';

const NoSupportedStep = ({
  onChangeStep,
}: {
  onChangeStep: (options: { step: OnboardingTourStepOption; replace?: boolean }) => void;
}) => {
  useEffect(() => {
    onChangeStep({ step: 'intro', replace: true });
  }, [onChangeStep]);

  return null;
};

export default NoSupportedStep;
