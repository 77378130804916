import axios, { AxiosError } from 'axios';

import qs from 'qs';
import { localStorageTokenKey } from '../../constants';
import { normalizeErrorResponse, normalizeSuccessResponse } from './utils';

export const mediaModuleClient = axios.create({
  responseType: 'json',
  withCredentials: false,
  baseURL: String(process.env.REACT_APP_MEDIA_MODULE_URL),
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

mediaModuleClient.interceptors.response.use(
  (response) => {
    return normalizeSuccessResponse(response);
  },
  (error: AxiosError) => {
    return normalizeErrorResponse(error);
  },
);

// TODO: Refactor after Axios update (see PBA files for reference)
mediaModuleClient.interceptors.request.use((config) => {
  const headers = config.headers as Record<string, unknown>;
  headers.Authorization = `Bearer ${String(localStorage.getItem(localStorageTokenKey))}`;
  return config;
});
