import { type Form, type Rate as RateAD } from 'antd';
import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Rate } from '../Rate';
import { Item } from './Item';

type ItemP = Omit<ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
};

type InputP = ComponentProps<typeof RateAD>;

type P = {
  item: ItemP;
  input?: InputP;
};

const RateInput = ({ item: { rules, label, ...itemRest }, input }: P) => {
  const { t } = useTranslation();
  const requiredRule = rules?.find((rule) => typeof rule === 'object' && rule.required);

  let newRules = rules;

  // When clicking on a rating star and then clicking on the same star again, the star appears empty, but this status isn't evaluated as empty. Therefore, we need to implement a custom rule to check if the rating is empty.
  if (newRules && requiredRule) {
    newRules = newRules.filter((rule) => requiredRule !== rule);
    newRules.push({
      async validator(_, value) {
        // eslint-disable-next-line no-template-curly-in-string
        return value >= 1 ? Promise.resolve() : Promise.reject(new Error(t('Error: ${label} cannot be empty')));
      },
      ...requiredRule,
    });
  }

  return (
    <Item {...itemRest} rules={newRules} label={label}>
      <Rate {...input} />
    </Item>
  );
};

export default RateInput;
