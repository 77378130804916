import dot from './dot-prop';

/**
 * Simple group-by function
 */
export function groupBy(arr, key) {
  return arr.reduce(function (prev, curr) {
    const keyVal = dot.get(curr, key);
    (prev[keyVal] = prev[keyVal] || []).concat(curr);
    return prev;
  }, {});
}

/**

 */
export function groupByEntity(arr, entityName) {
  const groupMap = arr.reduce((prev, curr) => {
    const entity = dot.get(curr, entityName);
    const enitityId = entity.id;
    prev[enitityId] = prev[enitityId] || { [entityName]: entity, collection: [] };
    prev[enitityId].collection.push(curr);
    return prev;
  }, {});
  return Object.values(groupMap);
}
