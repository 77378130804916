import * as React from 'react';

import { ThemeConsumer } from '../../atoms/theme';
import { Box } from '../../atoms/box';

export function SearchBox(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div className="global-search-box" onClick={props.onClick}>
          <Box flex="1" className="global-search-box__wrapper">
            {props.children}
          </Box>
        </div>
      )}
    </ThemeConsumer>
  );
}
