import { isProgramContentObject, type ContentObjectRedux, type ReduxUser } from 'models';
import { canSeeFuturePosts } from './canSeeFuturePosts';

export const canViewRating = ({ viewer, contentObject }: { viewer: ReduxUser; contentObject?: ContentObjectRedux }) => {
  if (!contentObject) {
    return false;
  }

  if (isProgramContentObject(contentObject)) {
    return (
      Number(contentObject.evaluation_rating_average) > 0 &&
      canSeeFuturePosts({ viewer, program: contentObject.program })
    );
  }

  return false;
};
