import { apiClient, type ApiClientResponse, routes } from 'common/services';

export type UpdateNotificationSettingRequest = {
  userId: string;
  notifications: {
    badge_notifications_settings: {
      enable_notifications: boolean;
    };
    email_notifications_settings: {
      enable_notifications: boolean;
      newsfeed: boolean;
      events: boolean;
      donations: boolean;
      program: boolean;
    };
    push_notifications_settings: {
      enable_notifications: boolean;
      newsfeed: boolean;
      events: boolean;
      messaging: boolean;
      donations: boolean;
      program: boolean;
    };
  };
};

export const updateNotificationsSettings = async ({ userId, notifications }: UpdateNotificationSettingRequest) => {
  const updateUser = {
    user: {
      ...notifications,
    },
  };

  return apiClient.patch<unknown, ApiClientResponse<unknown>>(routes.user.patch(userId), updateUser);
};
