import { type ReduxUser, type Community } from 'models';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { type EventFeedbackOptions, EventFeedback, EventsListItem } from 'features/events/';
import { Card, Skeleton, Title, Row, Col, Empty, List } from 'ui';

import { useEventsQuery } from '../../queries';

type P = {
  community: Community;
  viewer: ReduxUser;
  feedbackOptions: EventFeedbackOptions;
};

const EventsListCard = ({ community, viewer, feedbackOptions }: P) => {
  const { t } = useTranslation();

  const {
    data: paginatedEvents,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents,
    isRefetching: isRefetchingEvents,
    hasNextPage: hasMoreEvents,
  } = useEventsQuery({
    communityId: community.id,
    limit: 3,
    type: 'upcoming',
  });
  const events = paginatedEvents?.pages.flat();

  return (
    <Card
      shrinked={!isLoadingEvents}
      className="events-list-card"
      title={
        <Row item={{ justify: 'space-between', align: 'middle' }}>
          <Col>
            <Title level={5} className="events-list-card__title">
              {t('Upcoming events')}
            </Title>
          </Col>
          {hasMoreEvents ? (
            <Col>
              {/* TODO somehow solve navigation logic, so destination is not written here */}
              <NavLink<unknown> to={`/communities/${community.id}/events`}>{t('See All')}</NavLink>
            </Col>
          ) : null}
        </Row>
      }
    >
      <EventFeedback {...feedbackOptions} />

      {(isLoadingEvents && isFetchingEvents) || !events ? (
        <Skeleton active paragraph={{ rows: 6 }} />
      ) : events.length > 0 ? (
        <List
          rowKey="id"
          className="events-list-card__list"
          loading={isRefetchingEvents}
          dataSource={events}
          renderItem={(event) => (
            <List.Item className="events-list-card__list__item">
              <EventsListItem community={community} data={event} viewer={viewer} showImage={false} />
            </List.Item>
          )}
        />
      ) : (
        <List
          className="events-list-card__list"
          loading={isRefetchingEvents}
          dataSource={[{ description: 'No upcoming events' }]}
          renderItem={(empty) => (
            <List.Item className="events-list-card__list__item">
              <Empty description={t(empty.description)} />
            </List.Item>
          )}
        />
      )}
    </Card>
  );
};

export default EventsListCard;
