import { ThemeConsumer } from '../atoms/theme';
import { AppLogo } from 'ui';

export function GuestLayout(props) {
  return (
    <ThemeConsumer>
      {(theme) => {
        return (
          <div className="guest-layout">
            <div className="header">
              <div className="app-layout-width-container">
                <AppLogo />
              </div>
            </div>
            <div style={{ margin: '90px auto 0' }}>
              <div className="app-layout-width-container">
                <div style={{ maxWidth: 965, margin: '0 auto' }}>{props.content}</div>
              </div>
            </div>
          </div>
        );
      }}
    </ThemeConsumer>
  );
}
