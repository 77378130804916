import { select } from '@rematch/select';
import { normalize, denormalize } from 'normalizr';

import { dispatch, getState } from '../store';
import { NotificationService } from '../services/NotificationService';
import { NotificationSchema } from './schemas';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [NotificationSchema] : NotificationSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

/**
 * Initial state
 */
const INITIAL_STATE = {
  result: [],
  badges: {
    total: 0,
  },
  timestamp: '0',
};

/**
 * Model
 */
export const model = {
  name: 'notifications',
  state: INITIAL_STATE,
  reducers: {
    setState: (state, payload) => ({ ...state, ...payload }),
    push: (state, payload) => ({ ...state, result: [...state.result, ...payload] }),
  },
  selectors: {
    badges: (state) => state.badges,
    get: (state) => denormalize(state.result, [NotificationSchema], getState().entities),
    timestamp: (state) => state.timestamp,
  },
  effects: {
    clearLocalResult() {
      this.setState({ result: [] });
    },

    async getAsync(queryParams, state) {
      const response = await NotificationService.get(queryParams);
      if (response.ok) {
        if (queryParams.cursor) {
          this.push(normalizeData(response.data));
        } else {
          this.setState({ result: normalizeData(response.data) });
        }
      }
      return response;
    },

    async readAsync(notification, state) {
      const response = await NotificationService.read(notification.id);

      if (response.ok) {
        dispatch.entities.updateEntity({
          notifications: { [notification.id]: { $merge: { status: 'read' } } },
        });

        await dispatch.notifications.getBadgesAsync({ timestamp: state.timestamp });
      }

      return response;
    },

    async readAllAsync(payload, state) {
      const response = await NotificationService.readAll();

      if (response.ok) {
        const notifications = select.notifications.get(state);

        dispatch.entities.updateEntity({
          notifications: notifications.reduce((acc, notification) => {
            acc[notification.id] = { $merge: { status: 'read' } };
            return acc;
          }, {}),
        });

        await dispatch.notifications.getBadgesAsync({ timestamp: state.timestamp });
      }

      return response;
    },

    async getBadgesAsync(payload) {
      const response = await NotificationService.counts();
      if (response.ok) {
        this.setState({
          badges: response.data,
          timestamp: payload?.timestamp,
        });
      }
      return response;
    },
  },
};
