import { type ReduxUser, type EventType, type CommunityBase, CommunityListItem } from 'models';
import { canCreateEventInCommunity, canRequestEventInCommunity } from 'permissions';

export const getCreateEventPermissions = ({ viewer, community }: { viewer: ReduxUser; community?: CommunityBase }) => {
  const joinedCommunities = viewer.joined_communities.map((community) => new CommunityListItem(community));

  const communitiesToVerify = community ? [community] : joinedCommunities;

  const allEventTypes: EventType[] = ['webinar', 'conference', 'telehealth_visit'];
  const createEventAllowedTypes = allEventTypes.filter((eventType) =>
    communitiesToVerify.some((community) => canCreateEventInCommunity({ viewer, community, eventType })),
  );

  const isCreateButtonAvailable = createEventAllowedTypes.length > 0;
  const isTelehealthButtonAvailable = communitiesToVerify.some((community) =>
    canRequestEventInCommunity({ viewer, community, eventType: 'telehealth_visit' }),
  );

  return {
    joinedCommunities,
    createEventAllowedTypes,
    isCreateButtonAvailable,
    isTelehealthButtonAvailable,
  };
};
