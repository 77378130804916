import { type ReduxUser } from 'models';
import { useTranslation } from 'react-i18next';
import { CheckboxInput, Form } from 'ui';

const NotificationCheckbox = ({
  type,
  area,
  heading,
  user,
}: {
  type: 'email' | 'push';
  area: string;
  heading: string;
  user: ReduxUser;
}) => {
  const { t } = useTranslation();
  const notificationType = `${type}NotificationsSettings`;
  const notificationTypeUserKey = `${type}_notifications_settings`;

  return (
    <Form.Item noStyle dependencies={[[notificationType, 'enableNotifications']]}>
      {({ getFieldValue }) => {
        const isDisabled =
          !getFieldValue([notificationType, 'enableNotifications']) ||
          !user[notificationTypeUserKey]?.[area]?.change_allowed;

        return (
          <CheckboxInput
            item={{
              name: [notificationType, area],
              valuePropName: isDisabled ? 'notExistingPropName' : 'checked',
            }}
            input={{
              disabled: isDisabled,
              'aria-label': t(`${type} notifications settings for`, {
                value: t(heading),
              }),
              checked: false,
            }}
          />
        );
      }}
    </Form.Item>
  );
};

export default NotificationCheckbox;
