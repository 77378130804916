import { type Price } from 'models';
import { useTranslation } from 'react-i18next';
import { ModalFormActions, Form, Radio, Alert } from 'ui';

type AmountFormData = {
  donationAmount: string;
};

type P = {
  prices: Price[];
  onFormSubmit: (amount: string) => void;
};

const StripeDonationSelectAmountForm = ({ prices, onFormSubmit }: P) => {
  const [form] = Form.useForm<AmountFormData>();
  const { t } = useTranslation();
  const onFinish = (data: AmountFormData) => {
    onFormSubmit(data.donationAmount);
  };

  return (
    <Form onFinish={onFinish} form={form} className="stripe-donation-select-amount-form">
      <Form.Item name="donationAmount" rules={[{ required: true, message: t('This field is required') }]}>
        <Radio.Group className="stripe-donation-select-amount-form__buttons">
          {prices.map((priceItem) => (
            <Radio.Button
              className="stripe-donation-select-amount-form__buttons__button"
              value={priceItem.stripeId}
              key={`stripe-donation-select-amount-${priceItem.stripeId}`}
            >
              {priceItem.amount ? `$${Number(priceItem.amount)}` : 'Custom amount'}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item noStyle dependencies={['donationAmount']}>
        {({ getFieldValue }) => {
          const isCustomPrice: boolean =
            getFieldValue('donationAmount') &&
            getFieldValue('donationAmount') === prices.find((priceItem) => priceItem.amount === null)?.stripeId;

          if (!isCustomPrice) {
            return null;
          }

          return (
            <Alert showIcon type="info" description={t('Please continue to enter your custom amount in Stripe.')} />
          );
        }}
      </Form.Item>

      <ModalFormActions
        submit={{
          children: 'Continue to billing information',
        }}
      />
    </Form>
  );
};

export default StripeDonationSelectAmountForm;
