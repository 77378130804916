import moment, { type Moment } from 'moment';
import type React from 'react';

import { DateTimeInput } from 'ui';

type ItemP = {
  name: string;
  label?: string;
  rules: any[];
};

type InputP = {
  onChange: (event: any) => void;
};

type P = {
  item: ItemP;
  input?: InputP;
};

const EventPublish: React.FC<P> = ({ item, input }) => {
  const minimum = moment();

  return (
    <DateTimeInput
      item={{
        getValueFromEvent(event: Moment) {
          if (event.isBefore(minimum)) {
            return minimum;
          }

          return event;
        },
        ...item,
      }}
      input={{
        minimum,
        format: 'MMM D, YYYY [at] h:mm A',
        showTime: { format: 'h:mm A' },
        ...input,
      }}
    />
  );
};

export default EventPublish;
