import { AppLayout } from 'old/layouts/AppLayout';
import { useParams } from 'react-router-dom';
import { ChannelsCommunities } from 'features/channels';
import { useGetPathToEntityDetail } from '../../hooks';
import { useNavigateToJoinedCommunities } from '../hooks';

const PageChannelsCommunities = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const navigateToJoinedCommunities = useNavigateToJoinedCommunities();
  const getPathToEntityDetail = useGetPathToEntityDetail();

  return (
    <AppLayout
      center={
        <ChannelsCommunities
          getPathToCommunityDetail={(id) => getPathToEntityDetail(id, { entityType: 'community' })}
          navigateToJoinedCommunities={navigateToJoinedCommunities}
          organizationId={organizationId}
        />
      }
    />
  );
};

export default PageChannelsCommunities;
