import * as React from 'react';
import { Trans } from 'react-i18next';

import { Avatar, Link } from 'ui';
import { Menu, MenuButton, MenuList, MenuItem } from '../menu';
import { Icon } from '../icon';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';
import { ChatToButton } from '../../widgets/chat-to-button';
import { images } from 'common/utils';

export class EventMemberRow extends React.Component {
  onCreateMessage = (manager) => () => {
    const user = this.props.member;
    manager.sendMessageForRemoteUsers({}, [user]);
  };

  render() {
    const { viewer, member, onDropdownSelect, showActions, onClickToMember } = this.props;
    const fullName = `${member.first_name} ${member.last_name}`;

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="event-member-row">
            {member.id ? (
              <div className="event-member-row__info-container">
                <div className="event-member-row__avatar" style={{ cursor: `${onClickToMember ? 'pointer' : 'auto'}` }}>
                  <Link to={`/members/${member.id}`} style={{ textDecoration: 'inherit' }}>
                    <Avatar name={fullName} photo={member.profile_photo ?? images.default.userProfilePhoto} size={30} />
                  </Link>
                </div>
                <div className="event-member-row__name" style={{ cursor: `${onClickToMember ? 'pointer' : 'auto'}` }}>
                  <Link
                    className="event-member-row__name__name-link"
                    to={`/members/${member.id}`}
                    style={{ textDecoration: 'inherit' }}
                  >
                    <Text
                      className="event-member-row__name__name-text"
                      color={theme.color.gray}
                      size="15px"
                      lineHeight="1.1"
                    >
                      {member.first_name} {member.last_name}
                    </Text>
                  </Link>
                </div>

                {viewer.id !== member.id ? (
                  <div className="event-member-row__chat-to-button">
                    <ChatToButton legacyUserId={member.id} />
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="event-member-row__info-container">
                <div className="event-member-row__avatar">
                  <Avatar photo={member.profile_photo ?? images.default.userProfilePhoto} size={30} />
                </div>
                <div className="event-member-row__name">
                  <Text
                    className="event-member-row__name__name-text"
                    color={theme.color.gray}
                    size="15px"
                    lineHeight="1.1"
                  >
                    {member.email}
                  </Text>
                </div>
              </div>
            )}
            {showActions && (
              <div className="event-member-row__dropdown">
                <React.Fragment>
                  <Menu>
                    <MenuButton style={{ border: 0, background: 'none' }}>
                      <Icon name="menu" size={20} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onSelect={onDropdownSelect('call', member)}>
                        <Trans>Call</Trans> <Icon size={20} name="call-3" color="rgba(176,190,197,0.5)" />
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </React.Fragment>
              </div>
            )}
          </div>
        )}
      </ThemeConsumer>
    );
  }
}
