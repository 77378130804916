import { Empty as EmptyA, Space } from 'antd';
import cn from 'classnames';
import { type ReactNode } from 'react';
import { Text } from '../';

type DescriptionOptions = { title: string; subtitle?: string };

const isDescriptionOptions = (data: DescriptionOptions | ReactNode): data is DescriptionOptions =>
  Boolean(data && typeof data === 'object' && 'title' in data);

type P = Omit<React.ComponentProps<typeof EmptyA>, 'description'> & {
  size?: 'default' | 'small';
  description?: DescriptionOptions | ReactNode;
};

// TODO replace usage of description to `title` + `subtitle`
const Empty = ({ className, image, size = 'default', description, ...rest }: P) => {
  return (
    <EmptyA
      className={cn('empty-state', className, {
        'empty-state--small': size === 'small',
      })}
      image={
        image ? (
          <span className="empty-state__icon">{size === 'small' ? <Text size="large">{image}</Text> : image}</span>
        ) : undefined
      }
      description={
        isDescriptionOptions(description) ? (
          <Space direction="vertical" size="small">
            <Text weight="bold" size="large">
              {description.title}
            </Text>
            {description.subtitle ? <Text>{description.subtitle}</Text> : null}
          </Space>
        ) : (
          description
        )
      }
      {...rest}
    />
  );
};

export default Empty;
