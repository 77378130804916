import { http } from './shared';

/**
 * Implementation
 */
export const BraintreeService = {
  getClientToken() {
    return http.get('/braintree-client-token');
  },
};
