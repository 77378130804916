import { type ReduxUser } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';

import { Modal, Text, Title, Col, Button, Row } from 'ui';
import { useConfirmTimezone } from './queries';

const timeZoneModalShowed = 'timeZoneModalShowed';

type P = {
  viewer: ReduxUser;
  navigateToChangeTimezone: () => void;
  onConfirm: () => Promise<void> | void;
};

const TimezoneConfirmationModal = ({ viewer, navigateToChangeTimezone, onConfirm }: P) => {
  // TODO this is hack because of redux
  const [isConfirming, setIsConfirming] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation();

  const { mutate: confirmTimezone, isLoading: isConfirmLoading } = useConfirmTimezone();

  useEffect(() => {
    if (
      viewer.time_zone_changed !== null &&
      !viewer.time_zone_changed &&
      sessionStorage.getItem(timeZoneModalShowed) !== 'true'
    ) {
      setIsOpened(true);
      sessionStorage.setItem(timeZoneModalShowed, 'true');
    }
  }, [viewer]);

  if (!isOpened) {
    return null;
  }

  if (viewer.required_consents?.some((consent) => !consent.consented)) {
    sessionStorage.removeItem(timeZoneModalShowed);
    return null;
  }

  const handleConfirm = () => {
    confirmTimezone(
      {
        userId: viewer.id,
        timeZone: viewer.time_zone,
      },
      {
        async onSuccess() {
          setIsConfirming(true);
          await onConfirm();
          setIsConfirming(false);
          setIsOpened(false);
        },
      },
    );
  };

  const handleGoToProfile = () => {
    navigateToChangeTimezone();
    setIsOpened(false);
  };

  const handleClose = () => {
    if (isConfirming || isConfirmLoading) {
      return;
    }

    setIsOpened(false);
  };

  const footerButtons = (
    <Col item={{ span: 24 }}>
      <Row item={{ justify: 'end', gutter: variables.spaceXs.value }}>
        <Col>
          <Button
            key="cancel"
            disabled={isConfirmLoading || isConfirming}
            onClick={() => {
              handleGoToProfile();
            }}
            size="large"
          >
            {t('Go to your profile')}
          </Button>
        </Col>
        <Col>
          <Button
            key="confirm"
            onClick={() => {
              handleConfirm();
            }}
            type="primary"
            loading={isConfirmLoading || isConfirming}
            size="large"
          >
            {t('Confirm timezone')}
          </Button>
        </Col>
      </Row>
    </Col>
  );

  return (
    <Modal
      title="Confirm your timezone"
      isOpened={isOpened}
      onCancel={handleClose}
      disableBack
      footer={footerButtons}
      closable={false}
    >
      <Col item={{ span: 24 }}>
        <Text>{t("Please confirm, that you're currently located in the timezone displayed below.")}</Text>
        <Title level={5}>{viewer.time_zone}</Title>
        <Text>{t('You can change it in Your Profile')}</Text>
      </Col>
    </Modal>
  );
};

export default TimezoneConfirmationModal;
