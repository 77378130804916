import { ThemeConsumer } from '../theme';

export function Text(props) {
  const { color, component: Component = 'span', lineHeight, size, weight, ...htmlProps } = props;
  return (
    <ThemeConsumer>
      {(theme) => {
        const color = props.color ? props.color : theme.color.gray;
        return (
          <Component
            {...htmlProps}
            className={props.className}
            style={{
              fontSize: props.size,
              color: color,
              fontWeight: props.weight || 400,
              lineHeight: props.lineHeight,
              ...props.style,
            }}
          />
        );
      }}
    </ThemeConsumer>
  );
}
