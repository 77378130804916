import { validator } from '../../utils/validator';

const requiredFields = [
  { name: 'html_content', rule: 'required' },
  { name: 'post_in_communities', rule: 'required|min:1' },
];

export function validate(data, fields) {
  let rules = {};

  requiredFields.map((field) => {
    if (fields.includes(field.name)) {
      rules[field.name] = field.rule;
    }
    return field;
  });

  return validator.validateAll(
    data,
    rules,
    {
      required_if: 'Write something.',
      'required.post_in_communities': 'Please, select at least one community.',
    },
  );
}
