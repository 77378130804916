import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { AdminAppConfigLayout } from '../../layouts/AdminAppConfigLayout';
import { Box } from '../../atoms/box';
import { showAlert } from '../../atoms/alert';
import { AppConfigForm } from '../../atoms/app-config-form';
import { AppService } from '../../services/AppService';

export class Renderer extends React.Component {
  contentRef = React.createRef();

  state = {
    appConfigResult: null,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  init = async () => {
    await this.props.fetchOrganizations();
  };

  onCreateSuccess = (response) => {
    showAlert({ type: 'success', message: <Trans>App config was successfully created.</Trans> });
  };

  onSubmitError(response) {
    showAlert({
      type: 'error',
      message: response ? response.data.message : <Trans>Something went wrong!</Trans>,
    });
  }

  onCreateAppConfig = async (values) => {
    const result = {
      ...values,
    };

    const response = await this.props.createAppConfig(result);

    if (response.ok) {
      this.props.history.push(`/admin/app-configs/${response.data.name}/update`);
    }

    return response;
  };

  render() {
    return (
      <AdminAppConfigLayout>
        <Box>
          <div className="app-config-form-wrapper" ref={this.contentRef}>
            <React.Fragment>
              <AppConfigForm
                appConfig={this.state.appConfigResult}
                onSubmit={this.onCreateAppConfig}
                onSuccess={this.onCreateSuccess}
                currentUser={this.props.user}
                onError={this.onSubmitError}
                organizations={this.props.organizations}
              />
            </React.Fragment>
          </div>
        </Box>
      </AdminAppConfigLayout>
    );
  }
}

const mapState = (state, props) => ({
  user: select.session.user(state),
  organizations: select.adminOrganizations.get(state),
});

const mapDispatch = (dispatch, props) => ({
  createAppConfig: (payload) => AppService.create(payload),
  fetchOrganizations: () => dispatch.adminOrganizations.getAsync(),
});

export const PageAppConfigCreate = connect(mapState, mapDispatch)(Renderer);
