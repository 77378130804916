import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';
import { useTranslation } from 'react-i18next';
import { type ApiClientResponse } from 'common/services';
import { type FileAssetsDataObject } from 'models/service';
import { message } from 'ui';
import { getAssetsQueryKey } from '..';
import { uploadFile } from '../../services';

export const useUploadFile = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<
    Array<PromiseSettledResult<ApiClientResponse<FileAssetsDataObject>>>,
    { data: File[]; params: { communityId: string; parentId?: string } }
  >(
    async ({ data, params }) => {
      const promises = data.map(async (file) => {
        const fileData = new FormData();

        fileData.append('file', file);
        fileData.append('community_id', params.communityId);
        fileData.append('type', 'file');
        fileData.append('name', file.name);

        if (params.parentId) {
          fileData.append('parent_id', params.parentId);
        }

        return uploadFile(fileData);
      });

      return Promise.allSettled(promises);
    },
    {
      onSuccess(data, { data: inputData, params: { communityId, parentId } }) {
        for (const [index, result] of data.entries()) {
          // TODO change to not use ui components in mutations/queries
          if (result.status === 'fulfilled') {
            message.success(t('assetUploadSuccess', { asset: inputData[index].name }));
          } else {
            message.error(t('assetUploadFail', { asset: inputData[index].name }));
          }
        }

        queryClient.invalidateQueries(getAssetsQueryKey({ id: communityId, params: { parentId } }));
      },
    },
  );
};
