import { type ReactNode } from 'react';
import variables from 'common/styles/variables.json';
import { Col, Row, Text, Button, type ButtonProps } from 'ui';

type P = {
  icon: ReactNode;
  title: ReactNode;
  button: ButtonProps;
};

const CalendarOption = ({ icon, title, button }: P) => {
  // TODO accesibility title vs random button
  return (
    <Row item={{ align: 'middle', gutter: [variables.spaceMd.value, variables.spaceMd.value] }}>
      <Col item={{ flex: '0 0 auto' }}>{icon}</Col>
      <Col item={{ flex: 1 }}>
        <Text type="title" weight="bold">
          {title}
        </Text>
      </Col>
      <Col item={{ flex: '0 0 auto' }}>
        <Button type="primary" {...button} />
      </Col>
    </Row>
  );
};

export default CalendarOption;
