import {
  type Event,
  type InviteType,
  type EventContentObject,
  type CommunityListItem,
  type EventType,
  type ReduxUser,
} from 'models';
import { useMemo } from 'react';

import { canCreateEventInCommunity } from 'permissions';

import { type DeprecatedEventProgramProperties } from '../../../types';
import { DefaultEventForm, type DefaultEventData } from './DefaultEventForm';
import { TelehealthEventForm, type TelehealthEventFormValues, type TelehealthEventData } from './TelehealthEventForm';

export { mapInviteTypeToRole } from './EventInviteesPicker';
export { type DefaultEventData, type DefaultEventFormValues } from './DefaultEventForm';
export { type TelehealthEventFormValues, type TelehealthEventData } from './TelehealthEventForm';

type P = {
  isLoading: boolean;
  eventType: EventType;
  mode: 'create' | 'duplicate' | 'edit';
  viewer: ReduxUser;
  communities: CommunityListItem[];
  inviteType: InviteType;
  program?: DeprecatedEventProgramProperties;
  communityId?: string;
  eventContentObject?: EventContentObject;
  initialData?: Partial<TelehealthEventFormValues>;
  onInviteesTypeChanged: (type: InviteType) => void;
  onSubmit: (data: DefaultEventData | TelehealthEventData, event?: Event) => Promise<void> | void;
};

const DataStepEventForm = ({ viewer, communities, eventType, initialData, ...props }: P) => {
  const medicalCommunities = useMemo(
    () =>
      communities.filter((community) =>
        canCreateEventInCommunity({ viewer, community, eventType: 'telehealth_visit' }),
      ),
    [communities, viewer],
  );

  if (eventType === 'telehealth_visit') {
    return <TelehealthEventForm communities={medicalCommunities} initialData={initialData} {...props} />;
  }

  return <DefaultEventForm {...props} communities={communities} eventType={eventType} viewer={viewer} />;
};

export default DataStepEventForm;
