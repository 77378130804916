import { Tooltip as TooltipAD } from 'antd';
import type React from 'react';
import { useTranslation } from 'react-i18next';

type ItemP = Omit<React.ComponentProps<typeof TooltipAD>, 'css'> & {
  title?: string;
};

type P = {
  item?: ItemP;
};

const Tooltip: React.FC<P> = ({ item, children }) => {
  const { t } = useTranslation();

  return (
    <TooltipAD title={t(item?.title ?? '')} {...item}>
      {typeof children === 'string' ? t(children) : children}
    </TooltipAD>
  );
};

export default Tooltip;
