import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../../common/services';

import { ID } from '../../../common/types';
import { ChatroomMessage } from '../../../types';
import { MessageDataObject } from '../../data-objects';

export const createChatroomMessage = (chatroom: ID, data: ChatroomMessage) =>
  nodeApiClient.post<MessageDataObject, AxiosResponse<MessageDataObject>>(
    routes.chatrooms.messages.create(chatroom),
    data,
  );
