import * as React from 'react';

import { StorageService } from '../../services/StorageService';
import { DefaultTriggerComponent } from './DefaultTriggerComponent';
import { message } from '../../../ui';

export class Uploader extends React.Component {
  static defaultProps = {
    multiple: false,
    TriggerComponent: DefaultTriggerComponent,
  };

  state = {
    uploading: false,
  };

  onChange = async (event) => {
    if (this.props.setUploading) {
      this.props.setUploading(true);
    }

    let files = Array.from(event.target.files);

    if (this.props.validate) {
      const valid = await this.props.validate(files);

      if (!valid) {
        return this.props.setUploading(false);
      }
    }

    this.setState({ uploading: true });

    if (!this.props.disableUpload) {
      const functions = files.map((file) =>
        StorageService.send(file).then((response) => {
          response.ok
            ? message.success('Upload Successful.')
            : message.error('There was an error uploading the file');
          return response;
        }),
      );

      const responses = await Promise.all(functions);

      files = responses.filter((response) => response.ok).map((response) => response.data);
    }

    if (this.props.onChange && files.length > 0) {
      this.props.onChange(files);
    }

    if (this.props.disableUpload) {
      event.target.value = '';
    }

    if (this.props.setUploading) {
      this.props.setUploading(false);
    }

    this.setState({ uploading: false });
  };

  render() {
    const { TriggerComponent, UploadStateComponent, setUploading, disableUpload, validate, ...htmlInputBag } =
      this.props;
    const { uploading } = this.state;
    return (
      <React.Fragment>
        <input
          {...htmlInputBag}
          id={this.props.id}
          type={uploading ? '' : 'file'}
          onChange={this.onChange}
          style={{ visibility: 'hidden', position: 'fixed' }}
        />
        <label htmlFor={this.props.id} style={{ cursor: 'pointer' }}>
          {!uploading ? <TriggerComponent uploading={uploading} /> : null}
          {UploadStateComponent ? <UploadStateComponent uploading={uploading} /> : null}
        </label>
      </React.Fragment>
    );
  }
}
