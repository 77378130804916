import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type FileAssetsDataObject, type FolderAssetDataObject } from 'models/service';

type FilesRequest = {
  community_id?: string;
  parent_id?: string;
  limit?: number;
  offset?: number;
  sort?: 'name' | 'size' | 'updated_at';
  order?: 'asc' | 'desc';
};

export const getAssets = async (params: FilesRequest) =>
  apiClient.get<
    Array<FileAssetsDataObject | FolderAssetDataObject>,
    ApiClientResponse<Array<FileAssetsDataObject | FolderAssetDataObject>>
  >(routes.files.get, {
    params,
  });
