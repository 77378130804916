import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const GlobalSearchService = {
  get: (params) => http.get(`/global_search`, params),
  detail: (params) => http.get(`/global_search/${params.scope}`, params),
  recentSearch: () => http.get(`/recent_searches`),
  trackRecent: (params) => http.post(`/recent_searches`, { recent_search: params }),
};
