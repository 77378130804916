import * as React from 'react';

export class FloatingFieldLabel extends React.Component {
  render() {
    return (
      <div className={`floating-label ${this.props.visible ? 'floating-label__visible' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}
