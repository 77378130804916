import * as React from 'react';
import Toggle from 'react-toggle';

import { Text } from '../../text';

import 'react-toggle/style.css';
import './toggle-with-label.css';

export function ToggleWithLabel(props) {
  const { inputProps } = props;
  return (
    <label style={{ display: 'flex' }} id={props.id}>
      <Toggle
        name={props.name}
        disabled={inputProps.disabled}
        defaultChecked={inputProps.checked}
        icons={false}
        onChange={inputProps.onChange}
      />
      <span style={{ margin: 'auto', marginLeft: 10 }}>
        <Text size="17px" lineHeight="22px" weight="100">
          {props.label}
        </Text>
      </span>
    </label>
  );
}
