// TODO We need to use type from PBa not from chat-web submodule
import { type Login } from 'chat-web';
import { nodeApiClient, type NodeApiClientResponse, routes } from '../';

export type TokenLoginDataObject = {
  token: string;
};

export const nodeApiLogin = async (accessToken: string) => {
  return nodeApiClient.post<TokenLoginDataObject, NodeApiClientResponse<Login>>(routes.user.login, {
    strategy: 'pba',
    accessToken,
  });
};
