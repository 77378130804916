import { parseISO } from 'date-fns';
import { ID } from '../common/types';
import { MessageType } from '../types';

type MessagePayloadMeta = {
  mimeType?: string;
  name?: string;
  alts: string[];
  height?: number;
  width?: number;
};

export class MessagePayload {
  ref?: ID;
  type: string;
  meta: MessagePayloadMeta;

  constructor({ ref, type, meta }: { ref: ID; type: string; meta: MessagePayloadMeta }) {
    this.ref = ref;
    this.type = type;
    this.meta = meta;
  }
}

export class Message {
  _id: string;
  chatroom: string;
  author?: string;
  type: MessageType;
  text: string | null;
  createdAt: Date;
  updatedAt: Date;
  payload: MessagePayload[];
  status?: string;

  constructor({
    _id,
    chatroom,
    author,
    type,
    text,
    createdAt,
    updatedAt,
    payload,
    status,
  }: {
    _id: string;
    chatroom: string;
    author?: string;
    type: MessageType;
    text: string;
    createdAt: string;
    updatedAt: string;
    payload: MessagePayload[];
    status?: string;
  }) {
    this._id = _id;
    this.chatroom = chatroom;
    this.author = author;
    this.type = type;
    this.text = text;
    this.createdAt = parseISO(createdAt);
    this.updatedAt = parseISO(updatedAt);
    this.payload = payload;
    this.status = status;
  }
}
