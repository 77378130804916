import { useQuery } from 'query';
import { getOrganizations } from '../services';
import { type ChannelsOrganization, getChannelsOrganizationData } from '../types';
import { getChannelsOrganizationsQueryKey } from './';

export const useOrganizationsQuery = () =>
  useQuery<ChannelsOrganization[]>(
    getChannelsOrganizationsQueryKey(),

    async () => {
      const response = await getOrganizations();
      return response.data.map((organizationDataObject) => getChannelsOrganizationData(organizationDataObject));
    },
    // TODO: Solve this in better way
    { refetchOnMount: 'always' },
  );
