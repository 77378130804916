import * as React from 'react';
import { Text } from './Text';
import { ThemeConsumer } from '../theme';

export { Text };

export function H1(props) {
  return <Text component="h1" size="32px" weight="bold" style={{ marginTop: 14, marginBottom: '1em' }} {...props} />;
}

export function H2(props) {
  return (
    <Text component="h2" size="17px" weight="bold" style={{ marginTop: '25px', marginBottom: '10px' }} {...props} />
  );
}

export function H3(props) {
  return <Text component="h3" size="15px" weight="bold" style={{ marginTop: 8, marginBottom: 6 }} {...props} />;
}

export function H4(props) {
  return <Text component="h4" size="12px" weight="600" style={{ marginTop: 10, marginBottom: 9 }} {...props} />;
}

export function H5(props) {
  return <Text component="h5" size="14px" weight="500" style={{ marginTop: 9, marginBottom: 6 }} {...props} />;
}

export function Caption(props) {
  return <Text component="div" size="12px" weight="500" style={{ marginTop: 7, marginBottom: 5 }} {...props} />;
}

export function SmallContent(props) {
  return <Text size="12px" lineHeight="14px" letterSpacing="-0.5px" {...props} />;
}

export function Content(props) {
  return <Text size="14px" lineHeight="24px" letterSpacing="-0.4px" {...props} />;
}

export function BigLightContent(props) {
  return <Text size="16px" lineHeight="19px" letterSpacing="-0.2px" color="#A2ABBB" {...props} />;
}

export function BigContent(props) {
  return <Text size="16px" lineHeight="19px" letterSpacing="-0.2px" color="#263044" {...props} />;
}

export function BigBrandContent(props) {
  return (
    <ThemeConsumer>
      {(theme) => {
        return <Text size="16px" lineHeight="19px" letterSpacing="-0.2px" color={theme.color.brand} {...props} />;
      }}
    </ThemeConsumer>
  );
}

export function RedText(props) {
  return (
    <ThemeConsumer>
      {(theme) => {
        return <Text size="14px" lineHeight="18px" letterSpacing="-0.4px" color={theme.color.red} {...props} />;
      }}
    </ThemeConsumer>
  );
}

export function Paragraph(props) {
  return (
    <div style={{ marginTop: 5, marginBottom: 10, ...props.style }}>
      <Text lineHeight="1.8em" {...props} />
    </div>
  );
}
