import * as React from 'react';
import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box } from '../../atoms/box';

import { ThemeConsumer } from '../../atoms/theme';
import { Loader } from '../../atoms/loader';
import { Card } from './Card';
import { withTranslation } from 'react-i18next';

import './fade.css';

class Renderer extends React.Component {
  componentDidMount() {
    this.setState({ type: this.props.type });
  }

  onCategoryClick = async (category) => {
    const url = this.props.type === 'program' ? '/courses' : '/network';
    window.location.href = `${url}/category/${category.id}`;
    this.setState({ category: category });
  };

  renderHeader = () => {
    return (
      <ThemeConsumer>
        {(theme) => (
          <div>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <div className="header-text">{this.props.header ? this.props.header() : null}</div>
            </Box>
          </div>
        )}
      </ThemeConsumer>
    );
  };

  render() {
    const { categories, isFetching } = this.props;

    return (
      <ThemeConsumer>
        {(theme) =>
          isFetching ? (
            <Loader color={theme.color.lightGray} size={30} />
          ) : (
            <React.Fragment>
              {this.renderHeader()}
              <div>
                {this.props.loading ? <Loader color={theme.color.lightGray} size={30} /> : null}
                <TransitionGroup className="row">
                  {categories.map((category) =>
                    category &&
                    (this.props.type === 'program' ? category.programs_count : category.organizations_count) ? (
                      <CSSTransition key={category.id} timeout={400} classNames="fade">
                        <div className="col-md-6 col-sm-6 col-xs-6" id={`category-${category.id}`}>
                          <Card category={category} onClick={() => this.onCategoryClick(category)} />
                        </div>
                      </CSSTransition>
                    ) : null,
                  )}
                  {categories.map((category) =>
                    category &&
                    (this.props.type === 'program' ? !category.programs_count : !category.organizations_count) ? (
                      <CSSTransition key={category.id} timeout={400} classNames="fade">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <Card category={category} onClick={() => {}} comingSoon={true} />
                        </div>
                      </CSSTransition>
                    ) : null,
                  )}
                </TransitionGroup>
              </div>
            </React.Fragment>
          )
        }
      </ThemeConsumer>
    );
  }
}

const mapState = (state) => ({
  categories: select.categories.get(state),
  isFetching: state.loading.effects.categories.getAsync,
});

export const CategoriesCardList = withTranslation()(connect(mapState)(Renderer));
