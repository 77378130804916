import { Component } from 'react';
import { Trans } from 'react-i18next';
import { Card } from 'ui';
import { AppLayout } from './AppLayout';
import { TwoColumnsInnerLayout } from './TwoColumnsInnerLayout';
import { Navigation, NavLink } from '../atoms/navigation';

export const TABS = {
  profile: 'profile-your-profile-tab',
  settings: 'profile-settings-tab',
  help: 'profile-help-tab',
  inviteFriends: 'profile-invite-friend-tab',
};

export class UserZoneLayout extends Component {
  render() {
    return (
      <AppLayout
        center={
          <>
            <Card shrinked>
              <div className="user-zone-layout">
                <Navigation direction="horizontal" id="profile-tabs">
                  <NavLink to="/profile" id="tab-profile-user" controls={TABS.profile}>
                    <Trans>Your Profile</Trans>
                  </NavLink>
                  <NavLink to="/settings" id="tab-profile-settings" controls={TABS.settings}>
                    <Trans>Settings</Trans>
                  </NavLink>
                  <NavLink to="/help" id="tab-profile-help" controls={TABS.help}>
                    <Trans>Help</Trans>
                  </NavLink>
                  <NavLink to="/invite" id="tab-profile-invite" controls={TABS.inviteFriends}>
                    <Trans>Invite Friends</Trans>
                  </NavLink>
                </Navigation>
              </div>
            </Card>
            <TwoColumnsInnerLayout
              right={this.props.children}
              left={this.props.navigation}
              contentId={this.props.contentId}
              className="user-zone-layout__two-columns"
            />
          </>
        }
      />
    );
  }
}
