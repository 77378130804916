import { OrganizationService } from '../services/OrganizationService';

/**
 * Typings
 */

/**
 * Model
 */
export const model = {
  state: {
    organization: null,
  },
  reducers: {
    setOrganization: (state, payload) => ({ ...state, organization: payload }),
  },
  selectors: {
    getOrganization: (state) => state.organization,
  },
  effects: {
    async fetchOrganization(id) {
      const response = await OrganizationService.find(id);
      // TODO handle failed request
      this.setOrganization(response.data);
    },
  },
};
