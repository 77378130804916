import * as React from 'react';
import { connect } from 'react-redux';
import { PageCommunityAbout as NewPageCommunityAbout } from 'pages';
import { select } from '@rematch/select';

class Renderer extends React.Component {
  render() {
    return <NewPageCommunityAbout viewer={this.props.viewer} />;
  }
}

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export const PageCommunityAbout = connect(mapState)(Renderer);
