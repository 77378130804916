import { InviteType } from 'models';
import { dispatch } from 'old/store';
import { useState } from 'react';
import { AxiosErrorHandler } from 'common/error-handlers';
import { message, Modal, Skeleton } from 'ui';
import { type QuickInviteFormData, QuickInviteForm } from './components';
import { useEventQuery, usePatchEvent } from './queries';

type P = {
  eventId: string;
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void> | void;
};

export const QuickInviteEventAttendees = ({ eventId, onSuccess, onCancel, ...rest }: P) => {
  const [inviteType, setInviteType] = useState<InviteType>(InviteType.empty);
  const isInviteOpened = inviteType !== InviteType.empty;

  const {
    data: eventContentObject,
    isLoading: isLoadingEvent,
    isFetching: isFetchingEvent,
    error: eventError,
  } = useEventQuery({ eventId }, { useErrorBoundary: false });

  const { mutate: patchEvent, isLoading: isPatchingEvent } = usePatchEvent();

  const onSubmit = (data: QuickInviteFormData) => {
    patchEvent(
      { id: eventId, data },
      {
        async onSuccess(data) {
          await dispatch.events.update(data.eventContentDataObject);
          await onSuccess();
        },
        onError(error) {
          message.error(error.response?.data.message ?? 'Failed to edit event.');
        },
      },
    );
  };

  const onBackClick = () => {
    setInviteType(InviteType.empty);
  };

  const getTitleName = () => {
    if (isInviteOpened) {
      switch (inviteType) {
        case 'moderators':
          return 'Invite admins / hosts';
        case 'presenters':
          return 'Invite presenters';
        default:
          return 'Invite attendees';
      }
    }

    return 'Invite members';
  };

  return (
    <Modal
      title={getTitleName()}
      onCancel={onCancel}
      isOpened={rest.isOpened}
      maskClosable={false}
      width="large"
      disableBack={!isInviteOpened}
      closable={!isInviteOpened}
      onBackClick={onBackClick}
    >
      <AxiosErrorHandler error={eventError} actions={false}>
        {(isLoadingEvent && isFetchingEvent) || !eventContentObject ? (
          <Skeleton active paragraph={{ rows: 12 }} />
        ) : (
          <QuickInviteForm
            eventContentObject={eventContentObject}
            isUpdating={isPatchingEvent}
            onSubmit={onSubmit}
            inviteType={inviteType}
            onInviteesTypeChanged={setInviteType}
          />
        )}
      </AxiosErrorHandler>
    </Modal>
  );
};
