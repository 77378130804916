import { useMutation } from '@tanstack/react-query';
import { handleAxiosError } from 'common/error-handlers';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { deleteCurrentUser } from '../../services';

export const useRemoveUserAccountQuery = () => {
  return useMutation<void, ApiClientResponseError<DefaultResponseErrorDataObject>, { deleteReason: string }>({
    async mutationFn({ deleteReason }) {
      const response = await deleteCurrentUser(deleteReason);
      return response.data;
    },
    onError(error) {
      handleAxiosError(error, 'Failed to remove account');
    },
  });
};
