import { useQueryClient } from '@tanstack/react-query';
import { Asset } from 'models';
import { useMutation } from 'query';
import { getAssetsQueryKey } from '..';
import { renameFile } from '../../services';

export const useRenameFile = () => {
  const queryClient = useQueryClient();

  return useMutation<Asset, { id: string; name: string; params: { communityId: string; parentId?: string } }>(
    async ({ id, name }) => {
      const response = await renameFile(id, { name });

      return new Asset(response.data);
    },
    {
      onSuccess: async (data, { params: { communityId, parentId } }) =>
        queryClient.invalidateQueries(getAssetsQueryKey({ id: communityId, params: { parentId } })),
    },
  );
};
