import { type EntityOptions, type ChannelDetail } from 'models';
import { Skeleton, Title, Row, Col, Paragraph, Form, Card } from 'ui';
import { ChannelsSlider, EntityTypeRadioPills } from '../components';
import { useTextCollapseExpand } from '../hooks';
import { useChannelDetailQuery } from '../queries';
import { type EntityType } from '../types';
import { AllEntities } from './AllEntities';
import { ChannelBreadcrumb } from './ChannelBreadcrumb';

const getEntityTypes = (channel: ChannelDetail) => {
  const entityOptions: Array<'campaign' | 'community' | 'event' | 'program'> = [];

  if (channel.communityCount > 0) {
    entityOptions.push('community');
  }

  if (channel.eventCount > 0) {
    entityOptions.push('event');
  }

  if (channel.programCount > 0) {
    entityOptions.push('program');
  }

  if (channel.campaignCount > 0) {
    entityOptions.push('campaign');
  }

  return entityOptions;
};

type ChannelDetailFormData = {
  subject?: EntityType;
};

type P = {
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
  channelId: string;
};

const CustomChannelsDetail = ({ getPathToEntityDetail, channelId }: P) => {
  const [form] = Form.useForm<ChannelDetailFormData>();

  const { data: channel, isLoading: isLoadingChannel } = useChannelDetailQuery({ channelId });

  const { ellipsis, collapseExpandButton } = useTextCollapseExpand({
    text: channel?.description,
  });

  return (
    <>
      {isLoadingChannel || !channel ? (
        <Skeleton active paragraph={{ rows: 12 }} />
      ) : (
        <>
          <ChannelBreadcrumb channel={channel} />
          <Title level={2} marginTop={false}>
            {channel.name}
          </Title>
          {channel.description ? (
            <Row key={channel.description}>
              <Col item={{ span: 12 }}>
                <Paragraph ellipsis={ellipsis}>{channel.description}</Paragraph>
                {collapseExpandButton}
              </Col>
            </Row>
          ) : null}
          <ChannelsSlider
            channelId={channel.id}
            view="detail"
            getPathToEntityDetail={getPathToEntityDetail}
            title="Featured"
          />
          <Form<ChannelDetailFormData> form={form} initialValues={{ subject: undefined }}>
            <Form.Item name="subject">
              <EntityTypeRadioPills options={getEntityTypes(channel)} />
            </Form.Item>
            <Form.Item<ChannelDetailFormData> noStyle dependencies={['subject']}>
              {({ getFieldsValue }) => {
                const { subject } = getFieldsValue(true) as Partial<ChannelDetailFormData>;

                return (
                  <Card>
                    <AllEntities
                      subject={subject}
                      channelId={channelId}
                      getPathToEntityDetail={getPathToEntityDetail}
                    />
                  </Card>
                );
              }}
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

export default CustomChannelsDetail;
