import { type Entity } from 'models';
import type React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Poll from 'models/poll';
import type Program from 'models/program';
import { message, Modal } from 'ui';
import PollForm from './form/PollForm';
import api from './services/api';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: () => Promise<void>;
  program?: Program;
  poll: Poll;
};

const EditPoll: React.FC<P> = ({ onSuccess, onCancel, isOpened, program, poll }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pollData, setPollData] = useState<Poll>(new Poll(poll));
  const [communitiesData, setCommunitiesData] = useState<Entity[]>([]);

  const { t } = useTranslation();

  const fetchData = () => {
    (async () => {
      if (isOpened) {
        setLoading(true);

        setPollData(new Poll(poll));
        const communitiesResponse = await api.findMyCommunities();

        if (communitiesResponse.ok) {
          setCommunitiesData(communitiesResponse.data);
        } else {
          message.error(t('Failed to load data.'));
        }

        setLoading(false);
      }
    })();
  };

  const onSubmit = async (poll: Poll) => {
    setLoading(true);

    const pollResponse = await api.updatePoll(poll);

    if (pollResponse.ok) {
      await onSuccess();
      message.success('Poll was updated.');
    } else {
      message.error('Failed to update poll.');
    }

    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [isOpened]);

  return (
    <Modal title={'Edit Poll'} isOpened={isOpened} onCancel={onCancel} loading={loading}>
      <PollForm poll={pollData} program={program} onSubmit={onSubmit} communities={communitiesData} edit />
    </Modal>
  );
};

export default EditPoll;
