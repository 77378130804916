import { Switch, Route } from 'react-router-dom';
import AppRouter from './AppRouter';
import { AdminRouter } from './AdminRouter';
import { PreconditionFailedGuard } from 'pages';
import { TimezoneConfirmation } from 'old/atoms/timezoneConfirmation';

export function WebAppRouter() {
  return (
    <>
      <TimezoneConfirmation />
      <PreconditionFailedGuard />
      <Switch>
        <Route path="/admin" component={AdminRouter} />
        <Route path="/" component={AppRouter} />
      </Switch>
    </>
  );
}
