import * as React from 'react';
import { Avatar, Link } from 'ui';
import { Text } from '../text';
import { images } from 'common/utils';
import { DefaultImage } from 'models';

export class PostCardRenderer extends React.Component {
  render() {
    const post = this.props.object;
    const title = (post.polls?.length ? post.polls[0].question : post?.post?.text_content) ?? '';

    let object = {};
    let attachment = null;
    if (this.props.object.contentable_type === 'Program') {
      object.name = 'Program';
      attachment = null;
    } else {
      object = this.props.object.post_in_communities[0];
      attachment = this.props.object.attachments[0]?.content_type.startsWith('image')
        ? this.props.object.attachments[0]
        : object.cover_photo ?? new DefaultImage({ url: images.default.communityCoverPhoto });
    }

    return (
      <div className="post-card-renderer" onClick={() => this.props.onRequestOpenDetail(this.props.object)}>
        <div className="post-card-renderer__header">
          <div className="post-card-renderer__image">
            <img src={attachment?.url} alt="" />
          </div>
          <div className="post-card-renderer__header__name">
            <Text color="white" size="13px">
              {object.name}
            </Text>
          </div>
        </div>
        <div className="post-card-renderer__body">
          <Text size="13px">{title.substr(0, 80) + (title.length > 80 ? '...' : '')}</Text>
          <Link
            to={`/members/${this.props.object.author.id}`}
            className="post-card-renderer__body__avatar"
            onClick={(event) => event.stopPropagation()}
          >
            <Avatar size={20} photo={this.props.object.author.profile_photo ?? images.default.userProfilePhoto} />
          </Link>
        </div>
      </div>
    );
  }
}
