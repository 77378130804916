import { message } from 'ui';

export function showAlert({ type, message: messageText }) {
  switch (type) {
    case 'success':
      message.success(messageText);
      return;
    case 'error':
      message.error(messageText);
      return;
    case 'warning':
      message.warn(messageText);
      return;
    case 'info':
      message.info(messageText);
    default:
      break;
  }
}

export function showSuccessErrorAlert(props) {
  if (props.isOk && props.hideSuccessMessage) {
    return;
  }

  if (props.isOk) {
    message.success(props.successMessage);
    return;
  }

  message.error(props.errorMessage ?? 'Something went wrong, please try again later.');
}
