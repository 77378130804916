import { Icon } from '../../icon';

export function Select(props) {
  return (
    <div className={`select ${props.disabled ? 'disabled' : ''} ${props.value ? 'not-empty' : ''}`}>
      <Icon name="arrow-open-bottom-thin" color="#2BB7D9" size={12} className="select__icon" />
      <select {...props} id={`select_${props?.id}`} />
    </div>
  );
}
