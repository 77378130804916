import { User } from '../../common/models';
import { transformError, transformResponse } from '../../common/recoil/queries';
import { ID, PaginationData, PaginationOptions } from '../../common/types';
import { findMembers, FindMembersDataObject, FindMembersRequest } from '../../services';
import { isStringEmptyOrOnlyWhiteSpace } from '../../utils';

export const getMembers = async (
  { skip, limit }: PaginationOptions,
  practice: ID | null,
  users: ID[] | null = null,
  query: string | null = null,
) => {
  let params: FindMembersRequest = {
    $skip: skip,
    $limit: limit,
    $sort: {
      lastName: 1,
    },
  };

  if (users && users.length > 0) {
    params = {
      ...params,
      _id: {
        $in: users,
      },
    };
  }

  if (query && !isStringEmptyOrOnlyWhiteSpace(query)) {
    params.$search = query;
  }

  try {
    const response = await findMembers(practice, params);

    return transformResponse<FindMembersDataObject, PaginationData<User>>(
      response,
      (pagination: FindMembersDataObject) => ({
        limit: pagination.limit,
        skip: pagination.skip,
        total: pagination.total,
        data: pagination.data.map((user) => new User({ ...user })),
      }),
    );
  } catch (error) {
    return transformError(error);
  }
};
