import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type PaginatedRailsDataObjects, type CommunityListItemDataObject } from 'models/service';
import { type CommunitiesScope } from '../types';

type CommunitiesRequest = {
  scope?: CommunitiesScope;
  limit?: number;
  offset?: number;
  cursor?: string;
  featured?: boolean;
  organization_id?: string;
  view: 'table';
  search?: string;
};

export const getCommunities = async (params: CommunitiesRequest) => {
  return apiClient.get<
    PaginatedRailsDataObjects<CommunityListItemDataObject>,
    ApiClientResponse<PaginatedRailsDataObjects<CommunityListItemDataObject>>
  >(routes.community.find, {
    params,
  });
};
