import { type ReduxUser } from 'models';
import { type ComponentProps } from 'react';
import { Col, InfinityLoading, Row } from 'ui';
import { type Todo } from '../../../types';
import { TodosWidgetItem, type TodosWidgetItemType } from '../TodosWidgetItem';

type P = {
  type?: TodosWidgetItemType;
  items: Todo[];
  isFetching: boolean;
  hasMoreData?: boolean;
  fetchNextPage: () => unknown;
  rowProps?: ComponentProps<typeof Row>['item'];
  todoColProps?: ComponentProps<typeof Col>['item'];
  todoProps?: Omit<ComponentProps<typeof TodosWidgetItem>, 'todo'>;
  loadingColProps?: ComponentProps<typeof Col>['item'];
  viewer: ReduxUser;
};

const TodosWidgetList = ({
  type,
  items,
  isFetching,
  hasMoreData,
  fetchNextPage,
  rowProps,
  todoColProps,
  todoProps,
  loadingColProps,
  viewer,
}: P) => {
  return (
    <Row item={{ ...rowProps }}>
      {items.map((todo) => (
        <Col key={todo.id} item={{ span: 24, ...todoColProps }}>
          <TodosWidgetItem type={type} todo={todo} viewer={viewer} {...todoProps} />
        </Col>
      ))}
      {isFetching || hasMoreData ? (
        <Col item={{ span: 24, ...loadingColProps }}>
          <InfinityLoading isLoading={isFetching} hasMoreData={hasMoreData} onEnter={fetchNextPage} />
        </Col>
      ) : null}
    </Row>
  );
};

export default TodosWidgetList;
