import * as React from 'react';
import { Trans } from 'react-i18next';

import { Menu, MenuButton, MenuList, MenuItem } from '../../menu';
import { Icon } from '../../icon';
import { can } from '../../../utils/permissions';
import { isLive } from '../../../utils/events';
import { canReportContentObject } from 'permissions';

export class ItemActionsRenderer extends React.Component {
  actions = [
    {
      action: {
        id: 'edit',
        label: <Trans>Edit</Trans>,
        handler: () => this.props.onEditObject(this.props.item),
      },
      visible: (props) => can(this.props.course, 'Course', 'editProgramContent'),
    },
    {
      action: {
        id: 'delete',
        label: <Trans>Delete</Trans>,
        handler: () => this.props.onDeleteObject({ object: this.props.item }),
      },
      visible: (props) =>
        can(this.props.course, 'Course', 'deleteProgramContent') &&
        (this.props.item.type !== 'event' || !isLive(this.props.item.event)),
    },
    {
      action: {
        id: 'report',
        label: <Trans>Report</Trans>,
        handler: () => this.props.onReportObject(this.props.item),
      },
      visible: (props) => canReportContentObject({ viewer: this.props.viewer, contentObject: this.props.course }),
    },
  ];

  render() {
    return (
      <Menu style={{ zIndex: 100 }}>
        <MenuButton style={{ border: 0, background: 'none' }}>
          <Icon name="menu" size={20} />
        </MenuButton>
        <MenuList>
          {this.actions
            .filter((action) => (action.visible ? action.visible(this.props) : true))
            .map((data, index) => (
              <MenuItem key={data.action.id} onSelect={data.action.handler}>
                {data.action.label}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    );
  }
}
