import * as React from 'react';

import { EventEditor } from '../event-editor';

export class UpdateTimelineObject extends React.Component {
  renderEventEditor() {
    const { course, isOpenEditorModal, draft, onRequestClose, onSubmit } = this.props;

    return (
      <EventEditor
        draft={draft.event}
        editorType="update"
        isEditorOpen={isOpenEditorModal}
        onRequestClose={onRequestClose}
        onSuccess={onSubmit || onRequestClose}
        course={course}
      />
    );
  }

  renderEditor(draft) {
    switch ((draft || {}).type) {
      case 'event':
        return this.renderEventEditor();
      default:
        return null;
    }
  }

  render() {
    return <React.Fragment>{this.renderEditor(this.props.draft)}</React.Fragment>;
  }
}
