import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type ListEventDataObject } from 'models/service';

type GetEventsRequestQueryType = 'past' | 'upcoming';

type GetEventsParams = {
  community_id?: string;
  cursor?: string;
  limit?: number;
  type?: GetEventsRequestQueryType;
};

export const getEvents = async (params: GetEventsParams) =>
  apiClient.get<ListEventDataObject[], ApiClientResponse<ListEventDataObject[]>>(routes.events.find, {
    params,
  });
