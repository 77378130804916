import { type ReduxUser } from 'models';
import { connect } from 'react-redux';
import { Leaderboard } from 'features/gamification';

// TODO define proper User type
type P = {
  user: ReduxUser;
};

const PageLeaderboard = ({ user }: P) => {
  return <Leaderboard user={user} />;
};

const mapStateToProperties = (state: { session: { user: ReduxUser } }) => ({ user: state.session.user });

export default connect(mapStateToProperties)(PageLeaderboard);
