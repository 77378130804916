import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { PageCommunityFeed as NewPageCommunityFeed } from 'pages';

class Renderer extends React.Component {
  render() {
    return <NewPageCommunityFeed viewer={this.props.viewer} />;
  }
}

const mapState = (state) => ({
  viewer: select.session.user(state),
});

export const PageCommunityFeed = connect(mapState)(Renderer);
