import { type GreetingsScope } from '../Greetings';
import { AfternoonIcon, EveningIcon, MorningIcon } from './icons';

const GreetingsIcon = ({ scope, className }: { scope: GreetingsScope; className?: string }) => {
  switch (scope) {
    case 'morning':
      return <MorningIcon className={className} />;
    case 'afternoon':
      return <AfternoonIcon className={className} />;
    case 'evening':
      return <EveningIcon className={className} />;
  }
};

export default GreetingsIcon;
