import cs from 'classnames';
import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

// TODO focus and hover should be default for Link and here should be props to switch it off - not on as it is now
type P = ComponentProps<typeof RouterLink> & {
  effects?: { focus?: boolean; hover?: boolean; radius?: boolean };
};

const Link = ({ children, className, effects, ...rest }: P) => {
  const { t } = useTranslation();

  return (
    <RouterLink
      className={cs(className, 'ui-link', {
        'ui-link--focus': effects?.focus,
        'ui-link--hover': effects?.hover,
        'ui-link--radius': effects?.radius,
      })}
      {...rest}
    >
      {typeof children === 'string' ? t(children) : children}
    </RouterLink>
  );
};

export default Link;
