import cn from 'classnames';
import { Card } from 'ui';

export function TwoColumnsInnerLayout(props) {
  return (
    <Card shrinkedLeft id={props.contentId}>
      <div className={cn('two-columns-layout', props.className)}>
        <div className={'two-columns-layout__left' + (props.wide ? ' two-columns-layout__left--wide' : '')}>
          {props.left}
        </div>
        <div className="two-columns-layout__right">{props.right}</div>
      </div>
    </Card>
  );
}
