import * as React from 'react';
import { Loader } from './Loader';
import { Text } from '../text';

export class LoaderOverlay extends React.Component {
  render() {
    return (
      <div className={this.props.className} style={{ position: 'relative' }}>
        {this.props.children}
        {this.props.loading && (
          <div className="loader-overlay">
            <Loader color="white" />
            <Text size={17} color="white">
              {this.props.text}
            </Text>
          </div>
        )}
      </div>
    );
  }
}
