import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../button';
import { ThemeConsumer } from '../theme';
import { isOpenToJoin } from '../../utils/events';

export function JoinWebinarButton(props) {
  const { t } = useTranslation();

  if (!isOpenToJoin(props.event)) return null;
  return (
    <ThemeConsumer>
      {(theme) => (
        <Button
          type="button"
          color={theme.color.green}
          size="sm"
          textColor="white"
          title={t('Join')}
          block
          onClick={() => props.onClick(props.event)}
          id={props.id}
        />
      )}
    </ThemeConsumer>
  );
}
