import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const OrganizationService = {
  get: () => http.get(`/organizations`),
  getByCategory: (queryParams) => http.get(`/organizations`, queryParams),
  find: (id) => http.get(`/organizations/${id}`),
  delete: (id) => http.delete(`/organizations/${id}`),
  getEdit: (id) => http.get(`/organizations/${id}/edit`),
  join: (id) => http.post(`/organizations/${id}/join`),
  leave: (id) => http.delete(`/organizations/${id}/join`),
  getMembers: (id) => http.get(`/organizations/${id}/members`),
  update: (id, scope) => http.put(`organizations/${id}`, { organization: scope }),
  create: (scope) => http.post(`organizations`, { organization: scope }),
  getRequests: (id) => http.get(`/organizations/${id}/requests`),
  inviteNonRegistered: (id, emails) =>
    http.post(`/organizations/${id}/invite`, {
      emails: emails,
    }),
};
