import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { select } from '@rematch/select';

import { CourseFetcher } from '../smart-atoms/course-fetcher';
import { TopBarLink } from './TopBarLink';
import { Loader } from '../atoms/loader';
import { Box } from '../atoms/box/Box';
import { H1 } from '../atoms/text';
import { showSuccessErrorAlert } from '../atoms/alert';
import { CoursesService } from '../services/CoursesService';
import { Menu, MenuButton, MenuItem, MenuList } from '../atoms/menu';
import { Icon } from '../atoms/icon';
import { can } from '../utils/permissions';
import { CourseDuplicate } from '../atoms/course-duplicate';
import { Empty, message, Card } from 'ui';
import { canViewProgramMembersRedux, canReportContentObject } from 'permissions';
import { routes } from 'routes';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  state = {
    openModal: false,
  };

  editCourse(course) {
    this.props.history.push(routes.program.update(course.id));
  }

  async finishCourse(course) {
    if (await window.confirm(this.props.t('Are you sure you want to archive this program?'))) {
      const result = { id: course.id, status: 'finished' };
      const response = await this.props.updateStatus(result);
      showSuccessErrorAlert({
        isOk: response.ok,
        successMessage: <Trans>The program has been finished.</Trans>,
        errorMessage: response?.data?.message,
      });
    }
  }

  async publishCourse(course) {
    if (await window.confirm(this.props.t('Are you sure you want to publish this program?'))) {
      if (!this.props.viewer.superadmin && course.privacy === 'public') {
        message.warn('A request for approval will be created. The change will not be immediate.');
      }

      const result = { id: course.id, status: 'published' };
      const response = await this.props.updateStatus(result);
      const MESSAGE = {
        'Validation failed: Start time Program start_time must be in the future': 'startTimeError',
        'End time Program end_time must be at least (start_time + 60 minutes)': 'endTimeError',
        "Programs topics can't be blank": 'modulesError',
        'Price Program price must be greater than or equal to sum of sponsored prices': 'priceError',
      };

      showSuccessErrorAlert({
        isOk: response.ok,
        hideSuccessMessage: !this.props.viewer.superadmin && course.privacy === 'public',
        successMessage: <Trans>The program has been published.</Trans>,
        errorMessage: response.data && this.props.t(MESSAGE[response.data.message] || response.data.message),
      });
    }
  }

  async reportCourse(course) {
    if (await window.confirm(this.props.t('Are you sure you want to report this program?'))) {
      CoursesService.reportAbuse(course.id);
    }
  }

  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  redirect = (path) => {
    this.props.history.push(path);
  };

  async deleteCourse(course) {
    if (await window.confirm(this.props.t('Are you sure you want to delete this program?'))) {
      const response = await this.props.delete(course);
      showSuccessErrorAlert({
        isOk: response.ok,
        successMessage: <Trans>Program successfully deleted.</Trans>,
        errorMessage: response?.data?.message,
      });

      if (response.ok) {
        this.props.history.push(`/courses`);
      }
    }
  }

  async leaveCourse(course) {
    if (await window.confirm(this.props.t('Are you sure you want to leave this program?'))) {
      const response = await this.props.declineInvitation(course);
      showSuccessErrorAlert({
        isOk: response.ok,
        successMessage: <Trans>You've successfully left the program</Trans>,
        errorMessage: response?.data?.message,
      });

      if (response.ok) {
        this.props.history.push(`/courses`);
      }
    }
  }

  menuActions(course) {
    return [
      {
        action: {
          id: 'edit',
          icon: 'pen',
          label: <Trans>Edit</Trans>,
          handler: this.editCourse.bind(this, course),
        },
        visible: (props, course) => can(course, 'Course', 'editProgram'),
      },
      {
        action: {
          id: 'finish',
          icon: 'check',
          label: <Trans>Archive</Trans>,
          handler: this.finishCourse.bind(this, course),
        },
        visible: (props, course) => can(course, 'Course', 'finish'),
      },
      {
        action: {
          id: 'publish',
          icon: 'check',
          label: <Trans>Publish</Trans>,
          handler: this.publishCourse.bind(this, course),
        },
        disabledInfo: 'Please adjust program start time into future',
        visible: (props, course) => can(course, 'Course', 'publishable'),
        disabled: (props, course) => !can(course, 'Course', 'publish'),
      },
      {
        action: {
          id: 'duplicate',
          icon: 'plus',
          label: <Trans>Duplicate</Trans>,
          handler: this.openModal.bind(this, course),
        },
        visible: (props, course) => can(course, 'Course', 'duplicate'),
      },
      {
        action: {
          id: 'delete',
          icon: 'delete',
          label: <Trans>Delete</Trans>,
          handler: this.deleteCourse.bind(this, course),
        },
        visible: (props, course) => can(course, 'Course', 'delete'),
      },
      {
        action: {
          id: 'report',
          icon: 'notification--message',
          label: <Trans>Report</Trans>,
          handler: this.reportCourse.bind(this, course),
        },
        visible: (props, course) => canReportContentObject({ viewer: this.props.viewer, contentObject: course }),
      },
      {
        action: {
          id: 'leave',
          icon: 'other-1',
          label: <Trans>Unsubscribe</Trans>,
          handler: this.leaveCourse.bind(this, course),
        },
        visible: (props, course) =>
          can(course, 'Course', 'joinedAsUser') && course.status === 'published' && !course.mandatory,
      },
    ];
  }

  renderDropdown(course) {
    return (
      <div className="settings-container" id="topbar_settings">
        <Menu>
          <MenuButton style={{ border: 0, background: 'none' }} id="btn_course-settings">
            <Icon name="menu" size={20} />
          </MenuButton>
          <MenuList style={{ width: 180 }} data-test-id="course_settings-list">
            {this.menuActions(course)
              .filter((action) => (action.visible ? action.visible(this.props, course) : true))
              .map((data) => (
                <MenuItem
                  key={data.action.id}
                  disabled={data.disabled?.(this.props, course)}
                  disabledInfo={data.disabledInfo}
                  onSelect={data.action.handler}
                >
                  {data.action.label}
                  <Icon
                    size={15}
                    name={data.action.icon}
                    color="rgba(176,190,197,0.5)"
                    id={`item_${data.action.icon}`}
                  />
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
      </div>
    );
  }

  render() {
    return (
      <CourseFetcher
        courseId={this.props.courseId}
        renderLoading={() => {
          if (this.props.loading) {
            return <Loader />;
          }

          return <Empty description={this.props.t('loadingProgramErrorMessage')} />;
        }}
        fetchUsers={this.props.fetchUsers}
        history={this.props.history}
        user={this.props.viewer}
        renderLoaded={(data) => (
          <div className="course-layout">
            <Card shrinked>
              <div className="course-layout__image">
                <img src={data.cover_photo?.url ?? images.default.programCoverPhoto} alt={data.name} />
              </div>
              <div className="course-layout__name">
                <Box alignItems="center" style={{ width: '100%' }}>
                  <H1 className="course-layout__name__text" weight="100" color="#001E34" style={{ marginBottom: 10 }}>
                    {data.name}
                  </H1>
                </Box>
              </div>
              <div id="courseNavigation" data-test-id="course_navigation">
                <Box className="course-layout__navbar">
                  {can(data, 'Course', 'viewProgram') && (
                    <React.Fragment>
                      <TopBarLink to={`/courses/${data.id}/timeline`} id="timeline">
                        <Trans>Timeline/Newsfeed</Trans>
                      </TopBarLink>
                      {canViewProgramMembersRedux({ viewer: this.props.viewer, program: data }) && (
                        <TopBarLink to={`/courses/${data.id}/members`} id="members">
                          <Trans>Members</Trans>
                        </TopBarLink>
                      )}
                      <TopBarLink to={`/courses/${data.id}/assets`} id="files">
                        <Trans>Files</Trans>
                      </TopBarLink>
                    </React.Fragment>
                  )}
                  <TopBarLink to={`/courses/${data.id}/about`} id="about">
                    <Trans>About</Trans>
                  </TopBarLink>
                  {this.renderDropdown(data)}
                </Box>
              </div>
            </Card>
            {this.props.children(data)}
            <CourseDuplicate
              isDuplicateOpen={this.state.openModal}
              onRequestClose={this.closeModal}
              program={data}
              redirect={this.redirect}
            />
          </div>
        )}
      />
    );
  }
}

const mapState = (state) => ({
  viewer: select.session.user(state),
  loading: state.loading.effects.courses.find,
});

const mapDispatch = (dispatch) => ({
  delete: (course) => dispatch.adminCourses.delete(course),
  updateStatus: (course) => dispatch.courses.updateStatus(course),
  declineInvitation: (course) => dispatch.courses.decline(course.id),
});

export const CourseLayout = withTranslation()(connect(mapState, mapDispatch)(Renderer));
