import { type ReduxUser } from 'models';
import { TABS, UserZoneLayout } from 'old/layouts/UserZoneLayout';
import { ProfileSideNav } from 'old/pages/page-profile/ProfileSideNav';
import { message } from 'ui';
import { useEducationsLevel, useGenders, useInterests, useProfessions, useUpdateUserProfile } from '../queries';
import { ProfileForm, type ProfileFormValues, ProfileFormSkeleton } from './ProfileForm';

type P = {
  user: ReduxUser;
};

const UserProfile = ({ user }: P) => {
  const { data: genders } = useGenders();
  const { data: professions } = useProfessions();
  const { data: educationsLevel } = useEducationsLevel();
  const { data: interests } = useInterests();

  const { mutate: updateProfile, isLoading } = useUpdateUserProfile();

  const onSubmit = (values: ProfileFormValues) => {
    updateProfile(
      {
        userId: user.id,
        userData: values,
      },
      {
        onSuccess() {
          message.success('Profile has been successfully updated.');
        },
      },
    );
  };

  return (
    <UserZoneLayout navigation={<ProfileSideNav user={user} />} contentId={TABS.profile}>
      {!genders || !professions || !educationsLevel || !interests ? (
        <ProfileFormSkeleton />
      ) : (
        <ProfileForm
          user={user}
          onSubmit={onSubmit}
          genders={genders}
          professions={professions}
          educationLevels={educationsLevel}
          interests={interests}
          loading={isLoading}
        />
      )}
    </UserZoneLayout>
  );
};

export default UserProfile;
