import { Event } from 'models';
import moment from 'moment';
import { type Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { type DateTimeInputItemProperties, DateTimeInput, type FormInstance, SelectInput, Row, Col } from 'ui';

type ItemP = Omit<DateTimeInputItemProperties, 'name'> & {
  name: string;
  'data-test-id'?: string;
};

type P = {
  mode?: 'create' | 'duplicate' | 'edit';
  form: FormInstance;
  minimum: Moment;
  start: ItemP;
  duration: ItemP;
  end: ItemP;
  disabledTime?: boolean;
  disabledDuration?: boolean;
  durations?: number[];
};

const defaultOptions = [30, 60, 90, 120, 150, 180, 210, 240];

const EventTime = ({
  mode = 'create',
  minimum,
  form,
  start,
  duration,
  end,
  disabledTime,
  disabledDuration,
  durations = defaultOptions,
}: P) => {
  const { t } = useTranslation();

  const findClosesValue = (value?: number) => {
    if (value === undefined) {
      return {
        value: undefined,
      };
    }

    // eslint-disable-next-line unicorn/no-array-reduce
    const closest = durations.reduce((closestDuration, anotherDuration) => {
      const closestDurationDiff = Math.abs(closestDuration - value);
      const anotherDurationDiff = Math.abs(anotherDuration - value);

      if (closestDurationDiff === anotherDurationDiff) {
        return closestDuration > anotherDuration ? closestDuration : anotherDuration;
      }

      return anotherDurationDiff < closestDurationDiff ? anotherDuration : closestDuration;
    }, durations[0]);

    return {
      value: closest,
    };
  };

  const formatDurationLabel = (durationInMinutes: number) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    const formattedHours = hours > 0 ? `${hours} ${hours === 1 ? t('hour') : t('hours')}` : null;
    const formattedMinutes = minutes > 0 ? `${minutes} ${minutes === 1 ? t('minute') : t('minutes')}` : null;
    return `${formattedHours ?? ''} ${formattedMinutes ?? ''}`.trim();
  };

  function generateOptions(values: number[]) {
    return values.map((durationValue) => ({
      value: Number(durationValue),
      label: formatDurationLabel(durationValue),
    }));
  }

  const updateTime = (eventStart?: Moment | null, eventDuration?: number) => {
    const currentStart = eventStart ?? (form.getFieldValue(start.name) as Moment);
    const currentDuration = eventDuration ?? (form.getFieldValue(duration.name) as number);

    form.setFieldsValue({
      [start.name]: currentStart,
      [end.name]: Event.getEndDate({ startTime: currentStart, duration: currentDuration }),
    });
  };

  return (
    <>
      <Row>
        <Col item={{ span: 13 }}>
          <DateTimeInput
            item={{
              rules:
                mode === 'edit'
                  ? undefined
                  : [
                      {
                        message: t('Error: Date and time cannot be in the past'),
                        async validator(_, value?: Moment) {
                          if (value?.isBefore(moment())) {
                            // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
                            return Promise.reject();
                          }

                          // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
                          return Promise.resolve();
                        },
                      },
                      {
                        required: true,
                      },
                    ],

              ...start,
            }}
            input={{
              minimum,
              format: 'MMM D, YYYY [at] h:mm A',
              showTime: { format: 'h:mm A' },
              'data-test-id': 'event_start-time',
              disabled: disabledTime,
              onChange(date: Moment | null) {
                updateTime(date);
              },
            }}
          />
        </Col>
        <Col item={{ span: 10, offset: 1 }}>
          <SelectInput
            item={{
              ...duration,
              rules: [{ required: true, message: t('Please select duration') }],
              getValueProps: findClosesValue,
              'data-test-id': 'event_duration',
            }}
            input={{
              options: generateOptions(durations),
              getOptionLabel: (option) => option.label,
              getOptionValue: (option) => option.value,
              onChange(value: any) {
                updateTime(null, value);
              },
              disabled: disabledDuration,
            }}
          />
        </Col>
      </Row>
      <DateTimeInput
        item={{ ...end, hidden: true }}
        input={{ format: 'MMM D, YYYY [at] h:mm A', showTime: { format: 'h:mm A' } }}
      />
    </>
  );
};

export default EventTime;
