import { Select as SelectA, type SelectProps } from 'antd';
import { type BaseOptionType, type DefaultOptionType } from 'antd/lib/select';
import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxLineIcon } from 'ui/Icons';
import { Empty } from '../Empty';

export type SelectProperties<
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> = SelectProps<ValueType, OptionType> & {
  emptyProps?: ComponentProps<typeof Empty>;
};

const Select = <ValueType, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>({
  emptyProps,
  ...props
}: SelectProperties<ValueType, OptionType>) => {
  const { t } = useTranslation();

  return (
    <SelectA<ValueType, OptionType>
      notFoundContent={<Empty image={<InboxLineIcon />} size="small" description={t('No data')} {...emptyProps} />}
      {...props}
    />
  );
};

Select.Option = SelectA.Option;

export default Select;
