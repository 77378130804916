import { InvitationToken } from 'models';
import { useQuery } from 'query';
import { getInvitationToken } from '../services';
import { getInvitationTokenKey } from '.';

export const useInvitationTokenQuery = (
  { invitationToken }: { invitationToken: string },
  options?: { useErrorBoundary?: boolean },
) => {
  return useQuery<InvitationToken>(
    getInvitationTokenKey({ invitationToken }),
    async () => {
      const { data } = await getInvitationToken(invitationToken);

      return new InvitationToken(data);
    },
    {
      useErrorBoundary: true,
      ...options,
    },
  );
};
