import { isAfter, parseISO } from 'date-fns';
import { ID } from '../common/types';
import { MessageReadText, ChatroomStatus, ChatroomType, ChatroomStats, MessageTypeForPreview } from '../types';

import { Message, Participant, getFullName } from './';
import { ParticipantDataObject } from '../services/data-objects';

export class Chatroom {
  _id: string;
  status: ChatroomStatus;
  name?: string;
  author: string;
  users: string[];
  message?: Message;
  statuses: Message[];
  createdAt: Date;
  updatedAt: Date;
  ref?: string;
  type?: ChatroomType;
  stats?: ChatroomStats;
  participants: Record<string, Participant>;

  constructor({
    _id,
    status,
    name,
    author,
    users,
    message,
    statuses,
    createdAt,
    updatedAt,
    ref,
    type,
    stats,
    participants,
  }: {
    _id: string;
    status: ChatroomStatus;
    name?: string;
    author: string;
    users: string[];
    message?: Message;
    statuses: Message[];
    createdAt: string;
    updatedAt: string;
    ref?: string;
    type?: ChatroomType;
    stats?: ChatroomStats;
    participants: Record<string, ParticipantDataObject>;
  }) {
    this._id = _id;
    this.status = status;
    this.name = name;
    this.author = author;
    this.users = users;
    this.message = message;
    this.statuses = statuses;
    this.createdAt = parseISO(createdAt);
    this.updatedAt = parseISO(updatedAt);
    this.ref = ref;
    this.type = type;
    this.stats = stats;
    this.participants = Object.entries(participants).reduce((acc, [id, participant]) => {
      acc[id] = { _id: id, ...participant };
      return acc;
    }, {} as Record<string, Participant>);
  }

  getMembersWithoutCurrentUser = (currentUserId: ID): ID[] =>
    this.users.filter((userId: ID) => userId !== currentUserId);

  isReadByUser = (currentUserId: ID) => {
    const lastStatus = this.statuses.find(
      (status) => status.author === currentUserId && status.type === 'status' && status.text === MessageReadText,
    );
    const lastMessage = this.message;

    // TODO: Local state (chatrooms) should be updated with correct status without request.
    //  Prerequisite is to add identifiers inside of the chatrooms array (TODOs from ChatroomDetail file).
    //  Currently we relly on deducing the state from properties of last sent message.

    if (lastMessage?.author === currentUserId) {
      return true;
    }

    if (lastStatus && lastMessage) {
      return isAfter(lastStatus?.updatedAt, lastMessage?.updatedAt);
    }

    return !lastMessage || lastMessage.author === currentUserId;
  };

  getName = (currentUserId: ID, users: Record<string, Participant>): string => {
    if (this.name) {
      return this.name;
    }

    const membersWithoutCurrentUser = this.getMembersWithoutCurrentUser(currentUserId);

    if (this.users.length === 2) {
      return membersWithoutCurrentUser.map((userId: ID) => `${getFullName(users[userId])}`).join(', ');
    }

    membersWithoutCurrentUser.push(currentUserId);
    return membersWithoutCurrentUser.map((userId: ID) => users[userId]?.firstName).join(', ');
  };

  getUsersForAvatars = (currentUserId: ID) => {
    return this.users.length > 2 ? this.users : this.users.filter((userItem) => userItem !== currentUserId);
  };

  getMessageTypeForPreview = (): MessageTypeForPreview => {
    if (this.message) {
      return this.message.text ? 'text' : 'attachment';
    }

    return 'empty';
  };
}
