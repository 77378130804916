import * as React from 'react';
import { Trans } from 'react-i18next';
import { Navigation, NavLink } from '../../atoms/navigation';
import { isDexcomEnabled } from 'common/validators';

export class ProfileSideNav extends React.Component {
  render() {
    return (
      <Navigation direction="vertical" compact>
        <NavLink to="/profile/personal-info" id={'nav-profile-info'}>
          <Trans>Personal Information</Trans>
        </NavLink>
        <NavLink to="/profile/language" id={'nav-language'}>
          <Trans>Language</Trans>
        </NavLink>
        <NavLink to="/profile/calendar-sync" id={'nav-calendar-sync'}>
          <Trans>Calendar Sync</Trans>
        </NavLink>
        {isDexcomEnabled() ? (
          <NavLink to="/profile/dexcom-account-sync" id={'nav-dexcom-account-sync'}>
            <Trans>Dexcom Account Sync</Trans>
          </NavLink>
        ) : null}
        {/* Temporarily disabled profile sync with withings account  */}
        {/* <NavLink to="/profile/withings-sync">
          <Trans>Withings Sync</Trans>
        </NavLink> */}
        <NavLink to="/profile/change-password" id={'nav-change-password'}>
          <Trans>Change Password</Trans>
        </NavLink>
        <NavLink to="/profile/remove-account" id={'nav-remove-account'}>
          <Trans>Remove account</Trans>
        </NavLink>
      </Navigation>
    );
  }
}
