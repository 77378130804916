import variables from 'common/styles/variables.json';
import { Col, Row, Skeleton } from 'ui';

const CalendarStepSkeleton = ({ calendars }: { calendars: number }) => {
  return (
    <Row item={{ gutter: [variables.spaceMd.value, variables.spaceMd.value] }}>
      {[...Array.from({ length: calendars }).keys()].map((calendar) => (
        <Col key={calendar} item={{ span: 24 }}>
          <Skeleton.Input block active />
        </Col>
      ))}
      <Col item={{ span: 24 }}>
        <Skeleton.Button block active />
      </Col>
    </Row>
  );
};

export default CalendarStepSkeleton;
