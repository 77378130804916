import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

export const useNavigateToChannelDetail = () => {
  const { push } = useHistory();

  return (id: string) => {
    push(routes.channels.customChannelDetail(id));
  };
};
