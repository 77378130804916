import { useQueryClient } from '@tanstack/react-query';
import { Community } from 'models';
import { useQuery } from 'query';
import { type CommunityDataObject } from 'models/service';

import { getCommunity } from '../services';
import { getCommunityQueryKey } from './';

// TODO this will be removed with CommunityProvider
export const useCommunityDataObjectQuery = (communityId: string) =>
  useQuery<CommunityDataObject>(['data-object', ...getCommunityQueryKey({ id: communityId })], async () => {
    const communityResponse = await getCommunity({ id: communityId });
    return communityResponse.data;
  });

export const useCommunityQuery = (communityId: string, invitationToken?: string) => {
  const queryClient = useQueryClient();

  return useQuery<Community>(
    getCommunityQueryKey({ id: communityId, invitationToken }),
    async () => {
      const { data } = await getCommunity({ id: communityId, invitationToken });
      return new Community(data);
    },
    invitationToken
      ? {
          refetchOnMount: 'always',
          onSuccess(data) {
            // TODO update current user data
            queryClient.setQueryData(getCommunityQueryKey({ id: communityId }), data);
            // TODO this will be removed with useCommunityDataObjectQuery
            queryClient.invalidateQueries(['data-object', ...getCommunityQueryKey({ id: communityId })]);
          },
          onError() {
            queryClient.invalidateQueries(getCommunityQueryKey({ id: communityId }));
            // TODO this will be removed with useCommunityDataObjectQuery
            queryClient.invalidateQueries(['data-object', ...getCommunityQueryKey({ id: communityId })]);
          },
        }
      : undefined,
  );
};
