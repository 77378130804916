import { PostRendererCore } from 'old/atoms/feed-object';
import { type FC, useState, useEffect } from 'react';

import { AxiosErrorHandler } from 'common/error-handlers';
import { type ContentObjectDataObjectDeprecated } from 'models/service/content-object-deprecated';
import { Loading } from 'ui';
import api from './services/api';

type P = {
  id: string;
};

export const PublicPost: FC<P> = ({ id }) => {
  const [loading, setLoading] = useState<boolean>(true);
  // TODO we cant use our intern object `Post` because this is going into components that are using Redux Data Objects
  const [postData, setPostData] = useState<ContentObjectDataObjectDeprecated>();
  const [error, setError] = useState<any>(null); // TODO this is hack, sadly response should be Error, but it isn't, so it is `any` here

  const fetchData = () => {
    (async () => {
      setLoading(true);
      const response = await api.getPost(id);

      if (response.ok) {
        setPostData(response.data);
      } else {
        setError(response);
      }

      setLoading(false);
    })();
  };

  useEffect(fetchData, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AxiosErrorHandler error={error} actions={false}>
      <PostRendererCore item={postData} isBookmark={false} isJournal={false} isPublic />
    </AxiosErrorHandler>
  );
};
