import { type Asset } from 'models';
import { useTranslation } from 'react-i18next';
import { message } from 'ui';
import { useRenameFile } from '../../../queries';
import { AssetEditor } from '../AssetEditor';

type P = {
  editingFile: Asset;
  isOpened: boolean;
  onCancel: () => void;
  communityId: string;
  parentId?: string;
};

const RenameAsset = ({ editingFile, isOpened, onCancel, communityId, parentId }: P) => {
  const { t } = useTranslation();

  const { isLoading: isLoadingRenameFile, mutate: renameFile } = useRenameFile();

  return (
    <AssetEditor
      isOpened={isOpened}
      onFinish={(values: { name: string }) => {
        renameFile(
          {
            id: editingFile.id,
            name: values.name,
            params: {
              communityId,
              parentId,
            },
          },
          {
            onSuccess() {
              message.success(t('Asset name successfully changed'));
              onCancel();
            },
          },
        );
      }}
      initialValues={{ name: editingFile.name }}
      onCancel={() => {
        onCancel();
      }}
      title="Rename file"
      inputLabel="Asset name"
      loading={isLoadingRenameFile}
    />
  );
};

export default RenameAsset;
