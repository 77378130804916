import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { withRouter } from 'react-router-dom';
import { dispatch } from '../../store';
import qs from 'qs';

import { withPermissions } from '../../atoms/auth';
import { withTranslation } from 'react-i18next';
import { showAlert } from '../../atoms/alert';
import { OrganizationModalDeprecated } from '../../atoms/organization-modal';
import { getAppConfig } from '../../atoms/app-config-gate';

export class Renderer extends React.Component {
  async fetchCommunity() {
    const response = await this.props.loadData();
    if (!response.ok) {
      showAlert({ type: 'error', message: response.data.message });
    }
  }

  componentDidMount = async () => {
    await this.fetchCommunity();
    await dispatch.session.fetchUser();
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.communityId !== prevProps.communityId) {
      await this.fetchCommunity();
      await dispatch.session.fetchUser();
    }
  };

  render() {
    if (this.props.data) {
      return withPermissions(
        [
          { scope: 'guest' },
          {
            scope: 'joined-community',
            subj: [this.props.communityId],
          },
        ],
        this.props.renderLoaded({
          data: this.props.data,
          onLeaveCommunity: this.props.leaveCommunity,
        }),
        <OrganizationModalDeprecated
          isOpen={true}
          onJoinNow={(community, payload) => this.props.joinCommunity(this.props.data, payload)}
          onRequestClose={() => this.props.history.push('/')}
          onRequestToJoin={() => {}}
          onExploring={() => {}}
          type="community"
          data={this.props.data}
        />,
      );
    } else if (this.props.loading) {
      return this.props.renderLoading();
    } else {
      return null;
    }
  }
}

const mapState = (state, props) => {
  const query = qs.parse(props.location.search.replace(/^.*\?/, ''), { ignoreQueryPrefix: true });
  const params = {
    communityId: props.communityId,
    invitation_token: query?.invitation_token,
    isForPublic: props.isForPublic,
  };
  return {
    data: select.communities.find(state, props.communityId),
    loadData: () => dispatch.communities.findAsync(params),
    joinCommunity: (community, payload) => dispatch.communities.joinAsync({ community, payload }),
    loading: state.loading.effects.communities.findAsync,
  };
};

const mapDispatch = (dispatch, props) => ({
  leaveCommunity: async (community) => {
    const brandedApp = getAppConfig().feature_flags.explore_branded_app;

    if (await window.confirm(props.t('Do you really want to leave this community?'))) {
      const response = await dispatch.communities.unjoinAsync(community);
      if (response.ok) {
        props.history.push(`/network/${brandedApp ? 'joined' : 'explore'}`);
      }
    }
  },
});

export const CommunityFetcher = withTranslation()(withRouter(connect(mapState, mapDispatch)(Renderer)));
