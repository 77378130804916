import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { MembersAutocomplete } from '../../atoms/form/inputs/MembersAutocomplete';

export class Renderer extends React.Component {
  render() {
    return (
      <div className="kudo">
        <label className="kudo__label">
          <Trans>Expressing Kudos to</Trans>:
        </label>
        <MembersAutocomplete
          scope="joined"
          disabled={this.props.disabled}
          placeholder={this.props.t('Choose member')}
          selected={null}
          onSelect={(item) => {
            if (item) {
              this.props.onSelect({ user_id: item.id });
            }
          }}
          value={this.props.value}
          communities={this.props.communities}
        />
      </div>
    );
  }
}

export const Kudo = withTranslation()(Renderer);
