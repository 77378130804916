import { type ReduxUser } from 'models';
import variables from 'common/styles/variables.json';
import { useEventActions } from 'features/events/hooks';
import { type Event } from 'features/todos/types';
import { Col, Button, Row, CheckCircleFillIcon, CloseCircleFillIcon } from 'ui';

type P = {
  data: Event;
  viewer: ReduxUser;
};

const PendingEventItem = ({ data, viewer }: P) => {
  const { onEventActionClick, isAccepting, isRejecting } = useEventActions({ data, viewer });

  return (
    <Row item={{ gutter: variables.spaceXs.value }}>
      <Col>
        <Button
          size="small"
          type="default"
          loading={isAccepting}
          onClick={(event) => {
            event.preventDefault();
            onEventActionClick('going');
          }}
          icon={<CheckCircleFillIcon type="success" />}
        >
          Going
        </Button>
      </Col>
      <Col>
        <Button
          size="small"
          type="default"
          loading={isRejecting}
          onClick={(event) => {
            event.preventDefault();
            onEventActionClick('not_going');
          }}
          icon={<CloseCircleFillIcon type="error" />}
        >
          Not going
        </Button>
      </Col>
    </Row>
  );
};

export default PendingEventItem;
