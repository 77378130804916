import { forwardRef } from 'react';

import { MenuItem as ReachMenuItem } from './ReachMenu';
export { Menu, MenuList, MenuButton, MenuLink } from './ReachMenu';

export const MenuItem = forwardRef((props, ref) => {
  return (
    <ReachMenuItem ref={ref} {...props} className={props.className}>
      <div className="reach-menu-wrapper">{props.children}</div>
    </ReachMenuItem>
  );
});
