import { type EntityOptions, getNestedEntity } from 'models';
import { CARDS_IN_ROW } from 'features/channels';
import { CompassIcon, Empty, Slider } from 'ui';
import { usePageCursor } from '../../hooks';
import { EntityCard } from '../EntityCard';
import { useFeaturedChannelEntities } from './hooks';

type P = {
  getPathToEntityDetail: (id: string, options: EntityOptions) => string;
  channelId: string;
  title?: string;
  onSeeAll?: () => void;
  view: 'detail' | 'preview';
  description?: string;
};

const ChannelsSlider = ({ getPathToEntityDetail, channelId, title, onSeeAll, view, description }: P) => {
  const [pageCursor, setPageCursor] = usePageCursor(channelId);
  // TODO useInfiniteQuery should be used at EntityList and this should be useQuery instead
  const {
    featured,
    query: {
      data: featuredChannelEntitiesData,
      isFetching: isFetchingFeatured,
      fetchNextPage: fetchNextFeatured,
      hasNextPage: hasNextFeatured,
    },
  } = useFeaturedChannelEntities({
    onSeeAll,
    channelId,
  });

  const currentFeaturedChannelEntitiesPage = featuredChannelEntitiesData?.pages[pageCursor];
  const numberOfFetchedFeaturedPages = (featuredChannelEntitiesData?.pages.length ?? 0) - 1;

  const showSeeAllButton = onSeeAll && (featuredChannelEntitiesData?.pages[0]?.length ?? 0) > 0;

  return (
    <Slider
      isLoading={isFetchingFeatured}
      items={currentFeaturedChannelEntitiesPage?.map((entity) => (
        <EntityCard
          entity={getNestedEntity(entity)}
          getPathToEntityDetail={getPathToEntityDetail}
          showTag
          showPrice
          key={`entity-${getNestedEntity(entity).id}`}
        />
      ))}
      title={title}
      queryData={{
        limit: CARDS_IN_ROW,
        hasNextPage: pageCursor === numberOfFetchedFeaturedPages ? Boolean(hasNextFeatured) : true,
        hasPreviousPage: pageCursor > 0,
      }}
      clickActions={{
        onPrevious() {
          setPageCursor((pageCursor) => Math.max(pageCursor - 1, 0));
        },
        async onNext() {
          if (pageCursor === numberOfFetchedFeaturedPages) {
            await fetchNextFeatured();
          }

          setPageCursor((pageCursor) => pageCursor + 1);
        },
        onSeeAll: showSeeAllButton ? onSeeAll : undefined,
      }}
      emptyState={
        <Empty
          image={<CompassIcon />}
          description={{
            title: view === 'preview' ? 'No channels yet' : 'This channel is empty',
            subtitle:
              view === 'preview'
                ? 'There are no channels yet or you’ve joined all the content already'
                : 'There is nothing available for this channel yet or you’ve joined all the content already',
          }}
        />
      }
      hideArrows={!featured}
      view={view}
      description={description}
    />
  );
};

export default ChannelsSlider;
