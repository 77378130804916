import { type ComponentProps } from 'react';
import { BillLineIcon } from 'ui';
import { type PostTodo } from '../../../../types';
import { BaseTodoWidgetItem } from '../BaseTodoWidgetItem';

type P = {
  type?: ComponentProps<typeof BaseTodoWidgetItem>['type'];
  todo: PostTodo;
  className?: string;
};

const PostTodoWidgetItem = ({ type, todo, className }: P) => {
  return (
    <BaseTodoWidgetItem
      type={type}
      className={className}
      path={todo.path}
      icon={<BillLineIcon />}
      title="Read post"
      description={todo.post.title}
      additionalDescription={todo.post.textContent}
      mode="by"
      time={todo.endTime}
    />
  );
};

export default PostTodoWidgetItem;
