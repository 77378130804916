import { PageHeader as PageHeaderA } from 'antd';
import classNames from 'classnames';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

type P = React.ComponentProps<typeof PageHeaderA>;

const PageHeader: FC<P> = ({ title, className, ...rest }) => {
  const { t } = useTranslation();

  return (
    <PageHeaderA
      title={typeof title === 'string' ? t(title) : title}
      className={classNames('page-header', className)}
      {...rest}
    />
  );
};

export default PageHeader;
