import { atom } from 'recoil';
import { Message } from '../../models';
import {
  defaultFailedMessageFromCreating,
  defaultMessages,
  defaultMessagesLastCreatedAt,
  defaultUnreadMessagesInChatroom,
} from '../defaults';

export const messages = atom<Message[]>({
  key: 'Messages',
  default: defaultMessages,
});

export const messagesLastCreatedAt = atom<Date | undefined>({
  key: 'MessagesLastCreatedAt',
  default: defaultMessagesLastCreatedAt,
});

export const unreadMessagesInChatroom = atom<number>({
  key: 'UnreadMessagesInChatroom',
  default: defaultUnreadMessagesInChatroom,
});

export const failedMessageFromCreating = atom<Message | undefined>({
  key: 'FailedMessageFromCreating',
  default: defaultFailedMessageFromCreating,
});
