import { BaseUser } from 'models';
import { useQuery } from 'query';
import { getUser } from '../services';
import { getUserKey } from '.';

export const useEventUserQuery = ({ userId }: { userId?: string }, options?: { useErrorBoundary?: boolean }) => {
  return useQuery<BaseUser>(
    getUserKey({ userId }),
    async () => {
      const userResponse = await getUser(userId!);

      return new BaseUser(userResponse.data);
    },
    {
      // TODO user can be changed and React Query is not notified about changes
      staleTime: 0,
      cacheTime: 0,
      enabled: Boolean(userId),
      useErrorBoundary: true,
      ...options,
    },
  );
};
