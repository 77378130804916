import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { CampaignRendererCore } from './CampaignRendererCore';
import { AutocompleteService } from '../../services/AutocompleteService';
import { ReportIcon, BookmarkIconLine } from 'ui';
import { ContentObjectHeader, ContentObjectFooter } from 'features/feed';
import { canReportContentObject } from 'permissions';
import { images } from 'common/utils';

export class Renderer extends React.Component {
  state = {
    loading: false,
  };

  onRequestMentions = async (value) => {
    const response = await AutocompleteService.getCommunityUsers({
      scope: 'communities',
      search: value,
      community_ids: this.props.item.post_in_communities.map((c) => c.id),
      limit: 5,
    });

    if (response.ok) {
      return response.data;
    }

    return [];
  };

  createActions = () => {
    const { t, isBookmark, viewer, item, onBookmarkObject, onReportObject } = this.props;

    if (isBookmark) {
      return [
        {
          label: t('Unbookmark'),
          key: 'unbookmark',
          onClick: () => onBookmarkObject(item),
          icon: <BookmarkIconLine width={16} height={16} />,
        },
      ];
    }

    const actions = [];

    if (canReportContentObject({ viewer, contentObject: item })) {
      actions.push({
        label: t('Report'),
        key: 'report',
        onClick: () => onReportObject(item),
        icon: <ReportIcon width={16} height={16} />,
      });
    }

    return actions;
  };

  render() {
    return (
      <>
        <ContentObjectHeader
          title={this.props.authorName}
          subtitle={parseISO(this.props.item.publish_at)}
          avatar={{
            url: this.props.item.author.profile_photo?.url ?? images.default.userProfilePhoto,
            alias: this.props.authorName,
          }}
          bookmark={this.props.bookmark}
          actions={this.props.hideActions ? undefined : this.createActions()}
          pill={this.props.pill}
          authorId={this.props.item.author.id}
        />
        <CampaignRendererCore
          viewer={this.props.viewer}
          item={this.props.item}
          onLikeObject={this.props.onLikeObject}
          onCreateObject={this.props.onCreateObject}
          isBookmark={this.props.isBookmark}
        />
        {!this.props.isRepost ? (
          <ContentObjectFooter
            item={this.props.item}
            viewer={this.props.viewer}
            view={this.props.isBookmark ? 'bookmark' : 'other'}
          />
        ) : null}
      </>
    );
  }
}

export const CampaignRenderer = withTranslation()(Renderer);
