import { type CampaignSlim, type CommunityListItem, type EventSlim } from 'models';
import { type ProgramSlim } from 'features/programs/types';

export const useIsChannelEmpty = (
  isFetching: boolean,
  pageCursor: number,
  channelData?: CampaignSlim[] | CommunityListItem[] | EventSlim[] | ProgramSlim[],
) => {
  return (!channelData || channelData.length === 0) && !isFetching && pageCursor === 0;
};
