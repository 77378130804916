import variables from 'common/styles/variables.json';
import { Col, Row, Skeleton } from 'ui';

const ConsentsStepSkeleton = () => {
  return (
    <Row item={{ gutter: [variables.spaceMd.value, variables.spaceMd.value] }}>
      <Col item={{ span: 24 }}>
        <Skeleton active paragraph={{ rows: 2 }} title={false} />
        <Skeleton.Input block active />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input block active />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input block active />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Input block active />
      </Col>
      <Col item={{ span: 24 }}>
        <Skeleton.Button block active />
      </Col>
    </Row>
  );
};

export default ConsentsStepSkeleton;
