import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type EmailEventInviteeAsRegisteredDataObject, type RegisteredEventInviteeDataObject } from 'models/service';

export type GetEventInviteesParams = {
  status: 'all';
  limit?: number;
};

export const getEventInvitees = async (id: string, params: GetEventInviteesParams) =>
  apiClient.get<
    Array<EmailEventInviteeAsRegisteredDataObject | RegisteredEventInviteeDataObject>,
    ApiClientResponse<Array<EmailEventInviteeAsRegisteredDataObject | RegisteredEventInviteeDataObject>>
  >(routes.events.users(id), {
    params,
  });
