import { apiClient, type ApiClientResponse, routes } from 'common/services';
import { type CommunityNotificationSettingsDataObject } from 'models/service';

export type UpdateNotificationsSettingsRequest = {
  push_notifications_settings: {
    enable_notifications?: boolean;
    newsfeed?: boolean;
    events?: boolean;
    program?: boolean;
    donations?: boolean;
  };
  email_notifications_settings: {
    enable_notifications?: boolean;
    newsfeed?: boolean;
    events?: boolean;
    program?: boolean;
    donations?: boolean;
  };
};

export const updateNotificationsSettings = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateNotificationsSettingsRequest;
}) => {
  return apiClient.put<
    CommunityNotificationSettingsDataObject,
    ApiClientResponse<CommunityNotificationSettingsDataObject>
  >(routes.community.notifications.update(id), data);
};
