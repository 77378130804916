import { denormalize, normalize, schema } from 'normalizr';
import { createModel } from '@rematch/core';
import { compareDesc, startOfMonth, lastDayOfMonth, parseISO, isDate } from 'date-fns';
import { update } from './helpers';
import { DonationsService } from '../services/DonationsService';
import { getAppConfig } from '../atoms/app-config-gate';

const DonationSchema = new schema.Entity('donations');

function denorm(state) {
  return denormalize(state.result || [], [DonationSchema], state.entities);
}

function norm(payload) {
  return normalize(payload, [DonationSchema]);
}

function compare(fn, prop) {
  return (a, b) => {
    return fn(isDate(a[prop]) ? a[prop] : parseISO(a[prop]), isDate(b[prop]) ? b[prop] : parseISO(b[prop]));
  };
}

// TODO create effect to load donations history
export const model = createModel({
  name: 'donations',
  state: {
    entities: { donations: {} },
    result: [],
    filter: {
      search: '',
      from: startOfMonth(new Date()),
      to: lastDayOfMonth(new Date()),
      campaignIds: [],
      communityIds: [],
    },
  },
  reducers: {
    set: (state, payload) => {
      const { entities, result } = norm(payload);
      return update(state, {
        entities: { donations: { $merge: entities.donations || {} } },
        result: { $set: result },
      });
    },
    setSummary: (state, payload) => {
      return update(state, {
        summary: { $set: payload },
      });
    },
    push: (state, payload) => {
      const { entities, result } = norm(payload);
      return update(state, {
        entities: { donations: { $merge: entities.donations || {} } },
        result: { $push: result },
      });
    },
    delete: (state, payload) => {
      return update(state, {
        result: { $set: state.result.filter((id) => id !== payload.id) },
      });
    },
    patch: (state, payload) => {
      const { entities } = norm(payload);
      return update(state, { entities: { donations: { $merge: entities.donations } } });
    },
    setFilter: (state, payload) => {
      return update(state, { filter: { $merge: payload } });
    },
  },
  selectors: {
    historyDonations(state) {
      return denorm(state)
        .filter((donation) => donation.braintree_subscription_status !== 'active' && donation.meta?.stripe_subscription_status !== 'active')
        .sort(compare(compareDesc, 'created_at'));
    },
    activeDonations(state) {
      return denorm(state)
        .filter((donation) => donation.braintree_subscription_status === 'active' || donation.meta?.stripe_subscription_status === 'active')
        .sort(compare(compareDesc, 'created_at'));
    },
    chequePledgeDonations(state) {
      return denorm(state)
        .filter((donation) => donation && donation.type === 'cheque')
        .sort(compare(compareDesc, 'created_at'));
    },
  },
  effects: (dispatch) => ({
    async get({ limit, skip, filter }) {
      const organization = getAppConfig().organization;
      const organization_ids = [];
      if (organization) {
        organization_ids.push(organization.id);
      }
      const query = { organization_ids: organization_ids, limit: limit || 10000000, skip: skip || 0 };
      if (filter.search) {
        query.search = filter.search;
      }
      if (filter.campaignIds) {
        query.campaigns = filter.campaignIds;
      }
      if (filter.communityIds) {
        query.communities = filter.communityIds;
      }
      if (filter.from) {
        query['created_at[from]'] = filter.from;
      }
      if (filter.to) {
        query['created_at[to]'] = filter.to;
      }
      const response = await DonationsService.get(query);
      if (response.ok) {
        dispatch.donations.set(response.data);
      }
      return response;
    },
    async getSummary({ filter }) {
      const query = {};
      if (filter.search) {
        query.search = filter.search;
      }
      if (filter.campaignIds) {
        query.campaigns = filter.campaignIds;
      }
      if (filter.communityIds) {
        query.communities = filter.communityIds;
      }
      if (filter.from) {
        query['created_at[from]'] = filter.from;
      }
      if (filter.to) {
        query['created_at[to]'] = filter.to;
      }
      const response = await DonationsService.getSummary(query);
      if (response.ok) {
        dispatch.donations.setSummary(response.data);
      }
      return response;
    },
    async getDonationsForCurrentUser() {
      const response = await DonationsService.getDonationsForCurrentUser();
      if (response.ok) {
        dispatch.donations.set(response.data);
      }
      return response;
    },
    async donate(data) {
      const response = await DonationsService.donate({
        campaign_id: data.campaignId,
        recaptcha_token: data.donationRequest.recaptcha_token,
        donation: {
          amount: data.donationRequest.amount,
          payment_method_nonce: data.donationRequest.paymentMethod.nonce,
          interval: data.donationRequest.interval,
        },
        payer: data.donationRequest.payer,
      });

      if (response.ok) {
        dispatch.donations.push([response.data]);
      }

      return response;
    },
    async cancelRecurringDonation(data) {
      await DonationsService.cancelRecurringDonation(data.id);
      // const a = { ...data, campaign: { ...data.campaign, name: 'dadsa' } };
      // dispatch.donations.patch([a]);
    },
    async update(payload) {
      const response = await DonationsService.update(payload.id, payload.donation);
      if (response.ok) {
        dispatch.donations.patch([response.data]);
      }
      return response;
    },
    async deleteDonation(id) {
      const response = await DonationsService.delete(id);
      if (response.ok) {
        dispatch.donations.delete(response.data);
      }
      return response;
    },
    async chequePledge(payload) {
      const response = await DonationsService.createCheque(payload);
      return response;
    },
    async sendReport(params) {
      const query = {};
      if (params.from) {
        query['created_at'] = {
          from: params.from,
          to: params.to,
        };
      }
      if (params.users) {
        query.users = params.users;
      }
      const response = await DonationsService.sendReport(query);
      return response;
    },
  }),
});
