import { type Pagination } from 'models';
import { useEffect, useState } from 'react';
import { useCommunitiesQuery } from 'features/communities/queries'; // TODO import from another feature

export const useFeaturedCommunities = ({
  onSeeAll,
  organizationId,
  pagination,
}: {
  onSeeAll?: () => void;
  organizationId?: string;
  pagination: Pagination;
}) => {
  const [featured, setFeatured] = useState<boolean>(true);

  useEffect(() => {
    setFeatured(true);
  }, [organizationId]);

  const query = useCommunitiesQuery({
    scope: 'channels',
    pagination,
    organizationId,
    featured,
  });

  const hasNoFeaturedCommunities = featured && onSeeAll && query.data?.data.length === 0 && pagination.position === 0;
  if (hasNoFeaturedCommunities) {
    setFeatured(false);
  }

  return { featured, query };
};
