import { type RouteComponentProps } from 'react-router-dom';

import { PublicKudos } from 'features/kudos';
import { ContentLeft, Footer, Header, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

interface RouteParams {
  kudosId: string;
}

interface P extends RouteComponentProps<RouteParams> {}

export const PagePublicKudos = ({ match }: P) => {
  const { onNavigateToPrimaryLogin, onNavigateToSignUp } = useOnboardingNavigation<OnboardingLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Log in',
      type: 'default',
      onClick() {
        onNavigateToPrimaryLogin({ referrer: window.location.pathname });
      },
    },
    {
      label: 'Sign up',
      type: 'default',
      onClick() {
        onNavigateToSignUp({ referrer: window.location.pathname });
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={<PublicKudos id={match.params.kudosId} />}
      footer={<Footer />}
    />
  );
};
