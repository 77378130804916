import { ChannelsEmpty, EntityList } from 'features/channels/components';
import { useProgramsInfiniteQuery } from 'features/programs/queries';

type P = {
  getPathToProgramDetail: (id: string) => string;
  navigateToJoinedPrograms: () => void;
  organizationId?: string;
};

const AllPrograms = ({ getPathToProgramDetail, navigateToJoinedPrograms, organizationId }: P) => {
  const {
    data: allProgramsData,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    fetchNextPage,
    hasNextPage,
  } = useProgramsInfiniteQuery({
    scope: 'channels',
    pageSize: 12,
    organizationId,
  });

  const allPrograms = allProgramsData?.pages.flat().flatMap((programsPaginated) => programsPaginated.data);

  return (
    <EntityList
      getPathToEntityDetail={getPathToProgramDetail}
      empty={<ChannelsEmpty scope="programs" navigateToJoined={navigateToJoinedPrograms} />}
      entities={allPrograms}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isFetching={isFetchingAll}
      isLoading={isLoadingAll}
      showPrice
    />
  );
};

export default AllPrograms;
