import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { select } from '@rematch/select';

import { AppLayout } from '../../layouts/AppLayout';
import { CourseLayout } from '../../layouts/CourseLayout';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { Filter } from '../../atoms/filter';
import { CircleIcon } from '../../atoms/icon';
import { ThemeConsumer } from '../../atoms/theme';
import { MembersList } from './MembersList';
import { CourseInviteForm } from '../../atoms/course-invite-form';
import { Modal } from '../../atoms/modal';
import { Button as ButtonOld } from '../../atoms/button';
import { can } from '../../utils/permissions';
import { message, modalConfirm, Card, DeleteIcon, Result, Button } from 'ui';
import variables from 'common/styles/variables.json';

const INITIAL_USERS_COUNT = 40;

class Renderer extends React.Component {
  state = {
    sortBy: 'name',
    hasMoreObjects: true,
    failedToLoad: false,
  };

  sortItems = [
    {
      key: 'joined_at',
      name: <Trans>Date Enrolled</Trans>,
    },
    {
      key: 'name',
      name: <Trans>Alphabetically</Trans>,
    },
    {
      key: 'invited_at',
      name: <Trans>Date Invited</Trans>,
    },
  ];

  componentDidMount = () => {
    this.props.loadData();
  }

  getNextObjects = async (params) => {
    if (this.props.loadingUsers === false) {
      const lastObject = this.props.users[this.props.users.length - 1];
      const resp = await this.props.getUsers({
        sort: this.state.sortBy,
        cursor: lastObject ? lastObject.id : undefined,
        ...params,
      });

      if (resp.ok) {
        this.setState({ hasMoreObjects: resp.data.users.length > 0 });
      } else {
        this.setLoadErrorStatus();
      }
    }
  };

  setLoadErrorStatus = (isError = true) => {
    this.setState({ hasMoreObjects: !isError, failedToLoad: isError });
  }

  onCourseInviteSubmit = async (invitations) => {
    return this.props.courseInvite(invitations, this.state.sortBy);
  };

  renderInviteModal = (course, theme) => (
    <Modal
      title={<Trans>Enroll Members</Trans>}
      showFooter={false}
      trigger={(ref) => (
        <ButtonOld
          ref={ref}
          title={<Trans>+ Enroll Members</Trans>}
          color="#ECEFF1"
          textColor={theme.color.gray}
          size="md"
          data-test-id="btn_invite-members"
        />
      )}
    >
      {(modal) => {
        return (
          <div style={{ maxWidth: 500 }}>
            <CourseInviteForm course={course} onSubmit={this.onCourseInviteSubmit} onSuccess={modal.closeModal} />
          </div>
        );
      }}
    </Modal>
  );

  onSorting = (key) => {
    this.setState({ sortBy: key });
    this.getNextObjects({ resetUsers: true, sort: key, cursor: undefined });
  };

  renderSortMenu = () => (
    <Filter title={<Trans>Sort by:</Trans>} items={this.sortItems} filter={this.state.sortBy} onSort={this.onSorting} />
  );

  onDeleteMember = async (member, courseName) => {
    const fullName = `${member.first_name} ${member.last_name}`;

    modalConfirm({
      title: (
        <Trans
          values={{
            fullName: fullName,
            groupName: courseName,
          }}
        >
          Remove user from?
        </Trans>
      ),
      content: <Trans>This action is irreversible.</Trans>,
      cancelText: <Trans>Cancel</Trans>,
      okText: <Trans>Yes, remove</Trans>,
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        if (!member.joined) {
          message.warn('User with pending invitation cannot be removed');
          return;
        }

        const result = await this.props.deleteMember(member.id, this.state.sortBy);
        if (result.ok) {
          message.success(<Trans>User removed successfully</Trans>);
        } else {
          message.error(<Trans>Something went wrong. The user could’t be removed.</Trans>);
        }
      },
      icon: <DeleteIcon style={{ color: variables.brandRedColor.value }} />,
    });
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <AppLayout
            center={
              <CourseLayout
                courseId={this.props.courseId}
                viewer={this.props.viewer}
                history={this.props.history}
                fetchUsers={false}
              >
                {(course) => {
                  const canDeleteMember = can(course, 'Course', 'deleteMember');

                  return (
                    <Card
                      title={
                        <Box flexDirection="row" justifyContent="space-between" alignItems="center" flex="1">
                          <Box flexDirection="row">
                            <div className="member-status-box">
                              <CircleIcon size={28} name="check" color="white" background={theme.color.lightGray} />
                              <Text>
                                {course.joined_count} <Trans>Joined</Trans>
                              </Text>
                            </div>
                            <div className="member-status-box">
                              <CircleIcon
                                size={28}
                                name="question-mark"
                                color="white"
                                background={theme.color.lightGray}
                              />
                              <Text>
                                {course.pending_count} <Trans>Pending</Trans>
                              </Text>
                            </div>
                          </Box>
                          {this.renderSortMenu()}
                          {can(course, 'Course', 'inviteMembers') && this.renderInviteModal(course, theme)}
                        </Box>
                      }
                    >
                      <MembersList
                        dateType={this.state.sortBy}
                        members={course.users ? course.users : []}
                        hasMoreObjects={this.state.hasMoreObjects}
                        loading={this.props.loadingUsers}
                        onGetNextObjects={this.getNextObjects}
                        onDeleteMember={canDeleteMember ? this.onDeleteMember : undefined}
                        courseName={course.name}
                      />
                      {this.state.failedToLoad ? (
                        <Result
                          status="error"
                          subTitle={<Trans>Sorry, something went wrong.</Trans>}
                          extra={
                            <Button type="primary" onClick={() => this.setLoadErrorStatus(false)}>
                              Retry
                            </Button>
                          }
                        />
                      ) : !this.state.hasMoreObjects && (
                        <div style={{ textAlign: 'center' }}>
                          <Text>
                            <Trans>You are at the end of the list. There are no more users to be loaded.</Trans>
                          </Text>
                        </div>
                      )}
                    </Card>
                  );
                }}
              </CourseLayout>
            }
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  courseId: props.match.params.courseId,
  loadingUsers: state.loading.effects.courses.getUsers,
  users: select.courses.getUsers(state, props.match.params.courseId),
  viewer: select.session.user(state),
});

const mapDispatch = (dispatch, props) => ({
  getUsers: (params) =>
    dispatch.courses.getUsers({
      courseId: props.match.params.courseId,
      limit: INITIAL_USERS_COUNT,
      ...params,
    }),
  loadData: () => {
    dispatch.courses.getUsers({
      courseId: props.match.params.courseId,
      limit: INITIAL_USERS_COUNT,
      sort: 'name',
    });
  },
  courseInvite: (invitations, sort) =>
    dispatch.courses.invite({ id: props.match.params.courseId, sort: sort, ...invitations }),
  deleteMember: (userId, sort) =>
    dispatch.courses.deleteMember({ courseId: props.match.params.courseId, userId, sort: sort }),
});

export const PageCourseMembers = connect(mapState, mapDispatch)(Renderer);
