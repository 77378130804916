import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const AssetService = {
  list: (payload) => http.get('/assets', payload),
  create: (payload) => http.post('/assets', payload),
  update: (id, payload) => http.put(`/assets/${id}`, payload),
  delete: (id) => http.delete(`/assets/${id}`),
};
