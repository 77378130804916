import { type PublicEventContentObject } from 'models';
import { EventAvatar } from 'old/atoms/events';
import { EventAvatar as EventAvatarLive } from 'old/widgets/events-list/EventAvatar';
import { Live } from 'old/widgets/events-list/EventRow';
import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Col, Row, Text } from 'ui';

const SharedEventInfo = ({ eventContentObject }: { eventContentObject: PublicEventContentObject }) => {
  const { t } = useTranslation();

  return (
    <Row item={{ gutter: variables.spaceMd.value, justify: 'center', align: 'middle' }}>
      <Col>
        {eventContentObject.event.isLive ? (
          <EventAvatarLive size="md" date={eventContentObject.event.startTime} />
        ) : (
          <EventAvatar size="md" date={eventContentObject.event.startTime} />
        )}
      </Col>
      <Col item={{ flex: '1' }}>
        <Row item={{ gutter: [0, variables.spaceXxs.value], justify: 'center', align: 'middle' }}>
          <Col item={{ span: 24 }}>
            <Text strong>{eventContentObject.event.name}</Text>
          </Col>
          <Col item={{ span: 24 }}>
            <Row item={{ gutter: variables.spaceXs.value, align: 'middle' }}>
              <Col>
                <Text type="secondary">
                  {t('dateFormatShortMonthDayYearTime', { date: eventContentObject.event.startTime })}
                </Text>
              </Col>
              {eventContentObject.event.isLive ? (
                <Col>
                  <Live marginBottom={false} />
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SharedEventInfo;
