import { useTranslation } from 'react-i18next';
import variables from 'common/styles/variables.json';
import { Button, Col, ArrowLeftLine, Progress, Row, Text, Title } from 'ui';
import { type UpdateOnboardingTourStepOptionHandler, type SupportedOboardingTourStep } from '../../../models';
import { useNavigateThroughOnboardingTourSteps } from '../../hooks';
import { useSupportedStepTitle, useSupportedStepProgress } from './hooks';

type P = {
  step: SupportedOboardingTourStep;
  steps: SupportedOboardingTourStep[];
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
};

const StepProgress = ({ step, steps, onChangeStep }: P) => {
  const { t } = useTranslation();
  const { onNavigateToPreviousStep } = useNavigateThroughOnboardingTourSteps({ step: step.step, steps, onChangeStep });
  const { percent, current, total } = useSupportedStepProgress({ step, steps });
  const title = useSupportedStepTitle({ step: step.step });

  return (
    <Row item={{ gutter: [variables.spaceMd.value, variables.spaceXxs.value] }}>
      {onNavigateToPreviousStep ? (
        <Col item={{ span: 24 }}>
          <Button icon={<ArrowLeftLine />} type="link-secondary" onClick={onNavigateToPreviousStep}>
            Back
          </Button>
        </Col>
      ) : null}
      <Col item={{ flex: '0 0 auto' }}>
        <Text size="large" weight="bold">
          {t('Step {{current}} of {{total}}', { current, total })}
        </Text>
      </Col>
      <Col item={{ flex: 1 }}>
        <Progress percent={percent} showInfo={false} />
      </Col>
      <Col item={{ span: 24 }}>
        <Title level={2} marginTop={false}>
          {title}
        </Title>
      </Col>
    </Row>
  );
};

export default StepProgress;
