import { createModel } from '@rematch/core';
import { normalize, denormalize } from 'normalizr';

import { CoursesService } from '../services/CoursesService';
import { ProgramSchema, UserSchema } from './schemas';
import { dispatch, getState } from '../store';
import { can } from '../utils/permissions';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [ProgramSchema] : ProgramSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

const groupByOrganization = (programs) => {
  const groupedByOrganizationId = programs.reduce((acc, program) => {
    if (program.organization) {
      acc[program.organization.id] = acc[program.organization.id] || [];
      acc[program.organization.id].push(program);
    }

    return acc;
  }, {});

  return Object.keys(groupedByOrganizationId).map((id) => {
    const organizationPrograms = groupedByOrganizationId[id];
    const organization = organizationPrograms[0].organization;

    return [organization, organizationPrograms];
  });
};

const INITIAL_STATE = [];

export const model = createModel({
  name: 'courses',
  state: INITIAL_STATE,
  reducers: {
    'session/reset': () => INITIAL_STATE,

    set: (state, payload) => payload,

    push: (state, payload) => {
      if (state.includes(payload)) {
        return state;
      }

      return state.concat(payload);
    },

    'adminCourses/deleteCourse': (state, course) => {
      return state.filter((id) => id !== course.id);
    },
  },
  selectors: {
    find(state, payload) {
      return denormalize(payload, ProgramSchema, getState().entities);
    },

    get(state) {
      return denormalize(state, [ProgramSchema], getState().entities);
    },

    getPending(state) {
      return this.get(state).filter((course) => !can(course, 'Course', 'isMemberOfProgram'));
    },

    getJoined(state) {
      return this.get(state).filter((course) => can(course, 'Course', 'isMemberOfProgram'));
    },

    getUsers(state, courseId) {
      const course = this.find(state, courseId);
      return course?.users || [];
    },
    getGroupedByOrganization(state) {
      return groupByOrganization(denormalize(state, [ProgramSchema], getState().entities));
    },
  },
  effects: (dispatch) => ({
    async get(data) {
      const response = await CoursesService.get(data);
      if (response.ok && !data.pinned) {
        this.set(normalizeData(response.data));
      }

      return response;
    },

    async joinedAsync(organization) {
      const organizationId = organization ? organization.id : undefined;

      const response = await CoursesService.get({
        scope: 'joined',
        organization_id: organizationId,
        status: '',
      });

      if (response.ok) {
        this.set(normalizeData(response.data));
      }

      return response;
    },

    async notJoinedAsync(organization) {
      const organizationId = organization ? organization.id : undefined;

      const response = await CoursesService.get({
        scope: 'not_joined',
        organization_id: organizationId,
        status: '',
      });

      if (response.ok) {
        this.set(normalizeData(response.data));
      }

      return response;
    },

    async getUsers(params) {
      const { resetUsers, courseId, ...queryParams } = params;

      if (resetUsers) {
        dispatch.entities.updateEntity({
          programs: {
            [courseId]: {
              $merge: { users: [] },
            },
          },
        });
      }

      const response = await CoursesService.getUsers(courseId, queryParams);

      if (response.ok) {
        if (queryParams.cursor) {
          const { id, users, ...data } = response.data;

          const normalizedData = normalize(users, [UserSchema]);
          dispatch.entities.mergeEntities(normalizedData.entities);

          dispatch.entities.updateEntity({
            programs: {
              [id]: { $merge: data },
              [id]: {
                users: (arr) => [...arr, ...normalizedData.result],
              },
            },
          });
        } else {
          normalizeData(response.data);
        }
      }

      return response;
    },

    async find(id) {
      const response = await CoursesService.find(id);

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    async invite(invitations) {
      const result = {
        organization_ids: invitations.invited_organizations.map((o) => o.id),
        community_ids: invitations.invited_communities.map((o) => o.id),
        user_ids: invitations.invited_users.map((o) => o.id),
      };
      const response = await CoursesService.invite(invitations.id, result);

      if (response.ok) {
        this.find(invitations.id);
        this.getUsers({ courseId: invitations.id, sort: invitations.sort, resetUsers: true });
      }

      return response;
    },

    async deleteMember(params) {
      const result = {
        role: 'none',
        user_id: params.userId,
        entity_type: 'program',
        entity_ids: [params.courseId],
      };

      const response = await CoursesService.deleteMember(result);

      if (response.ok) {
        this.getUsers({ courseId: params.courseId, sort: params.sort, resetUsers: true });
      }

      return response;
    },

    async accept(id) {
      const response = await CoursesService.accept(id);

      if (response.ok) {
        dispatch.entities.updateEntity({
          programs: {
            [id]: {
              joined: () => true,
              joined_count: (count) => count + 1,
              pending_count: (count) => count - 1,
            },
          },
        });

        await dispatch.session.fetchUser();
      }

      return response;
    },

    async decline(id) {
      const response = await CoursesService.decline(id);

      if (response.ok) {
        dispatch.entities.updateEntity({
          programs: {
            [id]: {
              joined: () => false,
              joined_count: (count) => count - 1,
              pending_count: (count) => count + 1,
            },
          },
        });

        dispatch.session.fetchUser();
      }

      return response;
    },

    async report(course) {
      return await CoursesService.reportAbuse(course.id);
    },

    async update(course) {
      const response = await CoursesService.update(course.id, course);

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    async updateStatus(course) {
      const response = await CoursesService.updateStatus(course.id, course);

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    async duplicate({ id, start_time, duplicate_users }) {
      const response = await CoursesService.duplicate(id, { start_time, duplicate_users });

      if (response.ok) {
        this.push(normalizeData(response.data));
        dispatch.adminCourses.pushAfterDuplicate(response.data);
      }

      return response;
    },
  }),
});
