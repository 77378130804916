import { LoadingOutlined } from '@ant-design/icons';
import { type ReduxUser } from 'models';
import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { Avatar, Badge, Col, PillBadge, Row, Title } from 'ui';
import { useOverallPointsQuery } from '../../queries/useOverallPointsQuery';

type P = {
  user: ReduxUser;
};

const LeaderboardHeader = ({ user }: P) => {
  const { isLoading, data } = useOverallPointsQuery(user.id);
  const userPoints = data?.points ?? null;
  const userPosition = data?.meta?.leaderboard?.[0].position;

  return (
    <div className="leaderboard-header">
      <Row item={{ gutter: variables.spaceMd.value, align: 'middle' }}>
        <Col>
          <Badge
            count={userPosition ? `${userPosition}.` : null}
            offset={[-variables.spaceMd.value, variables.spaceMd.value * 3]}
            className="leaderboard-header__position-badge"
            overflowCount={999}
          >
            <Avatar size={64} photo={user.profile_photo?.url ?? images.default.userProfilePhoto}>
              {user.name}
            </Avatar>
          </Badge>
        </Col>
        <Col>
          <Title level={3} className="leaderboard-header__title">
            {user.name}
          </Title>
        </Col>
        {userPoints ?? isLoading ? (
          <Col>
            <PillBadge type="overall">{isLoading ? <LoadingOutlined /> : userPoints}</PillBadge>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default LeaderboardHeader;
