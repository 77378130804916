import { CalendarOutlined, VideoCameraOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { type Community, type ListEvent, type ReduxUser } from 'models';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { images } from 'common/utils';
import { EventStatus } from 'features/events';
import { Col, Image, JoinStatusButton, Row, Title, Text, Button } from 'ui';
import { useEventActions } from '../hooks';
import { isOpenToJoin } from '../utils';

type P = {
  data: ListEvent;
  viewer: ReduxUser;
  community: Community;
  showImage?: boolean;
};

const EventsListItem = ({ data, viewer, community, showImage = true }: P) => {
  const { t } = useTranslation();

  const {
    handleJoinStatusButtonDropdown,
    onRejectButtonClick,
    handleJoinStatusButtonStatus,
    onJoinButtonClick,
    isAccepting,
    isRejecting,
    isJoining,
  } = useEventActions({ data, viewer });

  return (
    <div className={cn('events-list-item', { 'events-list-item--no-image': !showImage })}>
      {/* TODO somehow solve navigation logic, so destination is not written here */}
      {showImage ? (
        <NavLink<unknown> to={`/events/${data.id}/about`}>
          <Image
            rootClassName="events-list-item__image"
            // TODO this should be used everywhere - https://redmine.touch4it.com/issues/34166
            src={data.coverPhoto?.url ?? images.default.eventCoverPhoto}
            preview={false}
          />
        </NavLink>
      ) : null}
      <div className="events-list-item-content">
        <div>
          {/* TODO somehow solve navigation logic, so destination is not written here */}
          <NavLink<unknown> to={`/events/${data.id}/about`}>
            <Row item={{ className: 'events-list-item-content__date', align: 'middle' }}>
              <Col>
                <Text type="secondary">
                  <CalendarOutlined className="events-list-item-content__date__icon" />
                </Text>
              </Col>
              <Col>
                <Text type="secondary">
                  {t('dateFormatShortMonthDayYearTime', {
                    date: data.startTime,
                  })}
                </Text>
              </Col>
            </Row>

            <Title ellipsis={{ rows: 2 }} level={5}>
              {data.name}
            </Title>
            <Text ellipsis>{community.name}</Text>
          </NavLink>
        </div>
        <div
          className={cn('events-list-item-content__buttons', {
            'events-list-item-content__buttons--with-video': data.isRecordingAvailable,
          })}
        >
          {data.isRecordingAvailable ? (
            <Button
              icon={<VideoCameraOutlined />}
              className="events-list-item-content__buttons__recording"
              size="small"
            />
          ) : null}
          <div>
            {data.status === 'draft' ? (
              <EventStatus status={data.lastChangeRequestStatus} />
            ) : (
              <JoinStatusButton
                size="small"
                loading={isAccepting || isRejecting || isJoining}
                status={handleJoinStatusButtonStatus()}
                dropdown={handleJoinStatusButtonDropdown()}
                onClick={onJoinButtonClick}
                onRejectClick={onRejectButtonClick}
                // TODO: possible place to refactor and include it in model somehow
                disabled={!isOpenToJoin(data) && data.isPast}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsListItem;
