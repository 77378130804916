import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const UserService = {
  find: (id) => http.get(`/users/${id}`),
  exists: (queryParams) => http.get('/users/exists', queryParams),
  updatePassword: (payload) => http.post(`/users/update_password`, { user: payload }),
  updateUser: (id, payload) => http.patch(`/users/${id}`, { user: payload }),
};
