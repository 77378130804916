import { type ReduxUser } from 'models';
import { useParams } from 'react-router-dom';
import { CommunityAssets, CommunityLayout } from 'features/communities';

export type RouteParams = {
  communityId: string;
};

type P = {
  viewer: ReduxUser;
  fetchUser: () => void;
};

const PageCommunityAssets = ({ viewer, fetchUser }: P) => {
  const { communityId } = useParams<RouteParams>();

  return (
    <CommunityLayout viewer={viewer} communityId={communityId} tab="/assets" onStatusChange={fetchUser}>
      {(community) => <CommunityAssets community={community} viewer={viewer} />}
    </CommunityLayout>
  );
};

export default PageCommunityAssets;
