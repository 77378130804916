import { CommunityListItem, type ReduxUser, type EventType } from 'models';
import { useMemo } from 'react';
import { type EventContentObjectDataObject } from 'models/service';

import { CreateEventFormModal } from './components';
import { type DeprecatedEventProgramProperties } from './types';

type P = {
  isOpened: boolean;
  viewer: ReduxUser;
  onCancel: () => void;
  onSuccess: (eventData: EventContentObjectDataObject) => Promise<void> | void;
  onRequestReturn?: () => void;
  program?: DeprecatedEventProgramProperties;
  communityId?: string;
  eventTypes?: EventType[];
};

export const CreateEvent = ({ viewer, ...rest }: P) => {
  const communitiesData = useMemo<CommunityListItem[]>(
    () => viewer.joined_communities.map((community) => new CommunityListItem(community)),
    [viewer],
  );

  return rest.isOpened ? <CreateEventFormModal viewer={viewer} communities={communitiesData} {...rest} /> : null;
};
