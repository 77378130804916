import * as React from 'react';
import { ThemeConsumer } from '../theme';
import { withTranslation } from 'react-i18next';

export class Renderer extends React.Component {
  constructor(props) {
    super(props);

    this.placeholder = this.props.placeholder ? this.props.t(this.props.placeholder) : this.props.t('Post in...');
  }

  render() {
    const { className, ...inputProps } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <div className={`tags-input-element ${className}`}>
            <input
              {...inputProps}
              placeholder={this.placeholder}
              type="search"
              className="tags-input-element__input"
              data-test-id={inputProps?.['data-test-id']}
              id={inputProps?.id}
            />
          </div>
        )}
      </ThemeConsumer>
    );
  }
}

export const InputElement = withTranslation()(Renderer);
