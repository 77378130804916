import { type Profile, type Image } from 'models';
import { useResetQuery } from 'query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadImage } from 'common/queries';
import variables from 'common/styles/variables.json';
import { timezones } from 'common/utils';
import { useUpdateUserProfile } from 'features/user-profile/queries';
import {
  BirthDateInput,
  type BirthDateType,
  Button,
  Col,
  defaultBirthDateValidator,
  Form,
  PhoneInput,
  Row,
  SelectInput,
  getBirthDateTypeValue,
  ImageInput,
  Text,
  Title,
  type ImageUploadedData,
  transformImageForInit,
} from 'ui';
import { type SupportedOboardingTourStep } from '../../../models';
import { useUpdateOnboardingStep } from '../../../queries';

type ProfileStepFormValues = {
  profilePhoto?: ImageUploadedData<Image>;
  phone?: string;
  birthDate?: BirthDateType;
  timeZone: string;
};

type P = {
  viewer: Profile;
  step: SupportedOboardingTourStep;
  onSuccess: () => void;
};

const ProfileStep = ({ viewer, step, onSuccess }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ProfileStepFormValues>();
  const { mutateAsync: resetQuery } = useResetQuery();
  const { mutateAsync: updateProfile, isLoading: isUpdatingProfile } = useUpdateUserProfile();
  const { mutateAsync: updateStep, isLoading: isUpdatingStep } = useUpdateOnboardingStep();

  const initialValues: Partial<ProfileStepFormValues> = {
    profilePhoto: viewer.profilePhoto ? transformImageForInit(viewer.profilePhoto) : undefined,
    phone: viewer.phone,
    birthDate: getBirthDateTypeValue(viewer.birthDate),
    timeZone: viewer.timeZone,
  };

  const onFinish = useCallback(
    async (values: ProfileStepFormValues) => {
      await updateProfile({ userId: viewer.id, userData: values });
      await updateStep({ id: step.data.id, status: 'completed' });
      await resetQuery({ cacheResetOption: 'invalidate' });
      onSuccess();
    },
    [onSuccess, resetQuery, step.data.id, updateProfile, updateStep, viewer.id],
  );

  return (
    <Form<ProfileStepFormValues> form={form} initialValues={initialValues} onFinish={onFinish} className="profile-step">
      <Row item={{ gutter: [variables.spaceLg.value, 0] }}>
        <Col item={{ flex: '0 0 auto' }}>
          <ImageInput
            uploadFile={uploadImage}
            item={{ name: 'profilePhoto', label: 'Avatar' }}
            type="round"
            size="medium"
          />
        </Col>
        <Col item={{ flex: 1, className: 'profile-step__info' }}>
          <Title level={4} marginBottom={false}>
            {viewer.firstName} {viewer.lastName}
          </Title>
          <Text>{viewer.email}</Text>
        </Col>
        <Col item={{ span: 24 }}>
          <PhoneInput
            item={{
              name: 'phone',
              label: 'Phone number',
            }}
            input={{
              autocomplete: 'tel',
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <BirthDateInput
            item={{
              name: 'birthDate',
              label: 'Date of birth',
              rules: [defaultBirthDateValidator(t)],
            }}
          />
        </Col>
        <Col item={{ span: 24 }}>
          <SelectInput
            item={{
              name: 'timeZone',
              label: 'My timezone',
              rules: [
                {
                  required: true,
                },
              ],
            }}
            input={{
              showSearch: true,
              options: timezones,
              getOptionLabel: (option) => option.label,
              getOptionValue: (option) => option.value,
            }}
          />
        </Col>
      </Row>
      <Button loading={isUpdatingProfile || isUpdatingStep} size="large" htmlType="submit" type="primary" block>
        Continue
      </Button>
    </Form>
  );
};

export default ProfileStep;
