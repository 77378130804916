import { type Interest } from 'models';
import { useQuery } from 'query';
import { getInterests } from '../services';
import { getInterestsQueryKey } from '.';

export const useInterests = () =>
  useQuery<Interest[]>(getInterestsQueryKey(), async () => {
    const { data } = await getInterests();
    return data;
  });
