import { select } from '@rematch/select';
import { type Location } from 'history';
import { type ReduxUser } from 'models';
import qs from 'qs';
import { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TokenLogin } from 'features/onboarding';

const getQueryParameter = (location: Location, key: string) => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const value = query[key];
  if (typeof value === 'string') {
    return value;
  }

  return undefined;
};

const PageTokenLogin = ({ user }: { user: ReduxUser }) => {
  const { replace, location } = useHistory();

  const token = getQueryParameter(location, 'login_token');
  const referrer = getQueryParameter(location, 'referrer');
  const userId = getQueryParameter(location, 'user_id');

  const matchIds = userId && user && user.id === userId;
  const onFinishCalled = useRef(false);

  const onFinish = useCallback(() => {
    if (!onFinishCalled.current) {
      onFinishCalled.current = true;
      replace(referrer ?? '/');
    }
  }, [referrer, replace]);

  if (!token || matchIds) {
    onFinish();
    return null;
  }

  return <TokenLogin loginToken={token} onFinish={onFinish} />;
};

// @ts-expect-error old code
const mapStateToProperties = (state) => ({
  // @ts-expect-error old code
  user: select.session.user(state),
});

export default connect(mapStateToProperties)(PageTokenLogin);
