import { type EntityDataObject } from './service';

export class Entity {
  id: string;
  name: string;

  constructor(data: EntityDataObject) {
    this.id = data.id;
    this.name = data.name;
  }
}
