import { useCallback } from 'react';
import variables from 'common/styles/variables.json';
import { Col, Row, Title, Text, Form, ModalFormActions, TagsInput } from 'ui';
import { type SupportedOboardingTourStep } from '../../../../../models';
import { useCalendarOptions } from './hooks';

type FormValues = {
  calendars: string[];
};

type P = {
  step: SupportedOboardingTourStep;
  isLoading: boolean;
  onSuccess: (options: { calendars: string[] }) => Promise<void> | void;
};

const OtherCalendarForm = ({ step, isLoading, onSuccess }: P) => {
  const [form] = Form.useForm<FormValues>();
  const { calendars, noCalendar } = useCalendarOptions();

  const initialValues: Partial<FormValues> = {
    calendars:
      step.data.meta.selectedCalendarOptions?.filter((calendar) =>
        calendars.some((calendarOption) => calendarOption.value === calendar),
      ) ?? [],
  };

  const handleFinish = useCallback(
    (values: FormValues) => {
      onSuccess(values);
    },
    [onSuccess],
  );

  const handleNoCalendar = useCallback(() => {
    onSuccess({ calendars: [noCalendar] });
  }, [onSuccess, noCalendar]);

  return (
    <Form<FormValues> form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Row item={{ gutter: [variables.spaceXs.value, variables.spaceXs.value] }}>
        <Col item={{ textAlign: 'center', span: 24 }}>
          <Text size={40}>🗓️</Text>
        </Col>
        <Col item={{ textAlign: 'center', span: 24 }}>
          <Title marginBottom={false} marginTop={false} level={2}>
            Select calendar app
          </Title>
        </Col>
        <Col item={{ textAlign: 'center', span: 24 }}>
          <Text>
            Let us know what calendar app you use so we can improve our options. Please choose from the list below.
          </Text>
        </Col>
        <Col item={{ span: 24 }}>
          <TagsInput
            item={{
              name: 'calendars',
              label: 'Calendar apps',
              rules: [{ required: true }],
              extra: 'You can select multiple options',
            }}
            input={{
              options: calendars,
            }}
          />
        </Col>
      </Row>

      <ModalFormActions
        right={{
          children: "I don't use a calendar app",
          onClick: handleNoCalendar,
          loading: isLoading,
        }}
        submit={{ loading: isLoading }}
      />
    </Form>
  );
};

export default OtherCalendarForm;
