import { useParams } from 'react-router-dom';

import { PublicEvent } from 'features/events';
import { Header, ContentLeft, Footer, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

interface RouteParams {
  eventId: string;
}

export const PagePublicEvent = () => {
  const { eventId } = useParams<RouteParams>();
  const { onNavigateToPrimaryLogin, onNavigateToSignUp } = useOnboardingNavigation<OnboardingLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Log in',
      type: 'default',
      onClick() {
        onNavigateToPrimaryLogin({ referrer: window.location.pathname });
      },
    },
    {
      label: 'Sign up',
      type: 'default',
      onClick() {
        onNavigateToSignUp({ referrer: window.location.pathname });
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={<PublicEvent eventId={eventId} />}
      footer={<Footer />}
    />
  );
};
