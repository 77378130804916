import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

export const useOnRequestEventDetail = () => {
  const { push } = useHistory();

  return (eventId: string) => {
    push(routes.contentObjects.events(eventId));
  };
};
