import cs from 'classnames';
import { Image, Link } from 'ui';

type P = {
  className?: string;
};

const AppLogo = ({ className }: P) => (
  <Link to="/" id="header-logo">
    <Image
      preview={false}
      src={`/${process.env.REACT_APP_CONFIG}/dark/logo.png`}
      srcSet={`/${process.env.REACT_APP_CONFIG}/dark/logo.png 320w, /${process.env.REACT_APP_CONFIG}/dark/logo@2x.png 630w, /${process.env.REACT_APP_CONFIG}/dark/logo@3x.png 940w`}
      sizes="10vw"
      alt={process.env.REACT_APP_TITLE}
      className={cs('app-layout-logo', className)}
    />
  </Link>
);

export default AppLogo;
