import { useTranslation } from 'react-i18next';
import RadioOption, { type Option } from './RadioOption';

type P<T> = {
  disabled?: boolean;
  title?: string;
  selected: T;
  onSelect: (key: T) => void;
  options: Array<Option<T>>;
};

function RadioGroup<T extends string>({ disabled, title, selected, onSelect, options }: P<T>) {
  const { t } = useTranslation();

  return (
    <div className="radio-group">
      {title ? <span className="radio-group-title">{t(title)}</span> : null}
      {options.map((option) => (
        <RadioOption<T>
          disabled={disabled}
          key={option.key}
          option={option}
          checked={option.key === selected}
          onSelect={() => {
            onSelect(option.key);
          }}
        />
      ))}
    </div>
  );
}

export default RadioGroup;
