import { routes, apiClient, type ApiClientResponse } from 'common/services';

type CreateCalendarSyncRequestData = {
  grant_type: string;
  provider: string;
  code: string;
  redirect_uri: string;
};

export const createCalendarSync = async (data: CreateCalendarSyncRequestData) =>
  apiClient.post<void, ApiClientResponse<void>>(routes.user.calendar.create, data);
