import * as React from 'react';
import { ShadowBox } from '../shadow-box';

export const DefaultDropdownElement = React.forwardRef((props, ref) => {
  const { children, style, ...htmlBag } = props;
  return (
    <div
      ref={ref}
      {...htmlBag}
      style={{
        margin: '10px 0',
        zIndex: 100,
        overflowY: 'scroll',
        maxHeight: 400,
        maxWidth: 400,
        border: '1px solid #eaefef',
        ...style,
      }}
    >
      <ShadowBox>{children}</ShadowBox>
    </div>
  );
});
