import { type ReduxUser } from 'models';
import { AppLayout } from 'old/layouts/AppLayout';
import { JoinedPrograms } from 'features/programs';
import { useNavigateToProgramCreateForm, usePathToProgramDetail } from './hooks';

type P = {
  viewer: ReduxUser;
};

const PageJoinedPrograms = ({ viewer }: P) => {
  // TODO resolve - JoinedPrograms are implemented differently from JoinedCommunities in how they handle route paths
  const getPathToEntityDetail = usePathToProgramDetail();
  const navigateToProgramCreateForm = useNavigateToProgramCreateForm();

  return (
    <AppLayout
      center={
        <JoinedPrograms
          viewer={viewer}
          navigateToProgramCreateForm={navigateToProgramCreateForm}
          getPathToProgramDetail={(id) => getPathToEntityDetail(id)}
        />
      }
    />
  );
};

export default PageJoinedPrograms;
