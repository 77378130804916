import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans } from 'react-i18next';

import { ThemeConsumer } from '../../atoms/theme';
import { CampaignDonationForm } from '../../atoms/campaigns';
import { Loader } from '../../atoms/loader';
import { CampaignCover } from '../../atoms/campaigns/CampaignCover';
import { CampaignDetail } from '../../atoms/campaigns/CampaignDetail';
import { showAlert } from '../../atoms/alert';
import { LandingLayout, Title } from 'ui';
import { Header, ContentLeft, Footer } from 'features/onboarding';
import { useHistory } from 'react-router-dom';
import { useOnboardingNavigation } from 'pages/hooks';

const HeaderWithButtons = ({ viewer }) => {
  const { push } = useHistory();
  const { onNavigateToPasswordlessLogin, onNavigateToSignUp } = useOnboardingNavigation();

  const headerButtons = [];

  if (viewer) {
    const onNavigateToDonation = () => {
      push('/campaigns/listing');
    };

    headerButtons.push({
      label: 'Go back to donation',
      type: 'primary',
      onClick() {
        onNavigateToDonation();
      },
    });
  } else {
    headerButtons.push(
      {
        label: 'Log in',
        type: 'default',
        onClick() {
          onNavigateToPasswordlessLogin();
        },
      },
      {
        label: 'Sign up',
        type: 'default',
        onClick() {
          onNavigateToSignUp();
        },
      },
    );
  }

  return <Header items={headerButtons} />;
};

class Renderer extends React.Component {
  async componentDidMount() {
    if (this.props.match.params.campaignId) {
      const response = await this.props.findCampaign();

      if (!response.ok) {
        showAlert({ type: 'error', message: response.data?.message });
      }
    }
  }

  renderCampaignDetail() {
    if (this.props.campaign?.amounts) {
      return (
        <React.Fragment>
          <Title level={2}>
            <Trans>Donate</Trans>
          </Title>
          <CampaignCover campaign={this.props.campaign} />
          <CampaignDetail
            campaign={this.props.campaign}
            viewer={this.props.isAuthenticated ? this.props.viewer : undefined}
          />
          <CampaignDonationForm
            campaign={this.props.campaign}
            onSubmit={this.props.donate}
            loadingPayment={this.props.loadingPayment}
          />
        </React.Fragment>
      );
    }

    if (this.props.loadingCampaign) {
      return <Loader />;
    }

    return null;
  }

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <LandingLayout
            header={<HeaderWithButtons viewer={this.props.viewer} />}
            contentLeft={<ContentLeft />}
            contentRight={this.renderCampaignDetail()}
            footer={<Footer />}
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  campaign: select.campaigns.find(state, props.match.params.campaignId),
  loadingCampaign: state.loading.effects.campaigns.findAsync,
  loadingPayment: state.loading.effects.donations.donate,
  viewer: select.session.user(state),
  isAuthenticated: select.session.isAuthenticated(state),
});

const mapDispatch = (dispatch, props) => ({
  donate: (donationRequest) =>
    dispatch.donations.donate({
      campaignId: props.match.params.campaignId,
      donationRequest: donationRequest,
    }),
  findCampaign: () => dispatch.campaigns.findAsync(props.match.params.campaignId),
});

export const PublicPageCampaigns = connect(mapState, mapDispatch)(Renderer);
