import { useMemo } from 'react';
import { type OnboardingTourStepOption, type SupportedOboardingTourStep } from '../../models';

export const useSupportedOnboardingTourStep = ({
  step,
  steps,
}: {
  step: OnboardingTourStepOption;
  steps: SupportedOboardingTourStep[];
}) => useMemo(() => steps.find((remainingStep) => remainingStep.step === step), [step, steps]);
