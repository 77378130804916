import { EventService } from '../services/EventService';

export function parseBigmarkerErrorMessage(message) {
  const parsed = message.match(/>"([^"]+)"}/);
  return parsed ? parsed[1] : undefined;
}

export async function enterBigMarkerConference(props) {
  const exitUrl = props.eventId
    ? `${window.location.href}?feedback=true&eventId=${props.eventId}`
    : window.location.href;

  const response = await EventService.enterConference(
    props.eventId,
    props.userIds,
    props.email || null,
    props.exitUrl || exitUrl,
  );

  if (response.ok) {
    window.open(response.data.enter_uri, '_self');
  } else {
    return parseBigmarkerErrorMessage(response.data.message) || 'Something went wrong.';
  }

  return null;
}
