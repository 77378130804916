import { NodeUser } from 'models';
import { useQuery } from 'query';
import { type NodeApiClientResponseError, type NodeApiError } from 'common/services';
import { type NodeUserDataObject } from 'models/service';
import { getUser } from '../services';
import { getUsersQueryKey } from './';

export const useUserQuery = (userId: string) =>
  // TODO use node api client response error type
  useQuery<NodeUser, NodeApiClientResponseError<NodeApiError>>(
    getUsersQueryKey(userId),
    async () => {
      const response = await getUser(userId);

      return response.data.data.map((item: NodeUserDataObject) => new NodeUser(item))[0] ?? null;
    },
    {
      // TODO why is this override here
      staleTime: 24 * 60 * 60 * 1000, // 1 Day,
    },
  );
