import * as React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { Text } from '../text';
import { Box } from '../box';
import { ThemeConsumer } from '../theme';

export class SegmentedControl extends React.Component {
  getProperty = (label, property) => {
    if (typeof label === 'object') {
      return label[property];
    }
    return label;
  };

  onChange = (label) => {
    this.props.onChange(typeof label === 'object' ? label.value : label);
  };

  render() {
    const { color, labels, selectedLabel } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <Box flexDirection="row" className="segmented-control">
            {labels.map((label, idx, arr) => {
              const bgColor =
                selectedLabel === this.getProperty(label, 'value') ? color || theme.color.brand : theme.color.white;
              const textColor =
                selectedLabel === this.getProperty(label, 'value') ? theme.color.white : color || theme.color.brand;
              return (
                <button
                  key={idx}
                  onClick={() => !this.props.disabled && this.onChange(label)}
                  className={classNames('segmented-control__button', {
                    'left-rounded': idx === 0,
                    'right-rounded': idx === arr.length - 1,
                    'without-left-border': idx > 0,
                    'segmented-control__disabled': this.props.disabled,
                  })}
                  style={{ borderColor: `${color || theme.color.brand}`, backgroundColor: bgColor }}
                  id={`btn_events-` + this.getProperty(label, 'title').toLowerCase()}
                >
                  <Text size="14px" style={{ color: textColor, textTransform: 'capitalize' }}>
                    <Trans>{this.getProperty(label, 'title')}</Trans>
                  </Text>
                </button>
              );
            })}
          </Box>
        )}
      </ThemeConsumer>
    );
  }
}
