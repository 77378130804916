import { Component } from 'react';

export class ScrollToError extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.submitCount !== this.props.submitCount && !this.props.isValid) {
      const fieldName = Object.keys(this.props.errors).find(
        (fieldName) => !!document.querySelector(`[data-error="${fieldName}"]`),
      );
      if (fieldName) {
        document
          .querySelector(`[data-error="${fieldName}"]`)
          .previousElementSibling?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  render() {
    return null;
  }
}
