export const loadImage = async (file: Blob | File): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.addEventListener('load', (event) => {
      const _loadedImageUrl = event.target?.result;
      const image = document.createElement('img');
      image.src = _loadedImageUrl as string;

      image.addEventListener('load', () => {
        resolve(image);
      });

      image.addEventListener('error', (error) => {
        reject(error);
      });
    });

    reader.addEventListener = (error: any) => {
      reject(error);
    };
  });
