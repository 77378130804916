import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { withTranslation } from 'react-i18next';

import { EVENT_DRAFT } from './emptyDraftState';
import { EditEvent, CreateEvent } from 'features/events';

class Renderer extends React.Component {
  static defaultProps = {
    editorType: 'create',
    draft: EVENT_DRAFT,
  };

  get program() {
    const { course } = this.props;

    if (course?.id) {
      const { id, topics, author, administrators, start_time, end_time } = course;
      const users = [...administrators];

      if (!users.find((user) => user.id === author.id)) {
        users.push(author);
      }

      return {
        id,
        topics,
        users,
        start_time: start_time ? moment(start_time) : undefined,
        end_time: end_time ? moment(end_time) : undefined,
      };
    }

    return undefined;
  }

  onSuccess = (event) => {
    this.props.onSuccess(event);
  };

  onSubmit = async (values, formik) => {
    formik.setSubmitting(true);
    values.post_in_communities = values.post_in_communities.map((community) => ({
      id: community.id,
      name: community.name,
    }));
    await this.props.onSubmit(values, formik);
    formik.setSubmitting(false);
  };

  autocompleteData = (value) => {
    return this.props.user.joined_communities.filter((community) =>
      community.name.toLowerCase().includes(value ? value.toLowerCase() : ''),
    );
  };

  onRequestClose = () => {
    if (this.props.editorType === 'create' && window.confirm(this.props.t('Do you wish to discard your Event?'))) {
      this.props.onRequestClose();
    } else if (this.props.editorType === 'update' || this.props.editorType === 'duplicate') {
      this.props.onRequestClose();
    }
  };

  render() {
    const { communityId } = this.props;

    return (
      <React.Fragment>
        {this.props.editorType === 'create' ? (
          <CreateEvent
            viewer={this.props.user}
            eventTypes={this.props.eventTypes}
            isOpened={this.props.isEditorOpen}
            onCancel={this.onRequestClose}
            onSuccess={this.onSuccess}
            communityId={communityId}
          />
        ) : (
          <EditEvent
            viewer={this.props.user}
            // TODO eventTypes???
            eventId={this.props.draft.id}
            isOpened={this.props.isEditorOpen}
            onCancel={this.onRequestClose}
            onSuccess={this.onSuccess}
            program={this.program}
            mode={this.props.editorType === 'duplicate' ? 'duplicate' : 'edit'}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const EventEditor = withTranslation()(connect(mapState)(Renderer));
