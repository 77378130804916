import { Trans } from 'react-i18next';
import { formatDistanceToNow, parseISO, isDate, isValid } from 'date-fns';
import { withRouter } from 'react-router';

import { ThemeConsumer } from '../../atoms/theme';
import { CircleIcon } from '../../atoms/icon';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { adminDashboardRedirect } from 'common/admin-dashboard';

function Renderer(props) {
  const getIcon = (iconType) => {
    switch (iconType) {
      case 'program':
        return 'pba-education';
      case 'activity':
        return 'pba-Assignment';
      case 'user':
        return 'message';
      case 'like':
        return 'pba-heart-filled';
      case 'comment':
        return 'notification--comment';
      case 'video':
        return 'pba-calendar';
      case 'donation':
        return 'pba-donate';
      case 'poll':
        return 'pba-Poll';
      default:
        return `pba-${iconType}`;
    }
  };

  const iconName = getIcon(props.icon);

  const parsedDate = parseISO(props.created_at);
  const isValidDate = isDate(parsedDate) && isValid(parsedDate);

  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          className={`widget-notification ${props.status === 'unread' ? 'unread' : ''}`}
          onClick={async () => {
            if (props.url.includes(process.env.REACT_APP_ADMIN_DASHBOARD_URL)) {
              const parsedUrl = new URL(props.url);
              adminDashboardRedirect(parsedUrl.pathname + parsedUrl.search);
            } else {
              props.history.push(props.path);
            }

            props.onRequestReadNotification(props);
          }}
        >
          <CircleIcon
            style={{ marginRight: 20 }}
            size={35}
            name={iconName}
            color={props.status === 'read' ? '#455a64' : 'white'}
            background={props.status === 'read' ? '#d7dee2' : theme.color.brand}
          />
          <Box flex="1" style={{ borderBottom: '1px solid #e4e7ec', padding: '10px 0' }}>
            <Text weight="600" style={{ marginBottom: 3 }}>
              {props.name}
            </Text>
            <Text style={{ marginBottom: 3 }}>{props.content}</Text>
            <Text size="11px">
              {isValidDate ? formatDistanceToNow(parsedDate) : null} <Trans>ago</Trans>
            </Text>
          </Box>
        </div>
      )}
    </ThemeConsumer>
  );
}

export const Notification = withRouter(Renderer);
