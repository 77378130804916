import { type Profile } from 'models';
import { useCallback, useState } from 'react';
import variables from 'common/styles/variables.json';
import { Col, Text, Row } from 'ui';
import { type UpdateOnboardingTourStepOptionHandler, type SupportedOboardingTourStep } from '../../models';
import { useNavigateThroughOnboardingTourSteps } from '../hooks';
import { CalendarStep } from './CalendarStep';
import { ConsentsStep } from './ConsentsStep';
import { useSupportedStepDescription } from './hooks';
import { ProfileStep } from './ProfileStep';
import { StepProgress } from './StepProgress';

type P = {
  viewer: Profile;
  step: SupportedOboardingTourStep;
  steps: SupportedOboardingTourStep[];
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
};

const SupportedStep = ({ viewer, step, steps, onChangeStep }: P) => {
  const [isProgressVissible, setIsProgressVissible] = useState(true);
  const { onNavigateToNextStep } = useNavigateThroughOnboardingTourSteps({ step: step.step, steps, onChangeStep });
  const description = useSupportedStepDescription({ step: step.step });

  const onStepSuccess = useCallback(() => {
    setIsProgressVissible(true);
    onNavigateToNextStep?.();
  }, [onNavigateToNextStep]);

  const onConsent = useCallback((id?: string) => {
    setIsProgressVissible(!id);
  }, []);

  return (
    <Row item={{ gutter: [0, variables.spaceXl.value] }}>
      {isProgressVissible ? (
        <>
          <Col item={{ span: 24 }}>
            <StepProgress step={step} steps={steps} onChangeStep={onChangeStep} />
          </Col>
          <Col item={{ span: 24 }}>
            <Text>{description}</Text>
          </Col>
        </>
      ) : null}

      <Col item={{ span: 24 }}>
        {step.step === 'profile' ? <ProfileStep viewer={viewer} step={step} onSuccess={onStepSuccess} /> : null}
        {step.step === 'consents' ? (
          <ConsentsStep viewer={viewer} step={step} onConsent={onConsent} onSuccess={onStepSuccess} />
        ) : null}
        {step.step === 'calendar' ? <CalendarStep step={step} onSuccess={onStepSuccess} /> : null}
      </Col>
    </Row>
  );
};

export default SupportedStep;
