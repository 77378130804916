import { useQuery } from 'query';
import { type Profession } from 'models/profession';
import { getProfessions } from '../services';
import { getProfessionsQueryKey } from '.';

export const useProfessions = () =>
  useQuery<Profession[]>(getProfessionsQueryKey(), async () => {
    const { data } = await getProfessions();
    return data;
  });
