import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../common/services';

import { ID } from '../../common/types';
import { ChatroomType, ParticipantBase } from '../../types';
import { ChatroomDataObject } from '../data-objects';

export type CreateChatroomRequest = {
  users: ID[];
  ref?: ID;
  type: ChatroomType;
  name?: string;
  participants: Record<string, ParticipantBase>
};

export const createChatroom = (data: CreateChatroomRequest) =>
  nodeApiClient.post<ChatroomDataObject, AxiosResponse<ChatroomDataObject>>(routes.chatrooms.create, data);
