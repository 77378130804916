import * as React from 'react';
import Popup from 'reactjs-popup';
import { Trans } from 'react-i18next';

import { FileService } from '../../services/FileService';
import { downloadFile } from '../file-downloader/FileDownloader';
import { Loader } from '../loader';

const CSV = 0;
const PDF = 1;

class Renderer extends React.Component {
  state = {
    open: false,
    downloadingPDF: false,
    downloadingCSV: false,
  };

  getQuery = () => {
    const filter = this.props.filter;
    const query = {};
    if (filter.search) {
      query.search = filter.search;
    }
    if (filter.campaignIds) {
      query.campaigns = filter.campaignIds;
    }
    if (filter.communityIds) {
      query.communities = filter.communityIds;
    }
    if (filter.from) {
      query['created_at[from]'] = filter.from;
    }
    if (filter.to) {
      query['created_at[to]'] = filter.to;
    }
    return query;
  };

  downloadPdf = async () => {
    if (this.state.downloadingPDF) {
      return;
    }

    this.setState({ downloadingPDF: true });
    await downloadFile(() => FileService.get('/donations.pdf', this.getQuery(), false), 'donations_report.pdf');
    this.setState({ downloadingPDF: false });
    this.closePopup();
  };

  downloadCSV = async () => {
    if (this.state.downloadingCSV) {
      return;
    }

    this.setState({ downloadingCSV: true });
    await downloadFile(() => FileService.get('/donations.csv', this.getQuery(), false), 'donations_report.csv');
    this.setState({ downloadingCSV: false });
    this.closePopup();
  };

  getLabel = (value) => {
    switch (value) {
      case CSV:
        return (
          <div
            className="export__menu-item"
            key={value}
            onClick={this.downloadCSV}
            data-test-id={`export_menu-item[csv]`}
          >
            <Trans>Excel</Trans>
            {this.state.downloadingCSV && <Loader size={12} />}
            <img alt="arrow down" src="/assets/xls.svg" className="export__menu-arrow" />
          </div>
        );
      case PDF:
        return (
          <div
            className="export__menu-item"
            key={value}
            onClick={this.downloadPdf}
            data-test-id={`export_menu-item[pdf]`}
          >
            <Trans>PDF</Trans>
            {this.state.downloadingPDF && <Loader size={12} />}
            <img alt="arrow down" src="/assets/pdf.svg" className="export__menu-arrow" />
          </div>
        );
      default:
        return <Trans>Today</Trans>;
    }
  };

  closePopup = () => {
    this.setState({ open: true }, () => this.setState({ open: false }));
  };

  render() {
    return (
      <Popup
        trigger={() => (
          <img alt="arrow down" src="/assets/download.svg" className="export__trigger" data-test-id="export-trigger" />
        )}
        position="bottom right"
        offsetY={10}
        open={this.state.open}
        closeOnDocumentClick={true}
        closeOnEscape={true}
        contentStyle={``}
        className={'export'}
        arrowStyle={{ color: '#22333c', filter: 'none' }}
      >
        {[CSV, PDF].map((menu) => this.getLabel(menu))}
      </Popup>
    );
  }
}

export const Export = Renderer;
