import { type ElementType } from 'react';

import { AxiosErrorHandler } from 'common/error-handlers';
import { type CommunityDataObject } from 'models/service';
import { Skeleton, Card } from 'ui';

import { useCommunityDataObjectQuery } from '../queries';

// This component is for backwards compatibility
// It's using directly CommunityDataObject for old components

type P<U> = {
  communityId: string;
  render: ElementType<{ community: CommunityDataObject }>;
  renderProps: U;
};

const CommunityProvider = <U,>({ communityId, render: View, renderProps }: P<U>) => {
  const { isLoading, error, data } = useCommunityDataObjectQuery(communityId);

  return (
    <AxiosErrorHandler error={error}>
      {isLoading || !data ? (
        <>
          <Card>
            <Skeleton active avatar={{ shape: 'circle' }} paragraph={false} />
          </Card>
          <Card>
            <Skeleton active avatar paragraph={{ rows: 12 }} />
          </Card>
        </>
      ) : (
        <View community={data} {...renderProps} />
      )}
    </AxiosErrorHandler>
  );
};

export default CommunityProvider;
