import * as React from 'react';
import { Trans } from 'react-i18next';

import { Card } from 'ui';
import { CircleIcon } from '../icon';
import { FeedCoreInternal } from './FeedCoreInternal';
import { ModalPostDetail } from '../../pages/modal-post-detail';
import { PostCardRenderer } from '../../atoms/feed-object';
import { Text } from '../text';
import { ThemeConsumer } from '../theme';

export class PinnedFeed extends React.Component {
  state = {
    visibleModal: false,
    contentObjectId: null,
  };

  onRequestClose = () => {
    this.setState({ visibleModal: false });
  };

  onRequestOpenDetail = (object) => {
    this.setState({ visibleModal: !!object, contentObjectId: object.id });
  };

  renderObject = (object, actions) => {
    if (object.type === 'event') {
      // This scenario should never happen anyway.
      console.warn('[App] Trying to render object of `event` type in pinned feed.');
      return null;
    }
    return (
      <div id={object.id} style={{ marginLeft: 20 }}>
        <Card style={{ width: 220, overflow: 'hidden' }} shrinked>
          <PostCardRenderer
            object={object}
            onInitiatePostEditing={this.props.onInitiatePostEditing}
            onRequestOpenDetail={this.onRequestOpenDetail}
          />
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <ThemeConsumer>
          {(theme) => (
            <Card
              shrinked
              title={
                <React.Fragment>
                  <CircleIcon
                    size={24}
                    name="pin-1"
                    color="white"
                    background={theme.color.lightGray}
                    style={{ marginRight: 10 }}
                  />
                  <Text
                    color={theme.color.lightGray}
                    size="12px"
                    weight="600"
                    lineHeight="14px"
                    style={{ textTransform: 'uppercase' }}
                  >
                    <Trans>Pinned posts</Trans>
                  </Text>
                </React.Fragment>
              }
            >
              <div
                style={{
                  padding: '0',
                  display: 'inline-flex',
                  flexDirection: 'row',
                  overflow: 'auto',
                  maxWidth: 0,
                  minWidth: '100%',
                  height: 225,
                  flexWrap: 'nowrap',
                }}
              >
                <FeedCoreInternal
                  keyPrefix={'pinned-feed'}
                  {...this.props}
                  direction="horizontal"
                  renderObject={this.renderObject}
                  reloadNumber={this.props.reloadNumber}
                />
              </div>
            </Card>
          )}
        </ThemeConsumer>
        <ModalPostDetail
          community={this.props.community}
          pinned={this.props.pinned}
          actions={this.props.actions}
          isOpen={this.state.visibleModal}
          objectId={this.state.contentObjectId}
          onRequestClose={this.onRequestClose}
          onInitiatePostEditing={this.props.onInitiatePostEditing}
          viewer={this.props.viewer}
        />
      </React.Fragment>
    );
  }
}
