import { type ChannelDetail } from 'models';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { Breadcrumb, Link } from 'ui';

type P = {
  channel: ChannelDetail;
};

const ChannelBreadcrumb = ({ channel }: P) => {
  const { t } = useTranslation();
  const breadcrumbRoutes = [
    {
      path: routes.channels.index,
      breadcrumbName: 'Channels',
    },
    {
      path: routes.channels.customChannelDetail(channel.id),
      breadcrumbName: channel.name,
    },
  ];

  return (
    <Breadcrumb
      routes={breadcrumbRoutes.map((route) => ({ ...route, breadcrumbName: t(route.breadcrumbName) }))}
      itemRender={({ path, breadcrumbName }: { path: string; breadcrumbName: string }) => {
        return <Link to={path}>{breadcrumbName}</Link>;
      }}
    />
  );
};

export default ChannelBreadcrumb;
