import { Link } from 'react-router-dom';
import { Avatar, Text } from 'ui';

type P = {
  name: string;
  link: string;
  photoUrl?: string;
};

const NavigationPill = ({ name, link, photoUrl }: P) => {
  return (
    <Link to={link} className="navigation-pill">
      {/* TODO avatar size hardcoded */}
      {photoUrl ? (
        <div className="navigation-pill__avatar">
          <Avatar size={16} photo={photoUrl} />
        </div>
      ) : null}

      <Text ellipsis>{name}</Text>
    </Link>
  );
};

export default NavigationPill;
