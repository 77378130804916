import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { SmallContent, BigContent, BigBrandContent } from '../text';

import { ReactComponent as GreenCheck } from 'assets/check-green.svg';
import { ReactComponent as PostIcon } from 'assets/course/post.svg';
import { ReactComponent as ActivityIcon } from 'assets/course/activity.svg';
import { ReactComponent as EventIcon } from 'assets/course/event.svg';

import { ReactComponent as PollIcon } from 'assets/course/poll.svg';
import { ReactComponent as WithingsOrderPostIcon } from 'assets/course/withings_order_post.svg';

const TEXT_PREVIEW_LENGTH = 60;

export class Renderer extends React.Component {
  getHeaderImgClassName() {
    switch (this.props.type) {
      case 'selected':
        return 'course-topic-post__header__play';
      case 'checked':
        return 'course-topic-post__header__check';
      case 'unchecked':
        return 'course-topic-post__header__check'; // TODO
      default:
        return;
    }
  }

  getHeaderPostImage() {
    switch (this.props.post.type) {
      case 'post':
        return <PostIcon className="course-topic-post__header__play" />;
      case 'withings_order_post':
        return <WithingsOrderPostIcon className="course-topic-post__header__play" />;
      case 'activity':
        return <ActivityIcon className="course-topic-post__header__play" />;
      case 'event':
        return <EventIcon className="course-topic-post__header__play" />;
      case 'poll':
        return <PollIcon className="course-topic-post__header__play" />;
      default:
        return <PostIcon className="course-topic-post__header__play" />;
    }
  }

  getHeaderImage() {
    switch (this.props.type) {
      case 'selected':
        return this.getHeaderPostImage();
      case 'checked':
        return <GreenCheck className="course-topic-post__header__check" />;
      case 'unchecked':
        return <GreenCheck className="course-topic-post__header__check" />; // TODO
      default:
        return;
    }
  }

  renderHeader() {
    return (
      <React.Fragment>
        {this.getHeaderImage()}
        {!this.props.last && <div className="course-topic-post__header__line" />}
      </React.Fragment>
    );
  }

  renderBodyHeader() {
    const { post } = this.props;

    let title;
    switch (post.type) {
      case 'post':
        title = post.post?.title ?? post.post?.text_content;
        break;
      case 'withings_order_post':
        title = post.withings_order_post?.title ?? post.withings_order_post?.text_content;
        break;
      case 'activity':
        title = post.activity?.name;
        break;
      case 'event':
        title = post.event?.name;
        break;
      case 'poll':
        title = post.poll?.title ?? post.poll?.description;
        break;
      default:
        title = post.text_content;
    }

    return (
      title && (
        <React.Fragment>
          {(this.props.type === 'selected' && (
            <BigBrandContent>
              {title.length > TEXT_PREVIEW_LENGTH ? `${title.substr(0, TEXT_PREVIEW_LENGTH)}...` : title}
            </BigBrandContent>
          )) || (
            <BigContent>
              {title.length > TEXT_PREVIEW_LENGTH ? `${title.substr(0, TEXT_PREVIEW_LENGTH)}...` : title}
            </BigContent>
          )}
        </React.Fragment>
      )
    );
  }

  renderBodyFooter() {
    // TODO what here with different types of post
    // TODO what here do with future / actual / passed ???
    let text;

    switch (this.props.type) {
      case 'selected':
      case 'checked':
      case 'unchecked':
        text = <Trans>Posted</Trans>;
        break;
      default:
        return;
    }

    return (
      <SmallContent>
        {text} • {this.props.t('dateFormatShortMonthDayYear', { date: new Date(this.props.post.publish_at) })}
      </SmallContent>
    );
  }

  render() {
    return (
      <div className="course-topic-post">
        <div className="course-topic-post__header">{this.renderHeader()}</div>
        <div className={`course-topic-post__body ${this.props.last ? 'course-topic-post__body--last' : ''}`}>
          <div className="course-topic-post__body__row">{this.renderBodyHeader()}</div>
          <div className="course-topic-post__body__row">{this.renderBodyFooter()}</div>
        </div>
      </div>
    );
  }
}

export const CourseTopicPost = withTranslation()(Renderer);
