import * as React from 'react';
import { Icon } from '../icon';
import { withTranslation } from 'react-i18next';

class Renderer extends React.Component {
  state = {
    isFocused: false,
  };

  setFocused = (isFocused) => {
    this.setState({ isFocused });
    this.props.onChangeFocus(isFocused);
  };

  render() {
    const placeholder = this.state.isFocused ? this.props.t('Search by user name or email...') : '';

    return (
      <div className={`search-input-animated ${this.state.isFocused ? 'search-input-animated--focused' : ''}`}>
        <Icon size={22} name="pba-search" color="#90A4AE" className="search-input-animated__icon" />
        <input
          id={this.props.id}
          name="search"
          type="text"
          className="search-input-animated__input"
          placeholder={placeholder}
          onFocus={() => this.setFocused(true)}
          onBlur={() => this.setFocused(false)}
          onKeyUp={(value) => this.props.onChange(value.target.value)}
        />
      </div>
    );
  }
}

export const SearchInputAnimated = withTranslation()(Renderer);
