import * as React from 'react';
import { Trans } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import { select } from '@rematch/select';

import { Modal } from '../../atoms/modal';
import { Loader } from '../../atoms/loader';
import { ChequePledgeForm } from '../../atoms/cheque-pledge-form';
import { ChequePledgeList } from './ChequePledgeList';
import { CampaignsLayout } from '../../layouts/CampaignsLayout';
import { showSuccessErrorAlert } from '../../atoms/alert';
import { CampaignsSideNav } from '../admin-page-campaigns/CampaignsSideNav';

class Renderer extends React.Component {
  state = {
    donation: null,
  };

  componentDidMount() {
    this.props.getDonations();
  }

  openEditChequeModal = (donation) => this.setState({ donation: donation });

  editCheque = async (donation) => {
    const response = await this.props.updateChequePledge(this.state.donation.id, donation);
    showSuccessErrorAlert({
      successMessage: <Trans>Check donation successfully updated.</Trans>,
      errorMessage: <Trans>Check cannot be updated right now. Please try again later.</Trans>,
      isOk: response.ok,
    });
    this.setState({ donation: null });
  };

  deleteCheque = async (donation) => {
    const response = await this.props.deleteChequePledge(donation.id);
    showSuccessErrorAlert({
      successMessage: <Trans>Check donation successfully deleted.</Trans>,
      errorMessage: <Trans>Check cannot be deleted right now. Please try again later.</Trans>,
      isOk: response.ok,
    });
  };

  renderChequePledge = () => {
    const { chequePledgeDonations, viewer } = this.props;
    const editableDonations = viewer.superadmin
      ? chequePledgeDonations
      : chequePledgeDonations.filter(
          (donation) =>
            viewer.admin_organizations.includes(donation.campaign.community.organization_id) ||
            viewer.admin_communities.includes(donation.campaign.community_id),
        );

    return (
      <ChequePledgeList
        donations={editableDonations}
        editCheque={this.openEditChequeModal}
        deleteCheque={this.deleteCheque}
      />
    );
  };

  render() {
    const displayLoader = !!this.props.loadingCollection;
    const campaignsByOrganization = this.props.campaignsByOrganization;
    return (
      <CampaignsLayout
        campaignsByOrganization={this.props.campaignsByOrganization}
        navigation={
          <CampaignsSideNav
            organizationId={this.props.match.params.organizationId}
            displayLoader={displayLoader}
            campaignsByOrganization={campaignsByOrganization}
          />
        }
      >
        {this.props.loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <React.Fragment>{this.renderChequePledge()}</React.Fragment>
            <Modal
              title={<Trans>Check donation</Trans>}
              isOpen={!!this.state.donation}
              onRequestClose={() => this.setState({ donation: null })}
              showFooter={false}
            >
              {(modal) => <ChequePledgeForm onSubmit={this.editCheque} donation={this.state.donation} />}
            </Modal>
          </React.Fragment>
        )}
      </CampaignsLayout>
    );
  }
}

const mapState = (state, props) => ({
  viewer: select.session.user(state),
  loading: state.loading.effects.donations.get,
  chequePledgeDonations: select.donations.chequePledgeDonations(state),
  campaignsByOrganization: select.campaigns.getBy(state, 'organization'),
});

const mapDispatch = (dispatch, props) => ({
  updateChequePledge: (id, donation) => dispatch.donations.update({ id, donation }),
  deleteChequePledge: (id) => dispatch.donations.deleteDonation(id),
  getDonations: () => dispatch.donations.get({ filter: {} }),
});

export const PageChequePledge = connect(mapState, mapDispatch)(Renderer);
