import { AutocompleteUser } from 'models';
import { useQuery } from 'query';

import { getProgramUsers } from '../services';

import { getProgramUsersKey } from '.';

export const useEventProgramUsers = (
  {
    programId,
    programAdmins,
  }: {
    programId: string;
    programAdmins?: AutocompleteUser[];
  },
  options?: { useErrorBoundary?: boolean },
) => {
  return useQuery<AutocompleteUser[]>(
    getProgramUsersKey({ programId, programAdminsIds: programAdmins?.map((programAdmin) => programAdmin.id) }),
    async () => {
      const programUsersResponse = await getProgramUsers(programId);

      // TODO program admins are not in response of program users
      const allUsers: AutocompleteUser[] = [
        ...(programUsersResponse.data.users?.map((user: any) => new AutocompleteUser(user)) || []),
      ];

      for (const admin of programAdmins ?? []) {
        if (allUsers.every((userData: AutocompleteUser) => userData.id !== admin.id)) {
          allUsers.push(admin);
        }
      }

      return allUsers;
    },
    {
      // TODO this is set to `always` program users can be changed and React Query is not notified about changes
      refetchOnMount: 'always',
      useErrorBoundary: true,
      ...options,
    },
  );
};
