import withAntIconWrapper from '../icon-wrapper/withAntIconWrapper';

import { ReactComponent as CheckPasswordIconImport } from './check_circle_line.svg';
import { ReactComponent as ClosePasswordIconImport } from './close_circle_line.svg';
import { ReactComponent as EyeOpenIconImport } from './eye_2_line.svg';
import { ReactComponent as EyeCloseIconImport } from './eye_close_line.svg';

export const CheckPasswordIcon = withAntIconWrapper(CheckPasswordIconImport);
export const ClosePasswordIcon = withAntIconWrapper(ClosePasswordIconImport);
export const EyeCloseIcon = withAntIconWrapper(EyeCloseIconImport);
export const EyeOpenIcon = withAntIconWrapper(EyeOpenIconImport);
