import { apiClient } from 'common/services';

export const FileService = {
  get: async (url, params = {}, skipAuth = true) => {
    let requestParams = {
      url: url,
      method: 'GET',
      responseType: 'blob',
      params: params,
      skipAuth,
    };

    return apiClient(requestParams);
  },
};
