import { type Profile } from 'models';
import { useResetQuery } from 'query';
import { useCallback } from 'react';
import { handleAxiosError } from 'common/error-handlers';
import { ConsentsForm } from 'features/consents/components';
import { useConsentsQuery } from 'features/consents/queries';
import { type SupportedOboardingTourStep } from '../../../models';
import { useUpdateOnboardingStep } from '../../../queries';
import { ConsentsStepSkeleton } from './ConsentsStepSkeleton';

type P = {
  viewer: Profile;
  step: SupportedOboardingTourStep;
  onConsent: (id?: string) => void;
  onSuccess: () => void;
};

const ConsentsStep = ({ viewer, step, onConsent, onSuccess }: P) => {
  const { data: consents, isLoading: isLoadingConsents } = useConsentsQuery({
    consentIds: viewer.requiredConsents.map((consent) => consent.id),
  });
  const { mutateAsync: resetQuery } = useResetQuery();
  const { mutateAsync: updateStep } = useUpdateOnboardingStep();

  const onAccept = useCallback(async () => {
    try {
      await updateStep({ id: step.data.id, status: 'completed' });
      await resetQuery({ cacheResetOption: 'invalidate' });
      onSuccess();
    } catch (error) {
      if (error instanceof Error) {
        handleAxiosError(error);
      }
    }
  }, [onSuccess, resetQuery, step.data.id, updateStep]);

  if (isLoadingConsents || !consents) {
    return <ConsentsStepSkeleton />;
  }

  return (
    <ConsentsForm
      onAccept={onAccept}
      onConsent={onConsent}
      showTitle={false}
      consents={consents}
      currentAcceptedConsents={viewer.usersConsents}
      entities={[]}
      showInfoMessage={false}
      submit={{ block: true }}
    />
  );
};

export default ConsentsStep;
