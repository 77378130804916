import { useEffect, useState, FC, MutableRefObject } from 'react';

import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { MessageInput } from '..';
import { createChatroom, sendMessage } from '../../actions';
import { Profile, User } from '../../common/models';
import { DataState } from '../../common/recoil/queries';
import { activePracticeIdState } from '../../common/recoil/selectors';
import { ID } from '../../common/types';
import { failedMessageFromCreating } from '../../recoil/atoms';
import { chatroomsState, newChatroomAddedState } from '../../recoil/selectors';
import { ChatroomMessage, ParticipantBase } from '../../types';
import { message as messagePopUp } from '../../ui';
import Header from './Header';
import { ChatroomState } from '../../../index';

type P = {
  currentUserProfileData: DataState<Profile>;
  defaultNewChatroomUserRef?: MutableRefObject<User | undefined>;
  handleChatState: (chatStateToApply: ChatroomState, id?: ID) => void;
};

const ChatroomCreating: FC<P> = ({ currentUserProfileData, defaultNewChatroomUserRef, handleChatState }) => {
  const [members, setMembers] = useState<User[]>([]);
  const activePracticeId = useRecoilValue(activePracticeIdState);
  const addNewChatroom = useSetRecoilState(newChatroomAddedState);
  const reloadChatrooms = useResetRecoilState(chatroomsState);
  const setFailedMessage = useSetRecoilState(failedMessageFromCreating);

  useEffect(() => {
    if (defaultNewChatroomUserRef?.current) {
      setMembers([defaultNewChatroomUserRef.current]);
    }
  }, [defaultNewChatroomUserRef]);

  const handleCancelClick = () => {
    handleChatState('default');
    if (defaultNewChatroomUserRef) {
      defaultNewChatroomUserRef.current = undefined;
    }
  };

  const handleAddMembersToNewChatroom = (membersToAdd: User[]) => {
    setMembers(membersToAdd.map((member) => member));
  };

  const handleSendMessage = async (data: ChatroomMessage) => {
    const initialValue: Record<string, ParticipantBase> = currentUserProfileData.result ? {
      [currentUserProfileData.result._id]: {
        firstName: currentUserProfileData.result.firstName,
        lastName: currentUserProfileData.result.lastName,
      }
    } : {};

    const chatroomResponse = await createChatroom({
      users: members.map((member) => member._id),
      type: 'practice',
      participants: members.reduce((participants, member) => {
        participants[member._id] = {
          firstName: member.firstName,
          lastName: member.lastName,
        }
        
        return participants;
      }, initialValue),
      ...(activePracticeId && { ref: activePracticeId }),
    });
    const isNew = !chatroomResponse.result?.chatroom.message && !chatroomResponse.result?.chatroom.stats;
    if (chatroomResponse.error) {
      return messagePopUp.error('chat.chatroomCreating.messages.error');
    }

    const chatRoomData = chatroomResponse.result;
    const chatRoomId = chatRoomData.chatroom._id;

    const response = await sendMessage(data, chatRoomId);

    addNewChatroom({ chatroom: chatRoomData.chatroom, message: response.error ? undefined : response.result });
    reloadChatrooms();
    handleChatState('chatroom', chatRoomId);
    if (response.error) {
      if (currentUserProfileData.error) {
        return messagePopUp.error('chat.chatroomDetail.messages.messageSendError');
      }

      setFailedMessage({
        text: data.text,
        payload: data.payload,
        author: currentUserProfileData.result._id,
        status: 'failed',
        type: 'message',
        createdAt: new Date(),
        updatedAt: new Date(),
        _id: '0',
        chatroom: chatRoomId,
      });
      return messagePopUp.error('chat.chatroomDetail.messages.messageSendError');
    }

    return messagePopUp.success(
      isNew ? 'chat.chatroomCreating.messages.success' : 'chat.chatroomCreating.messages.redirect',
    );
  };

  const renderMessageInput = () => {
    if (members.length > 0) {
      return <MessageInput onSendClick={handleSendMessage} />;
    }

    return null;
  };

  return (
    <div className="chatroom-creating">
      {!currentUserProfileData.error && (
        <Header
          members={members}
          onMembersChange={handleAddMembersToNewChatroom}
          onCancelClick={handleCancelClick}
          currentUserProfile={currentUserProfileData.result}
          defaultNewChatroomUser={defaultNewChatroomUserRef?.current}
        />
      )}
      <div className="chatroom-creating__messages" />
      {renderMessageInput()}
    </div>
  );
};

export default ChatroomCreating;
