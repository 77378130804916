import moment, { type Moment } from 'moment';

function range(start: number, end: number) {
  const result = [];
  for (let index = start; index < end; index++) {
    result.push(index);
  }

  return result;
}

export function disabledDate(minimum?: Moment, maximum?: Moment) {
  if (minimum && maximum) {
    return (option: Moment) =>
      option && option < minimum.clone().startOf('day') && option > maximum.clone().startOf('day');
  }

  if (minimum) {
    return (option: Moment) => option && option < minimum.clone().startOf('day');
  }

  if (maximum) {
    return (option: Moment) => option && option > maximum.clone().startOf('day');
  }

  return undefined;
}

export function disabledTime(minimum?: Moment) {
  if (!minimum) {
    return minimum;
  }

  return (option: Moment | null) => {
    if (!option) {
      option = moment();
    }

    const minimumHours = minimum.hours();

    if (minimum.isSame(option, 'date')) {
      const disabledHours = () => range(0, minimumHours);

      if (option.isSame(minimum, 'hour')) {
        return {
          disabledHours,
          disabledMinutes: () => range(0, 60).slice(0, minimum.get('minute')),
        };
      }

      if (option.isBefore(minimum, 'hour')) {
        return {
          disabledHours,
          disabledMinutes: () => range(0, 60),
        };
      }

      return {
        disabledHours,
        disabledMinutes: () => [],
      };
    }

    if (option.isBefore(minimum, 'date')) {
      return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 60),
      };
    }

    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };
}
