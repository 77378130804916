import cs from 'classnames';
import variables from 'common/styles/variables.json';
import { Row, Col } from 'ui';

type P = {
  headerClassName?: string;
  asideRowClassName?: string;
  asideLeftColClassName?: string;
  asideRightColClassName?: string;
  children?: React.ReactNode;
  aside?: React.ReactNode;
  header?: React.ReactNode;
};

const ContentWrapper = ({
  headerClassName,
  asideRowClassName,
  asideLeftColClassName,
  asideRightColClassName,
  children,
  aside,
  header,
}: P) => (
  <div className="content-wrapper">
    {header ? (
      <Row>
        <Col item={{ className: cs('content-wrapper__header', headerClassName) }}>{header}</Col>
      </Row>
    ) : null}
    {aside ? (
      <Row item={{ wrap: false, gutter: variables.spaceMd.value, className: cs('content-wrapper', asideRowClassName) }}>
        <Col
          item={{
            className: cs('content-wrapper__aside--left', asideLeftColClassName),
          }}
        >
          {children}
        </Col>
        <Col
          item={{
            className: cs('content-wrapper__aside--right', asideRightColClassName),
          }}
        >
          {aside}
        </Col>
      </Row>
    ) : (
      children
    )}
  </div>
);

export default ContentWrapper;
