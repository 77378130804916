import { UploadFileStatus } from 'antd/lib/upload/interface';
import { useState, ChangeEvent, useEffect, useRef, useLayoutEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { uploadFile, deleteFile } from '../../actions';
import { ApiClientResponse } from '../../common/services';
import { Media, MessagePayload } from '../../models';
import { CreateMediaRequest } from '../../services';
import { ChatroomMessage } from '../../types';
import {
  RcFile,
  UploadChangeParam as UploadChangeParameter,
  RcCustomRequestOptions,
  Button,
  Col,
  Input,
  TextAreaRef as TextAreaReference,
  Row,
  Upload,
  message as messagePopUp,
  Modal,
} from '../../ui';

import { AttachmentIcon, SendIcon } from '../../ui/Icons';
import { isStringEmptyOrOnlyWhiteSpace, getImageDimensions } from '../../utils';

type P = {
  onSendClick: (data: ChatroomMessage) => void;
  onFileListChange?: (value: boolean) => void;
};

export interface UploadFile2<T> {
  uid: string;
  size?: number;
  name: string;
  fileName?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  url?: string;
  status?: UploadFileStatus;
  percent?: number;
  thumbUrl?: string;
  originFileObj?: RcFile;
  response?: T;
  error?: never;
  linkProps?: never;
  type?: string;
  xhr?: T;
  preview?: string;
}

const MessageInput = ({ onSendClick, onFileListChange }: P) => {
  const { t } = useTranslation(['translationChat']);
  const [message, setMessage] = useState<string | undefined>(undefined);
  // TODO fix type for fileList variable
  const [fileList, setFileList] = useState<UploadFile2<UploadFile2<Media>>[]>([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [cursor, setCursor] = useState<number | null>(null);
  const textAreaReference = useRef<TextAreaReference>(null);
  const textAreaElement = textAreaReference.current?.resizableTextArea?.textArea;

  const isSendDisabled =
    isUploading || message ? isStringEmptyOrOnlyWhiteSpace(message as string) : fileList.length === 0;

  useLayoutEffect(() => {
    if (textAreaElement) {
      textAreaElement.setSelectionRange(cursor, cursor);
    }
  }, [cursor]);

  useEffect(() => {
    if (textAreaElement) {
      textAreaElement.scrollTop = textAreaElement.scrollHeight;
    }
  }, [message]);

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);

  const handleOnChange = (event: UploadChangeParameter) => {
    setFileList(event.fileList as UploadFile2<UploadFile2<Media>>[]);
    onFileListChange?.(event.fileList.length > 0);
  };

  const clearData = () => {
    setFileList([]);
    setMessage(undefined);
    onFileListChange?.(false);
  };

  const handleDelete = async (file: { uid: string }) => {
    const itemToDelete = fileList.find((fileFromList) => fileFromList.uid === file.uid);
    if (itemToDelete?.status !== 'error') {
      return itemToDelete?.xhr?.response
        ? deleteFile(itemToDelete.xhr.response._id)
        : messagePopUp.error('chat.chatroomCreating.attachments.mediaDeleteError');
    }
  };

  const handleCustomRequest = async (options: RcCustomRequestOptions): Promise<void> => {
    setIsUploading(true);
    const { onSuccess, onError, file, onProgress } = options;
    const response: ApiClientResponse<Media> = await uploadFile(file as CreateMediaRequest, onProgress);

    let width;
    let height;
    if (file instanceof Blob && file.type.startsWith('image/')) {
      const proportions = await getImageDimensions(file);
      width = proportions.width;
      height = proportions.height;
    }

    // TODO fix case when response is string - 0.21.4 version is problematic.
    //  Axios response that is saved from response.request.response is coming as stringified JSON instead of an object.
    //  For now I made condition to parse it but it would need more systematical approach if this is intended change from axios devs.
    //  If not, we would want to check upcoming updates.
    //  https://github.com/axios/axios/commit/5ad6994da3e97b8ed857faed90955a65a891f459#diff-b34f2f53ab94368c86775969fb604e8375abe03b6a378bdd09896fd91ac0a0d2
    if (response.ok && response.request) {
      const responseRequest = response.request as XMLHttpRequest;

      onSuccess?.(
        'Ok',
        responseRequest.response && typeof responseRequest.response === 'string'
          ? ({
              ...responseRequest,
              response: {
                ...JSON.parse(responseRequest.response),
                height,
                width,
              },
            } as XMLHttpRequest)
          : ({ ...responseRequest, response: { ...responseRequest.response, height, width } } as XMLHttpRequest),
      );
    } else {
      onError?.(new Error(response.statusText));
    }

    setIsUploading(false);
  };

  const handleSendClick = () => {
    if (!isSendDisabled) {
      const dataToProcess: (Media | null)[] = fileList
        .map((item) => (item.xhr ? item.xhr.response : null))
        .filter((item): item is Media => item !== undefined);
      const dataForPayload: MessagePayload[] = dataToProcess.map(
        (dataItem): MessagePayload => ({
          ref: dataItem?._id,
          type: 'media',
          meta: {
            mimeType: dataItem?.mimeType,
            name: dataItem?.name,
            alts: [],
            height: dataItem?.height,
            width: dataItem?.width,
          },
        }),
      );

      onSendClick({ text: message ? message.trim() : null, payload: dataForPayload });

      clearData();
    }
  };

  const getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', (error) => {
        reject(error);
        messagePopUp.error('chat.chatroomDetail.attachments.previewDisplayError');
      });
    });
  };

  const handlePreview = async (file: { name: string; url?: string; originFileObj?: RcFile; preview?: string }) => {
    if (!file.url && !file.preview && file.originFileObj) {
      file.preview = (await getBase64(file.originFileObj)) as string;
    }

    if (file.url) {
      setPreviewImage(file.url);
    } else if (file.preview) {
      setPreviewImage(file.preview);
    } else {
      // Do nothing
    }

    setIsPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const handleKeyPress = (event: { preventDefault: () => void; key: string; shiftKey: boolean; altKey: boolean }) => {
    if (!textAreaElement) {
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();

      if (event.shiftKey || (navigator.userAgent.includes('Mac') && event.altKey)) {
        const startPoint = textAreaElement.selectionStart;
        const endPoint = textAreaElement.selectionEnd;

        const messageWithLineBreak = message?.substring(0, startPoint) + '\n' + message?.substring(endPoint);

        const newCursorPosition = startPoint + 1;
        setCursor(newCursorPosition);

        return setMessage(`${messageWithLineBreak ? messageWithLineBreak : ''}`);
      }

      return handleSendClick();
    }
  };

  return (
    <div className="chatroom-detail__input-wrapper">
      <Modal open={isPreviewOpen} title={previewTitle} footer={null} onCancel={() => setIsPreviewOpen(false)}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      {fileList.length > 0 && (
        <Row className="chatroom-detail__attachments">
          <Upload
            multiple
            listType="picture-card"
            fileList={fileList}
            onChange={handleOnChange}
            onRemove={handleDelete}
            onPreview={handlePreview}
          />
        </Row>
      )}
      <Row className="chatroom-detail__input">
        <Col flex={'0 0 auto'}>
          <Upload
            multiple
            fileList={fileList}
            customRequest={handleCustomRequest}
            onChange={handleOnChange}
            showUploadList={false}
          >
            <Button type="link" className="icon icon--attachment" size="large">
              <AttachmentIcon />
            </Button>
          </Upload>
        </Col>
        <Col flex={'1'} className="col--message-input">
          <Input.TextArea
            id="chatroomMessageInput"
            ref={textAreaReference}
            className="chatroom-message-input"
            placeholder={t('chat.chatroomDetail.messageInput')}
            size={'large'}
            value={message}
            onChange={handleMessageChange}
            autoSize={{ minRows: 1, maxRows: 8 }}
            onKeyDown={handleKeyPress}
          />
        </Col>
        <Col flex={'0 0 auto'}>
          <Button
            type="link"
            className="icon icon--send"
            onClick={() => handleSendClick()}
            disabled={isSendDisabled}
            size="large"
          >
            <SendIcon />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MessageInput;
