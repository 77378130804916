import { useMemo, useState, useEffect } from 'react';

import { Modal, message, Stepper } from 'ui';
import {
  SubscribeInfo,
  PersonalInfo,
  SubscribeResult,
  DeliveryInfo,
  Summary,
  type InfoFormData,
  type PersonalFormData,
  type DeliveryFormData,
} from './components';
import { useSubscribeQuery } from './queries';
import { type Gender } from './types';
import { convertFeetToInches } from './utils/inches';

const minStep = 0;
const maxStep = 4;

type P = {
  isOpened: boolean;
  programId: string;
  user: {
    first_name: string;
    last_name: string;
    email: string;
    country?: string;
    state?: string;
    gender?: {
      name: string;
    };
    phone?: string;
    street?: string;
    apt?: string;
    city?: string;
    zip?: string;
  };
  onCancel: () => void;
  onSuccess: () => void;
};

const Subscribe = ({ user, programId, isOpened, onCancel, onSuccess }: P) => {
  const {
    mutate: subscribe,
    isLoading: isSubscribing,
    error: subscribeError,
    isSuccess: isSubscribeSuccess,
  } = useSubscribeQuery();

  const [step, setStep] = useState(minStep);
  const [data, setData] = useState<{
    info?: InfoFormData;
    personal?: PersonalFormData;
    delivery?: DeliveryFormData;
  }>({});

  const gender: Gender | undefined = useMemo(() => {
    switch (user.gender?.name) {
      case 'Female':
        return 'female';
      case 'Male':
        return 'male';
      default:
        return undefined;
    }
  }, [user.gender]);

  const goToNextStep = useMemo(
    () => () => {
      setStep((currentStep) => Math.min(currentStep + 1, maxStep));
    },
    [setStep],
  );
  const goToPreviousStep = useMemo(
    () => () => {
      setStep((currentStep) => Math.max(currentStep - 1, minStep));
    },
    [setStep],
  );

  useEffect(() => {
    if (subscribeError) {
      if (Number(subscribeError.response?.status) < 500 && subscribeError.response?.data.message) {
        message.error(subscribeError.response?.data.message);
      } else {
        message.error('Something went wrong, please try again later.');
      }
    }
  }, [subscribeError]);

  useEffect(() => {
    if (isSubscribeSuccess && step === 3) {
      goToNextStep();
    }
  }, [subscribeError, isSubscribeSuccess, goToNextStep, step]);

  const handleInfo = (values: InfoFormData) => {
    setData({
      ...data,
      info: values,
    });
    goToNextStep();
  };

  const handlePersonalInfo = (values: PersonalFormData) => {
    setData({
      ...data,
      personal: values,
    });
    goToNextStep();
  };

  const handleDeliveryInfo = (values: DeliveryFormData) => {
    setData({
      ...data,
      delivery: values,
    });
    goToNextStep();
  };

  const handleSummary = () => {
    if (data.info && data.delivery && data.personal) {
      subscribe({
        programId,
        infoData: data.info,
        personalData: {
          ...data.personal,
          heightInImperial:
            convertFeetToInches(data.personal.heightInImperial.feet) + data.personal.heightInImperial.inches,
        },
        deliveryData: data.delivery,
      });
    }
  };

  const handleFinishResult = () => {
    onSuccess();
  };

  const handleBackClick = () => {
    goToPreviousStep();
  };

  const generateStepperContent = (step: number) => {
    switch (step) {
      case 0:
        return <SubscribeInfo initialData={data.info} onSubmit={handleInfo} />;
      case 1:
        return (
          <PersonalInfo
            user={{ firstName: user.first_name, lastName: user.last_name, gender }}
            initialData={data.personal}
            onBack={handleBackClick}
            onSubmit={handlePersonalInfo}
          />
        );
      case 2:
        return (
          <DeliveryInfo
            user={{
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              country: user.country,
              state: user.state,
              phone: user.phone,
              street: user.street,
              apt: user.apt,
              city: user.city,
              zip: user.zip,
            }}
            initialData={data.delivery}
            onBack={handleBackClick}
            onSubmit={handleDeliveryInfo}
          />
        );
      default:
        return <Summary data={data} onBack={handleBackClick} onSubmit={handleSummary} />;
    }
  };

  const steps = [
    <Stepper
      currentStep={step}
      numberOfSteps={4}
      getStep={generateStepperContent}
      onNavigateTo={(step) => {
        setStep(step);
      }}
      key="stepper"
    />,
    <SubscribeResult onSubmit={handleFinishResult} key="result" />,
  ];

  const handleModalClose = () => {
    setStep(minStep);
    setData({});

    if (step === maxStep) {
      onSuccess();
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      title="Order request"
      isOpened={isOpened}
      onCancel={handleModalClose}
      step={step === maxStep ? 1 : 0}
      steps={steps}
      loading={isSubscribing}
      disableBack={step === 0 || step === maxStep}
      onBackClick={handleBackClick}
      maskClosable={false}
      destroyOnClose
    />
  );
};

export default Subscribe;
