import * as React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '../../atoms/box';
import { Icon } from '../../atoms/icon';
import { Menu, MenuButton, MenuList, MenuItem } from '../../atoms/menu';
import { ThemeConsumer } from '../../atoms/theme';
import { images } from 'common/utils';
import { Link } from 'ui';

export class CampaignBox extends React.Component {
  render() {
    const { campaign } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => (
          <Box
            id={`campaign-box-${campaign.id}`}
            className="admin-campaign-box"
            key={campaign.id}
            flexDirection="row"
            style={{
              backgroundColor: theme.color.brand,
              backgroundImage: `url(${
                campaign.cover_photo ? `${campaign.cover_photo.url}` : images.default.campaignCoverPhoto
              })`,
            }}
          >
            <div className="admin-campaign-box__wrapper">
              <Box flex="1" flexDirection="row" style={{ alignItems: 'flex-end' }}>
                <Link
                  to={`/campaigns/listing/${campaign.organization.id}/${campaign.id}`}
                  className="admin-campaign-box__name"
                >
                  {campaign.name}
                </Link>
              </Box>
              <Box flexDirection="column" style={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Menu>
                  <MenuButton style={{ border: 0, background: 'none' }}>
                    <Icon name="menu" size={20} />
                  </MenuButton>
                  {campaign.finished ? (
                    <MenuList>
                      <MenuItem key={'edit'} onSelect={() => this.props.editCampaign(campaign)}>
                        <Trans>Edit</Trans>
                      </MenuItem>
                    </MenuList>
                  ) : (
                    <MenuList>
                      <MenuItem key={'edit'} onSelect={() => this.props.editCampaign(campaign)}>
                        <Trans>Edit</Trans>
                      </MenuItem>
                      <MenuItem key={'finish'} onSelect={() => this.props.finishCampaign(campaign)}>
                        <Trans>Finish</Trans>
                      </MenuItem>
                      <MenuItem key={'check'} onSelect={() => this.props.addCheque(campaign)}>
                        <Trans>Record Check Donation</Trans>
                      </MenuItem>
                    </MenuList>
                  )}
                </Menu>
              </Box>
            </div>
          </Box>
        )}
      </ThemeConsumer>
    );
  }
}
