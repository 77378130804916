import { normalize, denormalize } from 'normalizr';

import { OrganizationService } from '../services/OrganizationService';
import { CategoriesService } from '../services/CategoriesService';
import { OrganizationSchema } from './schemas';
import { dispatch, getState } from '../store';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [OrganizationSchema] : OrganizationSchema);
  dispatch.entities.mergeEntities(normalizedData.entities);
  return normalizedData.result;
}

const INITIAL_STATE = {
  all: [],
  invitations: [],
};

export const model = {
  name: 'organizations',
  state: INITIAL_STATE,
  reducers: {
    'session/reset': () => INITIAL_STATE,
    set: (state, payload) => payload,
    setAll: (state, payload) => ({ ...state, all: payload }),
  },
  selectors: {
    all: (state) => denormalize(state.all, [OrganizationSchema], getState().entities),
    find: (state, id) => denormalize(id, OrganizationSchema, getState().entities),
    getByCategory(state) {
      return denormalize(state, [OrganizationSchema], getState().entities);
    },
  },
  effects: (dispatch) => ({
    async getAsync() {
      const response = await OrganizationService.get();

      if (response.ok) {
        this.setAll(normalizeData(response.data));
      }

      return response;
    },

    async getByCategory(data) {
      const response = await OrganizationService.getByCategory({
        category_id: data.category_id,
      });

      if (response.ok) {
        this.set(normalizeData(response.data));
      }

      return response;
    },

    async fetchByCategoriesAsync(payload) {
      const response = await CategoriesService.organizations(payload);

      if (response.ok) {
        this.setAll(normalizeData(response.data));
      }

      return response;
    },

    async joinAsync(id) {
      const response = await OrganizationService.join(id);
      await dispatch.session.fetchUser();

      if (response.ok) {
        dispatch.entities.updateEntity({
          organizations: {
            [id]: { $merge: { joined: true } },
          },
        });
      }

      return response;
    },

    async findAsync(id) {
      const response = await OrganizationService.find(id);

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    async inviteNonRegisteredUsers(params) {
      const { organization_id, emails } = params;
      const response = await OrganizationService.inviteNonRegistered(organization_id, emails);
      return response;
    },
  }),
};
