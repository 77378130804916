import * as React from 'react';
import { Box } from '../box';
import { CommunityPreview } from '../community-preview';
import { CommunityService } from '../../services/CommunityService';
import { Modal } from '../modal';
import { OrganizationPreview } from '../organization-preview';
import { OrganizationService } from '../../services/OrganizationService';
import { ThemeConsumer } from '../theme';
import { message } from 'ui';
import { dispatch } from '../../store';
import { changeRequest } from 'common/services';

const styles = {
  modal: {
    content: {
      borderRadius: 10,
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0.5px 10px 0 rgba(0,0,0,0.13)',
      top: 0,
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, 0%)',
      padding: 0,
      border: 'none',
    },
    overlay: {
      zIndex: 10,
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
};

export class OrganizationModalDeprecated extends React.Component {
  state = {
    data: this.props.data,
    loading: false,
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.data && (prevProps.type === this.props.type || !this.props.isOpen)) {
      return;
    }

    if (this.props.data) {
      this.setState({ data: this.props.data, loading: true });
      await this.fetchData(this.props.type)
    }
  }

  onCommunityClick = (id) => {
    const community = (this.state.data.communities || []).find((community) => community.id === id);
    return this.props.onCommunityClick?.(community);
  };

  onJoinNow = async (community, params, isRequest) => {
    if (isRequest) {
      try {
        await changeRequest({
          kind: 'community_request_to_join',
          subject_type: 'Community',
          subject_id: community.id,
          meta: {
            reason_to_join: params.reason,
          },
        });
        await dispatch.session.fetchUser();
      } catch (error) {
        message.error('There was an error sending request to join community');
      }
    } else {
      await this.props.onJoinNow(community, params);
    }

    if (this.props.data) {
      await this.fetchData('community');
    }
  };

  fetchData = async (type) => {
    this.setState({ loading: true });
    const response =
      type === 'organization'
        ? await OrganizationService.find(this.props.data.id)
        : await CommunityService.find(this.props.data.id);

    if (response.ok) {
      this.setState({ data: response.data, loading: false });
    }
  }

  render() {
    const { type } = this.props;
    const { data, loading } = this.state;

    return (
      <ThemeConsumer>
        {() => (
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={styles.modal}
            closeTimeoutMS={500}
            submitButtonProps={{ title: 'Close' }}
            padding="0"
          >
            {() => (
              <Box style={{ width: '100%' }}>
                {type === 'organization' ? (
                  <OrganizationPreview
                    data={data}
                    loading={loading}
                    onJoinNow={(organization) => this.props.onJoinNow(organization, () => this.fetchData('organization'))}
                    onCommunityClick={this.onCommunityClick}
                  />
                ) : (
                  <CommunityPreview
                    data={data}
                    onJoinNow={this.onJoinNow}
                    onExploring={this.props.onExploring}
                    loading={loading}
                  />
                )}
              </Box>
            )}
          </Modal>
        )}
      </ThemeConsumer>
    );
  }
}
