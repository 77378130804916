import { useQueryClient } from '@tanstack/react-query';
import { AzureService } from 'old/services/AzureService';
import { useMutation } from 'query';
import { getCalendarSyncQueryKey } from '..';
import { createCalendarSync } from '../../services';

export const useCalendarAzureLoginMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, { redirectUrl?: string }, Error>(
    async ({ redirectUrl }) => {
      const responseAzure = await AzureService.login({
        scope: ['Calendars.ReadWrite', 'offline_access'],
        redirectUri: redirectUrl,
      });
      const respone = await createCalendarSync(responseAzure);

      return respone.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(getCalendarSyncQueryKey());
      },
    },
  );
};
