import { Typography } from 'antd';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

type P = React.ComponentProps<typeof Typography.Title> & {
  marginTop?: boolean;
  marginBottom?: boolean;
};

const Title = ({ marginBottom = true, marginTop = true, className, children, ...rest }: P) => {
  const { t } = useTranslation();

  return (
    <Typography.Title
      {...rest}
      className={cs(className, {
        'ant-typography-title': true,
        'margin-top--disabled': !marginTop,
        'margin-bottom--disabled': !marginBottom,
      })}
    >
      {typeof children === 'string' ? t(children) : children}
    </Typography.Title>
  );
};

export default Title;
