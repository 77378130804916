import { useResetQuery } from 'query';
import { useCallback, useState } from 'react';
import variables from 'common/styles/variables.json';
import { useCalendarSyncQuery } from 'features/user-profile/queries';
import { Button, Col, Row } from 'ui';
import { type SupportedOboardingTourStep } from '../../../models';
import { useUpdateOnboardingStep } from '../../../queries';
import { CalendarOption } from './CalendarOption';
import { CalendarStepSkeleton } from './CalendarStepSkeleton';
import { useCalendarOptionProperties, useCalendarOptions } from './hooks';
import { OtherCalendarModal } from './OtherCalendarModal';

type P = {
  step: SupportedOboardingTourStep;
  onSuccess: () => void;
};

const CalendarStep = ({ step, onSuccess }: P) => {
  const [isOtherCalendarModalOpened, setIsOtherCalendarModalOpened] = useState(false);

  const { isLoading, data: calendarsData } = useCalendarSyncQuery();
  const { mutateAsync: resetQuery, isLoading: isResettingQuery } = useResetQuery();
  const { mutateAsync: updateStep, isLoading: isUpdatingStep } = useUpdateOnboardingStep();

  const { calendars } = useCalendarOptions({ calendars: calendarsData });
  const { generateCalendarOptionProperties } = useCalendarOptionProperties();

  const handleSelectedCalendars = useCallback(
    async ({ calendars }: { calendars: string[] }) => {
      await updateStep({ id: step.data.id, status: 'completed', calendars });
      await resetQuery({ cacheResetOption: 'invalidate' });
      onSuccess();
    },
    [onSuccess, resetQuery, updateStep, step.data.id],
  );

  const handleContinue = useCallback(async () => {
    await handleSelectedCalendars({ calendars: [] });
  }, [handleSelectedCalendars]);

  const openOtherCalendarModal = useCallback(() => {
    setIsOtherCalendarModalOpened(true);
  }, [setIsOtherCalendarModalOpened]);

  const closeOtherCalendarModal = useCallback(() => {
    setIsOtherCalendarModalOpened(false);
  }, [setIsOtherCalendarModalOpened]);

  if (isLoading) {
    return <CalendarStepSkeleton calendars={calendars.length} />;
  }

  return (
    <>
      <Row item={{ gutter: [variables.spaceMd.value, variables.spaceMd.value] }}>
        {calendars.map((option) => (
          <Col key={option.type} item={{ span: 24 }}>
            <CalendarOption {...generateCalendarOptionProperties(option)} />
          </Col>
        ))}

        <Col item={{ span: 24 }}>
          {calendars.some((calendar) => calendar.status === 'synced') ? (
            <Button type="primary" block onClick={handleContinue} loading={isUpdatingStep || isResettingQuery}>
              Continue
            </Button>
          ) : (
            <Button type="default" block onClick={openOtherCalendarModal}>
              I don’t use any of these
            </Button>
          )}
        </Col>
      </Row>

      <OtherCalendarModal
        step={step}
        isOpened={isOtherCalendarModalOpened}
        isLoading={isUpdatingStep || isResettingQuery}
        onSuccess={handleSelectedCalendars}
        onCancel={closeOtherCalendarModal}
      />
    </>
  );
};

export default CalendarStep;
