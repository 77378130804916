import * as React from 'react';
import Popup from 'reactjs-popup';
import { SearchInput } from '../../atoms/search-input';
import { Icon } from '../../atoms/icon';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';

class Renderer extends React.Component {
  state = {
    chosen: [],
    shownCampaigns: [],
    open: false,
  };

  async componentDidUpdate(prevProps) {
    if (this.props.campaigns.length !== prevProps.campaigns.length) {
      this.setState({ shownCampaigns: this.props.campaigns });
    }
  }

  onSearch = (value) => {
    this.setState({
      shownCampaigns: value.target.value
        ? this.props.campaigns.filter((campaign) => campaign.name.search(new RegExp(value.target.value, 'i')) > -1)
        : this.props.campaigns,
    });
  };

  onCheck = (campaign) => {
    if (this.getCheckedCampaign(campaign.id)) {
      this.setState({ chosen: this.state.chosen.filter((innerCampaign) => innerCampaign.id !== campaign.id) });
    } else {
      this.setState({ chosen: this.state.chosen.concat([campaign]) });
    }
  };

  getCheckedCampaign = (id) => this.state.chosen.find((campaign) => campaign.id === id);

  closePopup = () => {
    this.setState({ open: true }, () => this.setState({ open: false }));

    this.props.onChange(this.state.chosen);
  };

  render() {
    return (
      <div className="campaign-filter">
        <Popup
          trigger={() => (
            <span>
              <span className="campaign-filter__top-campaigns">
                <Trans>Campaigns</Trans>:{' '}
              </span>
              <span className="campaign-filter__top-campaigns-side">
                {this.state.chosen.length > 0 ? (
                  <span className="campaign-filter__top-campaigns-chosen">
                    {this.state.chosen.length + ' '}
                    <img
                      alt="arrow down"
                      src="/assets/close.png"
                      onClick={() => this.setState({ chosen: [] }, this.closePopup)}
                    />
                  </span>
                ) : (
                  <Trans>All</Trans>
                )}
                <img alt="arrow down" src="/assets/arrow-down.svg" className="campaign-filter__top-campaigns-close" />
              </span>
            </span>
          )}
          position="bottom right"
          offsetY={10}
          open={this.state.open}
          closeOnDocumentClick={this.state.chosen.length === 0}
          closeOnEscape={this.state.chosen.length === 0}
          contentStyle={{
            backgroundColor: '#22333C',
            borderRadius: 4,
            boxShadow: 'none',
            border: 0,
            color: '#ECEFF1',
            padding: '16px 30px',
            width: 347,
            height: 477,
            lineHeight: '28px',
          }}
          arrowStyle={{ color: '#22333c', filter: 'none' }}
        >
          <SearchInput
            placeholder={this.props.t('Search campaign')}
            outerDivCss={{
              height: 29,
              backgroundColor: '#324149',
              position: 'relative',
              overflow: 'hidden',
              marginBottom: 20,
              flexBasis: 29,
            }}
            iconSize={14}
            iconColor={'#90A4AE'}
            inputCss={{
              color: '#90A4AE',
              position: 'relative',
              width: '100%',
              background: 'none',
              border: 0,
              outline: 'none',
              fontSize: 13,
              lineHeight: `${22 / 14}px`,
              paddingLeft: 34,
              paddingRight: 10,
            }}
            onKeyUp={this.onSearch}
          />

          <div className="campaign-filter__results-wrapper">
            {this.state.shownCampaigns.map((campaign) => (
              <div key={campaign.id} className="campaign-filter__campaign">
                <div className="campaign-filter__campaign-input">
                  <React.Fragment>
                    <input
                      name={campaign.id}
                      id={campaign.id}
                      type="checkbox"
                      onChange={() => this.onCheck(campaign)}
                      hidden
                    />
                    <label htmlFor={campaign.id}>
                      <i
                        className={`campaign-filter__campaign-icon ${
                          this.getCheckedCampaign(campaign.id) ? 'campaign-filter__campaign-icon--checked' : ''
                        }`}
                      >
                        {this.getCheckedCampaign(campaign.id) ? <Icon name="check" color="white" size={10} /> : null}
                      </i>
                    </label>
                  </React.Fragment>
                </div>

                <div>
                  {campaign.name}
                  <div className="campaign-filter__organization-name">
                    {campaign.organization.name}, {campaign.community.name}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="campaign-filter__apply-button-wrapper" onClick={this.closePopup}>
            <div className="campaign-filter__apply-button">
              <Trans>Apply</Trans>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export const CampaignFilter = withTranslation()(Renderer);
