import { select } from '@rematch/select';
import { connect } from 'react-redux';
import { ContentLeft, Footer, Header, PrivacyPolicy, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation, useOnSuccessLogin } from '../hooks';
import { type OnboardingLocationState } from '../types';

export type PrivacyPolicyLocationState = OnboardingLocationState;

const PagePrivacyPolicy = ({ isAuthenticated }: { isAuthenticated?: boolean }) => {
  const { onSuccessLogin } = useOnSuccessLogin<PrivacyPolicyLocationState>();
  const { onNavigateToPrimaryLogin } = useOnboardingNavigation<PrivacyPolicyLocationState>();

  const headerButtons: HeaderItem[] = isAuthenticated
    ? [
        {
          label: 'Home',
          type: 'primary',
          onClick() {
            onSuccessLogin();
          },
        },
      ]
    : [
        {
          label: 'Log in',
          type: 'primary',
          onClick() {
            onNavigateToPrimaryLogin();
          },
        },
      ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={<PrivacyPolicy />}
      footer={<Footer />}
    />
  );
};

const mapState = (state: any) => ({
  // @ts-expect-error
  isAuthenticated: select.session.isAuthenticated(state),
});

export default connect(mapState)(PagePrivacyPolicy);
