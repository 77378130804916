import { Text } from '../../text';
import { Checkbox } from './Checkbox';

export function CheckboxWithLabel(props) {
  const { inputProps } = props;
  return (
    <label disabled={inputProps.disabled} className="checkbox-with-label">
      <div className="checkbox-with-label__box">
        <Checkbox {...inputProps} id={props.id} />
      </div>
      <Text size="17px" lineHeight="22px" weight="100" className="checkbox-with-label__label">
        {props.label}
      </Text>
    </label>
  );
}
