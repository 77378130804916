import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorBox } from './ErrorBox';

export function RequestError(props) {
  const { t } = useTranslation();

  if (!props.response) {
    return null;
  } else if (props.response.ok) {
    return null;
  } else {
    return props.render ? (
      props.render(props.response)
    ) : (
      <ErrorBox>{props.response.data.error || props.response.data.message || t('Please check your form')}</ErrorBox>
    );
  }
}
