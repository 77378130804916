import * as React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '../../box';
import { Icon } from '../../icon';
import { Text } from '../../text';
import { ThemeConsumer } from '../../theme';

export function NoResultsItem() {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box flexDirection="row" alignItems="center" style={{ padding: '20px 15px' }}>
          <Box style={{ marginRight: 10 }}>
            <Icon name="pba-search" color="#B0BEC5" size={28} />
          </Box>
          <Box>
            <Text component="p" size="14px" color={theme.color.lightGray} lineHeight="19px">
              <Trans>No results found</Trans>
            </Text>
          </Box>
        </Box>
      )}
    </ThemeConsumer>
  );
}
