import { useLocation } from 'react-router-dom';
import { ContentLeft, Footer, Header, type HeaderItem, PasswordlessLogin } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

export type PasswordlessLocationState = OnboardingLocationState;

const PagePasswordlessLogin = () => {
  const { state } = useLocation<PasswordlessLocationState | undefined>();
  const { onNavigateToSignUp, onNavigateToPrimaryLogin, onReplaceCheckMagicLink } =
    useOnboardingNavigation<PasswordlessLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Sign up',
      type: 'primary',
      onClick() {
        onNavigateToSignUp();
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={
        <PasswordlessLogin<PasswordlessLocationState>
          state={state}
          onReplaceCheckMagicLink={onReplaceCheckMagicLink}
          onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
        />
      }
      footer={<Footer />}
    />
  );
};

export default PagePasswordlessLogin;
