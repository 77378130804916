import {
  useInfiniteQuery as useInfiniteQueryReact,
  type QueryKey,
  type QueryFunction,
  type UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { type AxiosError } from 'axios';

export const useInfiniteQuery = <
  TQueryFunctionData,
  TError extends AxiosError = AxiosError,
  TData = TQueryFunctionData,
  TQueryKey extends QueryKey = QueryKey,
>(
  key: TQueryKey,
  query: QueryFunction<TQueryFunctionData, TQueryKey>,
  options?: Omit<
    UseInfiniteQueryOptions<TQueryFunctionData, TError, TData, TQueryFunctionData, TQueryKey>,
    'queryFn' | 'queryKey'
  >,
) =>
  useInfiniteQueryReact<TQueryFunctionData, TError, TData, TQueryKey>(key, query, {
    staleTime: 5 * 60 * 1000, // 5 Minutes
    cacheTime: 24 * 60 * 60 * 1000, // 1 Day
    ...options,
  });
