import { AppLayout } from 'old/layouts/AppLayout';
import { useParams } from 'react-router-dom';
import { ChannelsPrograms } from 'features/channels';
import { useGetPathToEntityDetail } from '../../hooks';
import { useNavigateToJoinedPrograms } from '../hooks';

const PageChannelsPrograms = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const navigateToJoinedPrograms = useNavigateToJoinedPrograms();
  const getPathToEntityDetail = useGetPathToEntityDetail();

  return (
    <AppLayout
      center={
        <ChannelsPrograms
          getPathToProgramDetail={(id) => getPathToEntityDetail(id, { entityType: 'program' })}
          navigateToJoinedPrograms={navigateToJoinedPrograms}
          organizationId={organizationId}
        />
      }
    />
  );
};

export default PageChannelsPrograms;
