import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { select } from '@rematch/select';
import { dispatch } from '../../store';
import { default as PageCommunityEventsNew } from 'pages/PageCommunityEvents/PageCommunityEvents';

export const PageCommunityEventsContent = ({ viewer, createEvent, community }) => (
  <PageCommunityEventsNew
    viewer={viewer}
    createEvent={createEvent}
    community={community}
    onStatusChange={dispatch.session.fetchUser}
  />
);

const mapState = (state) => ({
  viewer: select.session.user(state),
});

const mapDispatch = (dispatch) => ({
  createEvent: (event) => dispatch.events.create(event),
});

export const PageCommunityEvents = withTranslation()(connect(mapState, mapDispatch)(PageCommunityEventsContent));
