import React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { withRouter } from 'react-router';

import { ProfileSideNav } from '../page-profile/ProfileSideNav';
import { UserZoneLayout, TABS } from '../../layouts/UserZoneLayout';
import { dispatch } from '../../store';
import { DexcomSync } from '../../../features/dexcom';
import { message } from 'ui';

class PageDexcomAccountSyncRenderer extends React.Component {
  render() {
    return (
      <UserZoneLayout navigation={<ProfileSideNav user={this.props.user} />} contentId={TABS.profile}>
        <DexcomSync
          user={this.props.user}
          onFinished={async ({ status, messageText }) => {
            switch (status) {
              case 'success':
                message.success(messageText);
                await dispatch.session.fetchUser();
                break;
              case 'error':
                message.error(messageText);
                await dispatch.session.fetchUser();
                break;
              case 'cancel':
                message.error(messageText);
              default:
                break;
            }
            if (this.props.location.state?.backUrl) {
              this.props.history.push(this.props.location.state.backUrl);
            }
          }}
          automaticSync={this.props.location.state?.automaticSync ?? false}
        />
      </UserZoneLayout>
    );
  }
}

const mapState = (state) => ({
  user: select.session.user(state),
});

export const PageDexcomAccountSync = connect(mapState)(withRouter(PageDexcomAccountSyncRenderer));
