import { Entity } from 'models';
import { useState, useEffect, useMemo } from 'react';
import { removeCircularReference } from 'common/utils';
import { type Social } from 'models/content-object';
import Post from 'models/post';
import Program from 'models/program';
import { type ContentObjectDataObjectDeprecated } from 'models/service/content-object-deprecated';
import { Modal, message } from 'ui';
import { PostForm } from './components';
import api from './services/api';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  onSuccess: (any: Post<Social>) => Promise<void> | void;
  objectData: ContentObjectDataObjectDeprecated;
  program: Program;
};

const EditPost = ({ onSuccess, onCancel, objectData, isOpened, program }: P) => {
  const postObject = useMemo(
    () =>
      new Post<Social>(
        objectData,
        program,
        objectData.post,
        objectData.program && objectData.programs_topic
          ? [new Program(objectData.program)]
          : objectData.post_in_communities?.map((community) => new Entity(community)),
      ),
    [objectData, program],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [postData, setPostData] = useState<Post<Social>>(postObject);

  const fetchData = () => {
    if (isOpened) {
      setLoading(true);
      setPostData(postObject);
      setLoading(false);
    }
  };

  const onSubmit = async (post: Post<Social>) => {
    setLoading(true);

    // TODO `removeCircularReference` this is fixing circural reference
    const postResponse = await api.editPost(removeCircularReference(post));

    if (postResponse.ok) {
      await onSuccess(postResponse.data);
      message.success('Post was updated.');
    } else {
      message.error('Failed to edit post.');
    }

    setLoading(false);
  };

  useEffect(fetchData, [isOpened, postObject]);

  return (
    <Modal title={'Edit Post'} isOpened={isOpened} onCancel={onCancel}>
      <PostForm onSubmit={onSubmit} loading={loading} post={postData} program={program} />
    </Modal>
  );
};

export default EditPost;
