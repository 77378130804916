import { type ReduxUser, type Community } from 'models';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { Card } from 'ui';
import { UserList } from '../UserList';

type P = {
  community: Community;
  viewer: ReduxUser;
};

const Administrators = ({ community, viewer }: P) => {
  const { t } = useTranslation();

  return (
    <Card title="Administrators">
      <UserList
        users={community.administrators?.map((administrator) => ({
          id: administrator.id,
          photo: {
            url: administrator.profilePhoto?.url ?? images.default.userProfilePhoto,
            alt: t('Admin profile photo'),
          },
          fullName: administrator.fullName,
        }))}
        viewer={viewer}
      />
    </Card>
  );
};

export default Administrators;
