import { Typography } from 'antd';
import { type BaseType } from 'antd/lib/typography/Base';
import cn from 'classnames';
import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { generateTypographySizeClassName, type TypographySize } from './Typography';

type TextType = BaseType | 'title';

type P = Omit<ComponentProps<typeof Typography.Paragraph>, 'type'> & {
  size?: TypographySize;
  weight?: 'bold' | 'medium';
  type?: TextType;
};

const Paragraph = ({ children, className, size, weight, type, ...rest }: P) => {
  const { t } = useTranslation();

  return (
    <Typography.Paragraph
      type={type === 'title' ? undefined : type}
      className={cn(className, 'ui-paragraph', generateTypographySizeClassName(size), {
        'title-color': type === 'title',
        'bold-weight': weight === 'bold',
        'medium-weight': weight === 'medium',
      })}
      {...rest}
    >
      {typeof children === 'string' ? t(children) : children}
    </Typography.Paragraph>
  );
};

export default Paragraph;
