import * as React from 'react';
import { getAppConfig } from '../app-config-gate';

/**
 * Export consumer & provider components
 */
const ThemeContext = React.createContext({
  abuse_reports_email: '',
  screens: {
    help_screen: [],
  },
  isBranded: false,
  application_name: '',
  color: {
    // Semantic colors
    brand: '#2E4690',
    infoColor: '#2B73B6',
    warnColor: '#cd853f',
    errorColor: '#cc3232',
    successColor: '#32A54A',
    link: '#00B0FF',
    facebookBlue: '#3b5998',
    twitterBlue: '#1da1f2',
    linkedinBlue: '#007bb6',
    googleRed: '#EA4335',
    // colors by name
    white: '#ffffff',
    green: '#00e676',
    red: '#ff1744',
    darkBlue: '#38505A',
    grayBlue: '#4e5f67',
    gray: '#455A64',
    lightGray: '#90A4AE',
  },
  resolveColor: (color) => color,
  feature_flags: {
    explore_branded_app: true,
  },
});

export const ThemeConsumer = ThemeContext.Consumer;

export function ThemeProvider(props) {
  function resolveColor(color) {
    return getAppConfig().colors[color] || color;
  }

  return (
    <ThemeContext.Provider
      value={{
        ...getAppConfig(),
        color: getAppConfig().colors,
        resolveColor: resolveColor,
        isBranded: process.env.REACT_APP_CONFIG !== 'default',
        feature_flags: getAppConfig().feature_flags,
      }}
      children={props.children}
    />
  );
}
