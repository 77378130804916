import { type InviteType, type EventContentObject } from 'models';
import { type CommonFormValues } from '../EventFormModal/DataStepEventForm/EventInviteesPicker';

import { DefaultEventForm } from './DefaultEventForm';
import { TelehealthEventForm } from './TelehealthEventForm';

export type QuickInviteFormValues = CommonFormValues;

export type QuickInviteFormData = {
  formValues: QuickInviteFormValues;
};

type P = {
  eventContentObject: EventContentObject;
  isUpdating: boolean;
  onSubmit: (data: QuickInviteFormData) => void;
  inviteType: InviteType;
  onInviteesTypeChanged: (type: InviteType) => void;
};

const QuickInviteForm = ({ eventContentObject, ...rest }: P) => {
  if (eventContentObject.event.eventType === 'telehealth_visit') {
    return <TelehealthEventForm eventContentObject={eventContentObject} {...rest} />;
  }

  return <DefaultEventForm eventContentObject={eventContentObject} {...rest} />;
};

export default QuickInviteForm;
