import { Form as FormAD, type FormProps } from 'antd';
import cs from 'classnames';
import { type ValidateErrorEntity } from 'rc-field-form/es/interface';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from './Item';

type FormP<Values = any> = FormProps<Values> & {
  children?: ReactNode;
  noOptional?: boolean;
};

function Form<Values = any>({ onFinishFailed, noOptional, className, ...props }: FormP<Values>) {
  // TODO this is a hack to focus on first error field, waiting for answer from feature proposal https://github.com/ant-design/ant-design/issues/45701
  const internalFinishFailed = (errorInfo: ValidateErrorEntity<Values>) => {
    const errorField = errorInfo.errorFields[0];
    const fieldKey = errorField.name[0];

    const input = document.querySelector<HTMLInputElement>(`[id="${fieldKey}"]`);

    if (input && typeof input.focus === 'function') {
      input.focus();
    }

    if (onFinishFailed) {
      onFinishFailed(errorInfo);
    }
  };

  const { t } = useTranslation();

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: t('Error: ${label} is required'),
    whitespace: t('Error: ${label} cannot be a blank character'),
    types: {
      email: t('Error: E-mail is not valid. Please enter a valid email address (e.g., example@email.com)'),
    },
  };
  /* eslint-enable no-template-curly-in-string */

  return (
    <FormAD<Values>
      {...props}
      onFinishFailed={internalFinishFailed}
      layout="vertical"
      scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
      requiredMark={false}
      validateMessages={validateMessages}
      className={cs(className, { 'form-no-optional': noOptional })}
    />
  );
}

Form.useForm = FormAD.useForm;
Form.List = FormAD.List;
Form.Item = Item;

export { type Rule, type FormInstance, type FormListFieldData } from 'antd/lib/form';
export default Form;
