import { useMutation as useMutationReact, type MutationFunction, type UseMutationOptions } from '@tanstack/react-query';

import { handleAxiosError } from 'common/error-handlers';
import { type ApiClientResponseError, type ResponseErrorDataObject } from 'common/services/api';

export const useMutation = <
  TData,
  TVariables,
  TError extends Error = ApiClientResponseError<ResponseErrorDataObject>,
  TContext = unknown,
>(
  query: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn' | 'mutationKey'>,
) =>
  useMutationReact<TData, TError, TVariables, TContext>(query, {
    onError(error) {
      handleAxiosError(error);
    },
    ...options,
  });
