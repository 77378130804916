import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { Link, Text } from 'ui';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Text>
      {process.env.REACT_APP_LANDING_SUMMARY}{' '}
      <a href={process.env.REACT_APP_TERMS_URL} target="_blank" rel="noopener noreferrer">
        {t('Terms')}
      </a>{' '}
      &amp;{' '}
      <Link to={routes.privacyPolicy} target="_blank" rel="noopener noreferrer">
        {t('Privacy Policy')}
      </Link>
    </Text>
  );
};
