import { type CampaignSlim } from 'models';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { CampaignIcon, DataCard } from 'ui';

type P = {
  getPathToCampaignDetail: (id: string, organizationId: string) => string;
  campaign: CampaignSlim;
  showTag?: boolean;
};

const CampaignCard = ({ getPathToCampaignDetail, campaign, showTag }: P) => {
  const { t } = useTranslation();

  return (
    <DataCard
      title={campaign.name}
      description={{ dateFrom: campaign.startTime, dateTo: campaign.endTime, dateFormat: 'dateRangePicker' }}
      prices={
        Number(campaign.amountGoal) > 0
          ? {
              currentPrice: Number(campaign.amountGoal),
              label: t('Goal'),
            }
          : undefined
      }
      image={{
        src: campaign.coverPhoto?.url ?? images.default.campaignCoverPhoto,
        alt: t('Campaign cover image'),
      }}
      tag={showTag ? { className: 'campaign-card__tag', text: t('Campaign'), icon: <CampaignIcon /> } : undefined}
      className="campaign-card"
      path={getPathToCampaignDetail(campaign.id, campaign.organization.id)}
    />
  );
};

export default CampaignCard;
