import { Col, Layout, Row, Text, Title, SadFaceIcon } from 'ui';

const PageUnsubscribe = () => {
  return (
    <Layout className="unsubscribe-page">
      <Row item={{ justify: 'center', align: 'middle' }}>
        <Col>
          <SadFaceIcon />
          <Title level={2}>We are sad to see you go!</Title>
          <Text>You have successfully unsubscribed, you will no longer recieve this type of emails.</Text>
        </Col>
      </Row>
    </Layout>
  );
};

export default PageUnsubscribe;
