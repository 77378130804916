import { type BaseEvent } from 'models';

export const getLastChangeRequestStatus = (event: BaseEvent) =>
  event.changeRequests?.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[event.changeRequests.length - 1]
    ?.status;

export function getLastChangeRequestStatusRedux(reduxEvent: any) {
  return reduxEvent.change_requests?.sort(
    (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  )[reduxEvent.change_requests.length - 1]?.status;
}
