import { normalize, denormalize } from 'normalizr';

import { CategoriesService } from '../services/CategoriesService';
import { CategorySchema, OrganizationSchema } from './schemas';
import { dispatch, getState } from '../store';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [CategorySchema] : CategorySchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

/**
 * Typings
 */

const INITIAL_STATE = {
  categories: [],
  organizations: [],
};

/**
 * Model
 */
export const model = {
  name: 'categories',
  state: INITIAL_STATE,
  selectors: {
    find: (state, categoryId) => {
      return denormalize(categoryId, CategorySchema, getState().entities);
    },
    withCommunities: (state) => {
      return denormalize(state.categories, [CategorySchema], getState().entities).filter(
        (category) => category.communities_count > 0,
      );
    },
    withOrganizations: (state) => {
      return denormalize(state.categories, [CategorySchema], getState().entities).filter(
        (category) => category.organizations_count > 0,
      );
    },
    organizations: (state) => {
      return denormalize(state.organizations, [OrganizationSchema], getState().entities);
    },
    get: (state) => {
      return denormalize(state.categories, [CategorySchema], getState().entities);
    },
  },
  reducers: {
    setState: (state, payload) => ({ ...state, ...payload }),
  },
  effects: {
    async find(categoryId) {
      const response = await CategoriesService.find(categoryId);

      if (response.ok) {
        this.setState({ categories: normalizeData([response.data]) });
      }

      return response;
    },

    async getAsync(params) {
      const response = await CategoriesService.list(params);
      if (response.ok) {
        this.setState({ categories: normalizeData(response.data) });
      }
      return response;
    },

    async getOrganizationsAsync(payload) {
      const response = await CategoriesService.organizations(payload);

      if (response.ok) {
        const normalizedData = normalize(response.data, [OrganizationSchema]);
        dispatch.entities.mergeEntities(normalizedData.entities);

        this.setState({ organizations: normalizedData.result });
      }

      return response;
    },
  },
};
