import * as React from 'react';
import { isAfter } from 'date-fns';

import { CourseTopic } from './CourseTopic';
import { can } from '../../utils/permissions';
import { Card, Title } from 'ui';

export class CourseTimeline extends React.Component {
  state = {
    selected: 0,
    selectedPost: 0,
    filter: {
      sort: '',
      type: [],
    },
  };

  async componentDidMount() {
    this.reload();
    this.setDefaultFilter();
  }

  get posts() {
    const { course } = this.props;
    const currentTime = new Date();

    let filteredPosts = this.sortPosts(this.state.filter.sort).filter((post) =>
      this.state.filter.type.includes(post.type),
    );

    return can(course, 'Course', 'seeFuturePosts')
      ? filteredPosts
      : filteredPosts.filter((post) => isAfter(currentTime, new Date(post.publish_at)));
  }

  setDefaultFilter = () => {
    this.setState({
      filter: { sort: 'date_created', type: ['post', 'event', 'poll', 'activity', 'withings_order_post'] },
    });
  };

  setFilter = (value) => {
    this.setState({ filter: { ...this.state.filter, type: value } });
  };

  reload = () => {
    if (this.props.topics?.length) {
      this.onSelectTopic(this.state.selected, this.state.selectedPost);
    }
  };

  onSelectTopic = (topicIndex, oldPostIndex) => {
    this.setState({ posts: [], selectedPost: 0, selected: topicIndex });
    this.props.onSelectTopic(this.props.topics[topicIndex]);
    this.setDefaultFilter();
  };

  onSelectPost = (topic, post, postIndex) => {
    this.setState({ selectedPost: postIndex });
    this.props.onSelectPost(post);
    document.getElementById('courseNavigation').scrollIntoView();
  };

  sortPosts = (sortType) => {
    switch (sortType) {
      case 'name':
        return this.props.items.concat().sort((a, b) => {
          return (a[a.type]?.name || a[a.type]?.['description'] || a.text_content).localeCompare(
            b[b.type]?.name || b[b.type]?.['description'] || b.text_content,
          );
        });
      case 'type':
        return this.props.items.concat().sort((a, b) => {
          return a.type.localeCompare(b.type);
        });
      default:
        return this.props.items;
    }
  };

  filterPosts = (sort) => {
    this.setState({ filter: { ...this.state.filter, ...sort } });
  };

  renderTopics() {
    const { selected, selectedPost } = this.state;
    const { course, loading } = this.props;

    return (
      <>
        {(this.props.topics || []).map((topic, index) => {
          return (
            <CourseTopic
              course={course}
              onSelectTopic={this.onSelectTopic}
              onSelectPost={this.onSelectPost}
              className="course__header"
              key={index}
              index={index}
              topic={topic}
              selected={selected === index}
              selectedIndex={selectedPost}
              posts={this.posts}
              loading={loading}
              filterPosts={this.filterPosts}
              filter={this.state.filter}
              setFilter={this.setFilter}
              id="course_topic-header"
            />
          );
        })}
      </>
    );
  }

  render() {
    return (
      <Card shrinked className="course__background">
        <Title level={4} className="course__header">
          Modules
        </Title>
        {this.renderTopics()}
      </Card>
    );
  }
}
