import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services';
import { unSync } from '../services';

export const useDesyncAccount = () =>
  useMutation<unknown, ApiClientResponseError<DefaultResponseErrorDataObject>>({
    async mutationFn() {
      await unSync();
    },
  });
