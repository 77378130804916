import { getLoginToken } from '../../features/auth/services/api';
import { LoginToken } from '../../models/auth';
import { routes } from './routes';

export const generateEhrPortalUrl = (path: string) => `${process.env.REACT_APP_EHR_PORTAL_URL}${path}`;

const ehrPortalRedirect = async (redirectUrl: string) => {
  const ehrUrl = new URL(generateEhrPortalUrl(routes.tokenLogin));
  const response = await getLoginToken();

  if (response.ok) {
    const token = new LoginToken(response.data);
    ehrUrl.searchParams.append('token', token.loginToken);
    ehrUrl.searchParams.append('redirectUrl', redirectUrl);
  }

  return window.open(ehrUrl.toString(), '_blank');
};

export default ehrPortalRedirect;
