import * as React from 'react';
import { Trans } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';

import { Modal, message } from '../../../ui';
import { Button } from '../../atoms/button';
import { ThemeConsumer } from '../../atoms/theme';
import { withTranslation } from 'react-i18next';
import { Box } from '../../atoms/box';
import { ChequePledgeForm } from '../../atoms/cheque-pledge-form';
import { ManageCampaign } from './ManageCampaign';
import { CampaignsService } from '../../services/CampaignsService';
import { AdminService } from '../../services/AdminService';
import { CampaignBox } from './CampaignBox';
import { CampaignsLayout } from '../../layouts/CampaignsLayout';
import { showSuccessErrorAlert } from '../../atoms/alert';
import { select } from '@rematch/select';
import { CampaignsSideNav } from './CampaignsSideNav';

class Renderer extends React.Component {
  state = {
    campaigns: [],
    isCampaignModalOpen: false,
    currentEditCampaign: {},
    currentChequePledgeCampaignId: '',
  };

  get isChequePledgeOpened() {
    return this.state.currentChequePledgeCampaignId !== '';
  }

  closeCampaignModal = () => {
    this.setState({ isCampaignModalOpen: false });
  };

  async getCampaigns(scope = 'current') {
    const response = await AdminService.getCampaigns({ scope });
    if (response.ok) {
      this.setState({
        campaigns: response.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        }),
      });
    }
  }

  finishCampaign = async (campaign) => {
    if (await window.confirm(this.props.t('Do you want to finish this campaign?'))) {
      const response = await CampaignsService.finish(campaign.id);
      if (response.ok) {
        message.success(this.props.t('Campaign is successfully finished'));
        this.setState({ currentEditCampaign: null });
      } else {
        message.error(this.props.t('Something went wrong. Cannot finish campaign'));
      }
      this.getCampaigns();
    }
  };

  componentDidMount() {
    this.getCampaigns(this.props.scope);
    this.props.getCollection();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scope !== this.props.scope) {
      this.getCampaigns(nextProps.scope);
    }
  }

  confirm = () => {
    this.getCampaigns(this.props.scope);
    this.setState({ isCampaignModalOpen: false });
  };

  editCampaign = (campaign) => {
    this.setState({ isCampaignModalOpen: true, currentEditCampaign: campaign });
  };

  openChequePledgeModal = (campaign) => {
    this.setState({ currentChequePledgeCampaignId: campaign.id });
  };

  createChequePledge = async (cheque) => {
    const response = await this.props.createChequePledge(cheque);
    showSuccessErrorAlert({
      successMessage: <Trans>Check donation successfully recorded.</Trans>,
      errorMessage: <Trans>Cannot record new Check donation at this moment. Please try again later.</Trans>,
      isOk: response.ok,
    });
    this.setState({ currentChequePledgeCampaignId: '' });
  };

  renderCampaigns() {
    return this.state.campaigns.map((campaign) => (
      <CampaignBox
        key={campaign.id}
        finishCampaign={(campaign) => this.finishCampaign(campaign)}
        editCampaign={(campaign) => this.editCampaign(campaign)}
        addCheque={(campaign) => this.openChequePledgeModal(campaign)}
        campaign={campaign}
      />
    ));
  }

  render() {
    const displayLoader = !!this.props.loadingCollection;
    const campaignsByOrganization = this.props.campaignsByOrganization;
    return (
      <CampaignsLayout
        campaignsByOrganization={this.props.campaignsByOrganization}
        navigation={
          <CampaignsSideNav
            organizationId={this.props.match.params.organizationId}
            displayLoader={displayLoader}
            campaignsByOrganization={campaignsByOrganization}
          />
        }
      >
        <React.Fragment>
          <ThemeConsumer>
            {(theme) => (
              <Box>
                <div style={{ margin: '20px 0' }}>
                  <Button
                    type="button"
                    size="md"
                    textColor={theme.color.darkBlue}
                    color="#eceff1"
                    title={this.props.t('New Campaign')}
                    onClick={() => this.setState({ isCampaignModalOpen: true, currentEditCampaign: null })}
                    id="btn_new-campaign"
                    style={{ float: 'right' }}
                  />
                </div>
                <Box>{this.renderCampaigns()}</Box>
              </Box>
            )}
          </ThemeConsumer>
          {this.state.isCampaignModalOpen && (
            <Modal
              title={`${this.state.currentEditCampaign ? this.props.t('Edit') : this.props.t('Create')} ${this.props.t(
                'Donation Campaign',
              )}`}
              isOpened={this.state.isCampaignModalOpen}
              onCancel={this.closeCampaignModal}
            >
              {this.state.isCampaignModalOpen ? (
                <ManageCampaign campaign={this.state.currentEditCampaign} confirm={this.confirm} />
              ) : null}
            </Modal>
          )}
          {this.isChequePledgeOpened && (
            <Modal
              title={this.props.t('Record Check Donation')}
              isOpened={this.isChequePledgeOpened}
              onCancel={() => this.setState({ currentChequePledgeCampaignId: '' })}
            >
              {this.isChequePledgeOpened ? (
                <ChequePledgeForm
                  onSubmit={this.createChequePledge}
                  campaignId={this.state.currentChequePledgeCampaignId}
                />
              ) : null}
            </Modal>
          )}
        </React.Fragment>
      </CampaignsLayout>
    );
  }
}
const mapState = (state, props) => ({
  scope: props.match.params.scope || 'current',
  campaignsByOrganization: select.campaigns.getBy(state, 'organization'),
  loadingCollection: state.loading.effects.campaigns.getAsync,
});

const mapDispatch = (dispatch, props) => ({
  createChequePledge: (cheque) => dispatch.donations.chequePledge(cheque),
  getCollection: () => dispatch.campaigns.getAsync(),
});

export const AdminPageCampaigns = withTranslation()(connect(mapState, mapDispatch)(Renderer));
