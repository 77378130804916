import { Box as BoxBase } from './Box';

// Box
function Box(props) {
  return <BoxBase {...props} />;
}

Box.defaultProps = BoxBase.defaultProps;

// Content Box
function Content(props) {
  return <BoxBase {...props} />;
}

Content.defaultProps = BoxBase.defaultProps;

// Export mixed
Box.Content = Content;

export { Box };
