import * as React from 'react';

import { Box } from '../../atoms/box';
import { EventAvatar } from './EventAvatar';
import { EventRow } from './EventRow';

export function EventDateList(props) {
  return (
    <Box flexDirection="row" alignItems="flex-start" className="event-date-list">
      <EventAvatar date={props.data[0].start_time} event={props.data[0]} />
      <div style={{ paddingLeft: 14, flexGrow: 1 }}>
        {props.data
          .filter((d, index) => index < (props.showPerGroup || props.data.length))
          .map((d, index) => (
            <React.Fragment key={index}>
              {index > 0 || props.border ? <hr /> : null}
              <EventRow data={d} onActionClick={props.onActionClick} />
            </React.Fragment>
          ))}
      </div>
    </Box>
  );
}
