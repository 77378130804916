import { denormalize, normalize } from 'normalizr';
import { createModel } from '@rematch/core';

import { AdminService } from '../services/AdminService';
import { CommunityService } from '../services/CommunityService';
import { CommunitySchema } from './schemas';
import { store } from '../store';

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [CommunitySchema] : CommunitySchema);
  store.dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

export const model = createModel({
  name: 'adminCommunities',
  state: {
    list: [],
    detail: null,
  },
  reducers: {
    setCollection: (state, payload) => {
      return { ...state, list: payload };
    },
    push: (state, payload) => {
      if (state.list.includes(payload)) {
        return state;
      }
      return { ...state, list: [...state.list, payload] };
    },
    deleteCommunity: (state, payload) => {
      return { ...state, list: state.list.filter((id) => id === payload) };
    },
    setDetail: (state, payload) => {
      return { ...state, detail: payload };
    },
    resetDetail: (state, payload) => {
      return { ...state, detail: null };
    },
  },
  selectors: {
    find(state) {
      return denormalize(state.detail, CommunitySchema, store.getState().entities);
    },
    get(state) {
      return denormalize(state.list, [CommunitySchema], store.getState().entities);
    },
  },
  effects: (dispatch) => ({
    async get(organizationId) {
      const response = await AdminService.getCommunities(organizationId);

      if (response.ok) {
        this.setCollection(normalizeData(response.data));
      }

      return response;
    },

    async create(payload) {
      const response = await CommunityService.create(payload);

      if (response.ok) {
        this.push(normalizeData(response.data));
      }

      return response;
    },

    async update(params) {
      const { community_id, payload } = params;
      const response = await CommunityService.update(community_id, payload);

      if (response.ok) {
        const result = normalizeData(response.data);
        this.push(result);
        this.setDetail(result);
      }

      return response;
    },

    async find(id) {
      const response = await CommunityService.getEdit(id);

      if (response.ok) {
        const result = normalizeData(response.data);
        this.push(result);
        this.setDetail(result);
      }

      return response;
    },

    async delete(id) {
      const response = await CommunityService.delete(id);

      if (response.ok) {
        this.deleteCommunity(id);
      }

      return response;
    },

    async resetCommunity() {
      this.resetDetail();
    },
  }),
});
