import * as React from 'react';

import { ThemeConsumer } from '../../theme';
import { Icon } from '../../icon';

export class Checkbox extends React.Component {
  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <React.Fragment>
            <input type="checkbox" {...this.props} hidden />
            <i
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 28,
                height: 28,
                borderRadius: '5px',
                transition: 'background-color 200ms ease-in-out',
                border: `1px solid ${theme.color.brand}`,
                backgroundColor: this.props.checked ? theme.color.brand : 'transparent',
              }}
            >
              {this.props.checked ? <Icon name="check" color="white" size={15} /> : null}
            </i>
          </React.Fragment>
        )}
      </ThemeConsumer>
    );
  }
}
