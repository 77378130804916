import { type ReduxUser } from 'models';
import { isOpenToJoin } from 'features/events/utils';
import { type Event } from 'features/todos/types';
import AcceptedEventItem from './AcceptedEventItem';
import JoinEventItem from './JoinEventItem';
import PendingEventItem from './PendingEventItem';

type P = {
  event: Event;
  viewer: ReduxUser;
  isPending: boolean;
};

const EventItems = ({ event, viewer, isPending }: P) => {
  if (isPending) {
    return <PendingEventItem data={event} viewer={viewer} />;
  }

  if (isOpenToJoin(event)) {
    return <JoinEventItem data={event} viewer={viewer} />;
  }

  return <AcceptedEventItem data={event} viewer={viewer} />;
};

export default EventItems;
