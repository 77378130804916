import cs from 'classnames';
import { type ReactNode, Fragment } from 'react';

type P = {
  items: ReactNode[];
  className?: string;
  separatorClassName?: string;
};

const DotSeparator = ({ items, className, separatorClassName }: P) => {
  return (
    <div className={cs('dot-separator-wrapper', className)}>
      {items.map((item, index) => (
        <Fragment key={`${index}_dot-separator`}>
          {item}
          {index < items.length - 1 && <div className={cs('dot-separator', separatorClassName)} />}
        </Fragment>
      ))}
    </div>
  );
};

export default DotSeparator;
