import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from 'query';
import { getAssetsQueryKey } from '..';
import { deleteFile } from '../../services';

export const useDeleteFile = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, { id: string; params: { communityId: string; parentId?: string } }>(
    async ({ id }) => deleteFile(id),
    {
      onSuccess: async (data, { params: { communityId, parentId } }) =>
        queryClient.invalidateQueries(getAssetsQueryKey({ id: communityId, params: { parentId } })),
    },
  );
};
