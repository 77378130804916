import { useTranslation } from 'react-i18next';
import { type ChangeRequestStatus } from 'models/service';
import { Tag, Text } from 'ui';

type P = {
  status?: ChangeRequestStatus;
};

const EventStatus = ({ status }: P) => {
  const { t } = useTranslation();

  if (status === 'pending') {
    return (
      <Tag className="event-status--pending">
        <Text strong className="event-status--pending__text">
          {t('Pending')}
        </Text>
      </Tag>
    );
  }

  if (status === 'denied') {
    return (
      <Tag className="event-status--denied">
        <Text strong className="event-status--denied__text">
          {t('Declined')}
        </Text>
      </Tag>
    );
  }

  if (status === 'expired') {
    return (
      <Tag className="event-status--expired">
        <Text strong className="event-status--expired__text">
          {t('Event is past due')}
        </Text>
      </Tag>
    );
  }

  return null;
};

export default EventStatus;
