import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const NotificationService = {
  counts: () => http.get('/notifications/panel_counts'),
  get: (params) => http.get(`/notifications`, params),
  read: (id) => http.post(`/notifications/${id}/read`),
  readAll: () => http.post(`/notifications/read`),
};
