import * as React from 'react';
import { Box } from '../box';
import { ThemeConsumer } from '../theme';

// TODO remove border styling and selected prop from here
export class ShadowBox extends React.Component {
  static defaultProps = {
    ...Box.defaultProps,
    selected: false,
    radius: 10,
  };

  render() {
    const { className, children, selected, radius, style } = this.props;

    return (
      <ThemeConsumer>
        {(theme) => {
          return (
            <Box
              className={className}
              style={{
                backgroundColor: '#ffffff',
                borderRadius: `${radius}px`,
                borderColor: selected ? `${theme.color.green}` : undefined,
                ...style,
              }}
            >
              {children}
            </Box>
          );
        }}
      </ThemeConsumer>
    );
  }
}
