import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { withTranslation, Trans } from 'react-i18next';
import i18n from 'i18next';

import { getFeedKey } from '../../models/feed';
import { InViewport } from '../in-viewport';
import { getAppConfig } from '../../atoms/app-config-gate';
import { Loading, Result, Button } from 'ui';

class Renderer extends React.Component {
  static defaultProps = {
    render: (node, actions) => node,
  };

  state = {
    hasMoreObjects: true,
    failedToLoad: false,
    feed: [],
  };

  actions = {
    createObject: this.props.createObject,
    deleteObject: this.props.deleteObject,
    loadCollection: this.props.loadCollection,
    updateObject: this.props.updateObject,
    reloadObject: this.props.reloadContentObject,
  };

  getNextObjects = async () => {
    if (!this.props.loading) {
      const lastObject = this.props.collection[this.props.collection.length - 1];
      const resp = await this.props.loadCollection({
        cursor: lastObject ? lastObject.id : undefined,
      });

      if (resp.ok) {
        this.setState({ hasMoreObjects: resp.data.length > 0 });
      } else {
        this.setLoadErrorStatus();
      }
    }
  };

  setLoadErrorStatus = (isError = true) => {
    this.setState({ hasMoreObjects: !isError, failedToLoad: isError });
  };

  componentWillUnmount = () => {
    this.props.removeAll();
  };

  render() {
    const node = (
      <TransitionGroup component={null}>
        {this.props.collection.map((object) => {
          return !getAppConfig().feature_flags.campaigns && object.type === 'campaign' ? null : (
            <CSSTransition key={`${object.type}-${object.id}`} timeout={400} classNames="feed-object">
              {this.props.renderObject(object, this.actions)}
            </CSSTransition>
          );
        })}
        <CSSTransition key="loader" timeout={400} classNames="feed-object">
          <div
            className={`feed-object__wrapper ${
              this.props.direction === 'horizontal' ? 'feed-object__wrapper--horizontal' : ''
            } ${this.props.direction === 'vertical' ? 'feed-object__wrapper--vertical' : ''}`}
          >
            {this.props.loading ? (
              <Loading />
            ) : this.state.hasMoreObjects ? (
              <InViewport useNewLoader size={12} key="feed-journal-viewport" onEnter={this.getNextObjects} />
            ) : this.state.failedToLoad ? (
              <Result
                status="error"
                subTitle={<Trans>Sorry, something went wrong.</Trans>}
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setLoadErrorStatus(false);
                    }}
                  >
                    Retry
                  </Button>
                }
              />
            ) : null}
          </div>
        </CSSTransition>
      </TransitionGroup>
    );

    return this.props.render(node, this.actions);
  }
}

const mapState = (state, props) => {
  const feedName = getFeedKey(props);
  return {
    collection: select.feed.get(state, feedName),
    loading: select.feed.loading(state, { feed: feedName }),
  };
};

const mapDispatch = (dispatch, props) => {
  const feedName = getFeedKey(props);
  return {
    createObject: (draft) => {
      return dispatch.feed.createAsync({ draft: draft, parent_id: null, feed: feedName });
    },
    updateObject: async (object) => {
      return dispatch.feed.updateAsync({ object: object });
    },
    loadCollection: (params) => {
      return dispatch.feed.getAsync({
        feed: feedName,
        params: {
          ...params,
          limit: props.limit,
        },
        journal: true,
      });
    },
    removeAll: () => dispatch.feed.removeAll({ feed: feedName }),
    deleteObject: async ({ object }) => {
      if (await window.confirm(i18n.t('Delete this journal entry?'))) {
        return dispatch.feed.deleteAsync({ object: object, feed: feedName });
      }
    },
    reloadContentObject: (object) => {
      dispatch.feed.reloadAsync({ feed: feedName, postId: object.id });
    },
  };
};

export const FeedJournalCore = withTranslation()(connect(mapState, mapDispatch)(Renderer));
