import { Button as ButtonAD } from 'antd';
import { type ButtonType } from 'antd/es/button';
import cs from 'classnames';
import type React from 'react';
import { useTranslation } from 'react-i18next';

export type ButtonProperties = Omit<React.ComponentProps<typeof ButtonAD>, 'type'> & {
  'data-test-id'?: string;
  type?: ButtonType | 'link-secondary';
};

const Button = ({ className, type, children, ...rest }: ButtonProperties) => {
  const { t } = useTranslation();

  return (
    <ButtonAD
      className={cs(className, 'ui-button', {
        'link-secondary': type === 'link-secondary',
      })}
      type={type === 'link-secondary' ? 'link' : type}
      {...rest}
    >
      {typeof children === 'string' ? t(children) : children}
    </ButtonAD>
  );
};

export default Button;
