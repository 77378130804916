import * as React from 'react';
import { Trans } from 'react-i18next';

import { Avatar, Link } from 'ui';
import { Box } from '../../atoms/box';
import { Menu, MenuButton, MenuList, MenuItem } from '../../atoms/menu';
import { Icon } from '../../atoms/icon';
import { Modal } from '../../atoms/modal';
import { Pill } from '../../atoms/pill';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { images } from 'common/utils';

export class CommunityMemberRow extends React.Component {
  state = {
    isModalOpen: false,
  };

  render() {
    const { member, onDropdownSelect, showCommunities, showActions } = this.props;
    const fullName = `${member.first_name} ${member.last_name}`;
    const joinedCommunities = showCommunities ? [...member.joined_communities] : [];
    const communities = joinedCommunities.slice(0, 2);

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className="community-member-row">
            <div className="community-member-row__avatar">
              <Link to={`/members/${member.id}`} style={{ textDecoration: 'inherit' }}>
                <Avatar name={fullName} photo={member.profile_photo ?? images.default.userProfilePhoto} size={30} />
              </Link>
            </div>
            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
              <Link to={`/members/${member.id}`} style={{ textDecoration: 'inherit' }}>
                <Text color={theme.color.gray} size="15px" lineHeight="1">
                  {fullName}
                </Text>
              </Link>
            </div>
            {showCommunities && communities.length > 0 ? (
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <Box flexDirection="row" alignItems="center">
                  {communities.map((community) => (
                    <div key={community.id} style={{ margin: '0 4px' }}>
                      <Pill title={community.name} color="#B0BEC5" truncate />
                    </div>
                  ))}
                  {joinedCommunities.length - communities.length > 0 ? (
                    <Text
                      color={theme.color.lightGray}
                      size="13px"
                      weight="600"
                      lineHeight="18px"
                      style={{ margin: '0 4px' }}
                    >
                      {`+${joinedCommunities.length - communities.length}`}
                    </Text>
                  ) : null}
                </Box>
              </div>
            ) : null}
            {showActions ? (
              <div className="community-member-row__actions">
                <React.Fragment>
                  <Menu>
                    <MenuButton style={{ border: 0, background: 'none' }}>
                      <Icon name="menu" size={20} />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onSelect={onDropdownSelect('message', member)}>
                        <Trans>Message</Trans> <Icon size={20} name="chat-1" color="rgba(176,190,197,0.5)" />
                      </MenuItem>
                      <MenuItem onSelect={onDropdownSelect('call', member)}>
                        <Trans>Call</Trans> <Icon size={20} name="call-3" color="rgba(176,190,197,0.5)" />
                      </MenuItem>
                      <MenuItem onSelect={onDropdownSelect('role', member)}>
                        <Trans>Change role</Trans> <Icon size={20} name="change-role" color="rgba(176,190,197,0.5)" />
                      </MenuItem>
                      <MenuItem onSelect={() => this.setState({ isModalOpen: true })}>
                        <Trans>Remove</Trans> <Icon size={20} name="other-1" color="rgba(176,190,197,0.5)" />
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  <Modal
                    isOpen={this.state.isModalOpen}
                    onRequestClose={() => {
                      this.setState({ isModalOpen: false });
                    }}
                  >
                    {(modal) =>
                      joinedCommunities.map((community) => (
                        <div
                          key={community.id}
                          onClick={() => {
                            modal.closeModal();
                            onDropdownSelect('remove', member, community);
                          }}
                        >
                          {community.name}
                        </div>
                      ))
                    }
                  </Modal>
                </React.Fragment>
              </div>
            ) : null}
          </div>
        )}
      </ThemeConsumer>
    );
  }
}
