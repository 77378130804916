import * as React from 'react';

import { Text } from '../text';
import { Box } from '../box';
import { ThemeConsumer } from '../theme';

export class SegmentedControl extends React.Component {
  getProperty = (label, property) => {
    if (typeof label === 'object') {
      return label[property];
    }
    return label;
  };

  onChange = (label) => {
    this.props.onChange(typeof label === 'object' ? label.value : label);
  };

  render() {
    const { labels, selectedLabel, fontSize, buttonPadding, buttonMargin } = this.props;
    return (
      <ThemeConsumer>
        {(theme) => (
          <Box flexDirection="row" className="program-segmented">
            {labels.map((label, idx, arr) => {
              return (
                <button
                  key={idx}
                  onClick={() => this.onChange(label)}
                  className={`program-segmented__button ${
                    selectedLabel === this.getProperty(label, 'value') ? 'program-segmented__button--selected' : ''
                  }`}
                  style={{
                    margin: buttonMargin ? buttonMargin : '0 24px',
                    padding: buttonPadding ? buttonPadding : '25px 0',
                  }}
                  data-test-id={this.getProperty(label, 'title').toLowerCase().replace(' ', '-')}
                >
                  <Text
                    size={fontSize || '15'}
                    color={selectedLabel === this.getProperty(label, 'value') ? theme.color.brand : '#90A4AE'}
                  >
                    {this.getProperty(label, 'title')}
                  </Text>
                </button>
              );
            })}
          </Box>
        )}
      </ThemeConsumer>
    );
  }
}
