import {
  CampaignIcon,
  RadioPills,
  CommunityIcon,
  ProgramIcon,
  EventIcon,
  DotGridLineIcon,
  type RadioChangeEvent,
} from 'ui';
import { type EntityType } from '../../types';

type P = {
  value?: EntityType;
  onChange?: (value: RadioChangeEvent) => void;
  options: Array<'campaign' | 'community' | 'event' | 'program'>;
};

const EntityTypeRadioPills = ({ value, onChange, options }: P) => {
  const generatePills = () => {
    const pills = [];

    if (options.length > 1) {
      pills.push({
        label: 'All',
        value: undefined,
        icon: <DotGridLineIcon />,
      });
    }

    if (options.includes('community')) {
      pills.push({
        label: 'Communities',
        value: 'Community',
        icon: <CommunityIcon />,
      });
    }

    if (options.includes('program')) {
      pills.push({
        label: 'Programs',
        value: 'Program',
        icon: <ProgramIcon />,
      });
    }

    if (options.includes('event')) {
      pills.push({
        label: 'Events',
        value: 'Event',
        icon: <EventIcon />,
      });
    }

    if (options.includes('campaign')) {
      pills.push({
        label: 'Campaigns',
        value: 'Campaign',
        icon: <CampaignIcon />,
      });
    }

    return pills;
  };

  return <RadioPills onChange={onChange} value={value} items={generatePills()} />;
};

export default EntityTypeRadioPills;
