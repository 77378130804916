import { apiClient, type ApiClientResponse, routes } from 'common/services';

type P = {
  changeRequests: Array<{
    change_request: {
      kind: string;
      subject_type: string;
      subject_id: string;
      meta?: { invitation_email?: string; invitation_phone?: string };
      subject_user_id?: string;
    };
  }>;
};

export const inviteUsersToCommunity = async ({ changeRequests }: P) => {
  return apiClient.post<undefined, ApiClientResponse<undefined>>(routes.community.inviteUsers, changeRequests);
};
