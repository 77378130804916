// eslint-disable-next-line import/named
import { RecoilValueReadOnly, selector, waitForAll } from 'recoil';

import { User } from '../../common/models';
import { ErrorState, SuccessState, transformError } from '../../common/recoil/queries';
import { activePracticeIdState } from '../../common/recoil/selectors';
import { ID } from '../../common/types';
import { activeChatroom, usersPaginationOptions, usersSearch } from '../atoms';
import { getMembers } from '../queries';
import { activeChatroomState } from '.';
import { Participant } from '../../models';

export const usersState: RecoilValueReadOnly<ErrorState | SuccessState<User[]>> = selector({
  key: 'UsersState',
  get: async ({ get }) => {
    const { pagination, activePracticeId, searchQuery } = get(
      waitForAll({
        pagination: usersPaginationOptions,
        activePracticeId: activePracticeIdState,
        searchQuery: usersSearch,
      }),
    );

    // TODO: Handle `practiceId` null state
    const usersPaginatedData = await getMembers(pagination, activePracticeId as ID, null, searchQuery);

    if (usersPaginatedData.error) {
      return transformError(usersPaginatedData.error);
    }

    const users = usersPaginatedData.result.data;
    return {
      error: null,
      result: users,
    };
  },
});

export const usersInChatroomState = selector<Record<string, Participant>>({
  key: 'UsersInChatroomState',
  get:
    ({ get }) => {
      const { activeChatroomId } = get(
        waitForAll({
          activeChatroomId: activeChatroom,
        }),
      );
      
      const activeChatroomData = get(activeChatroomState(activeChatroomId));
      let chatroomUsers: Record<string, Participant> = {};

      if (activeChatroomData) {
        for (const id of activeChatroomData.users) {
          const participant = activeChatroomData.participants[id];
          chatroomUsers[participant._id] = { _id: participant._id, firstName: participant.firstName, lastName: participant.lastName }
        }
      }

      return chatroomUsers;
    },
});
