import uniqBy from 'lodash.uniqby';
import { normalize, denormalize } from 'normalizr';

import { CommunityService } from '../services/CommunityService';
import { CommunitySchema } from './schemas';
import { dispatch, getState } from '../store';
import { getAppConfig } from '../atoms/app-config-gate';
import { can } from '../utils/permissions';

export const hasJoined = (community) => can(community, 'Community', 'joined');
export const hasNotJoined = (community) => !hasJoined(community);
export const flatten = (list) => [].concat(...list);
export const dedupe = (list) => [...new Set(list)];

export const groupByCategory = (communities) => {
  const pickedCommunityIds = [];
  const groupedByCategory = [];
  const categories = uniqBy(flatten(communities.map((c) => c.categories)), 'id');

  categories.forEach((category) => {
    const communitiesForCategory = communities.filter((community) => community.categories.includes(category));
    pickedCommunityIds.push(...communitiesForCategory.map((community) => community.id));
    groupedByCategory.push([category, communitiesForCategory]);
  });

  const dedupedPickedCommunityIds = dedupe(pickedCommunityIds);
  const uncategorizedCommunities = communities.filter((community) => {
    return !dedupedPickedCommunityIds.includes(community.id);
  });

  if (uncategorizedCommunities.length > 0) {
    groupedByCategory.push([{ id: 'UNCATEGORIZED', name: 'UNCATEGORIZED' }, uncategorizedCommunities]);
  }

  return groupedByCategory;
};

const groupByOrganization = (communities) => {
  const groupedByOrganizationId = communities.reduce((acc, community) => {
    if (community.organization) {
      acc[community.organization.id] = acc[community.organization.id] || [];
      acc[community.organization.id].push(community);
    }

    return acc;
  }, {});

  return Object.keys(groupedByOrganizationId).map((id) => {
    const organizationCommunities = groupedByOrganizationId[id];
    const organization = organizationCommunities[0].organization;

    return [organization, organizationCommunities];
  });
};

export const reduceGroupedWith = (grouped, filter) =>
  grouped.reduce((result, [key, value]) => {
    const matched = value.filter(filter);

    if (matched.length > 0) {
      const entry = [key, matched];
      return [...result, entry];
    }

    return result;
  }, []);

function normalizeData(data) {
  const normalizedData = normalize(data, Array.isArray(data) ? [CommunitySchema] : CommunitySchema);
  dispatch.entities.mergeEntities(normalizedData.entities);

  return normalizedData.result;
}

const INITIAL_STATE = [];

export const model = {
  name: 'communities',
  state: INITIAL_STATE,
  selectors: {
    all(state) {
      return denormalize(state, [CommunitySchema], getState().entities);
    },
    groupBy(state, type) {
      switch (type) {
        case 'category':
          return groupByCategory(denormalize(state, [CommunitySchema], getState().entities));
        case 'organization':
          return groupByOrganization(denormalize(state, [CommunitySchema], getState().entities));
        default:
          return [];
      }
    },
    find(state, communityId) {
      return denormalize(communityId, CommunitySchema, getState().entities);
    },
  },
  reducers: {
    'session/reset': () => INITIAL_STATE,
    setState: (state, payload) => payload,
    unshift: (state, payload) => {
      return state.filter((id) => id !== payload);
    },
    push: (state, payload) => {
      return payload.reduce((acc, id) => {
        if (!acc.includes(id)) {
          acc.push(id);
        }

        return acc;
      }, state);
    },
  },
  effects: (dispatch) => ({
    async get() {
      const organization = getAppConfig().organization;
      const organization_ids = [];
      if (organization) {
        organization_ids.push(organization.id);
      }
      const response = await CommunityService.get();
      return response;
    },
    async joinedAsync(organization) {
      const organizationId = organization ? organization.id : undefined;

      const response = await CommunityService.get({
        scope: 'joined',
        organization_id: organizationId,
      });

      if (response.ok) {
        this.setState(normalizeData(response.data));
      }

      return response;
    },

    async notJoinedAsync(organization) {
      const organizationId = organization ? organization.id : undefined;

      const response = await CommunityService.get({
        scope: 'not_joined',
        organization_id: organizationId,
      });

      if (response.ok) {
        this.setState(normalizeData(response.data));
      }

      return response;
    },

    async joinAsync({ community, payload }) {
      const response = await CommunityService.join(community.id, payload);
      await dispatch.session.fetchUser();

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    async joinWithSecretCodeAsync(code) {
      const response = await CommunityService.joinWithCode(code);
      await dispatch.session.fetchUser();

      if (response.ok) {
        normalizeData(response.data);
      }

      return response;
    },

    async unjoinAsync(community) {
      const response = await CommunityService.leave(community.id);
      await dispatch.session.fetchUser();

      if (response.ok) {
        this.unshift(normalizeData(response.data));
      }

      return response;
    },

    async findAsync(params) {
      let response = null;
      if (params.invitation_token) {
        response = await CommunityService.find(params.communityId, {
          invitation_token: params.invitation_token,
        });
      } else if (params.isForPublic) {
        response = await CommunityService.findForPublic(params.communityId);
      } else {
        response = await CommunityService.find(params.communityId);
      }
      normalizeData(response.data);
      return response;
    },

    async inviteUsers(payload) {
      const { params } = payload;
      const response = await CommunityService.inviteUsers(params);
      return response;
    },

    async getMembers({ id, limit, cursor }) {
      const response = await CommunityService.getMembers(id, { limit, cursor });
      return response;
    },

    async getInvitationToken({ id }) {
      const response = await CommunityService.getInvitationToken(id);
      return response;
    },
  }),
};
