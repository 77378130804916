import { FC } from 'react';

type P = {
  videoId: string;
  width?: string;
  height?: string;
  className?: string;
};

const YoutubeEmbedded: FC<P> = ({ videoId, height = '500', className = 'youtube-embedded-video' }: P) => (
  <div className={className}>
    <iframe
      width="100%"
      height={height}
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={`Embedded youtube video id: ${videoId}`}
    />
  </div>
);

export default YoutubeEmbedded;
