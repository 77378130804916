import { useMemo } from 'react';
import {
  type OnboardingTourStepOption,
  type SupportedOboardingTourStep,
  type UpdateOnboardingTourStepOptionHandler,
} from '../../models';

type NavigateThroughStepsOptions = {
  onNavigateToPreviousStep?: () => void;
  onNavigateToNextStep?: () => void;
};

const useNavigateToPreviousStep = ({
  step,
  steps,
  onChangeStep,
}: {
  step: OnboardingTourStepOption;
  steps: SupportedOboardingTourStep[];
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
}) =>
  useMemo(() => {
    const currentStepIndex = steps.findIndex((supportedStep) => supportedStep.step === step);

    if (currentStepIndex > 0) {
      return () => {
        onChangeStep({ step: steps[currentStepIndex - 1].step });
      };
    }

    if (currentStepIndex === 0) {
      return () => {
        onChangeStep({ step: 'intro' });
      };
    }

    return undefined;
  }, [onChangeStep, step, steps]);

const useNavigateToNextStep = ({
  step,
  steps,
  onChangeStep,
}: {
  step: OnboardingTourStepOption;
  steps: SupportedOboardingTourStep[];
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
}) =>
  useMemo(() => {
    if (step === 'intro') {
      if (steps.length === 0) {
        return () => {
          onChangeStep({ step: 'finish' });
        };
      }

      return () => {
        onChangeStep({ step: steps[0].step });
      };
    }

    const currentStepIndex = steps.findIndex((supportedStep) => supportedStep.step === step);

    if (currentStepIndex === steps.length - 1) {
      return () => {
        onChangeStep({ step: 'finish' });
      };
    }

    if (currentStepIndex > -1) {
      return () => {
        onChangeStep({ step: steps[currentStepIndex + 1].step });
      };
    }

    return undefined;
  }, [onChangeStep, step, steps]);

export const useNavigateThroughOnboardingTourSteps = ({
  step,
  steps,
  onChangeStep,
}: {
  step: OnboardingTourStepOption;
  steps: SupportedOboardingTourStep[];
  onChangeStep: UpdateOnboardingTourStepOptionHandler;
}) => {
  const onNavigateToPreviousStep = useNavigateToPreviousStep({ step, steps, onChangeStep });
  const onNavigateToNextStep = useNavigateToNextStep({ step, steps, onChangeStep });

  return useMemo<NavigateThroughStepsOptions>(
    () => ({
      onNavigateToPreviousStep,
      onNavigateToNextStep,
    }),
    [onNavigateToNextStep, onNavigateToPreviousStep],
  );
};
