export const getCurrentNodeToken = () => {
  return window.localStorage.getItem('accessNodeToken');
};

export const clearNodeToken = () => {
  window.localStorage.setItem('accessNodeToken', '');
};

export const storeNodeToken = (token: string) => {
  window.localStorage.setItem('accessNodeToken', token);
};
