import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

export const useNavigateToOrganizationChannel = () => {
  const { push } = useHistory();

  return useCallback(
    (id: string, scope: 'campaigns' | 'communities' | 'events' | 'programs') => {
      push(routes.channels.organizationChannel(id, scope));
    },
    [push],
  );
};
