import { type Form } from 'antd';
import cs from 'classnames';
import ReactPhoneInput from 'react-phone-number-input';
import { Item } from '../Item';

type ItemP = Omit<React.ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
};

type InputP = {
  placeholder?: string;
  value?: string;
  onChange?: (value?: string) => void;
  autocomplete?: string;
};

type P = {
  item: ItemP;
  input?: InputP;
};

const Input = ({ onChange, value, ...rest }: InputP) => {
  const handleOnChange = (value?: string) => onChange?.(value);

  return <ReactPhoneInput value={value} onChange={handleOnChange} defaultCountry="US" {...rest} />;
};

const PhoneInput = ({ item: { className, ...itemRest }, input }: P) => {
  return (
    <Item className={cs(className, 'form-phone-input')} {...itemRest}>
      <Input {...input} />
    </Item>
  );
};

export default PhoneInput;
