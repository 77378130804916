import { useMutation } from '@tanstack/react-query';
import { type ApiClientResponseError, type DefaultResponseErrorDataObject } from 'common/services/api';

import { subscribe } from '../../services';
import { type DeliveryData, type InfoData, type PersonalData } from '../../types';

export const useSubscribeQuery = () =>
  useMutation<
    Record<string, unknown>,
    ApiClientResponseError<DefaultResponseErrorDataObject>,
    {
      programId: string;
      infoData: InfoData;
      personalData: PersonalData;
      deliveryData: DeliveryData;
    }
  >({
    async mutationFn({ programId, infoData, personalData, deliveryData }) {
      const subscribeResponse = await subscribe({
        programId,
        infoData,
        personalData,
        deliveryData,
      });

      return subscribeResponse.data;
    },
  });
