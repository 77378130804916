import * as React from 'react';
import { Trans } from 'react-i18next';

import { Box } from '../atoms/box/Box';
import { Button } from '../atoms/button';
import { Icon } from '../atoms/icon';
import { Text } from '../atoms/text';
import { ThemeConsumer } from '../atoms/theme';

import { AppLogo } from 'ui';

export function BackButton(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <div style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
          <Button
            color="transparent"
            textColor={theme.color.brand}
            size="lg"
            title={(buttonProps) => (
              <React.Fragment>
                <Icon name="pba-arrow-left" size={28} color={buttonProps.textColor} />
                <Text style={buttonProps.textStyle} color={buttonProps.textColor}>
                  <Trans>Go Back</Trans>
                </Text>
              </React.Fragment>
            )}
            type="button"
            block
            {...props}
          />
        </div>
      )}
    </ThemeConsumer>
  );
}

export function NextButton(props) {
  return (
    <div style={{ width: 130, paddingLeft: 10, paddingRight: 10 }}>
      <Button color="#00D36C" textColor="white" size="lg" type="button" block {...props} />
    </div>
  );
}

export function CategoriesLayout(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box className="categories-layout">
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="categories-layout__logo-wrapper"
          >
            <AppLogo className="categories-layout__logo" />
            <Box flexDirection="row" alignItems="center" className="categories-layout__buttons-wrapper">
              {props.buttons}
            </Box>
          </Box>
          <div className="categories-layout__body">{props.children}</div>
        </Box>
      )}
    </ThemeConsumer>
  );
}
