import { AxiosResponse } from 'axios';
import { nodeApiClient } from 'nodeApiClient';
import { routes } from '../../../common/services';

import { PaginationDataObject, PaginationRequest } from '../../../common/services/data-objects';
import { ID } from '../../../common/types';
import { MessageType } from '../../../types';
import { MessageDataObject } from '../../data-objects';

export type FindChatroomMessagesRequest = PaginationRequest & {
  type: MessageType;
  $sort: {
    createdAt: number;
  };
  createdAt?: {
    $lt: string;
  };
};

export type FindChatroomMessagesDataObject = PaginationDataObject<MessageDataObject>;

export const findChatroomMessages = (chatroom: ID, params?: FindChatroomMessagesRequest) =>
  nodeApiClient.get<FindChatroomMessagesDataObject, AxiosResponse<FindChatroomMessagesDataObject>>(
    // TODO: Update rules and remove this eslint disable
    // eslint-disable-next-line unicorn/no-array-callback-reference
    routes.chatrooms.messages.find(chatroom),
    {
      params,
    },
  );
