import { CommentIcon, Empty } from 'ui';

const CommentsEmpty = () => {
  return (
    <Empty
      image={<CommentIcon />}
      description={{
        title: 'The conversation is about to begin',
        subtitle: 'Be first to add a comment and start a discussion',
      }}
    />
  );
};

export default CommentsEmpty;
