import { useQueryClient } from '@tanstack/react-query';
import { type UserRSVP } from 'models';
import { useMutation } from 'query';
import { handleAxiosError } from 'common/error-handlers';
// TODO fix these dependencies, everything should be one feature
import { getEventsQueryKey } from 'features/communities/queries';
import { queries } from 'features/todos';
import { updateEventUserStatus } from '../../services';

export const useUpdateEventUserStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<undefined, { id: string; statusToApply: UserRSVP }>(
    async ({ id, statusToApply }) => {
      const response = await updateEventUserStatus(id, statusToApply);

      return response.data;
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(getEventsQueryKey({ type: 'upcoming' }));
        await queryClient.invalidateQueries(queries.getTodosQueryKey({}));
      },
      async onError(error) {
        handleAxiosError(error);
        await queryClient.invalidateQueries(getEventsQueryKey({}));
        await queryClient.invalidateQueries(queries.getTodosQueryKey({}));
      },
    },
  );
};
