import { Practice } from '../../models';
import { findPractice as findPracticeApi, PracticeDataObject } from '../../services';

import { PaginationData } from '../../types';
import { transformError, transformResponse } from '.';

export const findPractice = async ({ legacyId }: { legacyId: string }) => {
  try {
    const response = await findPracticeApi({ legacyId });
    return transformResponse<PaginationData<PracticeDataObject>, PaginationData<Practice>>(
      response,
      ({ data, ...rest }: PaginationData<PracticeDataObject>) => ({
        ...rest,
        data: data.map((practice) => new Practice(practice)),
      }),
    );
  } catch (error) {
    return transformError(error);
  }
};
