import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import { Button } from '../button';
import { dispatch } from '../../store';
import { isErrorResponse } from '../../utils/error';
import { showAlert } from '../alert';
import { TextField } from '../form';
import { ThemeConsumer } from '../theme';
import { validator } from '../../utils/validator';
import { ErrorBox } from '../../atoms/error';

export class Renderer extends React.Component {
  state = {
    response: null,
  };

  render() {
    const { response } = this.state;

    return (
      <Formik
        initialValues={{ current_password: '', password: '', password_confirmation: '' }}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true);
          const response = await dispatch.session.updatePassword(values);

          if (isErrorResponse(response)) {
            this.setState({ response: response });
          } else {
            showAlert({
              type: 'success',
              message: <Trans>Password was successfully updated.</Trans>,
            });
          }

          formik.setSubmitting(false);
        }}
        validate={(values) =>
          validator.validateAll(values, {
            current_password: 'required',
            password: 'required|password_regex',
            password_confirmation: 'required|same:password',
          })
        }
      >
        {(formik) => {
          return (
            <ThemeConsumer>
              {(theme) => (
                <Form>
                  <div style={{ padding: '0 33px' }}>
                    <TextField
                      name="current_password"
                      label={<Trans>Current password</Trans>}
                      secret
                      id={'current_password'}
                    />
                    <TextField name="password" label={<Trans>New password</Trans>} secret id={'new_password'} />
                    <TextField
                      name="password_confirmation"
                      label={<Trans>Confirm new password</Trans>}
                      secret
                      id={'new_password-confirm'}
                    />
                    {response?.problem ? (
                      <ErrorBox id="same_password">
                        <ul className="form__error-list">
                          {response?.data?.['invalid-params'] ? (
                            Object.values(response.data['invalid-params']).map((error, index) => (
                              <li key={index}>{this.props.t(`${error.name} ${error.reason}`)}</li>
                            ))
                          ) : (
                            <li>{this.props.t('Sorry, something went wrong.')}</li>
                          )}
                        </ul>
                      </ErrorBox>
                    ) : null}
                    <div style={{ width: 200, marginTop: 50 }}>
                      <Button
                        block
                        type="submit"
                        color={theme.color.brand}
                        textColor="white"
                        size="lg"
                        title={this.props.t('Change Password')}
                        disabled={formik.isSubmitting}
                        id={'change-password-button'}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </ThemeConsumer>
          );
        }}
      </Formik>
    );
  }
}

export const ChangePasswordForm = withTranslation()(Renderer);
