import { Input } from 'antd';
import { type ComponentProps } from 'react';
import { Item } from './Item';

type ItemP<Values> = Omit<ComponentProps<typeof Item<Values>>, 'children'>;

type InputP = Omit<ComponentProps<typeof Input>, 'css'> & Record<string, unknown>;

type P<Values> = {
  item: ItemP<Values>;
  input?: InputP;
};

const TextInput = <Values = any,>({ item, input }: P<Values>) => {
  return (
    <Item<Values> {...item}>
      <Input size="large" {...input} />
    </Item>
  );
};

export default TextInput;
