import * as React from 'react';
import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';

import { AppLayout } from '../../layouts/AppLayout';
import { EventLayout } from '../../layouts/EventLayout';
import { AttendeesList } from './AttendeesList';
import { Box } from '../../atoms/box';
import { Text } from '../../atoms/text';
import { LoaderOverlay } from '../../atoms/loader';
import { CircleIcon } from '../../atoms/icon';
import { ThemeConsumer } from '../../atoms/theme';
import { Filter } from '../../atoms/filter';
import { showSuccessErrorAlert } from '../../atoms/alert';
import { Loader } from '../../atoms/loader';
import { InViewport } from '../../atoms/in-viewport';
import { Button, Col, Row, Card } from 'ui';
import { QuickInviteEventAttendees } from 'features/events';
import { onErrorReport, onSuccessReport } from '../../utils/report';
import { EventOrganizers } from '../../atoms/events';

const PAGE_LIMIT = 20;

class Renderer extends React.Component {
  state = {
    filterBy: 'all',
    members: [],
    hasMoreObjects: true,
    isInvModalOpened: false,
  };

  filterItems = [
    {
      key: 'all',
      name: <Trans>All Invitees</Trans>,
    },
    {
      key: 'going',
      name: <Trans>Registered</Trans>,
    },
    {
      key: 'pending',
      name: <Trans>Pending</Trans>,
    },
    {
      key: 'not_going',
      name: <Trans>Declined</Trans>,
    },
  ];

  componentDidMount() {
    this.getNextObjects();
  }

  getNextObjects = async () => {
    if (!this.props.loading) {
      const lastObject = this.state.members[this.state.members.length - 1];
      const resp = await this.props.fetchInvitees(
        this.state.filterBy,
        PAGE_LIMIT,
        lastObject ? lastObject.id : undefined,
      );

      if (resp.ok) {
        // If it is email invitee, do not ask for another Page, it was last then
        const lastObject = resp.data[resp.data.length - 1];

        if (!lastObject?.id || resp.data.length < PAGE_LIMIT) {
          this.setState({ hasMoreObjects: false, members: this.state.members.concat(resp.data) });
        } else {
          this.setState({ members: this.state.members.concat(resp.data) });
        }
      }
    }
  };

  deleteEvent = async (event) => {
    if (window.confirm(this.props.t('Are you sure you want to delete this event?'))) {
      const response = await this.props.deleteEvent(event);

      showSuccessErrorAlert({
        isOk: response.ok,
        successMessage: <Trans>Event successfully deleted.</Trans>,
        errorMessage: <Trans>Event was not deleted, please try again later.</Trans>,
      });

      if (response.ok) {
        this.props.history.push(`/feed`);
      }
    }
  };

  reloadMembers = () => {
    this.setState({ members: [], hasMoreObjects: true }, () => this.getNextObjects());
  };

  setFilter = (filter) => {
    this.setState({ filterBy: filter, members: [], hasMoreObjects: true }, () => this.getNextObjects());
  };

  navigateToEvent = (eventContentObject) => {
    this.props.history.push(`/events/${eventContentObject.event.id}`);
  };

  openInvModal = () => {
    this.setState({ isInvModalOpened: true });
  };

  closeInvModal = () => {
    this.setState({ isInvModalOpened: false });
  };

  renderRsvpIcons = (event, theme) => {
    const { filterBy } = this.state;

    return (
      <Box flexDirection="row">
        <div className="filter__status-box">
          <CircleIcon
            size={28}
            name="check"
            color="white"
            background={filterBy === 'going' ? theme.color.green : theme.color.lightGray}
          />
          <p>
            <Text>{event?.going_invitees_count}</Text>
          </p>
          <p>
            <Text>
              <Trans>Registered</Trans>
            </Text>
          </p>
        </div>
        <div className="filter__status-box">
          <CircleIcon
            size={28}
            name="question-mark"
            color="white"
            background={filterBy === 'pending' ? '#e5cb00' : theme.color.lightGray}
          />
          <p>
            <Text>{event?.pending_invitees_count}</Text>
          </p>
          <p>
            <Text>
              <Trans>Pending</Trans>
            </Text>
          </p>
        </div>
        <div className="filter__status-box">
          <CircleIcon
            size={28}
            name="close"
            color="white"
            background={filterBy === 'not_going' ? theme.color.red : theme.color.lightGray}
          />
          <p>
            <Text>{event?.not_going_invitees_count}</Text>
          </p>
          <p>
            <Text>
              <Trans>Declined</Trans>
            </Text>
          </p>
        </div>
      </Box>
    );
  };

  render() {
    return (
      <ThemeConsumer>
        {(theme) => (
          <AppLayout
            center={
              <LoaderOverlay
                text={<Trans>It can take several seconds to delete an event. Please wait.</Trans>}
                loading={this.props.deletingEvent}
              >
                <EventLayout
                  eventId={this.props.eventId}
                  onDeleteEvent={this.deleteEvent}
                  onReportEvent={this.props.reportObject}
                  onDuplicateEvent={this.navigateToEvent}
                  location={this.props.location}
                  onEventEdit={this.reloadMembers}
                >
                  {(object) => {
                    const { event = {} } = object;

                    const showInviteButton =
                      !(event.program || event.program_id) &&
                      moment().isBefore(moment(event.end_time).add(1, 'hours')) && // Event can be edited 1 hour after end of event still
                      (this.props.viewer.superadmin ||
                        (object?.post_in_communities?.map((community) => community.id) || []).some((commId) =>
                          this.props.viewer.admin_communities?.includes(commId),
                        ) ||
                        object?.author?.id === this.props.viewer.id);

                    return (
                      <div className="row">
                        <div className="col-xs-7">
                          <Card className="filter__status-box-wrapper">
                            <Row item={{ gutter: 16, align: 'middle', justify: 'space-between' }}>
                              <Col item={{ span: 12 }}>{this.renderRsvpIcons(event, theme)}</Col>
                              <Col item={{ span: 12, className: 'filter__invite-button-wrapper' }}>
                                {showInviteButton && (
                                  <Button
                                    className="filter__invite-button"
                                    type="primary"
                                    size="large"
                                    onClick={() => this.openInvModal()}
                                  >
                                    Invite Members
                                  </Button>
                                )}
                                {this.state.isInvModalOpened && (
                                  <QuickInviteEventAttendees
                                    eventId={event.id}
                                    isOpened={this.state.isInvModalOpened}
                                    onCancel={() => this.closeInvModal()}
                                    onSuccess={() => {
                                      this.closeInvModal();
                                      this.reloadMembers();
                                    }}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Card>
                          <Card
                            id="attendees-card"
                            title={
                              <Row item={{ gutter: 16, align: 'middle' }}>
                                <Col item={{ span: 12, className: 'filter__label' }}>
                                  <Trans>Attendees</Trans>
                                </Col>
                                <Col item={{ span: 12 }}>
                                  <Filter
                                    title={<Trans>Filter:</Trans>}
                                    items={this.filterItems}
                                    filter={this.state.filterBy}
                                    onSort={this.setFilter}
                                  />
                                </Col>
                              </Row>
                            }
                          >
                            <AttendeesList
                              viewer={this.props.viewer}
                              invitees={this.state.members}
                              loading={this.props.loading}
                            />
                            {this.props.loading ? (
                              <Loader />
                            ) : this.state.hasMoreObjects ? (
                              <InViewport key="page-events-attendees-viewport" onEnter={this.getNextObjects} />
                            ) : null}
                          </Card>
                        </div>

                        <div className="col-xs-5">
                          <EventOrganizers event={event} viewer={this.props.viewer} />
                        </div>
                      </div>
                    );
                  }}
                </EventLayout>
              </LoaderOverlay>
            }
          />
        )}
      </ThemeConsumer>
    );
  }
}

const mapState = (state, props) => ({
  viewer: select.session.user(state),
  eventId: props.match.params.eventId,
  deletingEvent: state.loading.effects.events.deleteAsync,
  loading: state.loading.effects.events.fetchInviteesAsync,
});

const mapDispatch = (dispatch, props) => ({
  deleteEvent: (event) => dispatch.events.deleteAsync(event),
  reportObject: async (object) => {
    if (await window.confirm('Report this Post/Event/Comment?')) {
      return dispatch.feed.reportAsync({ object: object, onSuccess: onSuccessReport, onError: onErrorReport });
    }
  },
  fetchInvitees: (status, limit, cursor) =>
    dispatch.events.fetchInviteesAsync({
      id: props.match.params.eventId,
      status: status,
      limit: limit,
      cursor: cursor,
    }),
});

export const PageEventAttendees = withTranslation()(connect(mapState, mapDispatch)(Renderer));
