import { Steps as StepsA } from 'antd';
import { useTranslation } from 'react-i18next';

type P = {
  currentStep: number;
  numberOfSteps: number;
  onChange?: (step: number) => void;
};

const Steps = ({ currentStep, numberOfSteps, onChange }: P) => {
  const { t } = useTranslation();

  return (
    <StepsA current={currentStep} onChange={onChange} className="steps">
      {[...Array.from({ length: numberOfSteps }).keys()].map((key) => {
        const isStepDone = key < currentStep;
        const isCurrentStep = key === currentStep;
        const stepLabel = isStepDone ? t('StepCompleted', { stepNumber: key + 1 }) : t('Step', { stepNumber: key + 1 });

        return (
          <StepsA.Step key={`step-${key}`} aria-label={stepLabel} aria-current={isCurrentStep ? 'true' : undefined} />
        );
      })}
    </StepsA>
  );
};

export default Steps;
