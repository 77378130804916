import { type ChannelEntity, getChannelEntityData } from 'models';
import { type QueryFunctionContext, type QueryKey, useInfiniteQuery } from 'query';
import { getChannelEntities } from '../services';
import { type EntityType } from '../types';
import { getChannelEntitiesQueryKey } from '.';

type P = {
  pageSize: number;
  channelId: string;
  featured?: boolean;
  subject?: EntityType;
};

export const useChannelEntitiesQuery = ({ pageSize, channelId, featured, subject }: P) => {
  return useInfiniteQuery<ChannelEntity[]>(
    getChannelEntitiesQueryKey({ pageSize, channelId, featured, subject }),
    async ({ pageParam: pageParameter = undefined }: QueryFunctionContext<QueryKey, number>) => {
      const response = await getChannelEntities(channelId, {
        limit: pageSize,
        offset: pageParameter,
        featured,
        subject,
      });

      return response.data.map((entity) => getChannelEntityData(entity));
    },
    {
      getNextPageParam(lastPage, allPages) {
        return lastPage.length < pageSize ? undefined : allPages.length * pageSize;
      },
    },
  );
};
