const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }

      seen.add(value);
    }

    return value;
  };
};

export const removeCircularReference = <T>(data: T): T => {
  if (typeof data === 'object') {
    return JSON.parse(JSON.stringify(data, getCircularReplacer()));
  }

  return data;
};
