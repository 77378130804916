import { Row as RowAD } from 'antd';
import type React from 'react';
import { useTranslation } from 'react-i18next';

type ItemP = React.ComponentProps<typeof RowAD> & {
  'data-test-id'?: string;
};

type P = {
  item?: ItemP;
};

const Row: React.FC<P> = ({ item, children }) => {
  const { t } = useTranslation();

  return <RowAD {...item}>{typeof children === 'string' ? t(children) : children}</RowAD>;
};

export default Row;
