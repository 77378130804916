import { type ReduxUser } from 'models';
import { useTranslation } from 'react-i18next';
import { Title } from 'ui';
import { type GreetingsScope } from '../Greetings';

const GreetingsTitle = ({
  scope,
  className,
  viewer,
}: {
  scope: GreetingsScope;
  className?: string;
  viewer: ReduxUser;
}) => {
  const { t } = useTranslation();

  const getTitle = () => {
    switch (scope) {
      case 'morning':
        return t('Good morning, {{name}}', { name: viewer.first_name });
      case 'afternoon':
        return t('Good afternoon, {{name}}', { name: viewer.first_name });
      case 'evening':
        return t('Good evening, {{name}}', { name: viewer.first_name });
    }
  };

  return (
    <Title level={5} ellipsis={{ rows: 2 }} className={className}>
      {getTitle()}
    </Title>
  );
};

export default GreetingsTitle;
