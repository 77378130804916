import * as React from 'react';
import { Formik } from 'formik';
import { Trans, withTranslation } from 'react-i18next';
import { Alert } from 'ui';

import { ThemeConsumer } from '../theme';
import { Box } from '../box';
import { Button } from '../button';
import { showSuccessErrorAlert } from '../alert';
import { OrganizationsField, CommunitiesField, MembersField } from '../form/smart-fields';

export class Renderer extends React.Component {
  invited_organizations() {
    const course = this.props.course || {};
    return (course.invited_organizations || []).map((o) => ({ id: o.id, name: o.name }));
  }

  invited_communities() {
    const course = this.props.course || {};
    return (course.invited_communities || []).map((c) => ({ id: c.id, name: c.name }));
  }

  invited_users() {
    const course = this.props.course || {};
    return (course.invited_users || []).map((u) => ({ id: u.id, first_name: u.first_name, last_name: u.last_name }));
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          invited_organizations: this.invited_organizations(),
          invited_communities: this.invited_communities(),
          invited_users: this.invited_users(),
        }}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true);

          const response = await this.props.onSubmit(values);
          formik.setSubmitting(false);

          showSuccessErrorAlert({
            isOk: response.ok,
            successMessage: <Trans>Invitations have been sent.</Trans>,
          });

          if (response?.ok) {
            this.props.onSuccess(response);
          }
        }}
      >
        {(formik) => (
          <ThemeConsumer>
            {(theme) => (
              <React.Fragment>
                <div style={{ marginBottom: 15 }}>
                  <OrganizationsField
                    placeholder={this.props.t('Search Organization')}
                    name="invited_organizations"
                    scope="admin"
                    label={<Trans>Invite Organizations</Trans>}
                    currentSelectedData={formik.values.invited_organizations}
                    id="search_organization"
                  />
                </div>

                <div style={{ marginBottom: 15 }}>
                  <CommunitiesField
                    placeholder={this.props.t('Search Community')}
                    name="invited_communities"
                    scope="admin"
                    label={<Trans>Invite Communities</Trans>}
                    currentSelectedData={formik.values.invited_communities}
                    id="search_community"
                  />
                </div>

                <div style={{ marginBottom: 15 }}>
                  <MembersField
                    placeholder={this.props.t('Search Member')}
                    name="invited_users"
                    scope="all"
                    label={<Trans>Enroll Members</Trans>}
                    currentSelectedData={formik.values.invited_users}
                    id="search_user"
                    courseId={this.props.course?.id}
                  />
                </div>

                {formik.values.invited_organizations?.length > 0 || formik.values.invited_communities?.length > 0 ? (
                  <Alert
                    showIcon
                    type="warning"
                    message={<Trans>Inviting the entire communities or organizations can result in a large number of invitations being sent. Please review the selection carefully before submitting to avoid any errors.</Trans>}
                  />
                ) : null}

                <Box flexDirection="row" justifyContent="flex-end">
                  <Button
                    size="md"
                    title={<Trans>Submit</Trans>}
                    type="submit"
                    color={theme.color.brand}
                    onClick={formik.submitForm}
                    textColor="white"
                    style={{ marginTop: 10 }}
                    disabled={formik.isSubmitting}
                    id="btn_submit-invite"
                  />
                </Box>
              </React.Fragment>
            )}
          </ThemeConsumer>
        )}
      </Formik>
    );
  }
}

export const CourseInviteForm = withTranslation()(Renderer);
