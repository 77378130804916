import { Dropdown as DropdownA } from 'antd';

import classNames from 'classnames';

type P = React.ComponentProps<typeof DropdownA>;

const Dropdown = ({ menu, ...rest }: P) => (
  <DropdownA menu={menu ? { ...menu, className: classNames('dropdown-menu', menu.className) } : undefined} {...rest} />
);

export default Dropdown;

export { type MenuProps } from 'antd/lib/menu';
