import { type Form, Radio } from 'antd';
import type React from 'react';
import { Item } from './Item';

type ItemP = Omit<React.ComponentProps<typeof Form.Item>, 'children' | 'label'> & {
  label?: string;
  hidden?: boolean;
};

type InputP = Omit<React.ComponentProps<typeof Radio.Group>, 'css' | 'options'> & {
  options: any[];
};

type P = {
  item: ItemP;
  input: InputP;
};

const RadioGroupInput = ({ item, input: { options, ...restInput } }: P) => {
  return (
    <Item {...item}>
      <Radio.Group {...restInput}>
        {options.map((option) => {
          return restInput.optionType === 'button' ? (
            <Radio.Button
              key={`radio_${item.name}_${option.value}`}
              value={option.value}
              disabled={option.disabled}
              data-test-id={option?.['data-test-id']}
            >
              {option.label}
            </Radio.Button>
          ) : (
            <Radio
              key={`radio_${item.name}_${option.value}`}
              disabled={option.disabled}
              value={option.value}
              id={option?.id}
            >
              {option.label}
            </Radio>
          );
        })}
      </Radio.Group>
    </Item>
  );
};

export default RadioGroupInput;
