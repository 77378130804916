import { MessageIcon } from '../../../ui/Icons';
import { Row } from '../../../ui';
import { useTranslation } from 'react-i18next';

type P = {
  isSearched?: boolean;
};

const ChatroomSidebarEmptyState = ({ isSearched }: P) => {
  const { t } = useTranslation(['translationChat']);

  return (
    <Row className="chatroom-sidebar-empty-state" align="middle" justify="center">
      <MessageIcon className="chatroom-sidebar-empty-state__icon" />
      <span className="label--large">
        {isSearched? t('chat.chatroomSidebar.emptyStates.noSearchResultsFound') : t('chat.chatroomSidebar.emptyStates.startConversation')}
      </span>
      <div className="chatroom-sidebar-empty-state__description">
        {isSearched
          ? t('chat.chatroomSidebar.emptyStates.noSearchResultsWithNoChatroom')
          : t('chat.chatroomSidebar.emptyStates.noResultsWithChatroom')}
      </div>
    </Row>
  );
};

export default ChatroomSidebarEmptyState;
