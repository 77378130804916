import { connect } from 'react-redux';
import { select } from '@rematch/select';
import { useTranslation } from 'react-i18next';
import { DirectMessageButton, IconDirectMessageButton } from 'features/chat';

const ChatToButtonComponent = ({
  legacyUserId,
  useTextButton,
  viewer,
}: {
  legacyUserId: string;
  viewer?: { id: string };
  useTextButton?: boolean;
}) => {
  const { t } = useTranslation();

  if (!viewer) {
    return null;
  }

  return useTextButton ? (
    <DirectMessageButton
      legacyUserId={legacyUserId}
      viewer={viewer}
      className="chat-to-button__text-button"
      type="default"
    >
      {t('Message')}
    </DirectMessageButton>
  ) : (
    <IconDirectMessageButton legacyUserId={legacyUserId} viewer={viewer} small={true} />
  );
};

//TODO: Use correct types for select
const mapState = (state: any) => ({
  // @ts-ignore
  viewer: select.session.user(state),
});

export const ChatToButton = connect(mapState)(ChatToButtonComponent);
