import { type AccountDataObject, type PositionAccountDataObject } from './service';

export class Account {
  _id: string;
  accounts: PositionAccountDataObject[];
  points?: number;
  position: number;
  meta?: {
    leaderboard?: {
      position?: number;
    };
  };

  constructor(data: AccountDataObject) {
    this._id = data._id;
    this.accounts = data.accounts;
    this.points = typeof data.points === 'number' ? data.points : data.points.total;
    this.position = data.position;
    this.meta = data.meta;
  }
}
