import cs from 'classnames';
import { type ReactNode } from 'react';

export type SectionProperties = {
  lineTop?: boolean;
  lineBottom?: boolean;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  className?: string;
  children?: ReactNode;
};

const Section = ({
  lineTop = false,
  lineBottom = true,
  paddingTop = true,
  paddingBottom = true,
  className,
  ...rest
}: SectionProperties) => {
  return (
    <div
      className={cs('form__section', className, {
        'form__section-top-padding': paddingTop,
        'form__section-top': lineTop,
        'form__section-bottom-padding': paddingBottom,
        'form__section-bottom': lineBottom,
      })}
      {...rest}
    />
  );
};

export default Section;
