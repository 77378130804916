import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { socketConnection } from '../recoil/atoms';

export const useCreateSocketIO = ({ token }: { token: string | undefined }) => {
  const [lastLoggedToken, setLastLoggedToken] = useState<string>();
  const socket = useRecoilValue(socketConnection);

  useEffect(() => {
    const login = () =>
      socket.emit('create', 'authentication', {
        strategy: 'jwt',
        accessToken: token,
      });

    const logout = () => socket.emit('remove', 'authentication', lastLoggedToken);

    const event = 'connect';
    const onConnect = () => {
      if (token) {
        login();
        setLastLoggedToken(token);
      }
    };

    socket.on(event, onConnect);

    if (lastLoggedToken !== token && socket.connected) {
      if (lastLoggedToken) {
        logout();
      }

      setLastLoggedToken(token);

      if (token) {
        login();
      }
    }

    return () => {
      socket.removeEventListener(event, onConnect);
    };
  }, [socket, token, lastLoggedToken]);

  // TODO maybe reconnect?
  return { socket };
};

export const useSocketIO = <T>({
  event,
  onEvent,
}: {
  event: string | 'connect_error' | 'connect' | 'disconnect';
  onEvent: (data: T) => void;
}) => {
  const socket = useRecoilValue(socketConnection);

  useEffect(() => {
    socket.on(event, onEvent);

    return () => {
      socket.removeEventListener(event, onEvent);
    };
  }, [socket, event, onEvent]);
};

export { SocketConnection } from './SocketConnection';
