import * as React from 'react';
import { ContentObjectRenderer } from '../feed-object';
import { FeedBookmarksCore } from './FeedBookmarksCore';
import { FeedJournalCore } from './FeedJournalCore';
import { FeedCoreInternal } from './FeedCoreInternal';
import cn from 'classnames';

import { Card } from 'ui';

export class RegularFeed extends React.Component {
  isAuthor(author) {
    return author.id === this.props.viewer.id;
  }

  renderObject = (object, actions) => {
    return (
      <div
        id={`${object.type}_${object[object.type]?.id}`}
        className={cn('regular-feed', { 'regular-feed__without-create': this.props.skipFirstMargin })}
      >
        <Card shrinked>
          <ContentObjectRenderer
            item={object}
            isOnFeed={true}
            onCreateComment={actions.createComment}
            onCreateObject={actions.createObject}
            onDeleteObject={actions.deleteObject}
            onEditObject={actions.updateObject}
            onInitiatePostEditing={this.props.onInitiatePostEditing}
            onLikeObject={actions.likeObject}
            onReportObject={actions.reportObject}
            onRequestEventDetail={this.props.onRequestEventDetail}
            onSortChange={actions.sortChange}
            onReloadObject={actions.reloadObject}
            onBookmarkObject={actions.bookmarkObject}
            viewer={this.props.viewer}
            reloadingPost={this.props.reloadingPost}
            isBookmark={this.props.type === 'bookmarks'}
            isJournal={this.props.type === 'journal'}
          />
        </Card>
      </div>
    );
  };

  render() {
    if (this.props.type === 'bookmarks') {
      return <FeedBookmarksCore {...this.props} direction="vertical" renderObject={this.renderObject} />;
    }
    if (this.props.type === 'journal') {
      return <FeedJournalCore {...this.props} direction="vertical" renderObject={this.renderObject} />;
    }
    return (
      <FeedCoreInternal
        keyPrefix={'regular-feed'}
        {...this.props}
        direction="vertical"
        renderObject={this.renderObject}
      />
    );
  }
}
