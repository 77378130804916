import { http } from './shared';

export const CoursesService = {
  get: (queryParams) => http.get(`/programs`, queryParams),
  getUsers: (id, params) => http.get(`/programs/${id}/users`, params),
  find: (courseId) => http.get(`/programs/${courseId}`),
  create: (payload) => http.post(`/programs`, { program: payload }),
  update: (id, payload) => http.put(`/programs/${id}`, { program: payload }),
  accept: (courseId) => http.post(`/programs/${courseId}/join`),
  decline: (courseId) => http.delete(`/programs/${courseId}/join`),
  invite: (courseId, payload) => http.post(`/programs/${courseId}/invite`, { program: payload }),
  deleteMember: (payload) => http.post(`/admin/users/change_role`, payload),
  getMembers: (courseId, params) => http.get(`/programs/${courseId}/users`, params),
  delete: (id) => http.delete(`/programs/${id}`),
  reportAbuse: (id) => http.post(`/abuse_reports`, { abuse_report: { program_id: id } }),
  duplicate: (id, params) => http.post(`/programs/${id}/duplicate`, params),
  updateStatus: (id, payload) => http.post(`/programs/${id}/update_state`, { program: payload }),
};
