import { useTranslation } from 'react-i18next';
import { message } from 'ui';
import { useCreateFolder } from '../../../queries';
import { AssetEditor } from '../AssetEditor';

type P = {
  isOpened: boolean;
  onCancel: () => void;
  communityId: string;
  parentId?: string;
};

const CreateFolder = ({ isOpened, onCancel, communityId, parentId }: P) => {
  const { t } = useTranslation();

  const { isLoading: isLoadingCreateFolder, mutate: createFolder } = useCreateFolder();

  return (
    <AssetEditor
      isOpened={isOpened}
      loading={isLoadingCreateFolder}
      onCancel={() => {
        onCancel();
      }}
      onFinish={(values: { name: string }) => {
        createFolder(
          { name: values.name, params: { communityId, parentId } },
          {
            onSuccess() {
              message.success(t('Folder Successfully Created.'));
              onCancel();
            },
          },
        );
      }}
      title="Create folder"
      inputLabel="Folder name"
    />
  );
};

export default CreateFolder;
