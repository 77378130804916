import { DefaultValue, selector, selectorFamily, waitForAll } from 'recoil';
import { DataState, transformError } from '../../common/recoil/queries';
import { activePracticeIdState } from '../../common/recoil/selectors';
import { ID, PaginationOptions } from '../../common/types';
import { Chatroom, Message } from '../../models';

import { ChatroomsStateType } from '../../types';
import { activeChatroom, chatrooms, chatroomsPaginationOptions, chatroomsSearch, deletedChatrooms } from '../atoms';
import { defaultChatroomsPaginationOptions } from '../defaults';
import { getChatrooms } from '../queries';

export const chatroomsState = selector<DataState<ChatroomsStateType>>({
  key: 'ChatroomsState',
  get: async ({ get }) => {
    // TODO: refactor to functions and name them
    const { pagination, search, activePracticeId } = get(
      waitForAll({
        pagination: chatroomsDependenciesState,
        search: chatroomsSearchState,
        activePracticeId: activePracticeIdState,
      }),
    );

    const chatroomsPaginatedData = await getChatrooms(pagination, activePracticeId as ID, search);

    if (chatroomsPaginatedData.error) {
      return transformError(chatroomsPaginatedData.error);
    }

    const { data: chatrooms, skip, limit, total } = chatroomsPaginatedData.result;

    const userIds = new Set<string>();
    for (const chatroom of chatrooms) {
      for (const user of chatroom.users) {
        userIds.add(user);
      }
    }

    return {
      error: null,
      result: {
        chatrooms,
        total,
        hasMoreChatrooms: skip + limit <= total,
      },
    };
  },
  set: ({ reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(chatroomsDependenciesState);
    }
  },
});

export const chatroomsSearchState = selector<string | undefined>({
  key: 'ChatroomsSearchState',
  get: ({ get }) => get(chatroomsSearch),
  set: ({ set, reset }, newValue) => {
    reset(chatroomsDependenciesState);
    set(chatroomsSearch, newValue);
    set(chatrooms, []);
  },
});

export const chatroomsDependenciesState = selector<PaginationOptions>({
  key: 'ChatroomsDependenciesState',
  get: ({ get }) => get(chatroomsPaginationOptions),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(chatroomsPaginationOptions, { ...defaultChatroomsPaginationOptions });
    } else {
      set(chatroomsPaginationOptions, newValue);
    }
  },
});

export const chatroomsLastUpdatedMessageState = selector<Message | undefined>({
  key: 'ChatroomsLastUpdatedMessageState',
  get: ({ get }) => get(chatrooms)[0].message,
  set: ({ get, set }, newValue) => {
    if (newValue && !(newValue instanceof DefaultValue)) {
      const { chatroom: chatroomId, createdAt } = newValue;
      const loadedChatrooms = get(chatrooms);
      const lastUpdatedChatroom = loadedChatrooms.find((c) => c._id === chatroomId);
      const restLoadedChatrooms = loadedChatrooms.filter((c) => c._id !== chatroomId);

      if (lastUpdatedChatroom) {
        const newChatroom = new Chatroom({
          ...lastUpdatedChatroom,
          message: newValue,
          createdAt: lastUpdatedChatroom.createdAt.toISOString(),
          updatedAt: createdAt.toISOString(),
        });

        const newChatrooms = [newChatroom, ...restLoadedChatrooms];
        set(chatrooms, newChatrooms);
      } else {
        // TODO: Fetch chatroom if updated chatroom is not in array of loaded chatrooms
      }
    }
  },
});

export const chatroomsLastUpdatedState = selector<Chatroom>({
  key: 'ChatroomsLastUpdatedState',
  get: ({ get }) => get(chatrooms)[0],
  set: ({ get, set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const { _id: updatedChatroomId, updatedAt, name, users, message, stats, participants } = newValue;
      const loadedChatrooms = get(chatrooms);
      const lastUpdatedChatroom = loadedChatrooms.find((c) => c._id === updatedChatroomId);
      const restLoadedChatrooms = loadedChatrooms.filter((c) => c._id !== updatedChatroomId);

      if (!lastUpdatedChatroom) {
        set(chatrooms, [newValue, ...restLoadedChatrooms]);
        return;
      }

      const newChatroom = new Chatroom({
        ...lastUpdatedChatroom,
        createdAt: lastUpdatedChatroom.createdAt.toISOString(),
        updatedAt: updatedAt.toISOString(),
        name,
        users,
        message,
        stats,
        participants,
      });
      const newChatrooms = [newChatroom, ...restLoadedChatrooms];
      set(chatrooms, newChatrooms);
    }
  },
});

export const updateChatroomStatusState = selector<Chatroom>({
  key: 'UpdateChatroomStatusState',
  get: ({ get }) => get(chatrooms)[0],
  set: ({ get, set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const loadedChatrooms = get(chatrooms);
      const newChatrooms = loadedChatrooms.map((c) => {
        if (c._id === newValue._id) {
          return newValue;
        }

        return c;
      });
      set(chatrooms, newChatrooms);
    }
  },
});

export const chatroomsArchivedState = selector<Chatroom>({
  key: 'ChatroomsArchivedState',
  get: ({ get }) => get(chatrooms)[0],
  set: ({ get, set, reset }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const { _id: updatedChatroomId } = newValue;
      const deletedChatroomsData = get(deletedChatrooms);
      set(deletedChatrooms, [...deletedChatroomsData, updatedChatroomId]);
      const loadedChatrooms = get(chatrooms);
      const newChatrooms = loadedChatrooms.filter((c) => c._id !== updatedChatroomId);
      set(chatrooms, newChatrooms);
      reset(activeChatroom);
    }
  },
});

export const newChatroomAddedState = selector<{ chatroom: Chatroom; message: Message | undefined }>({
  key: 'NewChatroomAddedState',
  get: ({ get }) => {
    const lastUpdatedChatroom = get(chatrooms)[0];
    return {
      chatroom: lastUpdatedChatroom,
      message: lastUpdatedChatroom.message,
    };
  },
  set: ({ get, set }, newValue) => {
    if (!(newValue instanceof DefaultValue)) {
      const { chatroom: newChatroom, message: newMessage } = newValue;
      const loadedChatrooms = get(chatrooms);
      newChatroom.message = newMessage;
      const newChatrooms = [newChatroom, ...loadedChatrooms];
      set(chatrooms, newChatrooms);
    }
  },
});

export const pushChatroomsState = selector<Chatroom[]>({
  key: 'PushChatroomsState',
  get: ({ get }) => get(chatrooms),
  set: ({ get, set, reset }, newChatrooms) => {
    if (!(newChatrooms instanceof DefaultValue)) {
      const data = get(chatrooms);
      set(chatrooms, [
        ...data.filter((room) => !newChatrooms.some((newChatroom) => newChatroom._id === room._id)),
        ...newChatrooms,
      ]);
    }
  },
});

export const updateChatroomsState = selector<Chatroom[]>({
  key: 'UpdateChatroomsState',
  get: ({ get }) => get(chatrooms),
  set: ({ get, set, reset }, chatroomsToApply) => {
    if (chatroomsToApply instanceof DefaultValue) {
      reset(chatrooms);
    } else {
      set(chatrooms, chatroomsToApply);
    }
  },
});

export const activeChatroomState = selectorFamily<Chatroom, ID | null>({
  key: 'ActiveChatroomState',
  get:
    (chatroomId: ID | null) =>
    ({ get }) => {
      const loadedChatrooms = get(chatrooms);
      return loadedChatrooms.find((c) => c._id === chatroomId) as Chatroom;
    },
});
