import * as React from 'react';

import { Box } from '../../atoms/box/Box';
import { Text } from '../../atoms/text';
import { ThemeConsumer } from '../../atoms/theme';
import { SearchInput } from '../../atoms/search-input';

export function CategoriesFilters(props) {
  return (
    <ThemeConsumer>
      {(theme) => (
        <Box flexDirection="row" alignItems="center" style={{ marginBottom: 43 }}>
          <div style={{ width: '45%' }}>
            <Text size="19px" lineHeight="1" color={theme.color.gray} weight="500" component="p">
              {props.title}
            </Text>
            <Text size="15px" lineHeight="1.3" color={theme.color.lightGray} component="small">
              {props.subtitle}
            </Text>
          </div>
          <div style={{ width: '55%' }}>
            {props.onChange ? <SearchInput value={props.value} onChange={props.onChange} /> : null}
          </div>
        </Box>
      )}
    </ThemeConsumer>
  );
}
