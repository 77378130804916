import { http } from './shared';

/**
 * Typings
 */

/**
 * Implementation
 */
export const AuthService = {
  signUp(payload) {
    return http.post(`/users`, { user: payload });
  },
  attemptWithEmail(payload) {
    payload = { ...payload, grant_type: 'password' };
    return http.post(`/oauth/token`, payload);
  },
  attemptWithSocial(payload) {
    payload = {
      ...payload,
      organization_slug: process.env.REACT_APP_CONFIG,
    };
    return http.post('/users/oauth', payload);
  },
  getCurrentUser() {
    return http.get('/users/current');
  },
};
