import { useTranslation } from 'react-i18next';
import { Button, CommentIcon, Text } from 'ui';

type P = {
  count: number;
  onShowComments?: () => void;
};

const CommentCount = ({ count, onShowComments }: P) => {
  const { t } = useTranslation();
  return (
    <Button onClick={onShowComments} type="text" icon={<CommentIcon />} size="large">
      <Text type="secondary">{t('commentsCount', { count })}</Text>
    </Button>
  );
};

export default CommentCount;
