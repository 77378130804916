import { createCalendarySync, type CalendarSync } from 'models';
import { useQuery } from 'query';
import { getCalendarSync } from '../services';
import { getCalendarSyncQueryKey } from '.';

export const useCalendarSyncQuery = () =>
  useQuery<CalendarSync[]>(getCalendarSyncQueryKey(), async () => {
    const { data } = await getCalendarSync();

    return data.map((calendarSync) => createCalendarySync(calendarSync));
  });
