import { useTranslation } from 'react-i18next';
import { routes } from 'routes';
import { useEventsInfiniteQuery } from 'features/events/queries';
import { Card, Link, Skeleton, Title, Breadcrumb, Empty } from 'ui';
import { EventsSlider, EntityList } from '../components';
import { useOrganizationIdForRequests } from '../hooks';
import { useOrganizationsQuery } from '../queries';

type P = {
  getPathToEventDetail: (id: string) => string;
  organizationId: string;
};

const ChannelsEvents = ({ getPathToEventDetail, organizationId }: P) => {
  const { t } = useTranslation();
  // TODO Solve default organization logic in better way - perhaps BE update?
  const { data: channelsOrganizations, isLoading: isChannelsOrganizationLoading } = useOrganizationsQuery();
  const organizationIdForRequests = useOrganizationIdForRequests(organizationId, channelsOrganizations);

  const {
    data: allEventsData,
    isLoading: isLoadingAll,
    isFetching: isFetchingAll,
    fetchNextPage,
    hasNextPage,
  } = useEventsInfiniteQuery({
    scope: 'channels',
    pageSize: 12,
    organizationId: organizationIdForRequests,
  });

  const allEvents = allEventsData?.pages.flat();

  const breadcrumbRoutes = [
    {
      path: routes.channels.index,
      breadcrumbName: t('Channels'),
    },
    {
      path: routes.channels.organizationChannel(organizationId, 'events'),
      breadcrumbName: t('Events'),
    },
  ];

  return (
    <>
      <Breadcrumb
        routes={breadcrumbRoutes.map((route) => ({ ...route, breadcrumbName: t(route.breadcrumbName) }))}
        itemRender={({ path, breadcrumbName }: { path: string; breadcrumbName: string }) => {
          return <Link to={path}>{breadcrumbName}</Link>;
        }}
      />
      <Title>{t('Events')}</Title>
      {isChannelsOrganizationLoading || isLoadingAll || !allEvents || !channelsOrganizations ? (
        <Skeleton active paragraph={{ rows: 16 }} />
      ) : (
        <>
          <EventsSlider
            getPathToEventDetail={getPathToEventDetail}
            organizationId={organizationIdForRequests}
            title="Featured"
            view="detail"
          />
          <Card>
            <Title level={3}>{t('All')}</Title>
            <EntityList
              getPathToEntityDetail={getPathToEventDetail}
              empty={<Empty />}
              entities={allEvents}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetching={isFetchingAll}
              isLoading={isLoadingAll}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default ChannelsEvents;
