import { useLocation } from 'react-router-dom';
import { Header, ContentLeft, Footer, ForgotPassword, type HeaderItem } from 'features/onboarding';
import { LandingLayout } from 'ui';
import { useOnboardingNavigation } from '../hooks';
import { type OnboardingLocationState } from '../types';

export type ForgotPasswordLocationState = OnboardingLocationState & { email?: string };

const PageForgotPassword = () => {
  const { state } = useLocation<ForgotPasswordLocationState | undefined>();
  const { onNavigateToSignUp, onNavigateToPrimaryLogin } = useOnboardingNavigation<ForgotPasswordLocationState>();

  const headerButtons: HeaderItem[] = [
    {
      label: 'Sign up',
      type: 'primary',
      onClick() {
        onNavigateToSignUp();
      },
    },
  ];

  return (
    <LandingLayout
      header={<Header items={headerButtons} />}
      contentLeft={<ContentLeft />}
      contentRight={
        <ForgotPassword
          onNavigateToPrimaryLogin={onNavigateToPrimaryLogin}
          onResetSuccess={onNavigateToPrimaryLogin}
          email={state?.email}
        />
      }
      footer={<Footer />}
    />
  );
};

export default PageForgotPassword;
