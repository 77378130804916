// TODO Move type
import { type RadioChangeEvent } from 'antd/lib/radio/interface';
import { BankIcon, Col, Empty, Paragraph, RadioPills, Row, StarIcon, Title } from 'ui';
import { useTextCollapseExpand } from '../hooks';
import { type ChannelsOrganization } from '../types';

type P = {
  value?: string;
  onChange?: (value: RadioChangeEvent) => void;
  isLoading?: boolean;
  organizations: ChannelsOrganization[];
};

const ChannelsOrganizationSelect = ({ value, onChange, organizations }: P) => {
  const selectedOrganization = value
    ? organizations.find((organizationItem) => organizationItem.id === value)
    : undefined;

  const { ellipsis, collapseExpandButton } = useTextCollapseExpand({
    text: selectedOrganization?.description,
  });

  const items = organizations.map((organization) => ({
    label: organization.name,
    value: organization.id,
    icon: organization.default ? <StarIcon /> : <BankIcon />,
  }));

  return (
    <>
      {organizations.length === 0 ? (
        <Empty />
      ) : (
        <div className="channels-organization-select">
          <Title level={2} marginTop={false}>
            Channels
          </Title>
          {items.length > 1 ? (
            <div className="channels-organization-select__radio-pills">
              <RadioPills onChange={onChange} value={value} items={items} />
            </div>
          ) : null}
          {selectedOrganization?.description ? (
            <Row key={selectedOrganization?.description}>
              <Col item={{ span: 12 }}>
                <Paragraph ellipsis={ellipsis}>{selectedOrganization?.description}</Paragraph>
                {collapseExpandButton}
              </Col>
            </Row>
          ) : null}
        </div>
      )}
    </>
  );
};

export default ChannelsOrganizationSelect;
