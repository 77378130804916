import * as React from 'react';

import { Box } from '../box/Box';
import { Icon } from '../icon';

export function Loader(props) {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      className="spinner"
      style={{
        minWidth: props.size * 1.5,
        minHeight: props.size * 1.5,
        padding: 0,
      }}
    >
      <Icon name="spin5" color={props.color} size={props.size} className="animate-spin" />
    </Box>
  );
}

Loader.defaultProps = {
  color: '#ccc',
  size: 30,
};
