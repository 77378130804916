import { type Image } from 'models';
import { apiClient, type ApiClientResponse, routes } from 'common/services';

type UpdateUserProfileRequestData = Partial<{
  profile_photo: Image;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthdate: string;
  time_zone: string;
  gender: {
    id: string;
  };
  country: string;
  state: string;
  city: string;
  zip: string;
  street: string;
  apt: string;
  education_level: {
    id: string;
  };
  company: string;
  profession: {
    id: string;
  };
  biography: string;
  interests: Array<{ id: string }>;
}>;

export const updateUserProfile = async (userId: string, data: UpdateUserProfileRequestData) =>
  apiClient.patch<unknown, ApiClientResponse<unknown>>(routes.user.patch(userId), {
    user: data,
  });
